import { Card } from 'react-bootstrap';

const MethodCardBody = ({method}) => {
    return (
        <Card.Body className="rounded-0 border-0 bg-white d-flex flex-column text-left method-card-body">
            <span className="method-card-middle-heading">{method.title}</span>
            <span className="method-card-text">{method.description ? method.description : method.location}</span>
        </Card.Body>
    );
}
export default MethodCardBody;