import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UnauthorizedAccess from './unauthorizedAccess';
import setAuthUserRole from "./setAuthUserRole";

const SecuredRoute = ({ component: Component, security, ...otherProps }) => (
  <>
  {setAuthUserRole(security.authRole)}
  <Route
    {...otherProps}
    render={(props) =>
      (security.authUser!=null && ['1', '2', '3', '4', '5'].includes(security.authRole.toString())) ? (
        <Component {...props} />
      ) : (
        <UnauthorizedAccess />
      )
    }
  />
  </>
);

SecuredRoute.propTypes = {
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, {})(SecuredRoute);
