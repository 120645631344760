import axios from "axios";

import {
  GET_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST,
  GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST,
  GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST_DTO,
  GET_ERRORS,
  GET_TOPICS_OF_DISCUSSION_BY_FIELDS_OF_INTEREST
} from "./types";

export const getAllTopicsOfDiscussionFieldsOfInterest = () => async (
  dispatch
) => {
  const res = await axios.get(`/api/topicOfDiscussionFieldsOfInterest/findAll`);
  dispatch({
    type: GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST,
    payload: res.data,
  });
};

export const getTopicOfDiscussionFieldsOfInterest = (id) => async (
  dispatch
) => {
  try {
    const res = await axios.get(
      `/api/topicOfDiscussionFieldsOfInterest/findById/${id}`
    );
    dispatch({
      type: GET_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST,
      payload: res.data,
    });
  } catch (e) {
    // e.getMessage();
  }
};

export const getAllTopicsOfDiscussionFieldsOfInterestDTO = () => async (
  dispatch
) => {
  const res = await axios.get(
    `/api/topicOfDiscussionFieldsOfInterest/findAllDTO`
  );
  dispatch({
    type: GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST_DTO,
    payload: res.data,
  });
};

export const getTopicsOfDiscussionByFieldsOfInterest = (fieldsOfInterest) => async (
  dispatch
) => {
  try {
    const res = await axios.post(
      `/api/topicOfDiscussionFieldsOfInterest/findAllByFieldsOfInterest`, fieldsOfInterest
    );
    dispatch({
      type: GET_TOPICS_OF_DISCUSSION_BY_FIELDS_OF_INTEREST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};