import axios from "axios";

import {
  GET_ERRORS,
  GET_ACTION_PLAN,
  GET_ACTION_PLAN_LIST,
  DELETE_ACTION_PLAN,
} from "./types";

export const uploadActionPlan = (data, history) => async (dispatch) => {
  await axios.post(`/api/actionPlan/upload`, data);
  history.push("/ogpPage/2");
};

export const updateActionPlan = (actionPlan, history) => async (dispatch) => {
  try {
    await axios.post("/api/actionPlan/update", actionPlan);
    history.push("/ogpPage/2");
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getAllActionPlans = () => async (dispatch) => {
  const res = await axios.get("/api/actionPlan/findAll");
  dispatch({
    type: GET_ACTION_PLAN_LIST,
    payload: res.data,
  });
};

export const getActionPlan = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/actionPlan/findById/${id}`);
    dispatch({
      type: GET_ACTION_PLAN,
      payload: res.data,
    });
  } catch (e) {
    e.getMessage();
  }
};

export const deleteActionPlan = (id) => async (dispatch) => {
  await axios.delete(`/api/actionPlan/delete/${id}`);
  dispatch({
    type: DELETE_ACTION_PLAN,
    payload: id,
  });
};
