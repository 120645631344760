import {
    GET_METHOD,
    GET_METHOD_LIST,
    CREATE_METHOD,
    UPDATE_METHOD,
    PIN_METHOD,
    UNPIN_METHOD,
    PUBLISH_METHOD,
    GET_METHOD_SURVEY_DTO,
    GET_METHOD_DOCUMENTS,
    DELETE_METHOD_DOCUMENT,
    GET_METHOD_LIST_FOR_SELECT
} from "../actions/types";

const initialState = {
    methodList: [],
    method: {},
    methodSurveyDTO: {}

};

export default function (state = initialState, action) {
    switch (action.type) {
    case GET_METHOD:
        return {
            ...state,
            method: action.payload,
            };
      case GET_METHOD_LIST:
        return {
          ...state,
          methodList: action.payload,
        };
        case GET_METHOD_LIST_FOR_SELECT:
            return {
              ...state,
              methodListForSelect: action.payload,
            };
      case CREATE_METHOD:
        return {
            ...state
            };
    case UPDATE_METHOD:
        return {
            ...state
            };
    case PIN_METHOD:
        return {
            ...state,
            methodList: action.payload,
            };
    case UNPIN_METHOD:
        return {
            ...state,
            methodList: action.payload,
            };
    case PUBLISH_METHOD:
        return {
            ...state,
            methodList: action.payload
            };
    case GET_METHOD_SURVEY_DTO:
        return {
            ...state,
            methodSurveyDTO: action.payload
            };
    case GET_METHOD_DOCUMENTS:
        return {
            ...state,
            methodDocuments: action.payload
            };
    case DELETE_METHOD_DOCUMENT:
        return {
            ...state
            }
      default:
        return state;
    }
}