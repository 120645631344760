import CommentFilterField from "./CommentFilterField";
import CommentFilterDateRange from "./CommentFilterDateRange";
import CommentFilterSelect from "./CommentFilterSelect";
import { useSelector } from "react-redux";

const CommentDisplayHeader = ({columnFilters, setColumnFilters, sorting, setSorting}) => {
    const category = useSelector(state => state.startUp.taxonomies.CommentCategory)
    const status = useSelector(state => state.startUp.taxonomies.CommentStatus);

    const statusList = status?.filter(x => x.code != "Primljen" && x.code != "UObradi" && x.code != "Odbijen");
    return (
        <div className="d-flex flex-wrap justify-content-between comment-filter-header">
                <CommentFilterField filterId='userFullName' 
                                    columnFilters={columnFilters} 
                                    setColumnFilters={setColumnFilters}
                                    sorting={sorting} setSorting={setSorting}/>
                <CommentFilterField filterId='userOrganizationOrganizationName'
                                    columnFilters={columnFilters} 
                                    setColumnFilters={setColumnFilters}
                                    sorting={sorting} setSorting={setSorting}/>
                <CommentFilterDateRange filterId='creationDate'
                                    columnFilters={columnFilters} 
                                    setColumnFilters={setColumnFilters}
                                    sorting={sorting} setSorting={setSorting}/>
                <CommentFilterField filterId='commentText' 
                                    columnFilters={columnFilters} 
                                    setColumnFilters={setColumnFilters}
                                    sorting={sorting} setSorting={setSorting}/>

                <CommentFilterSelect filterId='category' 
                                    columnFilters={columnFilters} 
                                    setColumnFilters={setColumnFilters}
                                    sorting={sorting} setSorting={setSorting}
                                    selectList={category}/>
                <CommentFilterSelect filterId='status' 
                                    columnFilters={columnFilters}
                                        setColumnFilters={setColumnFilters}
                                        sorting={sorting} setSorting={setSorting}
                                        selectList={statusList}/>
                <CommentFilterField filterId='commentTextModerator' 
                                    columnFilters={columnFilters} 
                                    setColumnFilters={setColumnFilters}
                                    sorting={sorting} setSorting={setSorting}/>
                <CommentFilterDateRange filterId='creationDateModerator'
                                        columnFilters={columnFilters}   
                                        setColumnFilters={setColumnFilters}
                                        sorting={sorting} setSorting={setSorting}/>
        </div>
    );
}

export default CommentDisplayHeader;