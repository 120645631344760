import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getSurveyAnswersList,
  getSurveyClosedAnswersList,
} from '../../actions/surveyAnswersActions';
import PropTypes from 'prop-types';
import SurveyAnswersTable from './SurveyAnswersTable';
import GoBackButton from '../Reusable/GoBackButton';
import { getSurveyQuestion } from '../../actions/surveyQuestionsActions';
import LoadingIndicator from '../Reusable/LoadingIndicator';
import { trackPromise } from 'react-promise-tracker';

class SurveyAnswersList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { id, questionTypeId } = this.props.match.params;
    // Open question
    if (questionTypeId == 1) {
      trackPromise(this.props.getSurveyAnswersList(id));
    }
    // Closed question
    else if (questionTypeId == 2) {
      trackPromise(this.props.getSurveyClosedAnswersList(id));
    }
    trackPromise(this.props.getSurveyQuestion(id)).finally(() =>
      this.setState({ isLoading: false })
    );
  }

  render() {
    const { isLoading } = this.state;

    const {
      surveyId,
      topicOfDiscussionId,
      phaseNumber,
      questionTypeId,
    } = this.props.match.params;

    const surveyAnswersList = [...this.props.surveyAnswers.surveyAnswersList];

    return isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="container-fluid component-wrapper-fade-in">
        {/* <GoBackButton
          route={`/surveyQuestionsList/${surveyId}/${topicOfDiscussionId}/${phaseNumber}`}
        /> */}
        <br></br>
        <div className="row col-md-11 m-auto">
          <div className="col-md-12">
            <div className="text-left mb-2">
              <h1 className="title-text text-center">
                {this.props.surveyQuestions.question}
              </h1>
              <SurveyAnswersTable
                surveyAnswersList={surveyAnswersList}
                questionTypeId={questionTypeId}
                handleShowMesasage={this.handleShowMesasage}
              />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SurveyAnswersList.propTypes = {
  surveyAnswers: PropTypes.object.isRequired,
  getSurveyAnswersList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  surveyAnswers: state.surveyAnswers,
  surveyQuestions: state.surveyQuestions.surveyQuestions,
});

export default connect(mapStateToProps, {
  getSurveyAnswersList,
  getSurveyClosedAnswersList,
  getSurveyQuestion,
})(SurveyAnswersList);
