import {
    GET_OGP_REPORT_FILE_LIST,
    GET_OGP_REPORT_FILE,
    DELETE_OGP_REPORT_FILE,
} from "../actions/types";

const initialState = {
    ogpReportFileList: [],
    ogpReportFile: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_OGP_REPORT_FILE_LIST:
            return {
                ...state,
                ogpReportFileList: action.payload,
            };
        case GET_OGP_REPORT_FILE:
            return {
                ...state,
                ogpReportFile: action.payload,
            };
        case DELETE_OGP_REPORT_FILE:
            return {
                ...state,
                ogpReportFileList: state.ogpReportFileList.filter(
                    (orf) => orf.id !== action.payload
                ),
            };

        default:
            return state;
    }
}
