import {
  GET_SURVEY_QUESTIONS,
  GET_SURVEY_QUESTIONS_LIST,
  GET_SURVEY_QUESTION_TYPE_LIST,
  DELETE_SURVEY_QUESTIONS,
  CLEAR_SURVEY_QUESTION,
} from '../actions/types';

const initialState = {
  surveyQuestionsList: [],
  surveyQuestionTypeList: [],
  surveyQuestions: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SURVEY_QUESTIONS_LIST:
      return {
        ...state,
        surveyQuestionsList: action.payload,
      };
    case GET_SURVEY_QUESTION_TYPE_LIST:
      return {
        ...state,
        surveyQuestionTypeList: action.payload,
      };
    case GET_SURVEY_QUESTIONS:
      return {
        ...state,
        surveyQuestions: action.payload,
      };
    case DELETE_SURVEY_QUESTIONS:
      return {
        ...state,
        surveyQuestionsList: state.surveyQuestionsList.filter(
          (sq) => sq.id !== action.payload
        ),
      };

    case CLEAR_SURVEY_QUESTION:
      return {
        ...state,
        surveyQuestions: action.payload,
      };

    default:
      return state;
  }
}
