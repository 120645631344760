import React from 'react';
import { Link } from 'react-router-dom';

const EditButton = (props) => {
  return (
    <div>
      <Link onClick={props.click} to="#">
        <i className="fas fa-edit deleteButton"></i>
      </Link>
    </div>
  );
};

export default EditButton;
