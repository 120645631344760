import React from "react";
import { Link } from "react-router-dom";

const UpdateButtonModal = (props) => {
    return (
        <div>
            <Link to="#" onClick={(e) => { props.onClickHandler(); }} >
                <i className="fas fa-edit deleteButton"></i>
            </Link>
        </div>
    );
};

export default UpdateButtonModal;