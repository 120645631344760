import axios from "axios";

import {
    GET_PHASE_LIST,
    CREATE_PHASE,
    UPDATE_PHASE,
    GET_ERRORS
} from "./types";

export const getPhaseList = (topicOfDiscussionId) => async (
    dispatch
  ) => {
    const res = await axios.get(
      `/api/phase/findAll/${topicOfDiscussionId}`
    );
    dispatch({
      type: GET_PHASE_LIST,
      payload: res.data,
    });
  };
  
  export const createPhase = (data) => async (
    dispatch
  ) => {
    try{
      const res = await axios.post(
        `/api/phase/create`, data
      );
      dispatch({
        type: CREATE_PHASE,
        payload: res.data,
      });
    }
    catch (err) {
      dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      throw(err);
    }
  };

  export const updatePhaseDates = (data) => async (
    dispatch
  ) => {
    try{
      const res = await axios.post(
        `/api/phase/updatePhaseDates`, data
      );
      dispatch({
        type: UPDATE_PHASE,
        payload: res.data,
      });
    }
    catch (err) {
      dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      throw(err);
    }
  };