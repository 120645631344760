import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OgpStepRow from "./OgpStepRow";
import authorizationService from "../../securityUtils/authorizationService";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";
import { createOGPStep } from "../../actions/ogpStepActions";
import { Link } from "react-router-dom";

class OgpStepTable extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleItemAdd = (item) => {
    this.props
      .createOGPStep(item)
      .then(() => this.setState({ isAdding: false }));
  };

  toggleAdding = () => {
    this.setState({ isAdding: !this.state.isAdding });
  };

  render() {
    const { isAdding } = this.state;

    let ogpStepList = this.props.ogpStepList.map((ogpStep) => (
      <OgpStepRow
        key={ogpStep.id}
        ogpStep={ogpStep}
        ogpUpdate={this.props.ogpUpdate}
        ogpModerators={this.props.ogpModerators}
      />
    ));

    return (
      <div>
        {authorizationService.canAccessOGPUpdateSpecificFields(
          this.props.authRole,
          this.props.ogpModerators
        ) && this.props.ogpUpdate ? (
          <div className="border-bottom" style={{ paddingBottom: "1%" }}>
            <span className="small-font boldText">
              <span className="redAsterisk">*</span> {this.props.dictionary["OgpStepTable.KorakePopuniti"]}
            </span>
            <Link to="#" onClick={(e) => {e.preventDefault();this.toggleAdding();}}>
              {isAdding ? (
                <i className="fas fa-minus-circle editButton fa-pull-right" />
              ) : (
                <i className="fas fa-plus-circle editButton fa-pull-right" />
              )}
            </Link>
          </div>
        ) : (
          ""
        )}
        <div className="table-responsive min-dynamic-tabele-height">
          <Table id="example" className="table table-sm table-striped">
            <Thead>
              <Tr className="table-header-height text-center align-items-center">
                <Th width="50%" className="font-weight-normal textShadow">
                  {this.props.dictionary["OgpStepTable.Aktivnosti"]}
                </Th>
                <Th className="font-weight-normal textShadow">
                {this.props.dictionary["OgpStepTable.DatumPocetka"]}:
                </Th>
                <Th className="font-weight-normal textShadow">
                {this.props.dictionary["OgpStepTable.DatumZavrsetka"]}:
                </Th>
                {authorizationService.canAccessOGPUpdateSpecificFields(
                  this.props.authRole,
                  this.props.ogpModerators
                ) && this.props.ogpUpdate ? (
                  <Th width="5%" className="font-weight-normal textShadow">
                    {this.props.dictionary["OgpStepTable.Izmena"]}
                  </Th>
                ) : (
                  <Th></Th>
                )}
                {authorizationService.canAccessOGPUpdateSpecificFields(
                  this.props.authRole ,
                  this.props.ogpModerators
                ) && this.props.ogpUpdate ? (
                  <Th width="5%" className="font-weight-normal textShadow">
                     {this.props.dictionary["OgpStepTable.Akcija"]}
                  </Th>
                ) : (
                  <Th></Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {isAdding ? (
                <OgpStepRow
                  handleAdd={this.handleItemAdd}
                  ogpId={this.props.ogpId}
                  ogpUpdate={this.props.ogpUpdate}
                  ogpModerators={this.props.ogpModerators}
                />
              ) : (
                <Tr></Tr>
              )}
              {ogpStepList}
            </Tbody>
          </Table>
        </div>
      </div>
    );
  }
}

OgpStepTable.propTypes = {
  createOGPStep: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, { createOGPStep })(OgpStepTable);
