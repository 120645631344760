import {
  GET_MAIL_SETTINGS,
  GET_MAIL_SETTINGS_LIST,
  DELETE_MAIL_SETTINGS,
} from "../actions/types";

const initialState = {
  mailSettingsList: [],
  mailSettings: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MAIL_SETTINGS_LIST:
      return {
        ...state,
        mailSettingsList: action.payload,
      };
    case GET_MAIL_SETTINGS:
      return {
        ...state,
        mailSettings: action.payload,
      };
    case DELETE_MAIL_SETTINGS:
      return {
        ...state,
        mailSettingsList: state.mailSettingsList.filter(
          (mailSettings) => mailSettings.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
