import { Log, User, UserManager } from 'oidc-client';
import {
  STS_AUTHORITY,
  CLIENT_ID,
  CLIENT_SCOPE,
  CLIENT_ROOT,
} from './oidcConfig';
import store from '../store';

class AuthService {
  constructor() {
    const settings = {
      authority: STS_AUTHORITY,
      client_id: CLIENT_ID,
      redirect_uri: `${CLIENT_ROOT}signin-oidc`,
      silent_redirect_uri: `${CLIENT_ROOT}silent-renew.html`,
      post_logout_redirect_uri: `${CLIENT_ROOT}signout-callback-oidc`,
      response_type: 'code',
      scope: CLIENT_SCOPE,
    };

    this.userManager = new UserManager(settings);

    Log.logger = console;
    Log.level = Log.INFO;
  }

  getUser() {
    return this.userManager.getUser();
  }

  login() {
    return this.userManager.signinRedirect();
  }

  renewToken() {
    return this.userManager.signinSilent();
  }

  logout() {
    store.dispatch({
      type: 'LOG_OUT_USER',
      payload: {},
    });
    Promise.resolve(this.userManager.signoutRedirect());
  }

  getToken() {
    return new UserManager({ response_mode: 'query' })
      .signinRedirectCallback()
      .catch(function (e) {
        console.error(e);
      });
  }
}

export default new AuthService();
