import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import CommentSort from './CommentSort';

const CommentFilterField = ({filterId, columnFilters, setColumnFilters, sorting, setSorting}) => {

    const dictionary = useSelector(state => state.startUp.dictionary);

    const [searchTerm, setSearchTerm] = useState('');


    useEffect(() => {
        const timer = setTimeout(() => {
            const oldFilter = columnFilters.filter(x => x.id != filterId);

            if (searchTerm != '') {
                setColumnFilters([
                    ...oldFilter,
                        {
                            id: filterId,    
                            value: searchTerm
                        } 
                ]);
            } else {
                setColumnFilters([
                    ...oldFilter
                ]);
            }
        }, 500)
    
        return () => clearTimeout(timer)
      }, [searchTerm])

    return (
        <div>
            <div>
                <label className="pe-2">{dictionary[`CommentFilterField.${filterId}`]}</label>
                <CommentSort filterId={filterId} sorting={sorting} setSorting={setSorting}/>
            </div>
            <div>
                <input 
                    id={filterId}
                    className="comment-filter-input"
                    type="text"
                    placeholder={dictionary[`CommentFilterField.Placeholder.${filterId}`]}
                    onChange={e => setSearchTerm(e.target.value)}
                    >
                </input>
            </div>
        </div>
    );
}

export default CommentFilterField;