import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserOrganizationCategoryList, getUserOrganizationCategory } from '../../actions/userOrganizationCategoryActions';
import UserOrganizationCategoryTable from './UserOrganizationCategoryTable';
import authorizationService from '../../securityUtils/authorizationService';
import LoadingIndicator from '../Reusable/LoadingIndicator';
import { trackPromise } from 'react-promise-tracker';
import ModalForAddUserOrganizationCategory from './ModalForAddUserOrganizationCategory';
import ModalForUpdateUserOrganizationCategory from './ModalForUpdateUserOrganizationCategory';

class UserOrganizationCategoryList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      showModalForAddUserOrganizationCategory: false,
      showModalForUpdateUserOrganizationCategory: false,
      refreshData: 0
    };
  }

  componentDidMount() {
    trackPromise(this.props.getUserOrganizationCategoryList()).finally(() =>
      this.setState({ isLoading: false })
    );
  }

  handleCloseModalForAddUserOrganizationCategory = (refresh) => {
    if(refresh != null && refresh == true) {
      this.setState({ isLoading: true });
      trackPromise(this.props.getUserOrganizationCategoryList()).finally(() =>
        this.setState({ isLoading: false })
      );
    }
    this.setState({
      showModalForAddUserOrganizationCategory: false,
      });
  };
  handleShowModalForAddUserOrganizationCategory = () => {
    this.setState({
      showModalForAddUserOrganizationCategory: true,
    });
  };
  handleCloseModalForUpdateUserOrganizationCategory = (refresh) => {
    if(refresh != null && refresh == true) {
      this.setState({ isLoading: true });
      trackPromise(this.props.getUserOrganizationCategoryList()).finally(() =>
        this.setState({ isLoading: false })
      );
    }
    this.setState({
        showModalForUpdateUserOrganizationCategory: false,
      });
  };
  handleShowModalForUpdateUserOrganizationCategory = (id) => {
    this.props.getUserOrganizationCategory(id);
    this.setState({
      showModalForUpdateUserOrganizationCategory: true,
    });
  };

  render() {
    const { isLoading } = this.state;

    const { userOrganizationCategoryList } =
      this.props.userOrganizationCategory;

    return isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="container component-wrapper-fade-in">
        <br></br>
        <div className="row">
          <div className="col-md-9 m-auto">
            <div className="text-left mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="textShadow">{this.props.dictionary["userOrganizationCategory.kategorijaOrganizacije"]}</h2>
                <div className="float-right">
                  {authorizationService.canAccessAdminPanel(this.props.authRole) && (
                    // <CreateButton route={`/addUserOrganizationCategory`} />
                    <button 
                      className="create-button"
                      onClick={(e) => {e.preventDefault(); this.handleShowModalForAddUserOrganizationCategory();}}
                    >
                    {this.props.dictionary["userOrganizationCategoryList.Dodaj"]}
                    </button>
                  )}
                </div>
              </div>
              <div>
                <UserOrganizationCategoryTable
                  userOrganizationCategoryList={userOrganizationCategoryList}
                  showModalForUpdateFunction = {this.handleShowModalForUpdateUserOrganizationCategory.bind(this)}
                />
              </div>
              { this.state.showModalForAddUserOrganizationCategory &&
                <ModalForAddUserOrganizationCategory
                  show={this.state.showModalForAddUserOrganizationCategory}
                  handleClose={this.handleCloseModalForAddUserOrganizationCategory}
                />
              }
              { this.state.showModalForUpdateUserOrganizationCategory &&
                <ModalForUpdateUserOrganizationCategory
                  show={this.state.showModalForUpdateUserOrganizationCategory}
                  handleClose={this.handleCloseModalForUpdateUserOrganizationCategory}
                  userOrganizationCategory={this.props.userOrganizationCategoryForEdit}
                />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  userOrganizationCategory: state.userOrganizationCategory,
  userOrganizationCategoryForEdit: state.userOrganizationCategory.userOrganizationCategory
});

export default connect(mapStateToProps, {
  getUserOrganizationCategoryList,
  getUserOrganizationCategory
})(UserOrganizationCategoryList);
