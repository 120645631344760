import jwtDecode from 'jwt-decode';
import { sessionStorageTokenKey } from '../constants';

export const extractAccessToken = () => {
  const tokenObject = parseTokenToJSON();
  return tokenObject?.access_token;
};

export const tokenExpirationCheck = () => {
  const tokenObject = parseTokenToJSON();
  return (
    tokenObject && jwtDecode(extractAccessToken()).exp * 1000 <= Date.now()
  );
};

function parseTokenToJSON() {
  const tokenJSON = sessionStorage[sessionStorageTokenKey];
  return tokenJSON ? JSON.parse(tokenJSON) : '';
}
