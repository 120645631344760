import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Input from '../Reusable/Input';
import TextArea from '../Reusable/TextArea';
import { updateUserOrganizationCategory } from '../../actions/userOrganizationCategoryActions';
import { setMessage } from '../../utils';
import { NotificationManager } from 'react-notifications';
import { UPDATE_USER_ORGANIZATION_CATEGORY_SUCCESS } from '../../actions/types'

const ModalForUpdateUserOrganizationCategory = (props) => {
    const dispatch = useDispatch();
    const dictionary = useSelector(state => state.startUp.dictionary);
    const errors = useSelector(state=>state.errors);
    const userOrganizationCategoryUpdated = useSelector(state=>state.userOrganizationCategory.userOrganizationCategoryUpdated);

    const emptyUserOrganizationCategory = {
        userOrganizationCategoryName:"",
        comment:""
    };
    const [userOrganizationCategory, setUserOrganizationCategory] = useState(emptyUserOrganizationCategory);
    const [userOrganizationCategoryOriginal, setUserOrganizationCategoryOriginal] = useState(emptyUserOrganizationCategory);

    useEffect(()=>{
        if(userOrganizationCategoryUpdated != null) {
            if(userOrganizationCategoryUpdated==true)
                if(errors.duplicateUserOrganizationCategoryNameErrorMessage) NotificationManager.error(errors.duplicateUserOrganizationCategoryNameErrorMessage,dictionary["universal.GreskaUObradi"], 5000);
                else NotificationManager.success(dictionary["updateUserOrganizationCategory.UspesnaPromena"],dictionary["universal.Uspesno"], 5000);
            else NotificationManager.error(dictionary["updateUserOrganizationCategory.NeuspesnaPromena"],dictionary["universal.GreskaUObradi"], 5000);  
            dispatch({
                type: UPDATE_USER_ORGANIZATION_CATEGORY_SUCCESS,
                payload: null,
              });
        }
    },[userOrganizationCategoryUpdated, props.error])

    useEffect(()=>{
        setUserOrganizationCategory(props.userOrganizationCategory);
        setUserOrganizationCategoryOriginal(props.userOrganizationCategory);
    },[props.userOrganizationCategory])


    const onChange = (e) => {
        const {name,value} = e.target;
        setUserOrganizationCategory(prevUserOrganizationCategory => ({...prevUserOrganizationCategory, [name]:value}));
    };
    const handleClose = (refresh) => {
        setUserOrganizationCategory(emptyUserOrganizationCategory);
        props.handleClose(refresh);
    };
    const handleValidation = () => {
        let error = false;
        if (userOrganizationCategory.userOrganizationCategoryName == '') {
          dispatch(setMessage(dictionary["addUserOrganizationCategory.Validation.Kategorija"],'#userOrganizationCategoryName','#validationErrorUserOrganizationCategoryName'));
          error = true;
        }
        if (
            userOrganizationCategoryOriginal.userOrganizationCategoryName !=
            userOrganizationCategory.userOrganizationCategoryName &&
            (userOrganizationCategory.comment === null ||
             userOrganizationCategory.comment === '' ||
             userOrganizationCategoryOriginal.comment == userOrganizationCategory.comment)
          ) {
            dispatch(setMessage(dictionary["updateUserOrganizationCategory.Validation.Obrazlozenje"],'#comment','#validationErrorComment'));
            error = true;
          }
      
          if (userOrganizationCategory.comment && userOrganizationCategory.comment.length > 300) {
            dispatch(setMessage(this.props.dictionary["updateUserOrganizationCategory.Validation.DuzinaKomentara"],'#comment','#validationErrorComment'));
            error = true;
          }
        return error;
    };
    const handleSubmit = (e) => {
        
        e.preventDefault();
        
        if (handleValidation() == true) {
          return;
        }

        dispatch(updateUserOrganizationCategory(userOrganizationCategory))
        .catch( (err) => {
            dispatch({
                type: UPDATE_USER_ORGANIZATION_CATEGORY_SUCCESS,
                payload: false,
              });
          })
        .then( () => {
            dispatch({
                type: UPDATE_USER_ORGANIZATION_CATEGORY_SUCCESS,
                payload: true,
              });
        })
        .finally ( () => { 
            handleClose(true); 
        });
    };

    return(<Modal
        show={props.show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        >
    <Modal.Header closeButton>
    <Modal.Body>
        <h4 className="text-center textShadow mt-5">{dictionary["updateUserOrganizationCategory.naslov"]}{' '}</h4>
        <hr />
        <div className="form-group">
        <label className="textShadow">
            {dictionary["updateUserOrganizationCategory.nazivKategorije"]}<span className="redAsterisk"> *</span>
        </label>
        <Input
            type="text"
            id="userOrganizationCategoryName"
            placeholder={dictionary["updateUserOrganizationCategory.placeholder.nazivKategorije"]}
            name="userOrganizationCategoryName"
            value={userOrganizationCategory.userOrganizationCategoryName}
            onChange={onChange}
            errors={errors.userOrganizationCategoryName}
        />
        <div id="validationErrorUserOrganizationCategoryName" />
        </div>
        <div className="form-group">
        <label className="textShadow">
            {dictionary["updateUserOrganizationCategory.komentar"]} <span className="redAsterisk"> *</span>
        </label>
        <TextArea
            type="text"
            id="comment"
            placeholder={dictionary["updateUserOrganizationCategory.placeholder.komentar"]}
            name="comment"
            value={userOrganizationCategory.comment}
            onChange={onChange}
            errors={errors.comment}
            maxLength="300"
            rows={4}
        />
        <div id="validationErrorComment" />
        </div>
        <div className="col-md-12 mt-4">
        <button id="subscribeButton" 
                className="col-md-2 float-right"
                onClick={handleSubmit}
        >
            {dictionary["updateUserOrganizationCategory.sacuvaj"]}
        </button>
        <button id="subscribeButton"
                className="col-md-2 float-right"
                onClick={handleClose}
        >
            {dictionary["updateUserOrganizationCategory.odustani"]}
        </button>
        </div>
    </Modal.Body>
    </Modal.Header>
    </Modal>);
}
export default ModalForUpdateUserOrganizationCategory;