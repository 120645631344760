import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getUserOrganization,
  updateUserOrganization,
} from "../../actions/userOrganizationActions";
import Input from "../Reusable/Input";
import ComboReusable from "../Reusable/ComboReusable";
import { getUserOrganizationCategoryList } from "../../actions/userOrganizationCategoryActions";
import { setMessage } from "../../utils";
import Modal from 'react-bootstrap/Modal';
import { trackPromise } from "react-promise-tracker";
import {
  NotificationManager,
} from 'react-notifications';

class UpdateUserOrganization extends Component {
  constructor() {
    super();
    this.state = {
      organizationName: "",
      userOrganizationCategory: {
        id: 0,
      },
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleComboBoxChange = this.handleComboBoxChange.bind(this);
  }

  componentDidMount() {
    const { id } = this.props==null || this.props.match==null ? this.props.userOrganization : this.props.match.params;
    this.props.getUserOrganization(id);
    this.props.getUserOrganizationCategoryList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    const { ...userOrganization } = nextProps.userOrganization;

    this.setState({
      ...userOrganization,
    });
  }
  handleComboBoxChange = (object, objectId) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
      });
    };
  };
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleValidation = () => {
    let error = false;

    if (this.state.organizationName == "") {
      this.props.setMessage(
        this.props.dictionary["updateUserOrganization.Validation.Naziv"],
        "#organizationName",
        "#msg1"
      );
      error = true;
    }

    return error;
  };

  onSubmit(e) {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const { userId } = this.props.match != null ? this.props.match.params:{};
    const updateUserOrganization = {
      id: this.state.id,
      organizationName: this.state.organizationName,
      user: this.props.userOrganization.user,
      userOrganizationCategory: {
        id: this.state.userOrganizationCategory.id,
      },
      status: this.props.userOrganization.status,
      changedByAdmin: userId ? true : false,
    };

    trackPromise(this.props.updateUserOrganization(
      updateUserOrganization,
      this.props.history
    )).then( () => {
      NotificationManager.success(this.props.dictionary["UpdateUserOrganization.UspesnaIzmena"],this.props.dictionary["universal.Uspesno"], 5000);
    })
    .catch( () => {
      NotificationManager.error(this.props.dictionary["UpdateUserOrganization.NeuspesnaIzmena"], this.props.dictionary["universal.GreskaUObradi"], 5000);
    });
  }

  render() {
    const { errors } = this.state;
    let { userOrganizationCategoryList } = this.props.userOrganizationCategory;
    let cat = this.state.userOrganizationCategory
      ? this.state.userOrganizationCategory.id
      : 0;

    if (this.props.authRole === "3") {
      userOrganizationCategoryList = userOrganizationCategoryList.filter(
        (uo) => uo.userOrganizationCategoryName !== "Орган државне управе"
      );
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="modalForUpdateUserOrganization"
      >
        <Modal.Header closeButton>
            <Modal.Title>
            {this.props.dictionary["updateUserOrganization.naslov"]}
            </Modal.Title>
        </Modal.Header> 
        <Modal.Body>  
          <div className="register">
            <div className="container">
              <div className="row">
                <div className="m-auto">
                  <form onSubmit={this.onSubmit} className="col-md-12">
                    <div className="form-group mb-3">
                      <label className="labelText textShadow">
                      {this.props.dictionary["updateUserOrganization.nazivOrganizacije"]}
                      </label>
                      <span className="redAsterisk"> *</span>
                      <Input
                        type="text"
                        id="organizationName"
                        name="organizationName"
                        placeholder={this.props.dictionary["updateUserOrganization.placeholder.nazivOrganizacije"]}
                        value={this.state.organizationName}
                        onChange={this.onChange}
                        errors={errors.organizationName}
                      />
                      <div id="msg1" />
                    </div>
                    <div className="from-group">
                      <label className="labelText textShadow">
                      {this.props.dictionary["updateUserOrganization.kategorija"]} <span className="redAsterisk"> *</span>
                      </label>

                      <ComboReusable
                        updateValue={cat}
                        id="eventTypeCb"
                        namePart={this.props.dictionary["updateUserOrganization.namePart.kategorija"]}
                        items={userOrganizationCategoryList.map((objekat) => (
                          <option key={objekat.id} value={objekat.id}>
                            {objekat.userOrganizationCategoryName}
                          </option>
                        ))}
                        onChange={this.handleComboBoxChange(
                          "userOrganizationCategory",
                          "id"
                        )}
                      />
                      <div id="msg4" />
                    </div>
                    <div className="row mt-5 mb-2 justify-content-center">
                      <button id="subscribeButton" className="col-md-6 me-0">
                      {this.props.dictionary["updateUserOrganization.sacuvaj"]}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
      </Modal.Body>
      </Modal>
    );
  }
}

UpdateUserOrganization.propTypes = {
  updateUserOrganization: PropTypes.func.isRequired,
  getUserOrganization: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userOrganization: state.userOrganization.userOrganization,
  userOrganizationCategory: state.userOrganizationCategory,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getUserOrganization,
  updateUserOrganization,
  getUserOrganizationCategoryList,
  setMessage,
})(UpdateUserOrganization);
