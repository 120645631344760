import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import TextArea from '../Reusable/TextArea';
import { setMessage } from '../../utils';
import {
  createModeratorReply,
  getCommentListForDocumentAndUser,
} from '../../actions/commentActions';
import moment from 'moment';
import ComboBoxCommentStatus from './ComboBoxCommentStatus';

class ModalForAddModeratorComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: null,
      commentTextModerator: '',
      errors: {},
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleValidation = () => {
    let error = false;

    if (!this.state.status) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForAddModeratorComment.Validation.Status"]}`,
        '#statusCb',
        '#msg1'
      );
      error = true;
    }

    if (this.state.commentTextModerator === '') {
      this.props.setMessage(
        `${this.props.dictionary["ModalForAddModeratorComment.Validation.Odgovor"]}`,
        '#commentTextModerator',
        '#commentTextModeratorМsg'
      );
      error = true;
    }
    return error;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const { comment } = this.props;

    const newModeratorReply = {
      ...comment,
      topicOfDiscussion: {
        id: comment.topicOfDiscussionId,
      },
      status: this.state.status,
      commentText: JSON.stringify(comment.commentText),
      commentTextModerator: this.state.commentTextModerator,
    };

    this.props
      .createModeratorReply(newModeratorReply)
      .then(() => this.props.handleClose());
  };

  render() {
    const { errors } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <div className="formGroup">
              <div className="d-flex flex-row w-100 justify-content-between align-items-center">
                <div>
                  <i className="fas fa-user-circle user-default-icon"></i>
                </div>
                <div className="user-name-style p-2">
                  <strong>
                    {this.props.comment.user.firstName +
                      ' ' +
                      this.props.comment.user.lastName}
                  </strong>
                </div>
                <span
                  className="bullet-char default-tab-date-label-style"
                  style={{ margin: '0', padding: '0' }}
                >
                  &bull;
                </span>
                <div className="default-tab-date-label-style p-2 mr-auto">
                  {moment(this.props.comment.creationDate).format(
                    'DD.MM.YYYY HH:mm:ss'
                  )}
                </div>
              </div>
              <div className="w-100 form-group mt-2">
                <strong>{this.props.comment.commentText.comment.text}</strong>
              </div>
            </div>
            <div className="from-group">
              <label className="labelText textShadow">
                {this.props.dictionary["ModalForAddModeratorComment.Status"]}: <span className="redAsterisk"> *</span>
              </label>
              <ComboBoxCommentStatus
                status={this.state.status}
                onChange={this.onChange}
              />
              <div id="msg1" />
            </div>
            <div className="form-group">
              <label className="user-name-style boldText">{this.props.dictionary["ModalForAddModeratorComment.Odgovor"]}:</label>
              <TextArea
                type="text"
                id="commentTextModerator"
                placeholder={this.props.dictionary["ModalForAddModeratorComment.Placeholder.Odgovor"]}
                name="commentTextModerator"
                value={this.state.commentTextModerator}
                onChange={this.onChange}
                errors={errors.commentTextModerator}
                maxLength="2000"
                rows={8}
              />
              <div id="commentTextModeratorМsg" />
            </div>
            <div className="form-row">
              <button
                type="button"
                id="subscribeButton"
                className="col-md-3 m-auto"
                onClick={this.handleSubmit}
              >
                {this.props.dictionary["ModalForAddModeratorComment.Sacuvaj"]}
              </button>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  createModeratorReply,
  setMessage,
  getCommentListForDocumentAndUser,
})(ModalForAddModeratorComment);
