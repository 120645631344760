import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { getAllModerators } from "../../actions/userActions";
import {
  createOgpModerator,
  getAllOgpModerator,
} from "../../actions/ogpModeratorActions";
import OgpModeratorTable from "./OgpModeratorTable";
import authorizationService from "../../securityUtils/authorizationService";

class ModalForAddOGPModerator extends React.Component {
  componentDidMount() {
    this.props.getAllModerators();
    this.props.getAllOgpModerator(this.props.ogp.id);
  }

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    const { usersModerators } = this.props.user;
    let ogpModeratorList = [...this.props.ogpModerator.ogpModeratorList];

    const availableUsers = usersModerators.filter(
      (u) => !ogpModeratorList.find((tm) => tm.user.id === u.id)
    );
    let isOgpAdmin = authorizationService.canAccessOGPPageSpecificAreas(
      this.props.authRole
    )
      ? true
      : false;

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body style={{ minHeight: "48vh" }}>
            <div className="form-group mt-4">
              <OgpModeratorTable
                usersModerators={availableUsers}
                ogpModeratorList={ogpModeratorList}
                createOgpModerator={this.props.createOgpModerator}
                ogp={this.props.ogp}
                isOgpAdmin={isOgpAdmin}
              />
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

ModalForAddOGPModerator.propTypes = {
  createOgpModerator: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  ogpModerator: state.ogpModerator,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  createOgpModerator,
  getAllModerators,
  getAllOgpModerator,
})(ModalForAddOGPModerator);
