import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import './App.css';
import './style/Footer.css';
import './style/Header.css';
import './style/Buttons.css';
import './style/Dashboard.css';
import './style/ViewAttachedPDF.css';
import './style/Comment.css';
import './style/Table.css';
import './style/ComboBox.css';
import './style/Input.css';
import './style/Alert.css';
import './style/CommentFilter.css';
import './style/Timeline.css';
import './style/OGPPage.css';
import './style/Modal.css';
import './style/GeneralPostCard.css';
import "./style/WelcomePage.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-notifications/lib/notifications.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style/Question.css';
import './style/Method.css';
import './style/TopicOfDiscussionInformations.css';
import store from './store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import ScrollToTop from 'react-scroll-to-top';
import Dashboard from './components/Dashboard/Dashboard';
import UserList from './components/User/UserList';
import FieldOfInterestList from './components/FieldOfInterest/FieldOfInterestList';
import AddOgpFieldOfInterest from './components/OgpFieldOfInterest/AddOgpFieldOfInterest';
import OgpFieldOfInterestList from './components/OgpFieldOfInterest/OgpFieldOfInterestList';
import UpdateOgpFieldOfInterest from './components/OgpFieldOfInterest/UpdateOgpFieldOfInterest';
import CommentList from './components/Comment/CommentList';
import InstitutionList from './components/Institution/InstitutionList';
import DictionaryUtils from './components/DictionaryUtils/DictionaryUtils';
import RoleList from './components/Role/RoleList';
import AddRole from './components/Role/AddRole';
import UpdateRole from './components/Role/UpdateRole';
import OGPPage from './components/Ogp/OGPPage';
import AddOgp from './components/Ogp/AddOgp';
import UpdateOgp from './components/Ogp/UpdateOgp';
import UpdateSurveyQuestions from './components/SurveyQuestions/UpdateSurveyQuestions';
import AddSurveyQuestions from './components/SurveyQuestions/AddSurveyQuestions';
import SurveyAnswersList from './components/SurveyAnswers/SurveyAnswersList';
import TopicOfDiscussionPage from './components/TopicOfDiscussion/TopicOfDiscussionPage';
import SigninRedirectPage from './components/SigninRedirectPage/SigninRedirectPage';

import ViewAttachment from './components/Reusable/ViewAttachment';
import ViewAttachedPdfDocumentWithHighlights from './components/Reusable/ViewAttachedPdfDocumentWithHighlights';
import setJWTToken from '../src/securityUtils/setJWTToken';
import { SET_LOGGED_USER,LOG_OUT_USER} from './actions/types';
import AuthService from './securityUtils/AuthService';
import NotificationList from './components/Notification/NotificationList';
import AddUserOrganization from './components/UserOrganization/AddUserOrganization';
import UserOrganizationList from './components/UserOrganization/UserOrganizationList';
import UpdateUserOrganization from './components/UserOrganization/UpdateUserOrganization';
import ViewDocument from './components/Reusable/ViewDocument';
import OGPArchiveList from './components/OGPArchive/OGPArchiveList';
import UserOrganizationCategoryList from './components/UserOrganizationCategory/UserOrganizationCategoryList';
import AddUserOrganizationCategory from './components/UserOrganizationCategory/AddUserOrganizationCategory';
import UpdateUserOrganizationCategory from './components/UserOrganizationCategory/UpdateUserOrganizationCategory';
import MailSettingsList from './components/MailSettings/MailSettingsList';
import AddMailSettings from './components/MailSettings/AddMailSettings';
import UpdateMailSettings from './components/MailSettings/UpdateMailSettings';
import OGPDescriptionList from './components/OGPDescription/OGPDescriptionList';
import UploadActionPlanReport from './components/ActionPlanReport/UploadActionPlanReport';
import UploadActionPlan from './components/ActionPlan/UploadActionPlan';
import UploadOgpReportFile from './components/OgpReport/UploadOgpReportFile';
import UploadOgpActionPlanArchiveFile from './components/OgpActionPlanArchive/UploadOgpActionPlanArchiveFile';
import SecuredRouteAdmin from './securityUtils/secureRouteAdmin';
import SecureRouteForHiddenTopic from './securityUtils/secureRouteForHiddenTopic';
import SecuredRouteOgp from './securityUtils/secureRouteOgp';
import SecuredRouteModerator from './securityUtils/secureRouteModerator';
import AdList from './components/Ad/AdList';
import UploadOgpDocument from './components/OgpDocument/UploadOgpDocument';
import OGPCommentList from './components/OGPComment/OGPCommentList';
import UploadAdDocument from './components/AdDocument/UploadAdDocument';
import SecureRouteModeratorAndEvaluator from './securityUtils/secureRouteModeratorAndEvaluator';
import ModeratorResourcesList from './components/ModeratorResources/ModeratorResourcesList';
import UploadModeratorResources from './components/ModeratorResources/UploadModeratorResources';
import SecuredRouteEvaluator from './securityUtils/secureRouteEvaluator';
import LandingPage from './components/Layout/LandingPage';
import SecuredRoute from './securityUtils/securedRoute';
import IndicatorsPage from './components/Indicators/IndicatorsPage';
import RoadOfActAdoption from './components/RoadOfActAdoption/RoadOfActAdoption';
import { sessionStorageTokenKey } from './constants';
import { tokenExpirationCheck } from './securityUtils/tokenData';
import {  NotificationManager,  NotificationContainer,} from 'react-notifications';
import { CompatRoute } from 'react-router-dom-v5-compat';
import { getDictionary } from './actions/startUpActions';
import { getTaxonomies } from './actions/startUpActions';
import { getUserRolesAndOptionallyCreateCitizen } from './actions/userActions';
import { setRoleOfAuthUser } from './actions/securityActions';
import ErrorList from './components/ErrorLog/ErrorList';
import DownloadDocument from './components/Document/DownloadDocument';
import setAuthUserRole from './securityUtils/setAuthUserRole';

const token = sessionStorage[sessionStorageTokenKey];

class App extends Component {
  componentWillReceiveProps() {
    if (tokenExpirationCheck()) {
      
      if (this.setForLogout==false && this.props.authRole !== 0 && Object.keys(this.props.authUser).length != 0) {
        NotificationManager.error('Токен је истекао', 'Грешка', 5000);
        AuthService.logout();
        this.setForLogout = true;
      }

    }
  }
  setForLogout = false;
  constructor() {
    super();
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    trackPromise(this.props.getDictionary().then(this.props.getTaxonomies())).finally(() => {
      if (token) {
        AuthService.getUser()
          .then((user) => {
            store.dispatch({
              type: SET_LOGGED_USER,
              payload: user,
            });
            const citizen = {
              email: user.profile['http://schema.id.rs/claims/mail'],
              firstName: user.profile['http://schema.id.rs/claims/givenname'],
              jmbg: user.profile['http://schema.id.rs/claims/umcn'],
              lastName: user.profile['http://schema.id.rs/claims/familyname'],
              };
               this.props.getUserRolesAndOptionallyCreateCitizen(citizen);
            setJWTToken(user.access_token);
          })
      }
    this.setState({ isLoading: false });
    const script = document.createElement("script");
    script.src = "/assets/js/eupravaApp.js";
    script.async = true;
    document.body.appendChild(script);
  });
  }

  componentDidUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname && Object.keys(nextProps.authUser).length != 0) {
      this.tokenRenewalHandler();
    }
    if(nextProps.authRole!=this.props.authRole && this.props.authRole!=0 && Object.keys(this.props.authUser).length != 0) {
        setAuthUserRole(this.props.authRole);
        this.props.setRoleOfAuthUser(this.props.authRole, this.props.authUser);
    }
  }

  tokenRenewalHandler = () => {
    AuthService.renewToken();
  };

  render() {
    const { isLoading } = this.state;
    let isUserLoggedIn = Object.keys(this.props.authUser).length != 0;
    if (isLoading) {
      return <></>;
    }
    return (
      <>
      <div>
        <Header history={this.props.history}/>
        <div className="App content-min-height m-auto">
          <Switch>
            {isUserLoggedIn ? (
                <SecuredRoute exact path="/" component={Dashboard} />
            ) : (
                <Route exact path="/" component={LandingPage} />
            )}
          </Switch>
          <SecuredRoute exact path="/dashboard" component={Dashboard} />
          <Switch>
            <CompatRoute exact path="/signin-oidc" component={SigninRedirectPage} />
            <CompatRoute exact path="/signout-callback-oidc" component={LandingPage}/>
            <SecuredRouteAdmin exact path="/userList" component={UserList} />
            <SecuredRouteAdmin exact path="/errorList" component={ErrorList} />
            <SecuredRouteAdmin exact path="/addMailSettings" component={AddMailSettings}/>
            <SecuredRouteAdmin exact path="/mailSettingsList" component={MailSettingsList}/>
            <SecuredRouteAdmin exact path="/updateMailSettings/:id" component={UpdateMailSettings}/>
            <SecuredRouteAdmin exact path="/addUserOrganizationCategory" component={AddUserOrganizationCategory}/>
            <SecuredRouteAdmin exact path="/userOrganizationCategoryList" component={UserOrganizationCategoryList}/>
            <SecuredRouteAdmin exact path="/updateUserOrganizationCategory/:id" component={UpdateUserOrganizationCategory}/>
            <CompatRoute exact path="/addUserOrganization" component={AddUserOrganization}/>
            <SecuredRouteOgp exact path="/uploadActionPlanReport" component={UploadActionPlanReport}/>
            <CompatRoute exact path="/userOrganizationList" component={UserOrganizationList}/>
            <CompatRoute exact path="/updateUserOrganization/:id" component={UpdateUserOrganization}/>
            <SecuredRouteAdmin exact path="/userOrganizationAdminList/:userId" component={UserOrganizationList}/>
            <SecuredRouteAdmin
              exact
              path="/updateUserOrganizationAdmin/:userId/:id"
              component={UpdateUserOrganization}
            />
            <SecuredRouteAdmin
              exact
              path="/fieldOfInterestList"
              component={FieldOfInterestList}
            />
            <SecuredRouteOgp
              exact
              path="/addOgpFieldOfInterest"
              component={AddOgpFieldOfInterest}
            />
            <SecuredRouteOgp
              exact
              path="/ogpFieldOfInterestList"
              component={OgpFieldOfInterestList}
            />
            <SecuredRouteOgp
              exact
              path="/updateogpFieldOfInterest/:id"
              component={UpdateOgpFieldOfInterest}
            />

            <SecuredRouteModerator
              exact
              path="/addSurveyQuestions/:id/:topicOfDiscussionId/:phaseNumber"
              component={AddSurveyQuestions}
            />


            <SecuredRouteModerator
              exact
              path="/updateSurveyQuestions/:id/:topicOfDiscussionId/:phaseNumber/:surid"
              component={UpdateSurveyQuestions}
            />

            <SecuredRouteModerator
              exact
              path="/surveyAnswersList/:id/:surveyId/:topicOfDiscussionId/:phaseNumber/:questionTypeId"
              component={SurveyAnswersList}
            />

            <SecuredRouteModerator
              exact
              path="/addSurveyQuestions/:id"
              component={AddSurveyQuestions}
            />
            <SecuredRouteModerator
              exact
              path="/updateSurveyQuestions/:surid/:id"
              component={UpdateSurveyQuestions}
            />

            <SecureRouteModeratorAndEvaluator
              exact
              path="/commentListModerator/:id"
              component={CommentList}
            />

            <SecuredRouteAdmin exact path="/roleList" component={RoleList} />

            <SecuredRouteAdmin exact path="/addRole" component={AddRole} />

            <SecuredRouteAdmin
              exact
              path="/updateRole/:id"
              component={UpdateRole}
            />

            <SecuredRouteAdmin
              exact
              path="/institutionList"
              component={InstitutionList}
            />
            <SecuredRouteAdmin
              exact
              path="/dictionaryUtils"
              component={DictionaryUtils}
            />
            <SecureRouteForHiddenTopic
              exact
              path="/topicOfDiscussionPage/:id/:phaseId"
              component={TopicOfDiscussionPage}
            />
            <SecuredRouteOgp
              exact
              path="/uploadOgpReportFile"
              component={UploadOgpReportFile}
            />
            <SecuredRouteOgp
              exact
              path="/uploadOgpActionPlanArchiveFile"
              component={UploadOgpActionPlanArchiveFile}
            />
            <CompatRoute
              exact
              path="/viewAttachment/:documentName/:controllerReference/:topicOfDiscussionId/:phaseNumber"
              component={ViewAttachment}
            />
            <CompatRoute
              exact
              path="/viewDocument/:documentName/:controllerReference"
              component={ViewDocument}
            />
            <CompatRoute
              exact
              path="/viewPdfAttachment/:documentName/:controllerReference/:topicOfDiscussionId/:phaseNumber/:eventId/:phaseActiveStatus"
              component={ViewAttachedPdfDocumentWithHighlights}
            />
            <CompatRoute
              exact
              path="/viewPdfAttachment/:documentName/:controllerReference/:ogpPhase"
              component={ViewAttachedPdfDocumentWithHighlights}
            />

            <CompatRoute exact path="/ogpPage/:currentSection" component={OGPPage} />
            <CompatRoute exact path="/addOgp" component={AddOgp} />
            <CompatRoute exact path="/updateOgp/:id" component={UpdateOgp} />

            <SecuredRouteOgp exact path="/adList" component={AdList} />


            <CompatRoute
              exact
              path="/notificationList/:id"
              component={NotificationList}
            />

            <CompatRoute
              exact
              path="/ogpArchiveList/:ogpId"
              component={OGPArchiveList}
            />

            <SecuredRouteOgp
              exact
              path="/ogpDescriptionList"
              component={OGPDescriptionList}
            />

            <SecuredRouteOgp
              exact
              path="/uploadActionPlan"
              component={UploadActionPlan}
            />

            <SecuredRouteOgp
              exact
              path="/uploadOgpDocument"
              component={UploadOgpDocument}
            />

            <SecuredRouteOgp
              exact
              path="/ogpCommentList"
              component={OGPCommentList}
            />

            <SecuredRouteOgp
              exact
              path="/uploadAdDocument/:adId"
              component={UploadAdDocument}
            />
            <SecureRouteModeratorAndEvaluator
              exact
              path="/moderatorResourcesList"
              component={ModeratorResourcesList}
            />
            <SecuredRouteEvaluator
              exact
              path="/uploadModeratorResources/:documentTypeId"
              component={UploadModeratorResources}
            />
          </Switch>
          <SecuredRouteEvaluator
            exact
            path="/indicatorsPage"
            component={IndicatorsPage}
          />
          <Route exact path="/putusvajanjaakta" component={RoadOfActAdoption} />
          <CompatRoute exact path="/document/:uuidDocumentName" component={DownloadDocument} />
        </div>
        <Footer />
        <ScrollToTop color="white" className="scroll-button-style" smooth component={<i style={{ color: "white", fontSize:"35px" }} className="fas fa-arrow-up"></i>}/>

        <NotificationContainer />
      </div>
      <script src="/assets/js/eUpravaApp.js"></script>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
  authUser: state.security.authUser,
  authRole: state.security.authRole,
  userRoles: state.security.userRoles,
  userId: state.security.userId,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps,{
  getDictionary,
  getTaxonomies,
  getUserRolesAndOptionallyCreateCitizen,
  setRoleOfAuthUser,
  setAuthUserRole
}) (App);
