import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getNotifications,
  setSeenByCitizen,
} from '../../actions/notificationActions';
import PropTypes from 'prop-types';
import NotificationTable from './NotificationTable';
import _ from 'lodash';

class NotificationList extends Component {
  constructor() {
    super();
  }
  countNotifications = (
    notificationList,
    notificationListWithoutDuplicates
  ) => {
    for (let i = 0; i < notificationListWithoutDuplicates.length; i++) {
      notificationListWithoutDuplicates[i].numberOfNotificationsPerTopicOfDiscussion = notificationList.filter(x=>x.topicOfDiscussion.id==notificationListWithoutDuplicates[i].topicOfDiscussion.id).length;
    
    }
    return notificationListWithoutDuplicates;
  };

  render() {
    const{handleShowModalForMethodComments}=this.props;
    const { notificationList } = this.props.notification;

    let notificationListWithoutDuplicates = _.uniqBy(
      notificationList,
      (obj) => obj.topicOfDiscussion.id
    );
    notificationListWithoutDuplicates = this.countNotifications(
      notificationList,
      notificationListWithoutDuplicates
    );

    const listScroller= {
      overflow: 'auto',
      height:'70vh'
    }

    return notificationList.length != 0 ? (
      <div style={listScroller} className='p-3'>
        <p className="medium-font">
          <em>{this.props.dictionary["menu.Profil.NotificationsForCommentsExplanation"]}</em>
        </p>
        <NotificationTable
          notificationList={
            this.props.authRole == '2'
              ? notificationListWithoutDuplicates
              : notificationList
          }
          quickPreviewSignal={this.props.quickPreviewSignal}
          setSeenByCitizen={this.props.setSeenByCitizen}
          handleShowModalForMethodComments={handleShowModalForMethodComments}
        />
      </div>
    ) : (
      <div style={listScroller} className='p-3'>
        <p>{this.props.dictionary["menu.Profil.NoNewCommentNotifications"]}</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  notification: state.notification,
});

export default connect(mapStateToProps, { getNotifications, setSeenByCitizen })(
  NotificationList
);
