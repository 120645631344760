import {
  GET_TOPIC_OF_DISCUSSION,
  GET_IS_TOPIC_OF_DISCUSSION_OPEN_FOR_COMMENTS,
  GET_TOPIC_OF_DISCUSSION_LIST,
  GET_TOPIC_OF_DISCUSSION_LIST_FILTERABLE,
  GET_OTHER_TOPIC_OF_DISCUSSION_LIST_FILTERABLE,
  GET_TOPIC_OF_DISCUSSION_WELCOME_PAGE,
  GET_TOPIC_OF_DISCUSSION_LIST_PAGEABLE,
  DELETE_TOPIC_OF_DISCUSSION,
  CREATE_TOPIC_OF_DISCUSSION,
  UPDATE_TOPIC_OF_DISCUSSION,
  GET_TOPIC_OF_DISCUSSION_LIST_DTO,
  GET_TOPIC_OF_DISCUSSION_CURRENT_CONSULTATIONS_AND_DEBATES_LIST_DTO,
  GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_LIST_DTO,
  GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_AND_NOTIFICATION_LIST_DTO,
  GET_ALL_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST_LIST_BY_USER_ID,
  GET_ALL_TOPIC_OF_DISCUSSION_INSTITUTION_LIST_BY_USER_ID,
  GET_TOPICS_OF_DISCUSSION_BY_USER_ID,
  GET_TOPIC_OF_DISCUSSION_LIST_BY_FIELD_OF_INTEREST,
  GET_TOPIC_OF_DISCUSSION_MODERATOR_DTO,
  SET_TOPIC_OF_DISCUSSION_TABLE_FILTER,
  SET_TOPIC_OF_DISCUSSION_TABLE_SCROLLYPOSITION,
  GET_IS_TOPIC_OF_DISCUSSION_DOCUMENTS,
  GET_FINISHED_TOPIC_OF_DISCUSSION,
  GET_HIDDEN_TOPIC_OF_DISCUSSION,
  GET_IS_TOPIC_OF_DISCUSSION_HIDDEN
} from "../actions/types";

const initialState = {
  topicOfDiscussionList: [],
  topicOfDiscussion: {},
  topicOfdiscussionMaxId: {},
  topicOfDiscussionListDTO: [],
  topicOfDiscussionUserActivityAndNotificationListDTO: [],
  topicOfDiscussionCurrentConsultationsAndDebatesListDTO: [],
  topicOfDiscussionUserActivityListDTO: [],
  topicsOfDiscusionByFieldAndUser: [],
  topicsOfDiscusionByInstitutionAndUser: [],
  topicsOfDiscussionByUserId: [],
  topicsOfDiscussionModeratorsDTO: {},
  topicOfDiscussionListPageable: [],
  totalElements: "",
  topicOfDiscussionDocuments: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TOPIC_OF_DISCUSSION:
      return {
        ...state,
        topicOfDiscussion: action.payload,
      };
      case GET_FINISHED_TOPIC_OF_DISCUSSION:
        return {
          ...state,
          topicOfDiscussion: action.payload,
        };
      case GET_HIDDEN_TOPIC_OF_DISCUSSION:
        return {
          ...state,
          topicOfDiscussion: action.payload,
        };
    case GET_IS_TOPIC_OF_DISCUSSION_OPEN_FOR_COMMENTS:
      return {
        ...state,
        isTopicOfDiscussionOpenForComments: action.payload
      };
    case GET_TOPIC_OF_DISCUSSION_LIST:
      return {
        ...state,
        topicOfDiscussionList: action.payload,
      };
    case GET_TOPIC_OF_DISCUSSION_LIST_FILTERABLE:
      return {
        ...state,
        topicOfDiscussionListFilterable: {
          ...action.payload,
          content: action.payload.content.map((topic) => ({
            ...topic,
            participants:
              topic.participants == null ? [] : topic.participants.split("#"),
          })),
        },
      };
      case GET_OTHER_TOPIC_OF_DISCUSSION_LIST_FILTERABLE:
        return {
          ...state,
          otherTopicOfDiscussionListFilterable: {
            ...action.payload,
            content: action.payload.content.map((topic) => ({
              ...topic,
              participants:
                topic.participants == null ? [] : topic.participants.split("#"),
            })),
          },
        };
    case GET_TOPIC_OF_DISCUSSION_WELCOME_PAGE:
      return {
        ...state,
        topicOfDiscussionListWelcomePage: {
          ...action.payload,
          content: action.payload.content.map((topic) => ({
            ...topic,
            participants:
              topic.participants == null ? [] : topic.participants.split("#"),
          })),
        },
      };
    case GET_TOPIC_OF_DISCUSSION_LIST_BY_FIELD_OF_INTEREST:
      return {
        ...state,
        topicOfDiscussionList: action.payload,
      };
    case GET_TOPIC_OF_DISCUSSION_LIST_PAGEABLE:
      return {
        ...state,
        topicOfDiscussionListPageable: action.payload[0],
        numberOfPages: action.payload[1],
        totalElements: action.payload[2],
      };

    case DELETE_TOPIC_OF_DISCUSSION:
      return {
        ...state,
        topicOfDiscussionList: action.payload,
      };
    case CREATE_TOPIC_OF_DISCUSSION:
      return {
        ...state,
        topicOfDiscussionList: state.topicOfDiscussionList
          .concat(action.payload)
          .reverse(),
        topicOfDiscussionMaxId: action.payload.id,
      };
    case GET_TOPIC_OF_DISCUSSION_LIST_DTO:
      return {
        ...state,
        topicOfDiscussionListDTO: action.payload,
      };
    case GET_ALL_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST_LIST_BY_USER_ID:
      return {
        ...state,
        topicsOfDiscusionByFieldAndUser: action.payload,
      };
    case GET_ALL_TOPIC_OF_DISCUSSION_INSTITUTION_LIST_BY_USER_ID:
      return {
        ...state,
        topicsOfDiscusionByInstitutionAndUser: action.payload,
      };
    case GET_TOPIC_OF_DISCUSSION_CURRENT_CONSULTATIONS_AND_DEBATES_LIST_DTO:
      return {
        ...state,
        topicOfDiscussionCurrentConsultationsAndDebatesListDTO: action.payload,
      };
    case GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_LIST_DTO:
      return {
        ...state,
        topicOfDiscussionUserActivityListDTO: action.payload,
      };
    case GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_AND_NOTIFICATION_LIST_DTO:
        return {
          ...state,
          topicOfDiscussionUserActivityAndNotificationListDTO: action.payload,
        };
    case GET_TOPICS_OF_DISCUSSION_BY_USER_ID:
      return {
        ...state,
        topicsOfDiscussionByUserId: action.payload,
      };

    case GET_TOPIC_OF_DISCUSSION_MODERATOR_DTO:
      return {
        ...state,
        topicsOfDiscussionModeratorsDTO: action.payload,
      };

    case UPDATE_TOPIC_OF_DISCUSSION:
      return {
        ...state,
        topicOfDiscussion: action.payload,
      };
    case SET_TOPIC_OF_DISCUSSION_TABLE_FILTER:
      return {
        ...state,
        topicOfDiscussionTableFilter: action.payload,
      };
    case SET_TOPIC_OF_DISCUSSION_TABLE_SCROLLYPOSITION:
      return {
        ...state,
        topicOfDiscussionTableScrollYPosition: action.payload
      }
    case GET_IS_TOPIC_OF_DISCUSSION_DOCUMENTS:
      return {
        ...state,
        topicOfDiscussionDocuments: action.payload,
      };
    case GET_IS_TOPIC_OF_DISCUSSION_HIDDEN:
      return {
        ...state,
        isTopicOfDiscussionHidden: action.payload
      }
    default:
      return state;
  }
}
