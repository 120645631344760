import React, { Component } from 'react';
import { connect } from 'react-redux';
import authorizationService from '../../securityUtils/authorizationService';
class DictionarySegment extends Component{
    render(){   
        const{
            sectionName,
            sectionCode,
            sectionFileNameForDownload,
            sectionDownloadControlId,
            messageControlId,
            sectionConcreteFileName,
            elementForFileChooseId,
            elementForMessageId,
            selectedFileForUpload,
            uploadFiles,
            downloadFiles,
            onFileChange,
        } = this.props || {};
        return <div className="table-responsive mt-5">
            <h5 className="textShadow">{sectionName}</h5>
            {authorizationService.canAccessAdminPanel(this.props.authRole) && ( 
            <>
                <button className="create-button" onClick={(e)=>downloadFiles(e,sectionCode, sectionFileNameForDownload)}>{this.props.dictionary["dictionary.Preuzmi"]}</button>
                <label id="inputFileRemoveDefault" className="create-button m-2 fas fa-upload" >
                    <input id={sectionDownloadControlId} type="file" className="buttonCreateUpdate" onChange={(e)=>onFileChange(e,sectionCode)} />
                </label>
                {sectionConcreteFileName}
                <button className="create-button float-right" onClick={(e)=>uploadFiles(e,sectionCode, elementForFileChooseId,    elementForMessageId, selectedFileForUpload)}>{this.props.dictionary["dictionary.Azuriraj"]}</button>
            </>
            ) } 
            <h6 id={messageControlId} className="textShadow"></h6>
            </div>
        }
}

const mapStateToProps = (state) => ({
      authRole: state.security.authRole,
      dictionary:state.startUp.dictionary,
  });

export default connect(mapStateToProps,{}) (DictionarySegment);