import React, { Component } from "react";
import { connect } from "react-redux";
import { getOGPDescriptionList } from "../../actions/ogpDescriptionActions";
import PropTypes from "prop-types";
import OGPDescriptionTable from "./OGPDescriptionTable";

class OGPDescriptionList extends Component {
  componentDidMount() {
    this.props.getOGPDescriptionList();
  }

  render() {
    const { ogpDescriptionList } = this.props.ogpDescription;
    return (
      <div className="container-fluid">
        <br></br>
        <div className="row col-md-12 m-auto">
          <div className="col-md-11 m-auto">
            <div className="text-left mb-2">
              <h2 className="textShadow text-center">
                {this.props.dictionary["OGPDescriptionList.ArhivaPOU"]}
              </h2>
              <br></br>
              <OGPDescriptionTable ogpDescriptionList={ogpDescriptionList} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OGPDescriptionList.propTypes = {
  ogpDescription: PropTypes.object.isRequired,
  getOGPDescriptionList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ogpDescription: state.ogpDescription,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, { getOGPDescriptionList })(
  OGPDescriptionList
);
