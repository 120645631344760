import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getAllQuestionResults } from '../../actions/surveyQuestionsActions';
import SurveyQuestionResults from './SurveyQuestionResults';
import ModalForOpenAnswersView from './ModalForOpenAnswersView';
import authorizationService from '../../securityUtils/authorizationService';
import { Link } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import { exportSurveyResults } from '../../actions/surveyQuestionsActions';
import LoadingIndicatorOverlay from "../Reusable/LoadingIndicatorOverlay";

import {
    NotificationManager,
  } from "react-notifications";
  import FileDownloadIcon from '@mui/icons-material/FileDownload';

const ModalForSurveyResultsView = (props) => {

    const dispatch = useDispatch();
    const dictionary = useSelector((store) => store.startUp.dictionary)

    const questions = useSelector((store) => store.surveyQuestions.surveyQuestionsList);
    const [showModalForOpenAnswersView, setShowModalForOpenAnswersView] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [questionToViewAnswers, setQuestionToViewAnswers] = useState({});

    const openModalForOpenAnswersView = (question) => {
        setQuestionToViewAnswers(question);
        setShowModalForOpenAnswersView(true);
    }

    const handleExport = () => {
        setIsLoading(true);
        trackPromise(dispatch(exportSurveyResults(dictionary["ModalForSurveyResultsView.RezultatiAnkete"], props.surveyId, props.surveyName)))
        .then(()=> {
            NotificationManager.success(dictionary["ModalForSurveyResultsView.UspesanIzvozPodataka"],dictionary["universal.Uspesno"])
          })
          .catch(() => {
            NotificationManager.error(dictionary["ModalForSurveyResultsView.NeuspesanIzvozPodataka"],dictionary["universal.GreskaUObradi"])
          })
          .finally(() => {
            setIsLoading(false);
          });;
    }


    return (
        <>
        {isLoading && <LoadingIndicatorOverlay />}
        <Modal
        show={props.show}
        onHide={props.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        id="modalForSurveyResultsView"
        centered
      >
            <Modal.Header closeButton className='modal-scroll'>
                <h4 className='pe-2'>{dictionary["ModalForSurveyResultsView.PregledRezultataAnkete"]}:</h4>
                <h4>{props.surveyName}</h4>
            </Modal.Header>
            <Modal.Body className='modal-body-scroll'>
            {questions.length > 0 && authorizationService.canExportSurveyResults(
                   props.authRole
                ) && (
                    <Link className='mb-1 float-end tableFooterLinks' 
                          onClick={(e) => {
                            e.preventDefault();
                            handleExport()}} 
                            to="#">
                      <FileDownloadIcon/>
                      {dictionary["ModalForSurveyResultsView.IzvozRezultataAnkete"]}
                  </Link>
                )}
            {questions?.map((question, index) => {
               return  <SurveyQuestionResults question={question} index={index} key={question.id} openModalForOpenAnswersView = {openModalForOpenAnswersView}/>
            })}</Modal.Body>
        </Modal>
        <ModalForOpenAnswersView
            show={showModalForOpenAnswersView}
            handleClose={()=> {
                setShowModalForOpenAnswersView(false);
            }}
            question ={questionToViewAnswers}
        />                
        </>
    )
}

export default ModalForSurveyResultsView;
  