import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DatePickerInput } from '@mantine/dates';
import CommentSort from './CommentSort';

const CommentFilterDateRange = ({filterId, columnFilters, setColumnFilters, sorting, setSorting}) => {
    const dictionary = useSelector(state => state.startUp.dictionary);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const onChangeHandler = (date, id, datePicker) => {
        const oldFilter = columnFilters.filter(x => x.id != id);
        
        if (datePicker == 'from') {
            setDateFrom(date)
        }
        if (datePicker == 'to') {
            setDateTo(date)
        }
        if(date != null) {
        setColumnFilters([
            ...oldFilter,
            {
                id: id,    
                value: date
            }
        ]);
        }   else {
            setColumnFilters([
                ...oldFilter
            ]);
        }
    }

    return (
        <div>
            <div>
                <label className="pe-2">{dictionary[`CommentFilterDateRange.${filterId}`]}</label>
                <CommentSort filterId={filterId} sorting={sorting} setSorting={setSorting}/>
            </div>
            <div>
            <DatePickerInput
                id={`${filterId}From`}
                clearable
                value={dateFrom}
                onChange={(date) => {onChangeHandler(date, `${filterId}From`, 'from')}}
                className="comment-date-picker"
                valueFormat="DD.MM.YYYY."
                placeholder={dictionary["CommentFilterDateRange.Placeholder.Min"]}
            />
            <DatePickerInput
                id={`${filterId}To`}
                clearable
                value={dateTo}
                onChange={(date) => {onChangeHandler(date, `${filterId}To`, 'to')}}
                className="comment-date-picker"
                valueFormat="DD.MM.YYYY."
                placeholder={dictionary["CommentFilterDateRange.Placeholder.Max"]}
                />
            </div>
        </div>
    );
}

export default CommentFilterDateRange;