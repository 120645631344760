import React from "react";

const UnauthorizedAccess = () => {
  return (
    <div className="unauthorized-access-message">
      <h1>Немате право приступа овој страници!</h1>
    </div>
  );
};

export default UnauthorizedAccess;
