
import { IconArrowLeft  } from '@tabler/icons-react';
import { IconArrowRight } from '@tabler/icons-react';
import { IconArrowBarToLeft } from '@tabler/icons-react';
import { IconArrowBarToRight } from '@tabler/icons-react';
import { useSelector } from "react-redux";
import Select from "react-select";

const CommentDisplayPagination = ({pagination, setPagination}) => {
    const dictionary = useSelector(state => state.startUp.dictionary);
    const options = [{value: 5, label: '5'}, {value: 10, label: '10'}, {value: 15, label: '15'}, {value: 20, label: '20'}];
    const pageable = useSelector(state => state?.comment?.commentListOnDocument?.pageable);
    const commentListOnDocument = useSelector(state => state?.comment?.commentListOnDocument);

    const navigateForward = (lastPage) => {
        if (lastPage >= 0) {
            setPagination({
                ...pagination,
                pageIndex: lastPage
                })
        } else {
            setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex + 1
                })
            }
    }

    const navigateBack = (firstPage) => {
        if (firstPage >= 0) {
            setPagination({
                ...pagination,
                pageIndex: firstPage
            })
        }
        else {
            setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex - 1
            })
        }
    }

    const handleChangePageSize = (event) => {
        if (event.value != pagination.pageSize) {
            setPagination({
                pageIndex: 0,
                pageSize: event.value
            })
        }

    }
    const selectStyles = {
        control: (styles) => ({ ...styles, minHeight: "45px" }),
        option: (styles) => ({
          ...styles,
          color: "black",
          fontSize: "17px",
        })
      };
    return (
        <div className="d-flex flex-row justify-content-end comment-display-pagination align-items-center" >
            <label className="pe-2">{dictionary["CommentDisplayPagination.RowsPerPage"]}</label>
            <Select
                    id="pagination"
                    className="pe-2 select-page-size"
                    name="pagination"
                    options={options}
                    styles={selectStyles}
                    onChange={handleChangePageSize}
                    defaultValue={options.find(option => option.value === pagination.pageSize)}
                    menuPosition="fixed"
                /> 
            {pageable && <>
            <span className="pe-2">
                {pageable.pageNumber * pageable.pageSize + (commentListOnDocument.numberOfElements == 0 ? 0 : 1)} - {pageable.pageNumber * pageable.pageSize + commentListOnDocument.numberOfElements} {dictionary["CommentDisplayPagination.From"]} {commentListOnDocument.totalElements}
            </span>
            <IconArrowBarToLeft size="34" stroke="1.5" className='me-2 pointer' onClick={() => navigateBack(0)}/>
            {pageable.pageNumber != 0 ?  <IconArrowLeft className='pointer me-2' onClick={() => navigateBack()}/> : <IconArrowLeft className="me-2"/> }
            {(pageable.pageNumber + 1 != commentListOnDocument.totalPages) ? <IconArrowRight className='pointer me-2' onClick={() => navigateForward()}/> : <IconArrowRight className="me-2"/>}
            <IconArrowBarToRight size="34" stroke="1.5" className='pointer' onClick={() => navigateForward(commentListOnDocument.totalPages - 1)}/>
            </>}
        </div>  
    );
}

export default CommentDisplayPagination;