import axios from "axios";

import {
  GET_ERRORS,
  GET_OGP_DESCRIPTION,
  GET_OGP_DESCRIPTION_LIST,
  CREATE_OGP_DESCRIPTION,
} from "./types";

export const createOGPDescription = (ogpDescription) => async (dispatch) => {
  try {
    const res = await axios.post("/api/ogpDescription/create", ogpDescription);
    dispatch({
      type: CREATE_OGP_DESCRIPTION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOGPDescriptionList = () => async (dispatch) => {
  const res = await axios.get(`/api/ogpDescription/findAll`);
  dispatch({
    type: GET_OGP_DESCRIPTION_LIST,
    payload: res.data,
  });
};

export const getOGPDescription = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpDescription/findById/${id}`);
    dispatch({
      type: GET_OGP_DESCRIPTION,
      payload: res.data,
    });
  } catch (e) {}
};
