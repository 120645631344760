import axios from "axios";
import {
  GET_ERRORS,
  GET_OGP_STEP,
  GET_OGP_STEP_LIST,
  DELETE_OGP_STEP,
  CREATE_OGP_STEP,
  UPDATE_OGP_STEP,
} from "./types";

export const createOGPStep = (ogpStep) => async (dispatch) => {
  try {
    const res = await axios.post("/api/ogpStep/create", ogpStep);
    dispatch({
      type: CREATE_OGP_STEP,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateOGPStep = (ogpStep) => async (dispatch) => {
  try {
    const res = await axios.post("/api/ogpStep/update", ogpStep);
    dispatch({
      type: UPDATE_OGP_STEP,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOGPStepList = (id) => async (dispatch) => {
  const res = await axios.get(`/api/ogpStep/findAll/${id}`);
  dispatch({
    type: GET_OGP_STEP_LIST,
    payload: res.data,
  });
};

export const getOGPStep = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpStep/findById/${id}`);
    dispatch({
      type: GET_OGP_STEP,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const deleteOGPStep = (id) => async (dispatch) => {
  await axios.delete(`/api/ogpStep/delete/${id}`);
  dispatch({
    type: DELETE_OGP_STEP,
    payload: id,
  });
};
