import React from 'react';
import { connect } from 'react-redux';
class UserModeratorsComboBox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let optionItems = this.props.usersModerators
      .filter((u) => u.id != this.props.mainModeratorId)
      .sort((a, b) =>
        a.firstName + a.lastName > b.firstName + b.lastName ? 1 : -1
      )
      .map((user) => (
        <option key={user.id} value={JSON.stringify(user)}>
          {user.firstName +
            ' ' +
            user.lastName +
            (user.institution?.institutionName ? `, ${user.institution.institutionName}` : '')
          }
        </option>
      ));

    return (
      <select
        id="usersModeratorsCb"
        className="form-control form-control-md"
        value={this.props.user}
        onChange={this.props.onChange}
        defaultValue={this.props.user ? this.props.user : '-1'}
      >
        <option key="-1" value="-1" disabled hidden>
          {this.props.dictionary["UserModeratorsComboBox.IzaberiteModeratora"]}
        </option>
        {optionItems}
      </select>
    );
  }
}
const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps, {
})(UserModeratorsComboBox);

