import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  deleteModeratorResources,
  downloadModeratorResource,
} from '../../actions/moderatorResourcesActions';
import DeleteButton from '../Reusable/DeleteButton';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Tr, Td } from 'react-super-responsive-table';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Link } from 'react-router-dom';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';

class ModeratorResourcesRow extends Component {
  constructor() {
    super();
    this.state = {
      showModeratorResourcesDocumentPreview: false,
    };
  }

  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteModeratorResources(id);
    };
    confirmDialog(this.props.dictionary["ModeratorResourcesRow.delete.confirmDialog.part1"], action, this.props.dictionary["ModeratorResourcesRow.delete.confirmDialog.part2"]);
  };

  onDownloadClick = (uuidDocName, documentName) => {
    const action = () => {
      this.props.downloadModeratorResource(uuidDocName, documentName);
    };
    confirmDialog(this.props.dictionary["ModeratorResourcesRow.download.confirmDialog.part1"], action, this.props.dictionary["ModeratorResourcesRow.downlaod.confirmDialog.part2"]);
  };

  showModeratorResourcesDocumentPreview = () => {
    this.setState({ showModeratorResourcesDocumentPreview: true });
  };
  handlePreviewClose = () => {
    this.setState({ showModeratorResourcesDocumentPreview: false });
  };

  render() {
    const { moderatorResources, isEvaluator } = this.props;

    const row = (
      <Tr>
        <Td>{moderatorResources.documentName}</Td>
        <Td className="text-center">
          <Link onClick={(e) => { e.preventDefault();this.showModeratorResourcesDocumentPreview();}} to="#">
            {this.props.dictionary["Document.Pogledaj"]}
          </Link>
        </Td>
        <Td className="text-center">
          <Link
            onClick={(e) => {
              e.preventDefault();
              this.onDownloadClick(
                moderatorResources.uuidDocName,
                moderatorResources.documentName
              )
            }}
            to="#"
          >
            <GetAppIcon className="previewButton" />
          </Link>
        </Td>
        <Td className="text-center">
          {isEvaluator === true ? (
            <DeleteButton
              click={() => this.onDeleteClick(moderatorResources.id)}
            />
          ) : (
            <Link to="#" className="deleteButton">
              <i className="far fa-trash-alt faded-text" />
            </Link>
          )}
        </Td>
      </Tr>
    );
    return (
      <Fragment>
        {row}
        {this.state.showModeratorResourcesDocumentPreview && (
          <ModalForDocumentPreview
            show={this.state.showModeratorResourcesDocumentPreview}
            documentName={moderatorResources.uuidDocName}
            controllerReference={'moderatorResourcesDocument'}
            handleClose={this.handlePreviewClose}
          />
        )}
      </Fragment>
    );
  }
}

ModeratorResourcesRow.propTypes = {
  deleteModeratorResources: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary
});

export default connect(mapStateToProps, {
  deleteModeratorResources,
  downloadModeratorResource,
})(ModeratorResourcesRow);
