import axios from "axios";
import { GET_DOCUMENT, REMOVE_DOCUMENT,  GET_ERRORS, } from "./types";

export const getDocument = (nazDok, controllerReference) => async (
  dispatch
) => {
  try{
    const res = await axios.get(`/api/${controllerReference}/find/${nazDok}`);
    dispatch({
      type: GET_DOCUMENT,
      payload: res.data,
    });
    if(res.data==""){
      dispatch({
        type: GET_ERRORS,
        payload: {
          documentName: nazDok,
          controllerReference: controllerReference,
          errMessage: "Dokument nije pronađen!",
        }
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: {
        documentName: nazDok,
        controllerReference: controllerReference,
        errMessage: err.response.data,
      }
    });
  }


};


export const getDocumentByDocumentName = (documentName) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/document/find/${documentName}`);
    if (!res.data) {
      dispatch({
        type: GET_ERRORS,
        payload: {
          documentName,
          errMessage: "Dokument nije pronađen!",
        },
      });
      throw new Error("Dokument nije pronađen!");
    }
    dispatch({
      type: GET_DOCUMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: {
        documentName,
        errMessage: err.message,
      },
    });
    throw err;
  }
};
export const downloadDocument =
  (documentName, controllerReference) => async (dispatch) => {
    axios({
      url: `/api/${controllerReference}/find/${documentName}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    });
  };




export const removeDocument = () => async (dispatch) => {
  dispatch({
    type: REMOVE_DOCUMENT,
    payload: {},
  });
};
