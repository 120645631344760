import React, { Component } from 'react';
import GoBackButton from '../Reusable/GoBackButton';

class ViewAttachedPdfDocument extends Component {
  render() {
    return (
      <div className="file-preview-wrapper-div">
        {this.props.topicOfDiscussionId ? (
          ''
          // <GoBackButton
          //   route={`/topicOfDiscussionPage/${this.props.topicOfDiscussionId}/${this.props.phaseNumber}`}
          // />
        ) : (
          ''
        )}
        <embed
          src={`data:application/pdf;base64,${this.props.content}`}
          className="default-pdf-viewer"
          type="application/pdf"
        />
      </div>
    );
  }
}

export default ViewAttachedPdfDocument;
