import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModeratorResourcesList from './ModeratorResourcesList';

export default class ModalForOtherEventsDocumentListPreview extends React.Component {
  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="xl"
        backdrop={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="wide-modal"
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <ModeratorResourcesList />
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}
