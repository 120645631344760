import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  deleteOGPDocument,
  downloadOGPDocument,
} from '../../actions/ogpDocumentActions';
import DeleteButton from '../Reusable/DeleteButton';
import authorizationService from '../../securityUtils/authorizationService';
import { Tr, Td } from 'react-super-responsive-table';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Link } from 'react-router-dom';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';

class OgpDocumentRow extends Component {
  constructor() {
    super();
    this.state = {
      showOgpDocumentPreview: false,
    };
  }

  showOgpDocumentPreview = () => {
    this.setState({ showOgpDocumentPreview: true });
  };
  handlePreviewClose = () => {
    this.setState({ showOgpDocumentPreview: false });
  };

  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteOGPDocument(id);
    };
    confirmDialog(`${this.props.dictionary["OgpDocumentRow.Obrisi.Poruka"]}`, action, `${this.props.dictionary["OgpDocumentRow.Obrisi"]}`);
  };

  onDownloadClick = (uuidDocName, documentName) => {
    const action = () => {
      this.props.downloadOGPDocument(uuidDocName, documentName);
    };
    confirmDialog(`${this.props.dictionary["OgpDocumentRow.Preuzmi.Poruka"]}`, action, `${this.props.dictionary["OgpDocumentRow.Preuzmi"]}`);
  };

  render() {
    const { ogpDocument } = this.props || {};
    const row = (
      <Tr>
        <Td id="topicUnorderedList" className="border-0">
          <div>
            <li className="default-label-text textShadow">
              {ogpDocument.documentName}
            </li>
          </div>
        </Td>
        <Td className="text-center border-0">
          <Link onClick={(e) => { e.preventDefault();this.showOgpDocumentPreview();}} to="#">
          {this.props.dictionary["Document.Pogledaj"]}
          </Link>
        </Td>
        <Td className="text-center border-0">
          <Link
            onClick={(e) => {
              e.preventDefault();
              this.onDownloadClick(
                ogpDocument.uuidDocName,
                ogpDocument.documentName
              )
            }}
            to="#"
          >
            <GetAppIcon className="previewButton" />
          </Link>
        </Td>
        {authorizationService.canAccessOGPPageSpecificAreas(
          this.props.authRole
        ) && (
          <Td className="text-center border-0">
            <DeleteButton click={() => this.onDeleteClick(ogpDocument.id)} />
          </Td>
        )}
      </Tr>
    );
    return (
      <Fragment>
        {row}
        {this.state.showOgpDocumentPreview && (
          <ModalForDocumentPreview
            show={this.state.showOgpDocumentPreview}
            documentName={ogpDocument.uuidDocName}
            controllerReference={'ogpDocument'}
            handleClose={this.handlePreviewClose}
          />
        )}
      </Fragment>
    );
  }
}

OgpDocumentRow.propTypes = {
  deleteOGPDocument: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  deleteOGPDocument,
  downloadOGPDocument,
})(OgpDocumentRow);
