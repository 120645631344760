import { useSelector } from "react-redux";
import CommentDisplayCard from "./CommentDisplayCard";



const CommentDisplayCardList = ({setRefresh}) => {
    const dictionary = useSelector(state => state.startUp.dictionary)
    const comments = useSelector(state => state?.comment?.commentListOnDocument?.content);

    return (
        <>
            {comments && comments.length > 0 ?
             comments.map(comment => {
                return (<CommentDisplayCard key={comment.id} comment={comment} setRefresh={setRefresh}/>)
            }) : 
            <div className="d-flex m-4 justify-content-center">
                <span>{dictionary["CommentDisplayCardList.NemaRezultata"]}</span>
            </div>
        }
        </>
    );
}

export default CommentDisplayCardList;