import { useSelector } from "react-redux"
import Input from '../Reusable/Input';
import TextArea from "../Reusable/TextArea";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import MethodDocuments from "../MethodDocument/MethodDocuments";
import 'moment/locale/en-gb'

const ExternalMembers = (props) => {

    const dictionary = useSelector(state => state.startUp.dictionary);

    const handleStartDateChange = (e) => {
        if (!props.startDate) {
            const selectedDate = new Date(e);
            const isMidnight = selectedDate.getHours() === 0 && selectedDate.getMinutes() === 0;
    
            if (isMidnight) {
                const now = new Date();
                selectedDate.setHours(now.getHours());
                selectedDate.setMinutes(now.getMinutes());
                props.setStartDate(selectedDate);
            } else {
                props.setStartDate(e);
            }
        } else { 
            props.setStartDate(e);
        }
    }

    const handleEndDateChange = (e) => {
        if (!props.endDate) {
            const selectedDate = new Date(e);
            const isMidnight = selectedDate.getHours() === 0 && selectedDate.getMinutes() === 0;
        
            if (isMidnight) {
            const adjustedDate = new Date(e);
            adjustedDate.setHours(23);
            adjustedDate.setMinutes(59);
            props.setEndDate(adjustedDate);
            } else {
            props.setEndDate(e);
            }
        } else {
            props.setEndDate(e);
        }
    }
    return (
        <>
            
                {!props.readOnly ? 
                <div className="form-group">
                <label className="labelText textShadow">
                    {dictionary["ModalForAddNewMethod.Naslov"]}: <span className="redAsterisk"> *</span>
                </label>
                <Input
                    type="text"
                    id="title"
                    placeholder={dictionary["ModalForAddNewMethod.Placeholder.Naslov"]}
                    name="title"
                    value={props.title}
                    onChange={(e) => props.setTitle(e.target.value)}
                    //errors={errors.title}
                />
                <div id="titleМsg" />
                </div>
                :
                <div className="d-flex flex-column pb-2">
                    <label className="labelText textShadow">
                        {dictionary["ModalForAddNewMethod.Naslov"]}:
                    </label>
                    <span className="">{props.title}</span> 
                </div>
            }
            

            <div className="d-flex justify-content-between">
                {!props.readOnly ? 
                <div className="form-group me-2">
                <label className="labelText textShadow">
                    {dictionary["ModalForAddNewMethod.StartDate"]}: <span className="redAsterisk"> *</span>
                </label>
                    <DatePicker
                        id="startDate"
                        selected={props.startDate ? new Date(props.startDate) : null}
                        onChange={(e) => {handleStartDateChange(e)}}
                        className="dateInput form-control"
                        dateFormat="dd.MM.yyyy HH:mm"
                        placeholderText={dictionary["ModalForAddNewMethod.Placeholder.StartDate"]}
                        minDate={moment().toDate()}
                        maxDate={props.phase?.endDate ? new Date(props.phase?.endDate) : null}
                        showTimeInput
                        timeInputLabel={dictionary["DatePicker.TimeInputLabel"]}
                        />
                    <div id="startDateМsg" />
                </div> : 
                <div className="d-flex flex-column">
                    <label className="labelText textShadow">
                    {dictionary["ModalForAddNewMethod.StartDate"]}:
                </label>
                    <span>{moment(props.startDate).format('DD.MM.YYYY. HH:MM')}</span>
                </div>
                }
                <div className="form-group ms-2">
                    <label className="labelText textShadow">
                        {dictionary["ModalForAddNewMethod.EndDate"]}: <span className="redAsterisk"> *</span>
                    </label>
                    <DatePicker
                        id="endDate"
                        selected={props.endDate ? new Date(props.endDate) : null}
                        onChange={(e) => {handleEndDateChange(e)}}
                        className="dateInput form-control"
                        dateFormat="dd.MM.yyyy HH:mm"
                        placeholderText={dictionary["ModalForAddNewMethod.Placeholder.EndDate"]}
                        minDate={props.startDate ? new Date(props.startDate) : null}
                        maxDate={props.phase?.endDate ? new Date(props.phase?.endDate) : null}
                        showTimeInput
                        timeInputLabel={dictionary["DatePicker.TimeInputLabel"]}
                        />
                    <div id="endDateMsg" />
                </div>
            </div>
                {!props.readOnly ? 
                <div className="form-group">
                    <label className="labelText textShadow">
                    {dictionary["ModalForAddNewMethod.Tekst"]}: <span className="redAsterisk"> *</span>
                    </label>
                    <TextArea
                        type="text"
                        id="description"
                        placeholder={dictionary["ModalForAddNewMethod.Placeholder.Tekst"]}
                        name="description"
                        value={props.description}
                        onChange={(e) => props.setDescription(e.target.value)}
                        //errors={errors.location}
                        maxLength="1000"
                        rows={6}
                    />
                    <div id="descriptionMsg" />
                </div> : 
                <div className="d-flex flex-column flex-grow-1 ocd-desc-padding">
                    <label className="labelText textShadow">
                {dictionary["ModalForAddNewMethod.Tekst"]}:
                </label>
                <span>{props.description}</span>
                </div>
             } 
            <MethodDocuments
                methodDocuments = {props.methodDocuments}
                uploadedFiles={props.uploadedFiles}
                setUploadedFiles={props.setUploadedFiles}
                selectedFieldsOfInterest={[]}
                phaseId={props.phase.id}
                documents={props.documents}
                setDocuments={props.setDocuments}
                methodId={props.methodId}
                readOnly={props.readOnly}
                selectedMethodType={props.selectedMethodType}
                oneFileAllowed={true}
            />
        </>
    );

}

export default ExternalMembers;