import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { Oval } from "react-loader-spinner";

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress && (
      <div
        style={{
          width: "100%",
          height: "100",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Oval
          color="#4357a18a"
          height="100"
          width="100"
          justify-content="center"
          align-items="center"
          secondaryColor="#C0C0C0"
        />
      </div>
    )
  );
};

export default LoadingIndicator;
