import React, { Component } from 'react';
import AdDocumentRow from './AdDocumentRow';
import authorizationService from '../../securityUtils/authorizationService';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';
import { connect } from 'react-redux';

class AdDocumentTable extends Component {
  render() {
    let adDocumentList = this.props.adDocumentList.map((adDocument) => (
      <AdDocumentRow
        key={adDocument.id}
        adDocument={adDocument}
      />
    ));

    return (
      <div className="table-responsive">
        <Table id="example" className="table table-sm border-0">
          <Thead>
            <Tr className="border-0">
              <Th className="border-0 faded-text">{this.props.dictionary["AdDocumentTable.PrilozeniDokumenti"]}</Th>
              <Th width="9%" className="text-center border-0 faded-text">
              {this.props.dictionary["AdDocumentTable.Pregled"]}
              </Th>
              <Th width="9%" className="text-center border-0 faded-text">
              {this.props.dictionary["AdDocumentTable.Preuzimanje"]}
              </Th>
              {authorizationService.canAccessOGPPageSpecificAreas(
                this.props.authRole
              ) && this.props.isArchive == false ? (
                <Th width="9%" className="text-center border-0 faded-text">
                  {this.props.dictionary["AdDocumentTable.Brisanje"]}
                </Th>
              ) : (
                ''
              )}
            </Tr>
          </Thead>
          <Tbody>{adDocumentList}</Tbody>
        </Table>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps,{}) (AdDocumentTable);
