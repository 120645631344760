import React from 'react';
import { setMessage } from '../../utils';
import Select from 'react-select';
import TextArea from '../Reusable/TextArea';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import ComboReusable from '../Reusable/ComboReusable';
import Input from '../../components/Reusable/Input';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import DatePicker from 'react-datepicker';
import {
  updateTopicOfDiscussion,
  getTopicOfDiscussion,
} from '../../actions/topicOfDiscussionActions';
import { getAllActiveFiledsOfInterest } from '../../actions/fieldOfInterestActions';
import { getInstitutions } from '../../actions/institutionActions';
import { trackPromise } from 'react-promise-tracker';
import {
  NotificationManager,
} from 'react-notifications';
import { getRelatedTopicOfDiscussionList } from '../../actions/topicOfDiscussionRelationActions';

class ModalForUpdateTopicOfDiscussion extends React.Component {
  constructor() {
    super();
    this.state = {
      nameOfTopic: '',
      description: '',
      creationDate: '',
      selectedfieldsOfInterest: [],
      institution: {
        id: 0,
      },
      law: false,
      regulation: false,
      other: false,
      newProcess: false,
      topic: {
        topicOfDiscussionGroupId: null,
      },
      errors: {},
      selectedTypeOfAct:null,
      mergedList:[],
    };
    this.handleCreationDateChange = this.handleCreationDateChange.bind(this);
    this.handleTypeOfActCheckChange = this.handleTypeOfActCheckChange.bind(this);
    this.handleFieldOfInterestMultiselectChange = this.handleFieldOfInterestMultiselectChange.bind(
      this
    );
    this.handleTextAreaExpand = this.handleTextAreaExpand.bind(this);
    this.handleTextAreaShrink = this.handleTextAreaShrink.bind(this);
  }

  componentDidMount() {
    const id = this.props.topicOfDiscussionId;
    this.props.getTopicOfDiscussion(id);
    // this.props.getAllFiledsOfInterest();
    // this.props.getInstitutions();
    this.props.getAllActiveFiledsOfInterest();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    const {
      id,
      nameOfTopic,
      description,
      creationDate,
      fieldOfInterests,
      institution,
      law,
      regulation,
      other,
      newProcess,
      topicOfDiscussionGroupId,
      user,
      updatedAt,
      endProcess,
      endDate,
      actType,
    } = nextProps.topicOfDiscussion;

    this.setState({
      id,
      nameOfTopic,
      description,
      creationDate: creationDate ? moment(creationDate).toDate() : '',
      fieldOfInterests,
      institution,
      law,
      regulation,
      other,
      newProcess,
      topicOfDiscussionGroupId,
      user,
      updatedAt,
      endProcess,
      endDate,
      selectedTypeOfAct : actType,
      selectedfieldsOfInterest: fieldOfInterests
        ? fieldOfInterests.map((field) => ({
            ...field,
            value: field.id,
            label: field.name,
          }))
        : [],
    });

    const  activeFieldOfInterestList  = nextProps.fieldOfInterest;
    let uniqueFieldOfInterestIds=new Set([...activeFieldOfInterestList.map(obj=> obj.id), ...this.state.selectedfieldsOfInterest.map(obj=>obj.id)]);

    let mergedList = [...uniqueFieldOfInterestIds].map(id=> {
        return activeFieldOfInterestList.find(obj=>obj.id==id) || this.state.selectedfieldsOfInterest.find(obj=>obj.id == id);
    });

    this.setState({mergedList:mergedList});
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCreationDateChange = (date) => {
    this.setState({ creationDate: date });
  };

  handleComboBoxChange = (object, objectId) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
        fieldId: e.target.value,
        comboSignal: object,
      });
    };
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleTypeOfActCheckChange = (item) => {
    this.setState({selectedTypeOfAct:item});
  };

  handleTextAreaExpand = (e) => {
    e.target.rows = 8;
  };

  handleTextAreaShrink = (e) => {
    e.target.rows = 4;
  };

  handleFieldOfInterestMultiselectChange(selectedOptions) {
    this.setState({ selectedfieldsOfInterest: selectedOptions || [] });
  }

  handleValidation = () => {
    let error = false;

    if(this.state.selectedTypeOfAct==null) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.Cekiranje"]}`,
        '#typeOfAct',
        '#msg5'
      );
      error = true;
    }
    if (this.state.nameOfTopic == '') {
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.NazivTeme"]}`,
        '#nameOfTopic',
        '#msg1'
      );
      error = true;
    } else if (this.state.nameOfTopic.length>255){
        this.props.setMessage(
          `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.NazivTemeDuzina"]}`,
          '#nameOfTopic',
          '#msg1'
        );
        error = true;
    }

    if (this.state.description == '') {
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.Opis"]}`,
        '#description',
        '#msg2'
      );
      error = true;
    }

    if (this.state.description.length > 1000) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.OpisDuzina"]}`,
        '#description',
        '#msg2'
      );
      error = true;
    }

    if (this.state.selectedfieldsOfInterest.length == 0) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.Oblast"]}`,
        '#multi',
        '#msg3'
      );
      error = true;
    }
    else {
      let listOfUnsuitableFieldsOfInterest = [];
      if(this.state.selectedTypeOfAct.code!='Ostalo'){
        for(let element of this.state.selectedfieldsOfInterest){
          if(element.actType.code!=this.state.selectedTypeOfAct.code) {
            listOfUnsuitableFieldsOfInterest.push(element.name);
          }
        }
        if(listOfUnsuitableFieldsOfInterest.length>0){
          this.props.setMessage(
            `${this.props.dictionary["ModalForUpdateTopicOfDiscussion.Validation.PogresneOblastiZaVrstuAktu"]+' '+listOfUnsuitableFieldsOfInterest.join(', ')}`,
            '#multi',
            '#msg3'
          );
          error = true;
        }
      }
     
    }

    return error;
  };


  handleSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const topicUpdate = {
      ...this.props.topicOfDiscussion,
      nameOfTopic: this.state.nameOfTopic,
      description: this.state.description,
      creationDate: this.state.creationDate,
      fieldOfInterests: this.state.selectedfieldsOfInterest,
      institution: {
        id: this.props.topicOfDiscussion.institution.id,
      },
      law: this.state.law,
      regulation: this.state.regulation,
      other: this.state.other,
      newProcess: this.state.newProcess,
      actType:this.state.selectedTypeOfAct,
    };
    trackPromise(this.props
      .updateTopicOfDiscussion(topicUpdate)
      .then( () => {
        NotificationManager.success(this.props.dictionary["ModalForUpdateTopicOfDiscussion.UspesnoAzuriranje"],this.props.dictionary["universal.Uspesno"], 5000);
      })
      .then(this.props.getRelatedTopicOfDiscussionList(this.props.topicOfDiscussion.id))
      .then(() => this.handleClose()))
      .catch( () => {
        NotificationManager.error(this.props.dictionary["ModalForUpdateTopicOfDiscussion.NeuspesnoAzuriranje"], this.props.dictionary["universal.GreskaUObradi"], 5000);
      })
  };

  render() {
    const { errors } = this.state;
    const {selectedTypeOfAct} = this.state;
    const actTypes = this.props.taxonomies['ActType'].filter(item => 
      item.taxonomyValueInformationList.some(element => 
        element.informationName==(this.props.jls ? 'JLS':'RepublickiNivo')));

    let fieldOfInterestOptions=this.state.mergedList.map((field) => ({
      ...field,
      value: field.id,
      label: field.name,
    }));
    if(this.state.selectedTypeOfAct!=null && this.state.selectedTypeOfAct.code != 'Ostalo') {
      fieldOfInterestOptions = fieldOfInterestOptions
      .filter((field) => field.actType.code==this.state.selectedTypeOfAct.code)
      .map((field) => ({
          ...field,
          value: field.id,
          label: field.name,
      }));
    } 

    const customStyleForMultiselect = {
      control: (styles) => ({ ...styles, minHeight: '45px' }),
      option: (styles) => ({
        ...styles,
        color: '#495057',
        fontSize: '17px',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
        marginLeft: '12px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
      }),
      input: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '1.25rem',
        marginLeft: '12px',
      }),
    };

    let infoAboutRegulation = (
      <p className="regular-font">{this.props.dictionary["tooltip.universal.infoAboutRegulation"]}</p>
    );
    let infoAboutLaw = <p className="regular-font">{this.props.dictionary["tooltip.universal.infoAboutLaw"]}</p>;
    let infoAboutOther = (
      <p className="regular-font">{this.props.dictionary["tooltip.universal.infoAboutOther"]}</p>
    );

    const actTypeTooltipInfo = {
      PlanskiDokument:<p className="regular-font">{this.props.dictionary["tooltip.universal.infoAboutRegulation"]}</p>,
      Propis:<p className="regular-font">{this.props.dictionary["tooltip.universal.infoAboutLaw"]}</p>,
      Ostalo:<p className="regular-font">{this.props.dictionary["tooltip.universal.infoAboutOther"]}</p>
  };

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <h4 className="text-center">{this.props.dictionary["ModalForUpdateTopicOfDiscussion.IzmenaAkta"]}</h4>
            <hr></hr>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="labelText textShadow">
                  {this.props.dictionary["ModalForUpdateTopicOfDiscussion.IzaberiteTipDokumenta"]}:{' '}
                    <span className="redAsterisk"> *</span>
                  </label>
                  <div className="form-group mb-4 mt-2">
                    {actTypes.map((item) => (
                      <>
                        <a data-tooltip-id={`currentStateTip_${item.code}`}>
                          <label className="col-md-7 labelText textShadow">
                            <span className="bullet-char">&bull;</span>
                              {item.value}:
                          </label>
                          <input
                            type="radio"
                            id={item.code}
                            name="law"
                            className="mt-2 filled-in"
                            checked={selectedTypeOfAct?.code===item.code}
                            onChange={()=> this.handleTypeOfActCheckChange(item)}
                            disabled={this.props.phase.phaseList.length>0}
                          />
                        </a>
                          <Tooltip
                          id={`currentStateTip_${item.code}`}
                          type="info"
                          effect="solid"
                          place="right"
                          >
                            {actTypeTooltipInfo[item.code]}
                          </Tooltip>
                      </>
                    ))}
                  </div>
                  <label className="labelText textShadow">
                  {this.props.dictionary["ModalForUpdateTopicOfDiscussion.Naziv"]}: <span className="redAsterisk"> *</span>
                  </label>
                  <TextArea
                    type="text"
                    id="nameOfTopic"
                    placeholder={this.props.dictionary["ModalForUpdateTopicOfDiscussion.Placeholder.Naziv"]}
                    name="nameOfTopic"
                    value={this.state.nameOfTopic}
                    onChange={this.onChange}
                    errors={errors.nameOfTopic}
                    maxLength="255"
                  />
                  <p className="small-font">
                    <span className="redAsterisk"> *</span>
                    <em>
                    {this.props.dictionary["ModalForTopicOfDiscussion.Info.PrviDeo"]} <strong>{this.props.dictionary["ModalForTopicOfDiscussion.Info.BrojKarakterNazivAkta"]}</strong> {this.props.dictionary["ModalForTopicOfDiscussion.Info.ZadnjiDeo"]}{' '}
                    </em>
                  </p>
                  <div id="msg1" />
                </div>
                <div className="form-group mb-4 mt-2">
                  <label className="labelText textShadow">
                  {this.props.dictionary["ModalForUpdateTopicOfDiscussion.Opis"]}:
                    <span className="redAsterisk"> *</span>
                  </label>
                  <TextArea
                    type="text"
                    id="description"
                    placeholder={this.props.dictionary["ModalForUpdateTopicOfDiscussion.Placeholder.Opis"]}
                    name="description"
                    value={this.state.description}
                    onChange={this.onChange}
                    errors={errors.description}
                    maxLength="1000"
                    rows={4}
                    onFocus={this.handleTextAreaExpand}
                    onBlur={this.handleTextAreaShrink}
                  />
                  <p className="small-font">
                    <span className="redAsterisk"> *</span>
                    <em>
                    {this.props.dictionary["ModalForUpdateTopicOfDiscussion.Info.PrviDeo"]} <strong>{this.props.dictionary["ModalForUpdateTopicOfDiscussion.Info.Broj"]}</strong> {this.props.dictionary["ModalForUpdateTopicOfDiscussion.Info.ZadnjiDeo"]}{' '}
                    </em>
                  </p>
                  <div id="msg2" />
                </div>
                <div className="form-group mb-4 mt-2">
                  <label className="labelText textShadow">
                  {this.props.dictionary["ModalForUpdateTopicOfDiscussion.Oblasti"]}: <span className="redAsterisk"> *</span>
                  </label>
                  <Select
                    isMulti
                    name="fieldOfInterestList1"
                    options={fieldOfInterestOptions}
                    value={this.state.selectedfieldsOfInterest}
                    onChange={this.handleFieldOfInterestMultiselectChange}
                    placeholder= {this.props.dictionary["ModalForTopicOfDiscussion.Placeholder.Oblast"]}
                    id="multi"
                    styles={customStyleForMultiselect}
                  />
                  <div id="msg3" />
                </div>
                <div className="from-group mb-4 mt-2">
                  <label className="labelText textShadow">
                  {this.props.dictionary["ModalForUpdateTopicOfDiscussion.Institucija"]}: <span className="redAsterisk"> *</span>
                  </label>
                  <label className="d-block">
                  {this.props.topicOfDiscussion.institution.institutionName}
                  </label>                  
                </div>
                <div id="msg5" />
              </div>
            </div>
            <br />
            <div className="row">
              <button
                type="button"
                id="subscribeButton"
                className="col-md-6 m-auto"
                onClick={this.handleSubmit}
              >
                {this.props.dictionary["ModalForUpdateTopicOfDiscussion.Sacuvaj"]}
              </button>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}
ModalForUpdateTopicOfDiscussion.propTypes = {
  getTopicOfDiscussion: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  topicOfDiscussion: state.topicOfDiscussion.topicOfDiscussion,
  fieldOfInterest: state.fieldOfInterest.activeFieldOfInterestList,
  institution: state.institution,
  errors: state.errors,
  dictionary: state.startUp.dictionary,
  institutionName:state.security.institutionName,
  institutionId:state.security.institutionId,
  jls:state.security.jls,
  taxonomies:state.startUp.taxonomies,
});

export default connect(mapStateToProps, {
  getTopicOfDiscussion,
  updateTopicOfDiscussion,
  getAllActiveFiledsOfInterest,
  getInstitutions,
  setMessage,
  getRelatedTopicOfDiscussionList,
})(ModalForUpdateTopicOfDiscussion);
