import axios from "axios";

import {
  GET_FIELD_OF_INTEREST_INSTITUTION_USER,
  GET_INSTITUTION_BY_USER_ID_LIST,
  GET_FIELD_OF_INTEREST_BY_USER_ID_LIST,
  GET_ERRORS,
} from "./types";

export const getAllByUserId = (id) => async (dispatch) => {
  const res = await axios.get(
    `/api/fieldOfInterestInstitutionUser/findById/${id}`
  );
  dispatch({
    type: GET_FIELD_OF_INTEREST_INSTITUTION_USER,
    payload: res.data,
  });
};

export const createFieldOfInterestUserList = (FUO) => async (dispatch) => {
  try {
    await axios.post("/api/fieldOfInterestInstitutionUser/createAll", FUO);
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  }
};

export const getFieldOfInterestByUserIdList = (id) => async (dispatch) => {
  const res = await axios.get(
    `/api/fieldOfInterestInstitutionUser/findFieldOfInterestByUserId/${id}`
  );
  dispatch({
    type: GET_FIELD_OF_INTEREST_BY_USER_ID_LIST,
    payload: res.data,
  });
};

export const getInstitutionByUserIdList = (id) => async (dispatch) => {
  const res = await axios.get(
    `/api/fieldOfInterestInstitutionUser/findInstitutionByUserId/${id}`
  );
  dispatch({
    type: GET_INSTITUTION_BY_USER_ID_LIST,
    payload: res.data,
  });
};
