
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from '../Reusable/TextArea';
import { hideProcessAndNotifyModerator } from '../../actions/topicOfDiscussionActions';
import { trackPromise } from 'react-promise-tracker';
import { NotificationManager } from 'react-notifications';
import { setMessage } from '../../utils';

const ModalForHidingProcess = ({topicOfDiscussionId, show, handleClose}) => {

    const dictionary = useSelector(state => state.startUp.dictionary);
    const dispatch = useDispatch();
    
    const [mailbody, setMailBody] = useState('');
    const handleSubmit = () => {
        let error = handleValidation();
        if (!error) {
            let formData = new FormData();
            formData.append('mailbody', mailbody);
            trackPromise(dispatch(hideProcessAndNotifyModerator(topicOfDiscussionId, formData)).catch(() => {
                NotificationManager.error(dictionary["ModalForHidingProcess.NeuspešnoSakrivanjeProcesa"]);
            }))
            .then(() => {
            NotificationManager.success(dictionary["ModalForHidingProcess.UspesnoSakrivanjeProcesa"]);
            handleClose();
            })
        }
        
    }

    const handleValidation = () => {
        let error = false;
        if (mailbody == "") {
            dispatch(setMessage(
             dictionary["ModalForHidingProcess.Validation.TeloMaila"],
              "#mailbody",
              "#msgMailbody"
            ));
            error = true;
        }
        if (mailbody.length > 300) {
            dispatch(setMessage(
             dictionary["ModalForHidingProcess.Validation.TeloMailaMax"],
              "#mailbody",
              "#msgMailbody"
            ));
            error = true;
        }
          return error;
    }

    return (
        <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="ModalForHidingProcess"
      >
        <Modal.Header closeButton>
            <Modal.Title>
                {dictionary["ModalForHidingProcess.Naslov"]}
            </Modal.Title>
        </Modal.Header> 
        <Modal.Body>     
            <div className="form-group mb-3">
                <label className="labelText textShadow">
                {dictionary["ModalForHidingProcess.TeloMaila"]}: <span className="redAsterisk"> *</span>
                </label>
                <TextArea
                    type="text"
                    id="mailbody"
                    placeholder={dictionary["ModalForHidingProcess.Placeholder.TeloMaila"]}
                    name="mailbody"
                    value={mailbody}
                    onChange={(e) => setMailBody(e.target.value)}
                    // errors={errors.visibilityExplanation}
                    maxLength="300"
                    rows={4}
                />
                <div id="msgMailbody" />
            </div>
            <div className="mt-3 d-flex justify-content-around">
                <button
                    type="button"
                    id="subscribeButton"
                    className="m-auto"
                    onClick={handleClose}
                >
                    {dictionary["ModalForHidingProcess.Odustani"]}
                </button>
                <button
                    type="button"
                    id="subscribeButton"
                    className="m-auto"
                    onClick={handleSubmit}
                >
                    {dictionary["ModalForHidingProcess.DaSakrijProces"]}
                </button>
            </div>
        </Modal.Body> 
      </Modal>
    ); 
}
export default ModalForHidingProcess;