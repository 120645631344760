import React, { Component } from 'react';
import OGPCommentRow from './OGPCommentRow';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';
import { connect } from 'react-redux';

class OGPCommentTable extends Component {
  render() {
    let counter = 1;
    let ogpCommentList = this.props.ogpCommentList.map((ogpComment) => (
      <OGPCommentRow
        key={ogpComment.id}
        ogpComment={ogpComment}
        counter={counter++}
      />
    ));

    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-sm table-striped mt-2">
          <Thead>
            <Tr className="tableHeader">
              <Th width="3%">{this.props.dictionary["OGPCommentTable.RB"]}</Th>
              <Th>{this.props.dictionary["OGPCommentTable.Faza"]}</Th>
              <Th>{this.props.dictionary["OGPCommentTable.Korisnik"]}</Th>
              <Th>{this.props.dictionary["OGPCommentTable.Organizacija"]}</Th>
              <Th className="text-center">{this.props.dictionary["OGPCommentTable.Kategorija"]}</Th>
              <Th className="text-center">{this.props.dictionary["OGPCommentTable.DatumObjave"]}</Th>
              <Th className="text-center">{this.props.dictionary["OGPCommentTable.Status"]}</Th>
              <Th width="5%" className="text-center">
              {this.props.dictionary["OGPCommentTable.Obrada"]}
              </Th>
            </Tr>
          </Thead>
          <Tbody>{ogpCommentList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {})(OGPCommentTable);


