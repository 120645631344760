import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteOgpModerator } from '../../actions/ogpModeratorActions';
import DeleteButton from '../Reusable/DeleteButton';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Tr, Td } from 'react-super-responsive-table';
import { setMessage } from '../../utils';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Link } from 'react-router-dom';
import UserModeratorsComboBox from '../User/UserModeratorsComboBox';

class OgpModeratorRow extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedUser: null,
      errors: {},
    };
    this.handleOgpModeratorSelectChange =
      this.handleOgpModeratorSelectChange.bind(this);
  }

  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteOgpModerator(id);
    };
    confirmDialog(`${this.props.dictionary["OgpModeratorRow.Obrisi.Poruka"]}`, action, `${this.props.dictionary["OgpModeratorRow.Obrisi"]}`);
  };

  handleOgpModeratorSelectChange = (e) => {
    const moderator = JSON.parse(e.target.value);
    const userArray = this.props.usersModerators.filter(
      (u) => u.id === moderator.id
    );
    this.setState({
      selectedUser: userArray[0],
    });
  };

  handleValidation = () => {
    let error = false;

    if (!this.state.selectedUser) {
      this.props.setMessage(
        `${this.props.dictionary["OgpModeratorRow.Validation.Moderator"]}`,
        '#usersModeratorsCb',
        '#moderatorMsg'
      );
      error = true;
    }

    return error;
  };

  handleAdd = (e) => {
    e.preventDefault();

    if (this.handleValidation()) {
      return;
    }

    const newModerator = {
      ogp: {
        id: this.props.ogp.id,
      },
      user: this.state.selectedUser,
    };

    this.props.handleAdd(newModerator);
  };

  render() {
    const { errors } = this.state;
    const isEmpty = !this.props.ogpModerator;

    return (
      <Tr className="text-center">
        <Td>
          {isEmpty ? (
            <UserModeratorsComboBox
              mainModeratorId={this.props.ogp.user.id}
              usersModerators={this.props.usersModerators}
              onChange={this.handleOgpModeratorSelectChange}
            />
          ) : (
            <label>
              <strong>
                {this.props.ogpModerator.user.firstName +
                  ' ' +
                  this.props.ogpModerator.user.lastName +
                  ' '}{' '}
                <strong className="title-text-color bullet-char">
                  &bull;{' '}
                </strong>
              </strong>
              {' ' + this.props.ogpModerator.user.email}
            </label>
          )}
          <div id="moderatorMsg" />
        </Td>
        {isEmpty ? (
          <Td className="text-center">
            <Link to="#" onClick={(e) => { e.preventDefault();this.handleAdd();}}>
              <CheckBoxIcon className="editButton" />
            </Link>
          </Td>
        ) : (
          <Td className="text-center">
            {this.props.isOgpAdmin === true ? (
              <DeleteButton
                click={() => this.onDeleteClick(this.props.ogpModerator.id)}
              />
            ) : (
              <Link to="#">
                <i className="far fa-trash-alt empty-list-button"></i>
              </Link>
            )}
          </Td>
        )}
      </Tr>
    );
  }
}

OgpModeratorRow.propTypes = {
  deleteOgpModerator: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  deleteOgpModerator,
  setMessage,
})(OgpModeratorRow);
