import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Input from '../Reusable/Input';
import TextArea from '../Reusable/TextArea';
import ComboReusable from '../Reusable/ComboReusable';
import { setMessage } from '../../utils';
import { updateFieldOfInterest } from '../../actions/fieldOfInterestActions';
import { NotificationManager } from 'react-notifications';
import { UPDATE_FIELD_OF_INTEREST_SUCCESS } from '../../actions/types'
import { Label } from '@mui/icons-material';

const ModalForUpdateFieldOfInterest = (props) =>{
    
    const dispatch = useDispatch();
    const dictionary = useSelector(state => state.startUp.dictionary);
    const isJLS = useSelector(state => state.security.jls);
    const actTypeTiedToFieldOfInterest = x=>x.taxonomyValueInformationList.some(y=>y.informationName=="actTypeTiedToFieldOfInterest" && y.informationValue=='true');
    const actTypeisJLS = isJLS ? x=>x.taxonomyValueInformationList.some(y=>y.informationName=='JLS' && y.informationValue=='true') : x=>!x.taxonomyValueInformationList.some(y=>y.informationName=='JLS' && y.informationValue=='true')
    const actTypes = useSelector(state => state.startUp.taxonomies["ActType"].filter(actTypeTiedToFieldOfInterest).filter(actTypeisJLS));
    const fieldOfInterestUpdated = useSelector(state=>state.fieldOfInterest.fieldOfInterestUpdated);
    const errors = useSelector(state=>state.errors);
    const [originalFieldOfInterest, setOriginalFieldOfInterest] = useState({});

    const [field, setField] = useState({
        name : "",
        status : true,
        comment: "",
        actType : null,
    });
    const [inTopic, setInTopic] = useState(false);

    useEffect(()=>{
        setField(props.fieldOfInterest);
        setInTopic(props.inTopic);
        setOriginalFieldOfInterest(props.fieldOfInterest);
    }, [props.fieldOfInterest, props.inTopic]);

    useEffect(()=>{
      if(fieldOfInterestUpdated != null) {
          if(fieldOfInterestUpdated==true)
              if(errors.duplicateFieldOfInterestNameErrorMessage) NotificationManager.error(errors.duplicateFieldOfInterestNameErrorMessage,dictionary["universal.GreskaUObradi"], 5000);
              else NotificationManager.success(dictionary["ModalForUpdateFieldOfInterest.UspesnaPromena"],dictionary["universal.Uspesno"], 5000);
          else NotificationManager.error(dictionary["ModalForUpdateFieldOfInterest.NeuspesnaPromena"], dictionary["universal.GreskaUObradi"], 5000);
          dispatch({
              type: UPDATE_FIELD_OF_INTEREST_SUCCESS,
              payload: null,
            });
          clearErrors();
      }
  },[fieldOfInterestUpdated, props.error])

  const clearErrors = () => {
    errors.duplicateFieldOfInterestNameErrorMessage = null;
}

    const onChange = (e) => {
        const {name,value} = e.target;
        setField(prevField => ({...prevField, [name]:value}));
    };
    const handleCheckboxChange = (e) => {
        setField(prevField =>({...prevField, [e.target.name]: e.target.checked }));
      };
    const handleComboBoxChange = () => {
        return (e) => {
            const {name,value} = e.target;
            setField(prevField => ({...prevField, [name]:{['id']:value}}))
        };
      };
    const handleClose = (refresh) => {
        props.handleClose(refresh);
    };

    const handleValidation = () => {
      let error = false;
  
      if (field.name == '') {
        dispatch(setMessage(`${dictionary["UpdateFieldOfInterest.Validation.Naziv"]}`, '#name', '#validationErrorName'));
        error = true;
      }
  
      if (
        (field.name != props.fieldOfInterest.name || field.actType.id != props.fieldOfInterest.actType.id || field.status != props.fieldOfInterest.status) &&
        (field.comment === null ||
          field.comment === '' ||
          field.comment == props.fieldOfInterest.comment)
      ) {
        dispatch(setMessage(
          `${dictionary["UpdateFieldOfInterest.Validation.Obrazlozenje"]}`,
          '#comment',
          '#validationErrorComment'
        ));
        error = true;
      }
      if ((field.comment == null || field.comment == "")&&(field.name!=originalFieldOfInterest.name||field.actType!=originalFieldOfInterest.actType||field.status!=originalFieldOfInterest.status)) {
        dispatch(setMessage(
          `${dictionary["UpdateFieldOfInterest.Validation.KomentarPrazan"]}`,
          '#comment',
          '#validationErrorComment'
        ));
        error = true;
      }
      if (field.comment && field.comment.length > 300) {
        dispatch(setMessage(
          `${dictionary["UpdateFieldOfInterest.Validation.KomentarDuzina"]}`,
          '#comment',
          '#validationErrorComment'
        ));
        error = true;
      }
      return error;
    };
    const onSubmit = () => {
      if(handleValidation()==true) return;
      dispatch(updateFieldOfInterest(field))
      .then( () => {
        //NotificationManager.success(dictionary["ModalForUpdateFieldOfInterest.UspesnaPromena"],dictionary["universal.Uspesno"], 5000);
        dispatch({
          type: UPDATE_FIELD_OF_INTEREST_SUCCESS,
          payload: true,
        });
      })
    .catch( () => { 
        //NotificationManager.error(dictionary["ModalForUpdateFieldOfInterest.NeuspesnaPromena"], dictionary["universal.GreskaUObradi"], 5000);
        dispatch({
          type: UPDATE_FIELD_OF_INTEREST_SUCCESS,
          payload: false,
        });
      })
    .finally ( () => { 
            handleClose(true); 
        });
    };

    return(
        <Modal
        show={props.show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton>
            <Modal.Body>
                <h4 className="text-center textShadow mt-5">{dictionary["UpdateFieldOfInterest.Izmena"]}</h4>
                <hr />
                <div className="form-group">
                    <label className="labelText textShadow">
                    {dictionary["UpdateFieldOfInterest.Naziv"]}
                    </label>
                    <span className="redAsterisk"> *</span>
                    <Input
                      type="text"
                      id="name"
                      placeholder={dictionary["UpdateFieldOfInterest.Placeholder.Naziv"]}
                      name="name"
                      value={field.name}
                      onChange={onChange}
                      errors={errors.name}
                      disabled={inTopic}
                    />
                    <div id="validationErrorName" />
                  </div>
                  <div className="form-group row">
                    <label className="col-md-5 labelText textShadow mb-2 mt-2 w-auto">
                    {dictionary["UpdateFieldOfInterest.AktivnaOblast"]}:
                    </label>
                    <input
                      type="checkbox"
                      name="status"
                      className="mt-2 filled-in  mb-2 w-auto d-inline-block" 
                      checked={field.status}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="labelText textShadow">
                    {dictionary["UpdateFieldOfInterest.Komentar"]}: <span className="redAsterisk"> *</span>
                    </label>
                    <TextArea
                      type="text"
                      id="comment"
                      placeholder={dictionary["UpdateFieldOfInterest.Placeholder.Komentar"]}
                      name="comment"
                      value={field.comment}
                      onChange={onChange}
                      errors={errors.comment}
                      disabled={false}
                      maxLength="300"
                      rows={4}
                    />
                    <div id="validationErrorComment" />
                  </div>
                  <div className="form-group">
                    <label className="labelText textShadow">{dictionary["UpdateFieldOfInterest.Tip"]}</label>
                    <ComboReusable
                        id="actTypeCb"
                        name="actType"
                        disabled={inTopic}
                        namePart={dictionary["addFieldOfInterest.namePart.tip"]}
                        items={actTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.value}
                          </option>
                        ))}
                        updateValue = {field.actType?.id}
                        onChange={handleComboBoxChange()}
                    />
                  </div>
                  <div className="form-row mt-4">
                    <button
                      id="subscribeButton"
                      type="submit"
                      className="col-md-2 float-right"
                      onClick={()=>onSubmit()}
                    >
                      {dictionary["UpdateFieldOfInterest.Sacuvaj"]}
                    </button>
                    <button
                      id="subscribeButton"
                      type="button"
                      className="col-md-2 float-right"
                       onClick={()=>handleClose()}
                    >
                      {dictionary["UpdateFieldOfInterest.Odustani"]}
                    </button>
                  </div>
            </Modal.Body>
            </Modal.Header>
        </Modal>
    );
};
export default ModalForUpdateFieldOfInterest;