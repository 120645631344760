import React from 'react';
import { connect } from 'react-redux';

class ComboFieldOfInterest extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    let placeholder = this.props.signal
      ? this.props.namePart
      : `${this.props.dictionary["ComboFieldOfInterest.Izaberite"]}` + this.props.namePart;

    let cssStyle =
      this.props.style && this.props.style == 1
        ? 'select-field-light form-control form-control-md'
        : 'select-field form-control form-control-md';

    let regulationList = this.props.items
      .filter((fieldOfInterest) => fieldOfInterest.fieldOfInterestType === 1)
      .map((fieldOfInterest) => (
        <option key={fieldOfInterest.id} value={fieldOfInterest.id}>
          {fieldOfInterest.name}
        </option>
      ));
    let lawList = this.props.items
      .filter((fieldOfInterest) => fieldOfInterest.fieldOfInterestType === 0)
      .map((fieldOfInterest) => (
        <option key={fieldOfInterest.id} value={fieldOfInterest.id}>
          {fieldOfInterest.name}
        </option>
      ));

    return (
      <select
        id={this.props.id}
        className={cssStyle}
        value={this.props.updateValue}
        onChange={this.props.onChange}
        defaultValue={this.props.updateValue ? this.props.updateValue : '-1'}
      >
        <option key="0" value="-1" disabled hidden>
          {placeholder}
        </option>
        <optgroup label={this.props.dictionary["ComboFieldOfInterest.Propis"]}>{regulationList}</optgroup>
        <optgroup label={this.props.dictionary["ComboFieldOfInterest.PlanskiDokument"]}>{lawList}</optgroup>

        {this.props.allItemsNaming ? (
          <option key={this.props.items.length} value={0}>
            {this.props.allItemsNaming}
          </option>
        ) : (
          ''
        )}
      </select>
    );
  }
}
const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary,
});

export default connect(mapStateToProps, {})(ComboFieldOfInterest);

