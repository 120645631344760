import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  createOGPDescription,
  getOGPDescription,
} from '../../actions/ogpDescriptionActions';
import TextArea from '../Reusable/TextArea';
import { setMessage } from '../../utils';
import { confirmDialog } from '../Reusable/ConfirmDialog';

class AddOGPDescription extends Component {
  constructor() {
    super();
    this.state = {
      description: '',
      errors: {},
    };
    this.handleTextAreaExpand = this.handleTextAreaExpand.bind(this);
    this.handleTextAreaShrink = this.handleTextAreaShrink.bind(this);
  }

  componentDidMount() {
    this.props.getOGPDescription(this.props.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    const { ...ogpDescription } = nextProps.ogpDescription;

    this.setState({
      ...ogpDescription,
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTextAreaExpand = (e) => {
    e.target.rows = 8;
  };

  handleTextAreaShrink = (e) => {
    e.target.rows = 4;
  };

  handleValidation = () => {
    let error = false;

    if (this.state.description == '') {
      this.props.setMessage(
        `${this.props.dictionary["AddOGPDescription.Validation.Tekst"]}`,
        '#description',
        '#msg1'
      );
      error = true;
    }
    if (this.state.description.length > 1000) {
      this.props.setMessage(
        `${this.props.dictionary["AddOGPDescription.Validation.TekstDuzina"]}`,
        '#description',
        '#msg1'
      );
      error = true;
    }

    return error;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newOGPDescription = {
      description: this.state.description,
    };

    const action = () => {
      this.props
        .createOGPDescription(newOGPDescription)
        .then(this.props.handleButtonClickedChange('closeDescriptionEditForm'));
    };
    confirmDialog(`${this.props.dictionary["AddOGPDescription.Dialog.Sacuvaj.Poruka"]}`,
                   action, 
                   `${this.props.dictionary["AddOGPDescription.Dialog.Sacuvaj"]}`);
  };

  render() {
    const { errors } = this.state;

    return (
      <form onSubmit={this.onSubmit} className="component-wrapper-fade-in">
        <div className="form-group mb-4">
          <TextArea
            type="text"
            id="description"
            placeholder={this.props.dictionary["AddOGPDescription.Placeholder.Tekst"]}
            name="description"
            value={this.state.description}
            onChange={this.onChange}
            errors={errors.description}
            rows="4"
            maxlength="1000"
            onFocus={this.handleTextAreaExpand}
            onBlur={this.handleTextAreaShrink}
          />
          <div id="msg1" />
        </div>
        <div className="form-group">
          <button
            type="submit"
            id="subscribeButton"
            className="col-md-2 float-right"
          >
            {this.props.dictionary["AddOGPDescription.Sacuvaj"]}
          </button>
        </div>
      </form>
    );
  }
}

AddOGPDescription.propTypes = {
  createOGPDescription: PropTypes.func.isRequired,
  getOGPDescription: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ogpDescription: state.ogpDescription.ogpDescription,
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  setMessage,
  createOGPDescription,
  getOGPDescription,
})(AddOGPDescription);
