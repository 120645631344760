import {
    GET_SURVEY_ANSWERS,
    GET_SURVEY_ANSWERS_LIST,
    GET_SURVEY_CLOSED_ANSWERS_LIST,
    DELETE_SURVEY_ANSWERS,
    GET_SURVEY_ANSWERS_FILTERABLE,
} from "../actions/types";

const initialState = {
    surveyAnswersList: [],
    surveyAnswers: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SURVEY_ANSWERS_LIST:
            return {
                ...state,
                surveyAnswersList: action.payload,
            };
        case GET_SURVEY_CLOSED_ANSWERS_LIST:
            return {
                ...state,
                surveyAnswersList: action.payload,
            };
        case GET_SURVEY_ANSWERS:
            return {
                ...state,
                surveyAnswers: action.payload,
            };
        case DELETE_SURVEY_ANSWERS:
            return {
                ...state,
                surveyAnswersList: state.surveyAnswersList.filter(
                    (sa) => sa.id !== action.payload
                ),
            };
        case GET_SURVEY_ANSWERS_FILTERABLE:
            return{
                ...state,
                surveyAnswersFilterable: action.payload,
            };
        default:
            return state;
    }
}
