import axios from 'axios';

import {
  GET_ERRORS,
  GET_AD_LIST,
  GET_AD,
  DELETE_AD,
  CREATE_AD,
  UPDATE_AD,
} from './types';

export const createAd = (announcement) => async (dispatch) => {
  try {
    const res = await axios.post('/api/announcement/create', announcement);
    dispatch({
      type: CREATE_AD,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateAd = (announcement) => async (dispatch) => {
  try {
    const res = await axios.post('/api/announcement/update', announcement);
    dispatch({
      type: UPDATE_AD,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getAllAd = () => async (dispatch) => {
  const res = await axios.get('/api/announcement/findAll');
  dispatch({
    type: GET_AD_LIST,
    payload: res.data,
  });
};

export const getAd = (id) => async (dispatch) => {
  const res = await axios.get(`/api/announcement/findById/${id}`);
  dispatch({
    type: GET_AD,
    payload: res.data,
  });
};

export const deleteAd = (id) => async (dispatch) => {
  await axios.delete(`/api/announcement/delete/${id}`);
  dispatch({
    type: DELETE_AD,
    payload: id,
  });
};
