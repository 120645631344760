import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  downloadCommentList,
  updateCommentStatus
} from '../../actions/commentActions';
import PropTypes from 'prop-types';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import LoadingIndicator from '../Reusable/LoadingIndicator';
import { Alert } from 'react-bootstrap';
import authorizationService from '../../securityUtils/authorizationService';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import ModalForAddEvaluatorMessage from '../../components/EvaluatorMessage/ModalForAddEvaluatorMessage';
import CommentTableServerFiltering from './CommentTableServerFiltering';

class CommentList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      showRestOfTheButtonText: '',
      showSentMessageNotification: false,
      showModalForAddEvaluatorMessage: false,
      selectedDocument: '',
      numberOfUnhandledCommentsClick: false,
    };
    this.handleCommentListSearch = this.handleCommentListSearch.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.setState({ isLoading: false });
  }
  onDownloadClick = (topicOfDiscussionId) => {
    const action = () => {
      this.props.downloadCommentList(topicOfDiscussionId);
    };
    confirmDialog(`${this.props.dictionary["CommentList.Preuzmi.Poruka"]}`, action, `${this.props.dictionary["CommentList.Preuzmi"]}`);
  };

  handleShowRestOfTheButtonText = () => {
    this.setState({ showRestOfTheButtonText: 1 });
  };
  handleHideRestOfTheButtonText = () => {
    this.setState({ showRestOfTheButtonText: -1 });
  };

  handleShowMesasageNotification = () => {
    this.setState({
      showSentMessageNotification: true,
    });

    setTimeout(() => {
      this.setState({
        showSentMessageNotification: false,
      });
    }, 3000);
  };

  showModalForAddEvaluatorMessage = (e) => {
    this.setState({
      showModalForAddEvaluatorMessage: true,
    });
  };

  handlePreviewClose = () => {
    this.setState({
      showModalForAddEvaluatorMessage: false,
    });
  };

  handleCommentListSearch = (document) => {
    let documentName =
      document.value == 'Сви документи'
        ? document.value
        : document.value + '.pdf';
    this.setState({
      selectedDocument: documentName,
    });
  };

  handleNumberOfUnhandledCommentsClick = () => {
    if (this.state.numberOfUnhandledCommentsClick == true) {
      this.setState({
        numberOfUnhandledCommentsClick: false,
      });
    } else {
      this.setState({
        numberOfUnhandledCommentsClick: true,
      });
    }
  };

  render() {

    const { isLoading } = this.state;
    const { id } = this.props.match.params;
    let commentList = this.props.comment.commentList;


    let restOfTheText =
      this.state.showRestOfTheButtonText == 1 ? (
        <span>{this.props.dictionary["CommentList.SugestijaModeratoru"]}</span>
      ) : (
        ''
      );

    let isDisabled = authorizationService.canAccessSuggestionButton(this.props.authRole)
      ? false
      : true;

    let listOfDocuments = [];

    commentList.forEach((element) => {
      let documentName = element.documentTitle
        ? element.documentTitle.substring(
            0,
            element.documentTitle.indexOf('.pdf')
          )
        : '';
      if (
        documentName != '' &&
        listOfDocuments.filter((d) => d == documentName)[0] == null
      ) {
        listOfDocuments.push(documentName);
      }
    });
    listOfDocuments.push('Сви документи');

    const documentList = listOfDocuments.map((documentName) => ({
      value: documentName,
      label: documentName,
    }));
    let options = [];
    for (let i = 0; i < documentList.length; i++) {
      options.push(documentList[i]);
    }

    if (
      this.state.selectedDocument &&
      this.state.selectedDocument != 'Сви документи'
    ) {
      commentList = commentList.filter(
        (c) => c.documentTitle == this.state.selectedDocument
      );
    }

    if (this.state.numberOfUnhandledCommentsClick == true) {
      commentList = commentList.filter((c) => !c.visibility);
    }

    let commentListPage = (
      <div className="container-fluid component-wrapper-fade-in">
        {this.state.showSentMessageNotification ? (
          <Alert
            className={
              this.state.showSentMessageNotification
                ? 'alert-style mt-2 col-md-3 alert-shown'
                : 'alert-style mt-2 col-md-3 alert-hidden'
            }
          >
            <p className="mt-1 regular-font">{this.props.dictionary["CommentList.PorukaUspesnoPoslata"]}</p>
          </Alert>
        ) : (
          ''
        )}
        {!isDisabled ? (
          <div
            onMouseOver={this.handleShowRestOfTheButtonText}
            onMouseLeave={this.handleHideRestOfTheButtonText}
            className="save-version-button-style"
            style={{
              right: '0',
              marginRight: '1vw',
              marginTop: '40vh',
              position: 'fixed',
              zIndex: '9999999',
            }}
            onClick={this.showModalForAddEvaluatorMessage}
          >
            <ChatRoundedIcon /> {restOfTheText}
          </div>
        ) : (
          ''
        )}
        <br></br>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div className="row col-md-11 m-auto">
            <div className="col-md-12">
              <div className="text-left mb-2">
                <div className="form-row">
                  <h2 className="col-md-9 textShadow">Коментари</h2>
                  <div className="form-group col-md-3 float-right">
                  </div>
                </div>
                <CommentTableServerFiltering 
                  topicOfDiscussionId={id}
                  updateCommentStatus={this.props.updateCommentStatus}

                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
    return this.state.showModalForAddEvaluatorMessage == false ? (
      commentListPage
    ) : (
      <Fragment>
        {commentListPage}
        <ModalForAddEvaluatorMessage
          show={this.state.showModalForAddEvaluatorMessage}
          handleClose={this.handlePreviewClose}
          topicOfDiscussionId={id}
          handleShowMesasage={this.handleShowMesasageNotification}
        />
      </Fragment>
    );
  }
}

CommentList.propTypes = {
  comment: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  comment: state.comment,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  updateCommentStatus,
  downloadCommentList,
})(CommentList);
