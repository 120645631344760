import axios from "axios";
import {
  GET_ERRORS,
  GET_COMMENT_DOCUMENT,
  GET_COMMENT_DOCUMENT_LIST,
  DELETE_COMMENT_DOCUMENT,
} from "./types";

export const saveCommentDocument = (data, id) => async (dispatch) => {
  await axios.post(`/api/commentDocument/upload/${id}`, data);
};

export const getCommentDocuments = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/commentDocument/findAllDocumentsByCommentId/${id}`);
    dispatch({
      type: GET_COMMENT_DOCUMENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getCommentDocument = (documentName) => async (dispatch) => {
  const res = await axios.get(`/api/commentDocument/find/${documentName}`);
  dispatch({
    type: GET_COMMENT_DOCUMENT,
    payload: res.data,
  });
};

export const deleteCommentDocument = (id) => async (dispatch) => {
  axios.delete(`/api/commentDocument/delete/${id}`);
  dispatch({
    type: DELETE_COMMENT_DOCUMENT,
    payload: id,
  });
};
