import {
  GET_OGP_ACTION_PLAN_ARCHIVE_LIST,
  GET_OGP_ACTION_PLAN_ARCHIVE,
  DELETE_OGP_ACTION_PLAN_ARCHIVE,
} from '../actions/types';

const initialState = {
  ogpActionPlanArchiveList: [],
  ogpActionPlanArchive: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OGP_ACTION_PLAN_ARCHIVE_LIST:
      return {
        ...state,
        ogpActionPlanArchiveList: action.payload,
      };
    case GET_OGP_ACTION_PLAN_ARCHIVE:
      return {
        ...state,
        ogpActionPlanArchive: action.payload,
      };
    case DELETE_OGP_ACTION_PLAN_ARCHIVE:
      return {
        ...state,
        ogpActionPlanArchiveList: state.ogpActionPlanArchiveList.filter(
          (orf) => orf.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
