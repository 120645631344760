import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  deleteActionPlanReport,
  downloadActionPlanReport,
} from '../../actions/actionPlanReportActions';
import DeleteButton from '../Reusable/DeleteButton';
import { Tr, Td } from 'react-super-responsive-table';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import authorizationService from '../../securityUtils/authorizationService';
import { Link } from 'react-router-dom';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
import GetAppIcon from '@mui/icons-material/GetApp';

class ActionPlanReportRow extends Component {
  constructor() {
    super();
    this.state = {
      showActionPlanReportPreview: false,
    };
  }

  showActionPlanReportPreview = () => {
    this.setState({ showActionPlanReportPreview: true });
  };
  handlePreviewClose = () => {
    this.setState({ showActionPlanReportPreview: false });
  };

  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteActionPlanReport(id);
    };
    confirmDialog(`${this.props.dictionary["ActionPlanReportRow.Obrisi.Poruka"]}`,
                   action, 
                   `${this.props.dictionary["ActionPlanReportRow.Obrisi"]}`);
  };

  onDownloadClick = (uuidDocName, documentName) => {
    const action = () => {
      this.props.downloadActionPlanReport(uuidDocName, documentName);
    };
    confirmDialog(`${this.props.dictionary["ActionPlanReportRow.Preuzmi.Poruka"]}`,
                   action, 
                  `${this.props.dictionary["ActionPlanReportRow.Preuzmi"]}`);
  };

  render() {
    const { actionPlanReport } = this.props || {};
    const row = (
      <Tr>
        <Td>{actionPlanReport.documentName}</Td>
        <Td className="text-center">
          <Link onClick={(e) => {e.preventDefault();this.showActionPlanReportPreview();}} to="#">
            {this.props.dictionary["Document.Pogledaj"]}
          </Link>
        </Td>
        <Td className="text-center">
          <Link
            onClick={(e) => {
              e.preventDefault();
              this.onDownloadClick(
                this.props.actionPlanReport.uuidDocName,
                this.props.actionPlanReport.documentName
              );}
            }
            to="#"
          >
            <GetAppIcon className="previewButton" />
          </Link>
        </Td>
        {authorizationService.canAccessOGPPageSpecificAreas(
          this.props.authRole
        ) && (
          <Td className="text-center">
            <DeleteButton
              click={() => this.onDeleteClick(actionPlanReport.id)}
            />
          </Td>
        )}
      </Tr>
    );
    return (
      <Fragment>
        {row}
        {this.state.showActionPlanReportPreview && (
          <ModalForDocumentPreview
            show={this.state.showActionPlanReportPreview}
            documentName={actionPlanReport.uuidDocName}
            controllerReference={'actionPlanReport'}
            handleClose={this.handlePreviewClose}
          />
        )}
      </Fragment>
    );
  }
}

ActionPlanReportRow.propTypes = {
  deleteActionPlanReport: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps, {
  deleteActionPlanReport,
  downloadActionPlanReport,
})(ActionPlanReportRow);
