import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getUserOrganizationList,
  getUserOrganizationAdminList,
  crudIsFinished,
  crudInProgress,
} from "../../actions/userOrganizationActions";
import UserOrganizationTable from "./UserOrganizationTable";
import LoadingIndicatorOverlay from "../Reusable/LoadingIndicatorOverlay";
import { trackPromise } from "react-promise-tracker";
import CreateButtonModal from "../Reusable/CreateButtonModal";
import AddUserOrganization from "./AddUserOrganization";
import UpdateUserOrganization from "./UpdateUserOrganization";
import Modal from 'react-bootstrap/Modal';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Link } from 'react-router-dom';

class UserOrganizationList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      userId:null,
    };
  }

  componentDidMount() {
    const userid = this.props.authRole == 1 ? this.setUserIdForAdmin():this.setUserIdForCitizen();
    this.setState({userId:userid}); 
      if (userid!=this.props.userId) {
        trackPromise(
          this.props.getUserOrganizationAdminList(userid)
          ).finally(() => this.setState({ isLoading: false }));
        } else {
         trackPromise(this.props.getUserOrganizationList()).finally(() =>
          this.setState({ isLoading: false })
        );
        }
  }

  setUserIdForAdmin = () => {
    return this.props.match.params.userId;
  }

  setUserIdForCitizen = () => {
    return this.props.userId;
  }

  updateData() {  
    this.props.crudIsFinished();
    if (this.state.userId!=this.props.userId) {
        trackPromise(
      this.props.getUserOrganizationAdminList(this.state.userId)
      ).finally(() => this.setState({ isLoading: false }));
    } else {
     trackPromise(this.props.getUserOrganizationList()).finally(() =>
      this.setState({ isLoading: false })
    );
  }
}


  render() {
    const { isLoading } = this.state
    const { userOrganizationList } = this.props.userOrganization;
    const userId = this.state.userId;
    if(this.props.userOrganization.userOrganizationCRUDCompleted && (this.props.userOrganization.userOrganizationAdding || this.props.userOrganization.userOrganizationUpdateing)){
      this.updateData();
    };
    let isAccessedFromAdminPanel = this.props.authRole == 1 ? true : false;
    return isLoading ? (
      <LoadingIndicatorOverlay/>
    ) : (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="modalForUserOrganizationList"
      >
        <Modal.Header className="modalHeader">
            <Modal.Title>
              {isAccessedFromAdminPanel?this.props.dictionary["menu.Profil.OrganizacijeZaAdmina"]:this.props.dictionary["menu.Profil.OrganizacijeZaGradjanina"]}
            </Modal.Title>
            <button onClick={this.props.handleClose} type="button" class="btn-close btn-close-white m-0 opacity-1" aria-label="Close"></button>
        </Modal.Header> 
        <Modal.Body> 
          <div className="container-fluid">
              {isAccessedFromAdminPanel == false ? (
                <div className="float-right">
                  <Link
                    onClick={()=>this.props.crudInProgress("create")}
                    className="default-label-text-link mb-1"
                    to="#"
                  >
                    <AddBoxIcon fontSize={'small'} />
                    <strong className="ps-1">{this.props.dictionary["UserOrganizationList.DodajOrganizaciju"]}</strong>
                  </Link>
                </div>
              ) : (
                ""
              )}
               <div>
                <UserOrganizationTable
                  userOrganizationList={userOrganizationList}
                  isAccessedFromAdminPanel={isAccessedFromAdminPanel}
                />
            </div>
        {this.props.userOrganization.userOrganizationAdding && (
                <AddUserOrganization
                  show={this.props.userOrganization.userOrganizationAdding}
                  handleClose={() => this.updateData()}
                />
        )}
        {this.props.userOrganization.userOrganizationUpdateing && (
          <UpdateUserOrganization
            show={this.props.userOrganization.userOrganizationUpdateing}
            handleClose={() => this.updateData()}
          />
        )}
      </div>
      </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.security.userId,
  authRole: state.security.authRole,
  user: state.user,
  dictionary:state.startUp.dictionary,
  userOrganization: state.userOrganization,
});

export default connect(mapStateToProps, {
  getUserOrganizationList,
  getUserOrganizationAdminList,
  crudIsFinished,
  crudInProgress,
})(UserOrganizationList);
