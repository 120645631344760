import React, { Component } from 'react';
import { connect } from 'react-redux';
import CountUp from 'react-countup';
import { Tabs, Tab } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import moment from 'moment';
import OgpFieldOfInterestComboBox from '../OgpFieldOfInterest/OgpFieldOfInterestComboBox';
import OGPInstitutionComboBox from './OGPInstitutionComboBox';

class OGPStats extends Component {
  constructor(props) {
    super();
    this.state = {
      activeTabStats: '',
      total: '',
      accepted: '',
      refused: '',
      initialStart: 0,
      startDate: '',
      endDate: '',
      calculation: false,
      dateParam: '',
      fieldId: '',
      institutionName: '',
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleSelect = (selectedTab) => {
    this.setState({
      activeTabStats: selectedTab,
    });
  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  handleDateChange = (date) => {
    this.setState({ dateParam: date });
  };

  onFieldChange = (e) => {
    this.setState({ fieldId: e.target.value });
  };

  onInstitutionChange = (e) => {
    this.setState({ institutionName: e.target.value });
  };

  handleCalculation = (parameter) => {
    const { ogps } = this.props.ogp;
    let publicOgps = ogps.filter((o) => o.visibilityStatus);

    this.setState({ calculation: true });

    let ogpPerParameter;

    if (parameter == 'Period') {
      ogpPerParameter = publicOgps.filter(
        (o) =>
          o.creationDate &&
          moment(o.creationDate).isBetween(
            this.state.startDate,
            this.state.endDate
          )
      );
    } else if (parameter === 'Date') {
      ogpPerParameter = publicOgps.filter(
        (o) =>
          o.creationDate &&
          moment(o.creationDate).format('DD.MM.YYYY') ==
            moment(this.state.dateParam).format('DD.MM.YYYY')
      );
    } else if (parameter == 'Field') {
      ogpPerParameter = publicOgps.filter(
        (o) =>
          o.ogpFieldOfInterests &&
          o.ogpFieldOfInterests.filter((f) => f.id == this.state.fieldId)
            .length != 0
      );
    } else if (parameter == 'Institution') {
      ogpPerParameter = publicOgps.filter(
        (o) =>
          o.organisationName &&
          this.state.institutionName.includes(o.organisationName)
      );
    }

    let numberOfOgps = ogpPerParameter.length;
    let numberOfAcceptedOgps = ogpPerParameter.filter(
      (o) => o.status == 'Прихваћен'
    ).length;
    let numberOfRefusedOgps = ogpPerParameter.filter(
      (o) => o.status == 'Није прихваћен'
    ).length;

    return this.setState({
      total: numberOfOgps,
      accepted: numberOfAcceptedOgps,
      refused: numberOfRefusedOgps,
    });
  };

  render() {
    const { ogps } = this.props.ogp;
    let totalNumber = ogps
      ? ogps.filter((o) => o.visibilityStatus === true).length
      : 0;
    let totalOfAccepted = ogps
      ? ogps.filter((a) => a.status == 'Прихваћен').length
      : 0;
    let totalOfRefused = ogps
      ? ogps.filter((a) => a.status == 'Није прихваћен').length
      : 0;
    this.state.total = this.state.calculation ? this.state.total : totalNumber;
    this.state.accepted = this.state.calculation
      ? this.state.accepted
      : totalOfAccepted;
    this.state.refused = this.state.calculation
      ? this.state.refused
      : totalOfRefused;

    return !ogps ||
      ogps.length == 0 ||
      ogps.filter((o) => o.visibilityStatus).length == 0 ? (
      <div className="col-md-6 m-auto mt-3 component-wrapper-fade-in">
        <p className="faded-letter-info-paragraph">
          {this.props.dictionary["OGPStats.nemaDovoljnoPodataka"]}
        </p>
      </div>
    ) : (
      <div className="container-fluid stats-wrapper mt-3 component-wrapper-fade-in">
        <h3 className="text-center">{this.props.dictionary["OGPStats.predloziStatistika"]}</h3>
        <Tabs
          defaultActiveKey="date"
          activeKey={this.state.activeTabStats}
          className="row"
          onSelect={this.handleSelect}
        >
          <Tab
            eventKey="date"
            title={'По датуму'}
            tabClassName={
              this.state.activeTabStats == 'date'
                ? 'active-tab-phase-three'
                : 'default-tab-phase-three'
            }
          >
            <div className="row col-md-12 mt-3 component-wrapper-fade-in">
              <label className="labelText textShadow mt-1">
                {this.props.dictionary["OGPStats.label.dateParam"]}
              </label>
              <div className="col-md-4">
                <DatePicker
                  id="dateParam"
                  selected={this.state.dateParam}
                  onChange={this.handleDateChange.bind('dateParam')}
                  className="dateInput form-control"
                  dateFormat="dd.MM.yyyy"
                  placeholderText={this.props.dictionary["OGPStats.placeholder.dateParam"]}
                />
              </div>
              <div className="col- mt-1 ml-5">
                <Link
                  onClick={(e) => {
                    this.handleCalculation('Date');
                  }}
                  className="default-label-text-link"
                  to="#"
                >
                  <strong>{this.props.dictionary["OGPStats.prikazi"]}</strong>
                </Link>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="period"
            title={this.props.dictionary["OGPStats.tab.vremenskiPeriod"]}
            tabClassName={
              this.state.activeTabStats == 'period'
                ? 'active-tab-phase-three'
                : 'default-tab-phase-three'
            }
          >
            <div className="row col-md-12 mt-3 component-wrapper-fade-in">
              <label className="labelText textShadow mt-1">
                {this.props.dictionary["OGPStats.label.startDate"]}
              </label>
              <div className="col-md-3">
                <DatePicker
                  id="startDate"
                  selected={this.state.startDate}
                  onChange={this.handleStartDateChange.bind(
                    'startcreationDate'
                  )}
                  className="dateInput form-control"
                  dateFormat="dd.MM.yyyy"
                  placeholderText={this.props.dictionary["OGPStats.placeholder.startDate"]}
                />
              </div>
              <label className="labelText textShadow mt-1"> - </label>
              <div className="col-md-3">
                <DatePicker
                  id="endDate"
                  selected={this.state.endDate}
                  onChange={this.handleEndDateChange.bind('endDate')}
                  className="dateInput form-control"
                  dateFormat="dd.MM.yyyy"
                  placeholderText={this.props.dictionary["OGPStats.placeholder.endDate"]}
                />
              </div>
              <div className="col- mt-1 ml-5">
                <Link
                  onClick={(e) => {
                    this.handleCalculation('Period');
                  }}
                  className="default-label-text-link"
                  to="#"
                >
                  <strong>{this.props.dictionary["OGPStats.prikazi"]}</strong>
                </Link>
              </div>
            </div>
          </Tab>
          {/* <Tab
            eventKey="initiators"
            title={"По предлагачима (организација)"}
            tabClassName={
              this.state.activeTabStats == "initiators"
                ? "active-tab-phase-three"
                : "default-tab-phase-three"
            }
          >
            ПРЕДЛАГАЧ
          </Tab> */}
          <Tab
            eventKey="institution"
            title={this.props.dictionary["OGPStats.tab.institution"]}
            tabClassName={
              this.state.activeTabStats == 'institution'
                ? 'active-tab-phase-three'
                : 'default-tab-phase-three'
            }
          >
            <div className="row col-md-12 mt-3 component-wrapper-fade-in">
              <div className="col-md-9">
                <OGPInstitutionComboBox
                  ogpList={ogps}
                  onChange={this.onInstitutionChange}
                />
              </div>
              <div className="col- mt-1 ml-5">
                <Link
                  onClick={(e) => {
                    this.handleCalculation('Institution');
                  }}
                  className="default-label-text-link"
                  to="#"
                >
                  <strong>{this.props.dictionary["OGPStats.prikazi"]}</strong>
                </Link>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="fields"
            title={this.props.dictionary["OGPStats.tab.fields"]}
            tabClassName={
              this.state.activeTabStats == 'fields'
                ? 'active-tab-phase-three'
                : 'default-tab-phase-three'
            }
          >
            <div className="row col-md-12 mt-3 component-wrapper-fade-in">
              <div className="col-md-9">
                <OgpFieldOfInterestComboBox onChange={this.onFieldChange} />
              </div>
              <div className="col- mt-1 ml-5">
                <Link
                  onClick={(e) => {
                    this.handleCalculation('Field');
                  }}
                  className="default-label-text-link"
                  to="#"
                >
                  <strong>{this.props.dictionary["OGPStats.prikazi"]}</strong>
                </Link>
              </div>
            </div>
          </Tab>
        </Tabs>
        <div
          id="statsWrapper"
          className="container-fluid d-flex flex-row justify-content-evenly align-items-center"
        >
          <div className="form-group text-center col-md-4">
            <label className="textShadow">{this.props.dictionary["OGPStats.label.total"]}</label>
            <div className="counter-wrapper m-auto">
              <CountUp
                className="counter-style"
                start={this.state.initialStart}
                end={this.state.total}
                duration={2.75}
                useEasing={true}
                useGrouping={true}
              />
            </div>
          </div>
          <div className="form-group text-center col-md-4">
            <label className="textShadow">{this.props.dictionary["OGPStats.label.acepted"]}</label>
            <div className="counter-wrapper m-auto">
              <CountUp
                className="counter-style"
                start={this.state.initialStart}
                end={this.state.accepted}
                duration={2.75}
                useEasing={true}
                useGrouping={true}
              />
            </div>
          </div>
          <div className="form-group text-center col-md-4">
            <label className="textShadow">{this.props.dictionary["OGPStats.label.refused"]}</label>
            <div className="counter-wrapper m-auto">
              <CountUp
                className="counter-style"
                start={this.state.initialStart}
                end={this.state.refused}
                duration={2.75}
                useEasing={true}
                useGrouping={true}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ogp: state.ogp,
  dictionary:state.startUp.dictionary
});

export default connect(mapStateToProps, {})(OGPStats);
