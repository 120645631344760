import React, { Component } from 'react';
import UploadDocument from '../Reusable/UploadDocument';
import { saveOgpActionPlanArchive } from '../../actions/ogpActionPlanArchiveActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class UploadOgpActionPlanArchiveFile extends Component {
  render() {
    return (
      <UploadDocument 
        handleUpload={this.props.saveOgpActionPlanArchive}
        history={this.props.history}
        checkPDF={true}
        infoAboutInputFilesTooltip = {this.props.dictionary["tooltip.universal.infoAboutInputFilesJustPDF"]}
      />
    );
  }
}

UploadOgpActionPlanArchiveFile.propTypes = {
  saveOgpActionPlanArchive: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  saveOgpActionPlanArchive
})(UploadOgpActionPlanArchiveFile);

