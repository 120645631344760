import React, { Component } from "react";
import { connect } from "react-redux";
import { getDictionary, getTaxonomies } from "../../actions/startUpActions";
import { Row, Col, Container } from "react-bootstrap";
import LoadingIndicator from "../Reusable/LoadingIndicator";
import { trackPromise } from "react-promise-tracker";
import { gaKey } from "../../constants";
import ReactGA from "react-ga";

import AuthService from "../../securityUtils/AuthService";
import TopicOfDiscussionTableServerFilteringLandingPage from "../TopicOfDiscussion/TopicOfDiscussionTableServerFilteringLandingPage";
import WelcomePage from "./WelcomePage";

class LandingPage extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      errors: {},
    };
  }

  componentDidMount() {
    //ReactGA.initialize(gaKey);
    //ReactGA.pageview(window.location.pathname + window.location.search);
    trackPromise(
      this.props.getDictionary().then(this.props.getTaxonomies())
    ).finally(() => this.setState({ isLoading: false }));
  }

  handleLoginClick() {
    AuthService.login();
  }

  render() {
    const { isLoading } = this.state;
    return (
      <Container fluid className="dashboard">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Row>
            <Container className="col-md-12 m-auto">
              <Row>
                <br></br>
                {/* <WelcomePage></WelcomePage> */}
                <TopicOfDiscussionTableServerFilteringLandingPage />
                <br></br>
              </Row>
            </Container>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  dictionary: state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  getDictionary,
  getTaxonomies,
})(LandingPage);
