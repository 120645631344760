import React, { Component } from 'react';
import { connect } from 'react-redux';
import OgpReportFileRow from './OgpReportFileRow';
import authorizationService from '../../securityUtils/authorizationService';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';

class OgpReportFileTable extends Component {
  render() {
    let ogpReportFileList = this.props.ogpReportFileList.map(
      (ogpReportFile) => (
        <OgpReportFileRow
          key={ogpReportFile.id}
          ogpReportFile={ogpReportFile}
        />
      )
    );

    return (
      <div className="table-responsive component-wrapper-fade-in">
        <hr></hr>
        <Table id="example" className="table table-md border-0">
          <Thead>
            <Tr className="border-0">
              <Th className="border-0 boldText emphesized-label-font">
                {this.props.dictionary["OgpReportFileTable.PrilozenaDokumenta"]}
              </Th>
              <Th width="18%" className="text-center border-0">
              {this.props.dictionary["OgpReportFileTable.OstaviKomentar"]}
              </Th>
              {authorizationService.canAccessOGPPageSpecificAreas(
                this.props.authRole
              ) && (
                <Th width="9%" className="text-center border-0">
                  {this.props.dictionary["OgpReportFileTable.Brisanje"]}
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>{ogpReportFileList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps,{}) (OgpReportFileTable);
