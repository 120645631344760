import axios from "axios";

import {
  GET_ERRORS,
  GET_OGP_MODERATOR,
  GET_OGP_MODERATOR_LIST,
  DELETE_OGP_MODERATOR,
  CREATE_OGP_MODERATOR,
} from "./types";

export const createOgpModerator = (ogpModerator) => async (dispatch) => {
  try {
    const res = await axios.post("/api/ogpModerator/create", ogpModerator);
    dispatch({
      type: CREATE_OGP_MODERATOR,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateOgpModerator = (ogpModerator, history) => async (
  dispatch
) => {
  try {
    await axios.post("/api/ogpModerator/update", ogpModerator);
    history.push("/ogpModeratorList");
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

// export const getAllOgpModerator = (id) => async (dispatch) => {
//   const res = await axios.get(`/api/ogpModerator/findAll/${id}`);
//   dispatch({
//     type: GET_OGP_MODERATOR_LIST,
//     payload: res.data,
//   });
// };
export const getAllOgpModerator = (ogpId) => async (dispatch) => {
  const res = await axios.get(`/api/ogpModerator/findAll/${ogpId}`);
  dispatch({
    type: GET_OGP_MODERATOR_LIST,
    payload: res.data,
  });
};

export const getOgpModerator = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpModerator/findById/${id}`);
    dispatch({
      type: GET_OGP_MODERATOR,
      payload: res.data,
    });
  } catch (e) {
    e.getMessage();
  }
};

export const deleteOgpModerator = (id) => async (dispatch) => {
  await axios.delete(`/api/ogpModerator/delete/${id}`);
  dispatch({
    type: DELETE_OGP_MODERATOR,
    payload: id,
  });
};
