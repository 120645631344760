import { GET_DOCUMENT, REMOVE_DOCUMENT } from "../actions/types";

const initialState = {
  dokument: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENT:
      return {
        ...state,
        dokument: action.payload,
      };
    case REMOVE_DOCUMENT:
      return {
        ...state,
        dokument: action.payload,
      };

    default:
      return state;
  }
}
