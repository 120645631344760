import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class UploadDocumentInitialisedFiles extends Component {
  onFileChange = (e) => {
    this.props.onFileChange(e);
  };

  render() {
    let filePreview;
    let titles = '';
    if (this.props.uploadedFiles.length > 0) {
      for (let i = 0; i < this.props.uploadedFiles.length; i++) {
        titles += this.props.uploadedFiles[i].name + ';' + '   ';
      }
      filePreview = (
        <label className="fileName textShadow labelText">{titles}</label>
      );
    } else {
      filePreview = (
        <label className="faded-text fileName labelText">
          <em>{this.props.dictionary["UploadDocumentInitialisedFiles.noFiles"]}</em>
        </label>
      );
    }

    return (
      <div className="container-fluid mt-2">
        <div className="form-row">
          <div className="form-row col-md-12 text-left m-auto">
            <label id="inputFileRemoveDefault" className="col-md-1">
              <input
                id="fileChooser"
                type="file"
                multiple
                onChange={this.onFileChange}
              />
              <i className="fas fa-cloud-upload-alt fa-2x fa-pull-right uploadButton mt-2"></i>
            </label>
            <label className="col-md-11 text-center">
              {filePreview}
              <hr />
            </label>
          </div>
        </div>
      </div>
    );
  }
}

UploadDocumentInitialisedFiles.propTypes = {
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  dictionary:state.startUp.dictionary
});

export default connect(
  mapStateToProps,
  {}
)(UploadDocumentInitialisedFiles);
