import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Input from '../Reusable/Input';
import { createInstitution } from '../../actions/institutionActions';
import { NotificationManager } from 'react-notifications';
import { setMessage } from '../../utils';
const ModalForAddInstitution = (props)=>{
    const dispatch = useDispatch();
    const dictionary = useSelector(state => state.startUp.dictionary);

    const [institution, setInstitution] = useState({
        institutionName : ""
    });
    const [errors, setErrors] = useState({});

    const onChange = (e) => {
        const {name,value} = e.target;
        setInstitution(prevInst => ({...prevInst, [name]:value}));
    };

    const handleClose = (refresh) => {
        setInstitution({
            institutionName : ""
        });
        props.handleClose(refresh);
    };

    const handleValidation = () => {
        let error = false;
    
        if (institution.institutionName == '') {
          dispatch(setMessage(
            `${dictionary["UpdateInstitution.NazivInstitucije.Validacija"]}`,
            '#institutionName',
            '#validationErrorInstitutionName'
          ));
          error = true;
        }
    
        return error;
    };

    const handleSubmit = (e) => {
        
        e.preventDefault();
        
        if (handleValidation() == true) {
          return;
        }

        dispatch(createInstitution(institution))
        .then( () => {
          NotificationManager.success(dictionary["AddInstitution.UspesnoDodavanje"],dictionary["universal.Uspesno"], 5000);
        })
        .catch( (err) => {
          NotificationManager.error(dictionary["AddInstitution.DodavanjeNeuspelo"],dictionary["universal.GreskaUObradi"], 5000);
        })
        .finally ( () => { 
            handleClose(true); 
        });
    };

    return(
        <Modal
            show={props.show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Body>
                <h4 className='text-center'>{dictionary["AddInstitution.UnosInstitucije"]}</h4>
                <hr/>
                <div className="col-md-12">
                    <div className="form-group">
                      <label className="textShadow">{dictionary["AddInstitution.NazivInstitucije"]}:</label>
                      <Input
                        type="text"
                        id="institutionName"
                        placeholder={dictionary["AddInstitution.NazivInstitucije.Placeholder"]}
                        name="institutionName"
                        value={institution.institutionName}
                        onChange={onChange}
                        errors={errors.institutionName}
                      />
                      <div id="validationErrorInstitutionName" />
                    </div>
                </div>
                <div className="col-md-12 mt-4">
                    <button
                      id="subscribeButton"
                      className="col-md-2 btn-mr"
                      onClick={handleSubmit}
                    >
                        {dictionary["AddInstitution.Sacuvaj"]}
                    </button>
                    <button id="subscribeButton"
                            className="col-md-2 float-right"
                            onClick={handleClose}
                    >
                        {dictionary["AddInstitution.Odustani"]}
                    </button>
                  </div>
            </Modal.Body>
            </Modal.Header>
        </Modal>
    );
} 
export default ModalForAddInstitution;