import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOgps, findAllOgpsPageble } from '../../actions/ogpActions';
import PropTypes from 'prop-types';
import OgpTable from './OgpTable';
import { Link } from 'react-router-dom';
import authorizationService from '../../securityUtils/authorizationService';
import moment from 'moment';
import { TablePagination } from '@mui/material';
import { paragrapghPagination, changePaginationText } from '../../utils';
import { rowsPerPageOptions, numberOfItemsPerPage } from '../../constants';
class OgpList extends Component {
  constructor() {
    super();
    this.state = {
      activePage: 0,
      numberOfItemsPerPage: numberOfItemsPerPage,
    };
  }

  componentDidMount() {
    this.props.findAllOgpsPageble(0, this.state.numberOfItemsPerPage);
  }

  handlePageChange = (event, value) => {
    this.setState({
      activePage: value,
    });
    this.props.findAllOgpsPageble(value, this.state.numberOfItemsPerPage);
  };

  handleChangeRowsPerPage = (event, value) => {
    this.props.findAllOgpsPageble(0, event.target.value);

    this.setState({
      numberOfItemsPerPage: event.target.value,
      activePage: 0,
    });
  };

  render() {
    const { ogps, totalElements } = this.props.ogp;
    let visibleOGPs = ogps.filter(
      (o) =>
        (o.user && this.props.userId == o.user.id) ||
        o.visibilityStatus == true
    );
    let visibleAdminOGPs = ogps.filter((o) => o.visibilityStatus != false);
    let ogpPublicList = authorizationService.canAccessOGPPageSpecificAreas(
      this.props.authRole
    )
      ? visibleAdminOGPs
      : visibleOGPs;

    let requestIssues =
      this.props.requestIssuesFinalDate &&
      moment(new Date()).isBefore(moment(this.props.requestIssuesFinalDate));

    return (
      <div
        className="container-fluid component-wrapper-fade-in"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {!authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) &&
        this.props.authRole && this.props.authRole>0 &&
        requestIssues ? (
          <div className="form-row">
            <Link to={`/addOgp`} className="m-auto col-md-4">
              <button className="create-button col-md-12">
                {this.props.dictionary["OgpList.create"]}
              </button>
            </Link>
          </div>
        ) : (
          ''
        )}
        <OgpTable
          ogps={ogpPublicList}
          user={this.props.user}
        />
        <div className="col- float-right text-right"></div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TablePagination
            className="text-shadow"
            component="div"
            count={Number(totalElements)}
            page={this.state.activePage}
            onPageChange={this.handlePageChange}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            rowsPerPage={this.state.numberOfItemsPerPage}
            labelRowsPerPage={paragrapghPagination('Број редова')}
            labelDisplayedRows={(obj) => changePaginationText(obj)}
          />
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  ogp: state.ogp,
  user: state.user,
});

export default connect(mapStateToProps, {
  getOgps,
  findAllOgpsPageble,
})(OgpList);
