
import React, {useEffect, useMemo, useState} from "react";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import { getUsersPost } from '../../actions/userActions';
import { deactivateUser, activateUser } from "../../actions/userActions";
import { Link } from "react-router-dom";
import DeleteButton from "../Reusable/DeleteButton";
import UpdateButton from "../Reusable/UpdateButton";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import ActivateButton from "../Reusable/ActivateButton";
import { confirmDialog } from "../Reusable/ConfirmDialog";
import { setUserListTableFilter } from "../../actions/userActions";
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_SR_CYRL_RS } from 'mantine-react-table/locales/sr-Cyrl-RS';
import {
    NotificationManager,
  } from 'react-notifications';

const UserTableServerFiltering = (props) => {
    
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    //table state
    const [columnFilters, setColumnFilters] = useState(props.filter?.filters || []);
    const [globalFilter, setGlobalFilter] = useState(props.filter?.globalFilter || '');
    const [sorting, setSorting] = useState(props.filter?.sorting || [{ id: 'id', desc: false}]);
    const [pagination, setPagination] = useState( {
      pageIndex: props.filter?.start || 0,
      pageSize: props.filter?.size || 15,
    });
    const [reload, setReload] = useState(0);
    const [searchParams, setSearchParams] = useState({});

    const onDeleteClick = (id) => {
        const action = () => {
          trackPromise(props.deactivateUser(id))
          .catch(()=>{
            NotificationManager.error(props.dictionary["userList.neuspesnaDeaktivacijaKorisnika"]);
          })
          .then(()=>{
            NotificationManager.success(props.dictionary["userList.uspesnaDeaktivacijaKorisnika"]);
          }).finally(()=>{
            //bitno je samo da se promeni vrednost da bi se trigerovao useEffect
            setReload(Math.random());
          });
        };
        confirmDialog(props.dictionary["userList.confirm.deaktivirajPitanje"], action, props.dictionary["userList.confirm.deaktiviraj"]);
      };
    
      const onActivateClick = (id) => {
        const action = () => {
          trackPromise(props.activateUser(id))
          .catch(()=>{
            NotificationManager.error(props.dictionary["userList.neuspesnaAktivacijaKorisnika"]);
          })
          .then(()=>{
            NotificationManager.success(props.dictionary["userList.uspesnaAktivacijaKorisnika"]);
          }).finally(()=>{
            //bitno je samo da se promeni vrednost da bi se trigerovao useEffect
            setReload(Math.random());
          });
        };
        confirmDialog(
          props.dictionary["userList.confirm.aktivirajPitanje"],
          action,
          props.dictionary["userList.confirm.aktiviraj"]
        );
      };

    const columns = useMemo(
        () => [
          {
            accessorKey: 'id',
            header: props.dictionary["userList.column.id"],
            size:40,
          },
          {
            accessorKey: 'firstName',
            header: props.dictionary["userList.column.ime"],
            size:120,
          },
          {
            accessorKey: 'lastName',
            header: props.dictionary["userList.column.prezime"],
            size:110
          },
          {
            accessorKey: 'email',
            header: props.dictionary["userList.column.email"],
            size:140
          },
          {
            //accessorKey: 'institution.institutionName',
            accessorKey: "institutionName",
            header: props.dictionary["userList.column.institucija"],
            size:140,
          },
          {
            //accessorKey: 'role.roleName',
            accessorKey: "roleName",
            header: props.dictionary["userList.column.uloga"],
            size:160
          },
          {
            id: 'izmena',
            header: props.dictionary["userList.column.izmena"],
            size:40,
            columnDefType: 'display',
            enableColumnOrdering: true,
            accessorFn: (row) => row.id,
            mantineTableHeadCellProps: {
                align: 'center',
                className:'editColumn'
              },
              mantineTableBodyCellProps: {
                align: 'center',
              },
              Cell: ({row}) => (
                //<UpdateButton route={`/updateUser/${row.original.id}`} />
                <Link to='#' onClick={()=>{props.showModalForUpdateFunction(row.original.id)}}>
                <i className="fas fa-edit deleteButton"></i>
                </Link>
              )
          },
          // {
          //   id: 'organizacija',
          //   header: props.dictionary["userList.column.organizacija"],
          //   size:40,
          //   columnDefType: 'display', //turns off data column features like sorting, filtering, etc.
          //   enableColumnOrdering: true,
          //   accessorFn: (row) => row.id,
          //   mantineTableHeadCellProps: {
          //       align: 'center',
          //       className:'editColumn'
          //     },
          //     mantineTableBodyCellProps: {
          //       align: 'center',
          //     },
          //     Cell: ({row}) => (
          //       <Link to={`/userOrganizationAdminList/${row.original.id}`}>
          //       <FormatListBulletedRoundedIcon className="previewButton" />
          //     </Link>
          //     )
          // },
          {
            id: 'deaktivacija',
            header: props.dictionary["userList.column.deaktivacija"],
            size:40,
            columnDefType: 'display',
            enableColumnOrdering: true, 
            accessorFn: (row) => row.id,
            mantineTableHeadCellProps: {
              align: 'center',
              className:'editColumn'
            },
            mantineTableBodyCellProps: {
              align: 'center',
            },
            Cell: ({ row }) => (
                row.original.active === true ? (
                    <DeleteButton
                      deactivation={true}
                      click={() => onDeleteClick(row.original.id)}
                    />
                  ) : (
                    <ActivateButton
                      click={() => onActivateClick(row.original.id)}
                    />
                  )
            )
          }
        ],
        [],
      );
      useEffect(() => {
        if (!props.userList?.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }
        const searchParams = { 
          start: pagination.pageIndex,
          size: pagination.pageSize,
          filters: columnFilters ?? [],
          globalFilter: globalFilter ?? '',
          sorting: sorting ?? []
        };
        setSearchParams(searchParams);
        props.setUserListTableFilter(searchParams);
        trackPromise(props.getUsersPost(searchParams))
        .catch(() => {
           setIsError(true);
           NotificationManager.error(props.dictionary["universal.NeuspesnoUcitavanjePodataka"]);
          })
        .then( () => {
          setIsError(false);
        })
        .finally(() => {
          setIsLoading(false);
          setIsRefetching(false);
        }
      );
    }, [
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      reload,
      props.refresh
    ]);
      const customTable = 'custom-table';
      const customPaper = 'custom-paper';
      return   (
        <>
        <MantineReactTable
          localization={MRT_Localization_SR_CYRL_RS}
          enableGlobalFilter = {false}
          columns={columns}
          data={props.userList}
          displayColumnDefOptions= {{ 'mrt-row-numbers': { size:10, align:'center' } }}
          mantineTableProps={{
            className: {customTable},
            striped:true,
            tablelayout: 'fixed',
          }}
          mantineProgressProps={{
            striped:false,
            animate:true,
            size:'xs'
          }}
          mantinePaperProps={{
            className:{customPaper}
          }}
          //enableRowNumbers
          initialState={{ showColumnFilters: true }}
          manualFiltering
          manualPagination
          manualSorting
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={props.rowCount}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
        </>
      );
}
const mapStateToProps = (state) => {
    return {
    dictionary:state.startUp.dictionary,
    filter: state.user?.userListTableFilter,
    userList: state?.user?.users?.content ?? [],
    rowCount: state?.user?.users?.totalElements ?? 0
    
  } };
  
  export default connect(mapStateToProps, {
    getUsersPost,
    deactivateUser,
    activateUser,
    setUserListTableFilter
  })(UserTableServerFiltering);