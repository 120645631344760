import { useSelector } from "react-redux"
import Input from '../Reusable/Input';
import MethodDocuments from "../MethodDocument/MethodDocuments";

const  Survey = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary)
    return (
        <>
            <div className="form-group">
                <label className="labelText textShadow">
                    {dictionary["ModalForAddNewMethod.Naslov"]}: <span className="redAsterisk"> *</span>
                </label>
                <Input
                    type="text"
                    id="title"
                    placeholder={dictionary["ModalForAddNewMethod.Placeholder.Naslov"]}
                    name="title"
                    value={props.title}
                    onChange={(e) => props.setTitle(e.target.value)}
                    //errors={errors.title}
                />
                <div id="titleМsg" />
            </div>
            <MethodDocuments
                methodDocuments = {props.methodDocuments}
                uploadedFiles={props.uploadedFiles}
                setUploadedFiles={props.setUploadedFiles}
                selectedFieldsOfInterest={[]}
                phaseId={props.phase.id}
                documents={props.documents}
                setDocuments={props.setDocuments}
            />
        </>
    )
}

export default Survey;