import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import TextArea from '../Reusable/TextArea';
import { findMethodById } from "../../actions/methodActions";
import { setMessage, returnFileFormat } from '../../utils';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { getTopicOfDiscussion } from '../../actions/topicOfDiscussionActions';
import SurveyQuestion from './SurveyQuestion';
import { getSurveyQuestionList } from '../../actions/surveyQuestionsActions';
import { trackPromise } from 'react-promise-tracker';
import { createSurveyQuestions } from '../../actions/surveyQuestionsActions';
import ModalForSurveyPublish from './ModalForSurveyPublish';
import { updateMethod } from '../../actions/methodActions';
import { getMethodDocuments } from '../../actions/methodActions';
import { NotificationManager } from 'react-notifications';
import MethodDocuments from '../MethodDocument/MethodDocuments';

class ModalForSurveyUpdate extends React.Component {
  constructor() {
    super();
    this.state = {
      title: '',
      creationDate: '',
      closingDate: '',
      errors: {},
      uploadedFiles: [],
      uploadInProgress: false,
      surveyQuestionsList: [],
      showAdditionalOptions : false,
      showSurveyData: false,
      showModalForSurveyPublish: false,
      moderatorReadOnly: true,
      documents:[]
    };
  }

  componentDidMount() {
    const id = this.props.method?.id;
    this.props.findMethodById(id);
    // this.props.getMethodDocuments(id);
    trackPromise(this.props.getSurveyQuestionList(id)
                .then(()  => {this.setState({surveyQuestionsList: this.props.surveyQuestions.surveyQuestionsList})})
                );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const {
      id,
      title,
      startDate,
      topicOfDiscussion,
      phaseId,
      endDate,
      visible,
    } = nextProps.survey;

    this.setState({
      id,
      title,
      startDate: startDate ? moment(startDate).toDate() : '',
      topicOfDiscussion,
      phaseId,
      endDate: endDate ? moment(endDate).toDate() : null,
      visible,
      moderatorReadOnly: this.props.survey.startDate
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  setDocuments = (e) => {
    this.setState({documents: e})
  }

  handleClose = () => {
    this.props.handleMethodRefresh(this.props.phase.id);
    this.props.handleClose();
  };

  handleCreationDateChange = (date) => {
    this.setState({ creationDate: date });
  };

  handleClosingDateChange = (date) => {
    this.setState({ endDate: date });
  };

  onFileChange = (e) => {
    this.setState({
      uploadedFiles: e
    });

    for (let i = 0; i < e.length; i++) {
      let format = returnFileFormat(e[i].name);
      if (!['pdf', 'docx'].includes(format.toLowerCase())) {
        this.props.setMessage(
          `${this.props.dictionary["ModalForSurveyUpdate.Validation.DokumentFormat"]}`,
          '#fileChooser',
          '#msg'
        );
        return;
      }
    }
  };

  handleValidation = () => {
    let error = false;

    if (!this.state.title || this.state.title == '' ) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForSurveyUpdate.Validation.Tema"]}`,
        '#title',
        '#titleMsg'
      );
      error = true;
    }

    if (this.props.method.endDate && (!this.state.endDate || this.state.endDate == '' )) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForSurveyUpdate.Validation.EndDate"]}`,
        '#endDate',
        '#endDateMsg'
      );
      error = true;
    }

    if (this.state.uploadedFiles.length > 0) {
      let uploadedFilesSize = 0;
      for (let i = 0; i < this.state.uploadedFiles.length; i++) {
        uploadedFilesSize += this.state.uploadedFiles[i].size;
      }
      if (uploadedFilesSize >= 10000000) {
        this.props.setMessage(
          `${this.props.dictionary["ModalForSurveyUpdate.Validation.DokumentVelicina"]}`,
          '#fileChooser',
          '#uploadMsg'
        );
        error = true;
      }
    }

    return error;
  };


  handlePublishValidation = () => {
    let error = false
    if (this.state.surveyQuestionsList.length == 0) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForSurveyUpdate.Validation.BrojPitanja"]}`,
        '#fileChooser',
        '#publishMsg'
      )
      error = true;
    }
    const unsavedQuestions = this.state.surveyQuestionsList.filter((question) => question.id == 0);
    if (unsavedQuestions?.length == this.state.surveyQuestionsList.length) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForSurveyUpdate.Validation.BrojPitanja"]}`,
        '#fileChooser',
        '#publishMsg'
      )
      error = true;
    }
    return error;
  }

  handleSubmit =  (e) => {
    e.preventDefault();
    if (this.state.uploadInProgress) return;
    this.setState({ uploadInProgress: true });
    if (this.handleValidation() == true) {
      this.setState({ uploadInProgress: false });
      return;
    }


    let documentType = this.props.documentTypes.find(x => x.code == "Prilog");
    let existingDocuments = this.state.documents.map(document => ({ uuid: document.uuid }));
    const method = {
      id: this.props.survey.id,
      methodType: this.props.methodTypes.find(x => x.code == this.props.survey.methodType),
      title: this.state.title,
      description: this.props.survey.description,
      topicOfDiscussion: {
          id: this.props.survey.topicOfDiscussionId
      },
      creationDate: this.props.survey.creationDate,
      startDate: this.props.survey.startDate,
      endDate: this.state.endDate,
      location: this.props.survey.location,
      phase: {
          id: this.props.phase.id},
      linkPath: this.props.survey.linkPath,
      pinDate: this.props.survey.pinDate,
      hasArticles: false,
      documents: existingDocuments
  }
    let formData = new FormData();
    for (let i = 0; i < this.state.uploadedFiles.length; i++) {
      formData.append('file', this.state.uploadedFiles[i]);
      formData.append('name', this.state.uploadedFiles[i].name);
    }

    formData.append(
      'documenttype',
      new Blob([JSON.stringify(documentType)], {
        type: 'application/json',
      })
    );

    formData.append(
      'method',
      new Blob([JSON.stringify(method)], {
        type: 'application/json',
      })
    );
    
    trackPromise(this.props.updateMethod(formData))  
    .then( () => {
      NotificationManager.success(this.props.dictionary["ModalForUpdateMethod.UspesnaIzmena"],this.props.dictionary["universal.Uspesno"], 5000);
      this.handleClose();
      this.props.handleMethodRefresh(this.props.phase.id);
    })
    .catch( () => { 
      NotificationManager.error(this.props.dictionary["ModalForUpdateMethod.IzmenaNeuspela"], this.props.dictionary["universal.GreskaUObradi"], 5000);
    })    

    
  };


  addNewQuestion = (questionTypeId) => {
    const id = this.props.method.id;
    const topicOfDiscussionId = this.props.survey.topicOfDiscussionId;
    const newQuestion = {id: 0,
                         question: '', 
                         questionType: {id: questionTypeId},
                         method: {
                          id: id
                          }, 
                         methodId: id,
                         surveyAvailableAnswersList: [],
                         required: true,
                         multipleChoice: false
                        }
    this.setState({surveyQuestionsList: [...this.state.surveyQuestionsList, newQuestion]})
  }

   refreshQuestions = async (id) =>  {
    await this.props.getSurveyQuestionList(id)
    this.setState({surveyQuestionsList: this.props.surveyQuestions.surveyQuestionsList})
  }

  savePreviousQuestion = async () => {
    const question = this.state.surveyQuestionsList.find((quest) => quest.id == 0);
    if (question) {
      const error = this.handleQuestionValidation();
      if(!error) {
          await trackPromise(this.props.createSurveyQuestions(
            question
            ));
        await trackPromise(this.refreshQuestions(question.method.id));
        this.setState({showAdditionalOptions: true})
      } 
      } 
      else {
        this.setState({showAdditionalOptions: true})
      }
  }

  saveNewQuestion = async () => {
    await this.savePreviousQuestion();
  }
  
  handleQuestionValidation = () => {
    let error = false;
    const question = this.state.surveyQuestionsList[this.state.surveyQuestionsList.length-1];

    if (question.id == 0 && question.surveyAvailableAnswersList.length < 2 && question.questionType.id == 2) {
        this.props.setMessage(`${this.props.dictionary["SurveyQuestion.Validation.ZatvorenoPitanje"]}`, `#asnwers${this.state.surveyQuestionsList.length-1}`, `#answersMsg${this.state.surveyQuestionsList.length-1}`);
        error = true;
    }
    if (question.id == 0 && (!question.question || question.question == '' )) {
      this.props.setMessage(
        `${this.props.dictionary["SurveyQuestion.Validation.TekstPitanja"]}`,
        `#question${this.state.surveyQuestionsList.length-1}`, 
        `#questionMsg${this.state.surveyQuestionsList.length-1}`
      );
      error = true;
    }
    return error;
  }

  updateQuestion = (question) => {
    const questions = [...this.state.surveyQuestionsList];
    const index = questions.findIndex((item) => item.id == question.id);
    if (index != -1) {
      const updatedQuestion = {...questions[index], ...question}
      questions[index] = updatedQuestion;
    }

    this.setState({surveyQuestionsList: questions})
  }

  handleSurveyPublishModalClose = () => {
    this.setState({showModalForSurveyPublish: false});
  }

  showPublishModal = () => {
    if (!this.handlePublishValidation()) { 
      this.setState({showModalForSurveyPublish: true})
    }
  }



  render() {
    const { errors } = this.state;
    const status = this.props.method.active ? 'MethodStatus.UToku' : 
    this.props.method.endDate && new Date(this.props.method.endDate) < Date.now() ? 'MethodStatus.Zavrsena' : 
    'MethodStatus.UPripremi'
    return (
      <>
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className='modal-scroll'>
          <Modal.Body>
            
            {this.state.showSurveyData ? 
            <div className='card mb-4'>
              <button className='fas fa-solid fa-chevron-up only-icon pt-2 col-md-10 m-auto' onClick={() => {this.props.findMethodById(this.props.method?.id); this.setState({showSurveyData: false})}}></button>
              <div className="row ps-3 pe-3">
                <div className="col-md-12 float-start">
                  <div className="form-group">
                    <label className="labelText textShadow">{this.props.dictionary["ModalForSurveyUpdate.Tema"]}: <span className="redAsterisk"> *</span></label>
                    <TextArea
                      type="text"
                      id="title"
                      placeholder={this.props.dictionary["ModalForSurveyUpdate.Placeholder.Tema"]}
                      name="title"
                      value={this.state.title}
                      onChange={this.onChange}
                      errors={errors.title}
                      maxLength="1000"
                    />
                    <div id="titleMsg" />
                  </div>
                  <div className="ps-2 pt-2 form-group">
                  <label className="labelText textShadow pe-3">{this.props.dictionary["ModalForSurveyUpdate.StatusAnkete"]}:</label>
                  <span>{this.props.dictionary[status]}</span>
                </div>
                  <div className="d-flex flex-row justify-content-between pt-2"> 
                  {this.props.survey.startDate ?
                  <>
                    <div className="group ps-2 d-flex flex-column flex-fill">
                    <label className="labelText textShadow pe-3">{this.props.dictionary["ModalForSurveyUpdate.DatumKreiranja"]}:</label>
                    <span>{this.props.survey.startDate ? moment(this.props.survey.startDate).format('DD.MM.YYYY. HH:mm') : null}</span>
                    </div>
                    
                    <div className="group ps-2 d-flex flex-column flex-fill">
                      <label className="labelText textShadow pe-3">{this.props.dictionary["ModalForSurveyUpdate.DatumZatvaranja"]}<span className="redAsterisk"> *</span></label>
                     <DatePicker
                        id="endDate"
                        selected={this.state.endDate}
                        onChange={this.handleClosingDateChange.bind('endDate')}
                        className="form-control"
                        dateFormat="dd.MM.yyyy HH:mm"
                        placeholderText={this.props.dictionary["ModalForSurveyUpdate.Placeholder.DatumZatvaranja"]}
                        minDate={this.props.survey.startDate ? new Date(this.props.survey.startDate) : null}
                        maxDate={moment(this.props.phase.endDate).toDate()}
                        showTimeInput
                        timeInputLabel={this.props.dictionary["DatePicker.TimeInputLabel"]}
                      /> 
                      <div id="endDateMsg" />
                    </div>
                    </>
                  : ''}
                </div>
                </div>
              </div>

                <div className="ps-4 pe-4">
                  <MethodDocuments
                  methodDocuments = {this.props.methodDocuments}
                  uploadedFiles={this.state.uploadedFiles}
                  setUploadedFiles={this.onFileChange}
                  phaseId={this.props.phase.id}
                  documents={this.props.documents}
                  setDocuments={this.setDocuments}
                  methodId={this.props.method.id}
                  selectedMethodType={this.props.selectedMethodType}
                />
            </div>
              <div className="row mb-4 justify-content-center">
                <div className="col-md-3 ps-3 pe-3">
                  <button
                    type="button"
                    id="subscribeButton"
                    className="create-button btn-block w-100"
                    onClick={this.handleSubmit}
                  >
                    {this.props.dictionary["ModalForSurveyUpdate.Sacuvaj"]}
                  </button>
                </div>
                {!this.props.survey.startDate && (
                  <div className="col-md-3 ps-3 pe-3">
                    <button
                      className="create-button btn-block w-100"
                      onClick={() => {
                        this.showPublishModal()
                      }}

                    >
                      {this.props.dictionary["ModalForSurveyUpdate.ObjaviAnketu"]}
                    </button>
                  </div>
                )}
                <div id="publishMsg" className='text-center pt-3' />
              </div>
            </div> : 
            <div className="card d-flex mb-4">
              <div className='d-inline-flex justify-content-between ps-2 pt-2'>
                <div>
                  <div className="p-2">
                    <label className="labelText textShadow pe-3">{this.props.dictionary["ModalForSurveyUpdate.Tema"]}:</label>
                    <span>{this.state.title}</span>
                  </div>
                  <div className="p-2">
                  <label className="labelText textShadow pe-3">{this.props.dictionary["ModalForSurveyUpdate.StatusAnkete"]}:</label>
                  <span>{this.props.dictionary[status]}</span>
                  </div>
                </div>
                <div id="publishMsg" className='align-self-center'/>
                {!this.props.survey.startDate ? 
                <button className="create-button align-self-center" onClick={() => {this.showPublishModal()}}>{this.props.dictionary["ModalForSurveyUpdate.ObjaviAnketu"]}</button>
                : ''}
              </div>
              <button className='fas fa-solid fa-chevron-down only-icon pb-2 col-md-10 m-auto' onClick={() => {this.setState({showSurveyData: true})}}></button>
            </div>
            }
            
            <>
          {
            this.state.surveyQuestionsList.map((question, index) => (<SurveyQuestion key={question.id} 
            question={question}
            refreshQuestions={this.refreshQuestions}
            index={index}
            questionsLength={this.state.surveyQuestionsList.length}
            addNewQuestion={this.addNewQuestion}
            savePreviousQuestion={this.savePreviousQuestion}
            updateQuestion={this.updateQuestion}
            moderatorReadOnly={this.state.moderatorReadOnly}
            saveNewQuestion={this.saveNewQuestion}
            />))
          }
          {this.state.showAdditionalOptions ? 
            <div>
              <button className='create-button' onClick={() => {this.addNewQuestion(1); this.setState({showAdditionalOptions: false});}}>{this.props.dictionary["SurveyQuestion.DodajNovoPitanjeOtvoreno"]}</button>
              <button className='create-button' onClick={() => {this.addNewQuestion(2); this.setState({showAdditionalOptions: false});}}>{this.props.dictionary["SurveyQuestion.DodajNovoPitanjeZatvoreno"]}</button>
              <button className='create-button' 
                      onClick={() => {this.setState({showAdditionalOptions: false})}}>x</button>
            </div> : ''
          }
          {!this.state.showAdditionalOptions  && !this.props.survey.startDate ?
          <button className='create-button' 
                   onClick={() => {this.savePreviousQuestion();}}>{this.props.dictionary["SurveyQuestion.DodajNovoPitanje"]}</button> : ''}

        </>
          </Modal.Body>
        </Modal.Header>
      </Modal>
         {this.state.showModalForSurveyPublish && <ModalForSurveyPublish show={this.state.showModalForSurveyPublish}
                                handleClose={this.handleSurveyPublishModalClose}
                                handleModalForUpdateClose={this.props.handleClose}
                                phase={this.props.phase}
                                survey={this.props.survey}
         />}
         </>
    );
  }
}

const mapStateToProps = (state) => ({
  survey: state.method.method,
  surveyQuestions: state.surveyQuestions,
  errors: state.errors,
  methodDocuments: state.method.method.documents,
  dictionary: state.startUp.dictionary,
  methodTypes: state.startUp.taxonomies.MethodType,
  documentTypes: state.startUp.taxonomies.DocumentType
});

export default connect(mapStateToProps, {
  getTopicOfDiscussion,
  setMessage,
  getSurveyQuestionList,
  createSurveyQuestions,
  findMethodById,
  updateMethod,
  getMethodDocuments
})(ModalForSurveyUpdate);
