import React, { Component } from 'react';
import UploadDocument from '../Reusable/UploadDocument';
import { uploadActionPlan } from '../../actions/actionPlanActions';
import { connect } from 'react-redux';

class UploadActionPlan extends Component {

  render() {
    return (
      <UploadDocument 
        handleUpload={this.props.uploadActionPlan}
        history={this.props.history}
        checkPDF={true}
      />
    );
  }
}

export default connect(null, {
  uploadActionPlan
})(UploadActionPlan);


