import React, { Component } from 'react';
import { connect } from 'react-redux';
import OgpActionPlanArchiveRow from './OgpActionPlanArchiveRow';
import authorizationService from '../../securityUtils/authorizationService';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';

class OgpActionPlanArchiveTable extends Component {
  render() {
    let ogpActionPlanArchiveList = this.props.ogpActionPlanArchiveList.map(
      (ogpActionPlanArchive) => (
        <OgpActionPlanArchiveRow
          key={ogpActionPlanArchive.id}
          ogpActionPlanArchive={ogpActionPlanArchive}
        />
      )
    );

    return (
      <div className="table-responsive component-wrapper-fade-in">
        <hr></hr>
        <Table id="example" className="table table-md border-0">
          <Thead>
            <Tr className="border-0">
              {/*<Th className="border-0 boldText emphesized-label-font">
                Период
    </Th>*/}
              <Th className="border-0 boldText emphesized-label-font">
                {this.props.dictionary["OgpActionPlanArchiveTable.PrilozenaDokumenta"]}
              </Th>
              <Th width="9%" className="text-center border-0">
              {this.props.dictionary["OgpActionPlanArchiveTable.Pregled"]}
              </Th>
              <Th width="9%" className="text-center border-0">
              {this.props.dictionary["OgpActionPlanArchiveTable.Preuzimanje"]}
              </Th>
              {authorizationService.canAccessOGPPageSpecificAreas(
                this.props.authRole
              ) && (
                <Th width="9%" className="text-center border-0">
                  {this.props.dictionary["OgpActionPlanArchiveTable.Brisanje"]}
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>{ogpActionPlanArchiveList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps,{}) (OgpActionPlanArchiveTable);
