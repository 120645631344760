import React, { Component } from 'react';
import ComboReusable from '../Reusable/ComboReusable';
import NotificationTopicOfDiscussionTable from '../NotificationTopicOfDiscussion/NotificationTopicOfDiscussionTable';
import { connect } from 'react-redux';
import Select from 'react-select';

class NotificationsByFieldOfInterestTab extends Component {
  render() {
    const fieldOfInterestByUserIdList = this.props.fieldOfInterestByUserIdList.map((item) => ({
      ...item,
      value: item.fieldOfInterest.id,
      label: item.fieldOfInterest.name + ' ('+item.notificationNumber+')',
    }));
    const customStyleForMultiselect = {
      control: (styles) => ({ ...styles, minHeight: '45px' }),
      option: (styles) => ({
        ...styles,
        color: '#495057',
        fontSize: '17px',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
        marginLeft: '12px',
      }),
      input: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '1.25rem',
        marginLeft: '12px',
      }),
    };
    const listScroller = {
      overflow:'auto',
      height:'70vh'
    }
    return (
      <div style={listScroller} className="p-3">
        <Select
          id="fieldOfInterest"
          options={fieldOfInterestByUserIdList}
          placeholder={this.props.dictionary["ModalForTopicOfDiscussion.Placeholder.Oblast"]}
          onChange={this.props.handleComboNotificationsByFieldOfInterest()}
          styles={customStyleForMultiselect}
        />
        <p className="ml-3 mt-2 medium-font">
          <em>{this.props.dictionary["NotificationsByFieldOfInterestTab.Pregled"]}</em>
        </p>
        <NotificationTopicOfDiscussionTable
          quickPreviewSignal={1}
          filteredByCriteriaSignal={'fieldOfInterest'}
          notificationTopicOfDiscussionList={
            this.props.notificationByFiledOfInterestList
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {})(NotificationsByFieldOfInterestTab);

