import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useDispatch, useSelector } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';
import {pin, unpin} from '../../actions/methodActions';


const MethodCardLeftBar = ({isModerator, endProcess, method}) => {

    const dictionary = useSelector(state => state.startUp.dictionary);

    const dispatch = useDispatch();

    const pinMethod = (e) => {
        e.preventDefault();
        trackPromise(dispatch(pin(method)));
    }
    const unpinMethod = (e) => {
        e.preventDefault();
        trackPromise(dispatch(unpin(method)));
    }

    return (
        <div className="d-flex flex-column decorator-container">
            {isModerator && !endProcess ? (
                method.pinDate ? 
                    <>                                
                        <Link to="#" data-tooltip-id={`pinTooltip${method.id}`} onClick={(e) => {unpinMethod(e)}}>
                            <PushPinIcon fontSize="medium" className="tableFooterLinks pin"/>
                        </Link>
                        <Tooltip
                            id={`pinTooltip${method.id}`}
                            type="info"
                            effect="solid"
                            place="right"
                        >
                        {dictionary["Method.Tooltip.Unpin"]}
                        </Tooltip>
                    </> : 
                    <>
                        <Link to="#" data-tooltip-id={`unpinTooltip${method.id}`} onClick={(e) => {pinMethod(e)}} >
                            <PushPinOutlinedIcon fontSize="medium" className="tableFooterLinks pin"/>
                        </Link>
                        <Tooltip
                            id={`unpinTooltip${method.id}`}
                            type="info"
                            effect="solid"
                            place="right"
                        >
                        {dictionary["Method.Tooltip.Pin"]}
                        </Tooltip>
                    </>
            ) : (
                method.pinDate ? 
                <>
                    <PushPinIcon data-tooltip-id={`pinDisplayTooltip${method.id}`} className="tableFooterLinks pin"/> 
                    <Tooltip
                            id={`pinDisplayTooltip${method.id}`} 
                            type="info"
                            effect="solid"
                            place="right"
                        >
                        {dictionary["Method.Tooltip.PinDisplay"]}
                        </Tooltip>
                </>: null
            )}
            {method.participated && 
            <>
                <VerifiedIcon data-tooltip-id={`participatedTooltip${method.id}`}  className="participated pin mt-auto"/>
                <Tooltip
                            id={`participatedTooltip${method.id}`} 
                            type="info"
                            effect="solid"
                            place="right"
                        >
                        {dictionary["Method.Tooltip.Participated"]}
                </Tooltip>
            </>
            }
        </div>
    );
}

export default MethodCardLeftBar;