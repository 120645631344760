import axios from "axios";
import {
  GET_ERRORS,
  GET_OGP_DOCUMENT,
  GET_OGP_DOCUMENT_LIST,
  DELETE_OGP_DOCUMENT,
} from "./types";

export const saveOGPDocument = (data, history) => async (dispatch) => {
  await axios.post(`/api/ogpDocument/upload`, data);
  history.push(`/ogpPage/1`);
};

export const getOGPDocuments = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpDocument/findAll`);
    dispatch({
      type: GET_OGP_DOCUMENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOGPDocument = (documentName) => async (dispatch) => {
  const res = await axios.get(`/api/ogpDocument/find/${documentName}`);
  dispatch({
    type: GET_OGP_DOCUMENT,
    payload: res.data,
  });
};

export const deleteOGPDocument = (id) => async (dispatch) => {
  axios.delete(`/api/ogpDocument/delete/${id}`);
  dispatch({
    type: DELETE_OGP_DOCUMENT,
    payload: id,
  });
};

export const downloadOGPDocument = (uuidDocName, documentName) => async (
  dispatch
) => {
  axios({
    url: `/api/ogpDocument/download/${uuidDocName}`,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", documentName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  });
};
