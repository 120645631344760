import {
  GET_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST,
  GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST,
  GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST_DTO,
  GET_TOPICS_OF_DISCUSSION_BY_FIELDS_OF_INTEREST
} from "../actions/types";

const initialState = {
  topicOfDiscussionFieldsOfInterestList: [],
  topicOfDiscussionFieldsOfInterest: {},
  topicOfDiscussionFieldsOfInterestListDTO: [],
  topicsOfDiscussionByFieldsOfInterest:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST:
      return {
        ...state,
        topicOfDiscussionFieldsOfInterest: action.payload,
      };
    case GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST:
      return {
        ...state,
        topicOfDiscussionFieldsOfInterestList: action.payload,
      };
    case GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST_DTO:
      return {
        ...state,
        topicOfDiscussionFieldsOfInterestListDTO: action.payload,
      };
    case GET_TOPICS_OF_DISCUSSION_BY_FIELDS_OF_INTEREST: 
      return {
        ...state,
        topicsOfDiscussionByFieldsOfInterest: action.payload,
      }
    default:
      return state;
  }
}
