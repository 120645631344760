import React, { Component } from 'react';
import { connect } from 'react-redux';

import {downloadDictionary,uploadDictionary} from '../../actions/dictionaryActions';
import { setMessage } from "../../utils";
import { setNotification } from "../../utils";
import { trackPromise } from 'react-promise-tracker';
import {
    NotificationManager,

  } from 'react-notifications';
import DictionarySegment from './DictionarySegment';
import ManualHandler from './ManualHandler';
class DictionaryUtils extends Component {

    constructor() {
        super();
            this.state = {
                selectedFileUI: null,
                selectedFileEMAIL: null,
                selectedFileSPRING: null,
                fileNameUI: "",
                fileNameEMAIL: "",
                fileNameSPRING: "",
            };
    }
    componentDidMount() {
        const uploadMessage = (<label className="fileName textShadow labelText m-3">{this.props.dictionary["uploadCommentDocument.Placeholder"]}</label>);
        this.setState({
            uploadMessage: uploadMessage,
        }); 
        this.setState({
            fileNameUI: uploadMessage,
            fileNameSPRING: uploadMessage,
            fileNameEMAIL: uploadMessage,
        }); 
    }

    onFileChange = (e, type) => {
        switch(type){
            case "UI":
                this.setState(
                    {
                        selectedFileUI: e.target.files[0],
                        fileNameUI : (
                            <label className="fileName textShadow labelText m-3">{e.target.files[0].name}</label>
                        )
                    }
                );
                break;
                case "EMAIL":
                    this.setState(
                        {
                            selectedFileEMAIL: e.target.files[0],
                            fileNameEMAIL : (
                                <label className="fileName textShadow labelText m-3">{e.target.files[0].name}</label>
                            )
                        }
                    );
                    break;
                case "SPRING":
                    this.setState(
                        {
                            selectedFileSPRING: e.target.files[0],
                            fileNameSPRING : (
                                <label className="fileName textShadow labelText m-3">{e.target.files[0].name}</label>
                            )
                        }
                    );
                    break;
            default:
                break;
        }
        e.target.value=null;
    };

    uploadFiles = (e, type,idFileChooser, idmsg, uploadedFile) => {

        if (uploadedFile === null) {
          this.props.setMessage(
            this.props.dictionary["dictionary.UploadExcelNijeIzabran"],
            idFileChooser,
            idmsg
          );
          return;
        }

        if (uploadedFile.size >= 10000000) {
          this.props.setMessage(
            this.props.dictionary["dictionary.UploadExcelPrevelik"],
            idFileChooser,
            idmsg
          );
          return;
        }    
        const formData = new FormData();
        formData.append("file",uploadedFile);
        trackPromise(this.props.uploadDictionary(formData, type)).then(() => {
                NotificationManager.success(this.props.dictionary["dictionary.UploadExcelUspesan"], this.props.dictionary["universal.Uspesno"], 5000);
        }).catch(() => {
                NotificationManager.error(this.props.dictionary["dictionary.UploadExcelaNijeUspeo"], this.props.dictionary["universal.GreskaUObradi"], 5000);
        });
        switch(type){
            case "UI":
                this.setState(
                    {
                        selectedFileUI: null,
                        fileNameUI: (<label className="fileName textShadow labelText m-3">{this.props.dictionary["uploadCommentDocument.Placeholder"]}</label>),
                    }
                );
                break;
                case "EMAIL":
                    this.setState(
                        {
                            selectedFileEMAIL: null,
                            fileNameEMAIL: (<label className="fileName textShadow labelText m-3">{this.props.dictionary["uploadCommentDocument.Placeholder"]}</label>),
                        }
                    );
                    break;
                case "SPRING":
                    this.setState(
                        {
                            selectedFileSPRING: null,
                            fileNameSPRING: (<label className="fileName textShadow labelText m-3">{this.props.dictionary["uploadCommentDocument.Placeholder"]}</label>),
                        }
                    );
                    break;
            default:
                break;
        }
    };

    downloadFiles = (e, type, fileName) => {  
        trackPromise(this.props.downloadDictionary(type,fileName)).then(() => {
            NotificationManager.success(this.props.dictionary["dictionary.PreuzimanjeExcelUspesno"], this.props.dictionary["universal.Uspesno"], 5000);
        }).catch(() => {
            NotificationManager.error(this.props.dictionary["dictionary.PreuzimanjeExcelaNijeUspelo"], this.props.dictionary["universal.GreskaUObradi"], 5000);
        });
    };

    render() {
        return <div className="container-fluid component-wrapper-fade-in">
                <div className="text-center m-5">
                    <div className="justify-content-between">
                        <h3 className="textShadow  border border-info rounded p-3">{this.props.dictionary["dictionary.Explanation1"]}</h3>
                        <DictionarySegment
                            sectionName={this.props.dictionary["dictionary.Labele"] }
                            sectionCode="UI"
                            sectionFileNameForDownload={this.props.dictionary["dictionary.NazivFajlaSaLabelama"]}
                            sectionDownloadControlId="fileChooserUI"
                            messageControlId="msgUI"
                            sectionConcreteFileName={this.state.fileNameUI}
                            elementForFileChooseId="#fileChooserUI"
                            elementForMessageId="#msgUI"
                            selectedFileForUpload={this.state.selectedFileUI}
                            uploadFiles={this.uploadFiles}
                            downloadFiles={this.downloadFiles}
                            onFileChange={this.onFileChange}
                        />
                        <DictionarySegment
                            sectionName={this.props.dictionary["dictionary.Mailovi"]}
                            sectionCode="EMAIL"
                            sectionFileNameForDownload={this.props.dictionary["dictionary.NazivFajlaSaMailovima"]}
                            sectionDownloadControlId="fileChooserEMAIL"
                            messageControlId="msgEMAIL"
                            sectionConcreteFileName={this.state.fileNameEMAIL}
                            elementForFileChooseId="#fileChooserEMAIL"
                            elementForMessageId="#msgEMAIL"
                            selectedFileForUpload={this.state.selectedFileEMAIL}
                            uploadFiles={this.uploadFiles}
                            downloadFiles={this.downloadFiles}
                            onFileChange={this.onFileChange}
                        />
                        <DictionarySegment 
                            sectionName={this.props.dictionary["dictionary.Konstante"]}
                            sectionCode="SPRING"
                            sectionFileNameForDownload={this.props.dictionary["dictionary.NazivFajlaSaKonstantama"]}
                            sectionDownloadControlId="fileChooserSPRING"
                            messageControlId="msgSPRING"
                            sectionConcreteFileName={this.state.fileNameSPRING}
                            elementForFileChooseId="#fileChooserSPRING"
                            elementForMessageId="#msgSPRING"
                            selectedFileForUpload={this.state.selectedFileSPRING}
                            uploadFiles={this.uploadFiles}
                            downloadFiles={this.downloadFiles}
                            onFileChange={this.onFileChange}
                        />
                        <h5 className="textShadow mt-5">{this.props.dictionary["dictionary.Explanation5"]}</h5>
                        <h5 className="textShadow">{this.props.dictionary["dictionary.Explanation6"]}</h5>
                        <ManualHandler
                            sectionName={this.props.dictionary["dictionary.Uputstvo"]}
                        />
                    </div>
                </div>
            </div>;
        }
}

const mapStateToProps = (state) => ({
      authRole: state.security.authRole,
      dictionary:state.startUp.dictionary,
  });

  export default connect(mapStateToProps, { 
    downloadDictionary,
    uploadDictionary,
    setMessage,
    setNotification,
  }) (DictionaryUtils);