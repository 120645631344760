import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import authorizationService from '../../securityUtils/authorizationService';
import ViewAttachedPdfDocumentWithHighlights from './ViewAttachedPdfDocumentWithHighlights';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
const ViewDocumentButton = (props) => {
  const phaseActiveStatus = props.isActive == false ? 0 : 1;
  const processActiveStatus = props.isProcessFinished == true ? 0 : 1;
  const dictionary = useSelector(state => state.startUp.dictionary);
  const {authRole,userId} = useSelector(state => state.security);
  const isTopicOfDiscussionOpenForComments = useSelector(state=>state.topicOfDiscussion.isTopicOfDiscussionOpenForComments);
  const [showModalViewAttachedPdfDocumentWithHighlights, setShowModalViewAttachedPdfDocumentWithHighlights] = useState(false);
  const [showModalViewAttachment, setShowModalViewAttachment] = useState(false);
  let isUserModerator = props.processCreatorId ? authorizationService.canAccessProcessEditMode(
    authRole,
    userId,
    props.processCreatorId,
    props.topicOfDiscussionModerators
  ) : false;

  const showViewPdfAttachment = (e) => {
    setShowModalViewAttachedPdfDocumentWithHighlights(true);
  }
  const showAttachment= (e) => {
    setShowModalViewAttachment(true);
  }
  const handlePreviewClose = () => {
    setShowModalViewAttachedPdfDocumentWithHighlights(false);
    setShowModalViewAttachment(false);
  }
  return (
    <>
      {showModalViewAttachedPdfDocumentWithHighlights && (
      <ViewAttachedPdfDocumentWithHighlights
        show={showModalViewAttachedPdfDocumentWithHighlights}
        handleClose={handlePreviewClose}
        documentName={props.documentName}
        controllerReference={props.controllerReference}
        topicOfDiscussionId={props.topicOfDiscussionId}
        phaseNumber={props.phaseNumber}
        eventId={props.eventId}
        phaseActiveStatus={phaseActiveStatus}
        ogpPhase={props.ogpPhase}
      />
      )}
      {showModalViewAttachment && (
      <ModalForDocumentPreview
        show={showModalViewAttachment}
        documentName={props.documentName}
        controllerReference={props.controllerReference}
        handleClose={handlePreviewClose}
      />
      )}
    <div>
      {props.phaseNumber ? (
        (props.phaseNumber == '4' ||
          props.phaseNumber == '3' ||
          props.phaseNumber == '2' ||
          props.phaseNumber == '1') &&
        props.allowComments == 1 && isTopicOfDiscussionOpenForComments && authRole>0 && authRole!=2 && !isUserModerator && phaseActiveStatus==1 && processActiveStatus ? (
          <Link
          to="#"
          onClick={(e) => {e.preventDefault(); showViewPdfAttachment(e);}}
            //to={`/viewPdfAttachment/${props.documentName}/${props.controllerReference}/${props.topicOfDiscussionId}/${props.phaseNumber}/${props.eventId}/${phaseActiveStatus}`}
          >
            {dictionary["Document.Komentarisi"]}
          </Link>
        ) : (
          <Link
          to="#"
          onClick={(e) => {e.preventDefault(); showAttachment(e);}}
            //to={`/viewAttachment/${props.documentName}/${props.controllerReference}/${props.topicOfDiscussionId}/${props.phaseNumber}`}
          >
            {/* <i className="fas fa-search previewButton"></i> */}
            {dictionary["Document.Pogledaj"]}
          </Link>
        )
      ) : props.ogpPhase ? (
        <Link
          to="#"
          onClick={(e) => {e.preventDefault(); showViewPdfAttachment(e);}}
          //to={`/viewPdfAttachment/${props.documentName}/${props.controllerReference}/${props.ogpPhase}`}
        >
          {/* <i className="fas fa-search previewButton"></i> */}
          {dictionary["Document.Pogledaj"]}
        </Link>
      ) : (
        <Link
          to={`/viewDocument/${props.documentName}/${props.controllerReference}`}
          target="_blank"
        >
          {/* <i className="fas fa-search previewButton"></i> */}
          {dictionary["Document.Pogledaj"]}
        </Link>
      )}
    </div>
    </>
  );
};

export default ViewDocumentButton;
