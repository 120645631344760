import React, { Component } from "react";
import AdRow from "./AdRow";
import { connect } from "react-redux";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";

class AdTable extends Component {
  render() {
    let counter = 1;
    let adList = this.props.adList.map((ad) => (
      <AdRow
        key={ad.id}
        ad={ad}
        counter={counter++}
      />
    ));

    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-sm table-striped mt-2">
          <Thead>
            <Tr className="table-header-height">
              <Th width="5%" className="boldText emphesized-label-font">
                {this.props.dictionary["AdTable.RB"]}
              </Th>
              <Th className="boldText emphesized-label-font">{this.props.dictionary["AdTable.Datum"]}</Th>
              <Th className="boldText emphesized-label-font">{this.props.dictionary["AdTable.TekstObjave"]}</Th>
              <Th className="boldText emphesized-label-font">{this.props.dictionary["AdTable.Period"]}</Th>
              <Th className="text-center">{this.props.dictionary["AdTable.Status"]}</Th>
              <Th className="text-center">{this.props.dictionary["AdTable.Prilog"]}</Th>
            </Tr>
          </Thead>
          <Tbody>{adList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, { })(AdTable);
