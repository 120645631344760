import React, { Component } from "react";
import UploadDocument from '../Reusable/UploadDocument';
import { saveActionPlanReport } from "../../actions/actionPlanReportActions";
import { connect } from "react-redux";

class UploadActionPlanReport extends Component {

  render() {
    return (
      <UploadDocument 
        handleUpload={this.props.saveActionPlanReport}
        history={this.props.history}
      />
    );
  }
}

export default connect(null, {
  saveActionPlanReport
})(UploadActionPlanReport);
