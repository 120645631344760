
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllMethodParticipants } from "../../actions/methodParticipantActions";
import { useEffect } from "react";
import ExternalMembersTable from "./ExternalMembersTable";

const ModalForExternalMemberParticipants = (props) => {

    const dictionary = useSelector(state => state.startUp.dictionary);
    const methodParticipants = useSelector(state => state.methodParticipant.methodParticipantList);

    const dispatch = useDispatch();

    const handleClose = () => {
        props.handleClose();
    }

    useEffect(() => {
        dispatch(getAllMethodParticipants(props.methodId));
    }, [])

    return (
        <Modal
        show={props.show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
            <Modal.Header closeButton className="d-flex flex-column">
            <h3 className="text-center textShadow m-auto order-2">
                    {dictionary["ModalForExternalMemberParticipants.SpisakPrijavljenjih"]}
            </h3>
            </Modal.Header> 
            <Modal.Body className="modal-body-scroll">
                <ExternalMembersTable
                    methodParticipants={methodParticipants}
                    
                />
            </Modal.Body>
      </Modal>
      );
}

export default ModalForExternalMemberParticipants;