import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OgpModeratorRow from "./OgpModeratorRow";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";
import { Link } from "react-router-dom";

class OgpModeratorTable extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }

  handleItemAdd = (item) => {
    this.props
      .createOgpModerator(item)
      .then(() => this.setState({ isAdding: false }));
  };

  toggleAdding = () => {
    this.setState({ isAdding: !this.state.isAdding });
  };

  render() {
    const { isAdding } = this.state;

    let ogpModeratorList = this.props.ogpModeratorList
      ? this.props.ogpModeratorList.map((ogpModerator) => (
          <OgpModeratorRow
            key={ogpModerator.id}
            ogpModerator={ogpModerator}
            isOgpAdmin={this.props.isOgpAdmin}
          />
        ))
      : [];
    return (
      <div>
        <div className="mb-1">
          <label className="labelText textShadow">
            {this.props.dictionary["OgpModeratorTable.Moderatori"]}: <span className="redAsterisk"> *</span>
          </label>
          {this.props.isOgpAdmin === true && (
            <Link to="#" onClick={(e) => {
              e.preventDefault();
              this.toggleAdding();
            }}>
              {isAdding ? (
                <i className="fas fa-minus-circle editButton fa-2x fa-pull-right" />
              ) : (
                <i className="fas fa-plus-circle editButton fa-2x fa-pull-right" />
              )}
            </Link>
          )}
        </div>
        <div className="table-responsive">
          <Table id="example" className="table table-md">
            <Thead>
              <Tr className="align-items-center">
                <Th
                  width="50%"
                  className="font-weight-normal textShadow text-center"
                >
                  {this.props.dictionary["OgpModeratorTable.ImePrezimeMail"]}
                </Th>
                <Th
                  width="5%"
                  className="font-weight-normal textShadow text-center"
                >
                  {this.props.dictionary["OgpModeratorTable.Akcija"]}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isAdding ? (
                <OgpModeratorRow
                  usersModerators={this.props.usersModerators}
                  handleAdd={this.handleItemAdd}
                  ogp={this.props.ogp}
                />
              ) : (
                <Tr></Tr>
              )}
              {ogpModeratorList}
            </Tbody>
          </Table>
        </div>
      </div>
    );
  }
}

OgpModeratorTable.propTypes = {
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {})(OgpModeratorTable);
