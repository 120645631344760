import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tr, Td } from "react-super-responsive-table";

class SurveyAnswersRow extends Component {
  render() {
    const { answer } = this.props.surveyAnswers;
    return (
      <Tr>
        <Td className="textShadow">{this.props.counter + "."}</Td>
        <Td>{answer}</Td>
      </Tr>
    );
  }
}

export default SurveyAnswersRow;
