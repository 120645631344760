import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteMailSettings } from "../../actions/mailSettingsActions";
import DeleteButton from "../Reusable/DeleteButton";
import UpdateButton from "../Reusable/UpdateButton";
import authorizationService from "../../securityUtils/authorizationService";
import { Tr, Td } from "react-super-responsive-table";
import { confirmDialog } from "../Reusable/ConfirmDialog";

class MailSettingsRow extends Component {
  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteMailSettings(id);
    };
    confirmDialog(this.props.dictionary["MailSettingsRow.delete.confirmDialog.part1"], action, this.props.dictionary["MailSettingsRow.delete.confirmDialog.part2"]);
  };

  render() {

    return (
      <Tr>
        <Td className="textShadow">{this.props.mailSettings.mailAddress}</Td>
        <Td className="textShadow">{this.props.mailSettings.host}</Td>
        <Td className="textShadow">{this.props.mailSettings.port}</Td>
        {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
          <Td width="10%" className="text-center">
            <UpdateButton
              route={`/updateMailSettings/${this.props.mailSettings.id}`}
            />
          </Td>
        ) : (
          ""
        )}
        {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
          <Td width="10%" className="text-center">
            <DeleteButton
              click={() => this.onDeleteClick(this.props.mailSettings.id)}
            />
          </Td>
        ) : (
          ""
        )}
      </Tr>
    );
  }
}

MailSettingsRow.propTypes = {
  deleteMailSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  deleteMailSettings,
})(MailSettingsRow);
