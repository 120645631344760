import Modal from 'react-bootstrap/Modal';
import CommentDisplay from "./CommentDisplay"
import { useSelector } from 'react-redux';

const ModalForMethodComments = ({uuidDocumentName, methodId, show, handleClose}) => {
    
    const dictionary = useSelector(state => state.startUp.dictionary);

    return (
        <Modal
        show={show}
        onHide={handleClose}
        size="xxl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
            <Modal.Header closeButton className="d-flex flex-column">
            <h3 className="text-center textShadow m-auto order-2">
                    {dictionary["ModalForMethodComments.Komentari"]}
            </h3>
            </Modal.Header> 
            <Modal.Body className="modal-body-scroll">
                <CommentDisplay uuidDocumentName = {uuidDocumentName} methodId={methodId}/>
            </Modal.Body>
        </Modal>
    )
}

export default ModalForMethodComments;