import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { getAllModerators } from "../../actions/userActions";
import {
  createTopicOfDiscussionModerator,
  getAllTopicOfDiscussionModerator,
} from "../../actions/topicOfDiscussionModeratorActions";
import TopicOfDiscussionModeratorTable from "./TopicOfDiscussionModeratorTable";

class ModalForAddModerator extends React.Component {
  componentDidMount() {
    this.props.getAllModerators();
    this.props.getAllTopicOfDiscussionModerator(
      this.props.topicOfDiscussion.id
    );
  }

  handleClose = () => {
    this.props.handleClose();
  };

  render() {

    const { usersModerators } = this.props.user;
    let topicOfDiscussionModeratorList = [
      ...this.props.topicOfDiscussionModerator.topicOfDiscussionModeratorList,
    ];

    const availableUsers = usersModerators.filter(
      (u) => !topicOfDiscussionModeratorList.find((tm) => tm.user.id === u.id)
    );

    
    let moderatorName = this.props.topicOfDiscussion?.user != null ? this.props.topicOfDiscussion.user?.firstName +
    " " +
    this.props.topicOfDiscussion.user?.lastName : '';

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body style={{ minHeight: "48vh" }}>
            <div className="form-group">
              <label className="labelText textShadow pe-3">
              {this.props.dictionary["ModalForAddModerator.GlavniModerator"]}:
              </label>
              <label className="title-text-color">
                <strong>{moderatorName}</strong>
              </label>
            </div>
            <div className="form-group">
              <TopicOfDiscussionModeratorTable
                usersModerators={availableUsers}
                topicOfDiscussionModeratorList={topicOfDiscussionModeratorList}
                createTopicOfDiscussionModerator={
                  this.props.createTopicOfDiscussionModerator
                }
                topicOfDiscussion={this.props.topicOfDiscussion}
                isProcessFinished={this.props.isProcessFinished}
                isModerator={this.props.isModerator}
                isModeratorFromInstitution={this.props.isModeratorFromInstitution}
              />
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

ModalForAddModerator.propTypes = {
  createTopicOfDiscussionModerator: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  topicOfDiscussionModerator: state.topicOfDiscussionModerator,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  createTopicOfDiscussionModerator,
  getAllModerators,
  getAllTopicOfDiscussionModerator,
})(ModalForAddModerator);
