import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TopicOfDiscussionModeratorRow from "./TopicOfDiscussionModeratorRow";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";
import { Link } from "react-router-dom";
import authorizationService from "../../securityUtils/authorizationService";

class TopicOfDiscussionModeratorTable extends Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
    };
  }

  handleItemAdd = (item) => {
    this.props
      .createTopicOfDiscussionModerator(item)
      .then(() => this.setState({ isAdding: false }));
  };

  toggleAdding = () => {
    this.setState({ isAdding: !this.state.isAdding });
  };

  render() {
    const { isAdding } = this.state;

    let isMainModeratorLogged =
      this.props.topicOfDiscussion.user &&
      this.props.userId == this.props.topicOfDiscussion.user.id
        ? true
        : false;

    let topicOfDiscussionModeratorList = this.props
      .topicOfDiscussionModeratorList
      ? this.props.topicOfDiscussionModeratorList.map(
          (topicOfDiscussionModerator) => (
            <TopicOfDiscussionModeratorRow
              key={topicOfDiscussionModerator.id}
              topicOfDiscussionModerator={topicOfDiscussionModerator}
              isProcessFinished={this.props.isProcessFinished}
              isMainModeratorLogged={isMainModeratorLogged}
              isModerator={this.props.isModerator}
              isModeratorFromInstitution={this.props.isModeratorFromInstitution}
            />
          )
        )
      : [];

    return isMainModeratorLogged ? (
      <div>
        <div className="mb-1">
          <label className="labelText textShadow pe-3">
          {this.props.dictionary["TopicOfDiscussionModeratorTable.Moderatori"]}: <span className="redAsterisk"> *</span>
          </label>
          {this.props.isProcessFinished == false && isMainModeratorLogged ? (
            <Link to="#" onClick={(e) =>{e.preventDefault();this.toggleAdding();}}>
              {isAdding ? (
                <i className="fas fa-minus-circle editButton fa-2x fa-pull-right" />
              ) : (
                <i className="fas fa-plus-circle editButton fa-2x fa-pull-right" />
              )}
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="table-responsive">
          <Table id="example" className="table table-md">
            <Thead>
              <Tr className="align-items-center">
                <Th
                  width="50%"
                  className="font-weight-normal textShadow text-center"
                >
                  {this.props.dictionary["TopicOfDiscussionModeratorTable.ImePrezimeMail"]}
                </Th>
                {this.props.isProcessFinished == false && isMainModeratorLogged && (
                  <Th
                    width="5%"
                    className="font-weight-normal textShadow text-center"
                  >
                    {this.props.dictionary["TopicOfDiscussionModeratorTable.Akcija"]}
                  </Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {isAdding ? (
                <TopicOfDiscussionModeratorRow
                  usersModerators={this.props.usersModerators}
                  handleAdd={this.handleItemAdd}
                  topicOfDiscussion={this.props.topicOfDiscussion}
                  isProcessFinished={this.props.isProcessFinished}
                  isModerator={this.props.isModerator}
                  isModeratorFromInstitution={this.props.isModeratorFromInstitution}
                />
              ) : (
                <Tr></Tr>
              )}
              {topicOfDiscussionModeratorList}
            </Tbody>
          </Table>
        </div>
      </div>
    ) : (
      <div className="mb-1">
        <div className="table-responsive">
          <Table id="example" className="table table-md">
            <Thead>
              <Tr className="align-items-center">
                <Th
                  width="100%"
                  className="font-weight-normal textShadow text-center"
                >
                  {this.props.dictionary["TopicOfDiscussionModeratorTable.ImePrezimeMail"]}
                </Th>
              </Tr>
            </Thead>
            <Tbody>{topicOfDiscussionModeratorList}</Tbody>
          </Table>
        </div>
      </div>
    );
  }
}

TopicOfDiscussionModeratorTable.propTypes = {
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  authUser: state.security.authUser,
  authRole: state.security.authRole,
  userRoles: state.security.userRoles,
  userId: state.security.userId,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {})(TopicOfDiscussionModeratorTable);
