import axios from "axios";
import {
    GET_ERRORS,
    GET_ROLE,
    GET_ROLE_LIST,
    DELETE_ROLE,
} from "./types";

export const createRole = (role, history) => async (dispatch) => {
    try {
        await axios.post("/api/role/create", role);
        history.push("/roleList");
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    }
};

export const updateRole = (role, history) => async (dispatch) => {
    try {
        await axios.put("/api/role/update", role);
        history.push("/roleList");
        dispatch({
            type: GET_ERRORS,
            payload: {},
        });
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    }
};

export const getRoleList = () => async (dispatch) => {
    const res = await axios.get("/api/role/findAll");
    dispatch({
        type: GET_ROLE_LIST,
        payload: res.data,
    });
};

export const getRole = (id) => async (dispatch) => {
    try {
        const res = await axios.get(`/api/role/findById/${id}`);
        dispatch({
            type: GET_ROLE,
            payload: res.data,
        });
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
        });
    }
};

export const deleteRole = (id) => async (dispatch) => {
    if (
        window.confirm("Da li zaista želite da izbrišete odabranu rolu?")
    ) {
        await axios.delete(`/api/role/delete/${id}`);
        dispatch({
            type: DELETE_ROLE,
            payload: id,
        });
    }
};
