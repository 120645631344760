import React, { Component } from "react";
import { connect } from "react-redux";
import { getMailSettingsList } from "../../actions/mailSettingsActions";
import PropTypes from "prop-types";
import MailSettingsTable from "./MailSettingsTable";
import CreateButton from "../Reusable/CreateButton";
import authorizationService from "../../securityUtils/authorizationService";

class MailSettingsList extends Component {
  componentDidMount() {
    this.props.getMailSettingsList();
  }

  render() {
    const { mailSettingsList } = this.props.mailSettings;

    return (
      <div className="container">
        <br></br>
        <div className="row">
          <div className="col-md-9 m-auto">
            <div className="text-left mb-2">
              <h2 className="textShadow">{this.props.dictionary["MailSettingsList.title"]} </h2>
              <br></br>
              <div>
                <MailSettingsTable
                  mailSettingsList={mailSettingsList}
                />
                <div className="float-right">
                  {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
                    <CreateButton route={`/addMailSettings`} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  mailSettings: state.mailSettings,
});

export default connect(mapStateToProps, {
  getMailSettingsList,
})(MailSettingsList);
