import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { getOgp, updateOgp, statusUpdateOgp } from '../../actions/ogpActions';
import OgpStepList from '../OgpStep/OgpStepList';
import { getOGPStepList } from '../../actions/ogpStepActions';
import authorizationService from '../../securityUtils/authorizationService';
import { confirmDialog } from '../Reusable/ConfirmDialog';

class ModalForOGPPreview extends Component {
  constructor(props) {
    super();

    this.state = {
      ...props.ogp,
    };

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const id = this.props.id;
    this.props.getOgp(id);
    this.props.getOGPStepList(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const {
      id,
      nameAndLastname,
      organisationName,
      phoneNum,
      email,
      currentState,
      shortDescriptionAndMainGoal,
      strategy,
      additionalInformation,
      relevancy,
      startDate,
      endDate,
      ogpFieldOfInterests,
      obligationName,
      obligationNumber,
      otherParticipants,
      status,
      visibilityStatus,
      user,
      creationDate,
      statusComment,
      statusCommentCreationDate,
      statusCommentUserId,
    } = nextProps.ogp;

    this.setState({
      id,
      nameAndLastname,
      organisationName,
      phoneNum,
      email,
      currentState,
      shortDescriptionAndMainGoal,
      strategy,
      additionalInformation,
      relevancy,
      startDate: startDate ? moment(startDate).toDate() : '',
      endDate: endDate ? moment(endDate).toDate() : '',
      ogpFieldOfInterests,
      obligationName,
      obligationNumber,
      otherParticipants,
      status,
      visibilityStatus,
      user,
      creationDate,
      statusComment,
      statusCommentCreationDate,
      statusCommentUserId,
    });
  }

  handleClose() {
    this.props.handleClose();
  }

  onDeleteClick = (id) => {
    const action = () => {
      this.onSubmit('delete');
    };
    confirmDialog(this.props.dictionary["ModalForOGPPreview.delete.confirmDialog.part1"], action, this.props.dictionary["ModalForOGPPreview.delete.confirmDialog.part2"]);
  };

  onPublishClick = () => {
    const action = () => {
      this.onSubmit('publish');
    };
    confirmDialog(this.props.dictionary["ModalForOGPPreview.publish.confirmDialog.part1"], action, this.props.dictionary["ModalForOGPPreview.publish.confirmDialog.part2"]);
  };

  onSubmit = (signal) => {
    const updatedOgp = {
      ...this.props.ogp,
      visibilityStatus: signal == 'publish' ? true : false,
    };

    this.props
      .statusUpdateOgp(updatedOgp)
      .then(() => {
        this.setState({ ogp: {} });
      })
      .then(this.handleClose());
  };

  render() {
    let startDateInfo = this.props.ogp.startDate
      ? moment(this.props.ogp.startDate).format('DD.MM.YYYY ')
      : 'дд/мм/гггг';
    let endDateInfo = this.props.ogp.endDate
      ? moment(this.props.ogp.endDate).format('DD.MM.YYYY ')
      : 'дд/мм/гггг';

    let counter = -1;
    let listOfFields = this.props.ogp.ogpFieldOfInterests
      ? this.props.ogp.ogpFieldOfInterests.map((field) => {
          counter++;
          if (counter == this.props.ogp.ogpFieldOfInterests.length - 1) {
            return <div className="d-inline">{field.name}</div>;
          } else {
            return <div className="d-inline">{field.name + ', '}</div>;
          }
        })
      : [];

    const ogpStepList = [...this.props.ogpStep.ogpStepList];

    let ogpStatus = 'Поднесен';
    if (this.props.ogp.visibilityStatus == true) {
      ogpStatus = 'У разматрању';
    } else if (this.props.ogp.visibilityStatus == false) {
      ogpStatus = 'Одбачен';
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <h4 className="textShadow boldText text-center">{this.props.dictionary["ModalForOGPPreview.title.obligation "]}</h4>
            <div className="panel-heading" />
            <hr />
            {this.props.ogp.visibilityStatus == null &&
            this.props.ogp.user &&
            this.props.userId == this.props.ogp.user.id ? (
              <div className="col-md-2 ml-auto">
                <label className="boldText default-label-text">
                  {ogpStatus}
                </label>
              </div>
            ) : (
              ''
            )}
            <br />
            <div className="panel-body ">
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.label.obligationNumber"]}</label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">
                    {this.props.ogp.obligationNumber}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.label.obligationName "]}</label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">
                    {this.props.ogp.obligationName}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">
                    {this.props.dictionary["ModalForOGPPreview.label.startEndDate"]}
                  </label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">
                    {startDateInfo + ' - ' + endDateInfo}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">
                    {this.props.dictionary["ModalForOGPPreview.label.organisationName"]}
                  </label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">
                    {this.props.ogp.organisationName}
                  </p>
                </div>
              </div>
              <hr />
              <h5 className="textShadow mb-4 default-label-text text-center">
                {this.props.dictionary["ModalForOGPPreview.label.obligationDescription"]}
              </h5>
              <hr></hr>
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.label.fieldOfInterestList"]}</label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">{listOfFields}</p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">
                    {this.props.dictionary["ModalForOGPPreview.label.currentState"]}
                  </label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">
                    {this.props.ogp.currentState}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.label.shortDescriptionAndMainGoal"]}</label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">
                    {this.props.ogp.shortDescriptionAndMainGoal}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">
                    {this.props.dictionary["ModalForOGPPreview.label.strategy"]}
                  </label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">{this.props.ogp.strategy}</p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">
                    {this.props.dictionary["ModalForApplicationsPreview.label.relevancy"]}
                  </label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">{this.props.ogp.relevancy}</p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.label.additionalInformation"]}</label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">
                    {this.props.ogp.additionalInformation}
                  </p>
                </div>
              </div>
              <hr />
              <OgpStepList
                ogpId={this.props.ogp.id}
                ogpStepList={ogpStepList}
              />
              <hr></hr>
              {(this.props.ogp.user &&
                this.props.userId == this.props.ogp.user.id) ||
              authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) ||
              authorizationService.canAccessOGPUpdateSpecificFields(
                this.props.authRole,
                this.props.ogpModerators
              ) ? (
                <div>
                  <h5 className="textShadow mb-4 default-label-text text-center">
                    {this.props.dictionary["ModalForOGPPreview.label.contactInfo"]}
                  </h5>
                  <hr></hr>
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.label.nameAndLastname"]}</label>
                    </div>
                    <div className="col-md-6">
                      <p className="font-weight-bold">
                        {this.props.ogp.nameAndLastname}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.label.email"]}</label>
                    </div>
                    <div className="col-md-6">
                      <p className="font-weight-bold">{this.props.ogp.email}</p>
                    </div>
                    <div className="col-md-6">
                      <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.label.phoneNum"]}</label>
                    </div>
                    <div className="col-md-6">
                      <p className="font-weight-bold">
                        {this.props.ogp.phoneNum}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-4">
                    <div className="col-md-6">
                      <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.label.otherParticipants"]}</label>
                    </div>
                    <div className="col-md-6">
                      <p className="font-weight-bold">
                        {this.props.ogp.otherParticipants}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <hr />
            {authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) &&
            this.props.ogp.visibilityStatus == null ? (
              <div className="row mb-4">
                <div className="col-md-12">
                  <h5 className="textShadow mb-4 default-label-text text-center">
                    {this.props.dictionary["ModalForOGPPreview.title.status"]}
                  </h5>
                  <hr></hr>
                </div>
                <div className="col-md-12 d-flex flex-row justify-content-center align-items-center">
                  <div
                    onClick={() => this.onDeleteClick(this.props.ogp.id)}
                    className="col-md-2 m-auto p-2"
                  >
                    <h6 className="font-weight-bold default-label-text-link float-right">
                      {this.props.dictionary["ModalForOGPPreview.delete"]}
                    </h6>
                  </div>
                  <div
                    onClick={() => this.onPublishClick()}
                    className="col-md-2 m-auto p-2"
                  >
                    <h6 className="font-weight-bold default-label-text-link float-left">
                      {this.props.dictionary["ModalForOGPPreview.publish"]}
                    </h6>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ogp: state.ogp.ogp,
  errors: state.errors,
  ogpStep: state.ogpStep,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  getOgp,
  getOGPStepList,
  updateOgp,
  statusUpdateOgp,
})(ModalForOGPPreview);
