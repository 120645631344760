import{
    GET_DICTIONARY,
    UPLOAD_DICTIONARY,
    GET_TAXONOMIES
} from "../actions/types"

const initialState = {
};

export default function (state = initialState, action) {
    let dictionary = {...state.dictionary};
    let taxonomies = {...state.taxonomies};
    switch (action.type){
        case GET_DICTIONARY:           
            action.payload.map((item) => {
                dictionary[item.code] = item.content;
            });
            return { ...state,dictionary};
        case UPLOAD_DICTIONARY:
            action.payload.map((item) => {
                dictionary[item.code] = item.content;
            });
            return { ...state,dictionary};
        case GET_TAXONOMIES:
            action.payload.map((item) => {
                taxonomies[item.name]=item.taxonomyValueList;
            });
            return {
                ...state,
                taxonomies
            };
        default:
            return state;
    }
}