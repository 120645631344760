import axios from "axios";

import {
  GET_ERRORS,
  GET_OGP_COMMENT,
  GET_OGP_COMMENT_LIST,
  DELETE_OGP_COMMENT,
  GET_OGP_COMMENT_LIST_DTO,
  UPDATE_OGP_COMMENT,
  CREATE_OGP_COMMENT,
  GET_OGP_COMMENT_MAX_ID,
} from "./types";

export const createOgpComment = (ogpComment) => async (dispatch) => {
  try {
    const res = await axios.post("/api/ogpComment/create", ogpComment);
    dispatch({
      type: CREATE_OGP_COMMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateOgpComment = (ogpComment) => async (dispatch) => {
  try {
    const res = await axios.post("/api/ogpComment/update", ogpComment);
    dispatch({
      type: UPDATE_OGP_COMMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOgpCommentList = () => async (dispatch) => {
  const res = await axios.get("/api/ogpComment/findAll");
  res.data.forEach((c) => (c.commentText = JSON.parse(c.commentText)));
  dispatch({
    type: GET_OGP_COMMENT_LIST,
    payload: res.data,
  });
};

export const getOgpComment = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpComment/findById/${id}`);
    res.data.commentText = JSON.parse(res.data.commentText);
    dispatch({
      type: GET_OGP_COMMENT,
      payload: res.data,
    });
  } catch (e) {
    e.getMessage();
  }
};

export const deleteOgpComment = (id) => async (dispatch) => {
  await axios.delete(`/api/ogpComment/delete/${id}`);
  dispatch({
    type: DELETE_OGP_COMMENT,
    payload: id,
  });
};

// create it in backend
export const getOgpCommentListForDocumentAndUser = (uuidDocumentName) => async (
  dispatch
) => {
  const res = await axios.get(
    `/api/ogpComment/findAllByUserId/${uuidDocumentName}`
  );
  res.data.forEach((c) => (c.commentText = JSON.parse(c.commentText)));
  dispatch({
    type: GET_OGP_COMMENT_LIST_DTO,
    payload: res.data,
  });
};

export const getOgpCommentListForDocumentDTO = (uuidDocumentName) => async (
  dispatch
) => {
  const res = await axios.get(
    `/api/ogpComment/findAllByDocumentName/${uuidDocumentName}`
  );
  res.data.forEach((c) => (c.commentText = JSON.parse(c.commentText)));
  dispatch({
    type: GET_OGP_COMMENT_LIST_DTO,
    payload: res.data,
  });
};

export const getOgpCommentWithMaxId = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpComment/findMaxId`);
    dispatch({
      type: GET_OGP_COMMENT_MAX_ID,
      payload: res.data,
    });
  } catch (e) {
    e.getMessage();
  }
};
