import React, { Component } from 'react';
import { saveModeratorResources } from '../../actions/moderatorResourcesActions';
import { connect } from 'react-redux';
import UploadDocument from '../Reusable/UploadDocument';

class UploadModeratorResources extends Component { 
  render() {
    return (
      <UploadDocument 
        handleUpload={this.props.saveModeratorResources}
        history={this.props.history}
        multiple={true}
        documentTypeId={this.props.match.params.documentTypeId}
      />
    );
  }
}


export default connect(null, {
  saveModeratorResources
})(UploadModeratorResources);

