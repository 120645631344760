import { useRef, useEffect, useState } from "react";
import SurveyClosedAnswer from "./SurveyClosedAnswer";
import { createSurveyQuestions, deleteSurveyQuestions, updateSurveyQuestions } from "../../actions/surveyQuestionsActions";
import SurveyOpenedAnswer from "./SurveyOpenedAnswer";
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from "../../utils";
import { connect } from "react-redux";
import TextArea from "../Reusable/TextArea";
import { trackPromise } from "react-promise-tracker";



const SurveyQuestion = (props) => {

    const [edit, setEdit] = useState(props.question.id > 1 ? false : true);
    const [question, setQuestion] = useState(props.question);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const surveyQuestionBodyRef = useRef(null);
    const moreOptionsRef = useRef(null);
    const dictionary = useSelector(state => state.startUp.dictionary);
    const dispatch = useDispatch();

    useEffect(() => {
        props.updateQuestion(question);

        function handleScroll() {
            const element = surveyQuestionBodyRef.current;
            if (element) {
              const moreOptionsHidden = (element.scrollHeight == element.clientHeight) || (element.scrollHeight - element.clientHeight <= element.scrollTop+10);
              const moreOptions = moreOptionsRef.current;
              if(moreOptions) {
                if(moreOptionsHidden)
                    moreOptions.classList.add('hiddenMoreOptions');
                  else
                    moreOptions.classList.remove('hiddenMoreOptions');
              }

            }
          }

          if(props.fillUp) {
            const element = surveyQuestionBodyRef.current;
            if (element) {
            const showMoreOptions = (element.scrollHeight > element.clientHeight)
            if(showMoreOptions)
                setShowMoreOptions(true);
            element.addEventListener('scroll', handleScroll);
            handleScroll();
            }
          }
          

    }, [question])

    const deleteQuestion = async () => {
        if (props.question.id != 0) {
            await trackPromise(dispatch(deleteSurveyQuestions(props.question.id)));
        }   
        await trackPromise(props.refreshQuestions(question.methodId));
    };
   const saveQuestion = async () => {
    question.method = {id: question.methodId}
    // if (question.id != 0) {
    //     await props.saveNewQuestion();
    // }
    
    const erorr = await handleValidation();
    if (!erorr) {
        setEdit(false);
        if (question.id < 1) {
          await trackPromise(dispatch(createSurveyQuestions(question)));
        } else {
          await trackPromise(dispatch(updateSurveyQuestions(question)));
        }
        await trackPromise(props.refreshQuestions(question.methodId));
      }
  }

   const onTitleChangeHandler = (e) => {
    setQuestion({...question, question: e.target.value});
   } 

   const onRequiredChangeHandler = (e) => {
    if (question.required) {
        setQuestion({...question, required: false});

    }
    else {
        setQuestion({...question, required: true});
    }
    setEdit(true);
   }

   const onMultipleChoiceChangeHandler = (e) => {
    if (question.multipleChoice) {
        setQuestion({...question, multipleChoice: false});

    }
    else {
        setQuestion({...question, multipleChoice: true});
    }
    setEdit(true);
   }

   const updateAnswer = (answerText, ordinalNumber) => {
    
    const answer = question.surveyAvailableAnswersList.find(item => item.ordinalNumber == ordinalNumber);

    answer.answer = answerText;
    }

    const updateFillUpAnswer = (answer, isChecked) => {
        if(question.questionType.id==1)
        setQuestion({...question, openedEndedAnswer:answer})
        else {
            let closedAnswerList = question.closedAnswer && question.multipleChoice ? question.closedAnswer : [];
            if(question.multipleChoice) {
                if(isChecked) {
                    closedAnswerList = [...closedAnswerList, answer];
                }
                else{
                    closedAnswerList = closedAnswerList.filter(ans => ans.id !== answer.id);
                }
            }
            else{
                closedAnswerList = [...closedAnswerList, answer];
            }
            setQuestion({...question, closedAnswer: closedAnswerList});}
    }

    const deleteAnswer = (ordinalNumber) => {
    
        const answers = question.surveyAvailableAnswersList.filter(item => item.ordinalNumber !== ordinalNumber);
    
        if (answers.length != 0) {
          for(let i = 0; i<answers.length; i++) {
            answers[i].ordinalNumber = i+1;
          }
        }
    
        const updatedQuestion = {...question, surveyAvailableAnswersList: answers}
        setQuestion(updatedQuestion);
    }

    const addNewAnswer = () => {
        
        const maxOrdinalNumber = question.surveyAvailableAnswersList?.length > 0 ? 
                                 question.surveyAvailableAnswersList.reduce((max, answer) => 
                                 {
                                    return answer.ordinalNumber > max ? answer.ordinalNumber : max;
                                 }, -Infinity)
                                 : 0;
        
        const newAnswer = {
          answer: '',
          ordinalNumber: maxOrdinalNumber+1,
          id: 0
        }
        let answers = [];
        if (question.surveyAvailableAnswersList?.length > 0) {
            answers = question.surveyAvailableAnswersList = [...question.surveyAvailableAnswersList, newAnswer];
        }
        else {
            answers = question.surveyAvailableAnswersList = [newAnswer];
        }

        const updatedQuestion = {...question, surveyAvailableAnswersList: answers}
        setQuestion(updatedQuestion);
        setEdit(true);
    }


    const handleValidation = async () => {
        let error = false;
        if (question.surveyAvailableAnswersList.length < 2 && question.questionType.id == 2) {
            props.setMessage(`${dictionary["SurveyQuestion.Validation.ZatvorenoPitanje"]}`, 
                            `#asnwers${props.index}`, 
                            `#answersMsg${props.index}`);
            error = true;
        }
        if (question.question == '') {
            props.setMessage(`${dictionary["SurveyQuestion.Validation.TekstPitanja"]}`, 
                            `#question${props.index}`, 
                            `#questionMsg${props.index}`);
            error = true;
        }
        return error;
    }

    const handleMoreOptionsClick = () => {
        const element = surveyQuestionBodyRef.current;
        element.scrollTo({
            top: element.scrollTop+150,
            behavior:'smooth'
        });
    }

   const answers = question.questionType.id==2? question.surveyAvailableAnswersList.map((answer) => {
    return <SurveyClosedAnswer key={answer.ordinalNumber}
                               questionId={question.id} 
                               answer={answer}
                               edit={edit} 
                               updateFillUpAnswer={updateFillUpAnswer}
                               updateAnswer={updateAnswer}
                               deleteAnswer={deleteAnswer}
                               multipleChoice={question.multipleChoice}
                               disabled={props.fillUp ? false : true}
                               moderatorReadOnly={props.moderatorReadOnly}
                               />

    }) : (<SurveyOpenedAnswer
                               questionId={question.id}
                               updateFillUpAnswer={updateFillUpAnswer}
                               disabled={props.fillUp ? false : true}
                               moderatorReadOnly={props.moderatorReadOnly}
                               />);
   return ( 
    <>
   <div className="card mb-4 fade-in " style={{width: "100%", margin: "auto"}}>
    <div className="card-header d-flex question-card-header">
    <h5 className="align-self-top pe-2">{props.index+1}.</h5>
    {(!edit || props.moderatorReadOnly) ? <>
                <h5 className="card-title">{question.question}</h5>
                {(props.fillUp && question.required) && <span className="text-danger ms-1 me-2">*</span>}
                <div id={`msg${question.id}`} />
                {!props.fillUp && !props.moderatorReadOnly && (<button className="fas fa-edit only-icon"
                        onClick={() => {setEdit(true);}}></button> )}
            </> : 
            <>
            <div className="w-100">
             <TextArea
             className="inputField form-control"
             placeholder={dictionary["SurveyQuestion.Placeholder.Pitanje"]}
             value={question.question}
             onChange={onTitleChangeHandler}
             id={`#question${props.index}`}
           />
           <div id={`questionMsg${props.index}`}/>
           </div>
           <button className="far fa-trash-alt only-icon" onClick={deleteQuestion} ></button>
           </>}
    </div>
       <div className="card-body surveyQuestionBody" id={`#asnwers${props.index}`} ref={surveyQuestionBodyRef}>
            {answers}
        {props.fillUp && showMoreOptions && 
        (<div onClick={() => handleMoreOptionsClick()} className="surveyQuestionMoreOptions" ref={moreOptionsRef}>
            <span >{dictionary["SurveyQuestion.JosOpcija"]}</span>
            <i className="fas fa-chevron-down"></i>
        </div>)}
         <div className="d-flex">
            {question.questionType.id==1 ? '' : (!props.fillUp && !props.moderatorReadOnly) && (<button className="fas fa-solid fa-plus only-icon" onClick={() => {addNewAnswer()}}></button>)}
            <div id={`answersMsg${props.index}`} className="custom-warning"/>
        </div>
       </div>
    {props.fillUp ? (''):(
            <div className="card-footer d-flex justify-content-between question-card-footer">
            <div className="d-inline-flex">
                <div className="form-check form-switch me-2">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        role="switch"
                        id="customSwitches2"
                        checked={question.required}
                        readOnly
                        disabled={props.moderatorReadOnly}
                        onChange={onRequiredChangeHandler}

                    />
                    <label
                        className="custom-control-label boldText"
                        htmlFor="customSwitches2"
                    >{dictionary["SurveyQuestion.Obavezno"]}</label>
                </div>
                {question.questionType.id==1 ? '' :
                    <div className="form-check form-switch">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        role="switch"
                        id="customSwitches2"
                        checked={question.multipleChoice}
                        readOnly
                        onChange={onMultipleChoiceChangeHandler}
                        disabled={props.moderatorReadOnly}
                        />
                        <label
                            className="custom-control-label boldText"
                            htmlFor="customSwitches2"
                        >{dictionary["SurveyQuestion.ViseOdgovora"]}</label>
                    </div>
                }
            </div>
            {(edit && !props.moderatorReadOnly)? <button className='create-button create-question' onClick={saveQuestion}>{dictionary["SurveyQuestion.Sacuvaj"]}</button> : ''}        
         </div>
    )}

    </div>
    </>
   ); 
}


export default connect(null, {setMessage})(SurveyQuestion);

    