import axios from 'axios';
import {
  GET_ERRORS,
  GET_AD_DOCUMENT,
  GET_AD_DOCUMENT_LIST,
  DELETE_AD_DOCUMENT,
  UPLOAD_AD_DOCUMENT,
} from './types';

export const saveAdDocument = (data, id) => async (dispatch) => {
  try {
    const res = await axios.post(
      `/api/announcementDocument/upload/${id}`,
      data
    );
    dispatch({
      type: UPLOAD_AD_DOCUMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getAdDocumentList = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/announcementDocument/findAll/${id}`);
    dispatch({
      type: GET_AD_DOCUMENT_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getAdDocument = (documentName) => async (dispatch) => {
  const res = await axios.get(`/api/announcementDocument/find/${documentName}`);
  dispatch({
    type: GET_AD_DOCUMENT,
    payload: res.data,
  });
};

export const deleteAdDocument = (id) => async (dispatch) => {
  axios.delete(`/api/announcementDocument/delete/${id}`);
  dispatch({
    type: DELETE_AD_DOCUMENT,
    payload: id,
  });
};
