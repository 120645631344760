import axios from "axios";
import {
  GET_ERRORS,
  GET_IS_TOPIC_OF_DISCUSSION_DOCUMENTS,
  SAVE_MANUAL_DOCUMENT
} from './types';

export const downloadDocument =
  (uuidDocumentName, documentName) => async (dispatch) => {
    try{
    axios({
      url: `/api/document/download/${uuidDocumentName}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    });
  }catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
    throw err;
  }
  };

  export const uploadDocument = (data) => async (
    dispatch
  ) => {
    try{
      const res = await axios.post(
        `/api/document/upload`, data
      );
      dispatch({
        type: GET_IS_TOPIC_OF_DISCUSSION_DOCUMENTS,
        payload: res.data,
      });
    }
    catch (err) {
      dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      throw(err);
    }
  };

  export const deleteDocument = (data) => async (
    dispatch
  ) => {
    try{
      const res = await axios.post(
        `/api/document/delete`, data
      );
      dispatch({
        type: GET_IS_TOPIC_OF_DISCUSSION_DOCUMENTS,
        payload: res.data,
      });
    }
    catch (err) {
      dispatch({
          type: GET_ERRORS,
          payload: err.response.data,
        });
      throw(err);
    }
  };

  export const saveManualOnDisk = (data) => async (dispatch) => {
    try {
    const response = await axios.post(
      `/api/document/saveManualOnDisk`,
      data
    );
    dispatch({
      type: SAVE_MANUAL_DOCUMENT,
      payload: response.data,
    });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      throw err;
    }
  };
  