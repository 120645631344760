import React, { Component } from 'react';
import { connect } from 'react-redux';
import DeleteButton from '../Reusable/DeleteButton';
import EditButton from '../Reusable/EditButton';
import { deleteSurveyQuestions } from '../../actions/surveyQuestionsActions';
import { Tr, Td } from 'react-super-responsive-table';
import ModalForAvailableAnswers from '../SurveyAvailableAnswers/ModalForAvailableAnswers';
import { confirmDialog } from '../Reusable/ConfirmDialog';

class SurveyAvailableAnswerRow extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }

  onDeleteClick = (ordinalNumber) => {
    const { handleDelete } = this.props;
    const action = () => {
      handleDelete(ordinalNumber);
    };
    confirmDialog(this.props.dictionary["modalConfirm.BrisanjePitanja"], action, this.props.dictionary["surveyAvailableAnswer.brisanje"]);
  };

  onEditClick = (ordinalNumber) => {
    const { handleEdit } = this.props;
    this.setState({
      show: true,
    });
    handleEdit(ordinalNumber);
  };

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    const { answer, counter, selectedAnswer, handleUpdateAnswer } = this.props;
    const { show } = this.state;

    return (
      <Tr>
        <Td className="textShadow">{counter + '.'}</Td>
        <Td>{answer.answer}</Td>
        <Td className="text-center">
          <EditButton click={() => this.onEditClick(answer.ordinalNumber)} />
        </Td>
        <Td className="text-center border-0">
          <DeleteButton
            click={() => this.onDeleteClick(answer.ordinalNumber)}
          />
        </Td>
        {show && (
          <ModalForAvailableAnswers
            show={show}
            handleClose={this.handleClose}
            handleUpdateAnswer={handleUpdateAnswer}
            formType="editForm"
            selectedAnswer={selectedAnswer}
          />
        )}
      </Tr>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps, {
  deleteSurveyQuestions,
})(SurveyAvailableAnswerRow);
