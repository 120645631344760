import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createOgp } from '../../actions/ogpActions';
import Input from '../Reusable/Input';
import TextArea from '../Reusable/TextArea';
import DatePicker from 'react-datepicker';
import authorizationService from '../../securityUtils/authorizationService';
import { Tooltip } from 'react-tooltip';
import { getAllOgpFiledsOfInterest } from '../../actions/ogpFieldOfInterestActions';
import Select from 'react-select';
import { setMessage } from '../../utils';
import OgpStepList from '../OgpStep/OgpStepList';

class AddOgp extends Component {
  constructor() {
    super();
    this.state = {
      nameAndLastname: '',
      organisationName: '',
      phoneNum: '',
      email: '',
      currentState: '',
      shortDescriptionAndMainGoal: '',
      strategy: '',
      additionalInformation: '',
      relevancy: '',
      startDate: '',
      endDate: '',
      selectedOGPFieldsOfInterest: [],
      obligationName: '',
      obligationNumber: '',
      otherParticipants: '',
      errors: {},
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleTextAreaExpand = this.handleTextAreaExpand.bind(this);
    this.handleTextAreaShrink = this.handleTextAreaShrink.bind(this);
    this.handleOGPFieldsOfInterestMultiselectChange = this.handleOGPFieldsOfInterestMultiselectChange.bind(
      this
    );
  }

  componentDidMount() {
    this.props.getAllOgpFiledsOfInterest();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  handleTextAreaExpand = (e) => {
    e.target.rows = 17;
  };

  handleTextAreaShrink = (e) => {
    e.target.rows = 4;
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOGPFieldsOfInterestMultiselectChange(selectedOptions) {
    this.setState({ selectedOGPFieldsOfInterest: selectedOptions || [] });
  }

  handleValidation = () => {
    let error = false;
    if (this.state.selectedOGPFieldsOfInterest.length == 0) {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.selectedOGPFieldsOfInterest.length"],
        '#multi',
        '#msg1'
      );
      error = true;
    }

    if (this.state.currentState == '') {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.currentState"],
        '#currentState',
        '#msg2'
      );
      error = true;
    }
    if (this.state.currentState.length > 1000) {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.currentState.length"],
        '#currentState',
        '#msg2'
      );
      error = true;
    }

    if (this.state.shortDescriptionAndMainGoal == '') {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.shortDescriptionAndMainGoal"],
        '#shortDescriptionAndMainGoal',
        '#msg3'
      );
      error = true;
    }
    if (this.state.shortDescriptionAndMainGoal.length > 1000) {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.shortDescriptionAndMainGoal.length "],
        '#shortDescriptionAndMainGoal',
        '#msg3'
      );
      error = true;
    }

    if (this.state.strategy == '') {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.strategy"],
        '#strategy',
        '#msg4'
      );
      error = true;
    }
    if (this.state.strategy.length > 1000) {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.strategy.length"],
        '#strategy',
        '#msg4'
      );
      error = true;
    }

    if (this.state.relevancy == '') {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.relevancy"],
        '#relevancy',
        '#msg5'
      );
      error = true;
    }
    if (this.state.relevancy.length > 1000) {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.relevancy.length"],
        '#relevancy',
        '#msg5'
      );
      error = true;
    }

    if (this.state.additionalInformation.length > 1000) {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.additionalInformation.length"],
        '#additionalInformation',
        '#msg6'
      );
      error = true;
    }

    if (this.state.obligationName == '') {
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation.obligationName"],
        '#obligationName',
        '#msg7'
      );
      error = true;
    }
    error &&
      this.props.setMessage(
        this.props.dictionary["AddOgp.validation"],
        '#msg8',
        '#msg8'
      );
    return error;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newOgp = {
      nameAndLastname: this.state.nameAndLastname,
      organisationName: this.state.organisationName,
      phoneNum: this.state.phoneNum,
      email: this.state.email,
      currentState: this.state.currentState,
      shortDescriptionAndMainGoal: this.state.shortDescriptionAndMainGoal,
      strategy: this.state.strategy,
      relevancy: this.state.relevancy,
      additionalInformation: this.state.additionalInformation,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      ogpFieldOfInterests: this.state.selectedOGPFieldsOfInterest,
      user: this.state.selectedOGPModerator,
      obligationName: this.state.obligationName,
      obligationNumber: this.state.obligationNumber,
      otherParticipants: this.state.otherParticipants
    };

    this.props.createOgp(newOgp, this.props.history);
  };

  render() {

    const { errors } = this.state;

    let isDisabled = authorizationService.canAccessOGPPageSpecificAreas(
      this.props.authRole,
    )
      ? false
      : true;

    let infoAboutCurrentSatate = (
      <p className="regular-font" style={{ maxWidth: '26vw' }}>
       {this.props.dictionary["tooltip.ogp.infoAboutCurrentState"]}
      </p>
    );
    let infoAboutShortDescriptionAndMainGoal = (
      <p className="regular-font" style={{ maxWidth: '26vw' }}>
        {this.props.dictionary["tooltip.ogp.infoAboutShortDescriptionAndMainGoal"]}
      </p>
    );
    let infoAboutStrategy = (
      <div className="regular-font" style={{ maxWidth: '26vw' }}>
       {this.props.dictionary["tooltip.ogp.infoAboutStrategy"]}
      </div>
    );
    let infoAboutRelevancy = (
      <div className="regular-font" style={{ maxWidth: '26vw' }}>
      {this.props.dictionary["tooltip.ogp.infoAboutRelevancy"]}
      </div>
    );
    let infoAboutAdditionalInformation = (
      <div className="regular-font" style={{ maxWidth: '26vw' }}>
     {this.props.dictionary["tooltip.ogp.infoAboutAdditionalInformation"]}
      </div>
    );

    const { ogpFieldOfInterestList } = this.props.ogpFieldOfInterest;
    const fieldOfInterestList = ogpFieldOfInterestList.map((field) => ({
      ...field,
      value: field.id,
      label: field.name,
    }));

    let options = [];
    for (var i = 0; i < fieldOfInterestList.length; i++) {
      options.push(fieldOfInterestList[i]);
    }

    const customStyleForMultiselect = {
      control: (styles) => ({ ...styles, minHeight: '45px' }),
      option: (styles) => ({
        ...styles,
        color: '#495057',
        fontSize: '17px',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
        marginLeft: '12px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
      }),
      input: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '1.25rem',
        marginLeft: '12px',
      }),
    };

    return (
      <div>
        <div className="container">
          <br></br>
          <div className="row">
            <div className="col-md-12 m-auto">
              <h1 className="title text-center">{this.props.dictionary["AddOgp.title"]}</h1>
              <hr />
              <br />
            </div>
            <form onSubmit={this.onSubmit} className="col-md-12 mb-4">
              <div className="col-md-9 ml-4 mb-4">
                <div className="form-row border-bottom mb-4">
                  <div className="form-group col-md-9">
                    <label className="labelText textShadow">
                      {this.props.dictionary["AddOgp.label.obligationName"]} <span className="redAsterisk"> *</span>
                    </label>
                    <Input
                      type="text"
                      id="obligationName"
                      placeholder={this.props.dictionary["AddOgp.placeholder.obligationName"]}
                      name="obligationName"
                      value={this.state.obligationName}
                      onChange={this.onChange}
                      errors={errors.obligationName}
                    />
                    <div id="msg7" />
                  </div>
                  <div className="form-group col-md-3">
                    <label className="labelText textShadow ">
                      {this.props.dictionary["AddOgp.label.obligationNumber"]}
                    </label>
                    <Input
                      type="text"
                      id="obligationNumber"
                      placeholder={this.props.dictionary["AddOgp.placeholder.obligationNumber"]}
                      name="obligationNumber"
                      value={this.state.obligationNumber}
                      onChange={this.onChange}
                      errors={errors.obligationNumber}
                      disabled={isDisabled}
                    />
                  </div>
                  <p className="small-font ml-auto">
                    <span style={{ color: 'gray' }}> *</span> {this.props.dictionary["AddOgp.forAdministrator"]}
                  </p>
                </div>
                <div className="from-group row">
                  <div className="col-md-12">
                    <div className="row col-md-12">
                      <label className="labelText textShadow mt-1">
                        {this.props.dictionary["AddOgp.label.startEndDate"]}
                      </label>
                      <div className="col-md-3">
                        <DatePicker
                          id="startDate"
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange.bind(
                            'startcreationDate'
                          )}
                          className="dateInput form-control"
                          dateFormat="dd.MM.yyyy"
                          placeholderText="Датум почетка"
                          disabled={isDisabled}
                        />
                      </div>
                      <label className="labelText textShadow mt-1"> - </label>
                      <div className="col-md-3">
                        <DatePicker
                          id="endDate"
                          selected={this.state.endDate}
                          onChange={this.handleEndDateChange.bind('endDate')}
                          className="dateInput form-control"
                          dateFormat="dd.MM.yyyy"
                          placeholderText="Датум завршетка"
                          disabled={isDisabled}
                        />
                      </div>
                    </div>
                    <p className="small-font">
                      <span className="redAsterisk"> *</span> {this.props.dictionary["AddOgp.forAdministrator"]}
                    </p>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">
                  {this.props.dictionary["AddOgp.label.organisationName"]}
                  </label>
                  <Input
                    type="text"
                    id="organisationName"
                    placeholder={this.props.dictionary["AddOgp.placeholder.organisationName "]}
                    name="organisationName"
                    value={this.state.organisationName}
                    onChange={this.onChange}
                    errors={errors.organisationName}
                    disabled={isDisabled}
                  />
                  <p className="small-font">
                    <span className="redAsterisk"> *</span> {this.props.dictionary["AddOgp.forAdministrator"]}
                  </p>
                </div>
                <hr></hr>
                <h5
                  className="textShadow mb-4 default-label-text text-center"
                  text-center
                >
                  {this.props.dictionary["AddOgp.label.obligationDescription"]}
                </h5>
                <hr></hr>
                <div className="form-group">
                  <label className="labelText textShadow">
                    {this.props.dictionary["AddOgp.label.fieldOfInterestList"]} <span className="redAsterisk"> *</span>
                  </label>
                  <Select
                    isMulti
                    name="fieldOfInterestList"
                    options={options}
                    onChange={this.handleOGPFieldsOfInterestMultiselectChange}
                    placeholder={this.props.dictionary["AddOgp.placeholder.fieldOfInterestList"]}
                    id="multi"
                    styles={customStyleForMultiselect}
                  />
                  <div id="msg1" />
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">
                    {this.props.dictionary["AddOgp.label.currentState"]}
                  </label>
                  <span className="redAsterisk"> *</span>
                  <div data-tooltip-id="currentStateTip">
                    <TextArea
                      type="text"
                      id="currentState"
                      placeholder={this.props.dictionary["AddOgp.placeholder.currentState"]}
                      name="currentState"
                      value={this.state.currentState}
                      onChange={this.onChange}
                      errors={errors.currentState}
                      maxlength="1000"
                      rows="4"
                      onFocus={this.handleTextAreaExpand}
                      onBlur={this.handleTextAreaShrink}
                    />
                    <p className="small-font">
                      <span className="redAsterisk"> *</span>
                      <em>
                      <div dangerouslySetInnerHTML={{__html: this.props.dictionary["AddOgp.lengthWarning.currentState"]}} />
                      </em>
                    </p>
                  </div>
                  <Tooltip
                    id="currentStateTip"
                    type="info"
                    effect="solid"
                    place="right"
                  >
                    {infoAboutCurrentSatate}
                  </Tooltip>
                  <div id="msg2" />
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">{this.props.dictionary["AddOgp.label.shortDescriptionAndMainGoal"]}</label>
                  <span className="redAsterisk"> *</span>
                  <div data-tooltip-id="shortDescriptionAndMainGoalTip">
                    <TextArea
                      type="text"
                      id="shortDescriptionAndMainGoal"
                      placeholder={this.props.dictionary["AddOgp.placeholder.shortDescriptionAndMainGoal"]}
                      name="shortDescriptionAndMainGoal"
                      value={this.state.shortDescriptionAndMainGoal}
                      onChange={this.onChange}
                      errors={errors.shortDescriptionAndMainGoal}
                      maxlength="1000"
                      rows="4"
                      onFocus={this.handleTextAreaExpand}
                      onBlur={this.handleTextAreaShrink}
                    />
                    <p className="small-font">
                      <span className="redAsterisk"> *</span>
                      <em>
                      <div dangerouslySetInnerHTML={{__html: this.props.dictionary["AddOgp.lengthWarning.shortDescriptionAndMainGoal"]}} />
                      </em>
                    </p>
                  </div>
                  <Tooltip
                    id="shortDescriptionAndMainGoalTip"
                    type="info"
                    place="right"
                    effect="solid"
                  >
                    {infoAboutShortDescriptionAndMainGoal}
                  </Tooltip>
                  <div id="msg3" />
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">
                    {this.props.dictionary["AddOgp.label.strategy"]}
                  </label>
                  <span className="redAsterisk"> *</span>
                  <div data-tooltip-id="strategyTip" >
                    <TextArea
                      type="text"
                      id="strategy"
                      placeholder={this.props.dictionary["AddOgp.placeholder.strategy"]}
                      name="strategy"
                      value={this.state.strategy}
                      onChange={this.onChange}
                      errors={errors.strategy}
                      maxlength="1000"
                      rows="4"
                      onFocus={this.handleTextAreaExpand}
                      onBlur={this.handleTextAreaShrink}
                    />
                    <p className="small-font">
                      <span className="redAsterisk"> *</span>
                      <em>
                      <div dangerouslySetInnerHTML={{__html: this.props.dictionary["AddOgp.lengthWarning.strategy"]}} />
                      </em>
                    </p>
                  </div>
                  <Tooltip
                    id="strategyTip"
                    type="info"
                    effect="solid"
                    place="right"
                  >
                    {infoAboutStrategy}
                  </Tooltip>
                  <div id="msg4" />
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">
                  {this.props.dictionary["AddOgp.label.relevancy"]}
                  </label>
                  <span className="redAsterisk"> *</span>
                  <div data-tooltip-id="relevancyTip">
                    <TextArea
                      type="text"
                      id="relevancy"
                      placeholder={this.props.dictionary["AddOgp.placeholder.relevancy"]}
                      name="relevancy"
                      value={this.state.relevancy}
                      onChange={this.onChange}
                      errors={errors.relevancy}
                      maxlength="1000"
                      rows="4"
                      onFocus={this.handleTextAreaExpand}
                      onBlur={this.handleTextAreaShrink}
                    />
                    <p className="small-font">
                      <span className="redAsterisk"> *</span>
                      <em>
                      <div dangerouslySetInnerHTML={{__html: this.props.dictionary["AddOgp.lengthWarning.relevancy"]}} />
                      </em>
                    </p>
                  </div>
                  <Tooltip
                    id="relevancyTip"
                    type="info"
                    effect="solid"
                    place="right"
                  >
                    {infoAboutRelevancy}
                  </Tooltip>
                  <div id="msg5" />
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">
                    {this.props.dictionary["AddOgp.label.additionalInformation"]}
                  </label>
                  <div data-tooltip-id="additionalInformationTip">
                    <TextArea
                      type="text"
                      id="additionalInformation"
                      placeholder={this.props.dictionary["AddOgp.placeholder.additionalInformation"]}
                      name="additionalInformation"
                      value={this.state.additionalInformation}
                      onChange={this.onChange}
                      errors={errors.additionalInformation}
                      maxlength="1000"
                      rows="4"
                      onFocus={this.handleTextAreaExpand}
                      onBlur={this.handleTextAreaShrink}
                    />
                    <p className="small-font">
                      <span className="redAsterisk"> *</span>
                      <em>
                      <div dangerouslySetInnerHTML={{__html: this.props.dictionary["AddOgp.lengthWarning.additionalInformation"]}} />
                      </em>
                    </p>
                  </div>
                  <Tooltip
                    id="additionalInformationTip"
                    type="info"
                    effect="solid"
                    place="right"
                  >
                    {infoAboutAdditionalInformation}
                  </Tooltip>
                  <div id="msg6" />
                </div>
                <hr></hr>
                <p className="small-font">
                  <span className="redAsterisk"> *</span> {this.props.dictionary["AddOgp.forAdministrator"]}
                </p>
                <div
                  style={{
                    border: '.5px solid  #697c9d44',
                    borderRadius: '10px',
                    padding: '2%',
                  }}
                >
                  <OgpStepList ogpId={this.props.ogp.id} />
                </div>
                <hr></hr>
                <h5
                  className="textShadow mb-4 default-label-text text-center"
                  text-center
                >
                  {this.props.dictionary["AddOgp.title.contactInfo"]}
                </h5>
                <hr></hr>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">{this.props.dictionary["AddOgp.label.nameAndLastname"]}</label>
                  <span className="redAsterisk"> *</span>
                  <Input
                    type="text"
                    id="nameAndLastname"
                    placeholder={this.props.dictionary["AddOgp.placeholder.nameAndLastname"]}
                    name="nameAndLastname"
                    value={this.state.nameAndLastname}
                    onChange={this.onChange}
                    errors={this.errors}
                    disabled={isDisabled}
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6 mb-4">
                    <label className="labelText textShadow">
                      {this.props.dictionary["AddOgp.label.email"]}
                    </label>
                    <span className="redAsterisk"> *</span>
                    <Input
                      type="text"
                      id="email"
                      placeholder={this.props.dictionary["AddOgp.placeholder.email"]}
                      name="email"
                      value={this.state.email}
                      onChange={this.onChange}
                      errors={errors.email}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="form-group col-md-6 mb-4">
                    <label className="labelText textShadow">
                      {this.props.dictionary["AddOgp.label.phoneNum"]}
                    </label>
                    <span className="redAsterisk"> *</span>
                    <Input
                      type="text"
                      id="phoneNum"
                      placeholder={this.props.dictionary["AddOgp.placeholder.phoneNum"]}
                      name="phoneNum"
                      value={this.state.phoneNum}
                      onChange={this.onChange}
                      errors={errors.phoneNum}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="labelText textShadow">
                      {this.props.dictionary["AddOgp.label.otherParticipants"]}
                    </label>
                    <TextArea
                      type="text"
                      id="otherParticipants"
                      placeholder={this.props.dictionary["AddOgp.placeholder.otherParticipants"]}
                      name="otherParticipants"
                      value={this.state.otherParticipants}
                      onChange={this.onChange}
                      errors={errors.otherParticipants}
                      maxlength="1000"
                      rows="4"
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <hr></hr>
                <div id="msg8" />
                <div className="float-center">
                  <button
                    id="subscribeButton"
                    type="submit"
                    className="col-md-3 float-right"
                  >
                    {this.props.dictionary["AddOgp.save"]}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

AddOgp.propTypes = {
  createOgp: PropTypes.func.isRequired,
  getAllOgpFiledsOfInterest: PropTypes.func.isRequired,
  ogpFieldOfInterest: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ogp: state.ogp.ogp,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  ogpFieldOfInterest: state.ogpFieldOfInterest,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  createOgp,
  getAllOgpFiledsOfInterest,
  setMessage,
})(AddOgp);
