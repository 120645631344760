import React, { Component, Fragment } from 'react';
import { PdfPreviewer } from '../PdfPreviewer/PdfPreviewer';
import {
  createComment,
  getCommentListForDocumentDTO,
  updateComment,
  getCommentListForDocumentAndUser,
} from '../../actions/commentActions';
import { connect } from 'react-redux';
import {
  getDocument,
  removeDocument,
} from '../../actions/viewAttachmentAction';
import '../../style/ViewAttachedPDF.css';
import ModalForAddComment from '../Comment/ModalForAddComment';
import GoBackButton from '../Reusable/GoBackButton';
import {
  createCommentUserVote,
  updateCommentUserVote,
} from '../../actions/commentUserVoteActions';
import Alert from 'react-bootstrap/Alert';
import { getUserOrganizationList } from '../../actions/userOrganizationActions';
import PdfPreviewerTutorial from '../PdfPreviewer/PdfPreviewerTutorial';
import {
  createOgpComment,
  getOgpCommentListForDocumentDTO,
  updateOgpComment,
  getOgpCommentListForDocumentAndUser,
} from '../../actions/ogpCommentActions';
import authorizationService from '../../securityUtils/authorizationService';
import { getTopicOfDiscussionModeratorDTO } from '../../actions/topicOfDiscussionActions';
import { Link } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModalForSpecificCommentInfo from '../Comment/ModalForSpecificCommentInfo';
import DownloadDocumentButton from './DownloadDocumentButton';
import { setMessage } from '../../utils';
import Modal from 'react-bootstrap/Modal';
import { getDocumentByDocumentName } from '../../actions/viewAttachmentAction';
import { getMethodDocuments } from '../../actions/methodActions';
import { trackPromise } from 'react-promise-tracker';
import { NotificationManager } from 'react-notifications';

class ViewAttachedPdfDocumentWithHighlights extends Component {
  constructor() {
    super();
    this.state = {
      comments: [],
      showModalForAddComment: false,
      showMessage: false,
      showWarningMessage: false,
      userOrganizationId: '',
      showInfo: '',
      textSegment: '',
      articleNumber: '',
      showLockdownMessage: false,
      showCommentGatheringEndedMessage: false,
      errorCommentMessage: false,
      showModalForSepcificCommentInfo: false,
    };

    this.onCommentAdded = this.onCommentAdded.bind(this);
    this.onCommentUpdated = this.onCommentUpdated.bind(this);
    this.handleTextSegmentChange = this.handleTextSegmentChange.bind(this);
    this.handleArticleNumberChange = this.handleArticleNumberChange.bind(this);
  }

  componentDidMount() {
    const  methodId = this.props.methodId;
    const { topicOfDiscussionId } = this.props;
    const { documentName } = this.props;
    
    // //this.props.getDocument(documentName, controllerReference);
    // trackPromise(this.props.getMethodDocuments(this.props.method.id))
    // .then(() => {
    //   const methodDocuments = store.getState().method.methodDocuments;
    //   trackPromise(this.props.getDocumentByDocumentName(this.props.method.document))
    // });

    trackPromise(
      this.props.getDocumentByDocumentName(documentName)
        .catch((error) => {
          NotificationManager.error(
            this.props.dictionary["documentPreview.DokumentNePostoji"],
            this.props.dictionary["universal.GreskaUObradi"],
            5000,
            () => {
              // Optional callback after notification is closed
            },
            true
          );
        })
    )

    const isUserLoggedIn =
    this.props.authRole && this.props.authRole>0 ? 'YES' : 'NO';

    const  {ogpPhase}  = this.props;

    if (isUserLoggedIn == 'YES') {
      if (ogpPhase) {
        this.props.getOgpCommentListForDocumentAndUser(documentName);
      } else {
        this.props.getTopicOfDiscussionModeratorDTO(topicOfDiscussionId);
      }
      this.props.getUserOrganizationList();
    } else {
      if (ogpPhase) {
        this.props.getOgpCommentListForDocumentDTO(documentName);
      } else {
        this.props.getCommentListForDocumentDTO(
          methodId,
          documentName
        );
      }
    }

    const {phaseActiveStatus} = this.props;
    

    if (phaseActiveStatus == 0) {
      this.handleShowLockdownMessage();
    }

    
    if (phaseActiveStatus != 0 && !this.props.methodActive) {
      this.handleShowCommentGatheringEndedMessage();
    }
    
  }

  componentWillUnmount() {
    this.props.removeDocument();
  }

  onCommentAdded(comment) {
    const { ogpPhase } = this.props;

    if (
      (!ogpPhase && this.state.textSegment == '') ||
      comment.comment.text == ''
    ) {
      this.handleShowWarningMessage('show');
      return;
    }

    const getNextId = () => String(Math.random()).slice(2);

    const commentComplement = {
      ...comment,
      id: getNextId(),
    };
    comment = commentComplement;

    const { topicOfDiscussionId } = this.props;
    const { documentName } = this.props;

    const { comments } = this.state;

    this.setState({
      comments: [...comments, comment],
    });

    if (ogpPhase) {
      let newOgpComment = {
        commentText: JSON.stringify(comment),
        category: 'Коментар на део текста',
        phaseNumber: ogpPhase,
        uuidDocumentName: documentName,
        status: 'Примљен',
        visibility: 'НЕ',
        commentStatus: this.props.commentStatus.find((status) => status.code == "Primljen"),
        commentCategory: this.props.commentCategory.find((category) => category.code == "KomentarNaDeoTeksta"),
        method: { id: this.props.method.id}
      };

      if (this.state.userOrganizationId && this.state.userOrganizationId != 0) {
        newOgpComment = {
          commentText: JSON.stringify(comment),
          commentStatus: this.props.commentStatus.find((status) => status.code == "Primljen"),
          commentCategory: this.props.commentCategory.find((category) => category.code == "KomentarNaDeoTeksta"),
          method: { id: this.props.method.id},
          phaseNumber: ogpPhase,
          userOrganization: {
            id: this.state.userOrganizationId,
          },
          uuidDocumentName: documentName,
          visibility: 'НЕ',
        };
      }

      this.handleShowMesasage();
      this.props.createOgpComment(newOgpComment);
    } else {
      let newComment = {
        commentText: JSON.stringify(comment),
        topicOfDiscussion: {
          id: topicOfDiscussionId,
        },
        method: { id: this.props.method.id},
        commentStatus: this.props.commentStatus.find((status) => status.code == "Primljen"),
        commentCategory: this.props.commentCategory.find((category) => category.code == "KomentarNaDeoTeksta"),
        uuidDocumentName: documentName,
        textSegment: this.state.textSegment,
        articleNumber: this.state.articleNumber,
        visibility: 'НЕ',
      };

      if (this.state.userOrganizationId && this.state.userOrganizationId != 0) {
        newComment = {
          commentText: JSON.stringify(comment),
          topicOfDiscussion: {
            id: topicOfDiscussionId,
          },
          method: { id: this.props.method.id},
          commentStatus: this.props.commentStatus.find((status) => status.code == "Primljen"),
          commentCategory: this.props.commentCategory.find((category) => category.code == "KomentarNaDeoTeksta"),
          userOrganization: {
            id: this.state.userOrganizationId,
          },
          uuidDocumentName: documentName,
          textSegment: this.state.textSegment,
          articleNumber: this.state.articleNumber,
          visibility: 'НЕ',
        };
      }

      this.handleShowMesasage();
      this.props.createComment(newComment);
    }
  }

  onCommentUpdated(commentId, position, content) {
    const { comments } = this.state;

    const updatedComments = comments.map((c) => {
      if (c.id === commentId) {
        return {
          ...c,
          position: {
            ...c.position,
            ...position,
          },
          content: {
            ...c.content,
            ...content,
          },
        };
      } else {
        return c;
      }
    });

    this.setState({ comments: updatedComments });
  }

  showModalForAddComment = (e) => {
    this.setState({
      showModalForAddComment: true,
    });
  };

  showModalForSepcificCommentInfo = () => {
    this.setState({
      showModalForSepcificCommentInfo: true,
    });
  };

  handlePreviewClose = () => {
    this.setState({
      showModalForAddComment: false,
      showModalForSepcificCommentInfo: false,
    });
  };

  handleShowMesasage = () => {
    this.setState({
      showMessage: true,
    });

    setTimeout(() => {
      this.setState({
        showMessage: false,
      });
    }, 3000);
  };

  handleShowWarningMessage = (errorMessage) => {
    if (errorMessage == 'show') {
      this.setState({ errorCommentMessage: true });
    } else {
      this.setState({ showWarningMessage: true });
    }
    setTimeout(() => {
      this.setState({
        showWarningMessage: false,
        errorCommentMessage: false,
      });
    }, 3000);
  };

  handleUserOrganizationChange = (value) => {
    return (this.state.userOrganizationId = value);
  };
  handleTextSegmentChange = (value) => {
    return (this.state.textSegment = value);
  };
  handleArticleNumberChange = (value) => {
    return (this.state.articleNumber = value);
  };

  handleShowInfo = () => {
    if (this.state.showInfo == 1) {
      this.setState({ showInfo: -1 });
    } else {
      this.setState({ showInfo: 1 });
    }
  };

  handleShowLockdownMessage = () => {
    this.setState({ showLockdownMessage: true });
    setTimeout(() => {
      this.setState({
        showLockdownMessage: false,
      });
    }, 3000);
  };

  handleShowCommentGatheringEndedMessage = () => {
    this.setState({ showCommentGatheringEndedMessage: true });
    setTimeout(() => {
      this.setState({
        showCommentGatheringEndedMessage: false,
      });
    }, 3000);
  };

  render() {
    const { topicOfDiscussion } = this.props;
    const { topicsOfDiscussionModeratorsDTO } = topicOfDiscussion;
    const isUserLoggedIn =
    this.props.authRole && this.props.authRole>0
    ? 'YES' : 'NO';

    const topicOfDiscussionModerators =
      topicsOfDiscussionModeratorsDTO.topicOfDiscussionModerators;
    const processCreatorId = topicsOfDiscussionModeratorsDTO.creatorId;

    const isUserModerator =
      isUserLoggedIn == 'YES' &&
      authorizationService.canAccessProcessEditMode(
        this.props.authRole,
        this.props.userId,
        processCreatorId,
        topicOfDiscussionModerators
      )
        ? 'YES'
        : 'NO';
    const canLeaveComments = isUserLoggedIn && authorizationService.canLeaveComments(this.props.authRole)
    const { ogpPhase } = this.props;
    let commentList = [];
    if (ogpPhase) {
      commentList = this.props.ogpComment.ogpCommentListDTO.filter(
        (c) =>
          c.commentText.content != '' &&
          c.visibility &&
          c.visibility != 'НЕ'
      );
    } else {
      commentList = this.props.comment.commentListDTO.filter(
        (c) =>
          c.commentText.content != '' &&
          c.visibility &&
          c.visibility != 'НЕ'
      );
    }

    let commentListDTO = [];
    if (ogpPhase) {
      commentListDTO = this.props.ogpComment.ogpCommentListDTO.filter(
        (cDTO) => cDTO.visibility && cDTO.visibility != 'НЕ'
      );
    } else {
      commentListDTO = this.props.comment.commentListDTO.filter(
        (cDTO) => cDTO.visibility && cDTO.visibility != 'НЕ'
      );
    }

    const { topicOfDiscussionId } = this.props;

    let url = '';
    if (this.props.viewAttachment.dokument.documentContent) {
      const data = b64ToBlob(
        this.props.viewAttachment.dokument.documentContent
      );
      url = URL.createObjectURL(data);
    }

    const { methodId } = this.props;
    // const eventNumber = this.setEventNumber();

    const { userOrganizationList } = this.props.userOrganization;
    const { documentName } = this.props;
    const { phaseActiveStatus }  = this.props;
    const { methodActive } = this.props;
  

    let ogpPhaseSignal = ogpPhase ? 'DA' : 'NE';

    let specificCommentLabel =this.props.dictionary['viewAttachedPdfDocumentWithHighlights.dodajKomentarnaDeoTeksta'];

    let addCommentButton = (
      <button
        onClick={this.showModalForAddComment}
        id="subscribeButton"
        className="pdfModalButton"
      >
        <span className="p-2">{this.props.dictionary['viewAttachedPdfDocumentWithHighlights.DodajOpstiKomentar']}</span>
      </button>
    );

    let pdfPreviewContent = (
      <div className="h-100">
        <div className="form-row">
          <div>
            <div className="document-button-position-wrapper">
              <div className=''>
                <DownloadDocumentButton
                  documentContent={this.props.viewAttachment.dokument.documentContent}
                  documentName={this.props.documentNameText ? this.props.documentNameText : documentName }
                  label={this.props.dictionary['viewAttachedPdfDocumentWithHighlights.PreuzmiDokument']}
                  customClass={'pdfModalButton'}
                />
              </div> 
              {(isUserLoggedIn == 'YES' && canLeaveComments && phaseActiveStatus == 1 && methodActive) ||
              (isUserLoggedIn == 'YES' && canLeaveComments && ogpPhase) ? (
                <div className="document-button-position-wrapper align-content-center m-auto">
                  <div>{addCommentButton}</div>
                  <div className="">
                    <Link
                      onClick={(e) => { e.preventDefault();this.showModalForSepcificCommentInfo();}}
                      className="pdfModalButton"
                      to="#"
                    >
                      <InfoOutlinedIcon /> {specificCommentLabel}
                    </Link>
                  </div>
                </div>
              ) : (
                ''
              )}
              {(isUserLoggedIn == 'YES' && canLeaveComments && phaseActiveStatus == 1 && methodActive) ||
              (isUserLoggedIn == 'YES' && canLeaveComments && ogpPhase) ? (
                <PdfPreviewerTutorial
                  showInfo={this.state.showInfo}
                  onClick={this.handleShowInfo}
                />
              ) : (
                ''
              )}
            </div>
            {/* {(isUserLoggedIn == 'YES' && canLeaveComments && phaseActiveStatus == 1) ||
            (isUserLoggedIn == 'YES' && canLeaveComments && ogpPhase) ? (
              <hr></hr>
            ) : (
              <hr></hr>
            )} */}
          </div>
        </div>
        {this.state.showLockdownMessage ? (
          <Alert
            variant="danger"
            className="m-auto"
            style={{
              position: 'fixed',
              top: '25vh',
              left: '35vw',
              zIndex: '99999',
              borderRadius: '40px',
              background: '#9c0303fd',
              color: 'white',
            }}
          >
            <p className="mt-3">
              <em>{this.props.dictionary['viewAttachedPdfDocumentWithHighlights.FazaJeZakljucana']}</em>
            </p>
          </Alert>
        ) : (
          ''
        )}
         {this.state.showCommentGatheringEndedMessage ? (
          <Alert
            variant="danger"
            className="m-auto"
            style={{
              position: 'fixed',
              top: '25vh',
              left: '35vw',
              zIndex: '99999',
              borderRadius: '40px',
              background: '#9c0303fd',
              color: 'white',
            }}
          >
            <p className="mt-3">
              <em>{this.props.dictionary['viewAttachedPdfDocumentWithHighlights.PrikupljanjeJeZavrseno']}</em>
            </p>
          </Alert>
        ) : (
          ''
        )}
        {this.state.showMessage ? (
          <Alert
            className={
              this.state.showMessage
                ? 'alert-style mt-2 col-md-3 alert-shown'
                : 'alert-style mt-2 col-md-3 alert-hidden'
            }
          >
            <p className="mt-1 regular-font">{this.props.dictionary['viewAttachedPdfDocumentWithHighlights.komentarJePoslat']}</p>
          </Alert>
        ) : (
          ''
        )}
        {this.state.showWarningMessage || this.state.errorCommentMessage ? (
          <Alert
            className={
              this.state.showWarningMessage || this.state.errorCommentMessage
                ? 'warning-alert-style mt-2 col-md-6 regular-font alert-shown'
                : 'warning-alert-style mt-2 col-md-6 regular-font alert-hidden'
            }
          >
            {this.state.errorCommentMessage ? (
              <p className="mt-1 regular-font">
                {this.props.dictionary['viewAttachedPdfDocumentWithHighlights.FormaNijeIspravnoPopunjena']}
              </p>
            ) : (
              <p className="mt-1 regular-font">
                {this.props.dictionary['viewAttachedPdfDocumentWithHighlights.MarkiraniTekstValidacija']}
              </p>
            )}
          </Alert>
        ) : (
          ''
        )}

        <PdfPreviewer
          uuidDocumentName={documentName}
          onCommentAdded={this.onCommentAdded}
          onCommentUpdated={this.onCommentUpdated}
          comments={commentList.map((c) => c.commentText)}
          url={url}
          isUserLoggedIn={isUserLoggedIn}
          commentObjectList={commentListDTO}
          updateComment={this.props.updateComment}
          createCommentUserVote={this.props.createCommentUserVote}
          updateCommentUserVote={this.props.updateCommentUserVote}
          handleShowWarningMessage={this.handleShowWarningMessage}
          userOrganizationList={userOrganizationList}
          handleUserOrganizationChange={this.handleUserOrganizationChange}
          handleTextSegmentChange={this.handleTextSegmentChange}
          handleArticleNumberChange={this.handleArticleNumberChange}
          phaseActiveStatus={phaseActiveStatus}
          ogpPhase={ogpPhaseSignal}
          isUserModerator={isUserModerator}
          canLeaveComments={canLeaveComments}
          setMessage={this.props.setMessage}
          commentTextValidationMessage={this.props.dictionary['viewAttachedPdfDocumentWithHighlights.MaksimalniBrojKarakteraValidacija']}
          methodId = {this.props.methodId}
          methodActive = {methodActive}
        />
      </div>
    );
    return (
      <Modal id='pdfModal' className="modal-xl" show={this.props.show} onHide={this.props.handleClose}  enforceFocus={false}>
        <Modal.Header closeButton>

        </Modal.Header>
        <Modal.Body className='pt-0'>
          <>
          {this.state.showModalForAddComment == false ? (
          this.state.showModalForSepcificCommentInfo == false ? (
            pdfPreviewContent
          ) : (
            <Fragment>
              {pdfPreviewContent}
              <ModalForSpecificCommentInfo
                show={this.state.showModalForSepcificCommentInfo}
                handleClose={this.handlePreviewClose}
              />
            </Fragment>
          )
        ) : (
          <Fragment>
            {pdfPreviewContent}
            <ModalForAddComment
              show={this.state.showModalForAddComment}
              handleClose={this.handlePreviewClose}
              createComment={this.props.createComment}
              handleShowMesasage={this.handleShowMesasage}
              topicOfDiscussionId={topicOfDiscussionId}
              methodId={methodId}
  
              userOrganizationList={userOrganizationList}
              documentName={documentName}
              ogpPhase={ogpPhase}
              createOgpComment={this.props.createOgpComment}
            />
          </Fragment>
              )}
          </>
        </Modal.Body>
      </Modal>
      
    )
  }
}

const mapStateToProps = (state) => ({
  viewAttachment: state.viewAttachment,
  comment: state.comment,
  authRole: state.security.authRole,
  userId: state.security.userId,
  dictionary:state.startUp.dictionary,
  userOrganization: state.userOrganization,
  ogpComment: state.ogpComment,
  topicOfDiscussion: state.topicOfDiscussion,
  methodDocuments: state.method.methodDocuments,
  method: state.method.method,
  commentStatus: state.startUp.taxonomies.CommentStatus,
  commentCategory: state.startUp.taxonomies.CommentCategory
});

export default connect(mapStateToProps, {
  getDocument,
  removeDocument,
  createComment,
  getCommentListForDocumentDTO,
  updateComment,
  getCommentListForDocumentAndUser,
  createCommentUserVote,
  updateCommentUserVote,
  getUserOrganizationList,
  createOgpComment,
  getOgpCommentListForDocumentDTO,
  updateOgpComment,
  getOgpCommentListForDocumentAndUser,
  getTopicOfDiscussionModeratorDTO,
  setMessage,
  getDocumentByDocumentName,
  getMethodDocuments
})(ViewAttachedPdfDocumentWithHighlights);

function b64ToBlob(b64) {
  const byteCharacters = atob(b64);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);

  const blob = new Blob([byteArray]);
  return blob;
}
