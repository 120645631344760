import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getInstitutions, getInstitution, getInstitutionCanDelete } from '../../actions/institutionActions';
import PropTypes from 'prop-types';
import authorizationService from '../../securityUtils/authorizationService';
import LoadingIndicator from '../Reusable/LoadingIndicator';
import InstitutionTableServerFiltering from './InstitutionTableServerFiltering';
import ModalForAddInstitution from './ModalForAddInstitution';
import ModalForUpdateInstitution from './ModalForUpdateInstitution';

class InstitutionList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      showModalForAddInstitution: false,
      showModalForUpdateInstitution: false,
      refreshData: 0
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false })
  }
  handleCloseModalForAddInstitution = (refresh) => {
    if(refresh != null && refresh == true) {
      this.setState({
        refreshData: Math.random()
      })
    }
    this.setState({
        showModalForAddInstitution: false,
      });
  };
  handleShowModalForAddInstitution = () => {
    this.setState({
      showModalForAddInstitution: true,
    });
  };
  handleCloseModalForUpdateInstitution = (refresh) => {
    if(refresh != null && refresh == true) {
      this.setState({
        refreshData: Math.random()
      })
    }
    this.setState({
        showModalForUpdateInstitution: false,
      });
  };
  handleShowModalForUpdateInstitution = (id) => {
    this.props.getInstitution(id);
    this.setState({
      showModalForUpdateInstitution: true,
    });
  };

  render() {
    const { isLoading } = this.state;

    return isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="container-fluid component-wrapper-fade-in">
        <br></br>
        <div className="row">
          <div className="col-md-11 m-auto">
            <div className="text-left mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="textShadow">{this.props.dictionary['InstitutionList.Naslov']} </h2>
                <div className="float-right">
                  {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
                    <button 
                      className="create-button"
                      onClick={(e) => {e.preventDefault(); this.handleShowModalForAddInstitution();}}
                    >
                      {this.props.dictionary["institutionList.dodajInstituciju"]}
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div>
                <InstitutionTableServerFiltering
                  refresh={this.state.refreshData}
                  showModalForUpdateFunction = {this.handleShowModalForUpdateInstitution.bind(this)}
                />
              </div>
              { this.state.showModalForAddInstitution &&
                <ModalForAddInstitution
                  show={this.state.showModalForAddInstitution}
                  handleClose={this.handleCloseModalForAddInstitution}
                />
              }
              { this.state.showModalForUpdateInstitution &&
                <ModalForUpdateInstitution
                  show={this.state.showModalForUpdateInstitution}
                  handleClose={this.handleCloseModalForUpdateInstitution}
                  institution={this.props.selectedInstitution}
                />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InstitutionList.propTypes = {
  loggedUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary: state?.startUp?.dictionary,
  institution: state.institution,
  selectedInstitution: state?.institution?.institution
});

export default connect(mapStateToProps, { getInstitutions, getInstitution, getInstitutionCanDelete })(InstitutionList);
