import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteTopicOfDiscussionModerator } from '../../actions/topicOfDiscussionModeratorActions';
import DeleteButton from '../Reusable/DeleteButton';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Tr, Td } from 'react-super-responsive-table';
import { setMessage } from '../../utils';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Link } from 'react-router-dom';
import UserModeratorsComboBox from '../User/UserModeratorsComboBox';
import authorizationService from '../../securityUtils/authorizationService';

class TopicOfDiscussionModeratorRow extends Component {
  constructor(props) {
    super();
    this.state = {
      selectedUser: null,
      errors: {},
    };
    this.handleTopicOfDiscussionModeratorSelectChange =
      this.handleTopicOfDiscussionModeratorSelectChange.bind(this);
  }

  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteTopicOfDiscussionModerator(id);
    };
    confirmDialog(`${this.props.dictionary["TopicOfDiscussionModeratorRow.ObrisiModeratoraPoruka"]}`, action, `${this.props.dictionary["TopicOfDiscussionModeratorRow.ObrisiModeratoraAkcija"]}`);
  };

  handleTopicOfDiscussionModeratorSelectChange = (e) => {
    const moderator = JSON.parse(e.target.value);
    const userArray = this.props.usersModerators.filter(
      (u) => u.id === moderator.id
    );
    this.setState({
      selectedUser: userArray[0],
    });
  };

  handleValidation = () => {
    let error = false;

    if (!this.state.selectedUser) {
      this.props.setMessage(
        `${this.props.dictionary["TopicOfDiscussionModeratorRow.Validation.BarJednog"]}`,
        '#usersModeratorsCb',
        '#moderatorMsg'
      );
      error = true;
    }

    return error;
  };

  handleAdd = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newModerator = {
      topicOfDiscussion: {
        id: this.props.topicOfDiscussion.id,
      },
      user: this.state.selectedUser,
    };

    this.props.handleAdd(newModerator);
  };

  render() {
    const { errors } = this.state;
    const isEmpty = !this.props.topicOfDiscussionModerator;
    const isModerator = this.props.isModerator;

    return isModerator ? (
      <Tr className="text-center">
        <Td>
          {isEmpty ? (
            <UserModeratorsComboBox
              mainModeratorId={this.props.topicOfDiscussion.user.id}
              usersModerators={this.props.usersModerators}
              onChange={this.handleTopicOfDiscussionModeratorSelectChange}
            />
          ) : (
            <label>
              <strong>
                {this.props.topicOfDiscussionModerator.user.firstName +
                  ' ' +
                  this.props.topicOfDiscussionModerator.user.lastName +
                  ' '}{' '}
                <strong className="title-text-color bullet-char">
                  &bull;{' '}
                </strong>
              </strong>
              {' ' + this.props.topicOfDiscussionModerator.user.email}
            </label>
          )}
          <div id="moderatorMsg" />
        </Td>
        {this.props.isProcessFinished == false ? (
          isEmpty ? (
            <Td className="text-center">
              <Link to="#" onClick={this.handleAdd}>
                <CheckBoxIcon className="editButton" />
              </Link>
            </Td>
          ) : (
            <Td className="text-center">
              {this.props.isMainModeratorLogged === true ? (
                <DeleteButton
                  click={() =>
                    this.onDeleteClick(this.props.topicOfDiscussionModerator.id)
                  }
                />
              ) : (
                <Link to="#">
                  {/* <i className="far fa-trash-alt empty-list-button"></i> */}
                </Link>
              )}
            </Td>
          )
        ) : (
          ''
        )}
      </Tr>
    ) : (
      <Tr className="text-center">
        <Td>
          <label>
            <strong>
              {this.props.topicOfDiscussionModerator.user.firstName +
                ' ' +
                this.props.topicOfDiscussionModerator.user.lastName +
                ' '}{' '}
              <strong className="title-text-color bullet-char">&bull; </strong>
            </strong>
            {' ' + this.props.topicOfDiscussionModerator.user.email}
          </label>
        </Td>
      </Tr>
    );
  }
}

TopicOfDiscussionModeratorRow.propTypes = {
  deleteTopicOfDiscussionModerator: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  deleteTopicOfDiscussionModerator,
  setMessage,
})(TopicOfDiscussionModeratorRow);
