import React, { Component } from 'react';
import { connect } from "react-redux";
import ComboReusable from '../Reusable/ComboReusable';
import NotificationTopicOfDiscussionTable from '../NotificationTopicOfDiscussion/NotificationTopicOfDiscussionTable';
import Select from 'react-select';

class NotificationsByTopicOfDiscussionTab extends Component {
  render() {
   const topicsOfDiscussionByUserId = this.props.topicsOfDiscussionByUserId.map((item) => ({
      ...item,
      value: item.id,
      label: item.nameOfTopic + ' ('+item.notificationNumber+')',
    }));
    const customStyleForMultiselect = {
      control: (styles) => ({ ...styles, minHeight: '45px' }),
      option: (styles) => ({
        ...styles,
        color: '#495057',
        fontSize: '17px',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
        marginLeft: '12px',
      }),
      input: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '1.25rem',
        marginLeft: '12px',
      }),
    };
    const listScroller = {
      overflow:'auto',
      height:'70vh'
    }
    return (
      <div style={listScroller} className="p-3">
        <Select
          id="topicsOfInterest"
          options={topicsOfDiscussionByUserId}
          placeholder={this.props.dictionary["menu.Profil.NotificationsSelectProcess"]}
          onChange={this.props.handleComboTopicsOfDiscussionByUserId()}
          styles={customStyleForMultiselect}
        />
        <p className="ml-3 mt-2 medium-font">
          <em>{this.props.dictionary["menu.Profil.NotificationsByTopicExplanation"]}</em>
        </p>
        <NotificationTopicOfDiscussionTable
          quickPreviewSignal={1}
          filteredByCriteriaSignal={'topic'}
          notificationTopicOfDiscussionList={
            this.props.notificationByTopicOfDiscussionIdList
          }
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary,
});
export default connect(mapStateToProps, {}) (NotificationsByTopicOfDiscussionTab);
