import axios from 'axios';
import {
  GET_ERRORS,
  GET_ACTION_PLAN_REPORT_LIST,
  GET_ACTION_PLAN_REPORT,
  DELETE_ACTION_PLAN_REPORT,
} from './types';

export const saveActionPlanReport = (data, history) => async (dispatch) => {
  await axios.post(`/api/actionPlanReport/upload`, data);
  history.push('/ogpPage/2');
};

export const getActionPlanReports = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/actionPlanReport/findAll`);
    dispatch({
      type: GET_ACTION_PLAN_REPORT_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getActionPlanReport = (documentName) => async (dispatch) => {
  const res = await axios.get(`/api/actionPlanReport/find/${documentName}`);
  dispatch({
    type: GET_ACTION_PLAN_REPORT,
    payload: res.data,
  });
};

export const deleteActionPlanReport = (id) => async (dispatch) => {
  axios.delete(`/api/actionPlanReport/delete/${id}`);
  dispatch({
    type: DELETE_ACTION_PLAN_REPORT,
    payload: id,
  });
};

export const downloadActionPlanReport =
  (uuidDocName, documentName) => async (dispatch) => {
    axios({
      url: `/api/actionPlanReport/download/${uuidDocName}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    });
  };
