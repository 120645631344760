import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { getMethodListForSelect } from '../../actions/methodActions';
import { trackPromise } from "react-promise-tracker";
import TextArea from '../Reusable/TextArea';
import { NotificationManager } from 'react-notifications';
import { uploadDocument } from '../../actions/documentActions';
import { setMessage } from '../../utils';

const ModalForAddDocument = (props) => {
    const dispatch = useDispatch();
    const dictionary = useSelector(state => state.startUp.dictionary);
    const methodList = useSelector(state => state.method.methodListForSelect);

    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedPhase, setSelectedPhase] = useState(null);
    const [selectedMethods, setSelectedMethods] = useState([]);
    const [description, setDescription] = useState('');
    const [selectedDocumentType, setSelectedDocumentType] = useState(null);

    useEffect(() => {
    }, []);
    
    const handleClose = () => {
        props.handleClose();
        setSelectedDocument(null);
        setSelectedPhase(null);
        setSelectedMethods([]);
        setDescription('');
        setSelectedDocumentType(null);
    }

    const handleDocumentChange = (e) => {
        setSelectedDocument(e.target.files[0]);
    }

    const handleDocumentTypeChange = (documentType) => {
       setSelectedDocumentType(documentType);
       setSelectedMethods([]);
    }

    const handlePhaseChange = (phase) => {
      setSelectedPhase(phase);
      setSelectedMethods([]);
      trackPromise(dispatch(getMethodListForSelect(props.topicOfDiscussionId, phase.id)));
    }

    const handleMethodChange = (methods) => {
      setSelectedMethods(methods);
    }

    const handleDescriptionChange = (e) => {
      setDescription(e.target.value);
    }

    const handleValidation = () => {
      let error = false;
      let mimeTypeShort = selectedDocument!=null ? selectedDocument.name.substring(selectedDocument.name.lastIndexOf('.')) : null;
      if(selectedDocument==null) {
        dispatch(setMessage(
                `${dictionary["ModalForAddDocument.Validation.Dokument"]}`,
                '#inputFileRemoveDefault',
                '#inputFileValidationMsg'
            ))
        error=true;
      } else if (selectedDocument.size >= 10000000) {
        dispatch(setMessage(
            `${dictionary["ModalForAddDocument.Validation.DokumentiVeciOd10MB"]}`,
            '#inputFileValidationMsg',
            '#inputFileValidationMsg'
        ))
        error=true; 
                    
      }

      if(props.type==='Attachment' && selectedDocument!=null  && mimeTypeShort != '.pdf') {
        dispatch(setMessage(
          `${dictionary["ModalForAddDocument.Validation.NeispravanTipDokumentaSamoPDF"]}`,
          '#inputFileValidationMsg',
          '#inputFileValidationMsg'
        ))
        error=true;
      }
      if(props.type==='Report' && selectedDocument!=null && mimeTypeShort != '.pdf' && mimeTypeShort != '.docx' && mimeTypeShort != '.doc') {
        dispatch(setMessage(
          `${dictionary["ModalForAddDocument.Validation.NeispravanTipDokumenta"]}`,
          '#inputFileValidationMsg',
          '#inputFileValidationMsg'
        ))
        error=true; 
      }

      if(selectedDocumentType==null) {
        dispatch(setMessage(
                `${dictionary["ModalForAddDocument.Validation.TipDokumenta"]}`,
                '#documentType',
                '#documentTypeValidationMsg'
            ))
        error=true;
      }

      if(selectedPhase==null) {
        dispatch(setMessage(
                `${dictionary["ModalForAddDocument.Validation.Faza"]}`,
                '#phase',
                '#phaseValidationMsg'
            ))
        error=true;
      }

      if(selectedMethods.length==0 && selectedDocumentType?.code==='IzvestajOMetodi') {
        dispatch(setMessage(
                `${dictionary["ModalForAddDocument.Validation.Metoda"]}`,
                '#method',
                '#methodValidationMsg'
            ))
        error=true;
      }

     

      if(description=='' && selectedMethods.length==0 && selectedDocumentType?.code!=='IzvestajOFazi' && selectedDocumentType?.code!=='IzvestajOMetodi') {
        dispatch(setMessage(
                `${dictionary["ModalForAddDocument.Validation.OpisIliMetoda"]}`,
                '#description',
                '#descriptionValidationMsg'
            ))
        error=true;
      }
      else if((description==''&& selectedDocumentType?.code==='IzvestajOFazi') || (description==''&& selectedDocumentType?.code==='IzvestajOMetodi' && selectedMethods.length==0)) {
        dispatch(setMessage(
          `${dictionary["ModalForAddDocument.Validation.Opis"]}`,
          '#description',
          '#descriptionValidationMsg'
        ))
        error=true;
      }

      return error;
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      if(handleValidation()){
            return;
      }
      const document = {
        documentName:selectedDocument.name,
        mimeType:selectedDocument.type,
        mimeTypeShort:selectedDocument.name.substring(selectedDocument.name.lastIndexOf('.')),
        documentType:selectedDocumentType,
        phaseId:selectedPhase.id,
        topicOfDiscussionId:props.topicOfDiscussionId,
        description:description
      }
      
      
      
      let formData = new FormData();
      formData.append('file', selectedDocument)
      formData.append(
        'document',
        new Blob([JSON.stringify(document)], {
            type: 'application/json',
          })
      );

      formData.append(
        'methods',
        new Blob([JSON.stringify(selectedMethods)], {
            type: 'application/json',
          })
      );

    

      dispatch(uploadDocument(formData))
        .then(() => {
            NotificationManager.success(dictionary["ModalForAddDocument.UspesanUnosDokumenta"],dictionary["universal.Uspesno"], 5000);     
          })
          .catch( () => { 
            NotificationManager.error(dictionary["ModalForAddDocument.NeuspesanUnosDokumenta"], dictionary["universal.GreskaUObradi"], 5000);
          })
          .finally(() => {
            handleClose();
            props.handleRefresh();
            });
    }

    const customStyleForMultiselect = {
        control: (styles) => ({ ...styles, minHeight: '25px'}),
        option: (styles) => ({
          ...styles,
          color: '#495057',
          fontSize: '14px',
        }),
        placeholder: (styles) => ({
          ...styles,
          color: '#6c757d',
          fontSize: '14px',
          marginLeft: '12px',
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: '#6c757d',
          fontSize: '14px',
        }),
        input: (styles) => ({
          ...styles,
          color: '#6c757d',
          fontSize: '1rem',
          marginLeft: '12px',
        }),
      };

    return (
        <Modal
        show={props.show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        id="addDocumentModal"
        centered
      >
        <Modal.Header closeButton>
            <Modal.Title>
                {props.title}
            </Modal.Title>
        </Modal.Header> 
        <Modal.Body>     
            <div className='col-md-12'>
              <div className='d-flex align-items-center justify-content-center flex-column mt-2 mb-2'>
              <label id="inputFileRemoveDefault" className="col-md-1 position-relative" >
                  <input type="file" onChange={handleDocumentChange}/>
                  <span className="redAsterisk position-absolute end-0"> *</span>
                  <i className="fas fa-cloud-upload-alt fa-3x uploadButton"></i>
              </label>
              <div id="inputFileValidationMsg"/>
              {selectedDocument && <div>
                  <i className='fas fa-file-pdf fs-5'/>
                  <label className='ms-2 fs-5'>{selectedDocument.name}</label>
              </div>}
             </div>          
              <div className="form-group mt-2 mb-2">
                <label className="labelText textShadow">
                  {dictionary["ModalForAddDocument.TipDokumenta"]}<span className="redAsterisk"> *</span>
                </label>
                <Select
                    name={'documentType'}
                    id={'documentType'}
                    value={selectedDocumentType}
                    onChange={(selectedDocumentType) => handleDocumentTypeChange(selectedDocumentType)}
                    options={props.availableDocumentTypes}
                    styles={customStyleForMultiselect}
                    getOptionLabel={(option) => option.value}
                    getOptionValue={(option) => option.id}
                    placeholder={dictionary["ModalForAddDocument.IzaberiteTipDokumentaPlaceholder"]}
                />
                <div id="documentTypeValidationMsg"/>
              </div>  
              <div className="form-group mt-2 mb-2">
                <label className="labelText textShadow">
                  {dictionary["ModalForAddDocument.Faza"]}<span className="redAsterisk"> *</span>
                </label>
                <Select
                    name={'phase'}
                    id={'phase'}
                    value={selectedPhase}
                    onChange={(phase) => handlePhaseChange(phase)}
                    options={props.phases}
                    styles={customStyleForMultiselect}
                    getOptionLabel={(option) => option.phaseTypeDisplay}
                    getOptionValue={(option) => option.id}
                    placeholder={dictionary["ModalForAddDocument.IzaberiteFazuPlaceholder"]}
                />
                <div id="phaseValidationMsg"/>
              </div>
              {selectedDocumentType?.code != 'IzvestajOFazi' && selectedDocumentType?.code != 'NacrtPredlogAktaDokumentacija' && selectedDocumentType?.code != 'NacrtPredlogAktaNakonJavneRasprave' && selectedPhase && 
                 <div className="form-group mt-2 mb-2">
                 <label className="labelText textShadow">
                   {dictionary["ModalForAddDocument.Metoda"]}{selectedDocumentType?.code ==='IzvestajOMetodi' && <span className="redAsterisk"> *</span>}
                 </label>
                 <Select
                    isMulti
                    name={'method'}
                    id={'method'}
                    value={selectedMethods}
                    onChange={(method) => handleMethodChange(method)}
                    options={methodList}
                    styles={customStyleForMultiselect}
                    getOptionLabel={(option) => option.title}
                    getOptionValue={(option) => option.id}
                    placeholder={dictionary["ModalForAddDocument.IzaberiteMetoduPlaceholder"]}
                 />
                 <div id="methodValidationMsg"/>
               </div>
              }
              { 
                <div className="form-group mt-2 mb-2">
                <label className="labelText textShadow">
                  {dictionary["ModalForAddDocument.Opis"]}{!selectedMethods.length>0 && <span className="redAsterisk"> *</span>}
                </label>
                <TextArea
                  type="description"
                  id="description"
                  placeholder={dictionary["ModalForAddDocument.UnesiteOpisPlaceholder"]}
                  name="nameOfTopic"
                  value={description}
                  onChange={handleDescriptionChange}
                  maxLength="1000"
                />
                <p className="small-font">
                    <span className="redAsterisk"> *</span>
                    <em>
                    {dictionary["ModalForAddDocument.OpisBrojKarakteraPrviDeo"]} <strong>{dictionary["ModalForAddDocument.OpisBrojKaraktera"]}</strong> {dictionary["ModalForAddDocument.OpisBrojKarakteraPoslednjiDeo"]}{' '}
                    </em>
                  </p>
                <div id="descriptionValidationMsg"/>
              </div>
              }
              <div className="row mt-4 mb-2">
                    <button
                    type="button"
                    id="subscribeButton"
                    className="col-md-6 m-auto"
                    onClick={handleSubmit}
                    >
                    {dictionary["ModalForAddDocument.Sacuvaj"]}
                    </button>
                </div>
            </div>   
        </Modal.Body> 
      </Modal>
    ); 
}
export default ModalForAddDocument;