import React, { Component } from "react";
import PropTypes from 'prop-types';
import { downloadDocument } from "../../actions/documentActions";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';

class DownloadDocument extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.update();
  }

  update(){
    this.setState({ isLoading: true });
    const { uuidDocumentName } = this.props.match.params;
    trackPromise( 
      this.props.downloadDocument(uuidDocumentName,uuidDocumentName))
    .finally(()=> {
      this.setState({ isLoading: false })
    });
  }


  render() {

    return (
      <div className="container mt-2">
        <div className="row col-md-12 text-left">
          
        </div>
        <div className="row">
          <div id="msg" />
        </div>
      </div>
    );
  }
}


DownloadDocument.propTypes = {
  downloadDocument: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};


export default connect( null,{
  downloadDocument,
})(DownloadDocument);

