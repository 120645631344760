import axios from 'axios';

import { GET_ERRORS } from './types';

export const createEvaluatorMessage = (evaluatorMessage) => async (
  dispatch
) => {
  try {
    await axios.post('/api/evaluatorMessage/create', evaluatorMessage);
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
