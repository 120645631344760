import Modal from 'react-bootstrap/Modal';
import Input from '../../components/Reusable/Input';
import TextArea from '../Reusable/TextArea';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateInstitution } from '../../actions/institutionActions';
import { setMessage } from '../../utils';
import { NotificationManager } from 'react-notifications';

const ModalForUpdateInstitution = (props) => {

    const dispatch = useDispatch();
    const dictionary = useSelector(state => state.startUp.dictionary);

    const [institution, setInstitution] = useState({
        institutionName : "",
        comment : "",
        currentlyActive: false,
        canDelete: false
    });
    const [errors, setErrors] = useState({});

    useEffect(()=>{
        setInstitution(props.institution);
    }, [props.institution]);

    const onChange = (e) => {
        const {name,value} = e.target;
        setInstitution(prevInst => ({...prevInst, [name]:value}));
    };
    const handleActive = () =>{
        setInstitution(prevInst => ({...prevInst, ["currentlyActive"]:true}));
    };
    const handlePasive = () =>{
        setInstitution(prevInst => ({...prevInst, ["currentlyActive"]:false}));
    };

    const handleClose = (refresh) => {
        setInstitution({
            institutionName : ""
        });
        props.handleClose(refresh);
    };
    const handleValidation = () => {
        let error = false;
    
        if (institution.institutionName == '') {
          dispatch(setMessage(
            `${dictionary["UpdateInstitution.NazivInstitucije.Validacija"]}`,
            '#institutionName',
            '#nameMsg'
          ));
          error = true;
        }
        if (institution.comment==null || institution.comment == '') {
          dispatch(setMessage(
            `${dictionary["UpdateInstitution.KomentarPrazan.Validacija"]}`,
            '#comment',
            '#commentМsg'
          ));
          error = true;
        }
        if (institution.comment?.length > 300) {
          dispatch(setMessage(
            `${dictionary["UpdateInstitution.Komentar.Validacija"]}`,
            '#comment',
            '#commentМsg'
          ));
          error = true;
        }
        return error;
    };
    const handleSubmit = (e) => {
        e.preventDefault();   
        if (handleValidation() == true) {
          return;
        }
        dispatch(updateInstitution(institution))
        .then( () => {
            NotificationManager.success(dictionary["UpdateInstitution.UspesnaIzmena"],dictionary["universal.Uspesno"], 5000);
        })
        .catch( () => { 
            NotificationManager.error(dictionary["UpdateInstitution.IzmenaNeuspela"], dictionary["universal.GreskaUObradi"], 5000);
        })
        .finally ( () => { 
            handleClose(true); 
        });
      };

    return(
        <Modal
            show={props.show}
            onHide={handleClose}
            size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Body>
                <h4 className='text-center'>{dictionary["UpdateInstitution.IzmenaInstitucije"]}</h4>
                <hr/>
                <div className="form-group col-md-12">
                    <label className="textShadow">
                    {dictionary["UpdateInstitution.NazivInstitucije"]}{' '}
                    <span className="redAsterisk"> *</span>
                    </label>
                    <Input
                        type="text"
                        id="institutionName"
                        placeholder={dictionary["UpdateInstitution.NazivInstitucije.Placeholder"]}
                        name="institutionName"
                        value={institution.institutionName}
                        onChange={onChange}
                        errors={errors.institutionName}
                      />
                      <div id="nameMsg" />
                </div>
                <div className="form-group col-md-12">
                      <label className="textShadow">
                      {dictionary["UpdateInstitution.Komentar"]}: <span className="redAsterisk"> *</span>
                      </label>
                      <TextArea
                        type="text"
                        id="comment"
                        placeholder={dictionary["UpdateInstitution.Komentar.Validacija"]}
                        name="comment"
                        value={institution.comment}
                        onChange={onChange}
                        errors={errors.comment}
                        maxLength="300"
                        rows={4}
                      />
                      <div id="commentМsg" />
                </div>
                <div className="form-group">
                    <label className="textShadow" >{dictionary["UpdateInstitution.Status"]}: </label>
                    <div className="col-md-12 mt-4">
                      <label>{dictionary["UpdateInstitution.Status.Aktivna"]}</label>
                      <input
                        type="radio"
                        className="mt-2 col-md-1 filled-in"
                        checked={institution.currentlyActive}
                        onChange={handleActive}
                      />
                    </div>
                    <div className="col-md-12 mt-4">
                      <label>{dictionary["UpdateInstitution.Status.Pasivna"]}</label>
                      <input
                        type="radio"
                        className="mt-2 col-md-1 filled-in"
                        checked={!institution.currentlyActive}
                        onChange={handlePasive}
                      />
                    </div>
                </div>
                <div className="col-md-12 mt-4">
                    <button
                      id="subscribeButton"
                      className="col-md-2 btn-mr"
                      onClick={handleSubmit}
                    >
                      {dictionary["UpdateInstitution.Sacuvaj"]}
                    </button>
                    <button id="subscribeButton"
                            className="col-md-2"
                            onClick={handleClose}
                            >{dictionary["UpdateInstitution.Odustani"]}
                    </button>
                </div>
            </Modal.Body>
            </Modal.Header>
        </Modal>
    );
}
export default ModalForUpdateInstitution;