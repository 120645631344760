import axios from "axios";
import {
  GET_ERRORS,
  GET_MODERATOR_RESOURCES,
  GET_MODERATOR_RESOURCES_LIST,
  DELETE_MODERATOR_RESOURCES,
} from "./types";

export const saveModeratorResources = (data, history, documentTypeId) => async (
  dispatch
) => {
  await axios.post(
    `/api/moderatorResourcesDocument/upload/${documentTypeId}`,
    data
  );
  history.push("/moderatorResourcesList");
};

export const getModeratorResourcesList = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/moderatorResourcesDocument/findAll/${id}`
    );
    dispatch({
      type: GET_MODERATOR_RESOURCES_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
export const getModeratorResources = (documentName) => async (dispatch) => {
  const res = await axios.get(
    `/api/moderatorResourcesDocument/find/${documentName}`
  );
  dispatch({
    type: GET_MODERATOR_RESOURCES,
    payload: res.data,
  });
};

export const deleteModeratorResources = (id) => async (dispatch) => {
  axios.delete(`/api/moderatorResourcesDocument/delete/${id}`);
  dispatch({
    type: DELETE_MODERATOR_RESOURCES,
    payload: id,
  });
};

export const downloadModeratorResource = (uuidDocName, documentName) => async (dispatch) => {
  axios({
    url: `/api/moderatorResourcesDocument/download/${uuidDocName}`,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", documentName);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  })
  .catch((error) => {
    console.error("Error while downloading the file:", error);
  });
};
