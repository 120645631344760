import { useEffect, useMemo, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenAnswersForQuestion } from '../../actions/surveyAnswersActions';
import { MRT_Localization_SR_CYRL_RS } from 'mantine-react-table/locales/sr-Cyrl-RS';
import { MantineReactTable } from 'mantine-react-table';
import { NotificationManager } from 'react-notifications';

const ModalForOpenAnswersView = (props) => {

    const dispatch = useDispatch();
    const dictionary = useSelector((store) => store.startUp.dictionary)

    const answers = useSelector((store) => store.surveyAnswers.surveyAnswersFilterable);

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });

    const questionId = props.question?.id ?? 0;
    const question = props.question?.question ?? "";
    const columns = useMemo(
        () => [
          {
            accessorKey: 'openedEndedAnswer',
            header: dictionary["ModalForOpenAnswersView.openedEndedAnswer"]
          }
        ],
        [],
    );
    useEffect(()=> {
        if (!answers?.content?.length) {
            setIsLoading(true);
          } else {
            setIsRefetching(true);
          }
        const searchParams = { 
            start: pagination.pageIndex,
            size: pagination.pageSize,
            filters: columnFilters ?? [],
            globalFilter: globalFilter ?? '',
            sorting: sorting ?? []
          };
        dispatch(
            getOpenAnswersForQuestion(questionId, searchParams)
            )
            .then( () => {
                setIsError(false);
              })
            .catch(() => {
                setIsError(true);
                NotificationManager.error(dictionary["universal.NeuspesnoUcitavanjePodataka"]);
               })
            .finally(() => {
                setIsLoading(false);
                setIsRefetching(false);
              });
    },[
        questionId,
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting
    ])
    const customTable = 'custom-table';
    const customPaper = 'custom-paper';
    return (
        <Modal
        show={props.show}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="modalForOpenAnswersView"
      >
            <Modal.Header closeButton className='modal-scroll'>
                <h4>{question}</h4>
            </Modal.Header>
            <Modal.Body>
            <MantineReactTable
                localization={MRT_Localization_SR_CYRL_RS}
                enableGlobalFilter = {false}
                columns={columns}
                data={answers?.content}
                mantineTableProps={{
                    className: {customTable},
                    striped:true,
                    tablelayout: 'fixed',
                }}
                mantineProgressProps={{
                    striped:false,
                    animate:true,
                    size:'xs'
                }}
                 mantinePaperProps={{
                    className:{customPaper}
                }}
                initialState={{ showColumnFilters: true }}
                manualFiltering
                manualPagination
                manualSorting
                muiToolbarAlertBannerProps={
                    isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                        }
                    : undefined
                }
                onColumnFiltersChange={setColumnFilters}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={answers?.totalElements ?? 0}
                state={{
                    columnFilters,
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                    sorting,
                }}
            />
            </Modal.Body>
        </Modal>
    )
}

export default ModalForOpenAnswersView;