import axios from 'axios';
import { GET_ERRORS, RESET_ERRORS } from "../actions/types";
const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      const newState = {...state};
      if(Object.keys(action.payload).length>0){
        try
        {
          axios.post('/api/errorLog/createErrorLogFromClient',{
            errorMessage: JSON.stringify(action.payload),
            errorType:action.type,
          });
        }
        catch{}
      }
      return action.payload;
    case RESET_ERRORS:
      return {...state, ...action.payload};
    default:
      return state;
  }
}
