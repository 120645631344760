import { Card } from 'react-bootstrap';
import moment from 'moment';
import CalendarTodaySharpIcon from '@mui/icons-material/CalendarTodaySharp';
import { useSelector } from 'react-redux';

const MethodCardHeader = ({method}) => {
       
    const dictionary = useSelector(state => state.startUp.dictionary);
    const creationDate = method.creationDate ? moment(method.creationDate).format('DD.MM.YYYY. HH:mm') : null;
    const startDate = method.startDate ? moment(method.startDate).format('DD.MM.YYYY. HH:mm') : null;
    const endDate = method.endDate ? moment(method.endDate).format('DD.MM.YYYY. HH:mm') : null;
    
    const status = method.active ? 'MethodStatus.UToku' : 
                    (method.endDate && new Date(method.endDate) < Date.now()) ? (method.methodType == 'Anketa' ? 'MethodStatus.Zavrsena' : 'MethodStatus.Zavrseno') : 
                   'MethodStatus.UPripremi'
    const statusSpanClass = method.active ? 'methodInProgress' : 'methodNotInProgress';

    return (
        <Card.Header className="d-flex flex-lg-row flex-column rounded-0 border-0 bg-white justify-content-between text-left method-card-header">
            <div class="d-flex flex-lg-row flex-column">
            <span className="text-uppercase method-card-top-heading me-3">{method.methodTypeDisplay}</span>
            {(method.methodType == 'Anketa' || method.methodType == 'PrikupljanjeKomentara') && <div><span className={"text-uppercase "+statusSpanClass}>{dictionary[status]}</span></div>}
            </div>
            <div className='d-flex'>
                {(method.methodType == "OkrugliSto" || 
              method.methodType == "PanelDiskusija" || 
              method.methodType == "FokusGrupa" || 
              method.methodType == "OstaliDogadjaji")  &&
                    <span className='pe-2'>{dictionary["MethodCardHeader.VremeOdrzavanja"]}</span> 
                }
                <CalendarTodaySharpIcon className="calendar-icon" fontSize="small"/>
                <div>
                    <span>{startDate ? startDate : creationDate}</span>
                    {endDate && <span> - {endDate}</span>}
                </div>
            </div>
        </Card.Header>
    );
}

export default MethodCardHeader;