import React, { Component } from 'react';
import { saveAdDocument } from '../../actions/adDocumentActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setMessage } from '../../utils';

class UploadAdDocument extends Component {
  constructor() {
    super();
    this.state = {
      uploadedFiles: [],
      uploadInProgress: false,
    };
  }

  handleCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  onFileChange = (e) => {
    this.setState({
      uploadedFiles: e.target.files,
    });
  };

  resetInput = () => {
    return this.setState({
      uploadedFiles: [],
    });
  };

  uploadFiles = (e) => {
    e.preventDefault();
    if (this.state.uploadInProgress) return;
    this.setState({ uploadInProgress: true });
    if (this.state.uploadedFiles.length === 0) {
      this.props.setMessage(
        `${this.props.dictionary["UploadAdDocument.Validation.Dokument"]}`,
        '#fileChooser',
        '#msg'
      );
      this.setState({ uploadInProgress: false });
      return;
    }
    let uploadedFilesSize = 0;
    for (let i = 0; i < this.state.uploadedFiles.length; i++) {
      uploadedFilesSize += this.state.uploadedFiles[i].size;
    }
    if (uploadedFilesSize >= 10000000) {
      this.props.setMessage(
        `${this.props.dictionary["UploadAdDocument.Validation.DokumentVelicina"]}`,
        '#fileChooser',
        '#msg'
      );
      this.setState({ uploadInProgress: false });
      return;
    }

    let formData = new FormData();

    for (let i = 0; i < this.state.uploadedFiles.length; i++) {
      formData.append('file', this.state.uploadedFiles[i]);
      formData.append('name', this.state.uploadedFiles[i].name);
    }
    const adId = this.props.adId;
    try {
      this.props.saveAdDocument(formData, adId);
    } catch (err) {
      this.props.setMessage(`${this.props.dictionary["UploadAdDocument.Validation.Prilog"]}`, 1);
    }
  };

  render() {
    let filePreview;
    let titles = '';
    if (this.state.uploadedFiles.length > 0) {
      for (let i = 0; i < this.state.uploadedFiles.length; i++) {
        titles += this.state.uploadedFiles[i].name + '; ' + '   ';
      }
      filePreview = (
        <label className="fileName textShadow labelText">{titles}</label>
      );
    } else {
      filePreview = (
        <label className="faded-text fileName labelText">
          {this.props.dictionary["UploadAdDocument.UcitajteDokumente"]}
        </label>
      );
    }

    return (
      <div className="container mt-2 mb-4">
        <div className="row col-md-12 text-left">
          <label id="inputFileRemoveDefault" className="col-md-1">
            <input
              id="fileChooser"
              type="file"
              onChange={this.onFileChange}
              multiple
            />
            <i className="fas fa-cloud-upload-alt fa-3x fa-pull-right uploadButton mt-2"></i>
          </label>
          <label className="col-md-11 text-center">
            {filePreview}
            <hr />
          </label>
        </div>
        <div className="row">
          <div id="msg" />
        </div>
        <div className="row">
          <button
            id="subscribeButton"
            className="col-md-1 ml-auto"
            onClick={this.uploadFiles}
          >
            {this.props.dictionary["UploadAdDocument.Sacuvaj"]}
          </button>
        </div>
      </div>
    );
  }
}

UploadAdDocument.propTypes = {
  saveAdDocument: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  saveAdDocument,
  setMessage,
})(UploadAdDocument);

const returnFileFormat = (documentName) => {
  let format = documentName.substring(
    documentName.lastIndexOf('.') + 1,
    documentName.length
  );
  return format;
};
