import React, { Component } from "react";
import { connect } from 'react-redux';
import OgpFieldOfInterestRow from "./OgpFieldOfInterestRow";
import authorizationService from "../../securityUtils/authorizationService";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";

class OgpFieldOfInterestTable extends Component {
  render() {
    var ogpFieldOfInterestList = this.props.ogpFieldOfInterestList.map(
      (ogpFieldOfInterest) => (
        <OgpFieldOfInterestRow
          key={ogpFieldOfInterest.id}
          ogpFieldOfInterest={ogpFieldOfInterest}
        />
      )
    );


    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-sm table-striped mt-2">
          <Thead>
            {authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) ? (
              <Tr className="table-header-height">
                <Th className="boldText emphesized-label-font">
                  {this.props.dictionary["OgpFieldOfInterestTable.Naziv"]}
                </Th>

                <Th width="10%" className="text-center">
                  {this.props.dictionary["OgpFieldOfInterestTable.Izmena"]}
                </Th>

                <Th width="10%" className="text-center">
                  {this.props.dictionary["OgpFieldOfInterestTable.Brisanje"]}
                </Th>
              </Tr>
            ) : (
              <Tr className="table-header-height">
                <Th className="boldText emphesized-label-font">
                  {this.props.dictionary["OgpFieldOfInterestTable.Naziv"]}
                </Th>
              </Tr>
            )}
          </Thead>
          <Tbody>{ogpFieldOfInterestList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps,{}) (OgpFieldOfInterestTable);
