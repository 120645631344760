import {
  GET_OGP_FIELD_OF_INTEREST,
  GET_OGP_FIELD_OF_INTEREST_LIST,
  DELETE_OGP_FIELD_OF_INTEREST,
} from "../actions/types";

const initialState = {
  ogpFieldOfInterestList: [],
  ogpFieldOfInterest: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OGP_FIELD_OF_INTEREST_LIST:
      return {
        ...state,
        ogpFieldOfInterestList: action.payload,
      };
    case GET_OGP_FIELD_OF_INTEREST:
      return {
        ...state,
        ogpFieldOfInterest: action.payload,
      };
    case DELETE_OGP_FIELD_OF_INTEREST:
      return {
        ...state,
        ogpFieldOfInterestList: state.ogpFieldOfInterestList.filter(
          (ogp) => ogp.id !== action.payload
        ),
      };
    default:
      return state;
  }
}
