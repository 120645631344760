import { SET_LOGGED_USER, SET_ROLE_OF_LOGGED_USER, LOG_OUT_USER,GET_ERRORS, ACCEPT_COOKIES_POLICY, } from "./types";
import axios from "axios";

export const setAuthUser = (authUser) => async (dispatch) => {

  dispatch({
    type: SET_LOGGED_USER,
    payload: authUser,
  });
};

export const setRoleOfAuthUser = (authUserRole, authUser) => async (dispatch) => {
  try{
    const res = await axios.patch(
      `/api/user/setActiveRole`,authUser
    );
    dispatch({
      type: SET_ROLE_OF_LOGGED_USER,
      payload: {
        userRole: authUserRole,
        activeUser:res.data,
      }
    });
  }
  catch(err){
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOG_OUT_USER,
    payload: {},
  });
};

export const forgetMe = (userId) => async (dispatch) => {
  try { 
    const res = await axios.delete(
      `/api/user/forgetMe/${userId}`
    );
    dispatch({
      type: LOG_OUT_USER,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
}

export const acceptCookiesPolicy = (alowedAnalytics) => async (dispatch) => {
  try { 
    const res = await axios.patch(
      `/api/user/acceptCookies`
    );
    dispatch({
      type: ACCEPT_COOKIES_POLICY,
      payload: {acceptanceDate:res,alowedAnalytics:alowedAnalytics},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
}