import { useSelector } from "react-redux";
import ComboSelect from "../Reusable/ComboSelect";
import UploadDocumentInitialisedFiles from "../Reusable/UploadDocumentInitialisedFiles";
import MethodDocumentTable from "./MetodDocumentTable";
import { useEffect, useState } from "react";

const MethodDocuments = (props) => {
    const topicOfDiscussionDocuments = useSelector(state => state.topicOfDiscussion.topicOfDiscussionDocuments);
    const dictionary = useSelector(state => state.startUp.dictionary);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        const filteredDocuments = topicOfDiscussionDocuments?.filter((document) => {
            const commonConditions = document.phaseId === props.phaseId &&
                !props.methodDocuments?.some((methodDocument) => methodDocument.uuid === document.uuid);

            if (props.selectedMethodType === "PrikupljanjeKomentara") {
                return commonConditions && document.mimeTypeShort === '.pdf' && 
                document.documentType.taxonomyValueInformationList.some(item => (
                    item.informationName === "ZaKomentarisanje" && item.informationValue === "true"));
            } else {
                return commonConditions;
            }
        });

        const newOptions = filteredDocuments.map((topic) => ({
            ...topic,
            value: topic.uuid,
            label: topic.documentName
        }));

        setOptions(newOptions);
    }, [props.selectedMethodType, props.phaseId, props.methodDocuments, topicOfDiscussionDocuments]);

    
    const handleDocumentsSelect = (e) => {
        if (props.selectedMethodType == "PrikupljanjeKomentara" || props.selectedMethodType == "JavniPozivOCD") {
            e == null ? props.setDocuments([]) : props.setDocuments([e]);
        }
        else props.setDocuments(e)
        
    }
    
    return (
        <>
            <hr></hr>
            <MethodDocumentTable
                methodDocuments={props.methodDocuments}
                modalForPreviewSignal={props.readOnly}
                methodId={props.methodId}
            />
            {props.selectedMethodType != "JavniPozivOCD" && (!props.readOnly && ((props.oneFileAllowed && (!props.methodDocuments || props.methodDocuments?.length == 0)) || !props.oneFileAllowed) ) &&
                <div className="form-group  col-md-12">
                <ComboSelect
                    isMulti={!(props.selectedMethodType == "PrikupljanjeKomentara")}
                    name="methodDocuments"
                    options={options}
                    onChange={handleDocumentsSelect}
                    namePart={dictionary["MethodDocuments.Placeholder.ComboSelect"]}
                    id="multi1"
                    value={props.documents}
                    isClearable={true}
                />
                </div>
            }
            {(!props.readOnly && ((props.oneFileAllowed && (!props.methodDocuments || props.methodDocuments?.length == 0)) || !props.oneFileAllowed) ) &&
            <div className="form-group mb-4 mt-1">
                <UploadDocumentInitialisedFiles 
                    uploadedFiles={props.uploadedFiles}
                    onFileChange={(e) => props.setUploadedFiles(e.target.files)}
                />
                <div id="uploadMsg" />
            </div>
            }
    </>     
    );
}

export default MethodDocuments;