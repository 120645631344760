import axios from "axios";

import {
    GET_METHOD,
    GET_METHOD_LIST,
    CREATE_METHOD,
    UPDATE_METHOD,
    PIN_METHOD,
    UNPIN_METHOD,
    PUBLISH_METHOD,
    GET_METHOD_SURVEY_DTO,
    GET_METHOD_DOCUMENTS,
    DELETE_METHOD_DOCUMENT,
    GET_METHOD_LIST_FOR_SELECT,
    GET_ERRORS
} from "./types";

export const getMethodList = (topicOfDiscussionId, phaseId) => async (
    dispatch
  ) => {

    try {
        const res = await axios.get(
            `/api/method/findAll/${topicOfDiscussionId}/${phaseId}`
          );
        dispatch({
            type: GET_METHOD_LIST,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
    }
  };

  export const getMethodListForSelect = (topicOfDiscussionId, phaseId) => async (
    dispatch
  ) => {

    try {
        const res = await axios.get(
            `/api/method/findAll/${topicOfDiscussionId}/${phaseId}`
          );
        dispatch({
            type: GET_METHOD_LIST_FOR_SELECT,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
    }
  };

  export const getMethodListAnon = (topicOfDiscussionId, phaseId) => async (
    dispatch
  ) => {

    try {
        const res = await axios.get(
            `/api/method/findAllAnon/${topicOfDiscussionId}/${phaseId}`
          );
        dispatch({
            type: GET_METHOD_LIST,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
    }
  };

  export const createMethod = (data) => async (
    dispatch
  ) => {
    
    try {
        const res = await axios.post('/api/method/create', data);
        dispatch({
            type: CREATE_METHOD,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        throw(err);
    }
  };   

export const updateMethod = (formData) => async (
    dispatch
) => {

    try {
        const res = await axios.put('/api/method/update', formData);
        dispatch({
            type: UPDATE_METHOD,
            payload: res.data,
            });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
            });
        throw(err);
    }
};    


export const findMethodById = (id) => async (
    dispatch
  ) => {
    
    try {
        const res = await axios.get(`/api/method/findById/${id}`);
        dispatch({
            type: GET_METHOD,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
    }
};

export const pin = (method) => async ( 
    dispatch
    ) => {

    try {
        const res = await axios.put('/api/method/pin', method);
        dispatch({
            type: PIN_METHOD,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
    }
};

export const unpin = (method) => async (dispatch) => {
    const res = await axios.put(`/api/method/unpin`, method);
    try {
        dispatch({
            type: UNPIN_METHOD,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
    }
};


export const publishMethod = (method) => async (dispatch) => {
    const res = await axios.put(`/api/method/publish`, method);
    try {
        dispatch({
            type: PUBLISH_METHOD,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
    }
};

export const getMethodSurveyDTO = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/method/findByIdMethodSurveyDTO/${id}`);
    dispatch({
      type: GET_METHOD_SURVEY_DTO,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getMethodDocuments = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/method/findDocumentsByMethodId/${id}`);
    dispatch({
      type: GET_METHOD_DOCUMENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const deleteMethodDocument = (id, uuidString) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/method/deleteMethodDocumentByMethodIdAndUUID/${id}/${uuidString}`);
    dispatch({
      type: DELETE_METHOD_DOCUMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
    throw(err);
  }
};






