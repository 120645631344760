import React from 'react';

import classnames from 'classnames';

const TextArea = (props) => {
  let type;
  if (props.type == null) {
    type = 'text';
  } else {
    type = props.type;
  }

  return (
    <div className="w-100">
      <textarea
        type={type}
        id={props.name}
        className={classnames('inputField form-control', {
          'is-invalid': props.errors,
        })}
        placeholder={props.placeholder}
        name={props.name}
        value={props.value === null ? '' : props.value}
        onChange={props.onChange}
        maxLength={props.maxLength}
        minLength={props.minLength}
        rows={props.rows}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        disabled={props.disabled}
      />
      {props.errors && <div className="invalid-feedback">{props.errors}</div>}
    </div>
  );
};

export default TextArea;
