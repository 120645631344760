import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Tr, Td } from 'react-super-responsive-table';
import moment from 'moment';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import ModalForOGPArchivePreview from './ModalForOGPArchivePreview';
import { downloadOgpList } from '../../actions/ogpArchiveActions';

class OGPArchiveRow extends Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
    };

    this.handlePreviewClose = this.handlePreviewClose.bind(this);
  }
  onDownloadClick = (id) => {
    const action = () => {
      this.props.downloadOgpList(id);
    };
    confirmDialog(`${this.props.dictionary["OGPArchiveRow.Dialog.Preuzmi.Poruka"]}`, 
                   action, 
                   `${this.props.dictionary["OGPArchiveRow.Dialog.Preuzmi"]}`);
  };

  showModal = (e) => {
    this.setState({
      show: true,
    });
  };

  handlePreviewClose() {
    this.setState({ show: false });
  }

  render() {
    let ogpArchiveRow = (
      <Tr>
        <Td className="textShadow">{this.props.counter + '.'}</Td>
        <Td className="textShadow text-center">
          {moment(this.props.ogpArchive.archiveCreationDate).format(
            'DD.MM.YYYY'
          )}
        </Td>
        <Td className="textShadow">{this.props.ogpArchive.obligationName}</Td>
        <Td className="textShadow text-center">
          {this.props.ogpArchive.obligationNumber}
        </Td>
        <Td width="10%" className="text-center">
          <Link to="#" onClick={(e) => { e.preventDefault();this.showModal()}}>
          {this.props.dictionary["Document.Pogledaj"]}
          </Link>
        </Td>
        <Td width="10%" className="text-center">
          <Link
            onClick={(e) => { e.preventDefault();this.onDownloadClick(this.props.ogpArchive.id);}}
            to="#"
          >
            <i className="fas fa-file-download editButton"></i>
          </Link>
        </Td>
      </Tr>
    );
    return this.state.show == false ? (
      ogpArchiveRow
    ) : (
      <Fragment>
        {ogpArchiveRow}
        <ModalForOGPArchivePreview
          show={this.state.show}
          handleClose={this.handlePreviewClose}
          id={this.props.ogpArchive.id}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, { downloadOgpList })(OGPArchiveRow);
