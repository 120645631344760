import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDocument,
  removeDocument,
} from "../../actions/viewAttachmentAction";
import ViewAttachedDocument from "./ViewAttachedDocument";
import ViewAttachedPdfDocument from "./ViewAttachedPdfDocument";
import ViewAttachedExcelDocument from "./ViewAttachedExcelDocument";

class ViewAttachment extends Component {
  componentDidMount() {
    const { documentName } = this.props.match.params;
    const { controllerReference } = this.props.match.params;
    this.props.getDocument(documentName, controllerReference);
  }

  componentWillUnmount() {
    this.props.removeDocument();
  }

  vratiFormatFajla(documentName) {
    let format = documentName.substring(
      documentName.lastIndexOf(".") + 1,
      documentName.length
    );
    return format;
  }

  render() {
    const { documentContent } = this.props.viewAttachment.dokument;

    let format = this.vratiFormatFajla(this.props.match.params.documentName);

    const { phaseNumber } = this.props.match.params;
    const { topicOfDiscussionId } = this.props.match.params;

    return (
      <div>
        {format === "pdf" && (
          <ViewAttachedPdfDocument
            content={documentContent}
            phaseNumber={phaseNumber}
            topicOfDiscussionId={topicOfDiscussionId}
          />
        )}
        {(format === "xlsx" || format === "csv") && (
          <ViewAttachedExcelDocument content={documentContent} />
        )}
        {format !== "pdf" && format !== "xlsx" && format !== "csv" && (
          <ViewAttachedDocument
            content={documentContent}
            fileType={format}
            topicOfDiscussionId={topicOfDiscussionId}
            phaseNumber={phaseNumber}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viewAttachment: state.viewAttachment,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getDocument,
  removeDocument,
})(ViewAttachment);
