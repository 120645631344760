import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteOGPStep } from '../../actions/ogpStepActions';
import DeleteButton from '../Reusable/DeleteButton';
import authorizationService from '../../securityUtils/authorizationService';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Tr, Td } from 'react-super-responsive-table';
import moment from 'moment';
import Input from '../Reusable/Input';
import DatePicker from 'react-datepicker';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ModalForOgpStepUpdate from './ModalForOgpStepUpdate';
import { setMessage } from '../../utils';

class OgpStepRow extends Component {
  constructor(props) {
    super();
    this.state = {
      step: '',
      stepStartDate: '',
      stepEndDate: '',
      errors: {},
      showModalForUpdate: false,
    };
    this.handleStepStartDateChange = this.handleStepStartDateChange.bind(this);
    this.handleStepEndDateChange = this.handleStepEndDateChange.bind(this);
  }

  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteOGPStep(id);
    };
    confirmDialog(`${this.props.dictionary["OgpStepRow.Obrisi.Poruka"]}`, action, `${this.props.dictionary["OgpStepRow.Obrisi"]}`);
  };

  handleStepStartDateChange = (date) => {
    this.setState({ stepStartDate: date });
  };

  handleStepEndDateChange = (date) => {
    this.setState({ stepEndDate: date });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleValidation = () => {
    let error = false;

    if (this.state.stepStartDate == '') {
      this.props.setMessage(
        `${this.props.dictionary["OgpStepRow.Validation.DatumPocetka"]}`,
        '#stepStartDate',
        '#msgStepStartDate'
      );
      error = true;
    }
    if (this.state.stepEndDate == '') {
      this.props.setMessage(
        `${this.props.dictionary["OgpStepRow.Validation.DatumZavrsetka"]}`,
        '#stepEndDate',
        '#msgStepEndDate'
      );
      error = true;
    }

    if (this.state.step == '') {
      this.props.setMessage(`${this.props.dictionary["OgpStepRow.Validation.Korak"]}`, '#step', '#msgStep');
      error = true;
    }

    return error;
  };

  handleAdd = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newOgpStep = {
      step: this.state.step,
      stepStartDate: this.state.stepStartDate,
      stepEndDate: this.state.stepEndDate,
      ogp: {
        id: this.props.ogpId,
      },
    };
    this.props.handleAdd(newOgpStep);
  };

  showModalForUpdate = (e) => {
    this.setState({
      showModalForUpdate: true,
    });
  };

  handlePreviewClose = () => {
    this.setState({
      showModalForUpdate: false,
    });
  };

  render() {
    const { errors } = this.state;
    const isEmpty = !this.props.ogpStep;

    let ogpStepRow = (
      <Tr className="text-center">
        <Td style={{ wordBreak: 'break-all' }}>
          {isEmpty ? (
            <Input
              id="step"
              type="text"
              placeholder={this.props.dictionary["OgpStepRow.Placeholder.Korak"]}
              name="step"
              value={this.state.step}
              onChange={this.onChange}
              errors={errors.step}
            />
          ) : (
            this.props.ogpStep.step
          )}
          <div id="msgStep" />
        </Td>
        <Td>
          {isEmpty ? (
            <DatePicker
              id="stepStartDate"
              selected={this.state.stepStartDate}
              onChange={this.handleStepStartDateChange.bind('stepStartDate')}
              className="form-control"
              dateFormat="dd.MM.yyyy"
              placeholderText={this.props.dictionary["OgpStepRow.Placeholder.DatumPocetka"]}
            />
          ) : (
            moment(this.props.ogpStep.stepStartDate).format('DD.MM.YYYY ')
          )}
          <div id="msgStepStartDate" />
        </Td>
        <Td>
          {isEmpty ? (
            <DatePicker
              id="stepEndDate"
              selected={this.state.stepEndDate}
              onChange={this.handleStepEndDateChange.bind('stepEndDate')}
              className="form-control"
              dateFormat="dd.MM.yyyy"
              placeholderText={this.props.dictionary["OgpStepRow.Placeholder.DatumZavrsetka"]}
            />
          ) : (
            moment(this.props.ogpStep.stepEndDate).format('DD.MM.YYYY ')
          )}
          <div id="msgStepEndDate" />
        </Td>
        {authorizationService.canAccessOGPUpdateSpecificFields(
          this.props.authRole,
          this.props.ogpModerators
        ) && this.props.ogpUpdate ? (
          !isEmpty ? (
            <Td className="text-center">
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.showModalForUpdate();
                }}
              >
                <i className="fas fa-edit deleteButton"></i>
              </Link>
            </Td>
          ) : (
            <Td></Td>
          )
        ) : (
          ''
        )}
        {authorizationService.canAccessOGPUpdateSpecificFields(
          this.props.authRole,
          this.props.ogpModerators
        ) && this.props.ogpUpdate ? (
          isEmpty ? (
            <Td className="text-center">
              <Link to="#" onClick={(e) => {
                e.preventDefault();
                this.handleAdd();
                }}>
                <CheckBoxIcon className="editButton" />
              </Link>
            </Td>
          ) : (
            <Td className="text-center">
              <DeleteButton
                click={() => this.onDeleteClick(this.props.ogpStep.id)}
              />
            </Td>
          )
        ) : (
          ''
        )}
      </Tr>
    );
    return this.state.showModalForUpdate == false ? (
      ogpStepRow
    ) : (
      <Fragment>
        {ogpStepRow}
        <ModalForOgpStepUpdate
          show={this.state.showModalForUpdate}
          handleClose={this.handlePreviewClose}
          ogpStepId={this.props.ogpStep.id}
        />
      </Fragment>
    );
  }
}

OgpStepRow.propTypes = {
  deleteOGPStep: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  deleteOGPStep,
  setMessage,
})(OgpStepRow);
