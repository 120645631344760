import MethodList from "../Method/MethodList";
import authorizationService from '../../securityUtils/authorizationService';
import { useDispatch, useSelector } from "react-redux";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Link } from 'react-router-dom';
import ModalForUpdatePhase from "./ModalForUpdatePhase";
import { useEffect, useState } from "react";
import ModalForAddNewMethod from "../Method/ModalForAddNewMethod";
import LoadingIndicator from "../Reusable/LoadingIndicator";
import LoadingIndicatorOverlay from "../Reusable/LoadingIndicatorOverlay";
import { getTopicOfDiscussionDocuments } from '../../actions/topicOfDiscussionActions';

const PhaseContent = (props) => {
    const user = useSelector(state => state.security);
    const [showModalForUpdatePhase, setShowModalForUpdatePhase] = useState(false);
    const [showModalForAddNewMethod, setShowModalForAddNewMethod] = useState(false);

    const dictionary = useSelector(state => state.startUp.dictionary);
    const publicDebate = useSelector(state => state.phase.publicDebate);

    const dispatch = useDispatch();

    const isModerator = authorizationService.canAccessProcessEditMode(
        user.authRole,
        user.userId,
        props.processCreatorId,
        props.topicOfDiscussionModerators
      );

    const handleShowModalForUpdatePhase = () =>{
    setShowModalForUpdatePhase(true);
    }
    const handleCloseModalForUpdatePhase = () => {
    setShowModalForUpdatePhase(false);
    }

    const handleShowModalForAddNewMethod = () => {
        setShowModalForAddNewMethod(true);
    }
    const handleCloseModalForAddNewMethod = () => {
        setShowModalForAddNewMethod(false);
    }
    return (
        <>
        {isModerator && 
            <div className="d-flex justify-content-start">
                {props.phase && !props.isProcesFinished && 
                ((props.phase.phaseType == 'Konsultacije' && !publicDebate) || 
                (props.phase.phaseType == 'JavnaRasprava' && publicDebate)) && 
                <Link
                    onClick={(e) => {
                        e.preventDefault();
                        handleShowModalForUpdatePhase();
                    }}
                    className="default-label-text-link d-flex align-items-center me-2"
                    to="#"
                >
                    <EditRoundedIcon fontSize={'small'} />
                    <strong className="ps-1">{dictionary["PhaseContent.IzmeniFazu"]}</strong>
                </Link>}
                {props.phase?.active && !props.isProcesFinished &&
                    <>
                        <Link
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(getTopicOfDiscussionDocuments(props.topicOfDiscussionId));
                                handleShowModalForAddNewMethod();
                            }}
                            className="default-label-text-link d-flex align-items-center"
                            to="#"
                        >
                            <AddBoxIcon fontSize={'small'} />
                            <strong className="ps-1">{dictionary["PhaseContent.Dodaj"]}</strong>
                        </Link>
                        {showModalForAddNewMethod && <ModalForAddNewMethod
                            show={showModalForAddNewMethod}
                            handleClose={handleCloseModalForAddNewMethod}
                            handleMethodRefresh={props.handleMethodRefresh}
                            topicOfDiscussionId = {props.topicOfDiscussionId}
                            phase={props.phase}
                        />
                        }
                    </>
                }
            </div>
        }
        {props.phase &&
        <>  
            <LoadingIndicatorOverlay/>  
            <MethodList topicOfDiscussionId={props.topicOfDiscussionId} 
                        phase={props.phase} 
                        handleMethodRefresh={props.handleMethodRefresh}
                        isModerator={isModerator}/>
            {showModalForUpdatePhase && <ModalForUpdatePhase show={showModalForUpdatePhase} 
                                topicOfDiscussionId={props.topicOfDiscussionId} 
                                handleClose={handleCloseModalForUpdatePhase}
                                phase={props.phase}
                                />
            }
        </>
        }
        </>
    );
}

export default PhaseContent;