import axios from 'axios';

import { GET_NOTIFICATION_LIST, GET_ERRORS } from './types';

export const getNotifications = (id) => async (dispatch) => {
  const res = await axios.get(`/api/notification/findAll/${id}`);
  res.data.forEach(
    (notification) =>
      (notification.comment.commentText = JSON.parse(
        notification.comment.commentText
      ))
  );
  dispatch({
    type: GET_NOTIFICATION_LIST,
    payload: res.data,
  });
};

export const setSeenByCitizen = (id) => async (dispatch) => {
  try {
    await axios.patch(`/api/notification/setSeenByCitizen/${id}`);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
