import React, { Component, Fragment } from 'react';
import { Tr, Td } from 'react-super-responsive-table';
import { connect } from "react-redux";
import moment from 'moment';
import ModalForAdDocumentPreview from '../AdDocument/ModalForAdDocumentPreview';
import { Link } from 'react-router-dom';

class AdRow extends Component {
  constructor(props) {
    super();
    this.state = {
      showModalForAdDocumentPreview: false,
    };
  }

  showModalForAdDocumentPreview = () => {
    this.setState({ showModalForAdDocumentPreview: true });
  };

  handlePreviewClose = () => {
    this.setState({
      showModalForAdDocumentPreview: false,
    });
  };

  render() {
    const announcement = this.props.ad;
    const startDate = announcement.startDate
      ? moment(announcement.startDate).format('DD.MM.YYYY')
      : '/';
    const endDate = announcement.endDate
      ? moment(announcement.endDate).format('DD.MM.YYYY')
      : '/';

    const period = !(announcement.startDate && announcement.endDate)
      ? '/'
      : startDate + ' - ' + endDate;

    const adArchiveRow = (
      <Tr>
        <Td className="textShadow">{this.props.counter + '.'}</Td>
        <Td className="textShadow">{announcement.creationDate}</Td>
        <Td className="textShadow">{announcement.ad}</Td>
        <Td className="textShadow">{period}</Td>
        <Td className="text-center">
          {announcement.active ? `${this.props.dictionary["AdRow.Aktivna"]}` : `${this.props.dictionary["AdRow.Neaktivna"]}`}
        </Td>
        <Td className="textShadow text-center">
          <Link
            onClick={(e) => {
              e.preventDefault();
              this.showModalForAdDocumentPreview();
            }}
            className="default-label-text-link"
            to="#"
          >
            {this.props.dictionary["Document.Pogledaj"]}
          </Link>
        </Td>
      </Tr>
    );
    return (
      <Fragment>
        {adArchiveRow}
        {this.state.showModalForAdDocumentPreview && (
          <ModalForAdDocumentPreview
            show={this.state.showModalForAdDocumentPreview}
            handleClose={this.handlePreviewClose}
            adId={announcement.id}
            archiveSignal={1}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, { })(AdRow);
