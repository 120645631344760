import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  updateMailSettings,
  getMailSettings,
} from "../../actions/mailSettingsActions";
import Input from "../../components/Reusable/Input";

class UpdateMailSettings extends Component {
  constructor() {
    super();
    this.state = {
      mailAddress: "",
      mailPass: "",
      host: "",
      port: "",
      errors: {},
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getMailSettings(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const { ...mailSettings } = nextProps.mailSettings;

    this.setState({
      ...mailSettings,
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const updateMailSettings = {
      id: this.state.id,
      mailAddress: this.state.mailAddress,
      mailPass: this.state.mailPass,
      host: this.state.host,
      port: this.state.port,
    };
    this.props.updateMailSettings(updateMailSettings, this.props.history);
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto">
                <h1 className="text-center textShadow mt-5">{this.props.dictionary["UpdateMailSettings.title.mailAddress"]}</h1>
                <hr />
                <form onSubmit={this.onSubmit} className="col-md-12">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="textShadow">{this.props.dictionary["UpdateMailSettings.label.mailAddress"]}</label>
                      <Input
                        type="text"
                        id="mailAddress"
                        placeholder={this.props.dictionary["UpdateMailSettings.placeholder.mailAddress"]}
                        name="mailAddress"
                        value={this.state.mailAddress}
                        onChange={this.onChange}
                        errors={errors.mailAddress}
                      />
                      <div id="msg1" />
                    </div>
                    <div className="form-group">
                      <label className="textShadow">{this.props.dictionary["UpdateMailSettings.label.mailPass"]}</label>
                      <Input
                        type="password"
                        id="mailPass"
                        placeholder={this.props.dictionary["UpdateMailSettings.placeholder.mailPass"]}
                        name="mailPass"
                        value={this.state.mailPass}
                        onChange={this.onChange}
                        errors={errors.mailPass}
                      />
                      <div id="msg1" />
                    </div>

                    <div className="form-group">
                      <label className="textShadow">{this.props.dictionary["UpdateMailSettings.label.host"]}</label>
                      <Input
                        type="text"
                        id="host"
                        placeholder={this.props.dictionary["UpdateMailSettings.placeholder.host"]}
                        name="host"
                        value={this.state.host}
                        onChange={this.onChange}
                        errors={errors.host}
                      />
                      <div id="msg1" />
                    </div>
                    <div className="form-group">
                      <label className="textShadow">{this.props.dictionary["UpdateMailSettings.label.port"]}</label>
                      <Input
                        type="text"
                        id="port"
                        placeholder={this.props.dictionary["UpdateMailSettings.placeholder.port"]}
                        name="port"
                        value={this.state.port}
                        onChange={this.onChange}
                        errors={errors.port}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    <button
                      id="subscribeButton"
                      className="col-md-2 float-right"
                    >
                      {this.props.dictionary["UpdateMailSettings.save"]}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UpdateMailSettings.propTypes = {
  updateMailSettings: PropTypes.func.isRequired,
  getMailSettings: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  mailSettings: state.mailSettings.mailSettings,
  errors: state.errors,
  dictionary:state.startUp.dictionary
});

export default connect(mapStateToProps, {
  updateMailSettings,
  getMailSettings,
})(UpdateMailSettings);
