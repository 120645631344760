import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  updateSurveyQuestions,
  getSurveyQuestion,
  getSurveyQuestionTypeList,
} from '../../actions/surveyQuestionsActions';
import TextArea from '../Reusable/TextArea';
import ComboReusable from '../Reusable/ComboReusable';
import SurveyAvailableAnswersTable from '../SurveyAvailableAnswers/SurveyAvailableAnswersTable';
import ModalForAvailableAnswers from '../SurveyAvailableAnswers/ModalForAvailableAnswers';

const OPENED_QUESTION = 1;
const CLOSED_QUESTION = 2;

class UpdateSurveyQuestions extends Component {
  constructor() {
    super();
    this.state = {
      question: '',
      questionType: { id: 0 },
      selectedAnswer: {},
      show: false,
      errors: {},
      surveyAvailableAnswersList: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getSurveyQuestionTypeList();
    this.props.getSurveyQuestion(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const {
      id,
      question,
      questionType,
      survey,
      surveyAvailableAnswersList,
    } = nextProps.surveyQuestions;

    (!this.state.surveyAvailableAnswersList ||
      surveyAvailableAnswersList === this.state.surveyAvailableAnswersList) &&
      this.setState({
        id,
        question,
        questionType,
        survey,
        surveyAvailableAnswersList,
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleComboBoxChange = (object, objectId) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
        fieldId: e.target.value,
        comboSignal: object,
      });
    };
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { surid } = this.props.match.params;
    const { question, questionType, surveyAvailableAnswersList } = this.state;
    const { topicOfDiscussionId } = this.props.match.params;
    const { phaseNumber } = this.props.match.params;

    const updateSurveyQuestions = {
      id: this.state.id,
      question,
      questionType,
      survey: {
        id: surid,
        topicOfDiscussionId: topicOfDiscussionId,
      },
      surveyAvailableAnswersList:
        questionType.id == CLOSED_QUESTION ? surveyAvailableAnswersList : [],
    };
    this.props.updateSurveyQuestions(
      updateSurveyQuestions,
      this.props.history,
      surid,
      topicOfDiscussionId,
      phaseNumber
    );
  };

  showModalAvailableAnswers = () => {
    this.setState({
      show: true,
    });
  };

  handleClose = () => {
    this.setState({
      show: false,
      selectedAnswer: {},
    });
  };

  handleEditAnswer = (ordinalNumber) => {
    const { surveyAvailableAnswersList } = this.state;
    const newSelectedAnswer = surveyAvailableAnswersList.find(
      (item) => item.ordinalNumber === ordinalNumber
    );
    this.setState({ selectedAnswer: newSelectedAnswer });
  };

  handleDeleteAnswer = (ordinalNumber) => {
    const { surveyAvailableAnswersList } = this.state;
    const newSurveyAvailableAnswersList = surveyAvailableAnswersList.filter(
      (item) => item.ordinalNumber !== ordinalNumber
    );
    this.setState({
      surveyAvailableAnswersList: newSurveyAvailableAnswersList,
    });
  };

  handleUpdateAnswer = (answer) => {
    const { surveyAvailableAnswersList } = this.state;
    const newSurveyAvailableAnswersList = surveyAvailableAnswersList.map(
      (item) => {
        if (item.ordinalNumber === answer.ordinalNumber) {
          return (item = answer);
        } else {
          return item;
        }
      }
    );

    this.setState({
      surveyAvailableAnswersList: newSurveyAvailableAnswersList,
    });
  };

  handleAddAnswer = (answer) => {
    const { surveyAvailableAnswersList } = this.state;
    const newSurveyAvailableAnswersList = [
      ...surveyAvailableAnswersList,
      answer,
    ];

    this.setState({
      surveyAvailableAnswersList: newSurveyAvailableAnswersList,
      show: false,
    });
  };

  getMaxOrdinalNumber = (surveyAvailableAnswersList) => {
    let maxOrdinalNumber = 0;
    surveyAvailableAnswersList.forEach((item) => {
      if (item.ordinalNumber > maxOrdinalNumber) {
        maxOrdinalNumber = item.ordinalNumber;
      }
    });
    return maxOrdinalNumber;
  };

  render() {
    const {
      errors,
      show,
      selectedAnswer,
      surveyAvailableAnswersList,
      questionType,
    } = this.state;
    const { questionTypes } = this.props;

    let questionTypeId = this.props.surveyQuestions.questionType
      ? this.props.surveyQuestions.questionType.id
      : '';
    return (
      <div className="container component-wrapper-fade-in">
        <br></br>
        <div className="row">
          <div className="col-md-6 m-auto">
            <h1 className="title text-center">{this.props.dictionary["updateSurveyQuestions.naslov"]}</h1>
            <hr />
            <br />
          </div>
          <form onSubmit={this.onSubmit} className="col-md-12">
            <div className="col-md-6 m-auto mb-4">
              <div className="form-group mb-4">
                <label className="textShadow">{this.props.dictionary["updateSurveyQuestions.pitanje"]}</label>
                <TextArea
                  id="question"
                  type="text"
                  placeholder= {this.props.dictionary["updateSurveyQuestions.placeholder.pitanje"]}
                  name="question"
                  value={this.state.question}
                  onChange={this.onChange}
                  maxLength={1000}
                  rows={6}
                />
              </div>
              {questionTypeId == 1 ? (
                ''
              ) : (
                <div className="form-group m-auto mb-4">
                  <ComboReusable
                    id="questionTypeCbUpdate"
                    namePart={this.props.dictionary["updateSurveyQuestions.namePart.tipPitanja"]}
                    items={questionTypes.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.typeName}
                      </option>
                    ))}
                    onChange={this.handleComboBoxChange('questionType', 'id')}
                    updateValue={questionType ? questionType.id : 0}
                  />
                </div>
              )}
              {questionType && questionType.id == CLOSED_QUESTION && (
                <div className="form-group m-auto mb-4">
                  <div className="d-flex flex-row justify-content-between align-content-center">
                    <label className="textShadow">
                    {this.props.dictionary["updateSurveyQuestions.definisiOdgovore"]}{' '}
                      <span className="redAsterisk"> *</span>
                    </label>
                    <Link
                      className="form-group"
                      onClick={(e) => {
                        this.showModalAvailableAnswers();
                      }}
                      to="#"
                    >
                      <i className="far fa-plus-square createButton"></i>
                    </Link>
                  </div>
                  <SurveyAvailableAnswersTable
                    surveyAvailableAnswersList={surveyAvailableAnswersList}
                    handleEdit={this.handleEditAnswer}
                    handleDelete={this.handleDeleteAnswer}
                    handleUpdateAnswer={this.handleUpdateAnswer}
                    selectedAnswer={selectedAnswer}
                  />
                </div>
              )}
              <div className="form-row justify-content-end">
                <button id="subscribeButton" className="col-md-2">
                {this.props.dictionary["updateSurveyQuestions.sacuvaj"]}  
                </button>
              </div>
            </div>
          </form>
        </div>
        {show && (
          <ModalForAvailableAnswers
            selectedAnswer={selectedAnswer}
            show={show}
            handleClose={this.handleClose}
            formType="createForm"
            handleAddAnswer={this.handleAddAnswer}
            counter={this.getMaxOrdinalNumber(surveyAvailableAnswersList)}
          />
        )}
      </div>
    );
  }
}

UpdateSurveyQuestions.propTypes = {
  updateSurveyQuestions: PropTypes.func.isRequired,
  getSurveyQuestion: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  surveyQuestions: state.surveyQuestions.surveyQuestions,
  errors: state.errors,
  questionTypes: state.surveyQuestions.surveyQuestionTypeList,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  updateSurveyQuestions,
  getSurveyQuestion,
  getSurveyQuestionTypeList,
})(UpdateSurveyQuestions);
