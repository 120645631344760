import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteActionPlan } from '../../actions/actionPlanActions';
import DeleteButton from '../Reusable/DeleteButton';
import ViewDocumentButton from '../Reusable/ViewDocumentButton';
import authorizationService from '../../securityUtils/authorizationService';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Tr, Td } from 'react-super-responsive-table';

class ActionPlanRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpdate: false,
    };
  }
  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteActionPlan(id);
    };
    confirmDialog(`${this.props.dictionary["ActionPlanRow.Obrisi.Poruka"]}`, 
                  action, 
                  `${this.props.dictionary["ActionPlanRow.Obrisi"]}`);
  };

  render() {
    const authRole = this.props.authRole;
    let documentName = this.props.actionPlan.documentName;
    let documentTitle = documentName.substring(0, documentName.indexOf('.pdf'));
    return (
      <Tr>
        <Td className="textShadow">{documentTitle}</Td>
        <Td width="10%" className="text-center">
          <ViewDocumentButton
            documentName={this.props.actionPlan.uuidDocName}
            controllerReference={'actionPlan'}
            ogpPhase={2}
          />
        </Td>
        {authorizationService.canAccessOGPPageSpecificAreas(authRole) ? (
          <Td className="text-center">
            <DeleteButton
              click={() => this.onDeleteClick(this.props.actionPlan.id)}
            />
          </Td>
        ) : (
          <Td></Td>
        )}
      </Tr>
    );
  }
}

ActionPlanRow.propTypes = {
  deleteActionPlan: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  errors: state.errors,
  user: state.user,
  authUser: state.security.authUser,
  authRole: state.security.authRole,
  userRoles: state.security.userRoles,
  userId: state.security.userId,
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps, {
  deleteActionPlan,
})(ActionPlanRow);
