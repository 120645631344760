import {
  CREATE_OGP_ARCHIVE,
  GET_OGP_ARCHIVE_LIST,
  GET_OGP_ARCHIVE,
} from "../actions/types";

const initialState = {
  ogpArchiveList: [],
  ogpArchive: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_OGP_ARCHIVE:
      return {
        ...state,
        ogpArchiveList: state.ogpArchiveList.concat(action.payload),
      };
    case GET_OGP_ARCHIVE_LIST:
      return {
        ...state,
        ogpArchiveList: action.payload,
      };
    case GET_OGP_ARCHIVE:
      return {
        ...state,
        ogpArchive: action.payload,
      };
    default:
      return state;
  }
}
