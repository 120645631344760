import axios from 'axios';
import {
  GET_ERRORS,
  GET_SURVEY_QUESTIONS,
  GET_SURVEY_QUESTIONS_LIST,
  GET_SURVEY_QUESTION_TYPE_LIST,
  DELETE_SURVEY_QUESTIONS,
  CLEAR_SURVEY_QUESTION,
} from './types';

export const createSurveyQuestions = (
  surveyQuestions
) => async (dispatch) => {
  try {
    await axios.post('/api/surveyQuestions/create', surveyQuestions);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateSurveyQuestions = (
  surveyQuestions
) => async (dispatch) => {
  try {
    await axios.put('/api/surveyQuestions/update', surveyQuestions);
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getSurveyQuestionList = (id) => async (dispatch) => {
  const res = await axios.get(`/api/surveyQuestions/findAll/${id}`);
  dispatch({
    type: GET_SURVEY_QUESTIONS_LIST,
    payload: res.data,
  });
};

export const getSurveyQuestionTypeList = (id) => async (dispatch) => {
  const res = await axios.get(`/api/surveyQuestions/findAllQuestionTypes`);
  dispatch({
    type: GET_SURVEY_QUESTION_TYPE_LIST,
    payload: res.data,
  });
};

export const getSurveyQuestion = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/surveyQuestions/findById/${id}`);
    dispatch({
      type: GET_SURVEY_QUESTIONS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const deleteSurveyQuestions = (id) => async (dispatch) => {
    await axios.delete(`/api/surveyQuestions/delete/${id}`);
    dispatch({
      type: DELETE_SURVEY_QUESTIONS,
      payload: id,
    });
};

export const clearSurveyQuestion = () => async (dispatch) => {
  dispatch({
    type: CLEAR_SURVEY_QUESTION,
    payload: {},
  });
};


export const getAllQuestionResults = (id) => async (dispatch) => {
  const res = await axios.get(`/api/surveyQuestions/findAllQuestionResults/${id}`);
  dispatch({
    type: GET_SURVEY_QUESTIONS_LIST,
    payload: res.data,
  });
};

export const exportSurveyResults = (filename, id, surveyName) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/surveyQuestions/exportSurveyResults/${id}`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const currentDate = new Date().toLocaleDateString("sr-RS");
        link.setAttribute("download", `${filename} ${surveyName} ${currentDate.slice(0, -1)}.xlsx`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}
