import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteUserOrganization, crudInProgress, } from '../../actions/userOrganizationActions';
import DeleteButton from '../Reusable/DeleteButton';
import UpdateButton from '../Reusable/UpdateButton';
import UpdateButtonModal from '../Reusable/UpdateButtonModal';
import authorizationService from '../../securityUtils/authorizationService';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Tr, Td } from 'react-super-responsive-table';
import { trackPromise } from "react-promise-tracker";
import {
  NotificationManager,
} from 'react-notifications';

class UserOrganizationRow extends Component {
  constructor() {
    super();
    this.state = {
      showUpdate: false,
    };
  }
  onDeleteClick = (id) => {
    const action = () => {
      trackPromise(this.props.deleteUserOrganization(id)).then(() => {
        NotificationManager.success(this.props.dictionary["UserOrganizationRow.UspesnoBrisanje"],this.props.dictionary["universal.Uspesno"], 5000);
      })
      .catch( () => {
        NotificationManager.error(this.props.dictionary["UserOrganizationRow.Neuspesnobrisanje"], this.props.dictionary["universal.GreskaUObradi"], 5000);
      });
    };
    confirmDialog(this.props.dictionary["modalConfirm.BrisanjeOrganizacije"], action, this.props.dictionary["userOrganization.brisanje"]);
  };

  render() {
   return (
      <Tr>
        <Td className="textShadow text-center">
          {this.props.userOrganization.organizationName}
        </Td>
        <Td className="text-center">
          {this.props.userOrganization.userOrganizationCategory
            ? this.props.userOrganization.userOrganizationCategory
                .userOrganizationCategoryName
            : ''}
        </Td>
        <Td className="text-center">
          {this.props.userOrganization.changedByAdmin
            ? this.props.dictionary["userOrganization.administrator"]
            : this.props.dictionary["userOrganization.korisnik"]}
        </Td>
        <Td width="10%" className="text-center">
          {this.props.isAccessedFromAdminPanel === true ? (
            authorizationService.canAccessAdminPanel(this.props.authRole) ? (
              <UpdateButton
                route={`/updateUserOrganizationAdmin/${this.props.userOrganization.user.id}/${this.props.userOrganization.id}`}
              />
            ) : (
              <UpdateButton />
            )
          ) : (
            <UpdateButtonModal
            onClickHandler={()=>this.props.crudInProgress("update",this.props.userOrganization)}
            />
          )}
        </Td>
        <Td className="text-center">
          {this.props.isAccessedFromAdminPanel === false ? (
            <DeleteButton
              click={() => this.onDeleteClick(this.props.userOrganization.id)}
            />
          ) : (
            <Link className="deleteButton" to="#">
              <i className="far fa-trash-alt faded-text"></i>
            </Link>
          )}
        </Td>
      </Tr>
    );
  }
}

UserOrganizationRow.propTypes = {
  deleteUserOrganization: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  deleteUserOrganization,
  crudInProgress,
})(UserOrganizationRow);
