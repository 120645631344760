import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import UnauthorizedAccess from "./unauthorizedAccess";
import setAuthUserRole from "./setAuthUserRole";
import { useParams } from 'react-router';
import {checkIfTopicIsHidden} from './../actions/topicOfDiscussionActions';

const SecureRouteForHiddenTopic = ({
  component: Component,
  security,
  isTopicOfDiscussionHidden,
  ...otherProps
}) => {

    const params = useParams();
    const id = params['*'].split('/')[1];
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(checkIfTopicIsHidden(id));
    },[id]);

    return (
  <>
  {setAuthUserRole(security.authRole)}
  <Route
    {...otherProps}
    render={(props) =>
      (security.authRole != 1 && isTopicOfDiscussionHidden==true) ? (
        <UnauthorizedAccess />
      ) : (
        <Component {...props} />
      )
    }
  />
  </>
)};

SecureRouteForHiddenTopic.propTypes = {
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  isTopicOfDiscussionHidden: state.topicOfDiscussion.isTopicOfDiscussionHidden
});

export default connect(mapStateToProps, {})(SecureRouteForHiddenTopic);
