import React, { Component } from 'react';
import UploadDocument from '../Reusable/UploadDocument';
import { saveOgpReportFile } from '../../actions/ogpReportActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class UploadOgpReportFile extends Component {

  render() {
    return (
      <UploadDocument 
        handleUpload={this.props.saveOgpReportFile}
        history={this.props.history}
        checkPDF={true}
        infoAboutInputFilesTooltip = {this.props.dictionary["tooltip.universal.infoAboutInputFilesJustPDF"]}
      />
    );
  }
}

UploadOgpReportFile.propTypes = {
  saveOgpReportFile: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  saveOgpReportFile
})(UploadOgpReportFile);

