import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import TextArea from "../Reusable/TextArea";
import { useSelector } from "react-redux";

const SurveyClosedAnswer = (props) => {
    const [answer, setAnswer] = useState(props.answer.answer);
    const dictionary = useSelector((state) => state.startUp.dictionary);

    useEffect(() => {
        setAnswer(props.answer.answer);
      }, [props.answer.answer]);

    const onDelete = () => {
        props.deleteAnswer(props.answer.ordinalNumber);
    }
    const onChangeHandler = (e) => {
        setAnswer(e.target.value);
        props.updateAnswer(e.target.value, props.answer.ordinalNumber);
    }
    const onCheckChangeHandler = (e) => {
        console.log('aaaa');
        props.updateFillUpAnswer(props.answer, e.target.checked);
    }
    return ( 
      <div className="d-flex mb-2">
         {props.multipleChoice? (<Form.Check 
            type="checkbox"
            className='me-2 align-self-center'
            name={'answer_'+props.questionId}
            disabled={props.disabled}
            onChange={onCheckChangeHandler}
         />):( <Form.Check 
            type="radio"
            className='me-2 align-self-center'
            name={'answer_'+props.questionId}
            disabled={props.disabled}
            onChange={onCheckChangeHandler}
         />)}
        
         {(props.edit && !props.moderatorReadOnly) ? (<><TextArea 
                        className="inputField form-control"
                        type="text"
                        placeholder={dictionary["SurveyQuestion.PlaceHolder.Odgovor"]}
                        value={answer}
                        onChange={onChangeHandler}></TextArea> 
                    <button onClick={onDelete}className="far fa-trash-alt only-icon"></button></>)
                    :(<span className="w-100">{answer}</span>)}
      </div>
    ); 
}

export default SurveyClosedAnswer;