import React, { Component } from "react";
import OGPDescriptionRow from "./OGPDescriptionRow";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";
import { connect } from 'react-redux';

class OGPDescriptionTable extends Component {
  render() {
    let counter = 1;
    let ogpDescriptionList = this.props.ogpDescriptionList.map(
      (ogpDescription) => (
        <OGPDescriptionRow
          key={ogpDescription.id}
          ogpDescription={ogpDescription}
          counter={counter++}
        />
      )
    );

    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-sm table-striped mt-2">
          <Thead>
            <Tr className="table-header-height">
              <Th width="5%" className="boldText emphesized-label-font">
                {this.props.dictionary["OGPDescriptionTable.RB"]}
              </Th>
              <Th
                width="20%"
                className="boldText emphesized-label-font text-center"
              >
                {this.props.dictionary["OGPDescriptionTable.DatumObjave"]}
              </Th>
              <Th className="boldText emphesized-label-font">{this.props.dictionary["OGPDescriptionTable.Tekst"]}</Th>
              <Th width="5%" className="boldText emphesized-label-font">
              {this.props.dictionary["OGPDescriptionTable.Status"]}
              </Th>
            </Tr>
          </Thead>
          <Tbody>{ogpDescriptionList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {})(OGPDescriptionTable);

