import {
  GET_FIELD_OF_INTEREST,
  GET_FIELD_OF_INTEREST_LIST,
  GET_FIELD_OF_INTEREST_LIST_BY_JLS,
  GET_FIELD_OF_INTEREST_LIST_FOR_FILTER,
  GET_ACTIVE_FIELD_OF_INTEREST_LIST,
  DELETE_FIELD_OF_INTEREST,
  GET_FIELD_OF_INTEREST_IN_TOPIC,
  CREATE_FIELD_OF_INTEREST_SUCCESS,
  UPDATE_FIELD_OF_INTEREST_SUCCESS
} from "../actions/types";

const initialState = {
  fieldOfInterestList: [],
  fieldOfInterestListByJLS:[],
  activeFieldOfInterestList:[],
  fieldOfInterest: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FIELD_OF_INTEREST_LIST:
      return {
        ...state,
        fieldOfInterestList: action.payload,
      };
    case GET_FIELD_OF_INTEREST_LIST_BY_JLS:
        return {
          ...state,
          fieldOfInterestListByJLS: action.payload,
        };
    case GET_FIELD_OF_INTEREST_LIST_FOR_FILTER:
      return {
        ...state,
        fieldOfInterestForFilter: action.payload
      }
    case GET_ACTIVE_FIELD_OF_INTEREST_LIST:
        return {
          ...state,
          activeFieldOfInterestList: action.payload,
      };
    case GET_FIELD_OF_INTEREST:
      return {
        ...state,
        fieldOfInterest: action.payload,
      };
    case GET_FIELD_OF_INTEREST_IN_TOPIC:
      return {
        ...state,
        //fieldOfInterest: {...state.fieldOfInterest, inTopic:action.payload}
        inTopic:action.payload
      };
    case DELETE_FIELD_OF_INTEREST:
      return {
        ...state,
        fieldOfInterestList: state.fieldOfInterestList.filter(
          (op) => op.id !== action.payload
        ),
      };
      case CREATE_FIELD_OF_INTEREST_SUCCESS:
        return {
          ...state,
          fieldOfInterestCreated: action.payload
        };
      case UPDATE_FIELD_OF_INTEREST_SUCCESS:
        return {
            ...state,
            fieldOfInterestUpdated: action.payload
          };
    default:
      return state;
  }
}
