import {
  GET_TOPIC_OF_DISCUSSION_MODERATOR,
  GET_TOPIC_OF_DISCUSSION_MODERATOR_LIST,
  DELETE_TOPIC_OF_DISCUSSION_MODERATOR,
  CREATE_TOPIC_OF_DISCUSSION_MODERATOR,
} from "../actions/types";

const initialState = {
  topicOfDiscussionModeratorList: [],
  topicOfDiscussionModerator: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TOPIC_OF_DISCUSSION_MODERATOR:
      return {
        ...state,
        topicOfDiscussionModerator: action.payload,
      };
    case GET_TOPIC_OF_DISCUSSION_MODERATOR_LIST:
      return {
        ...state,
        topicOfDiscussionModeratorList: action.payload,
      };
    case DELETE_TOPIC_OF_DISCUSSION_MODERATOR:
      return {
        ...state,
        topicOfDiscussionModeratorList: state.topicOfDiscussionModeratorList.filter(
          (topicOfDiscussionModerator) =>
            topicOfDiscussionModerator.id !== action.payload
        ),
      };
    case CREATE_TOPIC_OF_DISCUSSION_MODERATOR:
      return {
        ...state,
        topicOfDiscussionModeratorList: state.topicOfDiscussionModeratorList.concat(
          action.payload
        ),
      };
    default:
      return state;
  }
}
