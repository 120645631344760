import React, {useEffect, useMemo, useState} from "react";
import { Row } from 'react-bootstrap';
import { MantineReactTable } from 'mantine-react-table';
import { MRT_Localization_SR_CYRL_RS } from 'mantine-react-table/locales/sr-Cyrl-RS';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import { getTopicOfDiscussionsPostAnonUser, setTopicOfDiscussionTableFilter } from '../../actions/topicOfDiscussionActions';
import { getAllFiledsOfInterestForFilterList } from '../../actions/fieldOfInterestActions';
import { setTopicOfDiscussionTableScrollYPosition } from '../../actions/topicOfDiscussionActions'
import {
    NotificationManager,
  } from 'react-notifications';
import WelcomePage from "../Layout/WelcomePage";
import 'dayjs/locale/sr-cyrl';

const TopicOfDiscussionTableServerFilteringLandingPage = (props) => {

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [endProcess, setEndProcess] = useState(props.filter?.filters.find( x => x.id==='endProcess').value || false);
    //table state
    const [columnFilters, setColumnFilters] = useState(props.filter?.filters || [{ id: 'endProcess', value: false},{id:"isJLS", value: false}]);
    const [globalFilter, setGlobalFilter] = useState(props.filter?.globalFilter || '');
    const [sorting, setSorting] = useState(props.filter?.sorting || [{ id: 'creationDate', desc: true}]);
    const [pagination, setPagination] = useState( {
      pageIndex: props.filter?.start || 0,
      pageSize: props.filter?.size || 10,
    });
    const [columnVisibility, setColumnVisibility] = useState({
      currentPhaseNameTableView: true,
      phaseCreationDate: true
     });

    const [searchParams, setSearchParams] = useState({});
    const [refreshColumns, setRefreshColumns] = useState(0);
    const [jLSFilter, setJLSFilter] = useState(false);

    const handleActive = () => {
      setEndProcess(false);
      const array = columnFilters.filter((filter) => {return filter.id !== 'endProcess'});
      //setColumnFilters([...array, {id: 'endProcess', value: false}]);
      setColumnFilters([{ id: 'endProcess', value: false }, ...columnFilters.slice(1)]);
      setPagination({pageIndex: 0,
        pageSize: props.filter?.size || 10});
      setColumnVisibility({
        currentPhaseNameTableView: true,
        phaseCreationDate: true
         });
    }

    const handleArchive = () => {
      setEndProcess(true);
      const array = columnFilters.filter((filter) => {return filter.id !== 'endProcess'});
      //setColumnFilters([...array, {id: 'endProcess', value: true}]);
      setColumnFilters([{ id: 'endProcess', value: true }, ...columnFilters.slice(1)]);
      setPagination({pageIndex: 0,
        pageSize: props.filter?.size || 10});
      setColumnVisibility({
        currentPhaseNameTableView: false,
        phaseCreationDate: false
         });
    }
    const handleLevelCheckChange = (e) => {
    
      if (e.target.name === 'rs') {
        if (columnFilters[1].value == true) {
          setColumnFilters([columnFilters[0], { id: 'isJLS', value: false }, ...columnFilters.slice(2)]);
          setJLSFilter(false);
      }
     } else {
        if (columnFilters[1].value == false) {
          setColumnFilters([columnFilters[0],{ id: 'isJLS', value: true }, ...columnFilters.slice(2)]);
          setJLSFilter(true);
        } else {
        }
        }
        setPagination({pageIndex: 0,
          pageSize: props.filter?.size || 10});
    }
    const cssLiStyle = 'topicTitleText textShadow mb-2 ';
    
    const columns = useMemo(
        () => [
          {
            id: 'nameOfTopic',
            header: props.dictionary["topicOfDiscussionTable.nameOfTopic"],
            size:290,
            accessorFn: (row) => row.nameOfTopic,
            Cell: ({row}) => (<Link
              to={`/topicOfDiscussionPage/${row.original.id}/${row.original.currentPhaseId}`}
              className="remove-link-effect"
              onClick={()=>{props.setTopicOfDiscussionTableScrollYPosition(window.scrollY);}}
            >
              <div>
                <li className={cssLiStyle}>{row.original.nameOfTopic}</li>
              </div>
            </Link>)
          },
          {
            accessorKey: 'institutionName',
            header: props.dictionary["topicOfDiscussionTable.institutionName"],
            size:210
          },
          {
            accessorKey: 'currentPhaseNameTableView',
            header: props.dictionary["topicOfDiscussionTable.currentPhaseName"],
            size:120
          },
          {
            id : 'phaseCreationDate',
            Cell : ({ cell }) => `${cell.getValue() ? cell.getValue()?.toLocaleDateString('sr-RS') : ''}`,
            accessorFn: (row) => row.phaseCreationDate ? new Date(row.phaseCreationDate) : null,
            //filterFn: 'between',
            filterVariant: 'date-range',
            header: props.dictionary["topicOfDiscussionTable.phaseCreationDate"],
            mantineTableHeadCellProps: {
              className:'dateColumn'
            },
            mantineFilterDateInputProps: {
              locale: 'sr-cyrl'
            },
          },
          {
            accessorKey: 'documentType',
            header: props.dictionary["topicOfDiscussionTable.documentType"],
            size:200,
          },
          {
            accessorKey: 'fieldOfInterestName',
            header: props.dictionary["topicOfDiscussionTable.fieldOfInterestName"],
            filterVariant:'multi-select',
            size:200,
            mantineFilterMultiSelectProps: {
              searchable:true,
              data: props.fieldOfInterestForFilter != null ? props.fieldOfInterestForFilter : [],
              //comboboxProps: { width : 100 },
            },
          },
          {
            id : 'creationDate',
            Cell : ({ cell }) => `${cell.getValue() ? cell.getValue()?.toLocaleDateString('sr-RS') : ''}`,
            accessorFn: (row) => row.creationDate ? new Date(row.creationDate) : null,
            filterVariant: 'date-range',
            mantineFilterDateInputProps: {
              locale: 'sr-cyrl'
            },
            header: props.dictionary["topicOfDiscussionTable.creationDate"],
            mantineTableHeadCellProps: {
              className:'dateColumn'
            },
          },
        ],
        [refreshColumns],
      );
      const customTable = 'custom-table';
      const customPaper = 'custom-paper'
      let isYesCheckedStyle =
      columnFilters[0].value == false
        ? 'boldText labelText textShadow text-right me-1'
        : 'labelText textShadow text-right me-1';
    let isNoCheckedStyle =
    columnFilters[0].value == true
        ? 'boldText labelText textShadow text-right me-1 ms-1'
        : 'labelText textShadow text-right me-1 ms-1';
        let isLevelYesCheckedStyle =
        columnFilters[1]?.value == false ? "boldText labelText textShadow text-right me-1 labelNoWordBreak" : "labelText textShadow text-right me-1 labelNoWordBreak";
      let isLevelNoCheckedStyle =
        columnFilters[1]?.value == true ? "boldText labelText textShadow text-right me-1 labelNoWordBreak" : "labelText textShadow text-right me-1 labelNoWordBreak";
   
        useEffect(() => {
          if (!props.topicOfDiscussionList?.length) {
            setIsLoading(true);
          } else {
            setIsRefetching(true);
          }
          var filters = [];
          columnFilters?.forEach(element => {
            switch(element.id) {
              case "creationDate":
                if(element.value[0] != undefined && element.value[0] != undefined && element.value[0] != "") {
                  const offset = element.value[0].getTimezoneOffset();
                  const dateFrom = new Date(element.value[0].getTime() - (offset*60*1000));
                  filters.push( {id: "creationDateFrom", value: dateFrom});
                }
                if(element.value[1] != undefined && element.value[1] != undefined && element.value[1] != "") {
                  const offset = element.value[1].getTimezoneOffset();
                  const dateTo = new Date(element.value[1].getTime() - (offset*60*1000));
                  filters.push( {id: "creationDateTo", value: dateTo});
                }
              break;
              case "phaseCreationDate":
                if(element.value[0] != undefined && element.value[0] != undefined && element.value[0] != "") {
                  const offset = element.value[0].getTimezoneOffset();
                  const dateFrom = new Date(element.value[0].getTime() - (offset*60*1000));
                  filters.push( {id: "phaseCreationDateFrom", value: dateFrom});
                }
                if(element.value[1] != undefined && element.value[1] != undefined && element.value[1] != "") {
                  const offset = element.value[1].getTimezoneOffset();
                  const dateTo = new Date(element.value[1].getTime() - (offset*60*1000));
                  filters.push( {id: "phaseCreationDateTo", value: dateTo});
                }
              break;
              case "fieldOfInterestName":
                let values=element.value?.join(";");
                filters.push({id: "fieldOfInterestName", value:values});
              break;
              default: filters.push(element);
            }
            // if(element.id != "creationDate" && element.id != "phaseCreationDate" && element.id != "fieldOfInterestName" ) filters.push(element);
            // else if (element.id == "creationDate"){
            //   if(element.value[0] != undefined && element.value[0] != undefined && element.value[0] != "") {
            //     const offset = element.value[0].getTimezoneOffset();
            //     const dateFrom = new Date(element.value[0].getTime() - (offset*60*1000));
            //     filters.push( {id: "creationDateFrom", value: dateFrom});
            //   }
            //   if(element.value[1] != undefined && element.value[1] != undefined && element.value[1] != "") {
            //     const offset = element.value[1].getTimezoneOffset();
            //     const dateTo = new Date(element.value[1].getTime() - (offset*60*1000));
            //     filters.push( {id: "creationDateTo", value: dateTo});
            //   }
            // } else if (element.id == "phaseCreationDate"){
            //   if(element.value[0] != undefined && element.value[0] != undefined && element.value[0] != "") {
            //     const offset = element.value[0].getTimezoneOffset();
            //     const dateFrom = new Date(element.value[0].getTime() - (offset*60*1000));
            //     filters.push( {id: "phaseCreationDateFrom", value: dateFrom});
            //   }
            //   if(element.value[1] != undefined && element.value[1] != undefined && element.value[1] != "") {
            //     const offset = element.value[1].getTimezoneOffset();
            //     const dateTo = new Date(element.value[1].getTime() - (offset*60*1000));
            //     filters.push( {id: "phaseCreationDateTo", value: dateTo});
            //   }
            // }
          });
          const searchParams = { 
            start: pagination.pageIndex,
            size: pagination.pageSize,
            filters: filters,
            globalFilter: globalFilter ?? '',
            sorting: sorting ?? []
          };
          const searchParamsToSet = { 
            start: pagination.pageIndex,
            size: pagination.pageSize,
            filters: columnFilters ?? [],
            globalFilter: globalFilter ?? '',
            sorting: sorting ?? []
          };
          props.setTopicOfDiscussionTableFilter(searchParamsToSet);
          trackPromise(props.getTopicOfDiscussionsPostAnonUser(searchParams))
          .catch(() => {
             setIsError(true);
             NotificationManager.error(props.dictionary["universal.NeuspesnoUcitavanjePodataka"]);
            })
          .then( () => {
            setIsError(false);
          })
          .finally(() => {
            setIsLoading(false);
            setIsRefetching(false);
          }
        );
      }, [
        columnFilters,
        globalFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting
      ]);
      useEffect(()=>{
        trackPromise(props.getAllFiledsOfInterestForFilterList(columnFilters[1]?.value)).then(()=>{setRefreshColumns(Math.random())});
      },[
        jLSFilter
      ]);
      useEffect(()=>{
        console.log(props.scrollYPosition);
        if(props.scrollYPosition != undefined) {
          window.scrollTo(0, props.scrollYPosition);
          props.setTopicOfDiscussionTableScrollYPosition(undefined);
        }
      },[props.scrollYPosition]);
    return(
        <>
        <WelcomePage
          jls={columnFilters[1]?.value}
        ></WelcomePage>
        <div id="topicOfDiscussionTableFilter" className="topicOfDiscussionTableFilter"> 

        </div>
        <div className="radioButtonForm">
          <div>
            <label className={isLevelYesCheckedStyle}>
              {props.dictionary["dashboard.commentTable.radioRS"]}
            </label>
            <input
              type="radio"
              id="level"
              name="rs"
              className="filled-in"
              // style={{ cursor: 'pointer', border: 0, height: '1.1em', verticalAlign: 'sub' }}
              checked={!columnFilters[1].value}
              onChange={handleLevelCheckChange}
            />
          </div>
          <div>
            <input
              type="radio"
              id="level"
              name="jls"
              className="filled-in"
              // style={{ cursor: 'pointer', border: 0, height: '1.1em', verticalAlign: 'sub' }}
              checked={columnFilters[1].value}
              onChange={handleLevelCheckChange}
            />
            <label className={isLevelNoCheckedStyle}>
              {props.dictionary["dashboard.commentTable.radioJLS"]}
            </label>
          </div>
      </div>
            <div id="topicOfDiscussionTable" className="radioButtonForm">
                <div>
                    <label className={isYesCheckedStyle}>
                        {props.dictionary["dashboard.commentTable.radioAktivni"]}
                    </label>
                    <input
                        type="radio"
                        id="activity"
                        name="active"
                        className="filled-in"
                        // style={{ cursor: 'pointer', border: 0, height: '1.1em', verticalAlign: 'sub' }}
                        checked={!endProcess}
                        onChange={handleActive}
                    />
                </div>
                <div>
                    <input
                        type="radio"
                        id="activity"
                        name="active"
                        className="filled-in"
                        // style={{ cursor: 'pointer', border: 0, height: '1.1em', verticalAlign: 'sub' }}
                        checked={endProcess}
                        onChange={handleArchive}
                    />
                    <label className={isNoCheckedStyle}>
                        {props.dictionary["dashboard.commentTable.radioArhiva"]}
                    </label>
                </div>
            </div>
        <MantineReactTable
        localization={MRT_Localization_SR_CYRL_RS}
        enableGlobalFilter = {false}
        //columnFilterDisplayMode= 'popover'
        columns={columns}
        onColumnVisibilityChange={setColumnVisibility}
        data={props.topicOfDiscussionList}
        displayColumnDefOptions= {{ 'mrt-row-numbers': { size:10,align:'center' } }}
        mantineTableProps={{
          className: {customTable},
          tablelayout: 'auto',
        }}
        mantineProgressProps={{
          striped:false,
          animate:true,
          size:'xs'
        }}
        mantinePaperProps={{
          className:{customPaper}
        }}
        //enableRowNumbers
        initialState={{ showColumnFilters: true }}
        manualFiltering={true}
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error loading data',
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={props.rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
          columnVisibility: columnVisibility
        }}
      />  
      </>
    );
};
const mapStateToProps = (state) => {
    return {
    dictionary:state.startUp.dictionary,
    topicOfDiscussionList: state?.topicOfDiscussion?.topicOfDiscussionListFilterable?.content ?? [],
    rowCount: state?.topicOfDiscussion?.topicOfDiscussionListFilterable?.totalElements ?? 0,
    filter: state?.topicOfDiscussion?.topicOfDiscussionTableFilter,
    fieldOfInterestForFilter: state.fieldOfInterest.fieldOfInterestForFilter?.map(x=>x.name),
    scrollYPosition: state.topicOfDiscussion.topicOfDiscussionTableScrollYPosition
  } };
  
  export default connect(mapStateToProps, {
    getTopicOfDiscussionsPostAnonUser,
    setTopicOfDiscussionTableFilter,
    getAllFiledsOfInterestForFilterList,
    setTopicOfDiscussionTableScrollYPosition
  })(TopicOfDiscussionTableServerFilteringLandingPage);