import React, { Component } from "react";
import { connect } from "react-redux";
import { getOGPArchiveList } from "../../actions/ogpArchiveActions";
import PropTypes from "prop-types";
import OGPArchiveTable from "./OGPArchiveTable";
import LoadingIndicator from "../Reusable/LoadingIndicator";
import { trackPromise } from "react-promise-tracker";

class OGPArchiveList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    const { ogpId } = this.props.match.params;
    trackPromise(this.props.getOGPArchiveList(ogpId)).finally(() =>
      this.setState({ isLoading: false })
    );
  }

  render() {
    const { isLoading } = this.state;

    const { ogpArchiveList } = this.props.ogpArchive;

    return isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="container-fluid">
        <br></br>
        <div className="row col-md-12 m-auto">
          <div className="col-md-11 m-auto">
            <div className="text-left mb-2">
              <h2 className="textShadow text-center">{this.props.dictionary["OGPArchiveList.Verzija"]}</h2>
              <br></br>
              <OGPArchiveTable ogpArchiveList={ogpArchiveList} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OGPArchiveList.propTypes = {
  ogpArchive: PropTypes.object.isRequired,
  getOGPArchiveList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ogpArchive: state.ogpArchive,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, { getOGPArchiveList })(OGPArchiveList);
