import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateOGPStep, getOGPStep } from "../../actions/ogpStepActions";
import Input from "../Reusable/Input";
import DatePicker from "react-datepicker";
import moment from "moment";

class ModalForOgpStepUpdate extends React.Component {
  constructor(props) {
    super();
    this.state = {
      step: "",
      stepStartDate: "",
      stepEndDate: "",

      errors: {},
    };
    this.handleStepStartDateChange = this.handleStepStartDateChange.bind(this);
    this.handleStepEndDateChange = this.handleStepEndDateChange.bind(this);
  }

  componentDidMount() {
    const ogpStepId = this.props.ogpStepId;
    this.props.getOGPStep(ogpStepId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    const { id, step, stepStartDate, stepEndDate, ogp } = nextProps.ogpStep;

    this.setState({
      id,
      step,
      stepStartDate: moment(stepStartDate).toDate(),
      stepEndDate: moment(stepEndDate).toDate(),
      ogp,
    });
  }

  handleClose = () => {
    this.props.handleClose();
  };

  handleStepStartDateChange = (date) => {
    this.setState({ stepStartDate: date });
  };

  handleStepEndDateChange = (date) => {
    this.setState({ stepEndDate: date });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const newOgpStep = {
      id: this.props.ogpStepId,
      step: this.state.step,
      stepStartDate: this.state.stepStartDate,
      stepEndDate: this.state.stepEndDate,
      ogp: this.props.ogpStep.ogp,
    };

    this.props.updateOGPStep(newOgpStep).then(() => this.props.handleClose());
  };

  render() {
    const { errors } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="xl"
        backdrop={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <h1 className="text-center">{this.props.dictionary["ModalForOgpStepUpdate.IzmenaKoraka"]}</h1>
            <hr />
            <div className="form-row">
              <div className="form-group col-md-8">
                <label className="textShadow">
                {this.props.dictionary["ModalForOgpStepUpdate.Aktivnosti"]}
                </label>
                <Input
                  type="text"
                  id="step"
                  errors={errors.step}
                  placeholder={this.props.dictionary["ModalForOgpStepUpdate.Placeholder.Korak"]}
                  name="step"
                  value={this.state.step}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group col-md-2">
                <label className="textShadow">{this.props.dictionary["ModalForOgpStepUpdate.DatumPocetka"]}</label>
                <DatePicker
                  id="stepStartDate"
                  selected={this.state.stepStartDate}
                  onChange={this.handleStepStartDateChange.bind(
                    "stepStartDate"
                  )}
                  className="form-control"
                  dateFormat="dd.MM.yyyy"
                  placeholderText={this.props.dictionary["ModalForOgpStepUpdate.Placeholder.DatumPocetka"]}
                />
              </div>
              <div className="form-group col-md-2">
                <label className="textShadow">{this.props.dictionary["ModalForOgpStepUpdater.DatumZavrsetka"]}</label>
                <DatePicker
                  id="stepEndDate"
                  selected={this.state.stepEndDate}
                  onChange={this.handleStepEndDateChange.bind("stepEndDate")}
                  className="form-control"
                  dateFormat="dd.MM.yyyy"
                  placeholderText={this.props.dictionary["ModalForOgpStepUpdate.Placeholder.DatumZavrsetka"]}
                />
              </div>
              <button
                type="button"
                id="subscribeButton"
                className="col-md-3 ml-auto"
                onClick={this.handleSubmit}
              >
                {this.props.dictionary["ModalForOgpStepUpdater.Sacuvaj"]}
              </button>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

ModalForOgpStepUpdate.propTypes = {
  getOGPStep: PropTypes.func.isRequired,
  updateOGPStep: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ogpStep: state.ogpStep.ogpStep,
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  getOGPStep,
  updateOGPStep,
})(ModalForOgpStepUpdate);
