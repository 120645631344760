import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import TextArea from '../Reusable/TextArea';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { setMessage, checkPdfStructure } from '../../utils';
import { Tooltip } from 'react-tooltip';
import { createPhase, getPhaseList } from '../../actions/phaseActions';
import { NotificationManager } from 'react-notifications';
import { getMethodList } from "../../actions/methodActions";
import { SpaOutlined } from '@mui/icons-material';


const ModalForStartingPhase = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary);
    const documentTypes = useSelector(state => state.startUp.taxonomies?.DocumentType);
    const actTypes = useSelector(state => state.startUp.taxonomies?.ActType);
    const actType = actTypes?.filter(obj => obj.code === props.topicOfDiscussion.actType?.code);
    const processPhases = useSelector(state => state.startUp.taxonomies?.ProcessPhase);
    const processPhase = processPhases?.filter(obj => obj.code === props.phaseCode);
    const methodTypes = useSelector(state => state.startUp.taxonomies?.MethodType);
    const jls = useSelector(state => state.security.jls);
    const dispatch = useDispatch();
    

    const [information, setInformation] = useState('');
    const [article77, setArticle77] = useState(null);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [documentTypesForPhaseAndActType, setDocumentTypesForPhaseAndActType] = useState([]);
    const [initalDocumentTypesForPhaseAndActType, setInitialDocumentTypesForPhaseAndActType] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isInformationForStartingWorkOnDocument, setIsInformationForStartingWorkOnDocument] = useState(false);
    const [showArticle77Options, setShowArticle77Options] = useState(false);
    const [minimumPhaseDuration, setMinimumPhaseDuration] = useState(1);
    const [requiredDocuments, setRequiredDocuments] = useState([]);
    useEffect(() => {
        if(props.topicOfDiscussion) {
            const infoForStartingWorkOnDocument = props.phaseCode === 'Konsultacije';
            setInformation(infoForStartingWorkOnDocument ? props.topicOfDiscussion.description : '');
            setIsInformationForStartingWorkOnDocument(infoForStartingWorkOnDocument);
            setShowArticle77Options(props.topicOfDiscussion?.actType?.code.includes('Propis') && props.phaseCode==='Konsultacije' && jls==false);
            setMinimumPhaseDuration(processPhase[0]?.taxonomyValueInformationList.find(obj => obj.informationName==='MinimalniPeriodTrajanjaFaze').informationValue);
            const documentTypeIdsByProcessPhase = (processPhase?.map(obj => obj.taxonomyValueInformationList
            .filter(tvi => tvi.informationName==='DostupniTipoviDokumenataZaFazu'))[0])
            ?.map(obj=> obj.informationValue);
            
            
            const documentTypeIdsByActType=(actType?.map(obj => obj.taxonomyValueInformationList
            .filter(tvi => tvi.informationName==='DostupniTipoviDokumenataZaVrstuAkta'))[0])
            ?.map(obj=> obj.informationValue);
        
            const documentTypeIds = documentTypeIdsByActType?.filter(element => documentTypeIdsByProcessPhase?.includes(element));
            let finalDocumentsList = documentTypes.filter(obj => documentTypeIds?.includes(obj.id.toString()))
            if(jls==true && props.topicOfDiscussion?.actType?.code.includes('Propis') && props.phaseCode==='Konsultacije')
                finalDocumentsList = finalDocumentsList.filter(obj => !obj.taxonomyValueInformationList
                    .some(el => el.informationName==='TipDokumentaSamoZaClan77'));
            setInitialDocumentTypesForPhaseAndActType(finalDocumentsList);
            setDocumentTypesForPhaseAndActType(finalDocumentsList.sort((a,b) => a.value.localeCompare(b.value)));

            const requiredDocument=finalDocumentsList
            .filter((obj) => obj.taxonomyValueInformationList.some((tvi) => tvi.informationName==='ObaveznoPolje' && tvi.informationValue==='true'));
            setRequiredDocuments(requiredDocument); 
        }
      }, [props.show]);

    const handleArticle77Change = (e) => {
        setArticle77(e.target.value === 'true')
        setEndDate(null);
        const documents = [...selectedDocuments];
        documents.forEach(function(obj) {
            if(obj.hasOwnProperty('selectedType')) {
                obj.selectedType=null;
            }
                if(obj.hasOwnProperty('hasArticles')) {
                    delete obj.hasArticles;
                }
            });
        let documentTypesByArticle77= initalDocumentTypesForPhaseAndActType;
        if(e.target.value==='true') {
            documentTypesByArticle77 = documentTypesByArticle77
            .filter(obj => obj.taxonomyValueInformationList
                .some(el => el.informationName==='TipDokumentaSamoZaClan77' || el.informationName==='TipDokumentaZaClan77IDrugePropise'));
            setMinimumPhaseDuration(processPhase[0]?.taxonomyValueInformationList.find(obj => obj.informationName==='MinimalniPeriodTrajanjaFazeZaClan77').informationValue);
        }
        else {
            documentTypesByArticle77 = documentTypesByArticle77
            .filter(obj => !obj.taxonomyValueInformationList
                .some(el => el.informationName==='TipDokumentaSamoZaClan77'));
            setMinimumPhaseDuration(processPhase[0]?.taxonomyValueInformationList.find(obj => obj.informationName==='MinimalniPeriodTrajanjaFaze').informationValue);
        }
        documentTypesByArticle77.sort((a,b) => a.value.localeCompare(b.value))
        const requiredDocument=documentTypesByArticle77
            .filter((obj) => obj.taxonomyValueInformationList.some((tvi) => tvi.informationName==='ObaveznoPolje' && tvi.informationValue==='true'));
        setRequiredDocuments(requiredDocument); 
        setDocumentTypesForPhaseAndActType(documentTypesByArticle77);
    }

    const handleDocumentChange = (e) => {
        const documents = Array.from(e.target.files);
        const documentsPdfOnly = documents.filter(obj => obj.type==='application/pdf');
        setSelectedDocuments((previousDocuments) => [...previousDocuments, ...documentsPdfOnly]);
        e.target.value=null;
    }

    const handleDeleteDocument = (index) => {
        const documents = [...selectedDocuments];
        documents.splice(index,1);
        setSelectedDocuments(documents);
    }

    const handleDocumentTypeChange = async (index, option) => {
        const documents = [...selectedDocuments];
        let documentToUpdate = documents[index];
        if(!option.taxonomyValueInformationList.some(obj => obj.informationName === 'TipDokumentaViseDokumentaZaUpload' && obj.informationValue==='true')){
            const isDocumentTypeSelected = documents.some((obj,i) => obj.selectedType?.code === option.code && i !== index);
            if(isDocumentTypeSelected) {
                dispatch(setMessage(
                    `${dictionary["ModalForStartingPhase.Validation.TipDokumentaVecIzabran"]}`,
                    '#documentType'+index, 
                    '#documentTypeValidationMsg'+index
                ));
                documentToUpdate.selectedType = null;
                if(documentToUpdate.hasOwnProperty('hasArticles')) {
                    delete documentToUpdate.hasArticles;
                }
                documents.splice(index,1,documentToUpdate);
                setSelectedDocuments(documents);
                return;
            }
        }
        const isDocumentForCommentGathering = option.taxonomyValueInformationList.some((el) => el.informationName==='ZaKomentarisanje' && el.informationValue==='true');
        if(isDocumentForCommentGathering){
           try {const pdfStructure = await checkPdfStructure([documentToUpdate], '#documentType'+index, '#documentTypeValidationMsg'+index);
           if(pdfStructure === false) {
                documentToUpdate.selectedType = null;
                if(documentToUpdate.hasOwnProperty('hasArticles')) {
                    delete documentToUpdate.hasArticles;
                }
                documents.splice(index,1,documentToUpdate);
                setSelectedDocuments(documents);
                return;
           }} catch(error) {
                documentToUpdate.selectedType = null;
                documents.splice(index, 1, documentToUpdate);
                setSelectedDocuments(documents);
                return;
           }
        }
        if(documentToUpdate) {
            documentToUpdate.selectedType = option;
            if(isDocumentForCommentGathering)
                documentToUpdate.hasArticles=false;
            else {
                if(documentToUpdate.hasOwnProperty('hasArticles')) {
                    delete documentToUpdate.hasArticles;
                }
            }
            documents.splice(index,1,documentToUpdate);
        }
        setSelectedDocuments(documents);
    }

    const handleDateChange = (date, type) => {
        if(type === 'startDate') {
            setStartDate(date);
            setEndDate(null);
        }
        else if(type === 'endDate')
            setEndDate(date);
    }

    const handleHasArticlesChange = (index) => {
        const documents = [...selectedDocuments];
        const documentToUpdate = documents[index];
        if(documentToUpdate) {
            documentToUpdate.hasArticles = !documentToUpdate.hasArticles
            documents.splice(index,1,documentToUpdate);
        }

        setSelectedDocuments(documents);
    }

    const handleTextAreaChange = (e) => {
        setInformation(e.target.value);
    }

    const handleValidation = () => {
        let error = false;

        if(isInformationForStartingWorkOnDocument && information==='') {
            dispatch(setMessage(
                `${dictionary["ModalForStartingPhase.Validation.Obavestenje"]}`,
                '#information',
                '#informationValidationMsg'
            ))
            error=true;
        }

        if(showArticle77Options && article77==null){
            dispatch(setMessage(
                `${dictionary["ModalForStartingPhase.Validation.Clan77"]}`,
                '#article77',
                '#article77ValidationMsg'
            ))
            error=true;
        }

        if(startDate==null){
            dispatch(setMessage(
                `${dictionary["ModalForStartingPhase.Validation.DatumPocetka"]}`,
                '#startDate',
                '#startDateValidationMsg'
            ))
            error=true;
        }

        if(endDate==null){
            dispatch(setMessage(
                `${dictionary["ModalForStartingPhase.Validation.DatumZavrsetka"]}`,
                '#endDate',
                '#endDateValidationMsg'
            ))
            error=true;
        }

        
        const documentsWithoutSelectedType = selectedDocuments
            .filter(obj=> !obj.hasOwnProperty('selectedType') || obj.selectedType==null);
        
        if(documentsWithoutSelectedType.length>0){
            dispatch(setMessage(
                `${dictionary["ModalForStartingPhase.Validation.ObavezanOdabirTipaDokumenta"]}`,
                '#documents',
                '#documentsValidationMsg'
            ))
            error=true;
        } else {
            const requiredDocumentIds= requiredDocuments;
            if(requiredDocumentIds.length>0){
                const requiredDocumentIdsNotUploaded = requiredDocumentIds.filter((el) => !selectedDocuments.some((obj) => obj.selectedType?.id === el.id));
                if(requiredDocumentIdsNotUploaded.length>0){
                    dispatch(setMessage(
                        `${dictionary["ModalForStartingPhase.Validation.ObavezniDokumenti"]}`,
                        '#documents',
                        '#documentsValidationMsg'
                    ))
                    error=true; 
                }
                let uploadedDocumentSize = 0
                for (let i = 0; i < selectedDocuments.length; i++) {
                    uploadedDocumentSize += selectedDocuments[i].size;
                }
                if(uploadedDocumentSize >= 10000000) {
                    dispatch(setMessage(
                        `${dictionary["ModalForStartingPhase.Validation.DokumentiVeciOd10MB"]}`,
                        '#documents',
                        '#documentsValidationMsg'
                    ))
                    error=true; 
                }
            }
        }

            

        return error;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
      
        if(handleValidation()){
            return;
        }
        setEndDate(endDate.setHours(23,59,59,999));
        const phase = {
            endDate:endDate,
            startDate:startDate,
            phaseType: processPhase[0],
            topicOfDiscussion:{
                id:props.topicOfDiscussionId,
                nameOfTopic:props.topicOfDiscussion.nameOfTopic,
            },
            article77:article77,
            information:information
        }

        let formData = new FormData();
        const documentTypes = [];
        const methods = [];
       
        if(isInformationForStartingWorkOnDocument) {
            const method = {
                creationDate:startDate,
                description:information,
                hasArticles:0,
                title:dictionary["ModalForStartingPhase.ObavestenjeOPocetkuRadaNaDokumentu"],
                methodType: methodTypes.find(x => x.code === 'Obavestenje'),
                topicOfDiscussion: {
                    id: props.topicOfDiscussionId
                },
                startDate:startDate,
            }
            formData.append(
                'methodAnnouncement',
                new Blob([JSON.stringify(method)], {
                    type: 'application/json',
                  })
            );
        } else if(information!=='') {
            const method = {
                creationDate:startDate,
                description:information,
                hasArticles:0,
                title:dictionary["ModalForStartingPhase.ObavestenjeOJavnojRaspravi"],
                methodType: methodTypes.find(x => x.code === 'Obavestenje'),
                topicOfDiscussion: {
                    id: props.topicOfDiscussionId
                },
                startDate:startDate,
            }
            formData.append(
                'methodAnnouncement',
                new Blob([JSON.stringify(method)], {
                    type: 'application/json',
                  })
            ); 
        }

        for (let i = 0; i < selectedDocuments.length; i++) {
            formData.append('documents',selectedDocuments[i]);
            documentTypes.push(selectedDocuments[i].selectedType.id);

            if(selectedDocuments[i].selectedType.taxonomyValueInformationList
                .some((el) => el.informationName==='ZaKomentarisanje' && el.informationValue==='true')) {
                    const method = {
                        // creationDate:startDate,
                        description:information,
                        hasArticles:selectedDocuments[i].hasArticles,
                        title:dictionary["ModalForStartingPhase.PrikupljanjeKomentaraNaslov"]+' '+selectedDocuments[i].selectedType.value.toLowerCase(),
                        methodType: methodTypes.find(x => x.code === 'PrikupljanjeKomentara'),
                        topicOfDiscussion: {
                            id: props.topicOfDiscussionId
                        },
                        // startDate:startDate,
                        endDate:endDate,
                        documentIndex:i
                    }

                    methods.push(method);
                }
        }
    
        formData.append(
          'phase',
          new Blob([JSON.stringify(phase)], {
            type: 'application/json',
          })
        );
        formData.append('documentTypes',documentTypes);
        
        formData.append(
            'methods',
            new Blob([JSON.stringify(methods)], {
                type: 'application/json',
              })
        );

        dispatch(createPhase(formData))
        .then(() => {
            NotificationManager.success(dictionary["ModalForStartingPhase.UspesnoPokretanjeFaze"],dictionary["universal.Uspesno"], 5000); 
            // dispatch(getPhaseList(props.topicOfDiscussionId));   
          })
          .catch( () => { 
            NotificationManager.error(dictionary["ModalForStartingPhase.NeuspesnoPokretanjeFaze"], dictionary["universal.GreskaUObradi"], 5000);
          })
          .finally(() => {
            handleClose();
            handleRefresh();
            });
    }
    const handleRefresh = () => {
        //window.location.reload();
        props.handlePhaseRefresh();
    };

    const handleClose = () => {
        setInformation('')
        setArticle77(null)
        setSelectedDocuments([])
        setDocumentTypesForPhaseAndActType([])
        setInitialDocumentTypesForPhaseAndActType([])
        setStartDate(null)
        setEndDate(null)
        props.handleClose();
    }
    const customStyleForMultiselect = {
        control: (styles) => ({ ...styles, minHeight: '20px', marginLeft: '20px', marginRight: '20px' }),
        option: (styles) => ({
          ...styles,
          color: '#495057',
          fontSize: '14px',
        }),
        placeholder: (styles) => ({
          ...styles,
          color: '#6c757d',
          fontSize: '14px',
          marginLeft: '12px',
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: '#6c757d',
          fontSize: '14px',
        }),
        input: (styles) => ({
          ...styles,
          color: '#6c757d',
          fontSize: '1rem',
          marginLeft: '12px',
        }),
      };
    const requiredDocumentsValue = requiredDocuments.map(obj => obj.value).join(', ')
    return (
        <Modal
        show={props.show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
            <Modal.Title>
                <h4 className="text-center textShadow m-auto">
                    {props.phaseCode === 'Konsultacije' ? dictionary["ModalForStartingPhase.NaslovKonsultacije"]:dictionary["ModalForStartingPhase.NaslovJavnaRasprava"]}
                </h4>
            </Modal.Title>
        </Modal.Header> 
        <Modal.Body className='scroll-80vh'>        
            <div className='ms-1 me-1'>
                <div className="form-group mt-2 mb-4">
                    <label className="labelText textShadow">
                        {isInformationForStartingWorkOnDocument ? dictionary["ModalForStartingPhase.ObavestenjePocetakRadaNaDokumentu"]:dictionary["ModalForStartingPhase.Obavestenje"]}: 
                        {isInformationForStartingWorkOnDocument ? 
                        (
                            <span className="redAsterisk"> *</span>
                        ) : 
                        (
                            <>
                                <i data-tooltip-id="informationTooltip" className="fas fa-info-circle ms-2"></i>
                                <Tooltip
                                    id="informationTooltip"
                                    type="info"
                                    effect="solid"
                                    place="right"
                                >
                                    {dictionary["ModalForStartingPhase.Tooltip.Obavestenje"]}
                                </Tooltip>
                            </>  
                        )}
                    </label>
                    <TextArea
                        type="text"
                        id="information"
                        name="information"
                        value={information}
                        onChange={handleTextAreaChange}
                        maxLength="1000"
                        rows={5}
                    />
                    <p className="small-font">
                    <span className="redAsterisk"> *</span>
                    <em>
                    {dictionary["ModalForStartingPhase.Info.PrviDeo"]} <strong>{dictionary["ModalForStartingPhase.Info.BrojKaraktera"]}</strong> {dictionary["ModalForStartingPhase.Info.ZadnjiDeo"]}{' '}
                    </em>
                    </p>
                    <div id="informationValidationMsg" />
                </div>
                {showArticle77Options &&
                    <>
                        <div id="article77" className="form-group mt-2 mb-4">
                            <label className="labelText textShadow">
                                {dictionary["ModalForStartingPhase.Clan77"]}: <span className="redAsterisk"> *</span>
                            </label>
                            <div className='article77-group mt-2'>
                                <label className='me-3'>
                                    <input
                                        type='radio'
                                        value='true'
                                        checked={article77 == true}
                                        onChange={handleArticle77Change}
                                    />
                                    {dictionary["ModalForStartingPhase.Da"]}
                                </label>
                                <label>
                                    <input
                                        type='radio'
                                        value='false'
                                        checked={article77 == false}
                                        onChange={handleArticle77Change}
                                    />
                                    {dictionary["ModalForStartingPhase.Ne"]}
                                </label>
                            </div>
                            <div id="article77ValidationMsg" />
                        </div>
                    </> 
                }
                <label className="labelText textShadow">
                    {dictionary["ModalForStartingPhase.Dokumenti"]}:
                </label>
                {((showArticle77Options && article77!=null) || !showArticle77Options) && <>
                    {requiredDocuments.length>0 && <span className="redAsterisk"> *</span>}
                    <div className="fst-italic">
                        <i data-tooltip-id="requiredDocumentsTooltip" className="fas fa-info-circle me-1"></i>
                        <span>
                            {requiredDocuments.length>0 ? dictionary["ModalForStartingPhase.Tooltip.ObavezniDokumenti"]+' '+requiredDocumentsValue:dictionary["ModalForStartingPhase.Tooltip.NemaObaveznihDokumenataZaUnos"]}
                        </span>
                    </div>
                </>}
                <div id="documents">
                    <label data-tooltip-id="inputFileTooltip" id="inputFileRemoveDefault" className="mb-2" >
                        <input type="file" accept='.pdf' onChange={handleDocumentChange} multiple disabled={showArticle77Options ? (article77==null):false}/>
                        <i className="fas fa-cloud-upload-alt fa-3x fa-pull-right uploadButton mt-2"></i>
                    </label>
                    {showArticle77Options && article77==null && <Tooltip
                        id="inputFileTooltip"
                        type="info"
                        effect="solid"
                        place="right"
                    >
                        {dictionary["ModalForStartingPhase.Tooltip.UploadDokumenata"]}
                    </Tooltip>}
                    {selectedDocuments.length > 0 && (
                        <div>
                            <ul className='ps-0'>
                                {selectedDocuments.map((document, index) => 
                                    <li key={index} id={index} className='align-items-center g-5 mb-3 relatedTopicCard'>
                                        <button className="far fa-trash-alt only-icon" onClick={()=> handleDeleteDocument(index)} ></button>
                                        <label>{document.name}</label>
                                        <Select
                                            name={'documentType'+index}
                                            id={'documentType'+index}
                                            value={document.selectedType}
                                            onChange={(selectedDocumentType) => handleDocumentTypeChange(index, selectedDocumentType)}
                                            options={documentTypesForPhaseAndActType}
                                            styles={customStyleForMultiselect}
                                            getOptionLabel={(option) => option.value}
                                            getOptionValue={(option) => option.id}
                                            placeholder={dictionary["ModalForStartingPhase.IzaberiteTipDokumentaPlaceholder"]}
                                        />
                                        {document.hasOwnProperty('hasArticles') && (
                                            <div>
                                                <label className='me-2'>
                                                    <input 
                                                        className='me-1'
                                                        type='checkbox'
                                                        checked={document.hasArticles}
                                                        onChange={() => handleHasArticlesChange(index)}
                                                    />
                                                    {dictionary["ModalForStartingPhase.DokumentSadrziClanove"]}
                                                </label>
                                            </div>
                                        )}
                                        <div id={'documentTypeValidationMsg'+index}/>
                                    </li>       
                                )}      
                            </ul>
                        </div>
                    )}
                 </div>
                 <div id="documentsValidationMsg"/>
                <div className="form-group mb-4 d-flex align-items-center gap-5 mt-4">
                    <div>
                    <label className="labelText textShadow">
                      {dictionary["ModalForStartingPhase.DatumPocetka"]}:
                      <span className="redAsterisk"> *</span>
                    </label>
                    <DatePicker
                      id="startDate"
                      selected={startDate}
                      onChange={(date) => handleDateChange(date, 'startDate')}
                      className="dateInput form-control"
                      dateFormat="dd.MM.yyyy"
                      placeholderText={dictionary["ModalForStartingPhase.DatumPocetka"]}
                      minDate={new Date()}
                    />
                    <div id="startDateValidationMsg" />
                    </div>
                    <div>
                    <label className="labelText textShadow">
                      {dictionary["ModalForStartingPhase.DatumZavrsetka"]}:
                      <span className="redAsterisk"> *</span>
                    </label>
                    <DatePicker
                      id="endDate"
                      selected={endDate}
                      onChange={(date) => handleDateChange(date, 'endDate')}
                      className="dateInput form-control"
                      dateFormat="dd.MM.yyyy"
                      placeholderText={dictionary["ModalForStartingPhase.DatumZavrsetka"]}
                      minDate={startDate? new Date(startDate.getTime() + minimumPhaseDuration * 24 * 60 * 60 * 1000) : null}
                    />
                     <div id="endDateValidationMsg" />
                    </div>
                </div>
                <br/>
                <div className="row">
                    <button
                    type="button"
                    id="subscribeButton"
                    className="col-md-3 m-auto"
                    onClick={handleSubmit}
                    >
                    {dictionary["ModalForStartingPhase.Sacuvaj"]}
                    </button>
                </div>
            </div>
        </Modal.Body> 
      </Modal>
    ); 
}

export default ModalForStartingPhase;