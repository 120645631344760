import React from 'react';
import { connect } from 'react-redux';
class OGPInstitutionComboBox extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const { ogpList } = this.props;
    let optionItems = ogpList
      .filter((ogp) => ogp.organisationName)
      .map((ogp) => (
        <option key={ogp.id} value={ogp.organisationName}>
          {ogp.organisationName}
        </option>
      ));

    return (
      <select
        id="institutionOGPCb"
        className="stats-combo-style form-control form-control-md"
        value={this.props.inst}
        onChange={this.props.onChange}
        defaultValue={this.props.inst ? this.props.inst : '-1'}
      >
        <option key="" value="-1" disabled hidden>
          {this.props.dictionary["OGPInstitutionComboBox.placeholder"]}
        </option>
        {optionItems}
      </select>
    );
  }
}
const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary
});

export default connect(mapStateToProps, {
})(OGPInstitutionComboBox);

