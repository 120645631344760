import {
  GET_OGP_DOCUMENT,
  GET_OGP_DOCUMENT_LIST,
  DELETE_OGP_DOCUMENT,
} from "../actions/types";

const initialState = {
  ogpDocumentList: [],
  ogpDocument: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OGP_DOCUMENT_LIST:
      return {
        ...state,
        ogpDocumentList: action.payload,
      };
    case GET_OGP_DOCUMENT:
      return {
        ...state,
        ogpDocument: action.payload,
      };
    case DELETE_OGP_DOCUMENT:
      return {
        ...state,
        ogpDocumentList: state.ogpDocumentList.filter(
          (ogpDocument) => ogpDocument.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
