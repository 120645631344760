import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteUserOrganizationCategory } from '../../actions/userOrganizationCategoryActions';
import DeleteButton from '../Reusable/DeleteButton';
import UpdateButton from '../Reusable/UpdateButton';
import authorizationService from '../../securityUtils/authorizationService';
import { Tr, Td } from 'react-super-responsive-table';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

class UserOrganizationCategoryRow extends Component {
  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteUserOrganizationCategory(id)
      .then(() => {
        NotificationManager.success(this.props.dictionary["UserOrganizationCategoryRow.UspesnoObrisanaKategorije"]);
        })
      .catch(() => {
          NotificationManager.error(this.props.dictionary["UserOrganizationCategoryRow.NeuspesnoBrisanjeKategorije"]);
      })
    };
    confirmDialog(this.props.dictionary["modalConfirm.BrisanjeKategorije"], action, this.props.dictionary["userOrganizationCategory.brisanje"]);
  };

  render() {
   return (
      <Tr>
        <Td className="textShadow">
          {this.props.userOrganizationCategory.userOrganizationCategoryName}
        </Td>

        {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
          <Td width="10%" className="text-center">
            {/* <UpdateButton
              route={`/updateUserOrganizationCategory/${this.props.userOrganizationCategory.id}`}
            /> */}
            <Link to='#' onClick={()=>{this.props.showModalForUpdateFunction(this.props.userOrganizationCategory.id)}}>
              <i className="fas fa-edit deleteButton"></i>
            </Link>
          </Td>
        ) : (
          ''
        )}
        {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
          <Td width="10%" className="text-center">
            <DeleteButton
              click={() =>
                this.onDeleteClick(this.props.userOrganizationCategory.id)
              }
            />
          </Td>
        ) : (
          ''
        )}
      </Tr>
    );
  }
}

UserOrganizationCategoryRow.propTypes = {
  deleteUserOrganizationCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  deleteUserOrganizationCategory,
})(UserOrganizationCategoryRow);
