import axios from "axios";
import ApiService from "../securityUtils/ApiService";

import {
  GET_ERRORS,
  GET_TOPIC_OF_DISCUSSION,
  GET_IS_TOPIC_OF_DISCUSSION_OPEN_FOR_COMMENTS,
  GET_TOPIC_OF_DISCUSSION_LIST,
  GET_TOPIC_OF_DISCUSSION_LIST_PAGEABLE,
  DELETE_TOPIC_OF_DISCUSSION,
  CREATE_TOPIC_OF_DISCUSSION,
  UPDATE_TOPIC_OF_DISCUSSION,
  GET_TOPIC_OF_DISCUSSION_LIST_BY_DATE,
  GET_TOPIC_OF_DISCUSSION_LIST_DTO,
  GET_TOPIC_OF_DISCUSSION_CURRENT_CONSULTATIONS_AND_DEBATES_LIST_DTO,
  GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_LIST_DTO,
  GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_AND_NOTIFICATION_LIST_DTO,
  GET_ALL_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST_LIST_BY_USER_ID,
  GET_ALL_TOPIC_OF_DISCUSSION_INSTITUTION_LIST_BY_USER_ID,
  GET_TOPIC_OF_DISCUSSION_GROUP_LIST_DTO,
  GET_TOPIC_OF_DISCUSSION_GROUP_REPRESENTATIVES_LIST_DTO,
  GET_TOPICS_OF_DISCUSSION_BY_USER_ID,
  GET_TOPIC_OF_DISCUSSION_LIST_BY_FIELD_OF_INTEREST,
  GET_TOPIC_OF_DISCUSSION_MODERATOR_DTO,
  GET_TOPIC_OF_DISCUSSION_LIST_FILTERABLE,
  GET_OTHER_TOPIC_OF_DISCUSSION_LIST_FILTERABLE,
  GET_TOPIC_OF_DISCUSSION_WELCOME_PAGE,
  SET_TOPIC_OF_DISCUSSION_TABLE_FILTER,
  SET_TOPIC_OF_DISCUSSION_TABLE_SCROLLYPOSITION,
  GET_IS_TOPIC_OF_DISCUSSION_DOCUMENTS,
  GET_FINISHED_TOPIC_OF_DISCUSSION,
  GET_HIDDEN_TOPIC_OF_DISCUSSION,
  GET_IS_TOPIC_OF_DISCUSSION_HIDDEN
} from "./types";

export const createTopicOfDiscussion =
  (topicOfDiscussion) => async (dispatch) => {
    try {
      const res = await axios.post(
        "/api/topicOfDiscussion/create",
        topicOfDiscussion
      );

      dispatch({
        type: CREATE_TOPIC_OF_DISCUSSION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      throw err;
    }
  };
export const updateTopicOfDiscussion =
  (topicOfDiscussion) => async (dispatch) => {
    try {
      const response = await axios.put(
        "/api/topicOfDiscussion/update",
        topicOfDiscussion
      );
      dispatch({
        type: UPDATE_TOPIC_OF_DISCUSSION,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  };

export const getAllTopicsOfDiscussion = () => async (dispatch) => {
  const res = await axios.get(`/api/topicOfDiscussion/findAll`);
  dispatch({
    type: GET_TOPIC_OF_DISCUSSION_LIST,
    payload: res.data,
  });
};

export const getAllTopicsOfDiscussionPageable =
  (pageNumber, pageSize) => async (dispatch) => {
    const res = await axios
      .get(`/api/topicOfDiscussion/findAll/${pageNumber}/${pageSize}`)
      .then((response) => {
        dispatch({
          type: GET_TOPIC_OF_DISCUSSION_LIST_PAGEABLE,
          payload: [
            response.data.content,
            response.data.totalPages,
            response.data.totalElements,
          ],
        });
      });
  };

export const getAllTopicsOfDiscussionPageableFilter =
  (topicOfDiscussionDTO) => async (dispatch) => {
    const res = await axios
      .post(
        `/api/topicOfDiscussion/findAllPageableFilter`,
        topicOfDiscussionDTO
      )
      .then((response) => {
        dispatch({
          type: GET_TOPIC_OF_DISCUSSION_LIST_PAGEABLE,
          payload: [
            response.data.content,
            response.data.totalPages,
            response.data.totalElements,
          ],
        });
      });
  };

export const getAllTopicsOfDiscussionByDate = () => async (dispatch) => {
  const res = await axios.get(`/api/topicOfDiscussion/findByOrderByUpdatedAt`);
  dispatch({
    type: GET_TOPIC_OF_DISCUSSION_LIST_BY_DATE,
    payload: res.data,
  });
};

export const getTopicOfDiscussion = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/topicOfDiscussion/findById/${id}`);
    dispatch({
      type: GET_TOPIC_OF_DISCUSSION,
      payload: res.data,
    });
  } catch (e) {
    // e.getMessage();
  }
};

export const getTopicOfDiscussionOriginal = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/topicOfDiscussion/findByIdOriginal/${id}`
    );
    dispatch({
      type: GET_TOPIC_OF_DISCUSSION,
      payload: res.data,
    });
  } catch (e) {
    // e.getMessage();
  }
};

export const deleteTopicOfDiscussion = (id) => async (dispatch) => {
  const res = await axios.delete(`/api/topicOfDiscussion/delete/${id}`);
  dispatch({
    type: DELETE_TOPIC_OF_DISCUSSION,
    payload: res.data,
  });
};

export const getAllTopicsOfDiscussionDTO = () => async (dispatch) => {
  const res = await axios.get(`/api/topicOfDiscussion/findAllDTO`);
  dispatch({
    type: GET_TOPIC_OF_DISCUSSION_LIST_DTO,
    payload: res.data,
  });
};

export const getAllTopicsOfDiscussionByFieldAndUser =
  (id) => async (dispatch) => {
    const res = await axios.get(`/api/fieldOfInterestUser/findByUserId/${id}`);
    dispatch({
      type: GET_ALL_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST_LIST_BY_USER_ID,
      payload: res.data,
    });
  };
export const getAllTopicsOfDiscussionByInstitutionAndUser =
  (id) => async (dispatch) => {
    const res = await axios.get(`/api/institutionUser/findByUserId/${id}`);
    dispatch({
      type: GET_ALL_TOPIC_OF_DISCUSSION_INSTITUTION_LIST_BY_USER_ID,
      payload: res.data,
    });
  };

export const getAllCurrentConsultationsAndPublicDebates =
  () => async (dispatch) => {
    const res = await axios.get(
      `/api/topicOfDiscussion/findAllCurrentConsultationsAndPublicDebates`
    );
    dispatch({
      type: GET_TOPIC_OF_DISCUSSION_CURRENT_CONSULTATIONS_AND_DEBATES_LIST_DTO,
      payload: res.data,
    });
  };

export const getAllTopicsWithUserActivity = () => async (dispatch) => {
  const req = {
    method: "GET",
    url: `/api/topicOfDiscussion/findAllTopicsWithUserActivity`,
  };
  const res = await ApiService.callApi(req);
  dispatch({
    type: GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_LIST_DTO,
    payload: res.data,
  });
};

export const getAllTopicsWithUserActivityAndNotification = () => async (dispatch) => {
  const req = {
    method: "GET",
    url: `/api/topicOfDiscussion/findAllTopicsWithUserActivityAndNotification`,
  };
  const res = await ApiService.callApi(req);
  dispatch({
    type: GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_AND_NOTIFICATION_LIST_DTO,
    payload: res.data,
  });
};

export const getTopicsOfDiscussionGroup =
  (topicOfDiscussionGroupId) => async (dispatch) => {
    const res = await axios.get(
      `/api/topicOfDiscussion/findAllByGroup/${topicOfDiscussionGroupId}`
    );
    dispatch({
      type: GET_TOPIC_OF_DISCUSSION_GROUP_LIST_DTO,
      payload: res.data,
    });
  };

export const getAllArchivedTopicsOfDiscussion = () => async (dispatch) => {
  const res = await axios.get(
    `/api/topicOfDiscussion/findAllArchivedTopicGroupRepresentatives`
  );
  dispatch({
    type: GET_TOPIC_OF_DISCUSSION_GROUP_REPRESENTATIVES_LIST_DTO,
    payload: res.data,
  });
};

export const getTopicsOfDiscussionByUserId = () => async (dispatch) => {
  const res = await axios.get(
    `/api/topicOfDiscussion/findByTopicOfDiscussionParticipantId`
  );
  dispatch({
    type: GET_TOPICS_OF_DISCUSSION_BY_USER_ID,
    payload: res.data,
  });
};

export const getTopicOfDiscussionModeratorDTO = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/topicOfDiscussion/findTopicOfDiscussionModerators/${id}`
    );
    dispatch({
      type: GET_TOPIC_OF_DISCUSSION_MODERATOR_DTO,
      payload: res.data,
    });
  } catch (e) {
    // e.getMessage();
  }
};

export const exportRawData = (filename) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios({
      url: `/api/topicOfDiscussion/exportRawData`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const currentDate = new Date().toLocaleDateString("sr-RS");
        link.setAttribute("download", `${filename} ${currentDate.slice(0, -1)}.xlsx`);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTopicOfDiscussionsPost = (searchParams) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/topicOfDiscussion/findAllFillteredTopicsOfDiscussion",
      searchParams
    );
    dispatch({
      type: GET_TOPIC_OF_DISCUSSION_LIST_FILTERABLE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const getOtherTopicOfDiscussionsPost = (searchParams) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/topicOfDiscussion/findAllFillteredTopicsOfDiscussion",
      searchParams
    );
    dispatch({
      type: GET_OTHER_TOPIC_OF_DISCUSSION_LIST_FILTERABLE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const getTopicOfDiscussionsPostAnonUser =
  (searchParams) => async (dispatch) => {
    try {
      const res = await axios.post(
        "/api/topicOfDiscussion/findAllFillteredTopicsOfDiscussionAnonUser",
        searchParams
      );
      dispatch({
        type: GET_TOPIC_OF_DISCUSSION_LIST_FILTERABLE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data,
      });
    }
  };

export const getTopicOfDiscussionsWelcomePage =
  (searchParams) => async (dispatch) => {
    try {
      const res = await axios.post(
        "/api/topicOfDiscussion/findAllFillteredTopicsOfDiscussionAnonUser",
        searchParams
      );
      dispatch({
        type: GET_TOPIC_OF_DISCUSSION_WELCOME_PAGE,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data,
      });
    }
  };

export const setTopicOfDiscussionTableFilter =
  (searchParams) => async (dispatch) => {
    try {
      dispatch({
        type: SET_TOPIC_OF_DISCUSSION_TABLE_FILTER,
        payload: searchParams,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data,
      });
    }
  };

export const setTopicOfDiscussionTableScrollYPosition = 
(scrollYPosition) => async(dispatch) => {
  try {
    dispatch({
      type: SET_TOPIC_OF_DISCUSSION_TABLE_SCROLLYPOSITION,
      payload: scrollYPosition,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

  export const isTopicOfDiscussionOpenForComments = (id) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/topicOfDiscussion/isTopicOfDiscussionOpenForComments/${id}`);
      dispatch({
        type: GET_IS_TOPIC_OF_DISCUSSION_OPEN_FOR_COMMENTS,
        payload: res.data,
      });
    } catch (e) {
      // e.getMessage();
    }
  };

  export const getTopicOfDiscussionDocuments = (id) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/topicOfDiscussion/findDocumentsByTopicOfDiscussionId/${id}`);
      dispatch({
        type: GET_IS_TOPIC_OF_DISCUSSION_DOCUMENTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data,
      });
    }
  };

  export const updateFinalVersionOfDocumentForTopicOfDiscussion =
  (topicOfDiscussionId, finalVersionOfDocument) => async (dispatch) => {
    try {
      const response = await axios.put(
        `/api/topicOfDiscussion/updateFinalVersionOfDocumentForTopicOfDiscussion/${topicOfDiscussionId}?finalVersionOfDocument=${finalVersionOfDocument}`, 
      );
      dispatch({
        type: UPDATE_TOPIC_OF_DISCUSSION,
        payload: response.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    }
  };

  export const finishTooEarly = (id) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/topicOfDiscussion/finishTooEarly/${id}`);
      dispatch({
        type: GET_FINISHED_TOPIC_OF_DISCUSSION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data,
      });
    }
  };

  export const assignMainModerator = (data) => async (dispatch) => {
    try {
      const res = await axios.post(`/api/topicOfDiscussion/assignMainModerator`, data);
      dispatch({
        type: UPDATE_TOPIC_OF_DISCUSSION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data,
      });
    }
  };

  export const hideProcessAndNotifyModerator = (id, mailbody) => async (dispatch) => {
    try {
      const res = await axios.post(`/api/topicOfDiscussion/hideProcessAndNotifyModerator/${id}`, mailbody);
      dispatch({
        type: GET_HIDDEN_TOPIC_OF_DISCUSSION,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data,
      });
    }
  };
  export const checkIfTopicIsHidden = (id) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/topicOfDiscussion/checkIfTopicIsHidden/${id}`);
      dispatch({
        type: GET_IS_TOPIC_OF_DISCUSSION_HIDDEN,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data,
      });
    }
  };
