import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { getTopicsOfDiscussionByFieldsOfInterest
 } from '../../actions/topicsOfDiscussionFieldsOfInterestActions';
import { addRelatedTopicsOfDiscussion } from '../../actions/topicOfDiscussionRelationActions';
import { NotificationManager } from 'react-notifications';

const ModalForAddTopicOfDiscussionRelation = (props) => {
    const dispatch = useDispatch();
    const dictionary = useSelector(state => state.startUp.dictionary);

    const [topicOfDiscussionOptions, setTopicOfDiscussionOptions] = useState([]);
    const topicsOfDiscussionByFieldsOfInterest = useSelector(state => state.topicOfDiscussionFieldsOfInterest.topicsOfDiscussionByFieldsOfInterest);
    const [finalRelatedTopicsOfDiscussion, settFinalRelatedTopicsOfDiscussion] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
      dispatch(getTopicsOfDiscussionByFieldsOfInterest(props.topicOfDiscussion.fieldOfInterests));
      settFinalRelatedTopicsOfDiscussion(props.relatedTopicOfDiscussionList);
    }, []);
    
    useEffect(() => {
      const topicOfDiscussion = props.topicOfDiscussion;
      let topicOfDiscussionList = topicsOfDiscussionByFieldsOfInterest;

      if(topicOfDiscussionList!=null && topicOfDiscussionList.length>0) {
        topicOfDiscussionList = topicOfDiscussionList.filter(el => el.id !== topicOfDiscussion.id);
        setTopicOfDiscussionOptions(topicOfDiscussionList);
      }
    }, [topicsOfDiscussionByFieldsOfInterest]);

    const handleClose = () => {
        props.handleClose();
    }

    const handleSubmit = () => {
      const relatedTopicsOfDiscussion = finalRelatedTopicsOfDiscussion;
      const topicOfDiscussion = {
        id:props.topicOfDiscussion.id
      };

      let formData = new FormData();
      formData.append(
        'topicOfDiscussion',
        new Blob([JSON.stringify(topicOfDiscussion)], {
            type: 'application/json',
          })
      );
      formData.append(
        'relatedTopicsOfDiscussion',
        new Blob([JSON.stringify(relatedTopicsOfDiscussion)], {
            type: 'application/json',
          })
      );

      dispatch(addRelatedTopicsOfDiscussion(formData))
      .then(() => {
        NotificationManager.success(dictionary["ModalForAddRelatedTopicOfDiscussion.UspesnoCuvanjePovezanihProcesa"],dictionary["universal.Uspesno"], 5000);     
      })
      .catch( () => { 
        NotificationManager.error(dictionary["ModalForAddRelatedTopicOfDiscussion.NeuspesnoCuvanjePovezanihProcesa"], dictionary["universal.GreskaUObradi"], 5000);
      })
      .finally(() => {
        handleClose();
      });
    }

    const handleSelectChange = (option) => {
      const topics = [...finalRelatedTopicsOfDiscussion];
      const isTopicPresentInFinalList = topics.find(obj => obj.id===option.id)
      if(!isTopicPresentInFinalList) {
        topics.push(option);
        settFinalRelatedTopicsOfDiscussion(topics);
    }
    }

    const handleRemoveTopic = (index) => {
      const topics = [...finalRelatedTopicsOfDiscussion];
      topics.splice(index,1);
      settFinalRelatedTopicsOfDiscussion(topics);
    }
    const customStyleForMultiselect = {
        control: (styles) => ({ ...styles, minHeight: '25px', marginLeft:'0.5rem', marginRight:'0.5rem'}),
        option: (styles) => ({
          ...styles,
          color: '#495057',
          fontSize: '14px',
        }),
        placeholder: (styles) => ({
          ...styles,
          color: '#6c757d',
          fontSize: '14px',
          marginLeft: '12px',
        }),
        multiValueLabel: (styles) => ({
          ...styles,
          color: '#6c757d',
          fontSize: '14px',
        }),
        input: (styles) => ({
          ...styles,
          color: '#6c757d',
          fontSize: '1rem',
          marginLeft: '12px',
        }),
      };

    return (
        <Modal
        show={props.show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
            <Modal.Title>
                {dictionary["ModalForAddRelatedTopicOfDiscussion.NaslovPovezaniProcesi"]}
            </Modal.Title>
        </Modal.Header> 
        <Modal.Body>     
            <div className='col-md-12'>
            <label className='ps-2'>{dictionary["ModalForAddRelatedTopicOfDiscussion.IzborPovezaniProcesi"]}</label>
            <Select
                name={'relatedTopicOfDiscussions'}
                id={'relatedTopicOfDiscussions'}
                value={selectedOption}
                onChange={(option)=> {handleSelectChange(option)}}
                options={topicOfDiscussionOptions}
                styles={customStyleForMultiselect}
                getOptionLabel={(option) => option.nameOfTopic}
                getOptionValue={(option) => option.id}
                placeholder={dictionary["ModalForAddRelatedTopicOfDiscussion.IzborPovezaniProcesiPlaceholder"]}
            />
            <div className='mb-4 mt-4'>
              <ul className='ps-2'>
              {finalRelatedTopicsOfDiscussion.map((topic, index) => 
               <li key={index} id={index} className='align-items-center g-5 mb-3 relatedTopicCard'>
                <button className="far fa-trash-alt only-icon" onClick={()=> handleRemoveTopic(index)}></button>
                <label className='ms-2'>{topic.nameOfTopic}</label>
               </li>
              )}
              </ul>
            </div>
            <div className='row'>
              <button
                  type="button"
                  id="subscribeButton"
                  className="col-md-3 m-auto"
                  onClick={handleSubmit}
                  >
                  {dictionary["ModalForAddRelatedTopicOfDiscussion.Sacuvaj"]}
              </button>
            </div>
            </div>   
        </Modal.Body> 
      </Modal>
    ); 
}
export default ModalForAddTopicOfDiscussionRelation;