import React, { Component, useEffect } from 'react';
import {
  withRouter
} from "react-router-dom";

class ScrollToTopOnRedirect extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
  }
  
    render() {
      const { children } = this.props;
      return children;
    }
  }
  
  export default withRouter(ScrollToTopOnRedirect);