import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopicOfDiscussionDashboardRow from './TopicOfDiscussionDashboardRow';
import { TablePagination } from '@mui/material';
import { paragrapghPagination, changePaginationText } from '../../utils';
import { rowsPerPageOptions } from '../../constants';

class TopicOfDiscussionDashboardList extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
    };
  }

  render() {
    const {
      topicsOfDiscussionByUserId,
      topicOfDiscussionUserActivityListDTO,
      topicOfDiscussionListPageable,
      totalElements,
    } = this.props.topicOfDiscussionPackage || {};

    const {
      createTopicOfDiscussionParticipant,
      unsubscribeFromTopicOfDiscussion,
    } = this.props || {};

    let topicsWithSubscribedFlag = [];
    topicsWithSubscribedFlag = topicOfDiscussionListPageable?.map((topic) => {
      const isUserTopic =
        topicsOfDiscussionByUserId.filter((all) => all.id === topic.id).length >
        0;
      return {
        ...topic,
        subscribed: isUserTopic,
      };
    });

    const { handleTopicsRefreash } = this.props || {};

    let userActivityList = [];
    if (topicOfDiscussionUserActivityListDTO) {
      userActivityList = topicOfDiscussionUserActivityListDTO;
    }

    const isUserModerator = this.props.authRole == '2';
    if (isUserModerator === false) {
      userActivityList = topicsOfDiscussionByUserId;
    }

    let topics = [];
    if (topicOfDiscussionListPageable) {
      topics =
        this.props.quickPreviewSignal === 1 ? (
          userActivityList.length !== 0 ? (
            userActivityList.map((topic, index) => (
              <TopicOfDiscussionDashboardRow
                key={index}
                topic={topic}
                registeredUser={1}
                quickPreviewSignal={this.props.quickPreviewSignal}
                isUserModerator={isUserModerator}
              />
            ))
          ) : (
            <tr className="component-wrapper-fade-in">
              <td className="ml-3">{this.props.dictionary["TopicOfDiscussionDashboardList.NisteUcestvovali"]}</td>
            </tr>
          )
        ) : (
          topicsWithSubscribedFlag.map((topic, index) => (
            <TopicOfDiscussionDashboardRow
              key={index}
              topic={topic}
              registeredUser={1}
              quickPreviewSignal={this.props.quickPreviewSignal}
              isUserModerator={isUserModerator}
              handleTopicParticipantRefreash={handleTopicsRefreash}
              createTopicOfDiscussionParticipant={
                createTopicOfDiscussionParticipant
              }
              unsubscribeFromTopicOfDiscussion={
                unsubscribeFromTopicOfDiscussion
              }
            />
          ))
        );
    }
    if (topics.length === 0) {
      topics = (
        <tr className="component-wrapper-fade-in-large-list">
          <td colSpan="2" className="faded-text mt-4 large-font">
          {this.props.dictionary["TopicOfDiscussionDashboardList.NijePronadjenProces"]}
          </td>
        </tr>
      );
    }

    const tableStyle =
      this.props.quickPreviewSignal != 1 ? 'topic-table-height-dashboard' : '';
    const listScroller= {
        // overflow: 'auto',
        maxHeight: '70vh',
    }
    return (
      <div>
        <div className={tableStyle} style={listScroller}>
          <table
            id="registeredUserTableId"
            className="table-responsive d-table component-wrapper-fade-in-large-list p-2"
          >
            <tbody
              id="topicUnorderedList"
              className="component-wrapper-fade-in-large-list"
            >
              {topics}
            </tbody>
          </table>
        </div>
        {this.props.quickPreviewSignal != 1 && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TablePagination
              className="text-shadow"
              component="div"
              count={Number(totalElements)}
              page={this.props.pageNumber}
              onPageChange={this.props.handlePageChange}
              rowsPerPageOptions={rowsPerPageOptions}
              onRowsPerPageChange={this.props.handleChangeRowsPerPage}
              rowsPerPage={this.props.pageSize}
              labelRowsPerPage={paragrapghPagination(`${this.props.dictionary["TopicOfDiscussionDashboardList.BrojRedova"]}`)}
              labelDisplayedRows={(obj) => changePaginationText(obj)}
            />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  user: state.user,
  authUser: state.security.authUser,
  authRole: state.security.authRole,
  userRoles: state.security.userRoles,
  userId: state.security.userId,
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps,{}) (TopicOfDiscussionDashboardList);
