import axios from "axios";

import {
  GET_ERRORS,
  GET_TOPIC_OF_DISCUSSION_MODERATOR,
  GET_TOPIC_OF_DISCUSSION_MODERATOR_LIST,
  DELETE_TOPIC_OF_DISCUSSION_MODERATOR,
  CREATE_TOPIC_OF_DISCUSSION_MODERATOR,
} from "./types";

export const createTopicOfDiscussionModerator = (
  topicOfDiscussionModerator
) => async (dispatch) => {
  try {
    const res = await axios.post(
      "/api/topicOfDiscussionModerator/create",
      topicOfDiscussionModerator
    );
    dispatch({
      type: CREATE_TOPIC_OF_DISCUSSION_MODERATOR,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getAllTopicOfDiscussionModerator = (topicId) => async (
  dispatch
) => {
  const res = await axios.get(
    `/api/topicOfDiscussionModerator/findAll/${topicId}`
  );
  dispatch({
    type: GET_TOPIC_OF_DISCUSSION_MODERATOR_LIST,
    payload: res.data,
  });
};

export const deleteTopicOfDiscussionModerator = (id) => async (dispatch) => {
  await axios.delete(`/api/topicOfDiscussionModerator/delete/${id}`);
  dispatch({
    type: DELETE_TOPIC_OF_DISCUSSION_MODERATOR,
    payload: id,
  });
};
