import axios from 'axios';

import {
 GET_ERROR_LOG, GET_ERRORS
} from './types';


export const getErrorLog= (searchParams) => async (dispatch) => {
  const res = await axios.post('/api/errorLog/getErrorLog',searchParams);
  dispatch({
    type: GET_ERROR_LOG,
    payload: res.data,
  });
};
