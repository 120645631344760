import { Card } from 'react-bootstrap';
import MethodCardHeader from './MethodCardHeader';
import MethodCardBody from './MethodCardBody';
import MethodCardLeftBar from './MethodCardLeftBar';
import MethodCardFooter from './MethodCardFooter';

const MethodCard = ({method, isModerator, endProcess, phase, topicOfDiscussionId, handleMethodRefresh}) => {
    return (
        <Card className={`shadow-sm mt-3 rounded-0 bg-white  d-flex flex-row`}>
            <div className={method.active ? 'method-card-ongoing' : 'method-card-passed'}></div>
            <MethodCardLeftBar isModerator={isModerator} 
                               endProcess={endProcess} 
                               method={method}/>
            <div className="flex-fill w-90percent">
                <MethodCardHeader method={method} />
                <MethodCardBody method={method} />
                <MethodCardFooter method={method} 
                                isModerator={isModerator}
                                phase={phase} 
                                topicOfDiscussionId={topicOfDiscussionId}
                                handleMethodRefresh={handleMethodRefresh} 
                                endProcess={endProcess}
                />
            </div>
        </Card>
    );
}
export default MethodCard;