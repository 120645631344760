import { useSelector } from "react-redux"
import Input from '../Reusable/Input';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import UploadDocumentInitialisedFiles from '../Reusable/UploadDocumentInitialisedFiles';
import TextArea from '../Reusable/TextArea';
import MethodDocumentTable from "../MethodDocument/MetodDocumentTable";
import MethodDocuments from "../MethodDocument/MethodDocuments";

const CommentGathering = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary);

    const status = props.method?.active ? 'MethodStatus.UToku' : 
    (props.method?.endDate && new Date(props.method?.endDate) < Date.now()) ? 'MethodStatus.Zavrseno' : 
   'MethodStatus.UPripremi'

        const handleStartDateChange = (e) => {
            if (!props.startDate) {
                const selectedDate = new Date(e);
                const isMidnight = selectedDate.getHours() === 0 && selectedDate.getMinutes() === 0;
        
                if (isMidnight) {
                    const now = new Date();
                    selectedDate.setHours(now.getHours());
                    selectedDate.setMinutes(now.getMinutes());
                    props.setStartDate(selectedDate);
                } else {
                    props.setStartDate(e);
                }
            } else { 
                props.setStartDate(e);
            }
        }

        const handleEndDateChange = (e) => {
            if (!props.endDate) {
                const selectedDate = new Date(e);
                const isMidnight = selectedDate.getHours() === 0 && selectedDate.getMinutes() === 0;
            
                if (isMidnight) {
                const adjustedDate = new Date(e);
                adjustedDate.setHours(23);
                adjustedDate.setMinutes(59);
                props.setEndDate(adjustedDate);
                } else {
                props.setEndDate(e);
                }
            } else {
                props.setEndDate(e);
            }
        }
    return (
        <>
            <div className="form-group">
                <label className="labelText textShadow">
                    {dictionary["ModalForAddNewMethod.Naslov"]}: <span className="redAsterisk"> *</span>
                </label>
                <Input
                    type="text"
                    id="title"
                    placeholder={dictionary["ModalForAddNewMethod.Placeholder.Naslov"]}
                    name="title"
                    value={props.title}
                    onChange={(e) => props.setTitle(e.target.value)}
                    //errors={errors.title}
                />
                <div id="titleМsg" />

            </div>
            {props.metod && props.edit &&
            <div className="pb-2 pt-2 form-group">
                  <label className="labelText textShadow pe-3">{dictionary["ModalForSurveyUpdate.StatusAnkete"]}:</label>
                  <span>{dictionary[status]}</span>
            </div>
            }
            <div className="d-flex">
                    <div className="form-group me-2 flex-grow-1">
                        <label className="labelText textShadow">
                            {dictionary["ModalForAddNewMethod.StartDate"]}: <span className="redAsterisk"> *</span>
                        </label>
                        <DatePicker
                            id="startDate"
                            selected={props.startDate ? new Date(props.startDate) : null}
                            onChange={(e) => handleStartDateChange(e)}
                            className="dateInput form-control"
                            dateFormat="dd.MM.yyyy HH:mm"
                            placeholderText={dictionary["ModalForAddNewMethod.Placeholder.StartDate"]}
                            minDate={moment().toDate()}
                            maxDate={props.phase?.endDate ? new Date(props.phase?.endDate) : null}
                            showTimeInput
                            timeInputLabel={dictionary["DatePicker.TimeInputLabel"]}
                            />
                        <div id="startDateМsg" />
                    </div>
                    <div className="form-group ms-2 flex-grow-1">
                        <label className="labelText textShadow">
                            {dictionary["ModalForAddNewMethod.EndDate"]}: <span className="redAsterisk"> *</span>
                        </label>
                        <DatePicker
                            id="endDate"
                            selected={props.endDate ? new Date(props.endDate) : null}
                            onChange={(e) => handleEndDateChange(e)}
                            className="dateInput form-control"
                            dateFormat="dd.MM.yyyy HH:mm"
                            placeholderText={dictionary["ModalForAddNewMethod.Placeholder.EndDate"]}
                            minDate={moment().toDate()}
                            maxDate={props.phase?.endDate ? new Date(props.phase?.endDate) : null}
                            showTimeInput
                            timeInputLabel={dictionary["DatePicker.TimeInputLabel"]}
                            />
                        <div id="endDateMsg" />
                    </div>
                </div>
            <div className="form-group">
                    <label className="labelText textShadow">
                    {dictionary["ModalForAddNewMethod.Tekst"]}: <span className="redAsterisk"> *</span>
                    </label>
                    <TextArea
                        type="text"
                        id="description"
                        placeholder={dictionary["ModalForAddNewMethod.Placeholder.Tekst"]}
                        name="description"
                        value={props.description}
                        onChange={(e) => props.setDescription(e.target.value)}
                        //errors={errors.description}
                        maxLength="1000"
                        rows={6}
                    />
                    <div id="descriptionMsg" />
            </div>
            <div className="form-group pt-2">
                <input
                    className="me-2 pointer"
                    type="checkbox"
                    checked={props.hasArticles}
                    onChange={(e) => props.setHasArticles(e.target.checked)}
                />
                <label className="labelText textShadow align-text-bottom">
                    {dictionary["ModalForAddNewMethod.DokumentSadrziClanove"]}
                </label>
            </div>
            <MethodDocuments
                methodDocuments = {props.methodDocuments}
                uploadedFiles={props.uploadedFiles}
                setUploadedFiles={props.setUploadedFiles}
                selectedFieldsOfInterest={[]}
                phaseId={props.phase.id}
                documents={props.documents}
                setDocuments={props.setDocuments}
                methodId={props.methodId}
                selectedMethodType={props.selectedMethodType}
                oneFileAllowed={true}
                readOnly={Date.now() > new Date(props.method?.startDate)}
            />
        </>
    )
}

export default CommentGathering;