import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Tr, Td } from 'react-super-responsive-table';
import ModalForUpdateOGPComment from './ModalForUpdateOGPComment';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import { connect } from 'react-redux';

class OGPCommentRow extends Component {
  constructor(props) {
    super();
    this.state = {
      showModalForCommentUpdate: false,
    };
  }

  showModalForCommentUpdate = (e) => {
    this.setState({
      showModalForCommentUpdate: true,
    });
  };

  handlePreviewClose = () => {
    this.setState({
      showModalForCommentUpdate: false,
    });
  };

  render() {
    const nameAndSurname = this.props.ogpComment.user
      ? this.props.ogpComment.user.firstName +
        ' ' +
        this.props.ogpComment.user.lastName
      : '';

    let phase = `${this.props.dictionary["OGPCommentRow.NacrtPlana"]}`;
    if (this.props.ogpComment.phaseNumber == 3) {
      phase = `${this.props.dictionary["OGPCommentRow.Izvestaji"]}`;
    }

    let unhandledCommentSign = !this.props.ogpComment.visibility ? (
      <PriorityHighRoundedIcon
        style={{ color: 'red', position: 'absolute', marginLeft: '-23px' }}
      />
    ) : (
      ''
    );

    let OGPCommentRow = (
      <Tr>
        <Td>
          {unhandledCommentSign}
          {this.props.counter ? this.props.counter + '.' : ''}
        </Td>
        <Td>
          <em>{phase}</em>
        </Td>
        <Td>{nameAndSurname}</Td>
        <Td>
          {this.props.ogpComment.userOrganizationName
            ? this.props.ogpComment.userOrganizationName
            : ''}
        </Td>
        <Td className="text-center">
          {this.props.ogpComment.category ? this.props.ogpComment.category : ''}
        </Td>
        <Td className="text-center">
          {moment(this.props.ogpComment.creationDate).format(
            'DD.MM.YYYY HH:mm:ss'
          )}
        </Td>
        <Td className="text-center">{this.props.ogpComment.visibility}</Td>
        <Td className="text-center">
          <Link
            onClick={(e) => {
              e.preventDefault();
              this.showModalForCommentUpdate();
            }}
            className="form-group deleteButton"
            to="#"
          >
            <i className="fas fa-edit"></i>
          </Link>
        </Td>
      </Tr>
    );
    return this.state.showModalForCommentUpdate == false ? (
      OGPCommentRow
    ) : (
      <Fragment>
        {OGPCommentRow}
        <ModalForUpdateOGPComment
          show={this.state.showModalForCommentUpdate}
          handleClose={this.handlePreviewClose}
          ogpCommentId={this.props.ogpComment.id}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {})(OGPCommentRow);


