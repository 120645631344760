import { combineReducers } from 'redux';
import errorReducer from './errorReducer';
import userReducer from './userReducer';
import securityReducer from './securityReducer';
import fieldOfInterestReducer from './fieldOfInterestReducer';
import fieldOfInterestUserReducer from './fieldOfInterestUserReducer';
import topicOfDiscussionReducer from './topicOfDiscussionReducer';
import commentReducer from './commentReducer';
import institutionReducer from './institutionReducer';
import ogpReducer from './ogpReducer';
import viewAttachmentReducer from './viewAttachmentReducer';
import actionPlanReportReducer from './actionPlanReportReducer';
import roleReducer from './roleReducer';
import surveyQuestionsReducer from './surveyQuestionsReducer';
import surveyAnswersReducer from './surveyAnswersReducer';
import topicOfDiscussionFieldsOfInterestReducer from './topicOfDiscussionFieldsOfInterestReducer';
import ogpFieldOfInterestReducer from './ogpFieldOfInterestReducer';
import ogpFieldsOfInterestReducer from './ogpFieldsOfInterestReducer';
import topicOfDiscussionParticipantReducer from './topicOfDiscussionParticipantReducer';
import notificationReducer from './notificationReducer';
import userOrganizationReducer from './userOrganizationReducer';
import commentDocumentReducer from './commentDocumentReducer';
import ogpStepReducer from './ogpStepReducer';
import ogpArchiveReducer from './ogpArchiveReducer';
import ogpDocumentReducer from './ogpDocumentReducer';
import ogpActionPlanArchiveReducer from './ogpActionPlanArchiveReducer';
import userOrganizationCategoryReducer from './userOrganizationCategoryReducer';
import mailSettingsReducer from './mailSettingsReducer';
import actionPlanReducer from './actionPlanReducer';
import adReducer from './adReducer';
import ogpModeratorReducer from './ogpModeratorReducer';
import ogpDescriptionReducer from './ogpDescriptionReducer';
import topicOfDiscussionModeratorReducer from './topicOfDiscussionModeratorReducer';
import topicOfDiscussionInstitutionsReducer from './topicOfDiscussionInstitutionsReducer';
import ogpReportReducer from './ogpReportReducer';
import ogpCommentReducer from './ogpCommentReducer';
import notificationTopicOfDiscussionReducer from './notificationTopicOfDiscussionReducer';
import adDocumentReducer from './adDocumentReducer';
import moderatorResourcesReducer from './moderatorResourcesReducer';
import startUpReducer from "./startUpReducer";
import errorLogReducer from './errorLogReducer';
import methodReducer from './methodReducer';
import phaseReducer from './phaseReducer';
import methodParticipantReducer from './methodParticipantReducer';
import topicOfDiscussionRelationReducer from './topicOfDiscussionRelationReducer';

export default combineReducers({
  errors: errorReducer,
  security: securityReducer,
  user: userReducer,
  fieldOfInterest: fieldOfInterestReducer,
  fieldOfInterestInstitutionUser: fieldOfInterestUserReducer,
  topicOfDiscussion: topicOfDiscussionReducer,
  comment: commentReducer,
  institution: institutionReducer,
  ogp: ogpReducer,
  viewAttachment: viewAttachmentReducer,
  actionPlanReport: actionPlanReportReducer,
  role: roleReducer,
  surveyQuestions: surveyQuestionsReducer,
  surveyAnswers: surveyAnswersReducer,
  topicOfDiscussionFieldsOfInterest: topicOfDiscussionFieldsOfInterestReducer,
  ogpFieldOfInterest: ogpFieldOfInterestReducer,
  ogpFieldsOfInterest: ogpFieldsOfInterestReducer,
  notification: notificationReducer,
  userOrganization: userOrganizationReducer,
  commentDocument: commentDocumentReducer,
  ogpStep: ogpStepReducer,
  commentMaxId: commentReducer,
  ogpArchive: ogpArchiveReducer,
  ogpDocument: ogpDocumentReducer,
  userOrganizationCategory: userOrganizationCategoryReducer,
  mailSettings: mailSettingsReducer,
  actionPlan: actionPlanReducer,
  ad: adReducer,
  ogpModerator: ogpModeratorReducer,
  ogpDescription: ogpDescriptionReducer,
  topicOfDiscussionModerator: topicOfDiscussionModeratorReducer,
  topicOfDiscussionParticipant: topicOfDiscussionParticipantReducer,
  topicOfDiscussionInstitutions: topicOfDiscussionInstitutionsReducer,
  ogpReportFile: ogpReportReducer,
  ogpComment: ogpCommentReducer,
  ogpCommentMaxId: ogpCommentReducer,
  notificationTopicOfDiscussion: notificationTopicOfDiscussionReducer,
  adDocument: adDocumentReducer,
  moderatorResources: moderatorResourcesReducer,
  ogpActionPlanArchive: ogpActionPlanArchiveReducer,
  startUp: startUpReducer,
  errorLog: errorLogReducer,
  method: methodReducer,
  methodParticipant: methodParticipantReducer,
  phase: phaseReducer,
  topicOfDiscussionRelation:topicOfDiscussionRelationReducer
});
