
import { useSelector } from "react-redux";
import MethodCard from "./MethodCard";


const MethodList = (props) => {

    const methodList = useSelector(state => state.method.methodList);
    const topicOfDiscussion = useSelector(state => state.topicOfDiscussion.topicOfDiscussion);

    return (
        <>  
        {methodList?.map((method) => {
            return <MethodCard 
                key={method.id}
                method={method}
                endProcess={topicOfDiscussion?.endProcess}
                isModerator={props.isModerator}
                phase={props.phase}
                handleMethodRefresh={props.handleMethodRefresh}
                topicOfDiscussionId={props.topicOfDiscussionId}
            />
        })}

        </>
    )
}
export default MethodList;
