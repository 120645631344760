import React, {useEffect, useMemo, useState} from "react";

import { MantineReactTable } from 'mantine-react-table';
import { MRT_Localization_SR_CYRL_RS } from 'mantine-react-table/locales/sr-Cyrl-RS';
import authorizationService from '../../securityUtils/authorizationService';
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import { getTopicOfDiscussionsPost,
  setTopicOfDiscussionTableFilter } from '../../actions/topicOfDiscussionActions';
import { Link } from "react-router-dom";
import {
  createTopicOfDiscussionParticipant,
  unsubscribeFromTopicOfDiscussion,
} from '../../actions/topicOfDiscussionParticipantActions';
import {
    NotificationManager,
  } from 'react-notifications';
  import { confirmDialog } from '../Reusable/ConfirmDialog';
import ViewAttachedPdfDocumentWithHighlights from "../Reusable/ViewAttachedPdfDocumentWithHighlights";
import { getAllFiledsOfInterestForFilterList } from '../../actions/fieldOfInterestActions';
import 'dayjs/locale/sr-cyrl';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import CommentIcon from '@mui/icons-material/Comment';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import { setTopicOfDiscussionTableScrollYPosition } from '../../actions/topicOfDiscussionActions'

const TopicOfDiscussionTableServerFiltering = (props) => {

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [refresh, setRefresh] = useState(0);
    //table state
    const [columnFilters, setColumnFilters] = useState(props.topicOfDiscussionTableFilter?.filters || [{ id: 'endProcess', value: false}]);//(props.authRole==3 ? [{ id: 'endProcess', value: false},{id:"isJLS", value: false}] :
    if(props.authRole==3) {
      let index = columnFilters.findIndex(x=>x.id=='isJLS');
      if(index<0) {
        let filters = [...columnFilters];
        filters.push({id:"isJLS", value: false});
        setColumnFilters(filters);
      }
    }
    else {
      let index = columnFilters.findIndex(x=>x.id=='isJLS');
      if(index>=0) {
        let filters = [...columnFilters];
        filters.splice(index,1);
        setColumnFilters(filters);
      }
    }
    const [globalFilter, setGlobalFilter] = useState(props.topicOfDiscussionTableFilter?.globalFilter || '');
    const [sorting, setSorting] = useState(props.topicOfDiscussionTableFilter?.sorting || [{ id: 'creationDate', desc: true}]);
    const [pagination, setPagination] = useState( {
      pageIndex: props.topicOfDiscussionTableFilter?.start || 0,
      pageSize: props.topicOfDiscussionTableFilter?.size || 10,
    });
    const [searchParams, setSearchParams] = useState({});
    const [refreshColumns, setRefreshColumns] = useState(0);
    const [jLSFilter, setJLSFilter] = useState(false);

    const prijaviSeTrue = props.authRole!=2 && props.authRole!=4;
    const [columnVisibility, setColumnVisibility] = useState({
      nameOfTopic: true,
      prijaviSe: prijaviSeTrue,
      allowComments: true
     });

    const [showModalViewAttachedPdfDocumentWithHighlights, setShowModalViewAttachedPdfDocumentWithHighlights] = useState(false);
    const [pdfModalProps, setPdfModalProps] = useState({
      commentDocumentName: '',
      id: '',
      currentPhaseId:'',
      eventId:''
    });

    useEffect(() => {
        if (!props.topicOfDiscussionList?.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }
        var filters = [];
      columnFilters?.forEach(element => {
        switch(element.id) {
          case "creationDate":
            if(element.value[0] != undefined && element.value[0] != undefined && element.value[0] != "") {
              const offset = element.value[0].getTimezoneOffset();
              const dateFrom = new Date(element.value[0].getTime() - (offset*60*1000));
              filters.push( {id: "creationDateFrom", value: dateFrom});
            }
            if(element.value[1] != undefined && element.value[1] != undefined && element.value[1] != "") {
              const offset = element.value[1].getTimezoneOffset();
              const dateTo = new Date(element.value[1].getTime() - (offset*60*1000));
              filters.push( {id: "creationDateTo", value: dateTo});
            }
          break;
          case "phaseCreationDate":
            if(element.value[0] != undefined && element.value[0] != undefined && element.value[0] != "") {
              const offset = element.value[0].getTimezoneOffset();
              const dateFrom = new Date(element.value[0].getTime() - (offset*60*1000));
              filters.push( {id: "phaseCreationDateFrom", value: dateFrom});
            }
            if(element.value[1] != undefined && element.value[1] != undefined && element.value[1] != "") {
              const offset = element.value[1].getTimezoneOffset();
              const dateTo = new Date(element.value[1].getTime() - (offset*60*1000));
              filters.push( {id: "phaseCreationDateTo", value: dateTo});
            }
          break;
          case "fieldOfInterestName":
            let values=element.value?.join(";");
            filters.push({id: "fieldOfInterestName", value:values});
          break;
          default: filters.push(element);
        }
      });
        const searchParams = { 
          start: pagination.pageIndex,
          size: pagination.pageSize,
          filters: filters ?? [],
          globalFilter: globalFilter ?? '',
          sorting: sorting ?? []
        };
        const searchParamsToSet = { 
          start: pagination.pageIndex,
          size: pagination.pageSize,
          filters: filters ?? [],
          globalFilter: globalFilter ?? '',
          sorting: sorting ?? []
        };
        setSearchParams(searchParams);
        props.setTopicOfDiscussionTableFilter(searchParamsToSet)
        trackPromise(props.getTopicOfDiscussionsPost(searchParams))
        .catch(() => {
           setIsError(true);
           NotificationManager.error(props.dictionary["universal.NeuspesnoUcitavanjePodataka"]);
          })
        .then( () => {
          setIsError(false);
        })
        .finally(() => {
          setIsLoading(false);
          setIsRefetching(false);
        }
      );
    }, [
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      refresh
    ]);
    useEffect(()=>{
      //let jlsFilterValue = columnFilters.filter(x=>x.id='isJLS')[0]?.value??false;
      trackPromise(props.getAllFiledsOfInterestForFilterList(jLSFilter)).then(()=>{setRefreshColumns(Math.random())});
    },[
      jLSFilter
    ]);
    useEffect(()=>{
      if(props.scrollYPosition != undefined) {
        window.scrollTo(0, props.scrollYPosition);
        props.setTopicOfDiscussionTableScrollYPosition(undefined);
      }
    },[props.scrollYPosition]);

    const cssLiStyle = 'topicTitleText textShadow mb-2 ';

    const columns = useMemo(
        () => [
          {
            id: 'nameOfTopic',
            header: props.dictionary["topicOfDiscussionTable.nameOfTopic"],
            size:200,
            enableHiding: true,
            accessorFn: (row) => row.nameOfTopic,
            Cell: ({row}) => (<Link
              to={`/topicOfDiscussionPage/${row.original.id}/${row.original.currentPhaseId}`}
              className="remove-link-effect"
              onClick={()=>{props.setTopicOfDiscussionTableScrollYPosition(window.scrollY);}}
            >
              <div>
                <li className={cssLiStyle}>{row.original.nameOfTopic}</li>
              </div>
            </Link>)
          },
          {
            accessorKey: 'institutionName',
            enableHiding: true,
            header: props.dictionary["topicOfDiscussionTable.institutionName"],
            size:110
          },
          {
            accessorKey: 'currentPhaseNameTableView',
            enableHiding: true,
            header: props.dictionary["topicOfDiscussionTable.currentPhaseName"],
            size:120
          },
          {
            id : 'phaseCreationDate',
            enableHiding: true,
            Cell : ({ cell }) => `${cell.getValue() ? cell.getValue()?.toLocaleDateString('sr-RS') : ''}`,
            accessorFn: (row) => row.phaseCreationDate ? new Date(row.phaseCreationDate) : null,
            //filterFn: 'between',
            filterVariant: 'date-range',
            mantineFilterDateInputProps: {
              locale: 'sr-cyrl'
            },
            header: props.dictionary["topicOfDiscussionTable.phaseCreationDate"],
            size: 80,
            mantineTableHeadCellProps: {
              className:'dateColumn'
            },
          },
          {
            accessorKey: 'allowComments',
            enableHiding: false,
            header: props.dictionary["topicOfDiscussionTable.allowComments"],
            size:40,
            columnDefType: 'display',
            enableColumnFilter: true,
            enableColumnOrdering: true,
            Cell: ({ row }) => (
              row.original.allowComments == 'Отворено' ? 
              <div >
                <CommentIcon className="me-2 colorGreen"/>
                <span>{row.original.allowComments}</span>
              </div>
                :
              <div>
                <CommentsDisabledIcon className="me-2 colorRed"/>
                <span>{row.original.allowComments}</span>
              </div>
          )
          },
          {
            id: 'prijaviSe',
            enableHiding: false,
            header: props.dictionary["topicOfDiscussionTable.subscribe"],
            //enableHiding: true,
            size:100,
            columnDefType: 'display',
            enableColumnOrdering: true,
            show : (row) => props.authRole!=2 && row.endProcess==0,
            accessorFn: (row) => row.id,
            mantineTableHeadCellProps: {
              align: 'center',
              className:'editColumn'
            },
            mantineTableBodyCellProps: {
              align: 'center',
            },
            Cell: ({ row }) => (
            row.original.participants.includes(props.userId.toString()) ?
            <Link
              onClick={() => onUnsubscribeClick(row.original.id)}
              className="unsubscribe-button small-font"
              to="#"
            >
            {props.dictionary["topicOfDiscussionTable.unsubscribe"]}
            </Link> :                  
            <Link
              onClick={() => onSubscribeClick(row.original.id)}
              className="create-button small-font subscribe-button-grid"
              to="#"
            >
            {props.dictionary["topicOfDiscussionTable.subscribe"]}
            </Link>) 
          },
          {
            accessorKey: 'documentType',
            enableHiding: true,
            header: props.dictionary["topicOfDiscussionTable.documentType"],
            size:80
          },
          // {
          //   accessorKey: 'fieldOfInterestName',
          //   enableHiding: true,
          //   header: props.dictionary["topicOfDiscussionTable.fieldOfInterestName"],
          //   size:200,
          // },
          {
            accessorKey: 'fieldOfInterestName',
            header: props.dictionary["topicOfDiscussionTable.fieldOfInterestName"],
            size:300,
            filterVariant:'multi-select',
            mantineFilterMultiSelectProps: {
              searchable:true,
              data: props.fieldOfInterestForFilter != null ? props.fieldOfInterestForFilter : [],
              //comboboxProps: {width:300},
            },
          },
          {
            id : 'creationDate',
            enableHiding: true,
            Cell : ({ cell }) => `${cell.getValue() ? cell.getValue()?.toLocaleDateString('sr-RS') : ''}`,
            accessorFn: (row) => row.creationDate ? new Date(row.creationDate) : null,
            //filterFn: 'between',
            filterVariant: 'date-range',
            mantineFilterDateInputProps: {
              locale: 'sr-cyrl'
            },
            header: props.dictionary["topicOfDiscussionTable.creationDate"],
            size: 120,
            mantineTableHeadCellProps: {
              className:'dateColumn'
            },
          },
        ],
        [isLoading, refreshColumns]
      );
      


      const columnsAdmin = useMemo(
        () => [
          {
            id: 'nameOfTopic',
            header: props.dictionary["topicOfDiscussionTable.nameOfTopic"],
            size:250,
            accessorFn: (row) => row.nameOfTopic,
            Cell: ({row}) => (<Link
              to={`/topicOfDiscussionPage/${row.original.id}/${row.original.currentPhaseId}`}
              className="remove-link-effect"
              onClick={()=>{props.setTopicOfDiscussionTableScrollYPosition(window.scrollY);}}
            >
              <div>
                <li className={cssLiStyle}>{row.original.nameOfTopic}</li>
              </div>
            </Link>)
          },
          {
            accessorKey: 'institutionName',
            header: props.dictionary["topicOfDiscussionTable.institutionName"],
            size:110
          },
          {
            accessorKey: 'currentPhaseNameTableView',
            header: props.dictionary["topicOfDiscussionTable.currentPhaseName"],
            size:120
          },
          {
            id : 'phaseCreationDate',
            Cell : ({ cell }) => `${cell.getValue() ? cell.getValue()?.toLocaleDateString('sr-RS') : ''}`,
            accessorFn: (row) => row.phaseCreationDate ? new Date(row.phaseCreationDate) : null,
            //filterFn: 'between',
            filterVariant: 'date-range',
            mantineFilterDateInputProps: {
              locale: 'sr-cyrl'
            },
            header: props.dictionary["topicOfDiscussionTable.phaseCreationDate"],
            size: 80,
            mantineTableHeadCellProps: {
              className:'dateColumn'
            },
          },
          // {
          //   id: 'hidden',
          //   header: props.dictionary["topicOfDiscussionTable.hidden"],
          //   //enableHiding: true,
          //   size:100,
          //   //enableColumnOrdering: true,
          //   Cell : ({  row }) =>  {
          //     return row.original.hidden==false ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />
          //   },
          //   accessorFn: (row) => row.hidden,
          //   filterVariant:'select',
          //   mantineFilterSelectProps: {
          //     searchable:false,
          //     data: [{value:'false', label:"" }, {value:'true', label:"" }],
          //     styles: {
          //       input: {
          //         'fontFamily': "'Font Awesome 5 Free',-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji", 
          //       },
          //       item: {
          //         'font-family': "'Font Awesome 5 Free'",
          //       }
          //     }
          //   },
          // },
          {
            accessorKey: 'documentType',
            header: props.dictionary["topicOfDiscussionTable.documentType"],
            size:80
          },
          // {
          //   accessorKey: 'fieldOfInterestName',
          //   header: props.dictionary["topicOfDiscussionTable.fieldOfInterestName"],
          //   size:200,
          // },
          {
            accessorKey: 'fieldOfInterestName',
            header: props.dictionary["topicOfDiscussionTable.fieldOfInterestName"],
            size:300,
            filterVariant:'multi-select',
            mantineFilterMultiSelectProps: {
              searchable:true,
              data: props.fieldOfInterestForFilter != null ? props.fieldOfInterestForFilter : [],
              //comboboxProps: {width:300},
            },
          },
          {
            id : 'creationDate',
            Cell : ({ cell }) => `${cell.getValue() ? cell.getValue()?.toLocaleDateString('sr-RS') : ''}`,
            accessorFn: (row) => row.creationDate ? new Date(row.creationDate) : null,
            //filterFn: 'between',
            filterVariant: 'date-range',
            mantineFilterDateInputProps: {
              locale: 'sr-cyrl'
            },
            header: props.dictionary["topicOfDiscussionTable.creationDate"],
            size: 80,
            mantineTableHeadCellProps: {
              className:'dateColumn'
            },
          },
          
        ],
        [refreshColumns],
      );

      const handleCheckChange = (e) => {
        let index = columnFilters.findIndex(x=>x.id=='endProcess');
        let filters = [...columnFilters];
        if (e.target.name === 'active') {
          if (columnFilters[index]?.value == true) {
            //setColumnFilters([{ id: 'endProcess', value: false }, ...columnFilters.slice(1)]);
            filters[index].value=false;
            setColumnFilters(filters);
            setColumnVisibility({
              prijaviSe: prijaviSeTrue,
              allowComments: true
             });
          } else {
          }
        } else {
          if (columnFilters[index].value == false) {
            //setColumnFilters([{ id: 'endProcess', value: true }, ...columnFilters.slice(1)]);
            filters[index].value=true;
            setColumnFilters(filters);
            setColumnVisibility({
              prijaviSe: false,
              allowComments: false
             });
          } else {
          }
          }
          setPagination({pageIndex: 0,
            pageSize: props.topicOfDiscussionTableFilter?.size || 10});
      }
      const handleLevelCheckChange = (e) => {
          let index = columnFilters.findIndex(x=>x.id=='isJLS');
          let filters = [...columnFilters];
          if (e.target.name === 'rs') {

            if (columnFilters[index]?.value == true) {
              //setColumnFilters([columnFilters[0], { id: 'isJLS', value: false }, ...columnFilters.slice(2)]);
              filters[index].value=false;
              setColumnFilters(filters);
              setJLSFilter(false);
          }
         } else {
            if (columnFilters[index]?.value == false) {
              //setColumnFilters([columnFilters[0],{ id: 'isJLS', value: true }, ...columnFilters.slice(2)]);
              filters[index].value=true;
              setColumnFilters(filters);
              setJLSFilter(true);
            } else {
            }
            }
            setPagination({pageIndex: 0,
              pageSize: props.topicOfDiscussionTableFilter?.size || 10});
    }

        const onSubscribeClick = (topicOfDiscussionId) => {
          const newTopicOfDiscussionParticipant = {
            topicOfDiscussion: {
              id: topicOfDiscussionId,
            },
          };
          //this.setState({ subscribed: true });
          props
            .createTopicOfDiscussionParticipant(newTopicOfDiscussionParticipant)
            .then(()=> {
              console.log("then");
              setRefresh(Math.random());
            }
              );
        };
      
        const onUnsubscribeClick = (topicOfDiscussionId) => {
          const action = () => {
            //this.setState({ subscribed: false });
            props
              .unsubscribeFromTopicOfDiscussion(topicOfDiscussionId)
              .then(setRefresh(Math.random()));
          };
          confirmDialog(props.dictionary["modalConfirm.OdjavaSaPredloga"], action, '');
        };

  const customTable = "custom-table";
  const customPaper = "custom-paper";
  let isYesCheckedStyle =
    columnFilters[0].value == false ? "labelText weight500" : "labelText";
  let isNoCheckedStyle =
    columnFilters[0].value == true ? "labelText weight500" : "labelText";
  let isLevelYesCheckedStyle =
    columnFilters[1]?.value == false ? "labelText labelNoWordBreak weight500" : "labelText labelNoWordBreak";
  let isLevelNoCheckedStyle =
    columnFilters[1]?.value == true ? "labelText labelNoWordBreak weight500" : "labelText labelNoWordBreak";
  return (
    <>
      {props.authRole==3 && (
        <div className="radioButtonForm">
        <div>
          <label className={isLevelYesCheckedStyle}>
            {props.dictionary["dashboard.commentTable.radioRS"]}
          </label>
          <input
            type="radio"
            id="level"
            name="rs"
            className="filled-in"
            // style={{ cursor: 'pointer', border: 0, height: '1.1em', verticalAlign: 'sub' }}
            checked={!columnFilters.filter(x=>x.id=='isJLS')[0]?.value}
            onChange={handleLevelCheckChange}
          />
        </div>
        <div>
          <input
            type="radio"
            id="level"
            name="jls"
            className="filled-in"
            // style={{ cursor: 'pointer', border: 0, height: '1.1em', verticalAlign: 'sub' }}
            checked={columnFilters.filter(x=>x.id=='isJLS')[0]?.value}
            onChange={handleLevelCheckChange}
          />
          <label className={isLevelNoCheckedStyle}>
            {props.dictionary["dashboard.commentTable.radioJLS"]}
          </label>
        </div>
      </div>
      )}
      <div className="radioButtonForm">
        <div>
          <label className={isYesCheckedStyle}>
            {props.dictionary["dashboard.commentTable.radioAktivni"]}
          </label>
          <input
            type="radio"
            id="activity"
            name="active"
            className="filled-in"
            // style={{ cursor: 'pointer', border: 0, height: '1.1em', verticalAlign: 'sub' }}
            checked={!columnFilters.filter(x=>x.id=='endProcess')[0]?.value}
            onChange={handleCheckChange}
          />
        </div>
        <div>
          <input
            type="radio"
            id="activity"
            name="archive"
            className="filled-in"
            // style={{ cursor: 'pointer', border: 0, height: '1.1em', verticalAlign: 'sub' }}
            checked={columnFilters.filter(x=>x.id=='endProcess')[0]?.value}
            onChange={handleCheckChange}
          />
          <label className={isNoCheckedStyle}>
            {props.dictionary["dashboard.commentTable.radioArhiva"]}
          </label>
        </div>
      </div>

      <MantineReactTable
        localization={MRT_Localization_SR_CYRL_RS}
        enableGlobalFilter = {false}
        enableHiding = {true}
        onColumnVisibilityChange={setColumnVisibility}
        initialState={{
          showColumnFilters: true,
          sorting: [{ id: 'creationDate', desc: true }],
        }}
        columns={props.authRole == 1 ? columnsAdmin : columns}
        data={props.topicOfDiscussionList}
        displayColumnDefOptions= {{ 'mrt-row-numbers': { size:10,align:'center' } }}
        mantineTableProps={{
          className: {customTable},
          striped:true,
          tablelayout: 'fixed',
        }}
        mantineProgressProps={{
          striped:false,
          animate:true,
          size:'xs'
        }}
        mantinePaperProps={{
          className:{customPaper}
        }}
        //enableRowNumbers
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error loading data',
              }
            : undefined
        }
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={props.rowCount}
        state={{
          columnFilters,
          globalFilter,
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,
          sorting,
          columnVisibility: columnVisibility
        }}
      />
      </>
    );
};
const mapStateToProps = (state) => {
    return {
    dictionary:state.startUp.dictionary,
    authRole: state.security.authRole,
    userId: state.security.userId,
    topicOfDiscussionList: state?.topicOfDiscussion?.topicOfDiscussionListFilterable?.content ?? [],
    rowCount: state?.topicOfDiscussion?.topicOfDiscussionListFilterable?.totalElements ?? 0,
    topicOfDiscussionTableFilter: state?.topicOfDiscussion?.topicOfDiscussionTableFilter,
    fieldOfInterestForFilter: state.fieldOfInterest.fieldOfInterestForFilter?.map(x=>x.name),
    scrollYPosition: state.topicOfDiscussion.topicOfDiscussionTableScrollYPosition
  } };
  
  export default connect(mapStateToProps, {
    getTopicOfDiscussionsPost,
    createTopicOfDiscussionParticipant,
    unsubscribeFromTopicOfDiscussion,
    setTopicOfDiscussionTableFilter,
    getAllFiledsOfInterestForFilterList,
    setTopicOfDiscussionTableScrollYPosition
  })(TopicOfDiscussionTableServerFiltering);