import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import UploadDocumentInitialisedFiles from "../Reusable/UploadDocumentInitialisedFiles";
import { createMethodParticipant } from "../../actions/methodParticipantActions";
import { NotificationManager } from "react-notifications";
import { getMethodList } from "../../actions/methodActions";
import { setMessage } from "../../utils";


const ModalForExternalMemberApplication = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary);
    const user = useSelector(state => state.security);

    const [organization, setOrganization] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const dispatch = useDispatch();

    const handleCheckboxChange = (e) => {
        setOrganization(e.target.checked);
    };

    const handleValidation = () => {
        let error = false;

        if (!uploadedFiles || uploadedFiles.length > 1 || uploadedFiles.length < 1) {
            dispatch(setMessage(
                dictionary["ModalForExternalMemberApplication.Validation.SamoJedanFajl"],
                '#fileChooser',
                '#uploadMsg'
            ));
            error = true;
        }
        return error;

    }

    const handleSubmit = () => {
        const error = handleValidation();
        if (error) return;
        const methodParticipant = {
            method: {
                id: props.method.id
            },
            user: {
                id: user.userId
            },
            organization: organization,
            document: null,
            userOrganization: null
        }

        let formData = new FormData();
        for (let i = 0; i < uploadedFiles.length; i++) {
          formData.append('file', uploadedFiles[i]);
          formData.append('name', uploadedFiles[i].name);
        }

        formData.append(
          'methodparticipant',
          new Blob([JSON.stringify(methodParticipant)], {
            type: 'application/json',
          })
        );


        dispatch(createMethodParticipant(formData))      
        .then( () => {
            NotificationManager.success(dictionary["ModalForExternalMemberApplication.UspesnaPrijava"],dictionary["universal.Uspesno"], 5000);
            dispatch(getMethodList(props.topicOfDiscussionId, props.phase.id))
            handleClose();
          })
          .catch( () => { 
            NotificationManager.error(dictionary["ModalForExternalMemberApplication.PrijavaNeuspela"], dictionary["universal.GreskaUObradi"], 5000);
          });
        }
 
    const handleClose = () => {
        props.handleClose();
    }
    return (
        <Modal
        show={props.show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
            <Modal.Header closeButton className="d-flex flex-column">
            <h3 className="text-center textShadow m-auto order-2">
                    {dictionary["ModalForExternalMemberApplication.Prijava"]}
            </h3>
            </Modal.Header> 
            <Modal.Body className="modal-body-scroll">
                <div className="form-group mb-4 mt-2">
                    <label className="labelText textShadow text-uppercase">{props.method.methodTypeDisplay}</label>
                </div>
                <div className="form-group mb-4 mt-2">
                    <label className="labelText textShadow">{props.method.title}</label>
                </div>
                <div className="form-group mb-4 mt-2">
                    <label className="labelText pe-2">{props.method.description}</label>
                </div>
                <div className="form-group mb-4 mt-2">
                    <label className="labelText textShadow">
                        <strong>{dictionary["ModalForExternalMemberApplication.NapomenaDokumenti"]} </strong> 
                        {dictionary["ModalForExternalMemberApplication.NapomenaDokumentiSadrzaj"]}
                    </label>
                </div>
                <div className="form-group mb-4 mt-1">
                    <UploadDocumentInitialisedFiles 
                        uploadedFiles={uploadedFiles}
                        onFileChange={(e) => setUploadedFiles(e.target.files)}
                    />
                    <div id="uploadMsg" />
                </div>
                <div className="mb-4">                        
                        <input
                        type="checkbox"
                        name="organisation"
                        className="mt-2 filled-in col-md-1"
                        checked={organization}
                        onChange={handleCheckboxChange}
                        />
                        <label className="labelText textShadow">{dictionary["ModalForExternalMemberApplication.Organizacija"]}</label>
                </div>
                <div className="mt-1 d-flex justify-content-around">
                    <button
                        type="button"
                        id="subscribeButton"
                        className="m-auto"
                        onClick={handleClose}
                    >
                        {dictionary["ModalForAddNewMethod.Odustani"]}
                    </button>
                    <button
                        type="button"
                        id="subscribeButton"
                        className="m-auto"
                        onClick={handleSubmit}
                    >
                        {dictionary["ModalForAddNewMethod.Sacuvaj"]}
                    </button>
                </div>   
            </Modal.Body>
      </Modal>)
}
export default ModalForExternalMemberApplication;