import React, { Component } from "react";
import { connect } from 'react-redux';
import MailSettingsRow from "./MailSettingsRow";
import authorizationService from "../../securityUtils/authorizationService";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";

class MailSettingsTable extends Component {
  render() {
    var mailSettingsList = this.props.mailSettingsList.map((mailSettings) => (
      <MailSettingsRow
        key={mailSettings.id}
        mailSettings={mailSettings}
      />
    ));


    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-sm table-striped mt-2">
          <Thead>
            <Tr className="table-header-height">
              <Th className="boldText emphesized-label-font">{this.props.dictionary["MailSettingsTable.header.mailAddress"]}</Th>
              <Th className="boldText emphesized-label-font">{this.props.dictionary["MailSettingsTable.header.host"]}</Th>
              <Th className="boldText emphesized-label-font">{this.props.dictionary["MailSettingsTable.header.port"]}</Th>
              {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
                <Th width="10%" className="text-center">
                  {this.props.dictionary["MailSettingsTable.header.edit"]}
                </Th>
              ) : (
                <Th></Th>
              )}
              {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
                <Th width="10%" className="text-center">
                  {this.props.dictionary["MailSettingsTable.header.delete"]}
                </Th>
              ) : (
                <Th></Th>
              )}
            </Tr>
          </Thead>
          <Tbody>{mailSettingsList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary
});

export default connect(mapStateToProps,{}) (MailSettingsTable);
