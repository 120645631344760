import {
  SET_LOGGED_USER,
  SET_USER_ROLES, 
  SET_ROLE_OF_LOGGED_USER,
  GET_USER_AFTER_LOGIN,
  LOG_OUT_USER,
  ACCEPT_COOKIES_POLICY,
} from "../actions/types";

const initialState = {
  authUser: {},
  userRoles: [],
  authRole: 0,
  userAfterLogin: {},
  userId: "",
  alowedAnalytics:false,
  lastCookiesPolicyAccept:"01/01/2000",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LOGGED_USER:
      const user = action.payload;
      if(user==null || user.profile == null) return state;
      const citizen = {
        email: user.profile['http://schema.id.rs/claims/mail'],
        firstName: user.profile['http://schema.id.rs/claims/givenname'],
        jmbg: user.profile['http://schema.id.rs/claims/umcn'],
        lastName: user.profile['http://schema.id.rs/claims/familyname'],
        amr:user.amr,
        aal:user.profile['http://schema.id.rs/claims/aal'],
        city:user.profile['http://schema.id.rs/claims/city'],
        country:user.profile['http://schema.id.rs/claims/country'],
        ial:user.profile['http://schema.id.rs/claims/ial'],
        streetAddress:user.profile['http://schema.id.rs/claims/streetaddress'],
        sub:user.profile.sub,
      };
      return {
        ...state,
        authUser: citizen,
      };
    case LOG_OUT_USER:
      return {
        ...state,
        authUser: {}, 
        userRoles: [],
        authRole: 0,
        userAfterLogin: {},
        userId: 0,
        lastCookiesPolicyAccept:"01/01/2000",
      };
      case SET_USER_ROLES:
        const authRole= action.payload.filter((r) => r.activeRole==true).length===0 ? action.payload[0].role.id : action.payload.filter((r) => r.activeRole==true)[0].role.id;
        const userId=action.payload.filter((r) => r.role.id == authRole)[0].id;
        const lastCookiesPolicyAccept=action.payload[0].acceptanceOfCookiesPolicy==null ? "01/01/2000" : action.payload[0].acceptanceOfCookiesPolicy;
        return {
          ...state,
          userRoles: action.payload,
          authRole: authRole,
          userId: userId,
          cookiesPolicyAcceptedAt: lastCookiesPolicyAccept,
          alowedAnalytics:window.localStorage.getItem("alowedAnalytics")=="true" ? true : false,
      };
      case ACCEPT_COOKIES_POLICY:
        return {
          ...state,
          cookiesPolicyAcceptedAt: action.payload.acceptanceDate,
          alowedAnalytics: action.payload.alowedAnalytics,
      };
      case GET_USER_AFTER_LOGIN:
        return {
          ...state,
        userAfterLogin: action.payload,
        };
      case SET_ROLE_OF_LOGGED_USER:
        const role = action.payload.userRole;
        const userIdRole = action.payload.activeUser.id;
        const institutionId=action.payload.activeUser.institution?.id;
        const institutionName=action.payload.activeUser.institution?.institutionName;
        const rsSuperAdmin = action.payload.activeUser.institution?.superAdmin;
        const institutionCurrentlyActive = action.payload.activeUser.institution?.currentlyActive;
        const jls=action.payload.activeUser.jls;
        const jlsSuperAdmin = action.payload.activeUser.jlssuperAdmin;
        const jlsSmallAdmin = action.payload.activeUser.jlssmallAdmin;
        return {  
          ...state,
          authRole: role,
          userId: userIdRole,
          institutionId:institutionId,
          institutionName:institutionName,
          institutionCurrentlyActive:institutionCurrentlyActive,
          jls:jls,
          jlsSuperAdmin:jlsSuperAdmin,
          jlsSmallAdmin:jlsSmallAdmin,
          rsSuperAdmin:rsSuperAdmin
        };
  default:
      return state;
  }
}
