import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteAdDocument } from '../../actions/adDocumentActions';
import DeleteButton from '../Reusable/DeleteButton';
import authorizationService from '../../securityUtils/authorizationService';
import { Tr, Td } from 'react-super-responsive-table';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Link } from 'react-router-dom';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
import GetAppIcon from '@mui/icons-material/GetApp';
import { downloadOGPDocument } from '../../actions/ogpDocumentActions';

class AdDocumentRow extends Component {
  constructor() {
    super();
    this.state = {
      showAnnouncementDocumentPreview: false,
    };
  }

  showAnnouncementDocumentPreview = () => {
    this.setState({ showAnnouncementDocumentPreview: true });
  };
  handlePreviewClose = () => {
    this.setState({ showAnnouncementDocumentPreview: false });
  };

  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteAdDocument(id);
    };
    confirmDialog(`${this.props.dictionary["AdDocumentRow.Obrisi.Poruka"]}`, action, `${this.props.dictionary["AdDocumentRow.Obrisi"]}`);
  };

  onDownloadClick = (uuidDocName, documentName) => {
    const action = () => {
      this.props.downloadOGPDocument(uuidDocName, documentName);
    };
    confirmDialog( `${this.props.dictionary["AdDocumentRow.Preuzmi.Poruka"]}`, action,  `${this.props.dictionary["AdDocumentRow.Preuzmi"]}`);
  };

  render() {
    const { adDocument } = this.props || {};
    const row = (
      <Tr>
        <Td id="topicUnorderedList" className="border-0">
          <div>
            <li className="default-label-text textShadow">
              {adDocument.documentName}
            </li>
          </div>
        </Td>
        <Td className="text-center border-0">
          <Link onClick={(e) => { e.preventDefault();this.showAnnouncementDocumentPreview();}} to="#">
            {this.props.dictionary["Document.Pogledaj"]}
          </Link>
        </Td>
        <Td className="text-center border-0">
          <Link
            onClick={(e) => {
              e.preventDefault();
              this.onDownloadClick(
                adDocument.uuidDocName,
                adDocument.documentName
              )
            }}
            to="#"
          >
            <GetAppIcon className="previewButton" />
          </Link>
        </Td>
        {authorizationService.canAccessOGPPageSpecificAreas(
          this.props.authRole
        ) && (
          <Td className="text-center border-0">
            <DeleteButton click={() => this.onDeleteClick(adDocument.id)} />
          </Td>
        )}
      </Tr>
    );
    return (
      <Fragment>
        {row}
        {this.state.showAnnouncementDocumentPreview && (
          <ModalForDocumentPreview
            show={this.state.showAnnouncementDocumentPreview}
            documentName={adDocument.uuidDocName}
            controllerReference={'announcementDocument'}
            handleClose={this.handlePreviewClose}
          />
        )}
      </Fragment>
    );
  }
}

AdDocumentRow.propTypes = {
  deleteAdDocument: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  deleteAdDocument,
  downloadOGPDocument,
})(AdDocumentRow);
