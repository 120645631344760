import React from "react";
import { Link } from "react-router-dom";

const UploadDocumentsButton = (props) => {
  return (
    <React.Fragment>
      <div>
      {props.topicOfDiscussionId ? (
        <Link to={`/${props.phaseRoute}/${props.topicOfDiscussionId}/${props.id}`}>
      <i className="fas fa-cloud-upload-alt fa-3x fa-pull-right uploadButton"></i>
    </Link>
    ) : props.id ? ( 
      <Link to={`/${props.phaseRoute}/${props.id}`}>
    <i className="fas fa-cloud-upload-alt fa-3x fa-pull-right uploadButton"></i>
  </Link>
  ) :  <Link to={`/${props.phaseRoute}`}>
         <i className="fas fa-cloud-upload-alt fa-3x fa-pull-right uploadButton"></i>
        </Link>}
      </div>
    </React.Fragment>
  );
};

export default UploadDocumentsButton;
