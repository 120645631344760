import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import CommentGathering from './CommentGathering';
import Event from './Event';
import GeneralPost from './GeneralPost';
import { updateMethod } from '../../actions/methodActions';
import { NotificationManager } from 'react-notifications';
import ExternalMembers from '../ExternalMembers/ExternalMembers';
import { findMethodById } from '../../actions/methodActions';
import { checkPdfFormat, checkPdfStructure, setMessage } from '../../utils';
import { getDocument } from '../../actions/viewAttachmentAction';
import store from '../../store';
import { trackPromise } from 'react-promise-tracker';
import { getTopicOfDiscussion } from '../../actions/topicOfDiscussionActions';
import { getTopicOfDiscussionDocuments } from '../../actions/topicOfDiscussionActions';


const ModalForUpdateMethod = (props) => {
    
    
    const dictionary = useSelector(state => state.startUp.dictionary);
    const methodTypes = useSelector(state => state.startUp.taxonomies.MethodType);
    const documentTypes = useSelector(state => state.startUp.taxonomies.DocumentType);
    const dispatch = useDispatch();

    const methodDocuments = useSelector(state => state.method.method.documents);

    useEffect(() => {
      dispatch(findMethodById(props.method.id));;
    }, [])


    const [ selectedMethodType, setSelectedMethodType ] = useState(props.method.methodType); 
    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ linkPath, setLinkPath ] = useState('');
    const [ uploadedFiles, setUploadedFiles ] = useState([]);
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const [ location, setLocation ] = useState('');
    const [ documents, setDocuments] = useState([]);
    const [ hasArticles, setHasArticles] = useState(false);
    const [ isSubmiting, setIsSubmiting ] = useState(false);

    useEffect(() => {
        setSelectedMethodType(props.method.methodType);
        setTitle(props.method.title);
        setDescription(props.method.description);
        setLinkPath(props.method.linkPath);
        setUploadedFiles([]);
        setStartDate(props.method.startDate);
        setEndDate(props.method.endDate);
        setLocation(props.method.location);
        setHasArticles(props.method.hasArticles);
    }, [props.method]);

    const b64ToBlob = (b64) => {
        const byteCharacters = atob(b64);
      
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
      
        const byteArray = new Uint8Array(byteNumbers);
      
        const blob = new Blob([byteArray]);
        return blob;
       }
    const handleValidation = async () => {
        let error = false;
    
        if (selectedMethodType === "PrikupljanjeKomentara") {
            if (documents?.length == 1) {
                await dispatch(getDocument(documents[0].uuid + documents[0].mimeTypeShort,  'document'));
                const documentContent = store.getState().viewAttachment.dokument.documentContent;
                
                const pdfStructure = await checkPdfStructure(
                    [b64ToBlob(documentContent)],
                   "#fileChooser",
                   "#uploadMsg"
                 );
                 if (pdfStructure === false) {
                   setDocuments([]);
                   error = true;
                 }
            }

            if (uploadedFiles.length > 0) {const validPdf = !checkPdfFormat(uploadedFiles,
                "#fileChooser",
                "#uploadMsg");
            if (validPdf) {
            const pdfStructure = await checkPdfStructure(
                 uploadedFiles,
                "#fileChooser",
                "#uploadMsg"
              );
              if (pdfStructure === false) {
                setUploadedFiles([]);
                error = true;
              }
            } 
            else {
                setUploadedFiles([]);
                error = true}}
            if (title === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Title"],
                    '#title',
                    '#titleМsg'
                ));
                error = true;
            }
    
            if (startDate === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.StartDate"],
                    '#startDate',
                    '#startDateМsg'
                ));
                error = true;
            }
    
            if (endDate === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.EndDate"],
                    '#endDate',
                    '#endDateMsg'
                ));
                error = true;
            }
    
            if (description === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Description"],
                    '#description',
                    '#descriptionMsg'
                ));
                error = true;
            }
            if ((uploadedFiles.length + documents.length + methodDocuments.length) != 1) {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.OneDocumentOnly"],
                    '#fileChooser',
                    '#uploadMsg'
                ));
                error = true;
            }
        }

        if (selectedMethodType == "Anketa") {
            if (title === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Title"],
                    '#title',
                    '#titleМsg'
                ));
                error = true;
            }
        }

        if (selectedMethodType == "Obavestenje") {
            if (title === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Title"],
                    '#title',
                    '#titleМsg'
                ));
                error = true;
            }
            if (description === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Description"],
                    '#description',
                    '#descriptionMsg'
                ));
                error = true;
            }
        }

        if ((selectedMethodType == "OkrugliSto" || 
        selectedMethodType == "PanelDiskusija" || 
        selectedMethodType == "FokusGrupa" || 
        selectedMethodType == "OstaliDogadjaji")) {
           
            if (title === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Title"],
                    '#title',
                    '#titleМsg'
                ));
                error = true;
            }
            
            if (startDate === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.StartDate"],
                    '#startDate',
                    '#startDateМsg'
                ));
                error = true;
            }
            if (location === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Location"],
                    '#location',
                    '#locationMsg'
                ));
                error = true;
            }
        }

        if (selectedMethodType == "JavniPozivOCD") {
            if (title === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Title"],
                    '#title',
                    '#titleМsg'
                ));
                error = true;
            }
            if (startDate === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.StartDate"],
                    '#startDate',
                    '#startDateМsg'
                ));
                error = true;
            }
    
            if (endDate === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.EndDate"],
                    '#endDate',
                    '#endDateMsg'
                ));
                error = true;
            }
            if (description === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Description"],
                    '#description',
                    '#descriptionMsg'
                ));
                error = true;
            }
            if ((uploadedFiles.length + documents.length + methodDocuments.length) != 1) {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.OneDocumentOnly"],
                    '#fileChooser',
                    '#uploadMsg'
                ));
                error = true;
            }
        }

        if (uploadedFiles.length > 0) {
            const filesArray = Array.from(uploadedFiles);
            let totalSize = 0;
            filesArray.forEach(element => {
                totalSize = totalSize + element.size;
            });
            if (totalSize >= 10000000) {
                dispatch(setMessage(
                    `${dictionary["ModalForAddNewMethod.Validation.DokumentiVeciOd10MB"]}`,
                    '#fileChooser',
                    '#uploadMsg' 
                ))
                error=true; 
            }
        }

        if ((startDate && endDate) && (new Date(startDate) > new Date(endDate))) { 

            dispatch(setMessage(
                dictionary["ModalForAddNewMethod.Validation.StartDateEndDate"],
                '#startDate',
                '#startDateМsg'
            ));
            error = true; 
            }
    
    
        return error;
    };
    
    const handleSubmit = async () => {
        const error = await handleValidation();
        if (!error) {
            if (!isSubmiting) {
                setIsSubmiting(true);
            let documentType = {}
            let existingDocuments = documents.map(document => ({ uuid: document.uuid }));
            if(selectedMethodType =="PrikupljanjeKomentara") {
                documentType = documentTypes.find(x => x.code == "PrilogZaKomentarisanje")
            }
            else if(selectedMethodType == "JavniPozivOCD") {
                documentType = documentTypes.find(x => x.code == "FormularPrijaveOCD")
            }
            else {
                documentType = documentTypes.find(x => x.code == "Prilog")
            }
        const method = {
            id: props.method.id,
            methodType: methodTypes.find(x => x.code == selectedMethodType),
            title: title,
            description: description,
            topicOfDiscussion: {
                id: props.method.topicOfDiscussionId
            },
            startDate: startDate,
            endDate: endDate,
            location: location,
            hasArticles: hasArticles,
            phase: {
                id: props.phase.id},
            linkPath: linkPath,
            pinDate: props.method.pinDate,
            creationDate: props.method.creationDate,
            documents: existingDocuments
        }

        let formData = new FormData();
        for (let i = 0; i < uploadedFiles.length; i++) {
          formData.append('file', uploadedFiles[i]);
          formData.append('name', uploadedFiles[i].name);
        }
        formData.append(
            'documenttype',
            new Blob([JSON.stringify(documentType)], {
              type: 'application/json',
            })
          );
    
        formData.append(
          'method',
          new Blob([JSON.stringify(method)], {
            type: 'application/json',
          })
        );

        dispatch(updateMethod(formData))
        .then( () => {
            NotificationManager.success(dictionary["ModalForUpdateMethod.UspesnaIzmena"],dictionary["universal.Uspesno"], 5000);
            props.handleMethodRefresh(props.phase.id);
            dispatch(getTopicOfDiscussion(props.topicOfDiscussionId));
            dispatch(getTopicOfDiscussionDocuments(props.topicOfDiscussionId));
          })
        .catch( () => { 
            NotificationManager.error(dictionary["ModalForUpdateMethod.IzmenaNeuspela"], dictionary["universal.GreskaUObradi"], 5000);
          }) 
        .finally(() => {
            setIsSubmiting(false);
            handleClose();
        })
        
        }
    }
    }


    const handleClose = () => {
        props.handleClose();
    }

    return (
        <Modal
        show={props.show}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="d-flex flex-column">
        <h3 className="text-center textShadow m-auto order-2">
                  {props.method?.methodTypeDisplay}
        </h3>
        </Modal.Header> 
        <Modal.Body className="modal-body-scroll">
            {selectedMethodType == "PrikupljanjeKomentara" && 
                <CommentGathering
                title={title}
                setTitle={setTitle}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                description={description}
                setDescription={setDescription}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                documents={documents}
                setDocuments={setDocuments}
                phase={props.phase}
                methodDocuments={methodDocuments}
                methodId={props.method.id}
                method={props.method}
                selectedMethodType={selectedMethodType}
                hasArticles={hasArticles}
                setHasArticles={setHasArticles}
                edit={true}
                />
            }
            {(selectedMethodType == "OkrugliSto" || 
              selectedMethodType == "PanelDiskusija" || 
              selectedMethodType == "FokusGrupa" || 
              selectedMethodType == "OstaliDogadjaji") &&
                                <Event
                                title={title}
                                setTitle={setTitle}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                location={location}
                                setLocation={setLocation}
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={setUploadedFiles}
                                selectedMethodType={selectedMethodType}
                                documents={documents}
                                setDocuments={setDocuments}
                                phase={props.phase}
                                methodDocuments={methodDocuments}
                                methodId={props.method.id}
                                />
            }
            { selectedMethodType == "Obavestenje" && 
                <GeneralPost
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    linkPath={linkPath}
                    setLinkPath={setLinkPath}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    documents={documents}
                    setDocuments={setDocuments}
                    phase={props.phase}
                    methodDocuments={methodDocuments}
                    methodId={props.method.id}
                />
            }
            { selectedMethodType == "JavniPozivOCD" && 
                <ExternalMembers
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    documents={documents}
                    setDocuments={setDocuments}
                    phase={props.phase}
                    methodDocuments={methodDocuments}
                    methodId={props.method.id}
                    readOnly={true}
                />
            }
            <div className="mt-1 d-flex justify-content-around">
                <button
                    type="button"
                    id="subscribeButton"
                    className="m-auto"
                    onClick={props.handleClose}
                >
                    {dictionary["ModalForAddNewMethod.Odustani"]}
                </button>
                <button
                    type="button"
                    id="subscribeButton"
                    className="m-auto"
                    onClick={handleSubmit}
                >
                    {dictionary["ModalForAddNewMethod.Sacuvaj"]}
                </button>
            </div>
        </Modal.Body> 
      </Modal>
    ); 
}

export default ModalForUpdateMethod;