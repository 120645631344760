import React from 'react';
import { Link } from 'react-router-dom';

const ActivateButton = (props) => {
  return (
    <div>
      <Link to='/userList' onClick={props.click}>
        <i className="fas fa-caret-right deleteButton"></i>
      </Link>
    </div>
  );
};

export default ActivateButton;
