import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OgpStepTable from "./OgpStepTable";

class OgpStepList extends Component {
  render() {
    const ogpId = this.props.ogpId;
    const ogpStepList = this.props.ogpStepList ? this.props.ogpStepList : [];

    return (
      <OgpStepTable
        ogpStepList={ogpStepList}
        ogpId={ogpId}
        ogpUpdate={this.props.ogpUpdate}
        ogpModerators={this.props.ogpModerators}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {})(OgpStepList);
