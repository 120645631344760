import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getOgpCommentList } from '../../actions/ogpCommentActions';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import OGPCommentTable from './OGPCommentTable';
import SearchFilter from '../Reusable/SearchFilter';
import LoadingIndicator from '../Reusable/LoadingIndicator';
import { trackPromise } from 'react-promise-tracker';
import Select from 'react-select';

class OGPCommentList extends Component {
  constructor() {
    super();
    this.state = {
      showRestOfTheButtonText: '',
      showSentMessageNotification: false,
      selectedDocument: '',
      numberOfUnhandledCommentsClick: false,
    };
  }

  componentDidMount() {
    trackPromise(this.props.getOgpCommentList());
  }

  handleCommentListSearch = (document) => {
    let documentName =
      document.value == 'Сви документи'
        ? document.value
        : document.value + '.pdf';
    this.setState({
      selectedDocument: documentName,
    });
  };

  handleNumberOfUnhandledCommentsClick = () => {
    if (this.state.numberOfUnhandledCommentsClick == true) {
      this.setState({
        numberOfUnhandledCommentsClick: false,
      });
    } else {
      this.setState({
        numberOfUnhandledCommentsClick: true,
      });
    }
  };

  render() {
    let isLoading = this.props.isLoading;
    let ogpCommentList = this.props.ogpComment.ogpCommentList;

    let numberOfUnhandledComments = ogpCommentList.filter((c) => !c.visibility)
      .length;

    let listOfDocuments = [];

    ogpCommentList.forEach((element) => {
      let documentName = element.documentTitle
        ? element.documentTitle.substring(
            0,
            element.documentTitle.indexOf('.pdf')
          )
        : '';
      if (
        documentName != '' &&
        listOfDocuments.filter((d) => d == documentName)[0] == null
      ) {
        listOfDocuments.push(documentName);
      }
    });
    listOfDocuments.push('Сви документи');

    const documentList = listOfDocuments.map((documentName) => ({
      value: documentName,
      label: documentName,
    }));

    let options = [];
    for (let i = 0; i < documentList.length; i++) {
      options.push(documentList[i]);
    }

    const customStyleForMultiselect = {
      control: (styles) => ({
        ...styles,
        minHeight: '45px',
      }),
      option: (styles) => ({
        ...styles,
        color: '#495057',
        fontSize: '17px',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
        marginLeft: '12px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
      }),
      input: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '1.25rem',
        marginLeft: '12px',
      }),
    };

    if (
      this.state.selectedDocument &&
      this.state.selectedDocument != 'Сви документи'
    ) {
      ogpCommentList = ogpCommentList.filter(
        (c) => c.documentTitle == this.state.selectedDocument
      );
    }

    if (this.state.numberOfUnhandledCommentsClick == true) {
      ogpCommentList = ogpCommentList.filter((c) => !c.visibility);
    }

    return (
      <div className="container-fluid">
        <br></br>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div className="row col-md-11 m-auto">
            <div className="col-md-12">
              <div className="text-left mb-2">
                <div className="form-row">
                  <h2 className="col-md-9 textShadow">{this.props.dictionary["OGPCommentList.Komentari"]}</h2>
                  <div className="form-group col-md-3 float-right">
                    <div onClick={this.handleNumberOfUnhandledCommentsClick}>
                      <Link className="remove-link-effect" to="#">
                        <em className="textShadow default-label-text-link">
                        {this.props.dictionary["OGPCommentList.BrojNeobradjenihKomentara"]}:&#8287;
                        </em>
                        <span className="textShadow bold-text">
                          {numberOfUnhandledComments}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <Select
                    name="documentList"
                    options={options}
                    onChange={this.onChange}
                    placeholder={this.props.dictionary["OGPCommentList.Placeholder.Pretraga"]}
                    id="documentListId"
                    styles={customStyleForMultiselect}
                  />
                </div>
                <SearchFilter noSearchColums={2} />
                <OGPCommentTable
                  ogpCommentList={ogpCommentList}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

OGPCommentList.propTypes = {
  ogpComment: PropTypes.object.isRequired,
  getOgpCommentList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ogpComment: state.ogpComment,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  getOgpCommentList,
})(OGPCommentList);
