import React, { Component } from 'react';
import { connect } from 'react-redux';
import authorizationService from '../../securityUtils/authorizationService';
import { headerDocumentTitles, manualOptions } from '../../constants';
import Select from 'react-select';
import { getDocument } from '../../actions/viewAttachmentAction';
import { trackPromise } from 'react-promise-tracker';
import { downloadDocument } from '../../utils';
import { saveManualOnDisk } from '../../actions/documentActions';
import { setMessage } from '../../utils';
import { NotificationManager } from 'react-notifications';
class ManualHandler extends Component {
    state = {
        selectedOption: null,
        uploadedDocument: null,
        documentName: null,
        inputLabelMsg: null,
        accept: null,
      };
    componentDidMount() {
        this.setState({inputLabelMsg:this.props.dictionary["uploadCommentDocument.Placeholder"]})
    }
    handleChange = selectedOption => {
        this.setState({ selectedOption }, () => {
            this.getFileName(this.state.selectedOption);
        });       
    };
    getFileName = selectedOption => {
        let documentName = "";
        let accept = "application/pdf";
        const userRole = selectedOption.value;
        switch (userRole) {
            case 0:
                documentName = headerDocumentTitles.ANONYMOUS_USER;
                break;
            case 1: 
                documentName = headerDocumentTitles.ROLE_ADMIN;
                break;
            case 2:
                documentName = headerDocumentTitles.ROLE_MODERATOR;
                break;
            case 3:
                documentName = headerDocumentTitles.ROLE_CITIZEN;
                break;
             case 4:
                documentName = headerDocumentTitles.ROLE_EVALUATOR;
                break;
            case 5: 
                documentName = headerDocumentTitles.ROLE_ADMIN_OGP;
                break;
            case 6:
                documentName = headerDocumentTitles.PRIVACY_POLICY;
                accept=".docx"
                break;
            default:
                documentName='NotAvailable';
        }
        this.setState({documentName:documentName, accept:accept});
    }
    downloadFile = (e) => {
        if(this.state.selectedOption == null){
            this.props.setMessage(
                this.props.dictionary["dictionary.Uputstvo.IzborUputstvaValidacija"],
                "#manualUpload",
                "#msgManual"
              );
              return;
        }
        trackPromise(this.props.getDocument(this.state.documentName, 'document').then(() => {
            if(this.props.viewAttachment.dokument.documentContent != null)
            downloadDocument(this.props.viewAttachment.dokument.documentContent, this.state.documentName);
            else
            this.props.setMessage(
                this.props.dictionary["dictionary.Uputstvo.DokumentNePostoji"],
                "#manualUpload",
                "#msgManual"
              );
        }   
        ));    
    }
    onFileChange = (e) => {
        if(e.target.files.length > 0) {
            this.setState({uploadedDocument: e.target.files[0], inputLabelMsg:e.target.files[0].name});
            e.target.value=null;
        }
    }
    uploadFiles = (e) => {
        if(this.state.selectedOption == null){
            this.props.setMessage(
                this.props.dictionary["dictionary.Uputstvo.IzborUputstvaValidacija"],
                "#manualUpload",
                "#msgManual"
              );
              return;
        }
        if(this.state.uploadedDocument == null) {
            this.props.setMessage(
                this.props.dictionary["dictionary.Uputstvo.UcitavanjeDokumentaValidacija"],
                "#manualUpload",
                "#msgManual"
              );
              return;
        }
        if(this.state.uploadedDocument.name.split('.').pop().toLowerCase()!=this.state.documentName.split('.').pop().toLowerCase()){
            this.props.setMessage(
                this.props.dictionary["dictionary.Uputstvo.PogresanFormatFajla"],
                "#manualUpload",
                "#msgManual"
              );
              return;
        }
        const formData = new FormData();
        formData.append("file",this.state.uploadedDocument);
        formData.append("documentName",this.state.documentName);
        trackPromise(this.props.saveManualOnDisk(formData)).then(() => {
            NotificationManager.success(this.props.dictionary["dictionary.Uputstvo.UspesnoAzuriranje"], this.props.dictionary["universal.Uspesno"], 5000);
            this.setState({selectedOption:null, uploadedDocument:null,inputLabelMsg:this.props.dictionary["uploadCommentDocument.Placeholder"]})
    }).catch(() => {
            NotificationManager.error(this.props.dictionary["dictionary.Uputstvo.NeuspesnoAzuriranje"], this.props.dictionary["universal.GreskaUObradi"], 5000);
    });
    }
    render(){
        const options = manualOptions;

        const{
            selectedOption
        } = this.state;
        const{
            sectionName,
        } = this.props || {};
        
        return <div className="d-flex flex-column align-items-center mt-5">
            <h5 className="textShadow">{sectionName}</h5>
            {authorizationService.canAccessAdminPanel(this.props.authRole) && ( 
            <div className='manualDiv'>
            <Select
                    name="roles"
                    isSearchable={false}
                    value={selectedOption}
                    options={options}
                    onChange={this.handleChange}
                    placeholder= {this.props.dictionary["dictionary.Uputstvo.IzaberiUlogu"]}
             />
             <button className="create-button mt-3" onClick={this.downloadFile}>{this.props.dictionary["dictionary.Preuzmi"]}</button>
             <label id="inputFileRemoveDefault" className="create-button m-2 fas fa-upload" >
                <input id="manualUpload" type="file" accept={this.state.accept} className="buttonCreateUpdate" onChange={(e)=>this.onFileChange(e)} />
             </label>
             <label className="fileName textShadow labelText m-3">{this.state.inputLabelMsg}</label>
             <button className="create-button float-right me-0" onClick={(e)=>this.uploadFiles(e)}>{this.props.dictionary["dictionary.Azuriraj"]}</button>
            </div>
            ) }
             <h6 id="msgManual" className="textShadow mt-1"></h6> 
            </div>
    }
}

const mapStateToProps = (state) => ({
    authRole: state.security.authRole,
    dictionary:state.startUp.dictionary,
    roleList: state.role.roleList,
    viewAttachment: state.viewAttachment,
});

export default connect(mapStateToProps,{getDocument, 
    saveManualOnDisk,
    setMessage}) (ManualHandler);