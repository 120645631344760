import React, {useState} from "react";
import { useSelector } from "react-redux";
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";
import MethodDocumentRow from "./MethodDocumentRow";


const  MethodDocumentTable = (props) => {
    
    const dictionary = useSelector(state => state.startUp.dictionary);
    const [showModalForDocumentPreview, setShowModalForDocumentPreview] = useState(false);
    const [uuidDocumentName, setUuidDocumentName] = useState(null);
    const handleShowModal = (uuidDocName) => {
      setShowModalForDocumentPreview(true);
      setUuidDocumentName(uuidDocName)
    }
    const handlePreviewClose = () => {
      setShowModalForDocumentPreview(false);
      setUuidDocumentName(null)
    }

    const tableRows = props.methodDocuments?.map((methodDocument) => (
        <MethodDocumentRow
            key={methodDocument.uuid}
            methodDocument={methodDocument}
            modalForPreviewSignal={props.modalForPreviewSignal}
            methodId={props.methodId}
            handleShowModal={handleShowModal}/>
    )
        
    )

    

    
    return (
        <>
        { tableRows?.length > 0 &&
            <div className="table-responsive">
            <Table id="example" className="table table-md border-0">
              <Thead>
                <Tr className="border-0">
                  <Th className="border-0 boldText emphesized-label-font">
                  {dictionary["GeneralPostAttachmentTable.Dokument"]}
                  </Th>
                  {!props.modalForPreviewSignal ? (
                    <Th
                      width="5%"
                      className="text-center border-0 boldText emphesized-label-font"
                    >
                      {dictionary["GeneralPostAttachmentTable.Brisanje"]}
                    </Th>
                  ) : (
                    <Th
                      width="5%"
                      className="border-0 boldText emphesized-label-font text-center"
                    >
                      {dictionary["GeneralPostAttachmentTable.Pregled"]}
                    </Th>
                  )}
                </Tr>
              </Thead>
              <Tbody>{tableRows}</Tbody>
            </Table>
          </div>
        }
        {showModalForDocumentPreview && (
          <ModalForDocumentPreview
            show={showModalForDocumentPreview}
            documentName={uuidDocumentName}
            controllerReference={'document'}
            handleClose={handlePreviewClose}
          />
        )}
        </>
      
    );
  }




export default MethodDocumentTable;
