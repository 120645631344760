import React, { Component } from 'react';
import { connect } from 'react-redux';

class ComboBoxCommentStatus extends Component {
  render() {
    let comboDisabledStyle =
      this.props.disabled == true
        ? 'disabled-select-field form-control form-control-mb'
        : 'select-field form-control form-control-mb';
    
    return (
      <select
        id="statusCb"
        name="status"
        className={comboDisabledStyle}
        onChange={this.props.onChange}
        value={this.props.status && this.props.status!='У обради' ? this.props.status : '-1'}
        disabled={this.props.disabled}
      >
        <option key="-1" value="-1" disabled hidden>
          {this.props.dictionary["ComboBoxCommentStatus.IzaberiteStatus"]}
        </option>
        <option key="1" value="Прихваћен">
        {this.props.dictionary["ComboBoxCommentStatus.Prihvacen"]}
        </option>
        <option key="2" value="Делимично прихваћен">
        {this.props.dictionary["ComboBoxCommentStatus.DelimicnoPrihvacen"]}
        </option>
        <option key="3" value="Није прихваћен">
        {this.props.dictionary["ComboBoxCommentStatus.NijePrihvacen"]}
        </option>
        <option key="4" value="Одбијен">
        {this.props.dictionary["ComboBoxCommentStatus.Odbijen"]}
        </option>
      </select>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {}
)(ComboBoxCommentStatus);
