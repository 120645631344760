import React, { useEffect, useState } from 'react';
import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  Popup,
  AreaHighlight,
} from 'react-pdf-highlighter';
import { userOrganizationComboPopup } from '../UserOrganization/UserOrganizationComboPopup';
import Sidebar from './Sidebar';
import LoadingIndicatorOverlay from '../Reusable/LoadingIndicatorOverlay';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import CustomTip   from './CustomTip';
import CommentOnDocumentTableServerFiltering from '../Comment/CommentOnDocumentTableServerFiltering';
import CommentDisplay from '../CommentDisplay/CommentDisplay';
const defaultUrl = '';
const noOp = () => {};

export const PdfPreviewer = ({
  comments = [],
  url = defaultUrl,
  onCommentAdded = noOp,
  onCommentUpdated = noOp,
  isUserLoggedIn = 'NO',
  commentObjectList = [],
  updateComment = noOp,
  createCommentUserVote = noOp,
  updateCommentUserVote = noOp,
  handleShowWarningMessage = noOp,
  userOrganizationList = [],
  handleUserOrganizationChange = noOp,
  phaseActiveStatus = 1,
  handleTextSegmentChange = noOp,
  handleArticleNumberChange = noOp,
  ogpPhase = noOp,
  isUserModerator = 'NO',
  canLeaveComments=true,
  setMessage=noOp,
  commentTextValidationMessage='',
  uuidDocumentName,
  methodId,
  methodActive
}) => {
  const dictionary = useSelector(state => state.startUp.dictionary);

  const parseIdFromHash = () =>
    document.location.hash.slice('#highlight-'.length);

  const resetHash = () => {
    document.location.hash = '';
  };

  let scrollViewerTo = (highlight) => {};

  const scrollToHighlightFromHash = () => {
    const highlight = getHighlightById(parseIdFromHash());

    if (highlight) {
      scrollViewerTo(highlight);
    }
  };

  const getHighlightById = (id) => {
    return comments.find((highlight) => highlight.id === id);
  };

  useEffect(() => {
    window.addEventListener('hashchange', scrollToHighlightFromHash);
  });

  const [textSegment, setTextSegment] = useState('');

  const onTextSegmentChange = (e) => {
    setTextSegment(e.target.value);
    return handleTextSegmentChange(e.target.value);
  };

  const [articleNumber, setArticleNumber] = useState('');
  const onArticleNumberChange = (e) => {
    setArticleNumber(e.target.value);
    return handleArticleNumberChange(e.target.value);
  };

  const addHighlight = (highlight,  hideTipAndSelection) => {
    if(highlight.comment.text.length > 3000) {
      setMessage(
        `${commentTextValidationMessage} - ${highlight.comment.text.length}/3000`,
        '#msgPdf',
        '#msgPdf'
      );
      return;
    }
    if (userOrganizationList && userOrganizationList.length != 0) {
      const action = () => {
        onCommentAdded(highlight);
      };
      userOrganizationComboPopup(
        userOrganizationList,
        action,
        handleUserOrganizationChange
      );
    } else {
      onCommentAdded(highlight);
    }
    hideTipAndSelection();
  };

  const updateHighlight = (highlightId, position, content) => {
    onCommentUpdated(highlightId, position, content);
  };

  const highlightTransform = (
    highlight,
    index,
    setTip,
    hideTip,
    viewportToScaled,
    screenshot,
    isScrolledTo
  ) => {
    const isTextHighlight = !Boolean(
      highlight.content && highlight.content.image
    );
    const component = isTextHighlight ? (
      <span
        onClick={() => {
          window.scrollTo({ top: 670, behavior: 'smooth' });
        }}
      >
        <Highlight
          isScrolledTo={isScrolledTo}
          position={highlight.position}
          comment={highlight.comment}
        />
      </span>
    ) : (
      <AreaHighlight
        highlight={highlight}
        onChange={(boundingRect) => {
          updateHighlight(
            highlight.id,
            { boundingRect: viewportToScaled(boundingRect) },
            { image: screenshot(boundingRect) }
          );
        }}
      />
    );

    return (
      <Popup
        popupContent={<HighlightPopup {...highlight} />}
        onMouseOver={(popupContent) =>
          setTip(highlight, (highlight) => popupContent)
        }
        onMouseOut={hideTip}
        key={index}
        children={component}
      />
    );
  };

  let infoAboutTextSegment = (
    <div className="form-group">
      <h6 className="regular-font">{dictionary["pdfPreviewer.Primeri"]}</h6>
      <p className="regular-font">{dictionary["pdfPreviewer.Primer1"]}</p>
      <p className="regular-font">{dictionary["pdfPreviewer.Primer2"]}</p>
    </div>
  );

  const onSelectionFinished = (
    position,
    content,
    hideTipAndSelection,
    transformSelection
  ) =>
    content.text.length > 300 ? (
      handleShowWarningMessage()
    ) : (
      <div className="container-fluid tip-wrapper">
        <button className='tip-close-button' onClick={hideTipAndSelection}>
          <i class="fas fa-times"></i>
        </button>
        {ogpPhase !== 'DA' && (
          <div className="mb-4 form-group">
            <label className="labelText textShadow">
            {dictionary["pdfPreviewer.SegmentDokumenta"]}{' '}
              <span className="redAsterisk"> *</span>
            </label>
            <a data-tooltip-id="currentStateTip">
              <input
                className="inputField form-control"
                type="text"
                id="textSegment"
                name="textSegment"
                placeholder={dictionary["pdfPreviewer.SegmentDokumentaPlaceholder"]}
                onChange={onTextSegmentChange}
              />
              <div id="textSegmentMsg" />
            </a>
            <Tooltip
              id="currentStateTip"
              type="info"
              effect="solid"
              place="right"
            >
              {infoAboutTextSegment}
            </Tooltip>
          </div>
        )}
        {ogpPhase !== 'DA' && (
          <div className="form-row mb-4">
            <label className="labelText textShadow col-md-4 float-left mt-2">
            {dictionary["pdfPreviewer.Clan"]}
            </label>
            <input
              className="inputField form-control col-md-7 float-right"
              type="number"
              id="articleNumber"
              name="articleNumber"
              placeholder={dictionary["pdfPreviewer.ClanPlaceholder"]}
              onChange={onArticleNumberChange}
            />
          </div>
        )}
        <div id="msgPdf" />
        <CustomTip
          onOpen={transformSelection}
          onConfirm={(comment) => {
            addHighlight({ content, position, comment }, hideTipAndSelection);
          }}
          addComment={dictionary["customTip.KomentarPlaceholder"]}
          addHighlight={dictionary["customTip.DodajKomentar"]}
          submitValue={dictionary["customTip.Sacuvaj"]}
        />
      </div>
    );

  return (
    <div className="pdf-previewer-wrapper">
      <div className="m-auto pdf-previewer-inner-wrapper component-wrapper-fade-in">
        {(isUserLoggedIn === 'YES' && canLeaveComments && phaseActiveStatus == 1 && methodActive) ||
        (isUserLoggedIn === 'YES' && canLeaveComments && ogpPhase === 'DA') ? (
          <PdfLoader url={url} beforeLoad={<LoadingIndicatorOverlay withoutPromise={true} />}>
            {(pdfDocument) => (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                onScrollChange={resetHash}
                scrollRef={(scrollTo) => {
                  scrollViewerTo = scrollTo;
                  scrollToHighlightFromHash();
                }}
                enableAreaSelection={(event) => event.altKey}
                onSelectionFinished={onSelectionFinished}
                highlightTransform={highlightTransform}
                highlights={comments}
              />
            )}
          </PdfLoader>
        ) : (
          <PdfLoader url={url} beforeLoad={<LoadingIndicatorOverlay withoutPromise={true} />}>
            {(pdfDocument) => (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                onScrollChange={resetHash}
                scrollRef={(scrollTo) => {
                  scrollViewerTo = scrollTo;
                  scrollToHighlightFromHash();
                }}
                onSelectionFinished={()=>{}}
                highlightTransform={highlightTransform}
                highlights={comments}
              />
            )}
          </PdfLoader>
        )}
      </div>
    </div>
  );
};

const HighlightPopup = ({ comment }) =>
  comment.text ? <div className="Highlight__popup">{comment.text}</div> : null;

