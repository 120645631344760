import axios from "axios";
import {
  GET_ERRORS,
  GET_USER_ORGANIZATION_CATEGORY,
  GET_USER_ORGANIZATION_CATEGORY_LIST,
  DELETE_USER_ORGANIZATION_CATEGORY,
} from "./types";

export const createUserOrganizationCategory = (userOrganizationCategory) => async (dispatch) => {
  try {
    await axios.post(
      "/api/userOrganizationCategory/create",
      userOrganizationCategory
    );
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const createAndUpdateUserOrganizationCategory = (
  userOrganizationCategory,
  id,
  history
) => async (dispatch) => {
  try {
    await axios.post(
      `/api/userOrganizationCategory/createAndUpdate/${id}`,
      userOrganizationCategory
    );
    history.push("/userOrganizationCategoryList");
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateUserOrganizationCategory = (userOrganizationCategory) => async (dispatch) => {
  try {
    await axios.put(
      "/api/userOrganizationCategory/update",
      userOrganizationCategory
    );
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getUserOrganizationCategoryList = () => async (dispatch) => {
  const res = await axios.get("/api/userOrganizationCategory/findAll");
  dispatch({
    type: GET_USER_ORGANIZATION_CATEGORY_LIST,
    payload: res.data,
  });
};

export const getUserOrganizationCategory = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/userOrganizationCategory/findById/${id}`);
    dispatch({
      type: GET_USER_ORGANIZATION_CATEGORY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const deleteUserOrganizationCategory = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/userOrganizationCategory/delete/${id}`);
    dispatch({
      type: DELETE_USER_ORGANIZATION_CATEGORY,
      payload: id,
    });
  } catch (e) {
    throw(e)
  }
};
