import {
  GET_AD,
  DELETE_AD,
  GET_AD_LIST,
  CREATE_AD,
  UPDATE_AD,
} from '../actions/types';

const initialState = {
  adList: [],
  ad: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AD:
      return {
        ...state,
        ad: action.payload,
      };
    case GET_AD_LIST:
      return {
        ...state,
        adList: action.payload,
      };
    case DELETE_AD:
      return {
        ...state,
        adList: state.adList.filter((ad) => ad.id !== action.payload),
      };
    case CREATE_AD:
      return {
        ...state,
        adList: state.adList.concat(action.payload),
      };
    case UPDATE_AD:
      return {
        ...state,
        adList: state.adList.map((ad) =>
          ad.id === action.payload.id ? action.payload : ad
        ),
      };
    default:
      return state;
  }
}
