import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getTopicOfDiscussion,
  isTopicOfDiscussionOpenForComments,
  updateTopicOfDiscussion,
} from '../../actions/topicOfDiscussionActions';
import { getPhaseList } from "../../actions/phaseActions";
import TopicOfDiscussionPageInformationTable from './TopicOfDiscussionPageInformationTable';
import TopicOfDiscussionPageDetailsTable from './TopicOfDiscussionPageDetailsTable';
import { Alert, Container } from 'react-bootstrap';
import authorizationService from '../../securityUtils/authorizationService';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import ModalForAddEvaluatorMessage from '../../components/EvaluatorMessage/ModalForAddEvaluatorMessage';
import { changeTabCssStyle } from './TopicOfDiscussionSupportMethods';
import LoadingIndicator from '../Reusable/LoadingIndicator';
import { trackPromise } from 'react-promise-tracker';
import ModalForModeratorResourcePage from '../ModeratorResources/ModalForModeratorResourcePage';
import { gaKey } from '../../constants';
import ReactGA from 'react-ga';
import Phases from '../Phase/Phases';
import TopicOfDiscussionPageModeratorAndEvaluatorTopControls from './TopicOfDiscussionPageModeratorAndEvaluatorTopControls.js';
import { getTopicOfDiscussionDocuments } from '../../actions/topicOfDiscussionActions';
import { getRelatedTopicOfDiscussionList } from '../../actions/topicOfDiscussionRelationActions';
import ScrollToTopOnRedirect from '../../components/Reusable/ScrollToTopOnRedirect'

class TopicOfDiscussionPage extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      showRestOfTheButtonText: '',
      showSentMessageNotification: false,
      showModalForAddEvaluatorMessage: false,
      showModeratorResourcePage: false,
      //userIsSubscribed: false
    };
  }

  componentDidMount() {
/*     if(this.props.alowedAnalytics){
      ReactGA.initialize(gaKey);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } */
    this.update();
  }

  update(){
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    trackPromise( 
      this.props.getTopicOfDiscussion(id))
    .then(this.props.getTopicOfDiscussionDocuments(id))
    .then(this.props.getRelatedTopicOfDiscussionList(id))
    //.then(trackPromise(this.props.isTopicOfDiscussionOpenForComments(id)))
    .then(this.props.getPhaseList(id))
    .finally(()=> {
      this.setState({ isLoading: false });
    });
  }

  handleStyleChange = (active) => {
    let arrayOfElements = [
      'zeroId', 
      'firstId',
      'thirdId',
      'fourthId',
      'fifthId',
      'sixthId',
    ];
    changeTabCssStyle(active, arrayOfElements);
  };

  handleShowRestOfTheButtonText = () => {
    this.setState({ showRestOfTheButtonText: 1 });
  };
  handleHideRestOfTheButtonText = () => {
    this.setState({ showRestOfTheButtonText: -1 });
  };

  handleShowMesasageNotification = () => {
    this.setState({
      showSentMessageNotification: true,
    });

    setTimeout(() => {
      this.setState({
        showSentMessageNotification: false,
      });
    }, 3000);
  };

  showModalForAddEvaluatorMessage = (e) => {
    this.setState({
      showModalForAddEvaluatorMessage: true,
    });
  };


  handlePreviewClose = () => {
    this.setState({
      showModalForAddEvaluatorMessage: false,
      showModeratorResourcePage: false,
    });
  };

  handleOpensModeratorResourcePage = (e) => {
    e.preventDefault();
    this.setState({ showModeratorResourcePage: true });
  };

  handleRefresh = () => {
    const { id } = this.props.match.params;
    this.props.getTopicOfDiscussion(id);
    this.props.getTopicOfDiscussionDocuments(id);
    this.props.getPhaseList(id);
  }

  handleRefreshWithoutDocuments = () => {
    const { id } = this.props.match.params;
    this.props.getTopicOfDiscussion(id);
    this.props.getPhaseList(id);
  }
  

  render() {
    const { isLoading } = this.state;
    const { id } = this.props.match.params;
    const { phaseId } = this.props.match.params;
    const {relatedTopicOfDiscussionList} = this.props;
    const {topicOfDiscussionDocuments} = this.props;
    const { topicOfDiscussion } = this.props;
    if(id!=topicOfDiscussion.id && !isLoading){
      this.update();
    }
    let isProcessFinished = topicOfDiscussion.endProcess;
    const {phase} = this.props;
    const activePhase = phase.publicDebate != null && phase.publicDebate.active==true ? 
    phase.publicDebate.phaseTypeDisplay : phase.consultativeProcess != null && phase.consultativeProcess.active==true ? phase.consultativeProcess.phaseTypeDisplay : '/';

    let topicOfDiscussionGroupId = topicOfDiscussion.topicOfDiscussionGroupId;
    let creationDate = topicOfDiscussion.creationDate;

    const topicOfDiscussionModerators =
      topicOfDiscussion.topicOfDiscussionModerators;
    const processCreatorId = topicOfDiscussion.user
      ? topicOfDiscussion.user.id
      : null;

    let isModerator = authorizationService.canAccessProcessEditMode(
      this.props.authRole,
      this.props.userId,
      processCreatorId,
      topicOfDiscussionModerators
    );
    let isEvaluator = authorizationService.canAccessSuggestionButton(
      this.props.authRole
    );

    let isDisabled = isEvaluator || isModerator;

    let restOfTheText =
      this.state.showRestOfTheButtonText == 1 ? (
        <span>
          {isEvaluator
            ? this.props.dictionary["TopicOfDiscussionPage.UputiteSugestiju"]
            : isModerator && this.props.dictionary["TopicOfDiscussionPage.ResursiModeratora"]}
        </span>
      ) : (
        ''
      );

    let pageInfo = isLoading ? (
      <LoadingIndicator />
    ) : (
      <Container fluid className="col component-wrapper-fade-in-large-list">
        {this.state.showSentMessageNotification ? (
          <Alert
            className={
              this.state.showSentMessageNotification
                ? 'alert-style mt-2 col-md-3 alert-shown'
                : 'alert-style mt-2 col-md-3 alert-hidden'
            }
          >
            <p className="mt-1 regular-font">{this.props.dictionary["TopicOfDiscussionPage.PorukaPoslata"]}</p>
          </Alert>
        ) : (
          ''
        )}
        {isDisabled && (
          <div
            onMouseOver={this.handleShowRestOfTheButtonText}
            onMouseLeave={this.handleHideRestOfTheButtonText}
            className="save-version-button-style"
            style={{
              right: '0',
              marginRight: '1vw',
              marginTop: '40vh',
              position: 'fixed',
              zIndex: '2',
            }}
            onClick={
              isEvaluator ? this.showModalForAddEvaluatorMessage : undefined
            }
          >
            <Link
              className="remove-link-effect"
              onClick={
                isModerator ? (e) => this.handleOpensModeratorResourcePage(e) : undefined
              }
              to="#"
            >
              {(isEvaluator && <ChatRoundedIcon />) ||
                (isModerator && <i className="fas fa-database p-1"></i>)}
              {restOfTheText}
            </Link>
          </div>
        )}
        <div id="topic-header-row" className="row container-flow mb-5">
          <div className="col-md-12 m-auto">
            <div className="col-md-11 m-auto">
              <h1>{topicOfDiscussion.nameOfTopic}</h1>
            </div>
          </div>
        </div>
        <div className="col-md-12 m-auto row">
        <div className="col-12 col-lg-4">
          <div id="rowTopicHight" className="col-md-12 m-auto row">
          <div className="col-md-12">
              <TopicOfDiscussionPageInformationTable
                topicOfDiscussion={topicOfDiscussion}
                getTopicOfDiscussion={this.props.getTopicOfDiscussion}
                isProcessFinished={isProcessFinished}
              />
            </div> 
          <div className="col-md-12">
              <TopicOfDiscussionPageDetailsTable
                topicOfDiscussion={topicOfDiscussion}
                activePhase={activePhase}
                relatedTopicOfDiscussionList={relatedTopicOfDiscussionList}
                topicOfDiscussionDocuments = {topicOfDiscussionDocuments}
                topicOfDiscussionModerators={topicOfDiscussionModerators}
                processCreatorId={processCreatorId}
                phase = {phase}
                handleRefresh = {this.handleRefreshWithoutDocuments}
              />
            </div>    
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <div className="col">
            <div className="col-md-12">
              <TopicOfDiscussionPageModeratorAndEvaluatorTopControls
                topicOfDiscussion={topicOfDiscussion}
                //userIsSubscribed={this.state.userIsSubscribed}
                getTopicOfDiscussion={this.props.getTopicOfDiscussion}
                isProcessFinished={isProcessFinished}
                relatedTopicOfDiscussionList={relatedTopicOfDiscussionList}
              />
              <Phases topicOfDiscussionId={topicOfDiscussion.id} 
                                       topicOfDiscussionModerators={topicOfDiscussionModerators}
                                       processCreatorId={processCreatorId}
                                      topicOfDiscussion={topicOfDiscussion}
                                      handleRefresh={this.handleRefresh}
                                      />
            </div>
          </div>
        </div>
        </div>
      </Container>
    );
    return (
      <ScrollToTopOnRedirect>
        {pageInfo}
        <ModalForAddEvaluatorMessage
          show={this.state.showModalForAddEvaluatorMessage}
          handleClose={this.handlePreviewClose}
          topicOfDiscussionId={topicOfDiscussion.id}
          handleShowMesasage={this.handleShowMesasageNotification}
        />
        <ModalForModeratorResourcePage
          show={this.state.showModeratorResourcePage}
          handleClose={this.handlePreviewClose}
        />
      </ScrollToTopOnRedirect>
    );
  }
}

TopicOfDiscussionPage.propTypes = {
  getTopicOfDiscussion: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => (
  {
  topicOfDiscussion: state.topicOfDiscussion.topicOfDiscussion,
  isTopicOfDiscussionOpenForComments: state.topicOfDiscussion.isTopicOfDiscussionOpenForComments,
  authRole: state.security.authRole,
  userId: state.security.userId,
  dictionary:state.startUp.dictionary,
  errors: state.errors,
  alowedAnalytics: state.security.alowedAnalytics,
  phase:state.phase,
  relatedTopicOfDiscussionList:state.topicOfDiscussionRelation.relatedTopicOfDiscussionList,
  topicOfDiscussionDocuments:state.topicOfDiscussion.topicOfDiscussionDocuments
});

export default connect(mapStateToProps, {
  getTopicOfDiscussion,
  isTopicOfDiscussionOpenForComments,
  changeTabCssStyle,
  updateTopicOfDiscussion,
  getTopicOfDiscussionDocuments,
  getRelatedTopicOfDiscussionList,
  getPhaseList
})(TopicOfDiscussionPage);
