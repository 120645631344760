import React, { Component } from "react";
import SurveyAnswersRow from "./SurveyAnswersRow";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";
import { connect } from 'react-redux';
class SurveyAnswersTable extends Component {
  render() {
    let counter = 1;
    let surveyAnswersList = this.props.surveyAnswersList.map(
      (surveyAnswers) => (
        <SurveyAnswersRow
          key={surveyAnswers.id}
          surveyAnswers={surveyAnswers}
          counter={counter++}
          questionTypeId={this.props.questionTypeId}
          handleShowMesasage={this.props.handleShowMesasage}
        />
      )
    );
    return (
      <div className="table-responsive min-table-height">
        <hr></hr>
        <Table id="example" className="table table-sm table-striped mt-2">
          <Thead>
            <Tr className="border-bottom">
              <Th
                wdith="7%"
                className="border-0 boldText emphesized-label-font"
              >
               {this.props.dictionary["surveyAnswersTable.rb"]}
              </Th>
              <Th
                width="93%"
                className="border-0 boldText text-left emphesized-label-font"
              >
                {this.props.dictionary["surveyAnswersTable.odgovor"]}
              </Th>
            </Tr>
          </Thead>
          <Tbody>{surveyAnswersList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
   dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps, {})(SurveyAnswersTable);

