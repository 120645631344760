import React, {useEffect, useMemo, useState} from "react";
import { getFilteredCommentListPost, getNumberOfUnhandledComments } from "../../actions/commentActions";
import { connect, useSelector } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
//import 'react-date-range/dist/styles.css'; // main style file
//import 'react-date-range/dist/theme/default.css'; // theme css file
import { Link } from 'react-router-dom';
import ModalForUpdateComment from './ModalForUpdateComment';
import authorizationService from "../../securityUtils/authorizationService";
import { MRT_Localization_SR_CYRL_RS } from 'mantine-react-table/locales/sr-Cyrl-RS';
//import { DatePicker }  from "@mui/x-date-pickers/datepicker";
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import StatusMark from './StatusMark';
import {
  NotificationManager,
} from 'react-notifications';
import 'dayjs/locale/sr-cyrl';


const CommentTableServerFiltering = (props) => {

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [showModalForCommentUpdate, setshowModalForCommentUpdate] = useState(false);
  const [selectedCommentId, setselectedCommentId] = useState(0);
  const [searchParams, setSearchParams] = useState({});

  const topicOfDiscussionId = props.topicOfDiscussionId;
    const tableClassNames = {
        table:['custom-table', 'comment-table']
    }
  
  const commentStatuses = useSelector(store => store.startUp.taxonomies.CommentStatus);
  const uObradi = commentStatuses.find(x => x.code == "UObradi");
  const columns = useMemo(
    () => [
      {
        id: "statusIcons",
        header: "",
        columnDefType: 'display',
        enableColumnOrdering: true,
        size:10,
        mantineTableBodyCellProps: {
          align: 'center',
        },
        accessorFn: (row) => row.status,
        Cell : ({cell}) => <StatusMark status= {cell.getValue()} />
      },
      {
        accessorKey: 'phaseName',
        header: props.dictionary["commentTable.phaseName"],
        size:120,
      },
      {
        accessorKey: 'userFullName',
        header: props.dictionary["commentTable.userFullName"],
        size:110
      },
      {
        accessorKey: 'userOrganizationOrganizationName',
        header: props.dictionary["commentTable.userOrganizationOrganizationName"],
        size:140
      },
      {
        accessorKey: 'category',
        header: props.dictionary["commentTable.category"],
        size:140,
      },
      {
        id : 'creationDate',
        Cell : ({ cell }) => `${cell.getValue()?.toLocaleDateString('sr-RS')} ${cell.getValue()?.toLocaleTimeString('sr-RS')}`,
        accessorFn: (row) => new Date(row.creationDate),
        filterVariant: 'date-range',
        mantineFilterDateInputProps: {
          locale: 'sr-cyrl'
        },
        header: props.dictionary["commentTable.creationDate"],
        size: 140,
        mantineTableHeadCellProps: {
          className:'dateColumn'
        }
      },
      {
        accessorKey: 'textSegment',
        header: props.dictionary["commentTable.textSegment"],
        size:160
      },
      {
        accessorKey: 'status',
        header: props.dictionary["commentTable.status"],
        size:110
      },
      {
        accessorKey: 'documentTitle',
        header: props.dictionary["commentTable.documentTitle"],
        size:150
      },
      {
        id: 'obradiKomentar',
        header: props.dictionary["commentTable.obradiKomentar"],
        size:40,
        columnDefType: 'display', 
        enableColumnOrdering: true, 
        show : authorizationService.canAccessStartOfANewProcess(this?.props?.authRole),
        accessorFn: (row) => row.id,
        mantineTableHeadCellProps: {
          align: 'center',
          className:'editColumn'
        },
        mantineTableBodyCellProps: {
          align: 'center',
        },
        Cell: ({ row }) => (
          !props.topicOfDiscussion?.endProcess &&
          <Link
          onClick={async (e) => {
            e.preventDefault();
            if (row.original.status==='Примљен'){
              let commentUpdate = {
                id: row.original.id,
                topicOfDiscussion: {
                  id: topicOfDiscussionId
                },
                commentStatus: uObradi
              }
              await props.updateCommentStatus(commentUpdate);
            }
            setselectedCommentId(row.original.id);       
            setshowModalForCommentUpdate(true);
          }}
          className="form-group deleteButton"
          to="#"
        >
          <i className="fas fa-edit"></i>
        </Link>
        )
      }
    ],
    [],
  );
  
  useEffect(() => {
      if (!props.commentListFiltered?.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }
      var filters = [];
      columnFilters?.forEach(element => {
        if(element.id != "creationDate") filters.push(element);
        else {
          if(element.value[0] != undefined && element.value[0] != undefined && element.value[0] != "") {
            const offset = element.value[0].getTimezoneOffset();
            const dateFrom = new Date(element.value[0].getTime() - (offset*60*1000));
            filters.push( {id: "creationDateFrom", value: dateFrom});
          }
          if(element.value[1] != undefined && element.value[1] != undefined && element.value[1] != "") {
            const offset = element.value[1].getTimezoneOffset();
            const dateTo = new Date(element.value[1].getTime() - (offset*60*1000));
            filters.push( {id: "creationDateTo", value: dateTo});
          }
        }
      });
      const searchParams = { 
        start: pagination.pageIndex,
        size: pagination.pageSize,
        filters: filters,
        globalFilter: globalFilter ?? '',
        sorting: sorting ?? []
      };
      setSearchParams(searchParams);
      //var p = qs.stringify(searchParams, {allowDots: true});
      trackPromise(props.getFilteredCommentListPost(topicOfDiscussionId, searchParams).then(() => {props.getNumberOfUnhandledComments(topicOfDiscussionId)}))
      .catch(() => {
         setIsError(true);
         NotificationManager.error(props.dictionary["universal.NeuspesnoUcitavanjePodataka"]);
        })
      .then( () => {
        setIsError(false);
      })
      .finally(() => {
        setIsLoading(false);
        setIsRefetching(false);
      }
    );
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    topicOfDiscussionId,
    selectedCommentId
  ]);

  const customTable = 'custom-table';
  const customPaper = 'custom-paper'

  const handleNumberOfUnhandledCommentsClick = (e) => {
    e.preventDefault();
    if (columnFilters.filter(f => f.id === 'status' && f.value === 'Примљен').length == 0) {
      setColumnFilters((columnFilters) => [...columnFilters, { id: 'status', value: 'Примљен'}]);
    }
  }
  return   (
    <>
    <div onClick={handleNumberOfUnhandledCommentsClick}>
      <Link className="remove-link-effect" to="#" onClick={(e) => {e.preventDefault()}}>
        <em className="textShadow default-label-text-link">
          {props.dictionary["CommentTableServerFiltering.BrojNeobradjenihKomentara"]}:&#8287;
        </em>
        <span className="textShadow bold-text">
          {props.numberOfUnhandledComments}
        </span>
      </Link>
      </div>
    <MantineReactTable
      localization={MRT_Localization_SR_CYRL_RS}
      enableGlobalFilter = {false}
      //columnFilterDisplayMode= 'popover'
      columns={columns}
      data={props.commentListFiltered}
      displayColumnDefOptions= {{ 'mrt-row-numbers': { size:10,align:'center' } }}
      mantineTableProps={{
        className: {customTable},
        striped:true,
        tablelayout: 'fixed',
      }}
      mantineProgressProps={{
        striped:false,
        animate:true,
        size:'xs'
      }}
      mantinePaperProps={{
        className:{customPaper}
      }}
      enableRowNumbers
      initialState={{ showColumnFilters: true }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={props.rowCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
    />
    { showModalForCommentUpdate && selectedCommentId>0 &&
    <ModalForUpdateComment
          show={showModalForCommentUpdate}
          handleClose={() => { setshowModalForCommentUpdate(false);}}
          commentId={selectedCommentId}
          searchParams={searchParams}
    /> }
    </>
  );
};
const mapStateToProps = (state) => {
  return {
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  commentListFiltered: state?.comment?.commentListFiltered?.content ?? [],
  rowCount: state?.comment?.commentListFiltered?.totalElements ?? 0,
  numberOfUnhandledComments: state?.comment?.commentsUnhandled ?? 0,
  topicOfDiscussion: state.topicOfDiscussion.topicOfDiscussion
} };

export default connect(mapStateToProps, {
  getNumberOfUnhandledComments,
  getFilteredCommentListPost,
})(CommentTableServerFiltering);
