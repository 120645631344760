// @flow

import React, { useState, Fragment,useEffect} from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Alert from 'react-bootstrap/Alert';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ModalForAddModeratorComment from '../Comment/ModalForAddModeratorComment';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
const updateHash = (highlight) => {
  document.location.hash = `highlight-${highlight.commentText.id}`;
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

function Sidebar({
  commentObjectList,
  isUserLoggedIn,
  createCommentUserVote,
  updateCommentUserVote,
  phaseActiveStatus,
  ogpPhase,
  isUserModerator,
}) {
  const [showModalForCommentAdd, setShowModalForCommentAdd] = useState(0);
  const [comment, setComment] = useState(1);
  const [showReplies, setShowReplies] = useState(2);
  const [replyId, setReplyId] = useState(3);
  const [numberOfLikes, setNumberOfLikes] = useState(4);
  const [numberOfDislikes, setNumberOfDislikes] = useState(5);
  const [showMessageAfterReply, setShowMessageAfterReply] = useState(false);
  const [showModalForModeratorCommentAdd, setShowModalForModeratorCommentAdd] =
    useState(0);
  const [showDocumentPreview, setShowDocumentPreview] = useState(false);
  const [documentName, setDocumentName] = useState(false);
  
  useEffect(() => {
    const hash=window.location.hash.substring(1);
    if(hash) {
      const params = new URLSearchParams(hash);
      const liElement=document.getElementById(params.get('commentId'));
      if(liElement){
          liElement.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
        });
          liElement.classList.add('selected__sidebar__highlight');
      }
    }
    }, []);
  const updateHighlight = (highlight, vote) => {
    if (highlight.commentUserVote) {
      const updatedCommentUserVote = {
        id: highlight.commentUserVote.id,
        commentId: highlight.commentUserVote.commentId,
        userId: highlight.commentUserVote.userId,
        hasLiked: vote == 'Like' ? true : false,
        hasDisliked: vote == 'Dislike' ? true : false,
        ogpCommentId: highlight.commentUserVote.ogpCommentId,
      };
      if (ogpPhase == 'DA') {
        updateCommentUserVote(updatedCommentUserVote, ogpPhase);
      } else {
        updateCommentUserVote(updatedCommentUserVote);
      }
    } else {
      let newCommentUserVote = {};
      if (ogpPhase == 'DA') {
        newCommentUserVote = {
          ogpCommentId: highlight.id,
          hasLiked: vote == 'Like' ? true : false,
          hasDisliked: vote == 'Dislike' ? true : false,
        };
      } else {
        newCommentUserVote = {
          commentId: highlight.id,
          hasLiked: vote == 'Like' ? true : false,
          hasDisliked: vote == 'Dislike' ? true : false,
        };
      }
      if (ogpPhase == 'DA') {
        createCommentUserVote(newCommentUserVote, ogpPhase);
      } else {
        createCommentUserVote(newCommentUserVote);
      }
    }
  };

  const numberOfReplies = (highlight) => {
    let listOfValidReplies = highlight.replies.filter(
      (r) => r.status && r.status != 'Одбијен'
    );
    let numberOfRepliesOnComment = listOfValidReplies.length;
    if (highlight.commentTextModerator) {
      ++numberOfRepliesOnComment;
    }
    return numberOfRepliesOnComment;
  };

  const handleShowMesasage = () => {
    setShowMessageAfterReply(true);

    setTimeout(() => {
      setShowMessageAfterReply(false);
    }, 3000);
  };

  const handlePreviewClose = (submitted) => {
    setShowModalForCommentAdd(false);
    setShowModalForModeratorCommentAdd(false);
    setShowDocumentPreview(false);
    if (submitted) {
      handleShowMesasage();
    }
  };

  const showModalForDocumentPreview = () => {
    setShowDocumentPreview(true);
  };

  let sidebarContent = (
    <div
      className="sidebar container-fluid border-bottom"
      style={{
        minWidth: '400px',
        // width: '85vw',
        height: 'max-content',
        // maxHeight: '100vh',
        position: 'relative',
      }}
    >
      {showMessageAfterReply ? (
        <Alert
          className={
            showMessageAfterReply
              ? 'alert-style mt-2 col-md-3 alert-shown'
              : 'alert-style mt-2 col-md-3 alert-hidden'
          }
        >
          <p className="mt-1">Ваш одговор је послат.</p>
        </Alert>
      ) : (
        ''
      )}
      <ul className="sidebar__highlights">
        {commentObjectList.map((highlight, index) => (
          <li key={index} id={highlight.id} className="sidebar__highlight">
            <ul className="w-100" style={{ padding: 0 }}>
              <li
                className="row w-100"
                onClick={() => {
                  if (
                    highlight.commentText.content.text &&
                    highlight.commentText.content.text != ''
                  ) {
                    updateHash(highlight);
                  }
                }}
              >
                <div className="d-flex flex-row w-100 justify-content-between align-items-center">
                  <div>
                    <i className="fas fa-user-circle user-default-icon"></i>
                  </div>
                  <div className="user-name-style p-2">
                    <strong>
                      {highlight.user.firstName + ' ' + highlight.user.lastName}
                    </strong>
                  </div>
                  {highlight.userOrganizationName ? (
                    <span
                      className="bullet-char default-tab-date-label-style"
                      style={{ margin: '0', padding: '0' }}
                    >
                      &bull;
                    </span>
                  ) : (
                    ''
                  )}
                  {highlight.userOrganizationName ? (
                    <div className="default-tab-date-label-style p-2">
                      {highlight.userOrganizationName}
                    </div>
                  ) : (
                    ''
                  )}
                  <span
                    className="bullet-char default-tab-date-label-style"
                    style={{ margin: '0', padding: '0' }}
                  >
                    &bull;
                  </span>
                  <div className="default-tab-date-label-style p-2">
                    {moment(highlight.creationDate).format(
                      'DD.MM.YYYY HH:mm:ss'
                    )}
                  </div>
                  <span
                    className="bullet-char default-tab-date-label-style"
                    style={{ margin: '0', padding: '0' }}
                  >
                    &bull;
                  </span>
                  <div className="default-tab-date-label-style p-2 me-auto">
                    {highlight.status}
                  </div>
                  <div className="default-tab-date-label-style p-3 ms-auto pe-0">
                    {highlight.category}
                  </div>
                </div>
                <div className="d-flex flex-row w-100 justify-content-between mb-1 mt-2" style={{ gap:'10px'}}>
                  <div className="overflow-auto text-justify" style={{ maxHeight:'200px', textAlign:'justify', flex:'7'}}>
                    <strong>{highlight.commentText.comment.text}</strong>
                  </div>    
                  {highlight.commentText.content.text ? (
                    <blockquote style={{ marginTop: '0.5rem',flex:'1' }}>
                      {`${highlight.commentText.content.text
                        .slice(0, 90)
                        .trim()}…`}
                    </blockquote>
                  ) : null}
                  {highlight.commentText.content.image ? (
                    <div
                      className="highlight__image"
                      style={{ marginTop: '0.5rem' }}
                    >
                      <img
                        src={highlight.commentText.content.image}
                        alt={'Screenshot'}
                      />
                    </div>
                  ) : null}
                  {highlight.commentText.position == '' ? (
                    highlight.commentDocument ? (
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          setDocumentName(
                            highlight.commentDocument.uuidDocName
                          );
                          showModalForDocumentPreview();
                        }}
                        to="#"
                        //className="form-row col-md-12 mb-1 mt-2"
                        className="d-flex"
                      >
                        <span className="title-text-color boldText textShadow ml-auto">
                          <span className="bullet-char">&bull;</span>
                          <em>Прилог</em>
                        </span>
                        <AttachFileIcon className="previewButton boldText" />
                      </Link>
                    ) : (
                      ''
                    )
                  ) : (
                    <footer className="highlight__location textShadow">
                      Страна {highlight.commentText.position.pageNumber}
                    </footer>
                  )}
                </div>
              </li>
              <li className="form-row w-100 mt-2">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  {ogpPhase == 'DA' ? (
                    ''
                  ) : isUserLoggedIn == 'YES' ? (
                    numberOfReplies(highlight) == 0 ? (
                      isUserModerator == 'YES' ? (
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            setShowModalForModeratorCommentAdd(true);
                            setComment(highlight);
                          }}
                          className="user-name-style boldText"
                          to="#"
                        >
                          Одговори
                        </Link>
                      ) : (
                        <span className="boldText span-2">Нема одговора</span>
                      )
                    ) : numberOfReplies(highlight) == 1 ? (
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          if (showReplies == true) {
                            setShowReplies(false);
                            setReplyId(highlight.id);
                          } else {
                            setShowReplies(true);
                            setReplyId(highlight.id);
                          }
                        }}
                        to="#"
                        className="user-name-style boldText span-2"
                      >
                        <ExpandMoreRoundedIcon /> {numberOfReplies(highlight)}{' '}
                        oдговор
                      </Link>
                    ) : (
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          if (showReplies == true) {
                            setShowReplies(false);
                            setReplyId(highlight.id);
                          } else {
                            setShowReplies(true);
                            setReplyId(highlight.id);
                          }
                        }}
                        to="#"
                        className="user-name-style boldText span-2"
                      >
                        <ExpandMoreRoundedIcon /> {numberOfReplies(highlight)}{' '}
                        oдговорa
                      </Link>
                    )
                  ) : numberOfReplies(highlight) == 0 ? (
                    <span className="boldText span-2">Нема одговора</span>
                  ) : numberOfReplies(highlight) == 1 ? (
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        if (showReplies == true) {
                          setShowReplies(false);
                          setReplyId(highlight.id);
                        } else {
                          setShowReplies(true);
                          setReplyId(highlight.id);
                        }
                      }}
                      to="#"
                      className="user-name-style boldText span-2"
                    >
                      <ExpandMoreRoundedIcon /> {numberOfReplies(highlight)}{' '}
                      oдговор
                    </Link>
                  ) : (
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        if (showReplies == true) {
                          setShowReplies(false);
                          setReplyId(highlight.id);
                        } else {
                          setShowReplies(true);
                          setReplyId(highlight.id);
                        }
                      }}
                      to="#"
                      className="user-name-style boldText span-2"
                    >
                      <ExpandMoreRoundedIcon /> {numberOfReplies(highlight)}{' '}
                      oдговорa
                    </Link>
                  )}
                  <div className="p-2">
                    <ThumbUpAltIcon
                      onClick={() => {
                        if (isUserLoggedIn == 'YES' && phaseActiveStatus == 1) {
                          setNumberOfLikes(1);
                          setNumberOfDislikes(-1);
                          updateHighlight(highlight, 'Like');
                        }
                      }}
                      className={
                        highlight.commentUserVote &&
                        highlight.commentUserVote.hasLiked === true &&
                        isUserLoggedIn == 'YES'
                          ? 'user-name-style'
                          : 'vote-icon-default-style'
                      }
                    />
                    {highlight.numberOfLikes}{' '}
                    <ThumbDownAltIcon
                      onClick={() => {
                        if (isUserLoggedIn == 'YES' && phaseActiveStatus == 1) {
                          setNumberOfDislikes(1);
                          setNumberOfLikes(-1);
                          updateHighlight(highlight, 'Dislike');
                        }
                      }}
                      className={
                        highlight.commentUserVote &&
                        highlight.commentUserVote.hasDisliked &&
                        (isUserLoggedIn == 'YES') === true
                          ? 'user-name-style'
                          : 'vote-icon-default-style'
                      }
                    />
                    {highlight.numberOfDislikes}
                  </div>
                </div>
              </li>
              {showReplies == true && highlight.id == replyId ? (
                <ShowRepliesOnComment
                  replies={highlight.replies}
                  highlight={highlight}
                  isUserLoggedIn={isUserLoggedIn}
                  setShowModalForCommentAdd={setShowModalForCommentAdd}
                  setComment={setComment}
                  phaseActiveStatus={phaseActiveStatus}
                />
              ) : (
                ''
              )}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
  return (
    <Fragment>
      {sidebarContent}
      {!!showModalForModeratorCommentAdd && (
        <ModalForAddModeratorComment
          show={showModalForModeratorCommentAdd}
          comment={comment}
          handleClose={handlePreviewClose}
        />
      )}

      {!!showDocumentPreview && (
        <ModalForDocumentPreview
          show={showDocumentPreview}
          documentName={documentName}
          controllerReference={'commentDocument'}
          handleClose={handlePreviewClose}
        />
      )}
    </Fragment>
  );
}

export default Sidebar;

const ShowRepliesOnComment = ({
  replies,
  highlight,
  isUserLoggedIn,
  setShowModalForCommentAdd,
  setComment,
  phaseActiveStatus,
}) => (
  <li className="form-row col-md-12 ml-auto mt-4">
    <ul>
      {highlight.commentTextModerator ? (
        <li key={0} className="row w-100 mb-4">
          <div className="d-flex flex-row w-100 justify-content-between align-items-center mb-1">
            <div>
              <i className="fas fa-user-circle user-moderator-icon"></i>
            </div>
            <div className="user-moderator-name-style textShadow p-2">
              <strong>{'Модератор'}</strong>
            </div>
            <span
              className="bullet-char default-tab-date-label-style"
              style={{ margin: '0', padding: '0' }}
            >
              &bull;
            </span>
            <div className="default-tab-date-label-style p-2 me-auto">
              {moment(highlight.creationDateModerator).format(
                'DD.MM.YYYY HH:mm:ss'
              )}
            </div>
          </div>
          <div className="form-group mt-2">
            <strong>{highlight.commentTextModerator}</strong>
          </div>
          {/* <div className="d-flex flex-row w-100 justify-content-between align-items-center">
            {isUserLoggedIn == 'YES' && phaseActiveStatus == 1 ? (
              <Link
                onClick={() => {
                  setShowModalForCommentAdd(true);
                  setComment(highlight);
                }}
                className="user-name-style boldText"
                to="#"
              >
                Одговори
              </Link>
            ) : (
              ''
            )}
          </div> */}
        </li>
      ) : (
        ''
      )}
      {replies.map((reply, index) => (
        <li key={index} className="ml-4 form-row w-80">
          <div className="d-flex flex-row w-100 justify-content-between align-items-center">
            <div>
              <i className="fas fa-user-circle user-default-icon"></i>
            </div>
            <div className="user-name-style p-2">
              <strong>
                {reply.user.firstName + ' ' + reply.user.lastName}
              </strong>
            </div>
            <span
              className="bullet-char default-tab-date-label-style"
              style={{ margin: '0', padding: '0' }}
            >
              &bull;
            </span>
            <div className="default-tab-date-label-style p-2 me-auto">
              {moment(reply.creationDate).format('DD.MM.YYYY HH:mm:ss')}
            </div>
          </div>
          <div className="form-group mt-2">
            <strong>{reply.replyText}</strong>
          </div>
        </li>
      ))}
    </ul>
  </li>
);
