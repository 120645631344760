import moment from 'moment';
import React from 'react';
import {
  BACKEND_MOMENT_DATE_FORMAT,
  FRONTEND_MOMENT_DATE_FORMAT,
  BACKEND_MOMENT_DATE_TIME_FORMAT,
  FRONTEND_MOMENT_DATE_TIME_FORMAT,
  allowedFileFormats,
} from './constants';
import { GET_ERRORS } from './actions/types';
import {getDocument, GlobalWorkerOptions} from 'pdfjs-dist';
import worker from 'pdfjs-dist/webpack';
import store from './store';


export const formatDateFromBackend = (date) =>
  moment(date, BACKEND_MOMENT_DATE_FORMAT).format(FRONTEND_MOMENT_DATE_FORMAT);

export const dateFromBackendToJsDate = (date) => {
  const momentDate = moment(date, BACKEND_MOMENT_DATE_FORMAT);
  return momentDate.isValid() ? momentDate.toDate() : null;
};

export const adjustTimezoneDifference = (date) => {
  const wrapper = moment.utc(date).local();
  const adjusted = new Date(wrapper.format(BACKEND_MOMENT_DATE_FORMAT));
  return adjusted;
};

export const formatDateTimeFromBackend = (date) =>
  moment(date, BACKEND_MOMENT_DATE_TIME_FORMAT).format(
    FRONTEND_MOMENT_DATE_TIME_FORMAT
  );

export const dateTimeFromBackendToJsDate = (date) => {
  const momentDate = moment(date, BACKEND_MOMENT_DATE_TIME_FORMAT);
  return momentDate.isValid() ? momentDate.toDate() : null;
};

export const adjustTimezoneDifferenceDateTime = (date) => {
  const wrapper = moment.utc(date).local();
  const adjusted = new Date(wrapper.format(BACKEND_MOMENT_DATE_TIME_FORMAT));
  return adjusted;
};

export const setMessage = (msg, tagId, msgId) => async (dispatch) => {
  document.querySelector(tagId)?.classList.add('alert-danger');
  document.querySelector(msgId).style.color = 'red';
  document.querySelector(msgId).innerHTML = '<p class="mb-0">' + msg + '</p>';

  setTimeout(function () {
    if (document.querySelector(msgId) != null) {
      document.querySelector(msgId).innerHTML = '';
      document.querySelector(msgId)?.classList.remove('alert-danger');
      document.querySelector(tagId)?.classList.remove('alert-danger');
    }
  }, 3000);
};

export const setNotification = (msg, tagId, msgId) => async (dispatch) => {
  document.querySelector(tagId).classList.add('alert-success');
  document.querySelector(msgId).style.color = '#3c763d';
  document.querySelector(msgId).innerHTML = '<p>' + msg + '</p>';

  setTimeout(function () {
    if (document.querySelector(msgId) != null) {
      document.querySelector(msgId).innerHTML = '';
      document.querySelector(msgId).classList.remove('alert-success');
      document.querySelector(tagId).classList.remove('alert-success');
    }
  }, 3000);
};

const setMessagecheckPdfFormat = async (msg, tagId, msgId) => {
  document.querySelector(tagId).classList.add('alert-danger');
  document.querySelector(msgId).style.color = 'red';
  document.querySelector(msgId).innerHTML = '<p>' + msg + '</p>';

  setTimeout(function () {
    if (document.querySelector(msgId) != null) {
      document.querySelector(msgId).innerHTML = '';
      document.querySelector(msgId).classList.remove('alert-danger');
      document.querySelector(tagId).classList.remove('alert-danger');
    }
  }, 3000);
};

export const returnFileFormat = (documentName) => {
  let format = documentName.substring(
    documentName.lastIndexOf('.') + 1,
    documentName.length
  );
  return format;
};

export const deleteErrors = () => async (dispatch) => {
  dispatch({
    type: GET_ERRORS,
    payload: {},
  });
};

export const checkPdfFormat = (files, tagId, msgId) => {
  const dictionary = store.getState().startUp.dictionary;
  for (let i = 0; i < files.length; i++) {
    let format = returnFileFormat(files[i].name);
    if (format.toLowerCase() != 'pdf' ) {
      setMessagecheckPdfFormat(
        dictionary["utils.DokumentUPdfFormatu"],
        tagId,
        msgId
      );
      return true;
    }
  }
  return false;
};

export const checkFileFormat = (files, tagId, msgId) => {
  const dictionary = store.getState().startUp.dictionary;
  for (let i = 0; i < files.length; i++) {
    let format = returnFileFormat(files[i].name);
    if (!allowedFileFormats.includes(format.toLowerCase())) {
      setMessagecheckPdfFormat(
        dictionary["utils.DokumentUOdgovarajucemFormatu"],
        tagId,
        msgId
      );
      return true;
    }
  }
  return false;
};

export const checkPdfOrDocxFormat = (files, tagId, msgId) => {
  const dictionary = store.getState().startUp.dictionary;
  for (let i = 0; i < files.length; i++) {
    let format = returnFileFormat(files[i].name);
    if (format.toLowerCase() != 'pdf' && format.toLowerCase() != 'docx') {
      setMessagecheckPdfFormat(
        dictionary["utils.DokumentUPdfDocxFormatu"],
        tagId,
        msgId
      );
      return true;
    }
  }
  return false;
};

export const checkPdfStructure = (files, tagId, msgId) => {
  return new Promise(async (resolve, reject) => {
    if(files.length==0)
      return;
    GlobalWorkerOptions.workerSrc=worker;
    const file = files[0];
    const fileReader = new FileReader();
    let containText = false;
    const dictionary = store.getState().startUp.dictionary;
    fileReader.onload = async () => {
      const arrBuffer = fileReader.result;
      const fileLoading = getDocument({ data: arrBuffer });
      try {
        const pdf = await fileLoading.promise;
        const numPages = pdf.numPages;
        for (let x = 1; x <= numPages; x++) {
          const page = await pdf.getPage(x);
          const pageTextContent = await page.getTextContent();
          if (pageTextContent.items.length > 0) {
            containText = true;
            break;
          }
        }
        if (containText === false) {
          setMessagecheckPdfFormat(dictionary["utils.DokumentNeSadrziTekst"], tagId, msgId);
        }
        resolve(containText);
      } catch (error) {
        console.error(error);
        setMessagecheckPdfFormat(dictionary["utils.GreskaProveraStruktureFajla"], tagId, msgId);
        reject(error);
      }
    };
    fileReader.readAsArrayBuffer(file);
  });
};

export const paragrapghPagination = (text) => {
  return <span className="text-shadow pagination-style">{text}</span>;
};

export const changePaginationText = (obj) => {
  return (
    <span className="text-shadow pagination-style">
      {' ' + obj.from + '-' + obj.to + ' до ' + obj.count}
    </span>
  );
};

export const downloadDocument = (documentContent, documentName) => {
  const binaryData = atob(documentContent);
  const byteArray = new Uint8Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }

  const blob = new Blob([byteArray], { type: 'application/octet-stream' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = documentName;
  a.style.display = 'none';

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};