import {
  GET_OGP_MODERATOR,
  GET_OGP_MODERATOR_LIST,
  DELETE_OGP_MODERATOR,
  CREATE_OGP_MODERATOR,
} from "../actions/types";

const initialState = {
  ogpModeratorList: [],
  ogpModerator: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OGP_MODERATOR:
      return {
        ...state,
        kmsVrstaZaposleniList: action.payload,
      };
    case GET_OGP_MODERATOR_LIST:
      return {
        ...state,
        ogpModeratorList: action.payload,
      };
    case DELETE_OGP_MODERATOR:
      return {
        ...state,
        ogpModeratorList: state.ogpModeratorList.filter(
          (ogpModerator) => ogpModerator.id !== action.payload
        ),
      };
    case CREATE_OGP_MODERATOR:
      return {
        ...state,
        ogpModeratorList: state.ogpModeratorList.concat(action.payload),
      };
    default:
      return state;
  }
}
