export const GET_ERRORS = 'GET_ERRORS';
export const RESET_ERRORS = 'RESET_ERRORS';
export const SET_LOGGED_USER = 'SET_LOGGED_USER';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const GET_USER = 'GET_USER';
export const GET_USER_AFTER_LOGIN = 'GET_USER_AFTER_LOGIN';
export const GET_USERS = 'GET_USERS';
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const CREATE_FIELD_OF_INTEREST_SUCCESS = 'CREATE_FIELD_OF_INTEREST_SUCCESS';
export const UPDATE_FIELD_OF_INTEREST_SUCCESS = 'UPDATE_FIELD_OF_INTEREST_SUCCESS';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_FIELD_OF_INTEREST = 'GET_FIELD_OF_INTEREST';
export const GET_FIELD_OF_INTEREST_IN_TOPIC = 'GET_FIELD_OF_INTEREST_IN_TOPIC';
export const GET_FIELD_OF_INTEREST_LIST = 'GET_FIELD_OF_INTEREST_LIST';
export const GET_FIELD_OF_INTEREST_LIST_BY_JLS = 'GET_FIELD_OF_INTEREST_LIST_BY_JLS';
export const GET_FIELD_OF_INTEREST_LIST_FOR_FILTER='GET_FIELD_OF_INTEREST_LIST_FOR_FILTER';
export const GET_ACTIVE_FIELD_OF_INTEREST_LIST = 'GET_ACTIVE_FIELD_OF_INTEREST_LIST';
export const DELETE_FIELD_OF_INTEREST = 'DELETE_FIELD_OF_INTEREST';
export const GET_ACTION_PLAN = 'GET_ACTION_PLAN';
export const GET_ACTION_PLAN_LIST = 'GET_ACTION_PLAN_LIST';
export const DELETE_ACTION_PLAN = 'DELETE_ACTION_PLAN';
export const GET_OGP_FIELD_OF_INTEREST = 'GET_OGP_FIELD_OF_INTEREST';
export const GET_OGP_FIELD_OF_INTEREST_LIST = 'GET_OGP_FIELD_OF_INTEREST_LIST';
export const DELETE_OGP_FIELD_OF_INTEREST = 'DELETE_OGP_FIELD_OF_INTEREST';
export const GET_OGP_MODERATOR = 'GET_OGP_MODERATOR';
export const GET_OGP_MODERATOR_LIST = 'GET_OGP_MODERATOR_LIST';
export const DELETE_OGP_MODERATOR = 'DELETE_OGP_MODERATOR';
export const CREATE_OGP_MODERATOR = 'CREATE_OGP_MODERATOR';
export const GET_TOPIC_OF_DISCUSSION_MODERATOR =
  'GET_TOPIC_OF_DISCUSSION_MODERATOR';
export const GET_IS_TOPIC_OF_DISCUSSION_DOCUMENTS = "GET_IS_TOPIC_OF_DISCUSSION_DOCUMENTS";
export const GET_TOPIC_OF_DISCUSSION_MODERATOR_LIST =
  'GET_TOPIC_OF_DISCUSSION_MODERATOR_LIST';
export const DELETE_TOPIC_OF_DISCUSSION_MODERATOR =
  'DELETE_TOPIC_OF_DISCUSSION_MODERATOR';
export const CREATE_TOPIC_OF_DISCUSSION_MODERATOR =
  'CREATE_TOPIC_OF_DISCUSSION_MODERATOR';
export const GET_TOPIC_OF_DISCUSSION = 'GET_TOPIC_OF_DISCUSSION';
export const GET_IS_TOPIC_OF_DISCUSSION_OPEN_FOR_COMMENTS = 'GET_IS_TOPIC_OF_DISCUSSION_OPEN_FOR_COMMENTS';
export const GET_TOPIC_OF_DISCUSSION_LIST = 'GET_TOPIC_OF_DISCUSSION_LIST';
export const GET_TOPIC_OF_DISCUSSION_LIST_BY_FIELD_OF_INTEREST =
  'GET_TOPIC_OF_DISCUSSION_LIST_BY_FIELD_OF_INTEREST';
export const GET_TOPIC_OF_DISCUSSION_LIST_PAGEABLE =
  'GET_TOPIC_OF_DISCUSSION_LIST_PAGEABLE';
export const GET_TOPIC_OF_DISCUSSION_LIST_FILTERABLE =
  "GET_TOPIC_OF_DISCUSSION_LIST_FILTERABLE";
export const GET_TOPIC_OF_DISCUSSION_WELCOME_PAGE =
  "GET_TOPIC_OF_DISCUSSION_WELCOME_PAGE";
export const GET_FINISHED_TOPIC_OF_DISCUSSION = 
  "GET_FINISHED_TOPIC_OF_DISCUSSION";
export const GET_HIDDEN_TOPIC_OF_DISCUSSION = 'GET_HIDDEN_TOPIC_OF_DISCUSSION';
export const GET_TOPIC_OF_DISCUSSION_LIST_BY_DATE =
  'GET_TOPIC_OF_DISCUSSION_LIST_BY_DATE';
export const DELETE_TOPIC_OF_DISCUSSION = 'DELETE_TOPIC_OF_DISCUSSION';
export const CREATE_TOPIC_OF_DISCUSSION = 'CREATE_TOPIC_OF_DISCUSSION';
export const UPDATE_TOPIC_OF_DISCUSSION = 'UPDATE_TOPIC_OF_DISCUSSION';
export const GET_TOPIC_OF_DISCUSSION_LIST_DTO =
  'GET_TOPIC_OF_DISCUSSION_LIST_DTO';
export const GET_TOPIC_OF_DISCUSSION_GROUP_LIST_DTO =
  'GET_TOPIC_OF_DISCUSSION_GROUP_LIST_DTO';
export const GET_TOPIC_OF_DISCUSSION_GROUP_REPRESENTATIVES_LIST_DTO =
  'GET_TOPIC_OF_DISCUSSION_GROUP_REPRESENTATIVES_LIST_DTO';
export const GET_TOPIC_OF_DISCUSSION_CURRENT_CONSULTATIONS_AND_DEBATES_LIST_DTO =
  'GET_TOPIC_OF_DISCUSSION_CURRENT_CONSULTATIONS_AND_DEBATES_LIST_DTO';
export const GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_LIST_DTO =
  'GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_LIST_DTO';
  export const GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_AND_NOTIFICATION_LIST_DTO =
  'GET_TOPIC_OF_DISCUSSION_USER_ACTIVITY_AND_NOTIFICATION_LIST_DTO';
export const SET_TOPIC_OF_DISCUSSION_TABLE_FILTER = 'SET_TOPIC_OF_DISCUSSION_TABLE_FILTER';
export const SET_TOPIC_OF_DISCUSSION_TABLE_SCROLLYPOSITION = 'SET_TOPIC_OF_DISCUSSION_TABLE_SCROLLYPOSITION';
export const GET_COMMENT = 'GET_COMMENT';
export const GET_COMMENT_LIST = 'GET_COMMENT_LIST';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const GET_COMMENT_MAX_ID = 'GET_COMMENT_MAX_ID';
export const GET_NUMBER_OF_UNHANDLED_COMMENTS = 'GET_NUMBER_OF_UNHANDLED_COMMENTS';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_COMMENT_STATUS = 'UPDATE_COMMENT_STATUS';
export const GET_COMMENT_LIST_DTO = 'GET_COMMENT_LIST_DTO';
export const GET_COMMENT_LIST_FILTERED = 'GET_COMMENT_LIST_FILTERED';
export const GET_STARTING_POINTS = 'GET_STARTING_POINTS';
export const GET_STARTING_POINTS_LIST = 'GET_STARTING_POINTS_LIST';
export const DELETE_STARTING_POINTS = 'DELETE_STARTING_POINTS';
export const GET_STARTINGWORK_ON_DOCUMENT = 'GET_STARTINGWORK_ON_DOCUMENT';
export const GET_STARTINGWORK_ON_DOCUMENT_LIST =
  'GET_STARTINGWORK_ON_DOCUMENT_LIST';
export const DELETE_STARTINGWORK_ON_DOCUMENT =
  'DELETE_STARTINGWORK_ON_DOCUMENT';
export const GET_FINAL_VERSION_OF_DOCUMENT_ADOPTED_IN_PARLAMENT =
  'GET_FINAL_VERSION_OF_DOCUMENT_ADOPTED_IN_PARLAMENT';
export const GET_FINAL_VERSION_OF_DOCUMENT_ADOPTED_IN_PARLAMENT_LIST =
  'GET_FINAL_VERSION_OF_DOCUMENT_ADOPTED_IN_PARLAMENT_LIST';
export const DELETE_FINAL_VERSION_OF_DOCUMENT_ADOPTED_IN_PARLAMENT =
  'DELETE_FINAL_VERSION_OF_DOCUMENT_ADOPTED_IN_PARLAMENT';
export const GET_FINAL_VERSION_OF_DOCUMENT = 'GET_FINAL_VERSION_OF_DOCUMENT';
export const GET_FINAL_VERSION_OF_DOCUMENT_LIST =
  'GET_FINAL_VERSION_OF_DOCUMENT_LIST';
export const DELETE_FINAL_VERSION_OF_DOCUMENT =
  'DELETE_FINAL_VERSION_OF_DOCUMENT';
export const GET_CONSULTATIVE_PROCESS = 'GET_CONSULTATIVE_PROCESS';
export const GET_CONSULTATIVE_PROCESS_LIST = 'GET_CONSULTATIVE_PROCESS_LIST';
export const DELETE_CONSULTATIVE_PROCESS = 'DELETE_CONSULTATIVE_PROCESS';
export const CREATE_CONSULTATIVE_PROCESS = 'CREATE_CONSULTATIVE_PROCESS';
export const UPDATE_CONSULTATIVE_PROCESS = 'UPDATE_CONSULTATIVE_PROCESS';
export const GET_INSTITUTION = 'GET_INSTITUTION';
export const GET_INSTITUTION_LIST = 'GET_INSTITUTION_LIST';
export const GET_INSTITUTION_LIST_FILTERED = 'GET_INSTITUTION_LIST_FILTERED';
export const DELETE_INSTITUTION = 'DELETE_INSTITUTION';
export const SET_INSTITUTION_LIST_TABLE_FILTER = 'SET_INSTITUTION_LIST_TABLE_FILTER';
export const GET_MAIL_SETTINGS = 'GET_MAIL_SETTINGS';
export const GET_MAIL_SETTINGS_LIST = 'GET_MAIL_SETTINGS_LIST';
export const DELETE_MAIL_SETTINGS = 'DELETE_MAIL_SETTINGS';
export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const DELETE_ROLE = 'DELETE_ROLE';
export const GET_SURVEY = 'GET_SURVEY';
export const GET_SURVEY_LIST = 'GET_SURVEY_LIST';
export const DELETE_SURVEY = 'DELETE_SURVEY';
export const CREATE_SURVEY = 'CREATE_SURVEY';
export const UPDATE_SURVEY = 'UPDATE_SURVEY';
export const GET_SURVEY_DTO = 'GET_SURVEY_DTO';
export const UPDATE_SURVEY_WITH_ATTACHMENT = 'UPDATE_SURVEY_WITH_ATTACHMENT';
export const GET_SURVEY_ATTACHMENT_LIST = 'GET_SURVEY_ATTACHMENT_LIST';
export const DELETE_SURVEY_ATTACHMENT = 'DELETE_SURVEY_ATTACHMENT';
export const GET_SURVEY_ANSWERS = 'GET_SURVEY_ANSWERS';
export const GET_SURVEY_ANSWERS_FILTERABLE = "GET_SURVEY_ANSWERS_FILTERABLE";
export const GET_SURVEY_ANSWERS_LIST = 'GET_SURVEY_ANSWERS_LIST';
export const GET_SURVEY_CLOSED_ANSWERS_LIST = 'GET_SURVEY_CLOSED_ANSWERS_LIST';
export const DELETE_SURVEY_ANSWERS = 'DELETE_SURVEY_ANSWERS';
export const GET_EVENT_TYPE = 'GET_EVENT_TYPE';
export const GET_EVENT_TYPE_LIST = 'GET_EVENT_TYPE_LIST';
export const GET_EVENT_PARTICIPANT = 'GET_EVENT_PARTICIPANT';
export const GET_EVENT_PARTICIPANT_LIST='GET_EVENT_PARTICIPANT_LIST';
export const DELETE_EVENT_PARTICIPANT ='DELETE_EVENT_PARTICIPANT';
export const UNSUBSCRIBE_FROM_EVENT_EVENT='UNSUBSCRIBE_FROM_EVENT_EVENT';
export const DELETE_EVENT_TYPE = 'DELETE_EVENT_TYPE';
export const GET_OGP = 'GET_OGP';
export const GET_OGP_LIST = 'GET_OGP_LIST';
export const GET_OGP_LIST_PAGEABLE = 'GET_OGP_LIST_PAGEABLE';
export const DELETE_OGP = 'DELETE_OGP';
export const UPDATE_OGP = 'UPDATE_OGP';
export const GET_OGP_DESCRIPTION = 'GET_OGP_DESCRIPTION';
export const GET_OGP_DESCRIPTION_LIST = 'GET_OGP_DESCRIPTION_LIST';
export const CREATE_OGP_DESCRIPTION = 'CREATE_OGP_DESCRIPTION';
export const GET_PUBLIC_DEBATE = 'GET_PUBLIC_DEBATE';
export const GET_PUBLIC_DEBATE_LIST = 'GET_PUBLIC_DEBATE_LIST';
export const DELETE_PUBLIC_DEBATE = 'DELETE_PUBLIC_DEBATE';
export const CREATE_PUBLIC_DEBATE = 'CREATE_PUBLIC_DEBATE';
export const UPDATE_PUBLIC_DEBATE = 'UPDATE_PUBLIC_DEBATE';
export const GET_REPLY_ON_COMMENT = 'GET_REPLY_ON_COMMENT';
export const GET_REPLY_ON_COMMENT_LIST = 'GET_REPLY_ON_COMMENT_LIST';
export const DELETE_REPLY_ON_COMMENT = 'DELETE_REPLY_ON_COMMENT';
export const UPDATE_REPLY_ON_COMMENT = 'UPDATE_REPLY_ON_COMMENT';
export const GET_SURVEY_QUESTIONS = 'GET_SURVEY_QUESTIONS';
export const GET_SURVEY_QUESTIONS_LIST = 'GET_SURVEY_QUESTIONS_LIST';
export const GET_SURVEY_QUESTION_TYPE_LIST = 'GET_SURVEY_QUESTION_TYPE_LIST';
export const DELETE_SURVEY_QUESTIONS = 'DELETE_SURVEY_QUESTIONS';
export const GET_STARTING_POINTS_DOCUMENT = 'GET_STARTING_POINTS_DOCUMENT';
export const GET_STARTING_POINTS_DOCUMENTS_LIST =
  'GET_STARTING_POINTS_DOCUMENTS_LIST';
export const DELETE_STARTING_POINTS_DOCUMENT =
  'DELETE_STARTING_POINTS_DOCUMENT';
export const GET_STARTING_WORK_ON_DOCUMENT_FILE =
  'GET_STARTING_WORK_ON_DOCUMENT_FILE';
export const GET_STARTING_WORK_ON_DOCUMENT_FILE_LIST =
  'GET_STARTING_WORK_ON_DOCUMENT_FILE_LIST';
export const DELETE_STARTING_WORK_ON_DOCUMENT_FILE =
  'DELETE_STARTING_WORK_ON_DOCUMENT_FILE';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const REMOVE_DOCUMENT = 'REMOVE_DOCUMENT';
export const GET_CONSULTATIVE_PROCESS_FILE = 'GET_CONSULTATIVE_PROCESS_FILE';
export const GET_CONSULTATIVE_PROCESS_FILE_LIST =
  'GET_CONSULTATIVE_PROCESS_FILE_LIST';
export const DELETE_CONSULTATIVE_PROCESS_FILE =
  'DELETE_CONSULTATIVE_PROCESS_FILE';
export const GET_PUBLIC_DEBATE_FILE = 'GET_PUBLIC_DEBATE_FILE';
export const GET_PUBLIC_DEBATE_FILE_LIST = 'GET_PUBLIC_DEBATE_FILE_LIST';
export const DELETE_PUBLIC_DEBATE_FILE = 'DELETE_PUBLIC_DEBATE_FILE';
export const SET_USER_ROLES = 'SET_USER_ROLES';
export const ACCEPT_COOKIES_POLICY = 'ACCEPT_COOKIES_POLICY';
export const SET_ROLE_OF_LOGGED_USER = 'SET_ROLE_OF_LOGGED_USER';
export const GET_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST =
  'GET_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST';
export const GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST =
  'GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST';
export const GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST_DTO =
  'GET_TOPIC_OF_DISCUSSION__FIELDS_OF_INTEREST_LIST_DTO';
export const GET_OGP_FIELDS_OF_INTEREST = 'GET_OGP_FIELDS_OF_INTEREST';
export const GET_OGP_FIELDS_OF_INTEREST_LIST =
  'GET_OGP_FIELDS_OF_INTEREST_LIST';
export const GET_TOPIC_OF_DISCUSSION_INSTITUTIONS =
  'GET_TOPIC_OF_DISCUSSION_INSTITUTIONS';
export const GET_TOPIC_OF_DISCUSSION__INSTITUTIONS_LIST =
  'GET_TOPIC_OF_DISCUSSION__INSTITUTIONS_LIST';
export const GET_PANEL = 'GET_PANEL';
export const GET_PANEL_LIST = 'GET_PANEL_LIST';
export const DELETE_PANEL = 'DELETE_PANEL';
export const CREATE_PANEL = 'CREATE_PANEL';
export const UPDATE_PANEL = 'UPDATE_PANEL';
export const GET_PANEL_PARTICIPANT = 'GET_PANEL_PARTICIPANT';
export const GET_PANEL_PARTICIPANT_LIST = 'GET_PANEL_PARTICIPANT_LIST';
export const DELETE_PANEL_PARTICIPANT = 'DELETE_PANEL_PARTICIPANT';
export const UNSUBSCRIBE_FROM_PANEL_EVENT = 'UNSUBSCRIBE_FROM_PANEL_EVENT';
export const GET_PANEL_DOCUMENT = 'GET_PANEL_DOCUMENT';
export const GET_PANEL_DOCUMENT_LIST = 'GET_PANEL_DOCUMENT_LIST';
export const DELETE_PANEL_DOCUMENT = 'DELETE_PANEL_DOCUMENT';
export const GET_FOCUS_GROUP = 'GET_FOCUS_GROUP';
export const GET_FOCUS_GROUP_LIST = 'GET_FOCUS_GROUP_LIST';
export const DELETE_FOCUS_GROUP = 'DELETE_FOCUS_GROUP';
export const CREATE_FOCUS_GROUP = 'CREATE_FOCUS_GROUP';
export const UPDATE_FOCUS_GROUP = 'UPDATE_FOCUS_GROUP';
export const GET_FOCUS_GROUP_DOCUMENT = 'GET_FOCUS_GROUP_DOCUMENT';
export const GET_FOCUS_GROUP_DOCUMENT_LIST = 'GET_FOCUS_GROUP_DOCUMENT_LIST';
export const DELETE_FOCUS_GROUP_DOCUMENT = 'DELETE_FOCUS_GROUP_DOCUMENT';
export const GET_FOCUS_GROUP_PARTICIPANT = 'GET_FOCUS_GROUP_PARTICIPANT';
export const GET_FOCUS_GROUP_PARTICIPANT_LIST =
  'GET_FOCUS_GROUP_PARTICIPANT_LIST';
export const DELETE_FOCUS_GROUP_PARTICIPANT = 'DELETE_FOCUS_GROUP_PARTICIPANT';
export const UNSUBSCRIBE_FROM_FOCUS_GROUP_EVENT =
  'UNSUBSCRIBE_FROM_FOCUS_GROUP_EVENT';
export const GET_ROUND_TABLE = 'GET_ROUND_TABLE';
export const GET_ROUND_TABLE_LIST = 'GET_ROUND_TABLE_LIST';
export const DELETE_ROUND_TABLE = 'DELETE_ROUND_TABLE';
export const CREATE_ROUND_TABLE = 'CREATE_ROUND_TABLE';
export const UPDATE_ROUND_TABLE = 'UPDATE_ROUND_TABLE';
export const GET_ROUND_TABLE_DOCUMENT = 'GET_ROUND_TABLE_DOCUMENT';
export const GET_ROUND_TABLE_DOCUMENT_LIST = 'GET_ROUND_TABLE_DOCUMENT_LIST';
export const DELETE_ROUND_TABLE_DOCUMENT = 'DELETE_ROUND_TABLE_DOCUMENT';
export const GET_ROUND_TABLE_PARTICIPANT = 'GET_ROUND_TABLE_PARTICIPANT';
export const GET_ROUND_TABLE_PARTICIPANT_LIST =
  'GET_ROUND_TABLE_PARTICIPANT_LIST';
export const DELETE_ROUND_TABLE_PARTICIPANT = 'DELETE_ROUND_TABLE_PARTICIPANT';
export const UNSUBSCRIBE_FROM_ROUND_TABLE_EVENT =
  'UNSUBSCRIBE_FROM_ROUND_TABLE_EVENT';
export const GET_NOTIFICATION_LIST = 'GET_NOTIFICATION_LIST';
export const GET_OTHER_EVENTS = 'GET_OTHER_EVENTS';
export const GET_OTHER_EVENTS_LIST = 'GET_OTHER_EVENTS_LIST';
export const DELETE_OTHER_EVENTS = 'DELETE_OTHER_EVENTS';
export const CREATE_OTHER_EVENTS = 'CREATE_OTHER_EVENTS';
export const UPDATE_OTHER_EVENTS = 'UPDATE_OTHER_EVENTS';
export const GET_OTHER_EVENTS_DOCUMENT = 'GET_OTHER_EVENTS_DOCUMENT';
export const GET_OTHER_EVENTS_DOCUMENT_LIST = 'GET_OTHER_EVENTS_DOCUMENT_LIST';
export const DELETE_OTHER_EVENTS_DOCUMENT = 'DELETE_OTHER_EVENTS_DOCUMENT';
export const GET_OTHER_EVENTS_PARTICIPANT = 'GET_OTHER_EVENTS_PARTICIPANT';
export const GET_OTHER_EVENTS_PARTICIPANT_LIST =
  'GET_OTHER_EVENTS_PARTICIPANT_LIST';
export const DELETE_OTHER_EVENTS_PARTICIPANT =
  'DELETE_OTHER_EVENTS_PARTICIPANT';
export const UNSUBSCRIBE_FROM_OTHER_EVENTS_EVENT =
  'UNSUBSCRIBE_FROM_OTHER_EVENTS_EVENT';
export const GET_USER_ORGANIZATION = 'GET_USER_ORGANIZATION';
export const GET_USER_ORGANIZATION_LIST = 'GET_USER_ORGANIZATION_LIST';
export const DELETE_USER_ORGANIZATION = 'DELETE_USER_ORGANIZATION';
export const CREATE_USER_ORGANIZATION = 'CREATE_USER_ORGANIZATION';
export const UPDATE_USER_ORGANIZATION = 'UPDATE_USER_ORGANIZATION';
export const CREATE_USER_ORGANIZATION_CATEGORY_SUCCESS = 'CREATE_USER_ORGANIZATION_CATEGORY_SUCCESS';
export const UPDATE_USER_ORGANIZATION_CATEGORY_SUCCESS = 'UPDATE_USER_ORGANIZATION_CATEGORY_SUCCESS';
export const USER_ORGANIZATION_CRUD_IN_PROGRESS =  'USER_ORGANIZATION_CRUD_IN_PROGRESS';
export const USER_ORGANIZATION_CRUD_COMPLEATED =  'USER_ORGANIZATION_CRUD_COMPLEATED';
export const GET_COMMENT_DOCUMENT = 'GET_COMMENT_DOCUMENT';
export const GET_COMMENT_DOCUMENT_LIST = 'GET_COMMENT_DOCUMENT_LIST';
export const DELETE_COMMENT_DOCUMENT = 'DELETE_COMMENT_DOCUMENT';

export const GET_AD_DOCUMENT = 'GET_AD_DOCUMENT';
export const GET_AD_DOCUMENT_LIST = 'GET_AD_DOCUMENT_LIST';
export const DELETE_AD_DOCUMENT = 'DELETE_AD_DOCUMENT';
export const UPLOAD_AD_DOCUMENT = 'UPLOAD_AD_DOCUMENT';

export const GET_OGP_DOCUMENT = 'GET_OGP_DOCUMENT';
export const GET_OGP_DOCUMENT_LIST = 'GET_OGP_DOCUMENT_LIST';
export const DELETE_OGP_DOCUMENT = 'DELETE_OGP_DOCUMENT';

export const GET_OGP_STEP = 'GET_OGP_STEP';
export const GET_OGP_STEP_LIST = 'GET_OGP_STEP_LIST';
export const DELETE_OGP_STEP = 'DELETE_OGP_STEP';
export const CREATE_OGP_STEP = 'CREATE_OGP_STEP';
export const UPDATE_OGP_STEP = 'UPDATE_OGP_STEP';

export const SET_UMCN = 'SET_UMCN';


export const GET_USER_ORGANIZATION_CATEGORY = 'GET_USER_ORGANIZATION_CATEGORY';
export const GET_USER_ORGANIZATION_CATEGORY_LIST =
  'GET_USER_ORGANIZATION_CATEGORY_LIST';
export const DELETE_USER_ORGANIZATION_CATEGORY =
  'DELETE_USER_ORGANIZATION_CATEGORY';
export const CREATE_OGP_ARCHIVE = 'CREATE_OGP_ARCHIVE';
export const GET_OGP_ARCHIVE_LIST = 'GET_OGP_ARCHIVE_LIST';
export const GET_OGP_ARCHIVE = 'GET_OGP_ARCHIVE';

export const GET_GENERAL_POST_LIST = 'GET_GENERAL_POST_LIST';
export const CREATE_GENERAL_POST = 'CREATE_GENERAL_POST';
export const UPDATE_GENERAL_POST = 'UPDATE_GENERAL_POST';
export const DELETE_GENERAL_POST = 'DELETE_GENERAL_POST';
export const GET_GENERAL_POST = 'GET_GENERAL_POST';
export const UPDATE_GENERAL_POST_WITH_ATTACHMENT =
  'UPDATE_GENERAL_POST_WITH_ATTACHMENT';
export const GET_GENERAL_POST_ATTACHMENT_LIST =
  'GET_GENERAL_POST_ATTACHMENT_LIST';
export const DELETE_GENERAL_POST_ATTACHMENT = 'DELETE_GENERAL_POST_ATTACHMENT';
export const SAVE_DOCUMENT_ON_DISK =
  'SAVE_DOCUMENT_ON_DISK';
export const GET_AD = 'GET_AD';
export const DELETE_AD = 'DELETE_AD';
export const GET_AD_LIST = 'GET_AD_LIST';
export const CREATE_AD = 'CREATE_AD';
export const UPDATE_AD = 'UPDATE_AD';
export const GET_ALL_MODERATORS = 'GET_ALL_MODERATORS';
export const SET_USER_LIST_TABLE_FILTER = 'SET_USER_LIST_TABLE_FILTER';

export const GET_ALL_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST_LIST_BY_USER_ID =
  'GET_ALL_TOPIC_OF_DISCUSSION_FIELDS_OF_INTEREST_LIST_BY_USER_ID';
export const GET_ALL_TOPIC_OF_DISCUSSION_INSTITUTION_LIST_BY_USER_ID =
  'GET_ALL_TOPIC_OF_DISCUSSION_INSTITUTION_LIST_BY_USER_ID';

export const GET_FIELD_OF_INTEREST_INSTITUTION_USER =
  'GET_FIELD_OF_INTEREST_INSTITUTION_USER';

export const DELETE_TOPIC_OF_DISCUSSION_PARTICIPANT =
  'DELETE_TOPIC_OF_DISCUSSION_PARTICIPANT';
export const UNSUBSCRIBE_FROM_TOPIC_OF_DISCUSSION =
  'UNSUBSCRIBE_FROM_TOPIC_OF_DISCUSSION';
export const GET_TOPIC_OF_DISCUSSION_PARTICIPANT_LIST =
  'GET_TOPIC_OF_DISCUSSION_PARTICIPANT_LIST';

export const GET_OGP_REPORT_FILE = 'GET_OGP_REPORT_FILE';
export const GET_OGP_REPORT_FILE_LIST = 'GET_OGP_REPORT_FILE_LIST';
export const DELETE_OGP_REPORT_FILE = 'DELETE_OGP_REPORT_FILE';

export const GET_ACTION_PLAN_REPORT_LIST = 'GET_ACTION_PLAN_REPORT_LIST';
export const GET_ACTION_PLAN_REPORT = 'GET_ACTION_PLAN_REPORT';
export const DELETE_ACTION_PLAN_REPORT = 'DELETE_ACTION_PLAN_REPORT';

export const GET_OGP_COMMENT = 'GET_OGP_COMMENT';
export const GET_OGP_COMMENT_LIST = 'GET_OGP_COMMENT_LIST';
export const DELETE_OGP_COMMENT = 'DELETE_OGP_COMMENT';
export const CREATE_OGP_COMMENT = 'CREATE_OGP_COMMENT';
export const GET_OGP_COMMENT_MAX_ID = 'GET_OGP_COMMENT_MAX_ID';
export const UPDATE_OGP_COMMENT = 'UPDATE_OGP_COMMENT';
export const GET_OGP_COMMENT_LIST_DTO = 'GET_OGP_COMMENT_LIST_DTO';

export const GET_NOTIFICATIONS_COUNTERS = 'GET_NOTIFICATIONS_COUNTERS';

export const GET_TOPICS_OF_DISCUSSION_BY_USER_ID =
  'GET_TOPIC_OF_DISCUSSION_BY_USER_ID';
export const GET_NOTIFICATION_BY_TOPIC_OF_DISCUSSION_ID =
  'GET_NOTIFICATION_BY_TOPIC_OF_DISCUSSION_ID';

export const GET_COMMENT_GATHERING = 'GET_COMMENT_GATHERING';
export const GET_COMMENT_GATHERING_LIST = 'GET_COMMENT_GATHERING_LIST';
export const DELETE_COMMENT_GATHERING_ATTACHMENT =
  'DELETE_COMMENT_GATHERING_ATTACHMENT';
export const UPDATE_COMMENT_GATHERING_WITH_ATTACHMENT =
  'UPDATE_COMMENT_GATHERING_WITH_ATTACHMENT';
export const GET_COMMENT_GATHERING_ATTACHMENT_LIST =
  'GET_COMMENT_GATHERING_ATTACHMENT_LIST';
export const UPDATE_COMMENT_GATHERING = 'UPDATE_COMMENT_GATHERING';
export const UPDATE_COMMENT_GATHERING_ATTACHMENT = 'UPDATE_COMMENT_GATHERING_ATTACHMENT';

export const GET_TOPIC_OF_DISCUSSION_MODERATOR_DTO =
  'GET_TOPIC_OF_DISCUSSION_MODERATOR_DTO';

export const GET_FIELD_OF_INTEREST_BY_USER_ID_LIST =
  'GET_FIELD_OF_INTEREST_BY_USER_ID_LIST';

export const GET_INSTITUTION_BY_USER_ID_LIST =
  'GET_INSTITUTION_BY_USER_ID_LIST';

export const GET_NOTIFICATIONS_BY_FIELD_OF_INTEREST_LIST =
  'GET_NOTIFICATIONS_BY_FIELD_OF_INTEREST_LIST';

export const GET_NOTIFICATIONS_BY_INSTITUTION_LIST =
  'GET_NOTIFICATIONS_BY_INSTITUTION_LIST';

export const GET_MODERATOR_RESOURCES = 'GET_MODERATOR_RESOURCES';
export const GET_MODERATOR_RESOURCES_LIST = 'GET_MODERATOR_RESOURCES_LIST';
export const DELETE_MODERATOR_RESOURCES = 'DELETE_MODERATOR_RESOURCES';

export const GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS =
  'GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS';
export const GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_LIST =
  'GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_LIST';
export const DELETE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_ATTACHMENT =
  'DELETE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_ATTACHMENT';
export const UPLOAD_AND_SAVE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_WITH_ATTACHMENT =
  'UPLOAD_AND_SAVE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_WITH_ATTACHMENT';
export const GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_ATTACHMENT_LIST =
  'GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_ATTACHMENT_LIST';
export const UPLOAD_AND_SAVE_INVOLVEMENT_OF_EXTERNAL_MEMBERS =
  'UPLOAD_AND_SAVE_INVOLVEMENT_OF_EXTERNAL_MEMBERS';

export const GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION =
  'GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION';
export const GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION_LIST =
  'GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION_LIST';
export const DELETE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION_ATTACHMENT =
  'DELETE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION_ATTACHMENT';
export const UPLOAD_AND_SAVE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION_WITH_ATTACHMENT =
  'UPLOAD_AND_SAVE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION_WITH_ATTACHMENT';
export const GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION_ATTACHMENT_LIST =
  'GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION_ATTACHMENT_LIST';
export const UPLOAD_AND_SAVE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION =
  'UPLOAD_AND_SAVE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_APPLICATION';

export const GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS =
  'GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS';
export const DELETE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS_ATTACHMENT =
  'DELETE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS_ATTACHMENT';
export const CREATE_OR_UPDATE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS_WITH_ATTACHMENT =
  'CREATE_OR_UPDATE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS_WITH_ATTACHMENT';
export const GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS_ATTACHMENT_LIST =
  'GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS_ATTACHMENT_LIST';
export const UPDATE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS =
  'UPDATE_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS';
export const GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS_LIST =
  'GET_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS_LIST';

export const GET_ALL_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS_ATTACHMENT_LIST =
  ' GET_ALL_INVOLVEMENT_OF_EXTERNAL_MEMBERS_RESULTS_ATTACHMENT_LIST';

export const CLEAR_SURVEY_QUESTION = 'CLEAR_SURVEY_QUESTION';

export const GET_OGP_ACTION_PLAN_ARCHIVE = 'GET_OGP_ACTION_PLAN_ARCHIVE';
export const GET_OGP_ACTION_PLAN_ARCHIVE_LIST =
  'GET_OGP_ACTION_PLAN_ARCHIVE_LIST';
export const DELETE_OGP_ACTION_PLAN_ARCHIVE = 'DELETE_OGP_ACTION_PLAN_ARCHIVE';
export const GET_DICTIONARY  = 'GET_DICTIONARY'
export const UPLOAD_DICTIONARY  = 'UPLOAD_DICTIONARY'
export const GET_TAXONOMIES  = 'GET_TAXONOMIES';
export const GET_ERROR_LOG='GET_ERROR_LOG';
export const GET_EVENT='GET_EVENT';
export const GET_EVENT_LIST='GET_EVENT_LIST';
export const DELETE_EVENT='DELETE_EVENT';
export const CREATE_EVENT='CREATE_EVENT';
export const UPDATE_EVENT='UPDATE_EVENT';
export const GET_EVENT_DOCUMENT = 'GET_EVENT_DOCUMENT';
export const GET_EVENT_DOCUMENT_LIST = 'GET_EVENT_DOCUMENT_LIST';
export const DELETE_EVENT_DOCUMENT = 'DELETE_EVENT_DOCUMENT';
export const GET_COMMENT_LIST_ON_DOCUMENT = 'GET_COMMENT_LIST_ON_DOCUMENT';

export const GET_METHOD = "GET_METHOD";
export const GET_METHOD_LIST = "GET_METHOD_LIST";
export const CREATE_METHOD = "CREATE_METHOD";
export const UPDATE_METHOD = "UPDATE_METHOD";
export const PIN_METHOD = "PIN_METHOD";
export const UNPIN_METHOD = "UNPIN_METHOD";
export const PUBLISH_METHOD = "PUBLISH_METHOD";
export const GET_METHOD_SURVEY_DTO = "GET_METHOD_SURVEY_DTO"
export const GET_METHOD_DOCUMENTS = "GET_METHOD_DOCUMENTS"
export const DELETE_METHOD_DOCUMENT = "DELETE_METHOD_DOCUMENT"
export const GET_METHOD_LIST_FOR_SELECT = "GET_METHOD_LIST_FOR_SELECT"
export const CREATE_METHOD_PARTICIPANT = "CREATE_METHOD_PARTICIPANT"
export const GET_METHOD_PARTICIPANTS = "GET_METHOD_PARTICIPANTS"
export const GET_METHOD_PARTICIPANT_DOCUMENT = "GET_METHOD_PARTICIPANT_DOCUMENT"
export const RESET_METHOD_PARTICIPANT_DOCUMENT = "RESET_METHOD_PARTICIPANT_DOCUMENT"

export const GET_PHASE_LIST = "GET_PHASE_LIST";
export const CREATE_PHASE = "CREATE_PHASE";
export const RESET_CREATED_PHASE = "RESET_CREATED_PHASE"
export const UPDATE_PHASE = "UPDATE_PHASE"

export const GET_RELATED_TOPIC_OF_DISCUSSION_LIST = "GET_RELATED_TOPIC_OF_DISCUSSION_LIST";
export const GET_TOPICS_OF_DISCUSSION_BY_FIELDS_OF_INTEREST = "GET_TOPICS_OF_DISCUSSION_BY_FIELDS_OF_INTEREST";
export const GET_IS_TOPIC_OF_DISCUSSION_HIDDEN = "GET_IS_TOPIC_OF_DISCUSSION_HIDDEN";

export const SAVE_MANUAL_DOCUMENT="SAVE_MANUAL_DOCUMENT"
export const GET_OTHER_TOPIC_OF_DISCUSSION_LIST_FILTERABLE="GET_OTHER_TOPIC_OF_DISCUSSION_LIST_FILTERABLE"