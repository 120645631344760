import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UnauthorizedAccess from "./unauthorizedAccess";
import setAuthUserRole from "./setAuthUserRole";

const SecuredRouteModerator = ({
  component: Component,
  security,
  ...otherProps
}) => (
  <>
  {setAuthUserRole(security.authRole)}
  <Route
    {...otherProps}
    render={(props) =>
      security.authRole == 2 ? (
        <Component {...props} />
      ) : (
        <UnauthorizedAccess />
      )
    }
  />
  </>
);

SecuredRouteModerator.propTypes = {
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
});

export default connect(mapStateToProps, {})(SecuredRouteModerator);
