import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CommentIcon from '@mui/icons-material/Comment';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ViewAttachedPdfDocumentWithHighlights from '../Reusable/ViewAttachedPdfDocumentWithHighlights';
import { useState } from 'react';
import { findMethodById } from "../../actions/methodActions";
import { getMethodList } from '../../actions/methodActions';
import { getMethodListAnon } from '../../actions/methodActions';
import { trackPromise } from 'react-promise-tracker';
import ModalForMethodComments from '../CommentDisplay/ModalForMethodComments';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ModalForMethodDocuments from '../MethodDocument/ModalForMethodDocuments';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';



const CommentGatheringActionBar = ({method, topicOfDiscussionId, phase, authRole}) => {
    const dictionary = useSelector(state => state.startUp.dictionary);
    const user = useSelector(state => state.security);

    const dispatch = useDispatch();

    const [showModalViewAttachedPdfDocumentWithHighlights, setShowModalViewAttachedPdfDocumentWithHighlights] = useState(false);
    const [showModalForMethodComments, setShowModalForMethodComments] = useState(false);
    const [showModalForMethodDocuments, setShowModalForMethodDocuments] = useState(false);
    const [showModalForDocumentPreview, setShowModalForDocumentPreview] = useState(false);

    const handleShowModalForDocumentPreview = (id) => {
        dispatch(findMethodById(id));
        setShowModalForDocumentPreview(true);
    };
    const handleCloseModalForDocumentPreview = () => {
        setShowModalForDocumentPreview(false);
    };

    const handleShowModalForMethodDocuments = () => {
        setShowModalForMethodDocuments(true);
    }
    const handleCloseModalForMethodDocuments = (id) => {
        setShowModalForMethodDocuments(false)
    }

    const handleShowModalViewAttachedPdfDocumentWithHighlights = (id) => {
        dispatch(findMethodById(id));
        setShowModalViewAttachedPdfDocumentWithHighlights(true);
        
    }

    const handleCloseModalViewAttachedPdfDocumentWithHighlights = async () => {
        if (Object.keys(user.authUser).length != 0) {
            await trackPromise(dispatch(getMethodList(topicOfDiscussionId, phase?.id)))
        }
        else {
            await trackPromise(dispatch(getMethodListAnon(topicOfDiscussionId, phase?.id)))
        }
        setShowModalViewAttachedPdfDocumentWithHighlights(false)
    }
    const handleShowModalForMethodComments = () => {
        setShowModalForMethodComments(true);
    }
    const handleCloseModalForMethodComments = () => {
        setShowModalForMethodComments(false);
    }
    return (
        <>   
        
            { method.additionalDocumentNumber == 1 &&
                <Link onClick={(e) => {
                    e.preventDefault();
                    handleShowModalForDocumentPreview(method.id);
                }} 
                to="#" 
                className="tableFooterLinks method-links">
                    <FindInPageIcon/>
                     
                    <span>{dictionary["Method.Prilog"]}</span>
                </Link> 
            }
            { method.additionalDocumentNumber > 1 &&
                <Link onClick={(e) => {
                    e.preventDefault();
                    handleShowModalForMethodDocuments(method.id);
                }} 
                to="#" 
                className="tableFooterLinks method-links">
                    <FindInPageIcon/>
                     
                    <span>{dictionary["Method.Prilozi"]}</span>
                </Link> 
            }
           { method.documentNumber == 1  &&
            ((authRole == 3 && method.active) ? 
                <Link onClick={(e) => {
                    e.preventDefault();
                    handleShowModalViewAttachedPdfDocumentWithHighlights(method.id);
                }} 
                to="#" 
                className="tableFooterLinks method-links">
                    <AddCommentIcon/>
                    <span>{dictionary["Method.Komentarisi"]}</span>
                </Link> :
                <Link onClick={(e) => {
                    e.preventDefault();
                    handleShowModalViewAttachedPdfDocumentWithHighlights(method.id);
                }} 
                    to="#" 
                    className="tableFooterLinks method-links">
                        <SearchIcon/>
                        <span>{dictionary["Method.Pogledaj"]}</span>
                </Link>
            )
            }

            <Link onClick={(e) => {
                    e.preventDefault();
                    handleShowModalForMethodComments();
                }} 
                to="#" 
                className="tableFooterLinks method-links">
                    <CommentIcon/>
                    <span>{dictionary["Method.Komentari"]}</span>
                </Link>

                {showModalViewAttachedPdfDocumentWithHighlights && (
                        <ViewAttachedPdfDocumentWithHighlights
                            show={showModalViewAttachedPdfDocumentWithHighlights}
                            handleClose={handleCloseModalViewAttachedPdfDocumentWithHighlights}
                            topicOfDiscussionId={topicOfDiscussionId}
                            phaseId={phase?.id}
                            methodId={method.id}
                            methodActive={method.active}
                            phaseActiveStatus={phase?.active}
                            authRole={authRole}
                            documentName={method?.documents[0]?.uuidDocumentName}
                            documentNameText={method?.documents[0]?.documentName}
                        />
                        )}
                {showModalForMethodComments && (
                    <ModalForMethodComments
                        show={showModalForMethodComments}
                        handleClose={handleCloseModalForMethodComments}
                        uuidDocumentName={method?.documents[0]?.uuidDocumentName}
                        methodId={method?.id}
                    />
                )}
                {showModalForMethodDocuments &&
                    <ModalForMethodDocuments 
                        show={showModalForMethodDocuments}
                        handleClose={handleCloseModalForMethodDocuments}
                        method={method}
                        phase={phase}
                        topicOfDiscussionId={topicOfDiscussionId}
                    />
                    } 
                {showModalForDocumentPreview && (
                    <ModalForDocumentPreview
                        show={showModalForDocumentPreview}
                        documentName={method?.additionalDocuments[0]?.uuidDocumentName}
                        controllerReference={'document'}
                        handleClose={handleCloseModalForDocumentPreview}
                    />
                )}
    </>
    );
}

export default CommentGatheringActionBar;