import {
    GET_ROLE,
    GET_ROLE_LIST,
    DELETE_ROLE,
} from "../actions/types";

const initialState = {
    roleList: [],
    role: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ROLE_LIST:
            return {
                ...state,
                roleList: action.payload,
            };
        case GET_ROLE:
            return {
                ...state,
                role: action.payload,
            };
        case DELETE_ROLE:
            return {
                ...state,
                roleList: state.roleList.filter(
                    (r) => r.id !== action.payload
                ),
            };

        default:
            return state;
    }
}
