import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tr, Td } from 'react-super-responsive-table';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { connect } from 'react-redux';

class TopicOfDiscussionDashboardRow extends Component {
  constructor(props) {
    super();
    this.state = {
      subscribed: props.topic.subscribed,
    };
  }

  onSubscribeClick = (topicOfDiscussionId) => {
    const newTopicOfDiscussionParticipant = {
      topicOfDiscussion: {
        id: topicOfDiscussionId,
      },
    };
    this.setState({ subscribed: true });
    this.props
      .createTopicOfDiscussionParticipant(newTopicOfDiscussionParticipant)
      .then(this.props.handleTopicParticipantRefreash);
  };

  onUnsubscribeClick = (topicOfDiscussionId) => {
    const action = () => {
      this.setState({ subscribed: false });
      this.props
        .unsubscribeFromTopicOfDiscussion(topicOfDiscussionId)
        .then(this.props.handleTopicParticipantRefreash);
    };
    confirmDialog(`${this.props.dictionary["TopicOfDiscussionDashboardRow.Odjava.Poruka"]}`, action, `${this.props.dictionary["TopicOfDiscussionDashboardRow.Odjava"]}`);
  };

  render() {
    let cssLiStyle = 'topicTitleText textShadow mb-2 ';
    return this.props.quickPreviewSignal != 1 ? (
      <tr className="component-wrapper-fade-in-large-list">
        <td className="border-0">
          <Link
            to={`/topicOfDiscussionPage/${this.props.topic.id}/${1}`}
            className="remove-link-effect"
          >
            <div>
              <li className={cssLiStyle}>{this.props.topic.nameOfTopic}</li>
            </div>
          </Link>
        </td>
        {this.props.isUserModerator == false &&
          this.props.topic.endProcess == false && (
            <td width="89px" className="text-center subscribe-td">
              {this.props.topic.subscribed === true ? (
                <Link
                  onClick={(e) => {e.preventDefault(); this.onUnsubscribeClick(this.props.topic.id);}}
                  className="unsubscribe-button small-font"
                  to="#"
                >
                  {this.props.dictionary["TopicOfDiscussionDashboardRow.OdjaviSe"]}
                </Link>
              ) : (
                <Link
                  onClick={(e) => {e.preventDefault(); this.onSubscribeClick(this.props.topic.id)}}
                  className="create-button small-font"
                  to="#"
                >
                  {this.props.dictionary["TopicOfDiscussionDashboardRow.PrijaviSe"]}
                </Link>
              )}
            </td>
          )}
      </tr>
    ) : (
      <Tr className="component-wrapper-fade-in-large-list">
        <Td className="border-0">
          <Link
            to={`/topicOfDiscussionPage/${this.props.topic.id}/${1}`}
            className="remove-link-effect"
          >
            <div>
              <li className={cssLiStyle}>{this.props.topic.nameOfTopic}</li>
            </div>
          </Link>
        </Td>
      </Tr>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary
});
export default connect(mapStateToProps,{}) (TopicOfDiscussionDashboardRow);
