import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import Input from '../Reusable/Input';

class ModalForAvailableAnswers extends React.Component {
  constructor(props) {
    super();
    this.state = {
      selectedAnswer: {},
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { selectedAnswer } = this.props;
    this.setState({ selectedAnswer });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleClose = () => {
    this.setState({ selectedAnswer: null });
    this.props.handleClose();
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    const { selectedAnswer } = this.state;
    this.setState({ selectedAnswer: { ...selectedAnswer, [name]: value } });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { selectedAnswer } = this.state;
    const { handleAddAnswer, handleUpdateAnswer, formType } = this.props;

    if (!selectedAnswer.answer || selectedAnswer.answer == '') {
      return;
    }
    if (formType === 'createForm') {
      const { counter } = this.props;
      selectedAnswer.ordinalNumber = counter + 1;
      selectedAnswer.id = 0;
      handleAddAnswer(selectedAnswer);
      this.handleClose();
    } else {
      handleUpdateAnswer(selectedAnswer);
      this.handleClose();
    }
    this.setState({ selectedAnswer: {} });
  };

  render() {
    const { errors, selectedAnswer } = this.state;
    const { show, formType, handleClose } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <div>
              {formType === 'createForm' ? (
                <h3 className="text-center textShadow">{this.props.dictionary["modalForAvailableAnswers.naslovDodavanje"]}</h3>
              ) : (
                <h3 className="text-center textShadow">{this.props.dictionary["modalForAvailableAnswers.naslovIzmena"]}</h3>
              )}
              <hr></hr>
              <form className="col-md-12">
                <div className="col-md-12 m-auto mb-4">
                  <div className="form-group mb-4">
                    <label className="labelText textShadow">{this.props.dictionary["modalForAvailableAnswers.odgovor"]}</label>
                    <span className="redAsterisk"> *</span>
                    <Input
                      type="text"
                      id="answer"
                      placeholder={this.props.dictionary["modalForAvailableAnswers.placeholder.odgovor"]}
                      name="answer"
                      value={selectedAnswer && selectedAnswer.answer}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-row justify-content-end">
                    <button
                      id="subscribeButton"
                      className="col-md-3"
                      onClick={this.handleSubmit}
                    >
                      {this.props.dictionary["modalForAvailableAnswers.sacuvaj"]}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

ModalForAvailableAnswers.propTypes = {};

const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {})(ModalForAvailableAnswers);
