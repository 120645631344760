import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import OGPPagePhases from './OGPPagePhases';
import { changeTabCssStyle } from '../TopicOfDiscussion/TopicOfDiscussionSupportMethods';
import { getOGPDescriptionList } from '../../actions/ogpDescriptionActions';
import authorizationService from '../../securityUtils/authorizationService';
import AddOGPDescription from '../OGPDescription/AddOGPDescription';
import AddAd from '../Ad/AddAd';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { getAllAd } from '../../actions/adActions';
import moment from 'moment';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import { Tooltip } from 'react-tooltip';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { getOGPDocuments } from '../../actions/ogpDocumentActions';
import OgpDocumentTable from '../OgpDocument/OgpDocumentTable';
import UploadDocumentsButton from '../Reusable/UploadDocumentsButton';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import LoadingIndicator from '../Reusable/LoadingIndicator';
import { trackPromise } from 'react-promise-tracker';
import ModalForAdDocumentPreview from '../AdDocument/ModalForAdDocumentPreview';
import AttachmentRoundedIcon from '@mui/icons-material/AttachmentRounded';
import { gaKey } from '../../constants';
import ReactGA from 'react-ga';

class OGPPage extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      showDescriptionEditForm: false,
      showDescriptionEditButton: true,
      showAdEditForm: false,
      showAdEditButton: true,
      showModalForAdDocumentPreview: false,
    };
    this.handleButtonClickedChange = this.handleButtonClickedChange.bind(this);
    this.handleButtonClickedChangeAd =
      this.handleButtonClickedChangeAd.bind(this);
  }

  componentDidMount() {
/*     if(this.props.alowedAnalytics){
      ReactGA.initialize(gaKey);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } */
    trackPromise(
      this.props
        .getOGPDescriptionList()
        .then(this.props.getAllAd())
        .then(this.props.getOGPDocuments())
    ).finally(() => this.setState({ isLoading: false }));
  }

  handleStyleChange = (active) => {
    let arrayOfElements = [
      'firstId',
      'secondId',
      'thirdId',
      'fourthId',
      'fifthId',
    ];
    changeTabCssStyle(active, arrayOfElements);
  };

  handleButtonClickedChange = (signal) => {
    if (signal == 'showDescriptionEdit') {
      this.setState({
        showDescriptionEditForm: true,
        showDescriptionEditButton: false,
      });
    } else if (signal == 'closeDescriptionEditForm') {
      this.setState({
        showDescriptionEditForm: false,
        showDescriptionEditButton: true,
      });
    }
  };

  handleButtonClickedChangeAd = (signal) => {
    if (signal == 'showAdEdit') {
      this.setState({
        showAdEditForm: true,
        showAdEditButton: false,
      });
    } else if (signal == 'closeAdEditForm') {
      this.setState({
        showAdEditForm: false,
        showAdEditButton: true,
      });
    }
  };

  showModalForAdDocumentPreview = () => {
    this.setState({ showModalForAdDocumentPreview: true });
  };

  handlePreviewClose = () => {
    this.setState({
      showModalForAdDocumentPreview: false,
    });
  };

  render() {
    const { isLoading } = this.state

    const { ogpDescriptionList } = this.props.ogpDescription;
    const { adList } = this.props.ad;
    const { ogpDocumentList } = this.props.ogpDocument;

    let createOrPreviewSwitch = (
      <div className="col-md-12 d-flex flex-row justify-content-center card card-body info-card component-wrapper-fade-in">
        <p className="faded-text">{this.props.dictionary["OGPPage.sadrzajUPripremi"]}</p>
      </div>
    );
    if (this.state.showDescriptionEditForm == true) {
      createOrPreviewSwitch = (
        <AddOGPDescription
          handleButtonClickedChange={this.handleButtonClickedChange}
        />
      );
    }

    if (
      ogpDescriptionList &&
      ogpDescriptionList[ogpDescriptionList.length - 1]
    ) {
      const lastAddedOGPDescription =
        ogpDescriptionList[ogpDescriptionList.length - 1];

      createOrPreviewSwitch = (
        <div className="col-md-12 card card-body info-card component-wrapper-fade-in">
          <p>{lastAddedOGPDescription.description}</p>
          {authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) ? (
            <Link to={`/ogpDescriptionList`} className="ml-auto">
              <a data-tooltip-id="ogpDescriptionTip">
                <StorageRoundedIcon className="previewButton" />
              </a>
              <Tooltip
                id="ogpDescriptionTip"
                type="info"
                effect="solid"
                place="right"
              >
                {this.props.dictionary["tooltip.ogp.arhivaTekstoviPOU"]}
              </Tooltip>
            </Link>
          ) : (
            ''
          )}
        </div>
      );

      if (this.state.showDescriptionEditForm == true) {
        createOrPreviewSwitch =
          authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) ? (
            <AddOGPDescription
              handleButtonClickedChange={this.handleButtonClickedChange}
              id={lastAddedOGPDescription.id}
            />
          ) : (
            ''
          );
      }
    }

    let createOrPreviewSwitchAd = (
      <div className="col-md-12 d-flex flex-row justify-content-center card card-body info-card">
        <p className="faded-text">{this.props.dictionary["OGPPage.sadrzajUPripremi"]} </p>
      </div>
    );

    if (this.state.showAdEditForm == true) {
      createOrPreviewSwitchAd = (
        <AddAd handleButtonClickedChangeAd={this.handleButtonClickedChangeAd} />
      );
    }

    let lastAddedAd = {};

    if (adList && adList[adList.length - 1]) {
      lastAddedAd = adList[adList.length - 1];
      const startDate = lastAddedAd.startDate
        ? moment(lastAddedAd.startDate).format('DD.MM.YYYY')
        : '/';
      const endDate = lastAddedAd.endDate
        ? moment(lastAddedAd.endDate).format('DD.MM.YYYY')
        : '/';
      const period = !(lastAddedAd.startDate && lastAddedAd.endDate)
        ? ''
        : 'Период: ' + startDate + ' - ' + endDate;

      createOrPreviewSwitchAd = (
        <div className="col-md-12 card card-body info-card emphesized-label-font component-wrapper-fade-in">
          {adList[adList.length - 1].active ? (
            <strong>
              <p>{lastAddedAd.ad}</p>
              {lastAddedAd.startDate && lastAddedAd.endDate && <p>{period}</p>}
            </strong>
          ) : (
            <p className="faded-text">{this.props.dictionary["OGPPage.nemaObjava"]}</p>
          )}
          {authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) && (
            <Link to={`/adList`} className="ml-auto">
              <span data-tooltip-id="adArchiveTip">
                <StorageRoundedIcon className="previewButton" />
              </span>
              <Tooltip
                id="adArchiveTip"
                type="info"
                effect="solid"
                place="right"
              >
                {this.props.dictionary["tooltip.ogp.arhivaObjave"]}
              </Tooltip>
            </Link>
          )}
        </div>
      );

      if (this.state.showAdEditForm == true) {
        createOrPreviewSwitchAd = (
          <AddAd
            handleButtonClickedChangeAd={this.handleButtonClickedChangeAd}
            id={lastAddedAd.id}
          />
        );
      }
    }

    const { currentSection } = this.props.match.params;

    let pageContent = isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="container-fluid component-wrapper-fade-in-large-list">
        <div id="topic-header-row" className="row container-flow mb-5">
          <div className="col-md-12 m-auto">
            <div className="col-md-11 m-auto d-flex flex-row justify-content-arround component-wrapper-fade-in">
              <h1 className="align-self-center p-2">
                {this.props.dictionary["OGPPage.pou"]}
              </h1>
              <div className="ms-auto">
                <Link
                  to="https://ogp.rs/"
                  target={'_blank'}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open('https://ogp.rs/');
                  }}
                >
                  <img
                    src="/images/OGP-logo.png"
                    alt="Партнерство за отворену управу лого"
                    className="logoXLG"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-md-12 mb-4">
          <div className="col-md-11 m-auto">
            <h4>
              <InfoRoundedIcon fontSize="large" className="previewButton" />{this.props.dictionary["OGPPage.pouInfo"]}
            </h4>
            {this.state.showDescriptionEditForm ? (
              <div
                onClick={() =>
                  this.handleButtonClickedChange('closeDescriptionEditForm')
                }
                className="form-group"
              >
                <CloseRoundedIcon className="editButton float-right mb-2" />
              </div>
            ) : (
              ''
            )}
            {createOrPreviewSwitch}
            <div className="form-group col- mt-2 float-right">
              {authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) &&
              this.state.showDescriptionEditButton == true ? (
                <Link
                  onClick={(e) => {
                    this.handleButtonClickedChange('showDescriptionEdit');
                  }}
                  to="#"
                >
                  <i className="far fa-edit editButton"></i>
                </Link>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className="row col-md-12">
          <div className="col-md-11 m-auto">
            <div className="row">
              <div className="col-md-6 float-left">
                <h4>
                  <LabelImportantIcon
                    fontSize="large"
                    className="previewButton"
                  />{' '}
                  {this.props.dictionary["OGPPage.oglasnaTabla"]}
                </h4>
                <div className="m-auto">
                  {this.state.showAdEditForm ? (
                    <div
                      onClick={() =>
                        this.handleButtonClickedChangeAd('closeAdEditForm')
                      }
                      className="form-group"
                    >
                      <CloseRoundedIcon className="editButton float-right mb-2" />
                    </div>
                  ) : (
                    ''
                  )}
                  {createOrPreviewSwitchAd}
                  <div className="form-group col- mt-2 float-right">
                    {authorizationService.canAccessOGPPageSpecificAreas(
                      this.props.authRole
                    ) && this.state.showAdEditButton == true ? (
                      <Link
                        onClick={(e) => {
                          this.handleButtonClickedChangeAd('showAdEdit');
                        }}
                        to="#"
                      >
                        <i className="far fa-edit editButton"></i>
                      </Link>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="float-left mt-2">
                  {this.state.showAdEditButton === true ? (
                    lastAddedAd.active ? (
                      <Link
                        onClick={(e) => {
                          this.showModalForAdDocumentPreview();
                        }}
                        className="default-label-text-link"
                        to="#"
                      >
                        <AttachmentRoundedIcon />
                        <strong className="pr-2">
                          {' '}
                          {this.props.dictionary["OGPPage.preuzimanjeDokumenata"]}{' '}
                        </strong>
                      </Link>
                    ) : (
                      <Link className="empty-list-button" to="#">
                        <AttachmentRoundedIcon />
                        <strong className="pr-2">
                          {' '}
                          {this.props.dictionary["OGPPage.preuzimanjeDokumenata"]}{' '}
                        </strong>
                      </Link>
                    )
                  ) : (
                    <Link className="empty-list-button" to="#">
                      <AttachmentRoundedIcon />
                      <strong className="pr-2"> {this.props.dictionary["OGPPage.preuzimanjeDokumenata"]} </strong>
                    </Link>
                  )}
                </div>
              </div>
              <div className="col-md-6 float-right">
                <h4>
                  <HelpOutlineRoundedIcon
                    fontSize="large"
                    className="previewButton"
                  />{' '}
                  {this.props.dictionary["OGPPage.dokumenta"]}
                </h4>
                <div className="card card-body info-card mb-2">
                  <OgpDocumentTable
                    ogpDocumentList={ogpDocumentList}
                  />
                </div>
                {authorizationService.canAccessOGPPageSpecificAreas(
                  this.props.authRole
                ) ? (
                  <UploadDocumentsButton phaseRoute={'uploadOgpDocument'} />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 mt-5">
          <div className="col-md-11 m-auto row">
            <div className="col-md-12">
              {authorizationService.canAccessOGPPageSpecificAreas(
                this.props.authRole
              ) ? (
                <div className="form-group">
                  <Link to="/ogpCommentList" className="topicTitleText">
                    <ChatRoundedIcon /> <strong>{this.props.dictionary["OGPPage.pregledKomentara"]}</strong>
                  </Link>
                </div>
              ) : (
                ''
              )}
              <OGPPagePhases
                currentSection={currentSection}
                handleStyleChange={this.handleStyleChange}
                requestIssuesFinalDate={
                  lastAddedAd &&
                  lastAddedAd.requestIssues &&
                  lastAddedAd.active &&
                  lastAddedAd.endDate
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <Fragment>
        {pageContent}
        {this.state.showModalForAdDocumentPreview == true ? (
          <ModalForAdDocumentPreview
            show={this.state.showModalForAdDocumentPreview}
            handleClose={this.handlePreviewClose}
            adId={lastAddedAd.id}
          />
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

OGPPage.propTypes = {
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  ogpDescription: state.ogpDescription,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  ad: state.ad,
  ogpDocument: state.ogpDocument,
  alowedAnalytics: state.security.alowedAnalytics,
});

export default connect(mapStateToProps, {
  changeTabCssStyle,
  getOGPDescriptionList,
  getAllAd,
  getOGPDocuments,
})(OGPPage);
