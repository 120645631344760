import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { getOGPArchive } from "../../actions/ogpArchiveActions";
import OgpStepList from "../OgpStep/OgpStepList";

class ModalForOGPPreview extends Component {
  constructor(props) {
    super();

    this.state = {
      ...props.ogpArchive,
    };

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const id = this.props.id;
    this.props.getOGPArchive(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const {
      id,
      ogpId,
      nameAndLastname,
      organisationName,
      phoneNum,
      email,
      currentState,
      shortDescriptionAndMainGoal,
      strategy,
      additionalInformation,
      relevancy,
      startDate,
      endDate,
      ogpFieldsOfInterestArchives,
      obligationName,
      obligationNumber,
      otherParticipants,
      status,
      user,
      archiveCreationDate,
      ogpStepArchives,
    } = nextProps.ogpArchive;

    this.setState({
      id,
      ogpId,
      nameAndLastname,
      organisationName,
      phoneNum,
      email,
      currentState,
      shortDescriptionAndMainGoal,
      strategy,
      additionalInformation,
      relevancy,
      startDate: startDate ? moment(startDate).toDate() : "",
      endDate: endDate ? moment(endDate).toDate() : "",
      ogpFieldsOfInterestArchives,
      obligationName,
      obligationNumber,
      otherParticipants,
      status,
      user,
      archiveCreationDate,
      ogpStepArchives,
    });
  }

  handleClose() {
    this.props.handleClose();
  }

  render() {
    let startDateInfo = this.props.ogpArchive.startDate
      ? moment(this.props.ogpArchive.startDate).format("DD.MM.YYYY ")
      : "дд/мм/гггг";
    let endDateInfo = this.props.ogpArchive.endDate
      ? moment(this.props.ogpArchive.endDate).format("DD.MM.YYYY ")
      : "дд/мм/гггг";

    let counter = -1;
    let listOfFields = this.props.ogpArchive.ogpFieldsOfInterestArchives
      ? this.props.ogpArchive.ogpFieldsOfInterestArchives.map((field) => {
          counter++;
          if (
            counter ==
            this.props.ogpArchive.ogpFieldsOfInterestArchives.length - 1
          ) {
            return (
              <span className="d-inline">{field.ogpFieldOfInterestName}</span>
            );
          } else {
            return (
              <div className="d-inline">
                {field.ogpFieldOfInterestName + ", "}
              </div>
            );
          }
        })
      : [];

    const ogpStepList = this.props.ogpArchive.ogpStepArchives
      ? this.props.ogpArchive.ogpStepArchives
      : [];

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <h4 className="textShadow boldText text-center">
              {`${this.props.dictionary["ModalForOGPPreview.Naslov"]}` +
                moment(this.props.ogpArchive.archiveCreationDate).format(
                  "DD.MM.YYYY"
                )}
            </h4>
            <div className="panel-heading" />
            <hr />
            <br />
            <div className="panel-body ">
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.BrojObaveze"]}:</label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.obligationNumber}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.Naziv"]}:</label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.obligationName}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">
                  {this.props.dictionary["ModalForOGPPreview.DatumPocetkaIZavrsetka"]}:
                  </label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">
                    {startDateInfo + " - " + endDateInfo}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">
                  {this.props.dictionary["ModalForOGPPreview.NazivOgranizacije"]}:
                  </label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.organisationName}
                  </p>
                </div>
              </div>
              <hr />
              <h5 className="textShadow mb-4 default-label-text text-center">
              {this.props.dictionary["ModalForOGPPreview.Opis"]}
              </h5>
              <hr></hr>
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.Oblasti"]}:</label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">{listOfFields}</p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">
                  {this.props.dictionary["ModalForOGPPreview.Problem"]}:
                  </label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.currentState}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.Obaveza"]}:</label>
                </div>
                <div className="col-md-6 float-left ">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.shortDescriptionAndMainGoal}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">
                  {this.props.dictionary["ModalForOGPPreview.Doprinos"]}
                  </label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.strategy}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">
                  {this.props.dictionary["ModalForOGPPreview.Relevantnost"]}
                  </label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.relevancy}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.DodatneInformacije"]}:</label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.additionalInformation}
                  </p>
                </div>
              </div>
              <hr />
              <OgpStepList
                ogpId={this.props.ogpArchive.ogpId}
                ogpStepList={ogpStepList}
              />
              <hr></hr>
              <h5 className="textShadow mb-4 default-label-text text-center">
              {this.props.dictionary["ModalForOGPPreview.InformacijeOKontaktima"]}
              </h5>
              <hr></hr>
              <div className="row mb-4">
                <div className="col-md-6">
                  <label>{this.props.dictionary["ModalForOGPPreview.ImePrezime"]}</label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.nameAndLastname}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.Email"]}:</label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.email}
                  </p>
                </div>
                <div className="col-md-6">
                  <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.Telefon"]}:</label>
                </div>
                <div className="col-md-6">
                  <p className="font-weight-bold">
                    {this.props.ogpArchive.phoneNum}
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div className="row mb-4">
              <div className="col-md-6">
                <label className="textShadow">{this.props.dictionary["ModalForOGPPreview.Ostali"]}:</label>
              </div>
              <div className="col-md-6">
                <p className="font-weight-bold">
                  {this.props.ogpArchive.otherParticipants}
                </p>
              </div>
            </div>
            <hr />
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ogpArchive: state.ogpArchive.ogpArchive,
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  getOGPArchive,
})(ModalForOGPPreview);
