import React, { Component } from 'react';
import { Tr, Td } from 'react-super-responsive-table';
import { connect } from 'react-redux';

class OGPDescriptionRow extends Component {
  render() {
    return (
      <Tr>
        <Td className="textShadow">{this.props.counter + '.'}</Td>
        <Td className="textShadow text-center">
          {this.props.ogpDescription.creationDate}
        </Td>
        <Td className="textShadow">{this.props.ogpDescription.description}</Td>
        <Td className="textShadow">
          {this.props.ogpDescription.active ? 
          `${this.props.dictionary["OGPDescriptionRow.Aktivan"]}` : 
          `${this.props.dictionary["OGPDescriptionRow.Neaktivan"]}`}
        </Td>
      </Tr>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {})(OGPDescriptionRow);
