import React, { Component } from "react";
import NotificationTopicOfDiscussionRow from "./NotificationTopicOfDiscussionRow";
import authorizationService from "../../securityUtils/authorizationService";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";

class NotificationTopicOfDiscussionTable extends Component {
  render() {
    let notificationTopicOfDiscussionList = this.props.notificationTopicOfDiscussionList.map(
      (notification) => (
        <NotificationTopicOfDiscussionRow
          key={notification.id}
          notification={notification}
          quickPreviewSignal={this.props.quickPreviewSignal}
        />
      )
    );
    return (
      <div>
        <Table id="example" className="table-responsive">
          <Tbody id="topicUnorderedList" className="col-md-12">
            {notificationTopicOfDiscussionList}
          </Tbody>
        </Table>
      </div>
    );
  }
}

export default NotificationTopicOfDiscussionTable;
