import axios from 'axios';
import ApiService from '../securityUtils/ApiService';

import {
  GET_ERRORS,
  GET_COMMENT,
  GET_COMMENT_LIST,
  GET_COMMENT_LIST_FILTERED,
  DELETE_COMMENT,
  GET_COMMENT_LIST_DTO,
  UPDATE_COMMENT,
  UPDATE_COMMENT_STATUS,
  CREATE_COMMENT,
  GET_COMMENT_MAX_ID,
  GET_NUMBER_OF_UNHANDLED_COMMENTS,
  GET_COMMENT_LIST_ON_DOCUMENT
} from './types';

export const createComment = (comment) => async (dispatch) => {
  try {
    const res = await axios.post('/api/comment/create', comment);
    dispatch({
      type: CREATE_COMMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateComment = (comment) => async (dispatch) => {
  try {
    const res = await axios.post('/api/comment/update', comment);
    // res.data.commentText = JSON.parse(res.data.commentText);
    dispatch({
      type: UPDATE_COMMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
    throw err;
  }
};

export const updateCommentStatus = (comment) => async (dispatch) => {
  try {
    const res = await axios.post('/api/comment/updateStatus', comment);
    // res.data.commentText = JSON.parse(res.data.commentText);
    dispatch({
      type: UPDATE_COMMENT_STATUS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const createModeratorReply = (comment) => async (dispatch) => {
  try {
    const res = await axios.post('/api/comment/createModeratorReply', comment);
    res.data.commentText = JSON.parse(res.data.commentText);
    dispatch({
      type: UPDATE_COMMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const getCommentList = (id) => async (dispatch) => {
  const req = {
    method: 'GET',
    url: `/api/comment/findAllDTO/${id}`,
  };
  const res = await ApiService.callApi(req);

  res.data.forEach((c) => (c.commentText = JSON.parse(c.commentText)));
  // console.log(res.data.commentText);
  dispatch({
    type: GET_COMMENT_LIST,
    payload: res.data,
  });
};


export const getFilteredCommentListPost = (id,searchParams) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/comment/findAllFilteredComments/${id}`, searchParams);
    //res.data.data.forEach((c) => (c.commentText = JSON.parse(c.commentText)));
    dispatch({
      type: GET_COMMENT_LIST_FILTERED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};


export const getComment = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/comment/findById/${id}`);
    // res.data.commentText = JSON.parse(res.data.commentText);
    dispatch({
      type: GET_COMMENT,
      payload: res.data,
    });
  } catch (e) {
    e.getMessage();
  }
};


export const getCommentDTO = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/comment/findDTOById/${id}`);
    res.data.commentText = JSON.parse(res.data.commentText);
    dispatch({
      type: GET_COMMENT,
      payload: res.data,
    });
  } catch (e) {
    e.getMessage();
  }
};

export const deleteComment = (id) => async (dispatch) => {
  await axios.delete(`/api/comment/delete/${id}`);
  dispatch({
    type: DELETE_COMMENT,
    payload: id,
  });
};

export const getCommentListForDocumentAndUser = (
  methodId,
  uuidDocumentName
) => async (dispatch) => {
  const req = {
    method: 'GET',
    url: `/api/comment/findAllByUserIdDocumentUuidAndMethodId/${uuidDocumentName}/${methodId}`,
  };
  const res = await ApiService.callApi(req);
  //   res.data.commentText = JSON.parse(res.data.commentText);
  res.data.forEach((c) => (c.commentText = JSON.parse(c.commentText)));
  // console.log(res.data.commentText);
  dispatch({
    type: GET_COMMENT_LIST_DTO,
    payload: res.data,
  });
};

export const getCommentListForUserAndMethodAndDocument = (
  methodId,
  uuidDocumentName
) => async (dispatch) => {
  const req = {
    method: 'GET',
    url: `/api/comment/findAllByUserAndMethodAndDocument/${methodId}/${uuidDocumentName}`,
  };
  const res = await ApiService.callApi(req);
  //   res.data.commentText = JSON.parse(res.data.commentText);
  res.data.forEach((c) => (c.commentText = JSON.parse(c.commentText)));
  // console.log(res.data.commentText);
  dispatch({
    type: GET_COMMENT_LIST_DTO,
    payload: res.data,
  });
};

export const downloadCommentList = (topicOfDiscussionId, nameOfTopic) => async (
  dispatch
) => {
  axios({
    url: `/api/comment/exportAllCommentsByTopicOfDiscussionIdInExcel/${topicOfDiscussionId}/${nameOfTopic}`,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      'Списак свих коментара процеса ' + nameOfTopic + '.xlsx'
    );
    document.body.appendChild(link);
    link.click();
  });
};

export const getCommentListForDocumentDTO = (
  methodId,
  uuidDocumentName
) => async (dispatch) => {
  const res = await axios.get(
    `/api/comment/findAllByMethodIdAndUuidDocumentName/${methodId}/${uuidDocumentName}`
  );
  //   res.data.commentText = JSON.parse(res.data.commentText);
  res.data.forEach((c) => (c.commentText = JSON.parse(c.commentText)));
  // console.log(res.data.commentText);
  dispatch({
    type: GET_COMMENT_LIST_DTO,
    payload: res.data,
  });
};

export const getCommentWithMaxId = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/comment/findMaxId`);
    dispatch({
      type: GET_COMMENT_MAX_ID,
      payload: res.data,
    });
  } catch (e) {
    e.getMessage();
  }
};

export const getNumberOfUnhandledComments = (topicOfDiscussionId) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/comment/getNumberOfUnhandledComments/${topicOfDiscussionId}`);
    dispatch({
      type: GET_NUMBER_OF_UNHANDLED_COMMENTS,
      payload: res.data,
    });
  } catch (e) {
    //e.getMessage();
  }
};

export const getCommentListForDocument = (uuidDocumentName, methodId, searchParams) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/comment/findAllCommentsOnDocument/${uuidDocumentName}/${methodId}`, searchParams);
    //res.data.data.forEach((c) => (c.commentText = JSON.parse(c.commentText)));
    dispatch({
      type: GET_COMMENT_LIST_ON_DOCUMENT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};


