import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getOgpFieldOfInterest,
  updateOgpFieldOfInterest,
} from '../../actions/ogpFieldOfInterestActions';
import Input from '../Reusable/Input';
import authorizationService from '../../securityUtils/authorizationService';
import { setMessage } from '../../utils';

class UpdateOgpFieldOfInterest extends Component {
  constructor() {
    super();
    this.state = {
      name: '',

      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const { ...ogpFieldOfInterest } = nextProps.ogpFieldOfInterest;

    this.setState({
      ...ogpFieldOfInterest,
    });
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getOgpFieldOfInterest(id);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleValidation = () => {
    let error = false;

    if (this.state.name == '') {
      this.props.setMessage(`${this.props.dictionary["UpdateOgpFieldOfInterest.Validation.Naziv"]}`, '#name', '#msg1');
      error = true;
    }

    return error;
  };

  onSubmit(e) {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const updateFieldOfInterest = {
      id: this.state.id,
      name: this.state.name,
    };

    this.props.updateOgpFieldOfInterest(
      updateFieldOfInterest,
      this.props.history
    );
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="component-wrapper-fade-in">
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto">
                <h1 className="text-center textShadow mt-5">{this.props.dictionary["UpdateOgpFieldOfInterest.Izmena"]}</h1>
                <hr />
                <form onSubmit={this.onSubmit} className="col-md-12">
                  <div className="form-group">
                    <label className="labelText textShadow">
                    {this.props.dictionary["UpdateOgpFieldOfInterest.Naziv"]}
                    </label>
                    <span className="redAsterisk"> *</span>
                    <Input
                      type="text"
                      id="name"
                      placeholder={this.props.dictionary["UpdateOgpFieldOfInterest.Placeholder.Naziv"]}
                      name="name"
                      value={this.state.name}
                      onChange={this.onChange}
                      errors={errors.name}
                    />
                    <div id="msg1" />
                  </div>
                  <div className="form-row mt-4">
                    {authorizationService.canAccessOGPPageSpecificAreas(
                      this.props.authRole 
                    ) ? (
                      <button id="subscribeButton" className="col-md-2 ml-auto">
                        {this.props.dictionary["UpdateOgpFieldOfInterest.Sacuvaj"]}
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UpdateOgpFieldOfInterest.propTypes = {
  updateOgpFieldOfInterest: PropTypes.func.isRequired,
  getOgpFieldOfInterest: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ogpFieldOfInterest: state.ogpFieldOfInterest.ogpFieldOfInterest,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getOgpFieldOfInterest,
  updateOgpFieldOfInterest,
  setMessage,
})(UpdateOgpFieldOfInterest);
