import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Component } from 'react';
import { Tooltip } from 'react-tooltip';
import { connect } from "react-redux";

class StatusMark extends Component {

    constructor(props) {
        super();
      }
    
    render() {
        let EmptyMark = (<></>)
        let OdbijenMark = (
            <div>
              <a data-tooltip-id="odbijenTip">
                <PriorityHighRoundedIcon
                  style={{ 
                    color: 'red',
                    //position: 'absolute', 
                    //marginLeft: '-23px' 
                  }}
                />
              </a>
              <Tooltip
                id="odbijenTip"
                type="info"
                effect="solid"
                place="right"
              >
              {this.props.dictionary["tooltip.statusMark.odbijenKomentar"]}
              </Tooltip>
            </div> )
        let PrimljenMark = (
            <div>
              <a data-tooltip-id="primljenTip">
                <WarningRoundedIcon
                  style={{
                    color: '50679b',
                    //position: 'absolute',
                    //marginLeft: '-23px',
                  }}
                />
              </a>
              <Tooltip
                id="primljenTip"
                type="info"
                effect="solid"
                place="right"
              >
              {this.props.dictionary["tooltip.statusMark.primljenKomentar"]}
              </Tooltip>
            </div>
          )
          let mark = EmptyMark;
          switch(this.props.status) {
            case "Одбијен" : mark = OdbijenMark; break;
            case "Примљен" : mark = PrimljenMark; break;
            default: mark = EmptyMark
          }

        return mark 
    }
}
const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});
export default connect(mapStateToProps, {
})(StatusMark);