import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tr, Td } from 'react-super-responsive-table';
import { Link } from 'react-router-dom';


class NotificationRow extends Component {

  render() {
    const{handleShowModalForMethodComments}=this.props;
    const notification = this.props.notification;

    const commentObject = notification.comment.commentText;
    const commentText = commentObject.comment.text;
    const moderatorAnswer = notification.comment.visibilityExplanation;
    let cssLiStyle =
      this.props.quickPreviewSignal == 1
        ? 'quickPreviewTopicTitleText mb-2 '
        : 'topicTitleText mb-2 ';
    
    const phaseActiveStatus = notification.comment.phaseActive == false ? 0 : 1;
    return (
      <Tr>
        <Td id="unorderedList" className="border-0">
          {this.props.authRole == '2' ? (
            <Link
              to={`/commentListModerator/${notification.topicOfDiscussion.id}`}
              className="remove-link-effect"
            >
              <div>
                <li className={cssLiStyle}>
                  {notification.topicOfDiscussion.nameOfTopic} (
                  {notification.numberOfNotificationsPerTopicOfDiscussion})
                </li>
              </div>
            </Link>
          ) : (
            <Link
              onClick={(e) =>
                handleShowModalForMethodComments(e,
                  {
                    commentDocumentName: notification.comment.uuidDocumentName,
                    id: notification.topicOfDiscussion.id,
                    notificationId: notification.id,
                    methodId:notification.comment.methodId
                  }
                )
              }
              className="remove-link-effect"
              to="#"
            >
              <div className={cssLiStyle}>
                <strong>{this.props.dictionary["NotificationRow.moderatorAnswer"]}</strong>
                <em>{'"' + commentText + '"'}</em>
                <span>
                  {this.props.dictionary["NotificationRow.tiedToProcess"] +
                    notification.topicOfDiscussion.nameOfTopic.toUpperCase() +
                    this.props.dictionary["NotificationRow.followingMessage"] +
                    '"'}
                </span>
                <em>{moderatorAnswer + '"'}</em>

                <hr className="hrStyle" />
              </div>
            </Link>
          )}
        </Td>
      </Tr>
    );
  }
}
const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary
});
export default connect(mapStateToProps,{}) (NotificationRow);