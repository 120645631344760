import React, { Component } from "react";
import OGPArchiveRow from "./OGPArchiveRow";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";
import { connect } from 'react-redux';

class OGPArchiveTable extends Component {
  render() {
    let counter = 1;
    let ogpArchiveList = this.props.ogpArchiveList.map((ogpArchive) => (
      <OGPArchiveRow
        key={ogpArchive.id}
        ogpArchive={ogpArchive}
        counter={counter++}
      />
    ));

    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-sm table-striped mt-2">
          <Thead>
            <Tr className="table-header-height">
              <Th width="5%" className="boldText emphesized-label-font">
                {this.props.dictionary["OGPArchiveTable.RB"]}
              </Th>
              <Th
                width="20%"
                className="boldText emphesized-label-font text-center"
              >
                {this.props.dictionary["OGPArchiveTable.Datum"]}
              </Th>
              <Th className="boldText emphesized-label-font">{this.props.dictionary["OGPArchiveTable.NazivObaveze"]}</Th>
              <Th className="boldText emphesized-label-font text-center">
                {this.props.dictionary["OGPArchiveTable.BrojObaveze"]}
              </Th>

              <Th width="10%" className="text-center">
              {this.props.dictionary["OGPArchiveTable.Pregled"]}
              </Th>
              <Th width="10%" className="text-center">
              {this.props.dictionary["OGPArchiveTable.Preuzmi"]}
              </Th>
            </Tr>
          </Thead>
          <Tbody>{ogpArchiveList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {})(OGPArchiveTable);



