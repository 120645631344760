import {
    GET_ERROR_LOG,
  } from "../actions/types";
  
  const initialState = {
    errors: []
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_ERROR_LOG:
        return {
          ...state,
          errors: action.payload,
        };
          default:
        return state;
    }
  }
  