import axios from "axios";
import {
  GET_ERRORS,
  DELETE_TOPIC_OF_DISCUSSION_PARTICIPANT,
  UNSUBSCRIBE_FROM_TOPIC_OF_DISCUSSION,
  GET_TOPIC_OF_DISCUSSION_PARTICIPANT_LIST,
} from "./types";

export const createTopicOfDiscussionParticipant = (
  topicOfDiscussionParticipant
) => async (dispatch) => {
  try {
    await axios.post(
      "/api/topicOfDiscussionParticipant/create",
      topicOfDiscussionParticipant
    );
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getTopicOfDiscussionParticipantList = () => async (dispatch) => {
  const res = await axios.get(`/api/topicOfDiscussionParticipant/findAll`);
  dispatch({
    type: GET_TOPIC_OF_DISCUSSION_PARTICIPANT_LIST,
    payload: res.data,
  });
};

export const unsubscribeFromTopicOfDiscussion = (id) => async (dispatch) => {
  await axios.delete(`/api/topicOfDiscussionParticipant/unsubscribe/${id}`);
  dispatch({
    type: UNSUBSCRIBE_FROM_TOPIC_OF_DISCUSSION,
    payload: id,
  });
};
