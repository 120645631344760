import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ComboReusable from "../Reusable/ComboReusable";
import { getAllFiledsOfInterest } from "../../actions/fieldOfInterestActions";
import {
  getAllTopicsWithUserActivity,
  getAllTopicsOfDiscussionPageable,
  getAllTopicsOfDiscussionPageableFilter,
  fieldOfInterestByUserIdList,
} from "../../actions/topicOfDiscussionActions";
import { getInstitutions } from "../../actions/institutionActions";
import TopicOfDiscussionDashboardList from "./TopicOfDiscussionDashboardList";
import { Tabs, Tab, Row, Col, Nav, Container } from "react-bootstrap";
import SearchFilter from "../Reusable/SearchFilter";
import LoadingIndicator from "../Reusable/LoadingIndicator";
import { trackPromise } from "react-promise-tracker";
import NotificationList from "../Notification/NotificationList";
import {
  getNotificationsCounters,
  getNotificationByTopicOfDiscussionId,
  getNotificationsByFieldOfInterest,
  getNotificationsByInstitution,
} from "../../actions/notificationTopicOfDiscussionActions";
import _ from "lodash";
import { getTopicsOfDiscussionByUserId } from "../../actions/topicOfDiscussionActions";
import {
  getFieldOfInterestByUserIdList,
  getInstitutionByUserIdList,
} from "../../actions/fieldOfInterestUserActions";
import NotificationsByFieldOfInterestTab from "./NotificationsByFieldOfInterestTab";
import NotificationsByInstitutionTab from "./NotificationsByInstitutionTab";
import NotificationsByTopicOfDiscussionTab from "./NotificationsByTopicOfDiscussionTab";
import ComboFieldOfInterest from "../FieldOfInterest/ComboFieldOfInterest";
import { getNotifications } from "../../actions/notificationActions";
import { gaKey } from "../../constants";
import ReactGA from "react-ga";
import { numberOfItemsPerPage } from "../../constants";
import {
  createTopicOfDiscussionParticipant,
  unsubscribeFromTopicOfDiscussion,
} from "../../actions/topicOfDiscussionParticipantActions";
import { Link } from "react-router-dom";

import TopicOfDiscussionTableServerFiltering from "../TopicOfDiscussion/TopicOfDiscussionTableServerFiltering";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      fieldOfInterest: {
        id: 0,
      },
      institution: {
        id: 0,
      },
      consultation: {
        id: 0,
      },
      publicDebate: {
        id: 0,
      },
      archive: {
        id: 0,
      },
      comboSignal: null,
      errors: {},
      signalCombo: false,
      activeTab: "topics",
      pageNumber: 0,
      pageSize: numberOfItemsPerPage,
    };
    this.handleComboBoxChange = this.handleComboBoxChange.bind(this);
  }

  componentDidMount() {
/*     if(this.props.alowedAnalytics){
      ReactGA.initialize(gaKey);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } */
    trackPromise(
      this.props.getAllTopicsOfDiscussionPageable(0, this.state.pageSize)
      // this.props
        // .getAllFiledsOfInterest()
        // .then(this.props.getInstitutions())
        // .then(this.props.getNotificationsCounters())
        // .then(this.props.getAllTopicsWithUserActivity())
        // .then(this.props.getTopicsOfDiscussionByUserId(this.props.userId))
        // .then(
          // this.props.getAllTopicsOfDiscussionPageable(0, this.state.pageSize)
        // )
    ).finally(() => this.setState({ isLoading: false }));
  }

  handleTopicsRefreash = () => {
    this.props
      .getTopicsOfDiscussionByUserId(this.props.userId)
      .then(
        this.setTopicDTO(
          this.state.pageNumber,
          this.state.pageSize,
          this.state.comboSignal
        )
      );
  };

  handleStyleChange = (active, old) => {
    if (document.getElementById(active).classList.contains("activeTab")) {
      return;
    }
    let element = document.getElementById(old);
    element.classList.remove("activeTab");
    let newElement = document.getElementById(active);
    newElement.classList.toggle("activeTab");
  };

  handleSelect = (selectedTab) => {
    this.setState({
      activeTab: selectedTab,
    });
    if (selectedTab === "comments") {
      this.props.getNotifications(this.props.userId);
    } else if (selectedTab === "fieldOfInterestTab") {
      this.props.getFieldOfInterestByUserIdList(this.props.userId);
    } else if (selectedTab === "institutionTab") {
      this.props.getInstitutionByUserIdList(this.props.userId);
    }
  };

  handleComboTopicsOfDiscussionByUserId = () => {
    return (e) => {
      this.props.getNotificationByTopicOfDiscussionId(e.target.value);
    };
  };

  handleComboNotificationsByFieldOfInterest = () => {
    return (e) => {
      this.props.getNotificationsByFieldOfInterest(e.target.value);
    };
  };

  handleComboNotificationsByInstitution = () => {
    return (e) => {
      this.props.getNotificationsByInstitution(e.target.value);
    };
  };

  handleComboBoxChange = (object, objectId) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
        comboSignal: object,
        pageNumber: 0,
      });

      this.setTopicDTO(0, this.state.pageSize, object, e.target.value);
      this.refreshCombo();
    };
  };

  handlePageChange = (event, value) => {
    this.setState({
      pageNumber: value,
    });

    this.setTopicDTO(value, this.state.pageSize, this.state.comboSignal);
  };

  handleChangeRowsPerPage = (event, value) => {
    this.setState({
      pageSize: event.target.value,
      pageNumber: this.state.pageNumber,
    });

    this.setTopicDTO(
      this.state.pageNumber,
      event.target.value,
      this.state.comboSignal
    );
  };

  refreshCombo = () => {
    let element = document.getElementById("consultationCb");
    element.value = -1;
    element = document.getElementById("publicDebateCb");
    element.value = -1;
    element = document.getElementById("archiveCb");
    element.value = -1;
  };

  setTopicDTO = (pageNumber, pageSize, comboSignal, filterValue) => {
    const dto = {
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    if (comboSignal) {
      switch (comboSignal) {
        case "fieldOfInterest":
          dto.fieldOfInterestId = filterValue
            ? filterValue
            : this.state.fieldOfInterest.id;
          break;
        case "institution":
          dto.institutionId = filterValue
            ? filterValue
            : this.state.institution.id;
          break;
        case "consultation":
          dto.consultativeProcessId = filterValue
            ? filterValue
            : this.state.consultation.id;
          break;
        case "publicDebate":
          dto.publicDebateId = filterValue
            ? filterValue
            : this.state.publicDebate.id;
          break;
        case "archive":
          dto.topicOfDiscussionArchive = filterValue
            ? filterValue
            : this.state.archive.id;
          break;
      }
      this.props.getAllTopicsOfDiscussionPageableFilter(dto);
    } else {
      this.props.getAllTopicsOfDiscussionPageable(pageNumber, pageSize);
    }
  };

  render() {
/*     if(this.props.alowedAnalytics){
      ReactGA.initialize(gaKey);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } */
    const { isLoading } = this.state;
    const {
      createTopicOfDiscussionParticipant,
      unsubscribeFromTopicOfDiscussion,
    } = this.props;
    const { fieldOfInterestList } = this.props.fieldOfInterest;
    const { institutions } = this.props.institution;
    institutions.sort(function (a, b) {
      return a.institutionName.localeCompare(b.institutionName);
    });

    fieldOfInterestList.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
    const { topicsOfDiscussionByUserId } = this.props.topicOfDiscussion;
    const {
      notificationsCounters,
      notificationByTopicOfDiscussionIdList,
      notificationByFiledOfInterestList,
      notificationByInstitutionList,
    } = this.props.notificationTopicOfDiscussion;

    const { fieldOfInterestByUserIdList, institutionByUserIdList } =
      this.props.fieldOfInterestInstitutionUser || [];

    let numberOfNotificationsForTopicOfDiscussionList =
      notificationByTopicOfDiscussionIdList.length;
    let notificationTopicOfDiscussionIndicator =
      numberOfNotificationsForTopicOfDiscussionList >= 10
        ? "9+"
        : numberOfNotificationsForTopicOfDiscussionList;

    return (
      <Container fluid className="dashboard">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Row>
            <Row className="text-end">
              <div className="mt-1">
                <Link
                  href="http://javnerasprave.euprava.gov.rs/zavr%C5%A1ene-javne-rasprave"
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(
                      "http://javnerasprave.euprava.gov.rs/zavr%C5%A1ene-javne-rasprave"
                    );
                  }}
                  target="_blank"
                  to="#"
                  className="small"
                >
                  {this.props.dictionary["TopicOfDiscussionTableServerFiltering.Arhiva"]}
                </Link>
              </div>
            </Row>
            <Row>
              <br></br>
              <TopicOfDiscussionTableServerFiltering />
              <br></br>
            </Row>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  fieldOfInterest: state.fieldOfInterest,
  institution: state.institution,
  notification: state.notification,
  notificationTopicOfDiscussion: state.notificationTopicOfDiscussion,
  errors: state.errors,
  topicOfDiscussion: state.topicOfDiscussion,
  fieldOfInterestInstitutionUser: state.fieldOfInterestInstitutionUser,
  authUser: state.security.authUser,
  authRole: state.security.authRole,
  userRoles: state.security.userRoles,
  userId: state.security.userId,
  dictionary: state.startUp.dictionary,
  alowedAnalytics: state.security.alowedAnalytics,
});

export default connect(mapStateToProps, {
  getAllFiledsOfInterest,
  getInstitutions,
  getAllTopicsWithUserActivity,
  getNotificationsCounters,
  getTopicsOfDiscussionByUserId,
  getNotificationByTopicOfDiscussionId,
  getFieldOfInterestByUserIdList,
  getInstitutionByUserIdList,
  getNotificationsByFieldOfInterest,
  getNotificationsByInstitution,
  getNotifications,
  getAllTopicsOfDiscussionPageable,
  getAllTopicsOfDiscussionPageableFilter,
  createTopicOfDiscussionParticipant,
  unsubscribeFromTopicOfDiscussion,
})(Dashboard);
