import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  updateUserOrganizationCategory,
  getUserOrganizationCategory,
} from '../../actions/userOrganizationCategoryActions';
import Input from '../../components/Reusable/Input';
import { setMessage } from '../../utils';
import TextArea from '../Reusable/TextArea';
import { NotificationManager } from 'react-notifications';
import { trackPromise } from 'react-promise-tracker';

class UpdateUserOrganizationCategory extends Component {
  constructor() {
    super();
    this.state = {
      userOrganizationCategoryName: '',
      comment: '',
      errors: {},
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getUserOrganizationCategory(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const {
      userOrganizationCategoryName,
      comment,
    } = nextProps.userOrganizationCategory;

    this.setState({
      userOrganizationCategoryName,
      comment,
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleValidation = () => {
    let error = false;

    if (this.state.userOrganizationCategoryName == '') {
      this.props.setMessage(
         this.props.dictionary["updateUserOrganizationCategory.Validation.Kategorija"],
        '#userOrganizationCategoryName',
        '#nameMsg'
      );
      error = true;
    }

    if (
      this.state.userOrganizationCategoryName !=
        this.props.userOrganizationCategory.userOrganizationCategoryName &&
      (this.state.comment === null ||
        this.state.comment === '' ||
        this.state.comment == this.props.userOrganizationCategory.comment)
    ) {
      this.props.setMessage(
        this.props.dictionary["updateUserOrganizationCategory.Validation.Obrazlozenje"],
        '#comment',
        '#commentМsg'
      );
      error = true;
    }

    if (this.state.comment && this.state.comment.length > 300) {
      this.props.setMessage(
        this.props.dictionary["updateUserOrganizationCategory.Validation.DuzinaKomentara"],
        '#comment',
        '#commentМsg'
      );
      error = true;
    }

    return error;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const { id } = this.props.match.params;
    const updatedNewUserOrganizationCategory = {
      id: id,
      userOrganizationCategoryName: this.state.userOrganizationCategoryName,
      comment: this.state.comment,
    };
    trackPromise(this.props.updateUserOrganizationCategory(updatedNewUserOrganizationCategory, this.props.history))
    .catch(() => {
      NotificationManager.error(this.props.dictionary["updateUserOrganizationCategory.NeuspesnaPromena"]);
     })
   .then( () => {
    if (this.state.errors.duplicateUserOrganizationCategoryNameErrorMessage) {
      NotificationManager.error(this.state.errors.duplicateUserOrganizationCategoryNameErrorMessage)
    }
    else {
      NotificationManager.success(this.props.dictionary["updateUserOrganizationCategory.UspesnaPromena"]);
    }
   })
  };
  goBack = () => {
    this.props.history.push('/userOrganizationCategoryList');
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="component-wrapper-fade-in">
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto">
                <h1 className="text-center textShadow mt-5">
                 {this.props.dictionary["updateUserOrganizationCategory.izmenaKategorije"]}
                </h1>
                <hr />
                <form onSubmit={this.onSubmit} className="col-md-12">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="textShadow">
                        {this.props.dictionary["updateUserOrganizationCategory.nazivKategorije"]}{' '}
                        <span className="redAsterisk"> *</span>
                      </label>
                      <Input
                        type="text"
                        id="userOrganizationCategoryName"
                        placeholder={this.props.dictionary["updateUserOrganizationCategory.placeholder.nazivKategorije"]}
                        name="userOrganizationCategoryName"
                        value={this.state.userOrganizationCategoryName}
                        onChange={this.onChange}
                        errors={errors.userOrganizationCategoryName}
                      />
                      <div id="nameMsg" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="textShadow">
                       {this.props.dictionary["updateUserOrganizationCategory.komentar"]} <span className="redAsterisk"> *</span>
                      </label>
                      <TextArea
                        type="text"
                        id="comment"
                        placeholder={this.props.dictionary["updateUserOrganizationCategory.placeholder.komentar"]}
                        name="comment"
                        value={this.state.comment}
                        onChange={this.onChange}
                        errors={errors.comment}
                        maxLength="300"
                        rows={4}
                      />
                      <div id="commentМsg" />
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    <button
                      id="subscribeButton"
                      className="col-md-2 float-right"
                    >
                      {this.props.dictionary["updateUserOrganizationCategory.sacuvaj"]}
                    </button>
                    <button
                      id="subscribeButton"
                      className="col-md-2 float-right"
                       onClick={()=>this.goBack()}
                    >
                      {this.props.dictionary["updateUserOrganizationCategory.odustani"]}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UpdateUserOrganizationCategory.propTypes = {
  updateUserOrganizationCategory: PropTypes.func.isRequired,
  getUserOrganizationCategory: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userOrganizationCategory:
    state.userOrganizationCategory.userOrganizationCategory,
  errors: state.errors,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  updateUserOrganizationCategory,
  getUserOrganizationCategory,
  setMessage,
})(UpdateUserOrganizationCategory);
