import React from 'react';

const DeleteButton = (props) => {
  return (
    <div>
      <button className='noBorder' onClick={props.click}>
        {props.deactivation ? (
          <i className="fas fa-times deleteButton"></i>
        ) : (
          <i className="far fa-trash-alt deleteButton"></i>
        )}
      </button>
    </div>
  );
};

export default DeleteButton;
