import { useSelector } from 'react-redux';
import Select from "react-select";
import CommentSort from './CommentSort';

const CommentFilterSelect = ({filterId, columnFilters, setColumnFilters, sorting, setSorting, selectList}) => {

    const dictionary = useSelector(state => state.startUp.dictionary);

    const options = selectList.map((option) => ({
        ...option,
        label: option.value
    }));

    const onChangeHandler = (event) => {
        const oldFilter = columnFilters.filter(x => x.id != filterId);
        if (event) { 
            setColumnFilters([
                ...oldFilter,
                {
                    id: filterId,   
                    value: event.value
                }
            ]);
        } else {
            setColumnFilters([
                ...oldFilter
            ]);
        }   
    }

    const selectStyles = {
        control: (styles) => ({ ...styles, color: "white" }),
        input: (styles) => ({
            ...styles,
            color: "white"
            }),
        multiValueLabel: (styles) => ({
        ...styles,
        color: "white",
        fontSize: "14px",
        }),
        singleValue: (styles) => ({
            ...styles,
            color: "white"
        }),
        placeholder: (styles) => ({
        ...styles,
        color: "#adb5bd",
        fontSize: "14px"
        })
      }; 

    return (
        <div>
            <div>
                <label className="pe-2">{dictionary[`CommentFilterField.${filterId}`]}</label>
                <CommentSort filterId={filterId} sorting={sorting} setSorting={setSorting}/>
            </div>
            <div>
                <Select
                    id={filterId}
                    className="filter-select"
                    name={filterId}
                    options={options}
                    onChange={onChangeHandler}
                    placeholder={dictionary[`CommentFilterSelect.Placeholder.${filterId}`]}
                    isClearable
                    styles={selectStyles}
                /> 
            </div>
        </div>
    );
}

export default CommentFilterSelect;