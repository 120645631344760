import {
    GET_ACTION_PLAN_REPORT_LIST,
    GET_ACTION_PLAN_REPORT,
    DELETE_ACTION_PLAN_REPORT,
} from "../actions/types";

const initialState = {
   actionPlanReportList: [],
    actionPlanReport: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ACTION_PLAN_REPORT_LIST:
            return {
                ...state,
                actionPlanReportList: action.payload,
            };
        case GET_ACTION_PLAN_REPORT:
            return {
                ...state,
                actionPlanReport: action.payload,
            };
        case DELETE_ACTION_PLAN_REPORT:
            return {
                ...state,
                actionPlanReportList: state.actionPlanReportList.filter(
                    (apr) => apr.id !== action.payload
                ),
            };

        default:
            return state;
    }
}
