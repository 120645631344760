import React from 'react';
import '../../style/GoBackButton.css';
import { Link } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Fade } from '@mui/material';

const PdfPreviewerTutorial = (props) => {
  const showInfo =
    props.showInfo == 1 ? (
      <Fade timeout={900} appear={true} in={true}>
        <div className="show-info-content-style mt-4">
          <p>Корисник може коментарисати:</p>
          <ol>
            <li>
              <div className="form-group">
                <u>
                  <h6>Појединачне делове текста</h6>
                </u>
                <p>
                  <strong>
                    <em>Опис:</em>{' '}
                  </strong>
                  <span className="ml-1">
                    Превлачењем миша преко жељеног дела текста, отвара се прозор
                    у којем можете унети Ваш коментар. Да би коментар био
                    успешно послат,{' '}
                    <strong> неопходно је попунити сва поља форме!</strong>
                  </span>
                </p>
                <label className="small-font">
                  <strong>Напомена:</strong>{' '}
                  <em>
                    Уколико сте члан неке <strong>организације</strong>, након
                    клика на дугме "Сачувај" појавиће се нови прозор у којем
                    можете одабрати организацију уколико дајете коментар као
                    представник исте.{' '}
                  </em>
                </label>
              </div>
            </li>
            <li>
              <div className="form-group">
                <u>
                  <h6>Цео текст</h6>
                </u>
                <p>
                  <strong>
                    <em>Опис:</em>{' '}
                  </strong>
                  <span className="ml-1">
                    Кликом на дугме "Додај коментар", отвара се модал у којем
                    можете унети Ваш коментар. За овај тип коментара је{' '}
                    <strong>могуће приложити и документ </strong>
                    (.docx).
                  </span>
                </p>
              </div>
            </li>
          </ol>
        </div>
      </Fade>
    ) : (
      ''
    );

  const showInfoTitle =
    props.showInfo == 1 ? (
      <Fade timeout={400} appear={true} in={true}>
        <span> Упутство за додавање коментара</span>
      </Fade>
    ) : window.innerWidth > 760 ? (
      'Упутство'
    ) : (
      ''
    );

  // let wrapperStyle =
  //   props.showInfo == 1
  //     ? 'show-info-button-wrapper-open ml-auto'
  //     : 'show-info-button-wrapper ml-auto';

  return (
    <div onClick={props.onClick}>
      <Link className="pdfModalButton" to="#">
        <span className="white-link-text">
          <HelpOutlineIcon /> {showInfoTitle}
        </span>
      </Link>
      {showInfo}
    </div>
  );
};

export default PdfPreviewerTutorial;
