import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Input from '../Reusable/Input';
import ComboReusable from '../Reusable/ComboReusable';
import { setMessage } from '../../utils';
import { createFieldOfInterest } from '../../actions/fieldOfInterestActions';
import { NotificationManager } from 'react-notifications';
import { CREATE_FIELD_OF_INTEREST_SUCCESS } from '../../actions/types'


const ModalForAddFieldOfInterest = (props) => {

    const dispatch = useDispatch();
    const dictionary = useSelector(state => state.startUp.dictionary);
    const isJLS = useSelector(state => state.security.jls);
    const actTypeTiedToFieldOfInterest = x=>x.taxonomyValueInformationList.some(y=>y.informationName=="actTypeTiedToFieldOfInterest" && y.informationValue=='true');
    const actTypeisJLS = isJLS ? x=>x.taxonomyValueInformationList.some(y=>y.informationName=='JLS' && y.informationValue=='true') : x=>!x.taxonomyValueInformationList.some(y=>y.informationName=='JLS' && y.informationValue=='true')
    //const actTypes = useSelector(state => state.startUp.taxonomies["ActType"].filter(x=>x.taxonomyValueInformationList.some(y=>y.informationName=="actTypeTiedToFieldOfInterest" && y.informationValue=='true')));
    const actTypes = useSelector(state => state.startUp.taxonomies["ActType"].filter(actTypeTiedToFieldOfInterest).filter(actTypeisJLS));
    const fieldOfInterestCreated = useSelector(state=>state.fieldOfInterest.fieldOfInterestCreated);
    const errors = useSelector(state=>state.errors);
    const [field, setField] = useState({
        name : "",
        status : true,
        actType : null
    });

    useEffect(()=>{
      if(fieldOfInterestCreated != null) {
          if(fieldOfInterestCreated==true)
              if(errors.duplicateFieldOfInterestNameErrorMessage) NotificationManager.error(errors.duplicateFieldOfInterestNameErrorMessage,dictionary["universal.GreskaUObradi"], 5000);
              else NotificationManager.success(dictionary["ModalForAddFieldOfInterest.UspesnoDodavanje"],dictionary["universal.Uspesno"], 5000);
          else NotificationManager.error(dictionary["ModalForAddFieldOfInterest.DodavanjeNeuspelo"], dictionary["universal.GreskaUObradi"], 5000);  
          dispatch({
              type: CREATE_FIELD_OF_INTEREST_SUCCESS,
              payload: null,
            });
          clearErrors();
      }
  },[fieldOfInterestCreated, props.error])
  const clearErrors = () => {
      errors.duplicateFieldOfInterestNameErrorMessage = null;
  }
    const onChange = (e) => {
        const {name,value} = e.target;
        setField(prevField => ({...prevField, [name]:value}));
    };
    const handleComboBoxChange = () => {
        return (e) => {
            const {name,value} = e.target;
            setField(prevField => ({...prevField, [name]:{['id']:value}}));
            console.log(field);
        };
      };
    const handleClose = (refresh) => {
        setField({
            name : "",
            status : true,
            actType : null
        });
        props.handleClose(refresh);
    };
    const handleValidation = () => {
        let error = false;
        if (field.name == '') {
            dispatch(setMessage(`${dictionary["AddFieldOfInterest.Validation.NazivOblasti"]}`, '#name', '#validationErrorName'));
            error = true;
          }
          if (field.actType == null) {
            dispatch(setMessage(`${dictionary["AddFieldOfInterest.Validation.TipOblasti"]}`, '#actTypeCb', '#validationErrorActType'));
            error = true;
          }
          return error;
    }
    const onSubmit = () => {
        if(handleValidation()){
            return;
        }
        dispatch(createFieldOfInterest(field))
        .then( () => {
            //NotificationManager.success(dictionary["ModalForAddFieldOfInterest.UspesnoDodavanje"],dictionary["universal.Uspesno"], 5000);
            dispatch({
              type: CREATE_FIELD_OF_INTEREST_SUCCESS,
              payload: true,
            });
          })
        .catch( () => { 
            //NotificationManager.error(dictionary["ModalForAddFieldOfInterest.DodavanjeNeuspelo"], dictionary["universal.GreskaUObradi"], 5000);
            dispatch({
              type: CREATE_FIELD_OF_INTEREST_SUCCESS,
              payload: false,
            });
          })
        .finally ( () => { 
                handleClose(true); 
            });
    }

    return(
        <Modal
            show={props.show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Body>
                <h4 className='text-center'>{dictionary["AddFieldOfInterest.Unos"]}</h4>
                <hr/>
                <div className='col-md-12'>
                    <label className="labelText textShadow">
                    {dictionary["AddFieldOfInterest.NazivOblasti"]}:
                    </label>
                   <span className="redAsterisk"> *</span>
                    <Input
                      type="text"
                      id="name"
                      placeholder={dictionary["AddFieldOfInterest.Placeholder.NazivOblasti"]}
                      name="name"
                      value={field.name}
                      onChange={onChange}
                      errors={errors.name}
                    />
                    <div id="validationErrorName" />
                </div>
                <div className="col-md-12 mt-3">
                    <label className="labelText textShadow">{dictionary["AddFieldOfInterest.TipOblasti"]}:</label>
                    <span className="redAsterisk"> *</span>
                    <ComboReusable
                        id="actTypeCb"
                        name="actType"
                        namePart={dictionary["addFieldOfInterest.namePart.tip"]}
                        items={actTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.value}
                          </option>
                        ))}
                        updateValue = {field.actType?.id}
                        onChange={handleComboBoxChange()}
                    />
                    <div id="validationErrorActType" />
                  </div>
                  <div className="col-md-12 mt-4">
                    <button
                      id="subscribeButton"
                      type="submit"
                      className="col-md-2 float-right"
                      onClick={()=>onSubmit()}
                    >
                      {dictionary["AddFieldOfInterest.Sacuvaj"]}
                    </button>
                    <button
                      id="subscribeButton"
                      type="button"
                      className="col-md-2 float-right"
                       onClick={()=>handleClose()}
                    >
                      {dictionary["AddFieldOfInterest.Odustani"]}
                    </button>
                  </div>
            </Modal.Body>
            </Modal.Header>
        </Modal>
    );
};
export default ModalForAddFieldOfInterest;