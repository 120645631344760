import React, { Component } from 'react';
import '../../style/SearchFilter.css';

class SearchFilter extends Component {
  constructor(props) {
    super(props);
  }

  filterTable() {
    var input, filter, table, tr, td, i, j, cell, noSearchColums;

    input = this.props.userStatus
      ? document.getElementById('searchInputRegistered')
      : document.getElementById('searchInput');
    filter = input.value.toUpperCase();
    table = this.props.userStatus
      ? document.getElementById('registeredUserTableId')
      : document.getElementById('example');
    tr = table.tBodies[0].getElementsByTagName('tr');
    noSearchColums = this.props.noSearchColums;

    for (i = 0; i < tr.length; i++) {
      tr[i].style.display = 'none';
      td = tr[i].getElementsByTagName('td');

      for (var j = 0; j < td.length - noSearchColums; j++) {
        cell = tr[i].getElementsByTagName('td')[j];
        if (cell) {
          if (cell.innerHTML.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = '';
            break;
          }
        }
      }
    }
  }

  render() {
    var param = this.props.param;
    let idName = this.props.userStatus
      ? 'searchInputRegistered'
      : 'searchInput';

    let searchFilterStyle = this.props.dashboardRequest
      ? 'search-filter-dashboard'
      : 'search-filter form-row';

    let searchfilterPlaceholderStyle = this.props.dashboardRequest
      ? 'search-filter-dashboard-placeholder col-11'
      : 'search-filter-placeholder col-12';

    return (
      <div className="container-fluid">
        <div className="row text">
          <div className={searchFilterStyle}>
            <span className="search-filter-icon display-inline">
              <img src="/images/search.png" id="search-filter-img" />
            </span>
            <input
              type="text"
              className={searchfilterPlaceholderStyle}
              id={idName}
              onKeyUp={this.filterTable.bind(this)}
              placeholder="Претрага..."
              value={param}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SearchFilter;
