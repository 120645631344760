import { Card } from 'react-bootstrap';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Link } from 'react-router-dom';
import SurveyActionBar from './SurveyActionBar';
import CommentGatheringActionBar from './CommentGatheringActionBar';
import ModalForUpdateMethod from './ModalForUpdateMethod';
import ModalForMethodDocuments from '../MethodDocument/ModalForMethodDocuments';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ExternalMembersActionBar from '../ExternalMembers/ExternalMembersActionBar';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
import LinkIcon from '@mui/icons-material/Link';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { findMethodById } from "../../actions/methodActions";
import { getTopicOfDiscussionDocuments } from '../../actions/topicOfDiscussionActions';
import { getMethodList } from '../../actions/methodActions';

const MethodCardFooter = ({method, isModerator, phase, topicOfDiscussionId, handleMethodRefresh, endProcess}) => {
    
    const dictionary = useSelector(state => state.startUp.dictionary);
    const user = useSelector(state => state.security);

    const [showModalForUpdateMethod, setShowModalForUpdateMethod] = useState(false);
    const [showModalForMethodDocuments, setShowModalForMethodDocuments] = useState(false);
    const [showModalForDocumentPreview, setShowModalForDocumentPreview] = useState(false);

    const dispatch = useDispatch();

   
    const handleShowModalForDocumentPreview = (id) => {
        dispatch(findMethodById(id));
        setShowModalForDocumentPreview(true);
    };
    const handleCloseModalForDocumentPreview = () => {
        setShowModalForDocumentPreview(false);
    };
    
    const handleShowModalForUpdateMethod = (id) => {
        setShowModalForUpdateMethod(true);
    }
    const handleCloseModalForUpdateMethod = () => {
        dispatch(getMethodList(topicOfDiscussionId, phase.id));
        setShowModalForUpdateMethod(false);
    }
    const handleShowModalForMethodDocuments = () => {
        setShowModalForMethodDocuments(true);
    }
    const handleCloseModalForMethodDocuments = (id) => {
        setShowModalForMethodDocuments(false)
    }

    return (
            <Card.Footer className="rounded-0 border-0 bg-white d-flex justify-content-around">
                {method.methodType == "Obavestenje" && method.linkPath &&
                    <Link onClick={(e) => {
                    }} 
                    to={{ pathname: method.linkPath }} target="_blank" 
                    className="tableFooterLinks method-links">
                        <LinkIcon/>
                        <span>{dictionary["Method.Link"]}</span>
                </Link>  
                }
                {(method.methodType != "PrikupljanjeKomentara" && method.methodType != "JavniPozivOCD" && method.documentNumber > 1) && 
                    <Link onClick={(e) => {
                            e.preventDefault();
                            handleShowModalForMethodDocuments(method.id);
                        }} 
                        to="#" 
                        className="tableFooterLinks method-links">
                            <FindInPageIcon/>
                            <span>{dictionary["Method.Prilozi"]}</span>
                    </Link>    
                }
                {(method.methodType != "PrikupljanjeKomentara" && method.methodType != "JavniPozivOCD" && method.documentNumber == 1) && 
                    <Link onClick={(e) => {
                    e.preventDefault();
                    handleShowModalForDocumentPreview(method.id);
                     }} 
                    to="#" 
                    className="tableFooterLinks method-links">
                        <FindInPageIcon/>
                        <span>{dictionary["Method.Prilog"]}</span>
                    </Link>    
                }
                {method.methodType == "Anketa" && 
                <SurveyActionBar
                    method={method}
                    isModerator={isModerator}
                    authRole={user.authRole}
                    phase={phase}
                    topicOfDiscussionId={topicOfDiscussionId}
                    handleMethodRefresh={handleMethodRefresh}
                    endProcess={endProcess}
                />
                }
                {method.methodType == "PrikupljanjeKomentara" && 
                    <CommentGatheringActionBar
                        method={method}
                        isModerator={isModerator}
                        authRole={user.authRole}
                        phase={phase}
                        topicOfDiscussionId={topicOfDiscussionId}
                        endProcess={endProcess}
                    />
                }
                {(method.methodType == "JavniPozivOCD") && 
                    <ExternalMembersActionBar
                        method={method}
                        isModerator={isModerator}
                        authRole={user.authRole}
                        phase={phase}
                        topicOfDiscussionId={topicOfDiscussionId}
                        endProcess={endProcess}
                    />
                }
                {(method.methodType != "Anketa" && isModerator && !endProcess) && 
                    <Link onClick={(e) => {
                            e.preventDefault();
                            dispatch(getTopicOfDiscussionDocuments(topicOfDiscussionId));
                            handleShowModalForUpdateMethod(method.id);
                        }} 
                        to="#" 
                        className="tableFooterLinks method-links">
                            <EditRoundedIcon/>
                            <span>{dictionary["Method.Izmeni"]}</span>
                    </Link>    
                }
                {showModalForUpdateMethod &&
                        <ModalForUpdateMethod 
                            show={showModalForUpdateMethod}
                            handleClose={handleCloseModalForUpdateMethod}
                            handleMethodRefresh={handleMethodRefresh}
                            method={method}
                            phase={phase}
                            topicOfDiscussionId={topicOfDiscussionId}
                        />
                        } 
                {showModalForMethodDocuments &&
                        <ModalForMethodDocuments 
                            show={showModalForMethodDocuments}
                            handleClose={handleCloseModalForMethodDocuments}
                            method={method}
                            phase={phase}
                            topicOfDiscussionId={topicOfDiscussionId}
                        />
                        } 
                {showModalForDocumentPreview && (
                    <ModalForDocumentPreview
                        show={showModalForDocumentPreview}
                        documentName={method?.documents[0]?.uuidDocumentName}
                        controllerReference={'document'}
                        handleClose={handleCloseModalForDocumentPreview}
                    />
                )}
            </Card.Footer>);
}

export default MethodCardFooter;