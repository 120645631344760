import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Input from '../Reusable/Input';
import { createUserOrganizationCategory } from '../../actions/userOrganizationCategoryActions';
import { setMessage } from '../../utils';
import { NotificationManager } from 'react-notifications';
import { CREATE_USER_ORGANIZATION_CATEGORY_SUCCESS } from '../../actions/types'

const ModalForAddUserOrganizationCategory = (props) => {
    const dispatch = useDispatch();
    const dictionary = useSelector(state => state.startUp.dictionary);
    const errors = useSelector(state=>state.errors);
    const userOrganizationCategoryCreated = useSelector(state=>state.userOrganizationCategory.userOrganizationCategoryCreated);

    const emptyUserOrganizationCategory = {
        userOrganizationCategoryName:""
    };
    const [userOrganizationCategory, setUserOrganizationCategory] = useState(emptyUserOrganizationCategory);

    useEffect(()=>{
        if(userOrganizationCategoryCreated != null) {
            if(userOrganizationCategoryCreated==true)
                if(errors.duplicateUserOrganizationCategoryNameErrorMessage) NotificationManager.error(errors.duplicateUserOrganizationCategoryNameErrorMessage,dictionary["universal.GreskaUObradi"], 5000);
                else NotificationManager.success(dictionary["addUserOrganizationCategory.UspesnoDodavanje"],dictionary["universal.Uspesno"], 5000);
            else NotificationManager.error(dictionary["addUserOrganizationCategory.NeuspesnoDodavanje"],dictionary["universal.GreskaUObradi"], 5000);  
            dispatch({
                type: CREATE_USER_ORGANIZATION_CATEGORY_SUCCESS,
                payload: null,
              });
        }
    },[userOrganizationCategoryCreated, props.error])


    const onChange = (e) => {
        const {name,value} = e.target;
        setUserOrganizationCategory(prevUserOrganizationCategory => ({...prevUserOrganizationCategory, [name]:value}));
    };
    const handleClose = (refresh) => {
        setUserOrganizationCategory(emptyUserOrganizationCategory);
        props.handleClose(refresh);
    };
    const handleValidation = () => {
        let error = false;
        if (userOrganizationCategory.userOrganizationCategoryName == '') {
          dispatch(setMessage(dictionary["addUserOrganizationCategory.Validation.Kategorija"],'#userOrganizationCategoryName','#validationErrorUserOrganizationCategoryName'));
          error = true;
        }
        return error;
    };
    const handleSubmit = (e) => {
        
        e.preventDefault();
        
        if (handleValidation() == true) {
          return;
        }

        dispatch(createUserOrganizationCategory(userOrganizationCategory))
        .catch( (err) => {
            dispatch({
                type: CREATE_USER_ORGANIZATION_CATEGORY_SUCCESS,
                payload: false,
              });
          })
        .then( () => {
            dispatch({
                type: CREATE_USER_ORGANIZATION_CATEGORY_SUCCESS,
                payload: true,
              });
        })
        .finally ( () => { 
            handleClose(true); 
        });
    };

    return(<Modal
        show={props.show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered 
        >
    <Modal.Header closeButton>
    <Modal.Body>
        <h4 className="text-center textShadow mt-5">{dictionary["addUserOrganizationCategory.naslov"]}{' '}</h4>
        <hr />
        <div className="form-group">
        <label className="textShadow">
            {dictionary["addUserOrganizationCategory.nazivKategorije"]}<span className="redAsterisk"> *</span>
        </label>
        <Input
            type="text"
            id="userOrganizationCategoryName"
            placeholder={dictionary["addUserOrganizationCategory.placeholder.nazivKategorije"]}
            name="userOrganizationCategoryName"
            value={userOrganizationCategory.userOrganizationCategoryName}
            onChange={onChange}
            errors={errors.userOrganizationCategoryName}
        />
        <div id="validationErrorUserOrganizationCategoryName" />
        </div>
        <div className="col-md-12 mt-4">
        <button id="subscribeButton" 
                className="col-md-2 float-right"
                onClick={handleSubmit}
        >
            {dictionary["addUserOrganizationCategory.sacuvaj"]}
        </button>
        <button id="subscribeButton"
                className="col-md-2 float-right"
                onClick={handleClose}
        >
            {dictionary["addUserOrganizationCategory.odustani"]}
        </button>
        </div>
    </Modal.Body>
    </Modal.Header>
    </Modal>);
}
export default ModalForAddUserOrganizationCategory;