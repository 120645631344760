import React from "react";
import { Link } from "react-router-dom";

const CreateButtonModal = (props) => {
  return (
    <div className={props.className}>
      <Link to="#" onClick={(e) => { props.onClickHandler(); }} >
        <button className={props.buttonClass==null?"create-button":props.buttonClass}>{props.label==null?"Додај":props.label}</button>
      </Link>
    </div>
  );
};

export default CreateButtonModal;
