import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  getAllByUserId,
  createFieldOfInterestUserList,
} from '../../actions/fieldOfInterestUserActions';
import { getActiveInstitutionsWithoutSuperAdminInstitution } from '../../actions/institutionActions';
import { setMessage } from '../../utils';
import { trackPromise } from "react-promise-tracker";
import {
  NotificationManager,
} from 'react-notifications';

class ModalForChoosingInstitutionsOfInterest extends React.Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
      selectedInstitutions: [],
    };
    this.handleCreationDateChange = this.handleCreationDateChange.bind(this);
    this.handleInstitutionMultiselectChange =
      this.handleInstitutionMultiselectChange.bind(this);
  }

  handleCreationDateChange = (date) => {
    this.setState({ creationDate: date });
  };

  handleComboBoxChange = (object, objectId) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
        fieldId: e.target.value,
        comboSignal: object,
      });
    };
  };

  handleClose = () => {
    this.props.handleClose();
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    this.props.getAllByUserId(this.props.userId);
    this.props.getActiveInstitutionsWithoutSuperAdminInstitution();
  }

  handleInstitutionMultiselectChange(selectedOptions) {
    this.setState({ selectedInstitutions: selectedOptions || [] });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const { institutions } =
      nextProps.fieldOfInterestInstitutionUser
        .fieldOfInterestInstitutionUserList;

    this.setState({
      selectedInstitutions: institutions
        ? institutions.map((field) => ({
            ...field,
            value: field.id,
            label:
              field.currentlyActive == true
                ? field.institutionName
                : field.institutionName + ' (неактивно)',
          }))
        : [],
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const listSelectedInstitutions = this.state.selectedInstitutions;
    const newFUO = {
      institutions: listSelectedInstitutions,
      type: 'institutions',
    };
    trackPromise(this.props
      .createFieldOfInterestUserList(newFUO))
      .then(() => this.props.handleClose())
      .then(() => {
        NotificationManager.success(this.props.dictionary["ModalForChoosingInstitutionsOfInterest.UspesanUnos"],this.props.dictionary["universal.Uspesno"], 5000);
      })
      .catch( () => {
        NotificationManager.error(this.props.dictionary["ModalForChoosingInstitutionsOfInterest.NeuspesanUnos"], this.props.dictionary["universal.GreskaUObradi"], 5000);
      });
  };
  render() {
    let institutions = [...this.props.institution.institutions];
    institutions.sort(function (a, b) {
      return a.institutionName.localeCompare(b.institutionName);
    });
    const institutions1 = institutions.map((i) => ({
      ...i,
      value: i.id,
      label:
        i.currentlyActive == true
          ? i.institutionName
          : i.institutionName + ' (неактивно)',
    }));
    let options = [];
    let options2 = [];
    for (var i = 0; i < institutions1.length; i++) {
      options2.push(institutions1[i]);
    }
    const customStyleForMultiselect = {
      control: (styles) => ({ ...styles, minHeight: '45px' }),
      option: (styles) => ({
        ...styles,
        color: '#495057',
        fontSize: '17px',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
        marginLeft: '12px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
      }),
      input: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '1.25rem',
        marginLeft: '12px',
      }),
    };
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="modalForChoosingInstitutionsOfInterest"
      >
        <Modal.Header className='modalHeader'>
            <Modal.Title>
                {this.props.dictionary["menu.Profil.InstitucijeOdInteresovanja"]}
            </Modal.Title>
            <button onClick={this.props.handleClose} type="button" className="btn-close btn-close-white m-0 opacity-1" aria-label="Close"></button>
        </Modal.Header> 
        <Modal.Body>  
          <div>
            <div className="form-group col-md-12">
            <label className="labelText textShadow">{this.props.dictionary["ModalForChoosingInstitutionsOfInterest.InstitucijeOdInteresovanja"]}</label>
              <Select
                isMulti
                name="Institutions"
                options={options2}
                onChange={this.handleInstitutionMultiselectChange}
                placeholder={this.props.dictionary["menu.Profil.Placeholder.Institucije"]}
                id="multi2"
                value={this.state.selectedInstitutions}
                styles={customStyleForMultiselect}
              />
            </div>
            <div className="row mt-5 mb-2 justify-content-center">
              <button
                type="button"
                id="subscribeButton"
                className="col-md-3 me-0"
                onClick={this.handleSubmit}
              >
                {this.props.dictionary["menu.Profil.Sacuvaj"]}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ModalForChoosingInstitutionsOfInterest.propTypes = {
  getActiveInstitutionsWithoutSuperAdminInstitution: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  institution: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  institution: state.institution,
  fieldOfInterestInstitutionUser: state.fieldOfInterestInstitutionUser,
  userRoles: state.security.userRoles,
  authRole: state.security.authRole,
  userId: state.security.userId,
  authUser: state.security.authUser,
  dictionary: state.startUp.dictionary,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getActiveInstitutionsWithoutSuperAdminInstitution,
  setMessage,
  createFieldOfInterestUserList,
  getAllByUserId,
})(ModalForChoosingInstitutionsOfInterest);
