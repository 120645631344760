import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import RoleChooser from './RoleChooser';
import { setRoleOfAuthUser, acceptCookiesPolicy } from '../../actions/securityActions';
import setAuthUserRole from '../../securityUtils/setAuthUserRole';
import { trackPromise } from 'react-promise-tracker';
import CookieInfoModal from './CookieInfoModal';

class ChooseRoleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: 0,
      showCookieModal: false,
      oneRoleSignal: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const hasOneRole = nextProps?.userRoles?.length === 1;
    hasOneRole &&
      this.setState({
        oneRoleSignal: hasOneRole,
        showCookieModal: hasOneRole,
      });
  }

  handleClose = () => {
    this.props.handleClose();
  };

  openCookieModalHandler = () => {
    const role = this.state.selectedRole;
    setAuthUserRole(role);
    trackPromise(
    this.props.setRoleOfAuthUser(role,this.props.authUser)).finally(
      () => {
        const dateCookies = new Date(this.props.cookiesPolicyAcceptedAt);
        const dateNow = new Date();
        const diffTime = Math.abs(dateNow - dateCookies)/1000/60/60/24;
        if(diffTime>1){
          this.setState({ showCookieModal: true });
        }
        else{
          this.setState({ showCookieModal: false });
          this.props.chooseRole();
        }
      }
      );;
      
  };

  handleSubmit = () => {
    this.openCookieModalHandler();
  };

  cookieSubmitHandler = (acceptAnalyticsCookies) => {
    this.setState({ showCookieModal: false });
    this.props.acceptCookiesPolicy(acceptAnalyticsCookies);
    if (this.state.oneRoleSignal) {
      const roleList = this.props.userRoles;
      let roleForSet = roleList[0].role;
      this.handleRoleChanged(roleForSet?.id);
      this.props.history.push('/dashboard');
    } else {
      this.props.chooseRole();
    }
  };

  handleRoleChanged = (role) => {
    this.setState({ selectedRole: role });
  };

  render() {
    const { errors } = this.props;
    

    let UserNotActiveDiv;
    const userNotActive = () => {
      if (errors.notActiveUserErrorMessage) {
        return (
          <div className="alert alert-danger text-center" role="alert">
            {errors.notActiveUserErrorMessage}
          </div>
        );
      }
    };
      UserNotActiveDiv = userNotActive();
      const roleCount = this.props.userRoles.length;
      return (
        <Fragment>
        {roleCount > 1 && (
          <Modal
            show={this.props.show}
            onHide={this.handleClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>{this.props.dictionary["chooseRoleModal.naslov"]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                {UserNotActiveDiv}
                <RoleChooser
                  handleRoleChanged={this.handleRoleChanged}
                  userRoles={this.props.userRoles.map(item =>  item.role)}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button id="declineButton" onClick={this.handleClose}>
              {this.props.dictionary["chooseRoleModal.odustani"]}
              </Button>
              <Button id="subscribeButton" onClick={this.handleSubmit}>
              {this.props.dictionary["chooseRoleModal.prihvati"]}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {this.state.showCookieModal && (
          <CookieInfoModal
            show={this.state.showCookieModal}
            handleSubmit={this.cookieSubmitHandler}
          />
        )}
      </Fragment>
      );
  }
}
const mapStateToProps = (state) => ({
  userRoles: state.security.userRoles,
  authRole: state.security.authRole,
  userId: state.security.userId,
  authUser: state.security.authUser,
  cookiesPolicyAcceptedAt: state.security.cookiesPolicyAcceptedAt,
  alowedAnalytics: state.security.alowedAnalytics,
  dictionary: state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  setRoleOfAuthUser,
  acceptCookiesPolicy,
})(ChooseRoleModal);
