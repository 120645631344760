import {
  GET_OGP_STEP,
  GET_OGP_STEP_LIST,
  DELETE_OGP_STEP,
  CREATE_OGP_STEP,
  UPDATE_OGP_STEP,
} from "../actions/types";

const initialState = {
  ogpStepList: [],
  ogpStep: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OGP_STEP_LIST:
      return {
        ...state,
        ogpStepList: action.payload,
      };
    case GET_OGP_STEP:
      return {
        ...state,
        ogpStep: action.payload,
      };
    case DELETE_OGP_STEP:
      return {
        ...state,
        ogpStepList: state.ogpStepList.filter(
          (ogpStep) => ogpStep.id !== action.payload
        ),
      };

    case CREATE_OGP_STEP:
      return {
        ...state,
        ogpStepList: state.ogpStepList.concat(action.payload),
      };
    case UPDATE_OGP_STEP:
      return {
        ...state,
        ogpStepList: state.ogpStepList.map((ogpStep) =>
          ogpStep.id === action.payload.id ? action.payload : ogpStep
        ),
      };

    default:
      return state;
  }
}
