import React, { useState }  from 'react';

import DeleteButton from '../Reusable/DeleteButton';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Tr, Td } from 'react-super-responsive-table';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { deleteMethodDocument, findMethodById } from '../../actions/methodActions';
import { trackPromise } from 'react-promise-tracker';
import { RESET_ERRORS } from '../../actions/types';

const MethodDocumentRow = (props) =>  {

    const dictionary = useSelector(state => state.startUp.dictionary);
    const dispatch = useDispatch();
    const showModal = () => {
        props.handleShowModal(props.methodDocument.uuidDocumentName);
    };

  const onDeleteClick = (id,uuidDocName) => {
    const action = () => {
        trackPromise(dispatch(deleteMethodDocument(id,uuidDocName)))
        .catch((err) => {
          showDeleteValidationMessage(err.response.data.objectName, err.response.data.defaultMessage);
        })
        .then(() => {
          dispatch(findMethodById(id))
          dispatch({
            type: RESET_ERRORS,
            payload: { objectName: null, defaultMessage: null},
          })
        })
    };
    confirmDialog('избришете одабрани документ', action, ', обриши!');
  };

  const showDeleteValidationMessage = (objectName, defaultMessage) => {
    document.getElementById(objectName)?.classList.add('alert-danger');
      document.getElementById(`${objectName}Message`).style.color = 'red';
      document.getElementById(`${objectName}Message`).innerHTML = '<p>' + defaultMessage + '</p>';
      
      dispatch({
        type: RESET_ERRORS,
        payload: { objectName: null, defaultMessage: null},
      })

      setTimeout(function () {
        if (document.getElementById(`${objectName}Message`) != null) {
          document.getElementById(`${objectName}Message`).innerHTML = '';
          document.getElementById(`${objectName}Message`).classList.remove('alert-danger');
          document.getElementById(objectName).classList.remove('alert-danger');
        }
      }, 3000);
  }

    return (
      <>
        <Tr>
        <Td><label
            id="uploadedDocument"
          >{props.methodDocument.documentName}<span id="uploadedDocumentMessage"></span></label></Td>
        {!props.modalForPreviewSignal ? (
          <Td className="text-center">
            <DeleteButton
              click={() => onDeleteClick(props.methodId, props.methodDocument.uuidDocumentName)}
            />
          </Td>
        ) : (
          <Td className="text-center">
            <Link onClick={(e) => {e.preventDefault(); showModal();}} to="#">
              {dictionary["Document.Pogledaj"]}
            </Link>
          </Td>
        )}
      </Tr>
      </>
    );
}


export default MethodDocumentRow;
