import React, { useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import ModalForAddDocument from '../Document/ModalForAddDocument';
import DocumentTable from '../Document/DocumentTable';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddBoxIcon from '@mui/icons-material/AddBox';
import authorizationService from '../../securityUtils/authorizationService';
import InputAdornment from '@mui/material/InputAdornment';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SaveSharpIcon from '@mui/icons-material/SaveSharp';
import CancelIcon from '@mui/icons-material/Cancel';
import { updateFinalVersionOfDocumentForTopicOfDiscussion } from '../../actions/topicOfDiscussionActions';
import { NotificationManager } from 'react-notifications';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import LinkIcon from '@mui/icons-material/Link';
import Alert from '@mui/material/Alert';

const ModalForTopicOfDiscussionDocuments = (props) => {
    const dispatch = useDispatch();
    const dictionary = useSelector(state => state.startUp.dictionary);
    const documentTypes = useSelector(state => state.startUp.taxonomies?.DocumentType);
    const user = useSelector(state => state.security);

    const finalVersionOfDocumentRef = useRef(null);

    const [showModalForAddDocument, setShowModalForAddDocument] = useState(false);
    const [modalForAddDocumentTitle, setModalForAddDocumentTitle] = useState('');
    const [modalForAddDocumentType, setModalForAddDocumentType] = useState('');

    const [availableDocumentTypes, setAvailableDocumentTypes] = useState([]);
    const [availableDocumentTypesAttachment, setAvailableDocumentTypesAttachment] = useState([]);
    const [availableDocumentTypesReport, setAvailableDocumentTypesReport] = useState([]);

    const [isEditingFinalVersionOfDocument, setEditingFinalVersionOfDocument] = useState(false);
    const [finalVersionOfDocument, setFinalVersionOfDocument] = useState(props.topicOfDiscussion.finalVersionOfDocumentAdoptedInParlament != null ? props.topicOfDiscussion.finalVersionOfDocumentAdoptedInParlament:'')
    const attachmentDocuments = props.topicOfDiscussionDocuments.filter(obj => !obj.documentType.code.includes('Izvestaj'));
    const reportDocuments = props.topicOfDiscussionDocuments.filter(obj => obj.documentType.code.includes('Izvestaj'));
    
    const isModerator = authorizationService.canAccessProcessEditMode(
        user.authRole,
        user.userId,
        props.processCreatorId,
        props.topicOfDiscussionModerators
    );

    useEffect(() => {
        let documentTypesAttachment = documentTypes.filter(obj => obj.taxonomyValueInformationList
            .some(el => el.informationName==='DokumentZaNaknadniUnosPrilog'));
        if(!props.topicOfDiscussion.endProcess) {
            documentTypesAttachment=documentTypesAttachment.filter(obj=>obj.code!='NacrtPredlogAktaNakonJavneRasprave')
        }
        const documentTypesReport = documentTypes.filter(obj => obj.taxonomyValueInformationList
            .some(el => el.informationName==='DokumentZaNaknadniUnosIzvestaj'));
        setAvailableDocumentTypesAttachment(documentTypesAttachment);
        setAvailableDocumentTypesReport(documentTypesReport);
    }, []);
    
    const handleClose = () => {
        props.handleClose();
    }

    const handlePreviewClose = () => {
        setShowModalForAddDocument(false);
    }
    const handleShowModalForAddDocument = (type) => {
        setModalForAddDocumentType(type);
        setModalForAddDocumentTitle(type === 'Attachment' ? dictionary["ModalForTopicOfDiscussionDocuments.DodavanjeDokumentacije"] : dictionary["ModalForTopicOfDiscussionDocuments.DodavanjeIzvestaja"]);
        setAvailableDocumentTypes(type==='Attachment' ? availableDocumentTypesAttachment:availableDocumentTypesReport);
        setShowModalForAddDocument(true);
    }

    const handleEditFinalVersionOfDocument = () => {
        setEditingFinalVersionOfDocument(true);
        if(finalVersionOfDocumentRef.current){
            finalVersionOfDocumentRef.current.querySelector('input').focus();
        }
    }

    const handleSaveFinalVersionOfDocument = () => {
        dispatch(updateFinalVersionOfDocumentForTopicOfDiscussion(props.topicOfDiscussionId, finalVersionOfDocument))
        .then(() => {
            NotificationManager.success(dictionary["ModalForTopicOfDiscussionDocuments.UspesnoSacuvanUsvojeniAkt"],dictionary["universal.Uspesno"], 5000);     
          })
          .catch( () => { 
            NotificationManager.error(dictionary["ModalForTopicOfDiscussionDocuments.NeuspesnoSacuvanUsvojeniAkt"], dictionary["universal.GreskaUObradi"], 5000);
          })
          .finally(() => {
            setEditingFinalVersionOfDocument(false);
        });
        
    }

    const handleCancelFinalVersionOfDocument = () => {
        setFinalVersionOfDocument(props.topicOfDiscussion.finalVersionOfDocumentAdoptedInParlament)
        setEditingFinalVersionOfDocument(false);
    }
    
    const handleFinalVersionOfDocumentChange = (e) => {
        setFinalVersionOfDocument(e.target.value);
    }

    
    return (
        <>
        <Modal
        show={props.show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="modalForTopicOfDiscussionDocuments"
      >
        <Modal.Header closeButton>
            <Modal.Title>
                {dictionary["ModalForTopicOfDiscussionDocuments.Naslov"]}
            </Modal.Title>
        </Modal.Header> 
        <Modal.Body className='scroll-80vh'>     
            <div className='col-md-12'>
                {(props.phase.publicDebate?.completed || props.topicOfDiscussion.endProcess) && (isModerator ? 
                    <div className='finalVersionOfDocument mb-3'>
                        <TextField 
                            ref={finalVersionOfDocumentRef}
                            fullWidth 
                            label={dictionary["ModalForTopicOfDiscussionDocuments.UsvojeniAkt"] +'      -'}
                            helperText={props.topicOfDiscussion.finalVersionOfDocumentAdoptedInParlament==null || props.topicOfDiscussion.finalVersionOfDocumentAdoptedInParlament=='' && dictionary["ModalForTopicOfDiscussionDocuments.UnesiteLinkUsvojeniAkt"]}
                            value={finalVersionOfDocument}
                            onChange={handleFinalVersionOfDocumentChange}
                            defaultValue={props.topicOfDiscussion.finalVersionOfDocumentAdoptedInParlament}
                            InputProps={{
                                readOnly: !isEditingFinalVersionOfDocument,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <a href={finalVersionOfDocument} target="_blank" >
                                            <OpenInNewIcon className="default-label-text-link open-in-new-icon"/>
                                        </a>
                                    </InputAdornment>
                                ),
                                endAdornment:(
                                    <InputAdornment position="start">
                                        <Divider sx={{height: 28, m: 0.5, fontWeight: 'bold', color: 'black', opacity:1}} orientation="vertical" />
                                    {isEditingFinalVersionOfDocument ? 
                                    <>
                                        <SaveSharpIcon className='default-label-text-link ms-2' onClick={handleSaveFinalVersionOfDocument} />
                                        <CancelIcon className='default-label-text-link ms-1' onClick={handleCancelFinalVersionOfDocument} />
                                    </>
                                    :
                                        <EditRoundedIcon className='default-label-text-link ms-2' onClick={handleEditFinalVersionOfDocument}/>
                                    }
                                    </InputAdornment>
                                )    
                                }}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        WebkitTextFillColor: 'black !important', 
                                        fontSize:'medium'
                                    },
                                  }}
                        />
                    </div>:
                    <>
                    {finalVersionOfDocument!=null && finalVersionOfDocument!='' && <div className='mb-3 finalVersionOfDocumentLink'>
                        <Chip 
                            icon={<LinkIcon/>} 
                            label={dictionary["ModalForTopicOfDiscussionDocuments.UsvojeniAkt"]}
                            variant="outlined" 
                            target="_blank" 
                            component="a" 
                            href={finalVersionOfDocument} 
                            clickable
                            sx={{
                                '& .MuiChip-label': {
                                    fontSize:'1rem'
                                },
                              }}
                        />
                    </div>}
                    </>
                    )
                }
                {
                 (props.phase.publicDebate==null && props.phase.consultativeProcess==null ? 
                    <Alert severity="warning">{dictionary["ModalForTopicOfDiscussionDocuments.NijeKreiranaNijednaFaza"]}</Alert>
                    :
                 <>
                    <div className='mt-2 mb-5'>
                        {isModerator && <Link
                            onClick={(e) => {
                                e.preventDefault();
                                handleShowModalForAddDocument('Attachment');
                            }}
                            className="default-label-text-link mb-1"
                            to="#"
                        >
                            <AddBoxIcon fontSize={'small'} />
                            <strong className="ps-1">{dictionary["ModalForTopicOfDiscussionDocuments.DodajPrilog"]}</strong>
                        </Link>}
                        {attachmentDocuments.length>0 && <DocumentTable 
                            topicOfDiscussionDocuments={attachmentDocuments}
                            isModerator={isModerator}
                            type='Attachment'
                            handleRefresh={props.handleRefresh}
                        />}
                    </div>
                    <div className='mt-2 mb-2'>
                        {isModerator && <Link
                            onClick={(e) => {
                                e.preventDefault();
                                handleShowModalForAddDocument('Report');
                            }}
                            className="default-label-text-link mb-1"
                            to="#"
                        >
                            <AddBoxIcon fontSize={'small'} />
                            <strong className="ps-1">{dictionary["ModalForTopicOfDiscussionDocuments.DodajIzvestaj"]}</strong>
                        </Link>}
                        {reportDocuments.length > 0 && <DocumentTable 
                            topicOfDiscussionDocuments={reportDocuments}
                            isModerator={isModerator}
                            type='Report'
                            handleRefresh={props.handleRefresh}
                        />}
                    </div>
                </>)
                }
            </div>   
        </Modal.Body> 
      </Modal>
      <ModalForAddDocument
          show={showModalForAddDocument}
          handleClose={handlePreviewClose}
          title={modalForAddDocumentTitle}
          phases={props.phase.phaseList}
          availableDocumentTypes={availableDocumentTypes}
          type={modalForAddDocumentType}
          topicOfDiscussionId={props.topicOfDiscussionId}
          handleRefresh={props.handleRefresh}
        />
      </>
    ); 
}
export default ModalForTopicOfDiscussionDocuments;