import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Tabs, Tab, Row, Col, Nav, Container } from "react-bootstrap";
import { getNotifications } from '../../actions/notificationActions';
import { getFieldOfInterestByUserIdList } from '../../actions/fieldOfInterestUserActions';
import { getInstitutionByUserIdList } from '../../actions/fieldOfInterestUserActions';
import { getAllTopicsWithUserActivityAndNotification } from '../../actions/topicOfDiscussionActions';
import { getNotificationByTopicOfDiscussionId } from '../../actions/notificationTopicOfDiscussionActions';
import NotificationList from "../Notification/NotificationList";
import NotificationsByFieldOfInterestTab from "../Dashboard/NotificationsByFieldOfInterestTab";
import NotificationsByInstitutionTab from "../Dashboard/NotificationsByInstitutionTab";
import NotificationsByTopicOfDiscussionTab from "../Dashboard/NotificationsByTopicOfDiscussionTab";
import { getNotificationsCounters } from '../../actions/notificationTopicOfDiscussionActions';
import { getNotificationsByFieldOfInterest } from '../../actions/notificationTopicOfDiscussionActions';
import { getNotificationsByInstitution } from '../../actions/notificationTopicOfDiscussionActions';
import { setSeenByCitizen } from '../../actions/notificationActions';
import {
    authorizationService,
    roleToIdMap,
  } from "../../securityUtils/authorizationService";
import ViewAttachedPdfDocumentWithHighlights from '../Reusable/ViewAttachedPdfDocumentWithHighlights';
import ModalForMethodComments from '../CommentDisplay/ModalForMethodComments';

const ModalForNotificationList = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary);
    const dispatch = useDispatch();
    const notificationTopicOfDiscussion = useSelector(state => state.notificationTopicOfDiscussion);
    const topicOfDiscussion = useSelector(state => state.topicOfDiscussion);
    const fieldOfInterestInstitutionUser = useSelector(state => state.fieldOfInterestInstitutionUser);

    const[activeTab, setActiveTab] = useState('topics');
    const[showModalForMethodComments, setShowModalForMethodComments] = useState(false);
    const[pdfModalProps, setPdfModalProps] = useState(null);
    
    const {
        notificationsCounters,
        notificationByTopicOfDiscussionIdList,
        notificationByFiledOfInterestList,
        notificationByInstitutionList,
      } = notificationTopicOfDiscussion;
    
    const { fieldOfInterestByUserIdList, institutionByUserIdList } =
    fieldOfInterestInstitutionUser || [];

    
    let numberOfNotificationsForTopicOfDiscussionList =
      notificationByTopicOfDiscussionIdList.length;
    let notificationTopicOfDiscussionIndicator =
      numberOfNotificationsForTopicOfDiscussionList >= 10
        ? "9+"
        : numberOfNotificationsForTopicOfDiscussionList;
        const { topicsOfDiscussionByUserId, topicOfDiscussionUserActivityListDTO, topicOfDiscussionUserActivityAndNotificationListDTO } = topicOfDiscussion;

    const handleSelect = (selectedTab) => {
        setActiveTab(selectedTab);
        if (selectedTab === "comments") {
          dispatch(getNotifications(props.userId));
        } else if (selectedTab === "fieldOfInterestTab") {
            dispatch(getFieldOfInterestByUserIdList(props.userId));
        } else if (selectedTab === "institutionTab") {
            dispatch(getInstitutionByUserIdList(props.userId));
        } else if (selectedTab === "topics") {
            dispatch(getAllTopicsWithUserActivityAndNotification())
        }
      };

    const handleComboNotificationsByFieldOfInterest = () => {
        return (e) => {
            if(e.fieldOfInterest.id!=null)
                dispatch(getNotificationsByFieldOfInterest(e.fieldOfInterest.id));
            else
                dispatch(getNotificationsByFieldOfInterest(e.target.value));
        };
    };
    const handleComboTopicsOfDiscussionByUserId = () => {
        return (e) => {
            if(e.id!=null)
                dispatch(getNotificationByTopicOfDiscussionId(e.id));
            else
                dispatch(getNotificationByTopicOfDiscussionId(e.target.value));
        };
    };

    const handleComboNotificationsByInstitution = () => {
        return (e) => {
          if(e.institution.id!=null)
            dispatch(getNotificationsByInstitution(e.institution.id));
          else
            dispatch(getNotificationsByInstitution(e.target.value));
        };
      };

    const handleShowModalForMethodComments = (e,documentData) => {  
        setShowModalForMethodComments(true);
        setPdfModalProps(documentData);
        dispatch(setSeenByCitizen(documentData.notificationId));
        dispatch(getNotificationsCounters());
        dispatch(getNotifications(props.userId));
      };

    const handlePreviewClose = () => {
        setShowModalForMethodComments(false);
    }
 
    return (
        <>
        {showModalForMethodComments && (
            <ModalForMethodComments
                show={showModalForMethodComments}
                handleClose={handlePreviewClose}
                uuidDocumentName={pdfModalProps.commentDocumentName}
                methodId={pdfModalProps.methodId}
            />
        )}
        <Modal
        show={props.show}
        onHide={props.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        id="modalForNotificationList"
        centered
      >
        <Modal.Header className='modalHeader'>
            <Modal.Title>
                {dictionary["menu.Profil.ObavestenjaOdInteresa"]}
            </Modal.Title>
            <button onClick={props.handleClose} type="button" class="btn-close btn-close-white m-0 opacity-1" aria-label="Close"></button>
        </Modal.Header> 
        <Modal.Body>  
            <div className="container-fluid p-0">
                <div className="text-left">
                <Tabs
                    defaultActiveKey="topics"
                    activeKey={activeTab}
                    onSelect={handleSelect}
                >
                    <Tab
                        eventKey="topics"
                        className="border border-top-0"
                        title={dictionary["menu.Profil.NotificationsByProcess"] +
                        (notificationsCounters.notificationsByTopicOfDiscussionCounter != null ? ' ('+notificationsCounters.notificationsByTopicOfDiscussionCounter+')' : '')
                    }
                    >
                        <NotificationsByTopicOfDiscussionTab
                            topicsOfDiscussionByUserId={topicOfDiscussionUserActivityAndNotificationListDTO}
                        
                            handleComboTopicsOfDiscussionByUserId={
                            handleComboTopicsOfDiscussionByUserId
                            }
                            notificationByTopicOfDiscussionIdList={
                            notificationByTopicOfDiscussionIdList
                            }
                            notificationTopicOfDiscussionIndicator={
                            notificationTopicOfDiscussionIndicator
                            }
                        />
                    </Tab>
                    <Tab
                        eventKey="comments"
                        className="border border-top-0"
                        title={dictionary["menu.Profil.NotificationsByComment"] +
                        (notificationsCounters.notificationsByCommentCounter != null ? ' ('+notificationsCounters.notificationsByCommentCounter+')' : '')
                    }
                    >
                        <NotificationList 
                            quickPreviewSignal={1}
                            handleShowModalForMethodComments={handleShowModalForMethodComments}  
                        />
                    </Tab>
                    {props.authRole == roleToIdMap.citizen || props.authRole==roleToIdMap.evaluator ? (
                    <Tab
                    eventKey="fieldOfInterestTab"
                    className="border border-top-0"
                    title={dictionary["menu.Profil.NotificationsByFieldOfInterest"] +
                    (notificationsCounters.notificationsByFieldOfInterestCounter != null ? ' ('+notificationsCounters.notificationsByFieldOfInterestCounter+')' : '')
                    }
                    >
                    <NotificationsByFieldOfInterestTab
                        fieldOfInterestByUserIdList={
                        fieldOfInterestByUserIdList
                        }
                        handleComboNotificationsByFieldOfInterest={
                        handleComboNotificationsByFieldOfInterest
                        }
                        notificationByFiledOfInterestList={
                        notificationByFiledOfInterestList
                        }
                    />
                    </Tab>
                    ): ""}
                    {props.authRole == roleToIdMap.citizen || props.
                    authRole==roleToIdMap.evaluator ? (
                    <Tab
                        className="border border-top-0"
                        eventKey="institutionTab"
                        title={dictionary["menu.Profil.NotificationsByInstitution"] +
                        (notificationsCounters.notificationsByInstitutionCounter != null ? ' ('+notificationsCounters.notificationsByInstitutionCounter+')' : '')
                        }
                    >
                    <NotificationsByInstitutionTab
                        institutionByUserIdList={institutionByUserIdList}
                        handleComboNotificationsByInstitution={
                        handleComboNotificationsByInstitution
                        }
                        notificationByInstitutionList={
                        notificationByInstitutionList
                        }
                    />
                    </Tab>
                    ): ""}
                </Tabs>
                </div>
                </div>
            </Modal.Body> 
        </Modal>
    </>
    ); 
}
export default ModalForNotificationList;