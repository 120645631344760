import React, { Component } from 'react';
import { connect } from 'react-redux';
import OgpDocumentRow from './OgpDocumentRow';
import authorizationService from '../../securityUtils/authorizationService';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';

class OgpDocumentTable extends Component {
  render() {
    let ogpDocumentList = this.props.ogpDocumentList.map((ogpDocument) => (
      <OgpDocumentRow
        key={ogpDocument.id}
        ogpDocument={ogpDocument}
      />
    ));

    return (
      <div className="table-responsive component-wrapper-fade-in">
        <Table id="example" className="table table-sm border-0">
          <Thead>
            <Tr className="border-0">
              <Th className="border-0 faded-text">{this.props.dictionary["OgpDocumentTable.PrilozenaDokumenta"]}</Th>
              <Th width="9%" className="text-center border-0 faded-text">
                {this.props.dictionary["OgpDocumentTable.Pregled"]}
              </Th>
              <Th width="9%" className="text-center border-0 faded-text">
                {this.props.dictionary["OgpDocumentTable.Preuzimanje"]}
              </Th>
              {authorizationService.canAccessOGPPageSpecificAreas(
                this.props.authRole
              ) && (
                <Th width="9%" className="text-center border-0 faded-text">
                  {this.props.dictionary["OgpDocumentTable.Brisanje"]}
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>{ogpDocumentList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps,{}) (OgpDocumentTable);
