import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";

class ComboSelect extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let placeholder = this.props.signal
      ? this.props.namePart
      : "Изаберите " + this.props.namePart;

    const customStyleForMultiselect = {
      control: (styles) => ({ ...styles, minHeight: "45px" }),
      option: (styles) => ({
        ...styles,
        color: "#495057",
        fontSize: "17px",
      }),
      placeholder: (styles) => ({
        ...styles,
        color: "#6c757d",
        fontSize: "17px",
        marginLeft: "12px",
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: "#6c757d",
        fontSize: "17px",
      }),
      input: (styles) => ({
        ...styles,
        color: "#6c757d",
        fontSize: "1.25rem",
        marginLeft: "12px",
      }),
    };

    return (
      <Select
        isMulti={this.props.isMulti}
        id={this.props.id}
        name={this.props.name}
        options={this.props.options}
        onChange={this.props.onChange}
        placeholder={placeholder}
        isClearable={this.props.isClearable}
        styles={customStyleForMultiselect}
        value={this.props.value}
      />
    );
  }
}

export default ComboSelect;
