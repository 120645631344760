import React, { Component, Fragment } from 'react';
import AuthService from '../../securityUtils/AuthService';
import { getUserRolesAndOptionallyCreateCitizen } from '../../actions/userActions';
import { setAuthUser} from '../../actions/securityActions';
import { connect } from 'react-redux';
import ChooseRoleModal from './ChooseRoleModal';
import setJWTToken from '../../securityUtils/setJWTToken';
import { trackPromise } from 'react-promise-tracker';
import authorizationService from '../../securityUtils/authorizationService';
import { setRoleOfAuthUser } from '../../actions/securityActions';
import { getUserAfterLogin } from '../../actions/userActions';
import { SET_LOGGED_USER, SET_ROLE_OF_LOGGED_USER } from '../../actions/types';
import store from '../../store';
import LoadingIndicator from '../Reusable/LoadingIndicator';

class SigninRedirectPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewShow: false,
      isLoading: true,
      errors: {},
    };
  }

  componentDidMount() {
    trackPromise(
      AuthService.getToken().then(() =>
        AuthService.getUser().then((user) => {
          store.dispatch({
            type: SET_LOGGED_USER,
            payload: user,
          });
            const citizen = {
            email: user.profile['http://schema.id.rs/claims/mail'],
            firstName: user.profile['http://schema.id.rs/claims/givenname'],
            jmbg: user.profile['http://schema.id.rs/claims/umcn'],
            lastName: user.profile['http://schema.id.rs/claims/familyname'],
            };
            this.props.getUserRolesAndOptionallyCreateCitizen(citizen);       
            setJWTToken(user.access_token);
        })
      )
    ).finally(
      () =>
     this.setState({
       isLoading: false, previewShow: true }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handlePreviewClose = () => {
    this.handleLogoutClick().then((window.location.href = '/')).catch( (error) => 
    {
      console.log(error)
    });
  };

  handleChooseRole = () => {
    authorizationService.canAccessAdminPanel(this.props.authRole)
  ? 
  this.props.history.push('/userList')
  : 
  authorizationService.canAccessOGPUpdateSpecificFields(
    this.props.authRole,this.props.userId
    )
  ? 
  this.props.history.push('/ogpPage/1')
  : 
  this.props.history.push('/dashboard');
  };

  handleLogoutClick() {
    return Promise.resolve(AuthService.logout());
  }

  render() {
    const { isLoading, errors } = this.state;
    //TODO zameniti ovaj modal za test i dev sa Test, a ako je za prod ostaviti
    if(isLoading || !this.props.userRoles)
      return(<LoadingIndicator />);
    else{
      return (
      <Fragment>
        {this.state.previewShow && (
          <ChooseRoleModal
            show={this.state.previewShow}
            handleClose={this.handlePreviewClose}
            chooseRole={this.handleChooseRole}
            history={this.props.history}
            errors={errors}
          />
        )}
      </Fragment>
    );
  }
}
}

const mapStateToProps = (state) => ({
  user: state.user,
  errors: state.errors,
  authUser: state.security.authUser,
  authRole: state.security.authRole,
  userRoles: state.security.userRoles,
  userId: state.security.userId,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  getUserRolesAndOptionallyCreateCitizen,
  setAuthUser,
  setRoleOfAuthUser,
  getUserAfterLogin,
})(SigninRedirectPage);
