import axios from 'axios';
import {
  GET_ERRORS,
  GET_INSTITUTION,
  GET_INSTITUTION_LIST,
  GET_INSTITUTION_LIST_FILTERED,
  DELETE_INSTITUTION,
  SET_INSTITUTION_LIST_TABLE_FILTER
} from './types';

export const createInstitution = (institution) => async (dispatch) => {
  try {
    await axios.post('/api/institution/create', institution);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
    throw err;
  }
};

export const createAndUpdateInstitution = (institution, id, history) => async (
  dispatch
) => {
  try {
    await axios.post(`/api/institution/createAndUpdate/${id}`, institution);
    history.push('/institutionList');
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateInstitution = (institution) => async (dispatch) => {
  try {
    await axios.put('/api/institution/update', institution);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
    throw err;
  }
};


export const getInstitutions = () => async (dispatch) => {
  const res = await axios.get('/api/institution/findAll');
  dispatch({
    type: GET_INSTITUTION_LIST,
    payload: res.data,
  });
};

export const getActiveInstitutionsWithoutSuperAdminInstitution = () => async (dispatch) => {
  const res = await axios.get('/api/institution/findAllActiveWithoutSuperAdminInstitution');
  dispatch({
    type: GET_INSTITUTION_LIST,
    payload: res.data,
  });
};

export const getFilteredInstitutions = (searchParams) => async (dispatch) => {
  try {
   const res = await axios.post('/api/institution/findAllFiltered', searchParams);
  dispatch({
    type: GET_INSTITUTION_LIST_FILTERED,
    payload: res.data,
  });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const getInstitutionsByStatus = () => async (dispatch) => {
  const res = await axios.get('/api/institution/findAllByStatus');
  dispatch({
    type: GET_INSTITUTION_LIST,
    payload: res.data,
  });
};
export const getInstitutionsForUpdateUser = (userId) => async (dispatch) => {
  const res = await axios.get(`/api/institution/findAllForUpdateUser/${userId}`);
  dispatch({
    type: GET_INSTITUTION_LIST,
    payload: res.data,
  });
};

export const getInstitution = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/institution/findById/${id}`);
    dispatch({
      type: GET_INSTITUTION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getInstitutionCanDelete = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/institution/findByIdCanDelete/${id}`);
    dispatch({
      type: GET_INSTITUTION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const deleteInstitution = (id) => async (dispatch) => {
  try {
  await axios.delete(`/api/institution/delete/${id}`);
  dispatch({
    type: DELETE_INSTITUTION,
    payload: id,
  });
  } catch (err) {
  dispatch({
    type: GET_ERRORS,
    payload: err.response.data,
  });
  throw err;
}
};  

export const setInstitutionListTableFilter =
(searchParams) => async (dispatch) => {
  try {
    dispatch({
      type: SET_INSTITUTION_LIST_TABLE_FILTER,
      payload: searchParams,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};
