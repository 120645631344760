import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import ViewAttachedDocument from './ViewAttachedDocument';
import LoadingIndicator from "../Reusable/LoadingIndicator";
import LoadingIndicatorOverlay from './LoadingIndicatorOverlay';
import {
  getDocument,
  removeDocument,
} from '../../actions/viewAttachmentAction';
import ViewAttachedExcelDocument from './ViewAttachedExcelDocument';
import '../../style/FilePreview.css';
import ViewAttachedDocumentWithoutZoom from './ViewAttachedDocumentWithoutZoom';
import DownloadDocumentButton from './DownloadDocumentButton';
import {
  NotificationManager,
} from 'react-notifications';
import { trackPromise } from 'react-promise-tracker';
class ModalForDocumentPreview extends React.Component {
  constructor(props) {
    super(props);
  this.state = {
    isLoading: true,
  };
  };  
  componentDidMount() {
    const { documentName, controllerReference, getDocument } = this.props || {};
    trackPromise(getDocument(documentName, controllerReference).finally(() => 
    this.setState({ isLoading: false })));
  }

  componentWillUnmount(){
    URL.revokeObjectURL(this.url);
  }

  handleClose = () => {
    this.props.removeDocument();
    this.props.handleClose();
  };

  extractFileFormat(documentName) {
    let format = documentName.substring(
      documentName.lastIndexOf('.') + 1,
      documentName.length
    );
    return format;
  }

  render() {
    const { isLoading } = this.state;
    const { largeModal, notWideModal } = this.props || {};
    const { documentContent } = this.props.viewAttachment.dokument;

    const format = this.extractFileFormat(this.props.documentName);

    const pdfOrText = format === 'txt' ? 'text/plain' : 'application/pdf';
    const isWideModal = !notWideModal && 'wide-modal';
    const modalSize = largeModal ? 'lg' : 'xl';
    if(!isLoading && this.props.viewAttachment.dokument===""){
      this.handleClose()  
    }


    let url = '';
    if(documentContent!=null) {
      const binaryData = atob(documentContent);
      const byteArray = new Uint8Array(binaryData.length);
    
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }
    
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      url = URL.createObjectURL(blob);
      this.url=url;
    }

    return (
    <>
      {isLoading ? (
        <LoadingIndicatorOverlay />
      ) : (
        <>
      {documentContent ? (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size={modalSize}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`secondModal ${isWideModal}`}
      >
        <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="col-md-12">
              {format !== 'pdf' &&
                format !== 'xlsx' &&
                format !== 'csv' &&
                format !== 'txt' && (
                  <ViewAttachedDocument
                    content={documentContent}
                    fileType={format}
                    documentName={this.props.documentName}
                  />
                )}
              {format === 'pdf' && largeModal && (
                <div className="pdf-attachment-wrapper">
                  <embed
                    src={this.url}
                    width="100%"
                    height="100%"
                    style={{ borderStyle: '1px solid rgba(128, 128, 128, 0.479)' }}
                    type={`${pdfOrText}`}
                  />
                </div>
              )}
              {(format === 'xlsx' || format === 'csv') && (
                <ViewAttachedExcelDocument content={documentContent} documentName={this.props.documentName} />
              )}
              {((format === 'pdf' && !largeModal) || format === 'txt') && (
                <div className="pdf-attachment-wrapper">
                  {(format === 'txt') && (<DownloadDocumentButton
                  documentContent={documentContent}
                  documentName={this.props.documentName}
                  customClass='tools-button btn'
                />)}
                  <embed
                    src={this.url}
                    width="100%"
                    height="100%"
                    style={{ borderStyle: '1px solid rgba(128, 128, 128, 0.479)' }}
                    type={`${pdfOrText}`}
                  />
                </div>
              )}
            </Modal.Body>
      </Modal>):(
        this.props.viewAttachment.dokument==="" && (
          NotificationManager.error(this.props.dictionary
            ["documentPreview.DokumentNePostoji"], this.props.dictionary
            ["universal.GreskaUObradi"], 5000, () => {
            } , true)
        )
      )}
      </>
      )}
    </>
    );
  }
}

const mapStateToProps = (state) => ({
  viewAttachment: state.viewAttachment,
  errors: state.errors,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  getDocument,
  removeDocument,
})(ModalForDocumentPreview);
