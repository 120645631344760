import React, { Component } from "react";
import RoleRow from "./RoleRow";
import { connect } from 'react-redux';

class RoleTable extends Component {
    render() {
        var roleList = this.props.roleList.map((role) => (
            <RoleRow key={role.id} role={role} />
        ));
        return (
            <div className="table-responsive">
                <hr></hr>
                <table
                    id="example"
                    className="table table-sm mt-2 border-0"
                >
                    <thead>
                        <tr className="border-0">
                            <th className="border-0 boldText emphesized-label-font">{this.props.dictionary["roleTable.nazivRole"]}</th>
                            <th width="9%" className="text-center border-0">{this.props.dictionary["roleTable.izmena"]}</th>
                            <th width="9%" className="text-center border-0">{this.props.dictionary["roleTable.brisanje"]}</th>
                        </tr>
                    </thead>
                    <tbody>{roleList}</tbody>
                </table>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    dictionary: state.startUp.dictionary,
  });
  
export default connect(mapStateToProps, {})(RoleTable);

