import {
  GET_AD_DOCUMENT,
  GET_AD_DOCUMENT_LIST,
  DELETE_AD_DOCUMENT,
  UPLOAD_AD_DOCUMENT,
} from "../actions/types";

const initialState = {
  adDocumentList: [],
  adDocument: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_AD_DOCUMENT_LIST:
      return {
        ...state,
        adDocumentList: action.payload,
      };
    case GET_AD_DOCUMENT:
      return {
        ...state,
        adDocument: action.payload,
      };
    case DELETE_AD_DOCUMENT:
      return {
        ...state,
        adDocumentList: state.adDocumentList.filter(
          (adDocument) => adDocument.id !== action.payload
        ),
      };
    case UPLOAD_AD_DOCUMENT:
      return {
        ...state,
        adDocumentList: state.adDocumentList.concat(action.payload),
      };
    default:
      return state;
  }
}
