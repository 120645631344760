import React, { Component } from 'react';
import ModeratorResourcesRow from './ModeratorResourcesRow';
import authorizationService from '../../securityUtils/authorizationService';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';
import { connect } from 'react-redux';

class ModeratorResourcesTable extends Component {
  render() {
    let moderatorResourcesList = this.props.moderatorResourcesList.map(
      (moderatorResources) => (
        <ModeratorResourcesRow
          key={moderatorResources.id}
          moderatorResources={moderatorResources}
          isEvaluator={this.props.isEvaluator}
        />
      )
    );

    return (
      <div className="table-responsive min-table-height component-wrapper-fade-in">
        <Table id="example" className="table table-md border-0">
          <Thead>
            <Tr className="border-0">
              <Th className="border-0 boldText emphesized-label-font">
                {this.props.dictionary["ModeratorResourcesTable.header.documents"]}
              </Th>
              <Th
                width="5%"
                className="border-0 boldText emphesized-label-font text-center"
              >
                {this.props.dictionary["ModeratorResourcesTable.header.view"]}
              </Th>
              <Th
                width="5%"
                className="border-0 boldText emphesized-label-font text-center"
              >
                {this.props.dictionary["ModeratorResourcesTable.header.download"]}
              </Th>
              <Th
                width="5%"
                className="text-center border-0 boldText emphesized-label-font"
              >
                {this.props.dictionary["ModeratorResourcesTable.header.delete"]}
              </Th>
            </Tr>
          </Thead>
          <Tbody>{moderatorResourcesList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary
});

export default connect(mapStateToProps, {})(ModeratorResourcesTable);
