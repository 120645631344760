import axios from "axios";
import {
  GET_ERRORS,
  GET_SURVEY_ANSWERS,
  GET_SURVEY_ANSWERS_LIST,
  GET_SURVEY_CLOSED_ANSWERS_LIST,
  DELETE_SURVEY_ANSWERS,
  GET_SURVEY_ANSWERS_FILTERABLE
} from "./types";

export const createSurveyAnswers = (surveyAnswers, surveyId) => async (dispatch) => {
  try {
    await axios.post(`/api/surveyAnswers/create?methodId=${surveyId}`, surveyAnswers);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getSurveyAnswersList = (id) => async (dispatch) => {
  const res = await axios.get(`/api/surveyAnswers/findAllOpenedAnswers/${id}`);
  dispatch({
    type: GET_SURVEY_ANSWERS_LIST,
    payload: res.data,
  });
};

export const getSurveyClosedAnswersList = (id) => async (dispatch) => {
  const res = await axios.get(`/api/surveyAnswers/findAllClosedAnswers/${id}`);
  dispatch({
    type: GET_SURVEY_CLOSED_ANSWERS_LIST,
    payload: res.data,
  });
};

export const getSurveyAnswers = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/surveyAnswers/findById/${id}`);
    dispatch({
      type: GET_SURVEY_ANSWERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOpenAnswersForQuestion = (questionId, searchParams) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/surveyAnswers/findAllOpenAnswersForQuestionId/${questionId}`, searchParams);

    dispatch({
      type: GET_SURVEY_ANSWERS_FILTERABLE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};
