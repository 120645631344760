import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import PDFWorker from 'pdfjs-dist/lib/pdf.worker';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {CompatRouter} from 'react-router-dom-v5-compat';
import { createRoot } from 'react-dom/client'
//import {setPdfWorker} from "react-pdf-highlighter";

//setPdfWorker(PDFWorker);

const app = (
  <Provider store={store}>
    <Router>
      <CompatRouter>  
        <Route path="/" component={App} />
      </CompatRouter>
    </Router>
  </Provider>
);
const root = createRoot(document.getElementById('root'));
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
