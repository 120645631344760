import React, { Component } from "react";
import { Link } from "react-router-dom";
//import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteButton from "../Reusable/DeleteButton";
import UpdateButton from "../Reusable/UpdateButton";
import { deleteRole } from "../../actions/roleActions";

class RoleRow extends Component {
    onDeleteClick = (id) => {
        this.props.deleteRole(id);
    };
    render() {
        return (
            <tr>
                <td id="topicUnorderedList" className="border-0">
                    <div>
                        <li className="topicTitleText textShadow">
                            {this.props.role.roleName}
                        </li>
                    </div>
                </td>
                <td className="text-center">
                    <UpdateButton
                        route={`/updateRole/${this.props.role.id}`}
                    />
                </td>

                <td className="text-center border-0">
                    <DeleteButton
                        click={() =>
                            this.onDeleteClick(this.props.role.id)
                        }
                    />
                </td>
            </tr>
        );
    }
}

// InstitutionRow.propTypes = {
//  deleteInstitution: PropTypes.func.isRequired
// };

export default connect(null, {
    deleteRole
})(RoleRow);
