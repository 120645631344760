import axios from "axios";

import { GET_ERRORS, UPDATE_COMMENT, UPDATE_OGP_COMMENT } from "./types";
export const createCommentUserVote = (commentUserVote, ogpSignal) => async (
  dispatch
) => {
  try {
    const response = await axios.post(
      "/api/commentUserVote/create",
      commentUserVote
    );
    response.data.commentText = JSON.parse(response.data.commentText);
    dispatch({
      type: ogpSignal ? UPDATE_OGP_COMMENT : UPDATE_COMMENT,
      UPDATE_OGP_COMMENT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateCommentUserVote = (commentUserVote, ogpSignal) => async (
  dispatch
) => {
  try {
    const response = await axios.post(
      "/api/commentUserVote/update",
      commentUserVote
    );
    response.data.commentText = JSON.parse(response.data.commentText);
    dispatch({
      type: ogpSignal ? UPDATE_OGP_COMMENT : UPDATE_COMMENT,
      payload: response.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
