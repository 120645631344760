import {
  GET_ACTION_PLAN,
  GET_ACTION_PLAN_LIST,
  DELETE_ACTION_PLAN,
} from "../actions/types";

const initialState = {
  actionPlanList: [],
  actionPlan: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACTION_PLAN_LIST:
      return {
        ...state,
        actionPlanList: action.payload,
      };
    case GET_ACTION_PLAN:
      return {
        ...state,
        actionPlan: action.payload,
      };
    case DELETE_ACTION_PLAN:
      return {
        ...state,
        actionPlanList: state.actionPlanList.filter(
          (actionPlan) => actionPlan.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
