import React from "react";
import { Link } from "react-router-dom";

const UpdateButton = (props) => {
    return (
        <div>
            <Link to={props.route} state={{value:1}}>
                <i className="fas fa-edit deleteButton"></i>
            </Link>
        </div>
    );
};

export default UpdateButton;