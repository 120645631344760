import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteFieldOfInterest } from '../../actions/fieldOfInterestActions';
import DeleteButton from '../Reusable/DeleteButton';
import UpdateButton from '../Reusable/UpdateButton';
import authorizationService from '../../securityUtils/authorizationService';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Tr, Td } from 'react-super-responsive-table';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

class FieldOfInterestRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpdate: false,
    };
  }
  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteFieldOfInterest(id)    
      .then(() => {
        NotificationManager.success(this.props.dictionary["FieldOfInterestRow.UspesnoObrisanaOblast"]);
        })
      .catch(() => {
          NotificationManager.error(this.props.dictionary["FieldOfInterestRow.NeuspesnoBrisanjeOblasti"]);
      })
      .then(() => { 
        this.props.getFiledsOfInterestFunction() 
      });
    };
    confirmDialog(`${this.props.dictionary["FieldOfInterestRow.Obrisi.Poruka"]}`, action, `${this.props.dictionary["FieldOfInterestRow.Obrisi"]}`);
  };

  render() {
 
    return (
      <Tr>
        <Td className="textShadow">{this.props.fieldOfInterest.name}</Td>
        <Td className="text-center">
          {this.props.fieldOfInterest.status == 0 ? 'Неактивна' : 'Активна'}
        </Td>
        <Td className="text-center">
          {this.props.fieldOfInterest.actType.value}
          {/* {this.props.fieldOfInterest.fieldOfInterestType === 1
            ? `${this.props.dictionary["FieldOfInterestRow.Propis"]}`
            : `${this.props.dictionary["FieldOfInterestRow.PlanskiDokument"]}`} */}
        </Td>
        {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
          <Td width="10%" className="text-center">
            {/* <UpdateButton
              route={`/updateFieldOfInterest/${this.props.fieldOfInterest.id}`}
            /> */}
            <Link to='#' onClick={()=>{this.props.showModalForUpdateFunction(this.props.fieldOfInterest.id)}}>
            <i className="fas fa-edit deleteButton"></i>
            </Link>
          </Td>
        ) : (
          ''
        )}
        {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
          <Td className="text-center">
            <DeleteButton
              click={() => this.onDeleteClick(this.props.fieldOfInterest.id)}
            />
          </Td>
        ) : (
          ''
        )}
      </Tr>
    );
  }
}

FieldOfInterestRow.propTypes = {
  deleteFieldOfInterest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  deleteFieldOfInterest,
})(FieldOfInterestRow);
