import axios from 'axios';

export const generateReport = (criteria, period) => async (dispatch) => {
  axios({
    url: `/api/topicOfDiscussionReport/generateReport`,
    method: 'POST',
    data: criteria,
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      'Агрегирани извештај за период ' + period + '.docx'
    );
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  });
};

export const downloadTopicOfDiscussion =
  (id, nameOfTopic, reportType) => async (dispatch) => {
    axios({
      url: `/api/topicOfDiscussionReport/exportfindByIdTopicOfDiscussionInWord/${id}/${reportType}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameOfTopic + ".docx");
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    });
  };

  export const downloadConsultativeProcessReportForm =
  (id, nameOfTopic) => async (dispatch) => {
    axios({
      url: `/api/topicOfDiscussionReport/exportConsultativeProcessReportForm/${id}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Образац извештаја о консултативном процесу о " + nameOfTopic + ".docx"
      );
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    });
  };

  export const downloadPublicDebateReportForm =
  (id, nameOfTopic) => async (dispatch) => {
    axios({
      url: `/api/topicOfDiscussionReport/exportPublicDebateReportForm/${id}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Образац извештаја о јавној расправи о " + nameOfTopic + ".docx"
      );
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    });
  };
