import React, { Component } from "react";
import { connect } from "react-redux";
import { getRoleList } from "../../actions/roleActions";
//import PropTypes from "prop-types";
import RoleTable from "./RoleTable";
// import RoleCreateButton from "./RoleCreateButton";
import CreateButton from "../Reusable/CreateButton";

class RoleList extends Component {
    componentDidMount() {
        this.props.getRoleList();
    }
    render() {
        const { roleList } = this.props.role;
        const { id } = this.props.match.params;
        return (
            <div className="container h-100">
                <div className="row">
                    <div className="col-md-12 m-auto">
                        <div className="card text-left mb-2">
                            <div className="card-header">
                                <h3>{this.props.dictionary["roleList.naslov"]}</h3>
                            </div>
                            <div className="card-body">
                                <RoleTable roleList={roleList} />
                                <br />
                                <div className="col-md-auto float-right">
                                    <CreateButton route={`/addRole`} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />
                    <hr />
                </div>
            </div>
        );
    }
}



const mapStateToProps = (state) => ({
    role: state.role,
    dictionary: state.startUp.dictionary,
});

export default connect(mapStateToProps, {
    getRoleList
})
    (RoleList);
