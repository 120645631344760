import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ReadMoreLess = ({ className, text, maxLength }) => {
  const [isTruncated, setIsTruncated] = useState(text.length > maxLength);

  const dictionary = useSelector(state => state.startUp.dictionary)

  const toggleTruncate = () => {
    setIsTruncated(!isTruncated);
  };

  return (  
    <div className={`read-more-less ${className}`}>
      <p className={`text ${isTruncated ? 'truncated' : ''}`}>
        {isTruncated ? `${text.slice(0, maxLength)}...` : text}
      </p>
      {text.length > maxLength && <div className="d-flex justify-content-center"><button className="button-read tableFooterLinks" onClick={toggleTruncate}>
        {isTruncated ? dictionary["ReadMoreLess.ReadMore"] : dictionary["ReadMoreLess.ReadLess"]}
      </button></div>}
    </div>
  );
};

export default ReadMoreLess;