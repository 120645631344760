import axios from "axios";

const setAuthUserRole = (role) => {
  if (role) {
    axios.defaults.headers.common["X-Role"] = role;
  } else {
    delete axios.defaults.headers.common["X-Role"];
  }
};

export default setAuthUserRole;
