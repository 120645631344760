import React from 'react';

class ComboReusable extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    let placeholder = this.props.signal
      ? this.props.namePart
      : 'Изаберите ' + this.props.namePart;

    let cssStyle = this.props.disabled ? 'disabled-select-field form-control form-control-md' :
      this.props.style && this.props.style == 1
        ? 'select-field-light form-control form-control-md'
        : 'select-field form-control form-control-md';

    return (
      <select
        id={this.props.id}
        name={this.props.name}
        className={`${cssStyle} ${this.props.className}`}
        value={this.props.updateValue}
        onChange={this.props.onChange}
        defaultValue={this.props.updateValue ? this.props.updateValue : '-1'}
        disabled={this.props.disabled}
      >
        <option key="-1" value="-1" disabled hidden>
          {placeholder}
        </option>
        {this.props.items}
        {this.props.allItemsNaming ? (
          <option key={this.props.items.length} value={0}>
              {this.props.allItemsNaming}
          </option>
        ) : (
          ''
        )}
      </select>
    );
  }
}

export default ComboReusable;
