import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createMailSettings } from "../../actions/mailSettingsActions";
import Input from "../../components/Reusable/Input";
import { setMessage } from "../../utils";

class AddMailSettings extends Component {
  constructor() {
    super();
    this.state = {
      mailAddress: "",
      mailPass: "",
      host: "",
      port: "",
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleValidation = () => {
    let error = false;

    if (this.state.mailAddress == "") {
      this.props.setMessage(this.props.dictionary["AddMailSettings.validation.mailAddress"], "#mailAddress", "#msg1");
      error = true;
    }

    return error;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newMailSettings = {
      mailAddress: this.state.mailAddress,
      mailPass: this.state.mailPass,
      host: this.state.host,
      port: this.state.port,
    };
    this.props.createMailSettings(newMailSettings, this.props.history);
  };

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto">
                <h1 className="text-center textShadow mt-5">
                  {this.props.dictionary["AddMailSettings.title.mailAddress"]}
                </h1>
                <hr />
                <form onSubmit={this.onSubmit} className="col-md-12">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="textShadow">{this.props.dictionary["AddMailSettings.label.mailAddress"]}</label>
                      <Input
                        type="text"
                        id="mailAddress"
                        placeholder={this.props.dictionary["AddMailSettings.placeholder.mailAddress"]}
                        name="mailAddress"
                        value={this.state.mailAddress}
                        onChange={this.onChange}
                        errors={errors.mailAddress}
                      />
                      <div id="msg1" />
                    </div>
                    <div className="form-group">
                      <label className="textShadow">{this.props.dictionary["AddMailSettings.label.mailPass"]}</label>
                      <Input
                        type="password"
                        id="mailPass"
                        placeholder={this.props.dictionary["AddMailSettings.placeholder.mailPass"]}
                        name="mailPass"
                        value={this.state.mailPass}
                        onChange={this.onChange}
                        errors={errors.mailPass}
                      />
                      <div id="msg1" />
                    </div>

                    <div className="form-group">
                      <label className="textShadow">{this.props.dictionary["AddMailSettings.label.host"]}</label>
                      <Input
                        type="text"
                        id="host"
                        placeholder={this.props.dictionary["AddMailSettings.placeholder.host"]}
                        name="host"
                        value={this.state.host}
                        onChange={this.onChange}
                        errors={errors.host}
                      />
                      <div id="msg1" />
                    </div>
                    <div className="form-group">
                      <label className="textShadow">{this.props.dictionary["AddMailSettings.label.port"]}</label>
                      <Input
                        type="text"
                        id="port"
                        placeholder={this.props.dictionary["AddMailSettings.placeholder.port"]}
                        name="port"
                        value={this.state.port}
                        onChange={this.onChange}
                        errors={errors.port}
                      />
                      <div id="msg1" />
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    <button
                      id="subscribeButton"
                      className="col-md-2 float-right"
                    >
                      {this.props.dictionary["AddMailSettings.save"]}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddMailSettings.propTypes = {
  createMailSettings: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  mailSettings: state.mailSettings.mailSettings,
  errors: state.errors,
  dictionary:state.startUp.dictionary
});

export default connect(mapStateToProps, {
  createMailSettings,
  setMessage,
})(AddMailSettings);
