import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, Thead, Tbody, Th, Tr, Td } from 'react-super-responsive-table';
import moment from "moment";
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
import { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { deleteDocument } from '../../actions/documentActions';
import { confirmAlert } from 'react-confirm-alert';
import ReadMoreLess from '../Reusable/ReadMoreReadLess';

const DocumentTable = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary);
    const dispatch = useDispatch();
    const formatDate = (date) => {
        return date ? new Date(date).toLocaleDateString("sr-RS") : null
    }

    const [showModalForDocumentPreview, setShowModalForDocumentPreview] = useState(false);
    const [uuidDocName, setUuidDocName] = useState(null);
   
    const handleShowModalForDocumentPreview = (uuidDocName) => {
        setUuidDocName(uuidDocName);
        setShowModalForDocumentPreview(true);
    };
    const handleCloseModalForDocumentPreview = () => {
        setShowModalForDocumentPreview(false);
    };
    
    const confirmDeleteDocument = (action) => {
        confirmAlert({
          customUI: ({onClose}) => {
            return (
              <div id="react-confirm-alert">
                <div className="react-confirm-alert">
                  <div className="custom-ui button">
                    <p>{dictionary["DocumentTable.PotvrdiBrisanje"]}</p>
                    <button onClick={() => {onClose(); action();}}>{dictionary["DocumentTable.Obrisi"]}</button>
                    <button onClick={() => {onClose();}}>{dictionary["DocumentTable.Otkazi"]}</button>
                  </div>
                </div>
              </div>
            );
          },
          onClickOutside: () => {
            
          },
        });
      };
   
      const handleDeleteDocument = (document) => {
        let formData = new FormData();
        formData.append(
            'document',
            new Blob([JSON.stringify(document)], {
                type: 'application/json',
              })
        );
        dispatch(deleteDocument(formData))
        .then(() => {
            NotificationManager.success(dictionary["DocumentTable.UspesnoBrisanjeDokumenta"],dictionary["universal.Uspesno"], 5000);     
          })
          .catch( () => { 
            NotificationManager.error(dictionary["DocumentTable.NeuspesnoBrisanjeDokumenta"], dictionary["universal.GreskaUObradi"], 5000);
          })
          .finally(() => {
            props.handleRefresh();
        });
    }

    return (
      <div className='document-table-div mt-2'>
        <Table className="table table-md">
            <Thead>
                <Tr>
                    <Th className="emphesized-label-font text-center">
                        {dictionary["DocumentTable.NazivDokumenta"]}
                    </Th>
                    <Th className="emphesized-label-font text-center">
                        {dictionary["DocumentTable.TipDokumenta"]}
                    </Th>
                    <Th className="emphesized-label-font text-center">
                        {dictionary["DocumentTable.OpisMetoda"]}
                    </Th>
                    <Th className="emphesized-label-font text-center w-100px">
                        {dictionary["DocumentTable.Datum"]}
                    </Th>
                    <Th className="emphesized-label-font text-center">
                        {dictionary["DocumentTable.Pregled"]}
                    </Th>
                    {props.isModerator && <Th className="emphesized-label-font text-center">
                        {dictionary["DocumentTable.Brisanje"]}
                    </Th>}
                </Tr>
            </Thead>
            <Tbody>
                {props.topicOfDiscussionDocuments!='' && props.topicOfDiscussionDocuments?.map((obj, index) => (
                <Tr key={index}>
                    <Td className="text-center">{obj.documentName}</Td>
                    <Td className="text-center">{obj.documentType.value}</Td>
                    <Td className="text-center">{obj.methods.length>0 ? 
                        obj.methods.map((method) => (
                            <label key={method.id}>&#x2022; {method.title}<br/></label>
                        )) : 
                        <>
                            {obj.description!=null && <ReadMoreLess text={obj?.description} maxLength={500}/>}
                        </>
                    }</Td>
                    <Td className="text-center">{formatDate(obj.uploadDate)}</Td>
                    <Td className="text-center">
                        <Link
                            to="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleShowModalForDocumentPreview(obj.uuidDocName);
                        }} >
                            {/* <i className="fas fa-search only-icon fw-bold"></i> */}
                            {dictionary["DocumentTable.Pogledaj"]}
                        </Link>
                    </Td>
                    {props.isModerator && <Td className="text-center">
                       {(props.type==='Report' || (props.type==='Attachment' && obj.documentType.taxonomyValueInformationList
            .some(el => el.informationName==='DokumentZaNaknadniUnosPrilog'))) ?
                        <button onClick={(e) => {
                            e.preventDefault();
                            confirmDeleteDocument(() => handleDeleteDocument(obj));
                    }} className="far fa-trash-alt only-icon fw-bold"></button> : <div className='far'></div>} 
                    </Td>}
                </Tr>
                ))}
            </Tbody>
        </Table>
        {showModalForDocumentPreview && (
            <ModalForDocumentPreview
                show={showModalForDocumentPreview}
                documentName={uuidDocName}
                controllerReference={'document'}
                handleClose={handleCloseModalForDocumentPreview}
            />
        )}
      </div>
    ); 
}
export default DocumentTable;