import axios from 'axios';
import {
  GET_ERRORS,
  GET_USER_ORGANIZATION,
  GET_USER_ORGANIZATION_LIST,
  DELETE_USER_ORGANIZATION,
  CREATE_USER_ORGANIZATION,
  UPDATE_USER_ORGANIZATION,
  USER_ORGANIZATION_CRUD_IN_PROGRESS,
  USER_ORGANIZATION_CRUD_COMPLEATED,
} from './types';
import { NotificationManager } from 'react-notifications';
import { errorMessage } from '../constants';
import ApiService from '../securityUtils/ApiService';

export const crudIsFinished = () => async (dispatch) => {
    dispatch({
      type: USER_ORGANIZATION_CRUD_COMPLEATED,
      payload: {},
    });
  }

export const crudInProgress = (crudType, userOrganization) => async (dispatch) => {
    const crud = {
      CRUD: crudType,
      userOrganization: userOrganization,
    };  
    dispatch({
      type:   USER_ORGANIZATION_CRUD_IN_PROGRESS,
      payload: crud,
    });
}

export const createUserOrganization = (userOrganization) => async (dispatch) => {
  try {
    await axios.post('/api/userOrganization/create', userOrganization);
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
    dispatch({
      type: CREATE_USER_ORGANIZATION,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateUserOrganization = (userOrganization, history) => async (
  dispatch
) => {
  try {
    await axios.put('/api/userOrganization/update', userOrganization);
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
    dispatch({
      type: UPDATE_USER_ORGANIZATION,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getUserOrganizationList = () => async (dispatch) => {
  const req = {
    method: 'GET',
    url: `/api/userOrganization/findAll`,
  };
  const res = await ApiService.callApi(req);
  dispatch({
    type: GET_USER_ORGANIZATION_LIST,
    payload: res.data,
  });
};

export const getUserOrganizationAdminList = (userId) => async (dispatch) => {
  const req = {
    method: 'GET',
    url: `/api/userOrganization/findAllByAdmin/${userId}`,
  };
  const res = await ApiService.callApi(req);
  dispatch({
    type: GET_USER_ORGANIZATION_LIST,
    payload: res.data,
  });
};

export const getUserOrganization = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/userOrganization/findById/${id}`);
    dispatch({
      type: GET_USER_ORGANIZATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const deleteUserOrganization = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/userOrganization/delete/${id}`);
    dispatch({
      type: DELETE_USER_ORGANIZATION,
      payload: id,
    });
  } catch (e) {
    NotificationManager.error(errorMessage, 'Грешка!', 5000);
  }
};
