import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import TopicOfDiscussionDashboardList from "../Dashboard/TopicOfDiscussionDashboardList";

const ModalForTopicOfDiscussionList = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary);
    const listScroller = {
      overflow:'auto',
      height:'70vh'
    }
    return (
        <Modal
        show={props.show}
        onHide={props.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        id="modalForTopicOfDiscussionList"
        centered
      >
        <Modal.Header className='modalHeader'>
            <Modal.Title>
                {props.isUserAdmin ? dictionary["menu.Profil.ProcesiOstalo"] : dictionary["menu.Profil.MojiProcesi"]}
            </Modal.Title>
            <button onClick={props.handleClose} type="button" class="btn-close btn-close-white m-0 opacity-1" aria-label="Close"></button>
        </Modal.Header> 
        <Modal.Body className='pe-0'>  
          <div style={listScroller} className="container-fluid p-0">
              <div className="text-left pe-1">
                <div>
                  {props.isUserAdmin ? 
                    <>
                      {props.topicOfDiscussionList.map((topic) => 
                        <Link
                        to={`/topicOfDiscussionPage/${topic.id}/${1}`}
                        className="remove-link-effect w-fit-content d-block"
                        >
                        <div>
                          <p className='topicTitleText textShadow mb-2'>&#8226; {topic.nameOfTopic}</p>
                        </div>
                      </Link>
                      )}
                    </> :
                    <TopicOfDiscussionDashboardList
                      quickPreviewSignal={1}
                      topicOfDiscussionPackage={props.topicOfDiscussion}
                    />
                  } 
                </div>
              </div>
            </div>   
        </Modal.Body> 
      </Modal>
    ); 
}
export default ModalForTopicOfDiscussionList;