import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllFiledsOfInterestByJLS, getFieldOfInterest, checkIfFieldOfInterestIsInTopic } from '../../actions/fieldOfInterestActions';
import FieldOfInterestTable from './FieldOfInterestTable';
import authorizationService from '../../securityUtils/authorizationService';
import LoadingIndicator from '../Reusable/LoadingIndicator';
import { trackPromise } from 'react-promise-tracker';
import ModalForAddFieldOfInterest from './ModalForAddFieldOfInterest';
import ModalForUpdateFieldOfInterest from './ModalForUpdateFieldOfInterest';
import TestModal from './ModalForAddFieldOfInterest';
import { identifier } from '@babel/types';
class FieldOfInterestList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      showModalForAddFieldOfInterest: false,
      showModalForUpdateFieldOfInterest: false,
      selectedId:0
    };
  }

  componentDidMount() {
    this.getFieldOfInterest();
  }
  getFieldOfInterest() {
    this.setState({ isLoading: true });
    trackPromise(this.props.getAllFiledsOfInterestByJLS()).finally(() =>
      this.setState({ isLoading: false })
    );
  }

  handleCloseModalForAddFieldOfInterest = (refresh) => {
    if(refresh != null && refresh == true) {
      this.getFieldOfInterest();
    }
    this.setState({
        showModalForAddFieldOfInterest: false,
      });
  };
  handleCloseModalForUpdateFieldOfInterest = (refresh) => {
    if(refresh != null && refresh == true) {
      this.getFieldOfInterest();
    }
    this.setState({
        showModalForUpdateFieldOfInterest: false,
      });
  };
  showModalForAddFieldOfInterest = () => {
    this.setState({
      showModalForAddFieldOfInterest: true,
    });
  };
  showModalForUpdateFieldOfInterest = (id) => {
    this.props.getFieldOfInterest(id);
    this.props.checkIfFieldOfInterestIsInTopic(id);
    this.setState({
      showModalForUpdateFieldOfInterest: true,
    });
  };

  render() {
    const { isLoading } = this.state;

    return isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="container component-wrapper-fade-in">
        <br></br>
        <div className="row">
          <div className="col-md-11 m-auto">
            <div className="text-left mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="textShadow">Области</h2>
                <div className="float-right">
                  {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
                    <button 
                      className="create-button"
                      onClick={(e) => {e.preventDefault(); this.showModalForAddFieldOfInterest();}}
                    >
                      {this.props.dictionary["fieldOfInterestList.dodajOblast"]}
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div>
                <FieldOfInterestTable
                  fieldOfInterestList={this.props.fieldOfInterestList}
                  getFiledsOfInterestFunction = {this.getFieldOfInterest.bind(this)}
                  showModalForUpdateFunction = {this.showModalForUpdateFieldOfInterest.bind(this)}
                />
                { this.state.showModalForAddFieldOfInterest &&
                <ModalForAddFieldOfInterest
                  show={this.state.showModalForAddFieldOfInterest}
                  handleClose={this.handleCloseModalForAddFieldOfInterest}
                />
                }
                { this.state.showModalForUpdateFieldOfInterest &&
                <ModalForUpdateFieldOfInterest
                  show={this.state.showModalForUpdateFieldOfInterest}
                  handleClose={this.handleCloseModalForUpdateFieldOfInterest}
                  fieldOfInterest={this.props.fieldOfInterest}
                  inTopic={this.props.inTopic}
                />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  fieldOfInterestList: state.fieldOfInterest.fieldOfInterestListByJLS,
  fieldOfInterest: state.fieldOfInterest.fieldOfInterest, 
  inTopic: state.fieldOfInterest.inTopic
});

export default connect(mapStateToProps, { getAllFiledsOfInterestByJLS, getFieldOfInterest, checkIfFieldOfInterestIsInTopic })(
  FieldOfInterestList
);
