import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteOgp, downloadOgpList } from '../../actions/ogpActions';
import UpdateButton from '../Reusable/UpdateButton';
import ModalForOGPPreview from './ModalForOGPPreview';
import authorizationService from '../../securityUtils/authorizationService';
import { Tr, Td } from 'react-super-responsive-table';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';

class OgpRow extends Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
    };

    this.onSelectEvent = this.onSelectEvent.bind(this);
    this.handlePreviewClose = this.handlePreviewClose.bind(this);
  }
  onDownloadClick = (id) => {
    const action = () => {
      this.props.downloadOgpList(id);
    };
    confirmDialog(this.props.dictionary["OgpRow.download.confirmDialog.part1"], action, this.props.dictionary["OgpRow.download.confirmDialog.part1"]);
  };

  handleDeleteOGP = (id) => {
    this.props.deleteOgp(id);
  };

  showModal = (e) => {
    this.setState({
      show: true,
    });
  };

  onSelectEvent(e) {
    this.setState({ show: true, selectedEvent: e });
  }
  handlePreviewClose() {
    this.setState({ show: false, selectedEvent: undefined });
  }

  render() {
    //const { id } = this.props.match.params;

    let canAccessEdit = this.props.ogp.user
      ? this.props.userId == this.props.ogp.user.id
      : false;

    let name =
      this.props.ogp.user && this.props.ogp.user.firstName
        ? this.props.ogp.user.firstName
        : '';
    let surname =
      this.props.ogp.user && this.props.ogp.user.lastName
        ? this.props.ogp.user.lastName
        : '';
    let userNameAndSurname = name + ' ' + surname;

    let ogpRow =
      authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) ||
      this.props.authRole && this.props.authRole>0 ? (
        <Tr>
          <Td className="textShadow">{userNameAndSurname}</Td>
          <Td className="textShadow">{this.props.ogp.organisationName}</Td>
          <Td className="textShadow">{this.props.ogp.obligationName}</Td>
          <Td className="textShadow">{this.props.ogp.status}</Td>
          <Td className="text-center">
            <Link to="#" onClick={(e) => {e.preventDefault();this.showModal()}}>
              {this.props.dictionary["Document.Pogledaj"]}
            </Link>
          </Td>
          <Td className="text-center">
            {this.props.ogp.visibilityStatus == null &&
            (canAccessEdit == false ||
              authorizationService.canAccessOGPUpdateSpecificFields(
                this.props.authRole,
                this.props.ogp.ogpModerators
              )) ? (
              <i className="fas fa-edit faded-text deleteButton"></i>
            ) : canAccessEdit ||
              authorizationService.canAccessOGPUpdateSpecificFields(
                this.props.authRole,
                this.props.ogp.ogpModerators
              ) ? (
              <UpdateButton route={`/updateOgp/${this.props.ogp.id}`} />
            ) : (
              <i className="fas fa-edit faded-text deleteButton"></i>
            )}
          </Td>
          {authorizationService.canAccessOGPUpdateSpecificFields(
            this.props.authRole,
            this.props.ogp.ogpModerators
          ) && (
            <Td className="text-center">
              {this.props.ogp.visibilityStatus == null ? (
                <StorageRoundedIcon className="faded-text" />
              ) : (
                <Link to={`/ogpArchiveList/${this.props.ogp.id}`}>
                  <StorageRoundedIcon className="previewButton" />
                </Link>
              )}
            </Td>
          )}
        </Tr>
      ) : (
        <Tr>
          <Td className="textShadow">{userNameAndSurname}</Td>
          <Td className="textShadow">{this.props.ogp.organisationName}</Td>
          <Td className="textShadow">{this.props.ogp.obligationName}</Td>
          <Td className="textShadow">{this.props.ogp.status}</Td>
          <Td className="text-center">
            <Link to="#" onClick={ (e) => { e.preventDefault(); this.showModal()}}>
            {this.props.dictionary["Document.Pogledaj"]}
            </Link>
          </Td>
          {/* <Td className="text-center">
            <Link onClick={() => this.onDownloadClick(this.props.ogp.id)}>
              <i className="fas fa-file-download editButton"></i>
            </Link>
          </Td> */}
        </Tr>
      );
    return this.state.show == false ? (
      ogpRow
    ) : (
      <Fragment>
        {ogpRow}
        <ModalForOGPPreview
          show={this.state.show}
          handleClose={this.handlePreviewClose}
          id={this.props.ogp.id}
          handleDeleteOGP={this.handleDeleteOGP}
          ogpModerators={this.props.ogp.ogpModerators}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  user: state.user,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

OgpRow.propTypes = {
  deleteOgp: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { deleteOgp })(OgpRow);
