import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllActionPlans } from '../../actions/actionPlanActions';
import ActionPlanTable from './ActionPlanTable';
import authorizationService from '../../securityUtils/authorizationService';
import UploadDocumentsButton from '../Reusable/UploadDocumentsButton';

class ActionPlanList extends Component {
  componentDidMount() {
    this.props.getAllActionPlans();
  }

  render() {

    const { actionPlanList } = this.props.actionPlan;

    return (
      <div className="container-fluid component-wrapper-fade-in">
        <ActionPlanTable
          actionPlanList={actionPlanList}
        />
        {authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) &&
        this.props.userId != 0 ? (
          <UploadDocumentsButton phaseRoute={'uploadActionPlan'} />
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  actionPlan: state.actionPlan,
  user: state.user,
  errors: state.errors,
  authUser: state.security.authUser,
  authRole: state.security.authRole,
  userRoles: state.security.userRoles,
  userId: state.security.userId,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  getAllActionPlans,
})(ActionPlanList);
