import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getModeratorResourcesList } from '../../actions/moderatorResourcesActions';
import PropTypes from 'prop-types';
import ModeratorResourcesTable from './ModeratorResourcesTable';
import { Tabs, Tab, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import authorizationService from '../../securityUtils/authorizationService';

class ModeratorResourcesList extends Component {
  constructor() {
    super();
    this.state = {
      activeTab: '',
    };
  }

  handleSelect = (selectedTab) => {
    this.setState({
      activeTab: selectedTab,
    });

    if (selectedTab === 'draftReports') {
      this.props.getModeratorResourcesList(1);
    } else if (selectedTab === 'goodPracticeExamples') {
      this.props.getModeratorResourcesList(2);
    } else if (selectedTab === 'restOfDocuments') {
      this.props.getModeratorResourcesList(3);
    }
  };

  render() {
    let moderatorResourcesList = [
      ...this.props.moderatorResources.moderatorResourcesList,
    ];
    const isEvaluator = authorizationService.canAccessSuggestionButton(
      this.props.authRole
    )
      ? true
      : false;
    return (
      <Container className="col-md-11 m-auto component-wrapper-fade-in min-table-height">
        <br></br>
        <Tabs
          defaultActiveKey="moderatorResources"
          id="uncontrolled-tab-example"
          activeKey={this.state.activeTab}
          onSelect={this.handleSelect}
        >
          <Tab
            eventKey="draftReports"
            title={this.props.dictionary["ModeratorResourcesList.tab.draftReports "]}
            tabClassName={
              this.state.activeTab == 'draftReports'
                ? 'active-tab-phase-three'
                : 'default-tab-phase-three'
            }
          >
            <ModeratorResourcesTable
              moderatorResourcesList={moderatorResourcesList}
              isEvaluator={isEvaluator}
            />
            {isEvaluator && (
              <Link to={`/uploadModeratorResources/${1}`}>
                <i className="fas fa-cloud-upload-alt fa-3x fa-pull-right uploadButton"></i>
              </Link>
            )}
          </Tab>
          <Tab
            eventKey="goodPracticeExamples"
            title={this.props.dictionary["ModeratorResourcesList.tab.goodPracticeExamples"]}
            tabClassName={
              this.state.activeTab == 'goodPracticeExamples'
                ? 'active-tab-phase-three'
                : 'default-tab-phase-three'
            }
          >
            <ModeratorResourcesTable
              moderatorResourcesList={moderatorResourcesList}
              isEvaluator={isEvaluator}
            />
            {isEvaluator && (
              <Link to={`/uploadModeratorResources/${2}`}>
                <i className="fas fa-cloud-upload-alt fa-3x fa-pull-right uploadButton"></i>
              </Link>
            )}
          </Tab>
          <Tab
            eventKey="restOfDocuments"
            title={this.props.dictionary["ModeratorResourcesList.tab.restOfDocuments "]}
            tabClassName={
              this.state.activeTab == 'restOfDocuments'
                ? 'active-tab-phase-three'
                : 'default-tab-phase-three'
            }
          >
            <ModeratorResourcesTable
              moderatorResourcesList={moderatorResourcesList}
              isEvaluator={isEvaluator}
            />
            {isEvaluator && (
              <Link to={`/uploadModeratorResources/${3}`}>
                <i className="fas fa-cloud-upload-alt fa-3x fa-pull-right uploadButton"></i>
              </Link>
            )}
          </Tab>
        </Tabs>
      </Container>
    );
  }
}

ModeratorResourcesList.propTypes = {
  moderatorResources: PropTypes.object.isRequired,
  getModeratorResourcesList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  moderatorResources: state.moderatorResources,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary
});

export default connect(mapStateToProps, {
  getModeratorResourcesList,
})(ModeratorResourcesList);
