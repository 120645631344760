import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ModalForExternalMemberApplication from './ModalForExternalMemberApplication';
import { useEffect, useState } from 'react';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ModalForExternalMemberParticipants from './ModalForExternalMemberParticipants';
import { getDocument } from '../../actions/viewAttachmentAction';
//import { downloadDocument } from '../../utils';
import { getMethodDocuments } from '../../actions/methodActions';
import { trackPromise } from 'react-promise-tracker';
import store from '../../store';
import { downloadDocument } from '../../actions/documentActions';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { findMethodById } from '../../actions/methodActions';
import ModalForMethodDocuments from '../MethodDocument/ModalForMethodDocuments';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
import { getUserFilledMethodDocument } from '../../actions/methodParticipantActions';
import { RESET_METHOD_PARTICIPANT_DOCUMENT } from '../../actions/types';




const ExternalMembersActionBar = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary);
    const methodDocuments = useSelector(state => state.method.methodDocuments);
    const userId = useSelector(state => state.security.userId);

    const [showModalForMethodDocuments, setShowModalForMethodDocuments] = useState(false);
    const [showModalForDocumentPreview, setShowModalForDocumentPreview] = useState(false);

    const methodParticipantDocument = useSelector(state => state.methodParticipant.methodParticipantDocument);
    const dispatch = useDispatch();

    const [ showModalForExternalMemberApplication, setShowModalForExternalMemberApplication] = useState(false);
    const [ showModalForExternalMemberParticipants, setShowModalForExternalMemberParticipants] = useState(false);

    const handleShowModalForDocumentPreview = (id) => {
        dispatch(findMethodById(id));
        setShowModalForDocumentPreview(true);
    };
    const handleCloseModalForDocumentPreview = () => {
        setShowModalForDocumentPreview(false);
    };

    const handleShowModalForMethodDocuments = () => {
        setShowModalForMethodDocuments(true);
    }
    const handleCloseModalForMethodDocuments = (id) => {
        setShowModalForMethodDocuments(false)
    }


    const handleShowModalForExternalMemberApplication = () => { 
        setShowModalForExternalMemberApplication(true)
    }

    const handleCloseModalForExternalMemberApplication = () => { 
        setShowModalForExternalMemberApplication(false)
    }   

    const handleShowModalForExternalMemberParticipants = () => {
        setShowModalForExternalMemberParticipants(true);
    }

    const handleCloseModalForExternalMemberParticipants = () => {
        setShowModalForExternalMemberParticipants(false);
    }



    useEffect(() => {
        if (methodParticipantDocument != null) {
        trackPromise(dispatch(downloadDocument(methodParticipantDocument.document.uuidDocName, methodParticipantDocument.document.documentName))).
        then( dispatch({
            type: RESET_METHOD_PARTICIPANT_DOCUMENT,
            payload: null,
          }));
        }
    }, [methodParticipantDocument])
    
    const method = props.method;
    const handleDownloadForm = (e) => {
        e.preventDefault();
        dispatch(downloadDocument(method?.documents[0]?.uuidDocumentName, method?.documents[0]?.documentName));
    }

    const handleDownloadUserFilledForm = (e) => {
        e.preventDefault();
        dispatch(getUserFilledMethodDocument(method.id, userId));
    }
    
    return (
        <>  
            { method.additionalDocumentNumber == 1 &&
                <Link onClick={(e) => {
                    e.preventDefault();
                    handleShowModalForDocumentPreview(method.id);
                }} 
                to="#" 
                className="tableFooterLinks method-links">
                    <FindInPageIcon/>
                     
                    <span>{dictionary["Method.Prilog"]}</span>
                </Link> 
            }
            { method.additionalDocumentNumber > 1 &&
                <Link onClick={(e) => {
                    e.preventDefault();
                    handleShowModalForMethodDocuments(method.id);
                }} 
                to="#" 
                className="tableFooterLinks method-links">
                    <FindInPageIcon/>
                     
                    <span>{dictionary["Method.Prilog"]}</span>
                </Link> 
            }

            {(props.authRole == 2 && props.isModerator) &&
            <>
                <Link onClick={(e) => {
                    e.preventDefault();
                    handleShowModalForExternalMemberParticipants();
                    
                }} 
                to="#" 
                className="tableFooterLinks method-links">
                    <FormatListBulletedIcon/> 
                    <span>{dictionary["Method.Spisak"]}</span>
                </Link>
                {showModalForExternalMemberParticipants && 
                    <ModalForExternalMemberParticipants
                        show={showModalForExternalMemberParticipants}
                        handleClose={handleCloseModalForExternalMemberParticipants}
                        methodId={method.id}
                    />
                }
            </>   
            }
            {((props.authRole == 3 && method.active && !method.participated)  && 
                <>
                    <Link onClick={(e) => {
                        e.preventDefault();
                        handleShowModalForExternalMemberApplication();
                    }} 
                        to="#" 
                        className="tableFooterLinks method-links">
                            <PersonAddIcon/>
                            <span>{dictionary["Method.PrijaviSe"]}</span>
                    </Link>
                    {showModalForExternalMemberApplication && 
                        <ModalForExternalMemberApplication
                            show={showModalForExternalMemberApplication}
                            handleClose={handleCloseModalForExternalMemberApplication}
                            method={props.method}
                            phase={props.phase}
                            topicOfDiscussionId={props.topicOfDiscussionId}
                        />
                    }
                </>
            )}
            {
                (method.participated) && 
                <Link onClick={(e) => {
                    handleDownloadUserFilledForm(e);
                }} 
                to="#" 
                className="tableFooterLinks method-links">
                    <FileDownloadIcon/> 
                    <span>{dictionary["Method.PreuzmiObrazac"]}</span>
                </Link> 

            }
            {   
                (!props.isModerator && !method.participated) &&  
                    <Link onClick={(e) => {
                        handleDownloadForm(e);
                    }} 
                    to="#" 
                    className="tableFooterLinks method-links">
                        <FileDownloadIcon/> 
                        <span>{dictionary["Method.PreuzmiObrazac"]}</span>
                    </Link> 
            }
            {showModalForMethodDocuments &&
                    <ModalForMethodDocuments 
                        show={showModalForMethodDocuments}
                        handleClose={handleCloseModalForMethodDocuments}
                        method={method}
                        phase={props.phase}
                        topicOfDiscussionId={props.topicOfDiscussionId}
                    />
                    } 
                {showModalForDocumentPreview && (
                    <ModalForDocumentPreview
                        show={showModalForDocumentPreview}
                        documentName={method?.additionalDocuments[0]?.uuidDocumentName}
                        controllerReference={'document'}
                        handleClose={handleCloseModalForDocumentPreview}
                    />
                )}
        </>
    );
}

export default ExternalMembersActionBar;