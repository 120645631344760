import { useSelector } from "react-redux"
import Input from '../Reusable/Input';
import TextArea from '../Reusable/TextArea';
import MethodDocuments from "../MethodDocument/MethodDocuments";

const GeneralPost = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary)
    return (
        <>
        <div className="form-group">
                <label className="labelText textShadow">
                    {dictionary["ModalForAddNewMethod.Naslov"]}: <span className="redAsterisk"> *</span>
                </label>
                <Input
                    type="text"
                    id="title"
                    placeholder={dictionary["ModalForAddNewMethod.Placeholder.Naslov"]}
                    name="title"
                    value={props.title}
                    onChange={(e) => props.setTitle(e.target.value)}
                    //errors={errors.title}
                />
            <div id="titleМsg" />

        </div>
        <div className="form-group">
            <label className="labelText textShadow">
            {dictionary["ModalForAddNewMethod.Tekst"]}: <span className="redAsterisk"> *</span>
            </label>
            <TextArea
                type="text"
                id="description"
                placeholder={dictionary["ModalForAddNewMethod.Placeholder.Tekst"]}
                name="description"
                value={props.description}
                onChange={(e) => props.setDescription(e.target.value)}
                //errors={errors.description}
                maxLength="1000"
                rows={6}
            />
            <div id="descriptionMsg" />
        </div>
        <div className="form-group">
            <label className="labelText textShadow">{dictionary["ModalForAddNewMethod.Link"]}:</label>
            <Input
                type="text"
                id="linkPath"
                placeholder={dictionary["ModalForAddNewMethod.Placeholder.Link"]}
                name="linkPath"
                value={props.linkPath}
                onChange={(e) => props.setLinkPath(e.target.value)}
                //errors={errors.linkPath}
            />
            <div id="linkPathМsg" />
        </div>
        <MethodDocuments
                methodDocuments = {props.methodDocuments}
                uploadedFiles={props.uploadedFiles}
                setUploadedFiles={props.setUploadedFiles}
                selectedFieldsOfInterest={[]}
                phaseId={props.phase.id}
                documents={props.documents}
                setDocuments={props.setDocuments}
                methodId={props.methodId}
            />
        </>
    )
}
export default GeneralPost;