import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../style/ConfirmDialog.css';

export const confirmDialog = (
  message,
  action,
  optionalAnswerMessage,
  signalForCustomMessage
) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      let messageText = signalForCustomMessage
        ? message
        : 'Да ли заиста желите да ' + message + '?';

      let customUiStyle = signalForCustomMessage
        ? 'wide-custom-ui custom-ui button'
        : 'custom-ui button';
      return (
        <div id="react-confirm-alert">
          <div className="react-confirm-alert">
            <div className={customUiStyle}>
              <p>{messageText}</p>
              <button onClick={onClose}>Не</button>
              <button
                onClick={() => {
                  {
                    action();
                  }
                  onClose();
                }}
              >
                Да{optionalAnswerMessage}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};
