import React, { Component } from 'react';
import { connect } from 'react-redux';
import OgpRow from './OgpRow';
import authorizationService from '../../securityUtils/authorizationService';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';

class OgpTable extends Component {
  render() {
    let ogps = this.props.ogps.map((ogp) => (
      <OgpRow
        key={ogp.id}
        ogp={ogp}
        user={this.props.user}
      />
    ));


    let editThText = authorizationService.canAccessOGPPageSpecificAreas(
      this.props.authRole
    )
      ? 'Обрада'
      : 'Измена';

    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-md border-0">
          <Thead>
            {this.props.authRole && this.props.authRole>0 ? (
              <Tr className="border-0">
                <Th className="border-0 boldText emphesized-label-font">
                  {this.props.dictionary["OgpTable.header.userNameAndSurname"]}
                </Th>
                <Th className="border-0 boldText emphesized-label-font">
                  {this.props.dictionary["OgpTable.header.organisationName"]}
                </Th>
                <Th className="border-0 boldText emphesized-label-font">
                  {this.props.dictionary["OgpTable.header.obligationName"]}
                </Th>
                <Th className="border-0 boldText emphesized-label-font text-center">
                  {this.props.dictionary["OgpTable.header.status"]}
                </Th>
                <Th
                  width="5%"
                  className="border-0 boldText emphesized-label-font text-center"
                >
                  {this.props.dictionary["OgpTable.header.pregled"]}
                </Th>
                <Th
                  width="5%"
                  className="border-0 boldText emphesized-label-font text-center"
                >
                  {editThText}
                </Th>
                {(authorizationService.canAccessOGPPageSpecificAreas(
                  this.props.authRole
                ) ||
                  authorizationService.canAccessStartOfANewProcess(
                    this.props.authRole
                  )) && (
                  <Th
                    width="5%"
                    className="border-0 boldText emphesized-label-font text-center"
                  >
                    {this.props.dictionary["OgpTable.header.documentVersions"]}
                  </Th>
                )}
              </Tr>
            ) : (
              <Tr className="table-header-height">
                <Th className="border-0 boldText emphesized-label-font">
                {this.props.dictionary["OgpTable.header.userNameAndSurname"]}
                </Th>
                <Th className="border-0 boldText emphesized-label-font">
                {this.props.dictionary["OgpTable.header.organisationName"]}
                </Th>
                <Th className="border-0 boldText emphesized-label-font">
                {this.props.dictionary["OgpTable.header.obligationName"]}
                </Th>
                <Th className="border-0 boldText emphesized-label-font text-center">
                {this.props.dictionary["OgpTable.header.status"]}
                </Th>
                <Th
                  width="10%"
                  className="border-0 boldText emphesized-label-font text-center"
                >
                  {this.props.dictionary["OgpTable.header.pregled"]}
                </Th>
              </Tr>
            )}
          </Thead>
          <Tbody>{ogps}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary
});
export default connect(mapStateToProps,{}) (OgpTable);
