import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { assignMainModerator } from '../../actions/topicOfDiscussionActions';
import { NotificationManager } from 'react-notifications';
import { confirmAlert } from 'react-confirm-alert';
import { getTopicOfDiscussion } from '../../actions/topicOfDiscussionActions';

const TopicOfDiscussionTakeOver = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary);
    const dispatch = useDispatch();

      
    const confirmTakingOverTopicOfDiscussion = (action) => {
        confirmAlert({
          customUI: ({onClose}) => {
            return (
              <div id="react-confirm-alert">
                <div className="react-confirm-alert">
                  <div className="custom-ui button">
                    <p>{dictionary["TopicOfDiscussionTakeOver.PotvrdiPreuzimanjeProcesa"]}</p>
                    <button onClick={() => {onClose(); action();}}>{dictionary["TopicOfDiscussionTakeOver.Potvrdi"]}</button>
                    <button onClick={() => {onClose();}}>{dictionary["TopicOfDiscussionTakeOver.Odustani"]}</button>
                  </div>
                </div>
              </div>
            );
          },
          onClickOutside: () => {
            
          },
        });
      };

    const handleAssignMainModerator = () => {
        let formData = new FormData();
        formData.append('topicOfDiscussionId',props.topicOfDiscussionId);
        formData.append('userId',props.authUserId);
        dispatch(assignMainModerator(formData))
        .then(() => {
            NotificationManager.success(dictionary["TopicOfDiscussionTakeOver.UspesnoPreuzimanjeProcesa"],dictionary["universal.Uspesno"], 5000);     
          })
          .catch( () => { 
            NotificationManager.error(dictionary["TopicOfDiscussionTakeOver.NeuspesnoPreuzimanjeProcesa"], dictionary["universal.GreskaUObradi"], 5000);
          })
          .finally(()=>{
            dispatch(getTopicOfDiscussion(props.topicOfDiscussionId))
          });
    }
    return (  
        <div className='mb-2'>
            <Alert 
                severity="warning"
                sx={{ fontSize: 'medium' }}
                action={
                <Button 
                    onClick={() => {
                        confirmTakingOverTopicOfDiscussion(()=> handleAssignMainModerator());
                    }}
                    color="inherit" 
                    size="small"
                    sx={{ fontWeight: 'bold' }}>
                    {dictionary["TopicOfDiscussionTakeOver.PreuzmiProces"]}
                </Button>}
            >
                {dictionary["TopicOfDiscussionTakeOver.Poruka"]}
            </Alert>
        </div>
    );
};

export default TopicOfDiscussionTakeOver;