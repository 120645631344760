import React from 'react';
import { connect } from 'react-redux';
import { getAllOgpFiledsOfInterest } from '../../actions/ogpFieldOfInterestActions';

class OgpFieldOfInterestComboBox extends React.Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
    this.props.getAllOgpFiledsOfInterest();
  }

  render() {
    const { ogpFieldOfInterestList } = this.props.ogpFieldOfInterest;
    let optionItems = ogpFieldOfInterestList.map((field) => (
      <option key={field.id} value={field.id}>
        {field.name}
      </option>
    ));

    return (
      <select
        id="fieldCb"
        className="stats-combo-style form-control form-control-md"
        defaultValue="-1"
        onChange={this.props.onChange}
      >
        <option key="-1" value="-1" disabled hidden>
          {this.props.dictionary["OgpFieldOfInterestComboBox.Izaberite"]}
        </option>
        {optionItems}
      </select>
    );
  }
}

const mapStateToProps = (state) => ({
  ogpFieldOfInterest: state.ogpFieldOfInterest,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, { getAllOgpFiledsOfInterest })(
  OgpFieldOfInterestComboBox
);
