import axios from 'axios';
import AuthService from './AuthService';
import store from '../store';

class ApiService {

   callApi(req) {
    return AuthService.getUser().then((user) => {
      if (user && user.access_token) {
        return this._callApi(user.access_token, req).catch((error) => {
          if (error.response.status === 401) {
            return AuthService.renewToken().then((renewedUser) => {
              return this._callApi(renewedUser.access_token, req);
            });
          }
          throw error;
        });
      } else if (user) {
        return AuthService.renewToken().then((renewedUser) => {
          return this._callApi(renewedUser.access_token, req);
        });
      } else {
        throw new Error('user is not logged in');
      }
    });
  }

  _callApi(token, req) {
    const state = store.getState();
    const idRole = state.security.authRole
    const headers = {
      // Authorization: 'Bearer ' + token,
      Authorization: token,
      'X-Role': idRole,
    };
    return axios({
      ...req,
      headers: { ...req.headers, ...headers },
    });
  }
}

export default new ApiService();
