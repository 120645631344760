import axios from "axios";
import { NotificationManager } from "react-notifications";
import { errorMessage } from "../constants";

import {
  GET_ERRORS,
  GET_OGP_FIELD_OF_INTEREST,
  GET_OGP_FIELD_OF_INTEREST_LIST,
  DELETE_OGP_FIELD_OF_INTEREST,
} from "./types";

export const createOgpFieldOfInterest = (ogpFieldOfInterest, history) => async (
  dispatch
) => {
  try {
    await axios.post("/api/ogpFieldOfInterest/create", ogpFieldOfInterest);
    history.push("/ogpFieldOfInterestList");
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateOgpFieldOfInterest = (ogpFieldOfInterest, history) => async (
  dispatch
) => {
  try {
    await axios.post("/api/ogpFieldOfInterest/update", ogpFieldOfInterest);
    history.push("/ogpFieldOfInterestList");
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getAllOgpFiledsOfInterest = () => async (dispatch) => {
  const res = await axios.get("/api/ogpFieldOfInterest/findAll");
  dispatch({
    type: GET_OGP_FIELD_OF_INTEREST_LIST,
    payload: res.data,
  });
};

export const getOgpFieldOfInterest = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpFieldOfInterest/findById/${id}`);
    dispatch({
      type: GET_OGP_FIELD_OF_INTEREST,
      payload: res.data,
    });
  } catch (e) {
    e.getMessage();
  }
};

export const deleteOgpFieldOfInterest = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/ogpFieldOfInterest/delete/${id}`);
    dispatch({
      type: DELETE_OGP_FIELD_OF_INTEREST,
      payload: id,
    });
  } catch (e) {
    NotificationManager.error(errorMessage, "Грешка!", 5000);
  }
};
