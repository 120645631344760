import React, { Fragment } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
import {FormControlLabel, FormGroup, Switch} from '@mui/material';
import { connect } from 'react-redux';

class CookieInfoModal extends React.Component {
  constructor() {
    super();
    this.state = {
      termsOfUse: false,
      acceptAnalyticsCookies: false,
    };
  }
  handleSubmit = () => {
    this.props.handleSubmit(this.state.acceptAnalyticsCookies);
  };

  showTermsOfUseModal = () => {
    this.setState({ termsOfUse: true });
  };

  handlePreviewClose = () => {
    this.setState({
      termsOfUse: false,
    });
  };

  onChangeAcceptAnalyticsCookies = () => {
    const acceptAnalyticsCookies = !this.state.acceptAnalyticsCookies;
    this.setState({
      acceptAnalyticsCookies: acceptAnalyticsCookies,
    });
    window.localStorage.setItem("alowedAnalytics",acceptAnalyticsCookies);
  };
  render() {
    const modalContent = (
      <Modal
        show={this.props.show}
        onHide={() => {}}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="secondModal"
      >
        <div className="col-md-12">
          <Modal.Header>
            <h5>{this.props.dictionary["cookieInfoModal.naslov"]}</h5>
          </Modal.Header>
          <Modal.Body className="col-md-12 m-auto p-2">
         
            <p>
              {this.props.dictionary["cookieInfoModal.sadrzajI"]}
            </p>
            <p>
              {this.props.dictionary["cookieInfoModal.sadrzajII"]}
            </p> 
            <section>
              <div className="text-center col-md-12 form-row mt-5">
              <FormControlLabel 
                control={
                  <Switch
                    id="agreedToBeContactedUpdate"
                    name="agreedToBeContacted"
                    checked={true}
                    role="checkbox"
                    disabled={true}
                  />
                }
                label={this.props.dictionary["cookieInfoModal.neophodni"]} />
              <FormControlLabel 
                control={
                    <Switch
                      id="agreedToBeContactedUpdate"
                      name="agreedToBeContacted"
                      checked={this.state.acceptAnalyticsCookies}
                      onChange={this.onChangeAcceptAnalyticsCookies}
                      role="checkbox"
                    />
                  }
                  label={this.props.dictionary["cookieInfoModal.analiticki"]} />
              </div>
            </section>
            <p className="text-center mt-4">
              <Button
                id="subscribeButton"
                className="m-5"
                onClick={this.handleSubmit}
              >
                <h5> {this.props.dictionary["cookieInfoModal.prihvatam"]} </h5>
              </Button>
            </p>
          </Modal.Body>
          <Modal.Footer className="justify-content-start">
            <label>
              <Link onClick={this.showTermsOfUseModal} to="#">
              {this.props.dictionary["cookieInfoModal.viseInformacija"]}
              </Link>
            </label>
          </Modal.Footer>
        </div>
      </Modal>
    );
    return (
      <Fragment>
        {modalContent}
        {this.state.termsOfUse && (
          <ModalForDocumentPreview
            show={this.state.termsOfUse}
            documentName={'PolitikaPrivatnosti.docx'}
            controllerReference={'document'}
            handleClose={this.handlePreviewClose}
          />
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary,
});

export default connect(mapStateToProps, {})(CookieInfoModal);
