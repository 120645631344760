import React, { Component } from 'react';
import { connect } from 'react-redux';
import UserOrganizationCategoryRow from './UserOrganizationCategoryRow';
import authorizationService from '../../securityUtils/authorizationService';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';

class UserOrganizationCategoryTable extends Component {
  render() {
    var userOrganizationCategoryList = this.props.userOrganizationCategoryList.map(
      (userOrganizationCategory) => (
        <UserOrganizationCategoryRow
          key={userOrganizationCategory.id}
          userOrganizationCategory={userOrganizationCategory}
          showModalForUpdateFunction={this.props.showModalForUpdateFunction}
        />
      )
    );


    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-sm table-striped mt-2">
          <Thead>
            <Tr>
              <Th className="boldText emphesized-label-font">
                  {this.props.dictionary["userOrganizationCategoryTable.nazivKategorije"]}
              </Th>
              {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
                <Th width="10%" className="text-center">
                   {this.props.dictionary["userOrganizationCategoryTable.izmena"]}
                </Th>
              ) : (
                <Th></Th>
              )}
              {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
                <Th width="10%" className="text-center">
                  {this.props.dictionary["userOrganizationCategoryTable.brisanje"]}
                </Th>
              ) : (
                <Th></Th>
              )}
            </Tr>
          </Thead>
          <Tbody>{userOrganizationCategoryList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps,{}) (UserOrganizationCategoryTable);
