import {
  DELETE_TOPIC_OF_DISCUSSION_PARTICIPANT,
  UNSUBSCRIBE_FROM_TOPIC_OF_DISCUSSION,
  GET_TOPIC_OF_DISCUSSION_PARTICIPANT_LIST,
} from "../actions/types";

const initialState = {
  topicOfDiscussionParticipantList: [],
  // focusGroupParticipant: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TOPIC_OF_DISCUSSION_PARTICIPANT_LIST:
      return {
        ...state,
        topicOfDiscussionParticipantList: action.payload,
      };
    //   case GET_FOCUS_GROUP_PARTICIPANT:
    //     return {
    //       ...state,
    //       focusGroupParticipant: action.payload,
    //     };
    case DELETE_TOPIC_OF_DISCUSSION_PARTICIPANT:
      return {
        ...state,
        topicOfDiscussionParticipantList: state.topicOfDiscussionParticipantList.filter(
          (topicOfDiscussionParticipant) =>
            topicOfDiscussionParticipant.id !== action.payload
        ),
      };
    case UNSUBSCRIBE_FROM_TOPIC_OF_DISCUSSION:
      return {
        ...state,
        topicOfDiscussionParticipantList: state.topicOfDiscussionParticipantList.filter(
          (topicOfDiscussionParticipant) =>
            topicOfDiscussionParticipant.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
