import React, { Component } from "react";
import { saveCommentDocument } from "../../actions/commentDocumentActions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { setMessage } from "../../utils";
import { getCommentWithMaxId } from "../../actions/commentActions";

class UploadCommentDocument extends Component {
  constructor() {
    super();
    this.state = {
      uploadedFiles: "",
    };
  }

  componentDidMount() {
    this.props.getCommentWithMaxId();
  }

  onFileChange = (e) => {
    this.setState({
      uploadedFiles: e.target.files,
      imagePreviewUrl: null,
    });
    let reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };
    // if (this.state.uploadedFiles.length === 0) {
    //   let msg = this.props.dictionary["uploadCommentDocument.UpozorenjeNijeOdabranDokument"];
    //   this.props.setMessage(
    //     msg,
    //     "#fileChooser",
    //     "#msg"
    //   );
    //   return;
    // }
    let uploadedFilesSize = 0;
    for (let i = 0; i < e.target.files.length; i++) {
      uploadedFilesSize += e.target.files[i].size;
    }
    if (uploadedFilesSize >= 2000000) {
      let msg = this.props.dictionary["uploadCommentDocument.UpozorenjeDokumentNeSmeBitiVeciOd2MB"];
      this.props.setMessage(
        msg,
        "#fileChooser",
        "#msg"
      );
      return;
    }
    for (let i = 0; i < e.target.files.length; i++) {
      let format = returnFileFormat(e.target.files[i].name);
      let msg = this.props.dictionary["uploadCommentDocument.UpozorenjeFormat"];
      if (format != "docx") {
        this.props.setMessage(
          msg,
          "#fileChooser",
          "#msg"
        );
        return;
      }
    }
    if(e.target.files[0] instanceof Blob)
      reader.readAsDataURL(e.target.files[0]);
  };

  uploadFiles = () => {
    let formData = new FormData();
    if(this.state.uploadedFiles.length>0){
      for (let i = 0; i < this.state.uploadedFiles.length; i++) {
        formData.append("file", this.state.uploadedFiles[i]);
        formData.append("name", this.state.uploadedFiles[i].name);
      }
  
      let { commentMaxId } = this.props.commentMaxId;
      const id = ++commentMaxId;
  
      if (formData) {
        this.props.saveCommentDocument(formData, id);
        this.props.onDocumentUploaded();
      };
    }
    else this.props.onDocumentUploaded();
   
  };

  render() {
    let filePreview;
    let nameOfTheFile = "";
    if (this.state.imagePreviewUrl) {
      for (let i = 0; i < this.state.uploadedFiles.length; i++) {
        nameOfTheFile += this.state.uploadedFiles[i].name;
      }
      filePreview = (
        <label className="fileName textShadow labelText">{nameOfTheFile}</label>
      );
    } else {
      filePreview = (
        <label className="faded-text fileName labelText">
          <em>{this.props.dictionary["uploadCommentDocument.Placeholder"]}</em>
        </label>
      );
    }

    if (this.props.fileUploaded == true) {
      this.uploadFiles();
    }

    return (
      <div className="container mt-2">
        <div className="row col-md-12 text-left">
          <label id="inputFileRemoveDefault" className="col-md-1">
            <input
              id="fileChooser"
              type="file"
              accept=".docx"
              onChange={this.onFileChange}
            />
            <i className="fas fa-cloud-upload-alt fa-2x fa-pull-right uploadButton mt-2"></i>
          </label>
          <label className="col-md-11 text-center">
            {filePreview}
            <hr />
          </label>
        </div>
        <div className="row">
          <div id="msg" />
        </div>
      </div>
    );
  }
}

UploadCommentDocument.propTypes = {
  saveCommentDocument: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  commentMaxId: state.commentMaxId,
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  saveCommentDocument,
  setMessage,
  getCommentWithMaxId,
})(UploadCommentDocument);

const returnFileFormat = (documentName) => {
  let format = documentName.substring(
    documentName.lastIndexOf(".") + 1,
    documentName.length
  );
  return format;
};
