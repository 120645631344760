import React, {useEffect, useMemo, useState} from "react";
import { getCommentListForDocument } from "../../actions/commentActions";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import authorizationService from "../../securityUtils/authorizationService";
import { MRT_Localization_SR_CYRL_RS } from 'mantine-react-table/locales/sr-Cyrl-RS';
import { MantineReactTable } from 'mantine-react-table';
import { NotificationManager } from 'react-notifications';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { comment } from "mammoth/mammoth.browser";
import {
    createCommentUserVote,
    updateCommentUserVote,
  } from '../../actions/commentUserVoteActions';
import 'dayjs/locale/sr-cyrl';


const CommentOnDocumentTableServerFiltering = (props) => {

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [refresh, setRefresh] = useState(0);
  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [selectedCommentId, setselectedCommentId] = useState(0);
  const [searchParams, setSearchParams] = useState({});

  const uuidDocumentName = props.uuidDocumentName;
  const tableClassNames = {
        table:['custom-table', 'comment-table']
    }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'userFullName',
        header: props.dictionary["commentOnDocumentTable.userFullName"],
        size:110
      },
      {
        accessorKey: 'userOrganizationOrganizationName',
        header: props.dictionary["commentOnDocumentTable.userOrganizationOrganizationName"],
        size:140
      },
      {
        id : 'creationDate',
        Cell : ({ cell }) => `${cell.getValue()?.toLocaleDateString('sr-RS')} ${cell.getValue()?.toLocaleTimeString('sr-RS')}`,
        accessorFn: (row) => new Date(row.creationDate),
        filterVariant: 'date-range',
        mantineFilterDateInputProps: {
          locale: 'sr-cyrl'
        },
        header: props.dictionary["commentOnDocumentTable.creationDate"],
        size: 140,
        mantineTableHeadCellProps: {
          className:'dateColumn'
        }
      },
      {
        accessorKey: 'commentText',
        header: props.dictionary["commentOnDocumentTable.commentText"],
        size:140
      },
      {
        accessorKey: 'category',
        header: props.dictionary["commentOnDocumentTable.category"],
        size:140,
      },
      {
        accessorKey: 'status',
        header: props.dictionary["commentOnDocumentTable.status"],
        size:110
      },
      {
        accessorKey: 'commentTextModerator',
        header: props.dictionary["commentOnDocumentTable.commentTextModerator"],
        size:160
      },
      {
        id : 'creationDateModerator',
        Cell : ({ cell }) => `${cell.getValue()?.toLocaleDateString('sr-RS')} ${cell.getValue()?.toLocaleTimeString('sr-RS')}`,
        accessorFn: (row) => new Date(row.creationDateModerator),
        filterVariant: 'date-range',
        header: props.dictionary["commentOnDocumentTable.creationDateModerator"],
        size: 140,
        mantineTableHeadCellProps: {
          className:'dateColumn'
        },
        mantineFilterDateInputProps: {
          locale: 'sr-cyrl'
        },
      },
       {
         id: 'LikeDislike',
         header: props.dictionary["commentOnDocumentTable.reakcijeNaKomentar"],
         size:60,
         columnDefType: 'display', 
         enableColumnOrdering: true, 
         accessorFn: (row) => row.numberOfLikes,
         mantineTableHeadCellProps: {
           align: 'center',
           className:'editColumn'
         },
         mantineTableBodyCellProps: {
           align: 'center',
         },
         Cell: ({ row }) => (
            <div className="p-2" >
              {props.authRole == 3 ?
                <ThumbUpAltIcon
                      onClick={() => {
                        vote(row.original, true);
                      }}
                      className={
                        userLikedComment(row.original)
                          ? 'user-name-style'
                          : 'vote-icon-default-style'
                      }
                    /> : <ThumbUpAltIcon/>}
                    {row.original.numberOfLikes}{' '}
                    {props.authRole == 3 ? 
                    <ThumbDownAltIcon
                      onClick={() => {
                        vote(row.original, false);
                      }}
                      className={
                        userDislikedComment(row.original)
                          ? 'user-name-style'
                          : 'vote-icon-default-style'
                      }
                    />: <ThumbDownAltIcon/>}
                    {row.original.numberOfDislikes}
            </div>
         )
        }
    ],
    [],
  );
  
  useEffect(() => {
      if (!props.commentListOnDocument?.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }
      var filters = [];
      columnFilters?.forEach(element => {
        if(element.id == "creationDate") {
          if(element.value[0] != undefined && element.value[0] != undefined && element.value[0] != "") {
            const offset = element.value[0].getTimezoneOffset();
            const dateFrom = new Date(element.value[0].getTime() - (offset*60*1000));
            filters.push( {id: "creationDateFrom", value: dateFrom});
          }
          if(element.value[1] != undefined && element.value[1] != undefined && element.value[1] != "") {
            const offset = element.value[1].getTimezoneOffset();
            const dateTo = new Date(element.value[1].getTime() - (offset*60*1000));
            filters.push( {id: "creationDateTo", value: dateTo});
          }
        } 
        else if (element.id == "creationDateModerator") {
          if(element.value[0] != undefined && element.value[0] != undefined && element.value[0] != "") {
            const offset = element.value[0].getTimezoneOffset();
            const dateFrom = new Date(element.value[0].getTime() - (offset*60*1000));
            filters.push( {id: "creationDateModeratorFrom", value: dateFrom});
          }
          if(element.value[1] != undefined && element.value[1] != undefined && element.value[1] != "") {
            const offset = element.value[1].getTimezoneOffset();
            const dateTo = new Date(element.value[1].getTime() - (offset*60*1000));
            filters.push( {id: "creationDateModeratorTo", value: dateTo});
          }
        }
        else {
          filters.push(element);
        }
      });
      const searchParams = { 
        start: pagination.pageIndex,
        size: pagination.pageSize,
        filters: filters,
        globalFilter: globalFilter ?? '',
        sorting: sorting ?? []
      };
      setSearchParams(searchParams);
      trackPromise(props.getCommentListForDocument(uuidDocumentName, searchParams)
      .then( () => {
        setIsError(false);
      })
      .catch(() => {
        setIsError(true);
        NotificationManager.error(props.dictionary["universal.NeuspesnoUcitavanjePodataka"]);
       })
      .finally(() => {
        setIsLoading(false);
        setIsRefetching(false);
      })
    );
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    selectedCommentId,
    refresh
  ]);
  const userLikedComment = (comment) => {
    return comment.commentUserVote.filter(cuv => cuv.userId == props.userId.toString() && cuv.hasLiked == true).length > 0;
  }
  const userDislikedComment = (comment) => {
    return comment.commentUserVote.filter(cuv => cuv.userId == props.userId.toString() && cuv.hasDisliked == true).length > 0;
  }
  const voteOfUser = (comment) => {
    return comment.commentUserVote.filter(cuv => cuv.userId == props.userId.toString())?.[0];
  } 
  const vote = (comment, vote) => {
    var currentVote = voteOfUser(comment);
    if(currentVote != null) {
        //update
        const updatedCommentUserVote = {
            id: currentVote.id,
            commentId: comment.id,
            userId: props.userId,
            hasLiked: vote,
            hasDisliked: !vote
        }
        props.updateCommentUserVote(updatedCommentUserVote).then(()=>{setRefresh(Math.random());});
    } else {
        const newCommentUserVote = {
            commentId: comment.id,
            userId: props.userId,
            hasLiked: vote,
            hasDisliked: !vote
        }
        props.createCommentUserVote(newCommentUserVote).then(()=>{setRefresh(Math.random());});
    }
    // if (highlight.commentUserVote) {
    //   const updatedCommentUserVote = {
    //     id: highlight.commentUserVote.id,
    //     commentId: highlight.commentUserVote.commentId,
    //     userId: highlight.commentUserVote.userId,
    //     hasLiked: vote == 'Like' ? true : false,
    //     hasDisliked: vote == 'Dislike' ? true : false,
    //     ogpCommentId: highlight.commentUserVote.ogpCommentId,
    //   };
    //   if (ogpPhase == 'DA') {
    //     updateCommentUserVote(updatedCommentUserVote, ogpPhase);
    //   } else {
    //     updateCommentUserVote(updatedCommentUserVote);
    //   }
    // } else {
    //   let newCommentUserVote = {};
    //   if (ogpPhase == 'DA') {
    //     newCommentUserVote = {
    //       ogpCommentId: highlight.id,
    //       hasLiked: vote == 'Like' ? true : false,
    //       hasDisliked: vote == 'Dislike' ? true : false,
    //     };
    //   } else {
    //     newCommentUserVote = {
    //       commentId: highlight.id,
    //       hasLiked: vote == 'Like' ? true : false,
    //       hasDisliked: vote == 'Dislike' ? true : false,
    //     };
    //   }
    //   if (ogpPhase == 'DA') {
    //     createCommentUserVote(newCommentUserVote, ogpPhase);
    //   } else {
    //     createCommentUserVote(newCommentUserVote);
    //   }
    // }
  };

  const customTable = 'custom-table';
  const customPaper = 'custom-paper';

  return   (
    <>
    <MantineReactTable
      localization={MRT_Localization_SR_CYRL_RS}
      enableGlobalFilter = {false}
      //columnFilterDisplayMode= 'popover'
      columns={columns}
      data={props.commentListOnDocument}
      displayColumnDefOptions= {{ 'mrt-row-numbers': { size:10,align:'center' } }}
      mantineTableProps={{
        className: {customTable},
        striped:true,
        tablelayout: 'fixed',
      }}
      mantineProgressProps={{
        striped:false,
        animate:true,
        size:'xs'
      }}
      mantinePaperProps={{
        className:{customPaper}
      }}
      enableRowNumbers
      initialState={{ showColumnFilters: true }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={props.rowCount}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
    />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
  userId: state.security.userId,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  commentListOnDocument: state?.comment?.commentListOnDocument?.content ?? [],
  rowCount: state?.comment?.commentListOnDocument?.totalElements ?? 0
} };

export default connect(mapStateToProps, {
    getCommentListForDocument,
    createCommentUserVote,
    updateCommentUserVote
})(CommentOnDocumentTableServerFiltering);
