import { useState } from "react";
import SortIcon from '@mui/icons-material/Sort';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const CommentSort = ({filterId, sorting, setSorting}) => {
    const [sort, setSort] = useState(null);

    const onSortChangeHandler = (sortDirection) => {
        setSort(sortDirection);
        const oldSort = sorting.filter(x => x.id != filterId);
        if (sortDirection != null) {
            setSorting([
                ...oldSort,
                {
                    id: filterId,    
                    [sortDirection]: true
                }
            ]);
        }
        else {
            setSorting([
                ...oldSort
            ]);
        } 
    }

    return (
        <>
            {!sort && <SortIcon className="pointer" onClick={() => {onSortChangeHandler('desc')}}/>}
            {sort == 'desc' && <KeyboardDoubleArrowDownIcon className="pointer" onClick={() => {onSortChangeHandler('asc')}}/>}
            {sort == 'asc' && <KeyboardDoubleArrowUpIcon className="pointer" onClick={() => {onSortChangeHandler(null)}}/>}
        </>
    );
}
export default CommentSort;