import React from 'react';

import classnames from 'classnames';

const Input = (props) => {
  let type;
  if (props.type == null) {
    type = 'text';
  } else {
    type = props.type;
  }

  return (
    <div>
      <input
        type={type}
        id={props.name}
        className={classnames('inputField form-control', {
          'is-invalid': props.errors,
        })}
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        min={props.min}
      />
      {props.errors && <div className="invalid-feedback">{props.errors}</div>}
    </div>
  );
};

export default Input;
