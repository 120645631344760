import React, { Component } from 'react';
import { connect } from 'react-redux';
import ErrorTableServerFiltering from './ErrorTableServerFiltering';

class ErrorList extends Component {
  constructor() {
    super();
  }

  

  render() {

    return (
      <div className="container-fluid component-wrapper-fade-in">
        <br></br>
        <div className="row">
          <div className="col-md-11 m-auto">
            <div className="text-left mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="textShadow">{this.props.dictionary["menu.AdminPanel.Greske"]}</h2>
              </div>
              <br></br>
              <ErrorTableServerFiltering/>
              <div id="msg" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary,
});

export default connect(mapStateToProps, {  })(ErrorList);
