import React, { useEffect, useState, Fragment } from "react";
import CreateButtonModal from "../Reusable/CreateButtonModal";
import AuthService from "../../securityUtils/AuthService";
import { connect } from "react-redux";
import { getTopicOfDiscussionsWelcomePage } from "../../actions/topicOfDiscussionActions";
import { headerDocumentTitles, headerModalName } from "../../constants";
import { roleToIdMap } from "../../securityUtils/authorizationService";
import ModalForDocumentPreview from "../Reusable/ModalForDocumentPreview";
import Slider from "react-slick";
import LandingPageStatistics from "./LandingPageStatistics";
import { Link } from "react-router-dom";

const WelcomePage = (props) => {
  const [isModalLGSize, setIsModalLGSize] = useState(false);
  const [showModalForDocumentPreview, setShowModalForDocumentPreview] =
    useState(false);
  const [documentForPreviewName, setDocumentForPreviewName] = useState("");

  const handleLogin = () => {
    AuthService.login();
  };
  const showModalForDocumentPreviewHandler = (documentForPreview) => {
    switch (documentForPreview) {
      case headerModalName.MANUAL:
        getManualNameHandler(props.authRole);
        setIsModalLGSize(true);
        setShowModalForDocumentPreview(true);
        break;
      case headerModalName.ROAD_OF_LAW:
        setIsModalLGSize(true);
        setShowModalForDocumentPreview(true);
        setDocumentForPreviewName(headerDocumentTitles.ROAD_OF_LAW);
        break;
    }
  };
  const getManualNameHandler = (UserRole) => {
    const userRole = parseInt(UserRole);
    let documentName = "";
    switch (userRole) {
      case roleToIdMap.admin:
        documentName = headerDocumentTitles.ROLE_ADMIN;
      case roleToIdMap.ogpAdmin:
        documentName = headerDocumentTitles.ROLE_ADMIN_OGP;
      case roleToIdMap.moderator:
        documentName = headerDocumentTitles.ROLE_MODERATOR;
      case roleToIdMap.evaluator:
        documentName = headerDocumentTitles.ROLE_EVALUATOR;
      case roleToIdMap.citizen:
        documentName = headerDocumentTitles.ROLE_CITIZEN;
      default:
        documentName = headerDocumentTitles.ROLE_CITIZEN;
    }
    setDocumentForPreviewName(documentName);
  };
  const handlePreviewClose = () => {
    setShowModalForDocumentPreview(false);
    setDocumentForPreviewName("");
    setIsModalLGSize(false);
  };
  let list = props.welcomePageTopicOfDiscussionList.map((x) => (
    <div key={x.id}  className="cardWrapper">
          <div className="card">
      <div className="cardHeader bottomBorder">
        <h6>{x.nameOfTopic}</h6>
      </div>
      <div className="cardContent">
        <div className="firstRow">
          <label>{props.dictionary["welcomePage.NadlezniOrgan"]}</label>
          <label>{x.institutionName}</label>
        </div>
        <div className="firstRow">
          <label>{props.dictionary["welcomePage.Oblasti"]}</label>
          <label>{x.fieldOfInterestName}</label>
        </div>
        <div className="secondRow">
          <div>
            <label>{props.dictionary["welcomePage.Datum"]}</label>
            <label>
              {new Date(x.creationDate).toLocaleDateString("sr-RS")}
            </label>
          </div>
          <div>
            <label>{props.dictionary["welcomePage.VrstaDokumenta"]}</label>
            <label>{x.documentType}</label>
          </div>
        </div>
      </div>
      <div className="cardFooter">
        <Link to={`/topicOfDiscussionPage/${x.id}/${1}`}>
          {props.dictionary["welcomePage.SaznajVise"]}
        </Link>
      </div>
    </div>
    </div>

  ));
  const searchParams = {
    start: 0,
    size: 6,
    filters: [{ id: "endProcess", value: false }, {id:"isJLS", value:props.jls}],
    globalFilter: "",
    sorting: [{ id: "creationDate", desc: true }],
  };
  useEffect(() => {
    props.getTopicOfDiscussionsWelcomePage(searchParams);
  }, [props.jls]);
  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: props.welcomePageTopicOfDiscussionList.length<3?  props.welcomePageTopicOfDiscussionList.length : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          dots: true,
          infinite: true,
          speed: 3000,
          slidesToShow: props.welcomePageTopicOfDiscussionList.length<3?  props.welcomePageTopicOfDiscussionList.length:2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 8000,
        }
      },
      {
        breakpoint: 750,
        settings: {
          dots: true,
          infinite: true,
          speed: 3000,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 8000,
        }
      }
    ]
  };
  return (
    <Fragment>
      {showModalForDocumentPreview && (
        <ModalForDocumentPreview
          show={showModalForDocumentPreview}
          documentName={documentForPreviewName}
          controllerReference={"document"}
          handleClose={handlePreviewClose}
          largeModal={isModalLGSize}
          notWideModal={true}
        />
      )}
      <div id="welcomePage" className="welcomePageMainDiv">
        <div>
          <div>
            <h1>{props.dictionary["welcomePage.Dobrodosli"]}</h1>
            <p>{props.dictionary["welcomePage.Opis"]}</p>
            <div className="welcomePageButtons">
              <CreateButtonModal
                onClickHandler={handleLogin}
                label={props.dictionary["welcomePage.PrijaviSe"]}
                className="signin-notification"
                buttonClass="signin-button create-button"
              />
              <a
                href="#topicOfDiscussionTableFilter"
                className="signin-button create-button secondaryButton"
              >
                {props.dictionary["welcomePage.PogledajSveTeme"]}
              </a>
            </div>
          </div>
          {/* <div className="statisticsDiv">
            <LandingPageStatistics/>
          </div> */}
        </div>
        <div className="carouselWelcomePage">
          <Slider {...settings}>
            {list}
          </Slider>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary,
  welcomePageTopicOfDiscussionList:
    state?.topicOfDiscussion?.topicOfDiscussionListWelcomePage?.content ?? [],
  authRole: state.security.authRole,
});

export default connect(mapStateToProps, { getTopicOfDiscussionsWelcomePage })(
  WelcomePage
);
