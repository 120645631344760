import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ModalForUpdateTopicOfDiscussion from './ModalForUpdateTopicOfDiscussion';
import authorizationService from '../../securityUtils/authorizationService';
import ModalForAddModerator from '../TopicOfDiscussionModerator/ModalForAddModerator';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import ReadMoreLess from '../Reusable/ReadMoreReadLess';

import {
  downloadTopicOfDiscussion,
  downloadConsultativeProcessReportForm,
  downloadPublicDebateReportForm,
} from '../../actions/topicOfDiscussionReportActions';
import { downloadCommentList } from '../../actions/commentActions';

class TopicOfDiscussionPageInformationsTable extends Component {
  constructor() {
    super();
    this.state = {
      showTopicOfDiscussion: false,
      showModalForAddModerator: false,
      showMessage: false,
      report: {
        id: 0,
      },
    };
  }
  showModalForUpdateTopicOfDiscussion = (e) => {
    e.preventDefault(e);
    this.setState({
      showTopicOfDiscussion: true,
    });
  };

  showModalForAddModerator = (e) => {
    e.preventDefault(e);
    this.setState({
      showModalForAddModerator: true,
    });
  };

  handlePreviewClose = () => {
    this.setState({
      showTopicOfDiscussion: false,
      showModalForAddModerator: false
    });
  };

  handleShowMesasage = () => {
    this.setState({
      showMessage: true,
    });

    setTimeout(() => {
      this.setState({
        showMessage: false,
      });
    }, 3000);
  };


  handleComboBoxChange = (object, objectId, id, nameOfTopic) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
      });
      const selectedOptionText = e.target.options[e.target.selectedIndex].text;
      const reportType = e.target.value;
      let action = null;
      if (reportType === '1' || reportType === '2') {
        action = () => {
          this.props.downloadTopicOfDiscussion(id, nameOfTopic, reportType);
        };
      } else if (reportType === '3') {
        action = () => {
          this.props.downloadCommentList(id, nameOfTopic);
        };
      } else if (reportType === '6') {
        action = () => {
          this.props.downloadPublicDebateReportForm(id, nameOfTopic);
        };
      } else if (reportType === '7') {
        action = () => {
          this.props.downloadConsultativeProcessReportForm(id, nameOfTopic);
        };
      } 
      confirmDialog(this.props.dictionary["modalConfirm.Preuzimanje"]+selectedOptionText.toLowerCase(), action, '');
    };
  };


  render() {

    const topicOfDiscussionModerators = this.props.topicOfDiscussion
      .topicOfDiscussionModerators;
    const processCreatorId = this.props.topicOfDiscussion.user
      ? this.props.topicOfDiscussion.user.id
      : null;

    const isModerator = authorizationService.canAccessProcessEditMode(
      this.props.authRole,
      this.props.authUserId,
      processCreatorId,
      topicOfDiscussionModerators
    );
    const isEvaluator = authorizationService.canAccessSuggestionButton(
      this.props.authRole
    );
    let isFinished = this.props.topicOfDiscussion
    ? this.props.topicOfDiscussion.endProcess == true
      ? true
      : false
    : false;
    let infoIzvestaji = (
      <p className="regular-font" style={{ maxWidth: '26vw' }}>
        {this.props.dictionary["tooltip.universal.infoIzvestaji"]}
      </p>
    );
    let topicInformation = (
      <div>
        <table id="informationTable" className="table table-sm table-bordered">
          <tbody>
            <tr>
              <td width="100%">
                <h5>
                  <strong>{this.props.dictionary["TopicOfDiscussionPageInformationsTable.Informacije"]}</strong>
                </h5>
              </td>
            </tr>
            <tr>
              <td width="100%">
              <ReadMoreLess text={this.props.topicOfDiscussion.description} maxLength={500}/>
                {/* <p className="topic-description-height">
                  {this.props.topicOfDiscussion.description}
                </p> */}
              </td>
            </tr>

            {/* <tr>
              {isModerator && (
                <td width="100%" className="border-0">
                  <div className="float-left">
                    {this.props.isProcessFinished == false && (
                      <Link
                        onClick={(e) => {
                          this.showModalForUpdateTopicOfDiscussion(e);
                        }}
                        className="default-label-text-link d-flex align-items-center"
                        to="#"
                      >
                        <EditRoundedIcon fontSize={'small'} />
                        <strong className="ps-1">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzmenaAkta"]}</strong>
                      </Link>
                    )}
                  </div>
                  <div className="float-right">
                    <Link
                      onClick={(e) => {
                        this.showModalForAddModerator(e);
                      }}
                      className="default-label-text-link d-flex align-items-center"
                      to="#"
                    >
                      <SupervisorAccountRoundedIcon />
                      <strong className="ps-1">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.Moderatori"]}</strong>
                    </Link>
                  </div>
                  <div>
                  {isModerator || isEvaluator ? (
                            <div>
                              <label className="tableFooterLabels">
                              <CommentIcon className="pe-1" />
                              <Link
                                className="tableFooterLinks"
                                to={`/commentListModerator/${this.props.topicOfDiscussion.id}`}
                              >
                                {this.props.dictionary["TopicOfDiscussionPageInformationsTable.Komentari"]}
                              </Link>
                              </label>
                            </div>
                          ) : (
                            <span
                              /* to={`/commentPage/${topicOfDiscussionId}`} 
                              to="#"
                              className="tableFooterLinks"
                              style={{ display: 'none' }}
                            >
                              {this.props.dictionary["TopicOfDiscussionPageInformationsTable.Komentari"]}
                            </span>
                          )}
                  </div>
                  <div>
                  {(isModerator || isEvaluator) && (
                        <div>
                          <a data-tooltip-id="eventCreationTip2">
                            <label className="tableFooterLabels">
                            <i className="fas fa-file pe-1"></i>
                              <select
                                id="selectReport"
                                className="select-field-for-reports"
                                onChange={this.handleComboBoxChange(
                                  'report',
                                  'id',
                                  this.props.topicOfDiscussion.id,
                                  this.props.topicOfDiscussion.nameOfTopic
                                )}
                                value="0"
                              >
                                <option key="0" value="0" disabled hidden>
                                {this.props.dictionary["TopicOfDiscussionPageInformationsTable.Izvestaji"]}
                                </option>
                                <optgroup label={this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajiZaProces"]}>
                                  <option key="1" value="1">
                                  {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajKP"]}
                                  </option>

                                  <option key="2" value="2">
                                  {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajJR"]}
                                  </option>
                                  <option key="3" value="3">
                                  {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajK"]}
                                  </option>
                                </optgroup>
                                {isEvaluator && (
                                  <optgroup label={this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajAgregiran"]}>
                                    <option key="4" value="4">
                                    {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajKPM"]}
                                    </option>
                                    <option key="5" value="5">
                                    {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajJRM"]}
                                    </option>
                                  </optgroup>
                                )}
                                <optgroup label={this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajObrazac"]}>
                                  <option key="6" value="7">
                                    {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajObrazacKP"]}
                                  </option>
                                  <option key="7" value="6">
                                  {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajObrazacJR"]}
                                  </option>
                                  {/* <option key="8" value="8">
                                    Извештај за сваку фазу консултација
                                  </option> }
                                </optgroup>
                              </select>
                            </label>
                          </a>
                          <Tooltip
                            id="eventCreationTip2"
                            type="info"
                            effect="solid"
                            place="right"
                          >
                            {infoIzvestaji}
                          </Tooltip>
                        </div>
                      )}
                  </div>
                </td>
              )}
            </tr> */}
          </tbody>
        </table>
      </div>
    );

    return (
      <Fragment>
        {topicInformation}
        {this.state.showTopicOfDiscussion == true ? (
          <ModalForUpdateTopicOfDiscussion
            show={this.state.showTopicOfDiscussion}
            handleClose={this.handlePreviewClose}
            topicOfDiscussionId={this.props.topicOfDiscussion.id}
            topicOfDiscussion={this.props.topicOfDiscussion}
            getToD={this.props.getTopicOfDiscussion}
          />
        ) : (
          ''
        )}
        {this.state.showModalForAddModerator == true ? (
          <ModalForAddModerator
            show={this.state.showModalForAddModerator}
            handleClose={this.handlePreviewClose}
            topicOfDiscussion={this.props.topicOfDiscussion}
            isProcessFinished={this.props.isProcessFinished}
          />
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  authUserId: state.security.userId,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps,{  downloadTopicOfDiscussion,
  downloadCommentList,
  downloadPublicDebateReportForm,
  downloadConsultativeProcessReportForm,
 }) (TopicOfDiscussionPageInformationsTable);
