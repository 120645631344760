import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setMessage, checkPdfFormat } from '../../utils';
import { Tooltip } from 'react-tooltip';

class UploadDocument extends Component {
  constructor() {
    super();
    this.state = {
      uploadedFiles: [],
      uploadInProgress: false,
    };
  }

  handleCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  onFileChange = (e) => {
    this.setState({
      uploadedFiles: e.target.files,
      imagePreviewUrl: null,
    });
    let reader = new FileReader();

    reader.onloadend = () => {
      this.setState({
        imagePreviewUrl: reader.result,
      });
    };
    if (this.props.checkPDF) {
      if (checkPdfFormat(e.target.files, '#fileChooser', '#msg')) return;
    }
    reader.readAsDataURL(e.target.files[0]);
  };

  uploadFiles = (e) => {
    e.preventDefault();
    if (this.state.uploadInProgress) return;
    this.setState({ uploadInProgress: true });

    if (this.state.uploadedFiles.length === 0) {
      this.props.setMessage(
        `${this.props.dictionary["UploadDocument.Validation.Dokument"]}`,
        '#fileChooser',
        '#msg'
      );
      this.setState({ uploadInProgress: false });
      return;
    }
    if (this.props.checkPDF){
    if (checkPdfFormat(this.state.uploadedFiles, '#fileChooser', '#msg')){
      this.setState({ uploadInProgress: false });
      return;
    }
  }
    let uploadedFilesSize = 0;
    for (let i = 0; i < this.state.uploadedFiles.length; i++) {
      uploadedFilesSize += this.state.uploadedFiles[i].size;
    }
    if (uploadedFilesSize >= 10000000) {
      this.props.setMessage(
        `${this.props.dictionary["UploadDocument.Validation.DokumentVelicina"]}`,
        '#fileChooser',
        '#msg'
      );
      this.setState({ uploadInProgress: false });
      return;
    }

    let formData = new FormData();

    for (let i = 0; i < this.state.uploadedFiles.length; i++) {
      formData.append('file', this.state.uploadedFiles[i]);
      formData.append('name', this.state.uploadedFiles[i].name);
    }

    try {
      this.props.handleUpload(formData, this.props.history, this.props.documentTypeId);
    } catch (err) {
      this.setMessage(`${this.props.dictionary["UploadDocument.Validation.Prilog"]}`, 1);
    }
  };

  render() {
    let filePreview;
    let titles = '';
    if (this.state.imagePreviewUrl) {
      for (let i = 0; i < this.state.uploadedFiles.length; i++) {
        titles += this.state.uploadedFiles[i].name + ';' + '   ';
      }
      filePreview = (
        <label className="fileName textShadow labelText">{titles}</label>
      );
    } else {
      filePreview = (
        <label className="faded-text fileName labelText">{this.props.dictionary["UploadDocument.Dokument"]}</label>
      );
    }
    let infoAboutInputFiles = (
      <p className="regular-font" style={{ maxWidth: '20vw' }}>
        {this.props.infoAboutInputFilesTooltip}
      </p>
    );
    return (
      <div className="container">
        <br></br>
        <div className="row mt-2">
          <div className="m-auto card uploadFile-card">
            <br></br>
            <div className="col-md-10 m-auto card-body">
              <h1 className="text-left mb-4">{this.props.dictionary["UploadDocument.UcitavanjeDokumenta"]}</h1>
              <hr></hr>
              <br></br>
              <div className="row text-left">
              <a
                  data-tooltip-id="infoAboutInputFilesHasTooltip"
                  className="container col-md-4"
                >
                <label
                  id="inputFileRemoveDefault"
                  className="col-md-12 input-file-button"
                >
                  <input
                    id="fileChooser"
                    type="file"
                    className="buttonCreateUpdate"
                    accept= {this.props.checkPDF ? "application/pdf" : ""}
                    multiple={this.props.multiple}
                    onChange={this.onFileChange}
                  />
                  {this.props.dictionary["UploadDocument.UcitajteDokument"]}
                </label>
              </a>
              <Tooltip
                  id={"infoAboutInputFiles" + (this.props.infoAboutInputFilesTooltip != null ? "HasTooltip" : "")  } 
                  //id="infoAboutInputFiles"
                  type="info"
                  effect="solid"
                  place="left"
                >
                  {infoAboutInputFiles}
              </Tooltip>
                <label className="col-md-8 text-center">
                  {filePreview}
                  <br></br>
                  <hr />
                </label>
              </div>
              <br />
              <div className="row">
                <div id="msg" />
              </div>
              <br />
              <div className="row">
                <button
                  id="subscribeButton"
                  className="col-md-3 ml-auto"
                  onClick={this.uploadFiles}
                >
                  {this.props.dictionary["UploadDocument.Sacuvaj"]}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UploadDocument.propTypes = {
  setMessage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  setMessage
})(UploadDocument);
