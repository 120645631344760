import {
  GET_USERS,
  GET_USER,
  CREATE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  DEACTIVATE_USER,
  ACTIVATE_USER,
  DELETE_USER,
  GET_ALL_MODERATORS,
  SET_USER_LIST_TABLE_FILTER
} from "../actions/types";

const initialState = {
  users: [],
  user: {},
  usersModerators: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
      };
    // case DEACTIVATE_USER:
    //   return {
    //     ...state,
    //     users: action.payload,
    //   };
    // case ACTIVATE_USER:
    //   return {
    //     ...state,
    //     users: action.payload,
    //   };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((r) => r.id !== action.payload),
      };
    case GET_ALL_MODERATORS:
      return {
        ...state,
        usersModerators: action.payload,
      };
      case  SET_USER_LIST_TABLE_FILTER:
      return {
        ...state,
        userListTableFilter: action.payload,
      };
      case CREATE_USER_SUCCESS:
        return {
          ...state,
          userCreated: action.payload
        }
      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          userUpdated: action.payload
        }
    default:
      return state;
  }
}
