import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tabs, Tab, Col, Row, Nav } from 'react-bootstrap';
import authorizationService from '../../securityUtils/authorizationService';
import OgpList from './OgpList';
import ActionPlanReportTable from '../ActionPlanReport/ActionPlanReportTable';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { downloadExcel } from '../../actions/ogpActions';
import OgpReportFileTable from '../OgpReport/OgpReportFileTable';
import { getOgpReportFiles } from '../../actions/ogpReportActions';
import { getActionPlanReports } from '../../actions/actionPlanReportActions';
import { getOgpActionPlansArchive } from '../../actions/ogpActionPlanArchiveActions';
import UploadDocumentsButton from '../Reusable/UploadDocumentsButton';
import ActionPlanList from '../ActionPlan/ActionPlanList';
import OGPStats from './OGPStats';
import OgpActionPlanArchiveTable from '../OgpActionPlanArchive/OgpActionPlanArchiveTable';
import UploadOgpActionPlanArchiveFile from '../OgpActionPlanArchive/UploadOgpActionPlanArchiveFile';

class OGPPagePhases extends Component {
  constructor(props) {
    super();
    this.state = {
      activeTab: 'consultations',
      show: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleSelect(selectedTab) {
    this.setState({
      activeTab: selectedTab,
    });
  }
  componentDidMount() {
    this.props.getOgpReportFiles();
    this.props.getActionPlanReports();
    this.props.getOgpActionPlansArchive();
  }

  onDownloadClick = () => {
    const action = () => {
      this.props.downloadExcel();
    };
    confirmDialog(this.props.dictionary["OGPPagePhases.download.confirmDialog.part1"], action, this.props.dictionary["OGPPagePhases.download.confirmDialog.part2"]);
  };

  render() {
    let { ogpReportFileList } = this.props.ogpReportFile;
    let { ogpActionPlanArchiveList } = this.props.ogpActionPlanArchive;
    let { actionPlanList } = this.props.actionPlan;
    let { actionPlanReportList } = this.props.actionPlanReport;

    let numberOfIssues = this.props.ogp
      ? this.props.ogp.ogps.filter((o) => o.visibilityStatus == true).length
      : 0;

    let numberOfActionPlans = this.props.actionPlan
      ? this.props.actionPlan.actionPlanList.length
      : 0;
    let numberOfReports = ogpReportFileList ? ogpReportFileList.length : 0;
    let numberOfActionPlansArhive = ogpActionPlanArchiveList
      ? ogpActionPlanArchiveList.length
      : 0;
    let numberOfActionPlanReports = actionPlanReportList
      ? actionPlanReportList.length
      : 0;

    const currentSection = this.props.currentSection;
    let defaultPhase;
    if (currentSection == 2) {
      defaultPhase = 'second';
    } else if (currentSection == 3) {
      defaultPhase = 'third';
    } else if (currentSection == 4) {
      defaultPhase = 'fourth';
    } else if (currentSection == 5) {
      defaultPhase = 'fifth';
    } else {
      defaultPhase = 'first';
    }

    return (
      <Tab.Container id="left-tabs-example" defaultActiveKey={defaultPhase}>
        <Row>
          <Col md={4}>
            <Nav className="flex-column">
              {defaultPhase == 'first' ? (
                <Nav.Item
                  id="firstId"
                  className="mb-3 border active-tab"
                  onClick={this.props.handleStyleChange.bind(this, 'firstId')}
                >
                  <Nav.Link eventKey="first">
                    <label
                      id="firstIdLabel"
                      className="boldText active-tab-label"
                    >
                      {this.props.dictionary["OGPPagePhases.predlozi"]}
                    </label>
                    <label
                      id="firstIdDateLabel"
                      className="active-tab-date-label"
                    >
                      {this.props.dictionary["OGPPagePhases.ukupno"] + numberOfIssues}
                    </label>
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Item
                  id="firstId"
                  className="mb-3 border default-tab-style"
                  onClick={this.props.handleStyleChange.bind(this, 'firstId')}
                >
                  <Nav.Link eventKey="first">
                    <label
                      id="firstIdLabel"
                      className="boldText default-tab-label-style"
                    >
                      {this.props.dictionary["OGPPagePhases.predlozi"]}
                    </label>
                    <label
                      id="firstIdDateLabel"
                      className="default-tab-date-label-style"
                    >
                      {this.props.dictionary["OGPPagePhases.ukupno"] + numberOfIssues}
                    </label>
                  </Nav.Link>
                </Nav.Item>
              )}
              {defaultPhase == 'second' ? (
                <Nav.Item
                  id="secondId"
                  className="mb-3 border active-tab"
                  onClick={this.props.handleStyleChange.bind(this, 'secondId')}
                >
                  <Nav.Link eventKey="second">
                    <label
                      id="secondIdLabel"
                      className="boldText active-tab-label"
                    >
                      {this.props.dictionary["OGPPagePhases.konsultacijeAkcioniPlan"]}
                    </label>
                    <label
                      id="secondIdDateLabel"
                      className="active-tab-date-label"
                    >
                      {this.props.dictionary["OGPPagePhases.ukupno"] + numberOfActionPlans}
                    </label>
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Item
                  id="secondId"
                  className="mb-3 border default-tab-style"
                  onClick={this.props.handleStyleChange.bind(this, 'secondId')}
                >
                  <Nav.Link eventKey="second">
                    <label
                      id="secondIdLabel"
                      className="boldText default-tab-label-style"
                    >
                      {this.props.dictionary["OGPPagePhases.konsultacijeAkcioniPlan"]}
                    </label>
                    <label
                      id="secondIdDateLabel"
                      className="default-tab-date-label-style"
                    >
                      {this.props.dictionary["OGPPagePhases.ukupno"] + numberOfActionPlans}
                    </label>
                  </Nav.Link>
                </Nav.Item>
              )}
              {defaultPhase === 'fourth' ? (
                <Nav.Item
                  id="fourthId"
                  className="mb-3 border active-tab"
                  onClick={this.props.handleStyleChange.bind(this, 'fourthId')}
                >
                  <Nav.Link eventKey="fourth">
                    <label
                      id="fourthIdLabel"
                      className="boldText active-tab-label"
                    >
                      Извештаји
                    </label>
                    <label
                      id="fourthIdDateLabel"
                      className="active-tab-date-label"
                    >
                      {this.props.dictionary["OGPPagePhases.ukupno"] + numberOfReports}
                    </label>
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Item
                  id="fourthId"
                  className="mb-3 border default-tab-style"
                  onClick={this.props.handleStyleChange.bind(this, 'fourthId')}
                >
                  <Nav.Link eventKey="fourth">
                    <label
                      id="fourthIdLabel"
                      className="boldText default-tab-label-style"
                    >
                      {this.props.dictionary["OGPPagePhases.izvestaji"]} 
                    </label>
                    <label
                      id="fourthIdDateLabel"
                      className="default-tab-date-label-style"
                    >
                      {this.props.dictionary["OGPPagePhases.ukupno"] + numberOfReports}
                    </label>
                  </Nav.Link>
                </Nav.Item>
              )}
              {defaultPhase == 'fifth' ? (
                <Nav.Item
                  id="fifthId"
                  className="mb-3 border active-tab"
                  onClick={this.props.handleStyleChange.bind(this, 'fifthId')}
                >
                  <Nav.Link eventKey="fifth">
                    <label
                      id="fifthIdLabel"
                      className="boldText active-tab-label mb-4"
                    >
                      {this.props.dictionary["OGPPagePhases.statistika"]}
                    </label>
                    <label
                      id="fifthIdDateLabel"
                      className="active-tab-date-label"
                    ></label>
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Item
                  id="fifthId"
                  className="mb-3 border default-tab-style"
                  onClick={this.props.handleStyleChange.bind(this, 'fifthId')}
                >
                  <Nav.Link eventKey="fifth">
                    <label
                      id="fifthIdLabel"
                      className="boldText default-tab-label-style mb-2"
                    >
                      {this.props.dictionary["OGPPagePhases.statistika"]}
                    </label>
                    <label
                      id="fifthIdDateLabel"
                      className="default-tab-date-label-style"
                    ></label>
                  </Nav.Link>
                </Nav.Item>
              )}
              {defaultPhase == 'third' ? (
                <Nav.Item
                  id="thirdId"
                  className="mb-3 border active-tab"
                  onClick={this.props.handleStyleChange.bind(this, 'thirdId')}
                >
                  <Nav.Link eventKey="third">
                    <label
                      id="thirdIdLabel"
                      className="boldText active-tab-label"
                    >
                      {this.props.dictionary["OGPPagePhases.sprovedeniAkcioniPlanovi"]}
                    </label>
                    <label
                      id="thirdIdDateLabel"
                      className="active-tab-date-label"
                    >
                      {this.props.dictionary["OGPPagePhases.ukupno"] + numberOfActionPlansArhive}
                    </label>
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Item
                  id="thirdId"
                  className="mb-3 border default-tab-style"
                  onClick={this.props.handleStyleChange.bind(this, 'thirdId')}
                >
                  <Nav.Link eventKey="third">
                    <label
                      id="thirdIdLabel"
                      className="boldText default-tab-label-style"
                    >
                      {this.props.dictionary["OGPPagePhases.sprovedeniAkcioniPlanovi"]}
                    </label>
                    <label
                      id="thirdIdDateLabel"
                      className="default-tab-date-label-style"
                    >
                      {this.props.dictionary["OGPPagePhases.ukupno"] + numberOfActionPlansArhive}
                    </label>
                  </Nav.Link>
                </Nav.Item>
              )}

              <button
                onClick={this.onDownloadClick.bind(this)}
                className="create-button"
              >
                {' '}
                {this.props.dictionary["OGPPagePhases.repozitorijum"]}
              </button>
            </Nav>
          </Col>

          <Col md={8}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <OgpList
                  requestIssuesFinalDate={this.props.requestIssuesFinalDate}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Tabs
                  defaultActiveKey="consultations"
                  activeKey={this.state.activeTab}
                  className="row col-md-12"
                  onSelect={this.handleSelect}
                >
                  <Tab
                    eventKey="consultations"
                    title={this.props.dictionary["OGPPagePhases.nacrtAkcionogPlana"]}
                    tabClassName={
                      this.state.activeTab == 'consultations'
                        ? 'active-tab-phase-three'
                        : 'default-tab-phase-three'
                    }
                  >
                    <ActionPlanList actionPlanList={actionPlanList} />
                  </Tab>
                  <Tab
                    eventKey="report"
                    title={
                      'Извештај са консултација (' +
                      numberOfActionPlanReports +
                      ')'
                    }
                    tabClassName={
                      this.state.activeTab == 'report'
                        ? 'active-tab-phase-three'
                        : 'default-tab-phase-three'
                    }
                  >
                    <ActionPlanReportTable
                      actionPlanReportList={actionPlanReportList}
                    />
                    <div className="col-md-12">
                      {authorizationService.canAccessOGPPageSpecificAreas(
                        this.props.authRole
                      ) ? (
                        <UploadDocumentsButton
                          phaseRoute={'uploadActionPlanReport'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <div className="row col-md-12 border-top">
                  <OgpReportFileTable
                    ogpReportFileList={ogpReportFileList}
                  />
                  <div className="row ml-auto">
                    <div className="col-md-11">
                      {authorizationService.canAccessOGPPageSpecificAreas(
                        this.props.authRole
                      ) ? (
                        <UploadDocumentsButton
                          phaseRoute={'uploadOgpReportFile'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="fifth">
                <div className="row col-md-12 border-top">
                  <OGPStats />
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <div className="row col-md-12">
                  <OgpActionPlanArchiveTable
                    ogpActionPlanArchiveList={ogpActionPlanArchiveList}
                  />
                  <div className="row ml-auto">
                    <div className="col-md-11">
                      {authorizationService.canAccessOGPPageSpecificAreas(
                        this.props.authRole
                      ) ? (
                        <UploadDocumentsButton
                          phaseRoute={'uploadOgpActionPlanArchiveFile'}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  ogp: state.ogp,
  actionPlan: state.actionPlan,
  error: state.errors,
  ogpReportFile: state.ogpReportFile,
  actionPlanReport: state.actionPlanReport,
  ogpActionPlanArchive: state.ogpActionPlanArchive,
});
export default connect(mapStateToProps, {
  downloadExcel,
  getOgpReportFiles,
  getActionPlanReports,
  getOgpActionPlansArchive,
})(OGPPagePhases);
