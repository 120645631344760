import axios from 'axios';
import {
    GET_DICTIONARY,
    GET_TAXONOMIES
} from './types';

export const getDictionary = () => async (dispatch) => {
    const res = await axios.get('/api/dictionary/getDictionaryForClient');
    dispatch({
        type: GET_DICTIONARY,
        payload: res.data,
    });
};

export const getTaxonomies = () => async (dispatch) => {
    const res = await axios.get('/api/taxonomy/getAllTaxonomies');
    dispatch({
        type: GET_TAXONOMIES,
        payload: res.data,
    });
}
