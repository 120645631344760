import {
  GET_OGP_FIELDS_OF_INTEREST,
  GET_OGP_FIELDS_OF_INTEREST_LIST,
} from "../actions/types";

const initialState = {
  ogpFieldsOfInterestList: [],
  ogpFieldsOfInterest: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OGP_FIELDS_OF_INTEREST:
      return {
        ...state,
        ogpFieldsOfInterest: action.payload,
      };
    case GET_OGP_FIELDS_OF_INTEREST_LIST:
      return {
        ...state,
        ogpFieldsOfInterestList: action.payload,
      };

    default:
      return state;
  }
}
