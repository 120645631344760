import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../style/ConfirmDialog.css';
import ComboReusable from '../Reusable/ComboReusable';

export const userOrganizationComboPopup = (
  userOrganizationList,
  action,
  handleUserOrganizationChange,
  eventMessageSignal
) => {
  const handleComboBoxChange = (e) => {
    return (e) => {
      handleUserOrganizationChange(e.target.value);
    };
  };
  confirmAlert({
    customUI: ({ onClose }) => {
      const buttonText = eventMessageSignal ? 'Пријави се' : 'Пошаљи коментар';
      const questionText = eventMessageSignal
        ? 'Изаберите организацију у чије име желите да присуствујете догађају (опционо):'
        : 'Изаберите организацију испред које желите да оставите коментар (опционо):';
      return (
        <div id="react-confirm-alert">
          <div className="react-confirm-alert">
            <div className="custom-ui">
              <div className="from-group">
                <label className="labelText textShadow mb-2">
                  {questionText}
                </label>
                <ComboReusable
                  id="userOrganizationCb"
                  namePart="организацију"
                  items={userOrganizationList.map((userOrganization) => (
                    <option
                      key={userOrganization.id}
                      value={userOrganization.id}
                    >
                      {userOrganization.organizationName}
                    </option>
                  ))}
                  onChange={handleComboBoxChange('userOrganization', 'id')}
                  style={1}
                  allItemsNaming={'Ништа од наведеног'}
                />
              </div>
              <button
                onClick={() => {
                  {
                    action();
                  }
                  onClose();
                }}
                className="labelText textShadow"
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
};
