export const roleToIdMap = {
  admin: 1,
  moderator: 2,
  citizen: 3,
  evaluator: 4,
  ogpAdmin: 5,
};

export const authorizationService = {
  canAccessAdminPanel: (authUserRole) => {
    if (authUserRole == roleToIdMap.admin) {
      return true;
    }
    return false;
  },
  canLeaveComments:(authUserRole) => {
    if (authUserRole == roleToIdMap.citizen) {
      return true;
    }
    return false;
  },
  canAccessStartOfANewProcess: (authUserRole) => {
    if (authUserRole == roleToIdMap.moderator) {
      return true;
    } else {
      return false;
    }
  },
  canAccessProcessEditMode: (authUserRole, authUserId, processCreatorId, moderators) => {
    let isAdditionalModerator =
      moderators &&
      moderators.filter((m) => m.userId == authUserId).length !=
        0;
    if (
      authUserRole == roleToIdMap.moderator &&
      (processCreatorId == authUserId || isAdditionalModerator)
    ) {
      return true;
    }
    return false;
  },
  canAccessOGPPageSpecificAreas: (authUserRole) => {
    if (authUserRole == roleToIdMap.ogpAdmin) {
      return true;
    }
    return false;
  },
  canAccessOGPUpdateSpecificFields: (authUserRole, authUserId,  moderators) => {
    let isAdditionalModerator =
      moderators &&
      moderators.filter((m) => m.userId == authUserId).length !=
        0;
    if (
      authUserRole == roleToIdMap.ogpAdmin ||
      (authUserRole == roleToIdMap.moderator &&
        isAdditionalModerator)
    ) {
      return true;
    }
    return false;
  },
  canAccessSuggestionButton: (authUserRole) => {
    if (authUserRole == roleToIdMap.evaluator) {
      return true;
    }
    return false;
  },
  canAccessSendApplicationButton: (authUserRole, authUserId, applicantsIds) => {
    let isApplicationAlreadySent =
      applicantsIds &&
      applicantsIds.filter((appId) => appId == authUserId)
        .length > 0;
    if (
      authUserRole == roleToIdMap.citizen &&
      isApplicationAlreadySent === false
    ) {
      return true;
    }
    return false;
  },
  canAccessExportRawDataButton: (authUserRole) => {
    if (authUserRole==roleToIdMap.admin || authUserRole==roleToIdMap.evaluator) {
      return true;
    }
    return false;
  },
  canExportSurveyResults: (authUserRole) => {
    if(authUserRole==roleToIdMap.moderator)
      return true;
    return false;
  },
  canTakeOverTopicOfDiscussion: (authUserRole, topicOfDiscussionInstitutionId, userInstitutionId) => {
    return authUserRole==roleToIdMap.moderator && topicOfDiscussionInstitutionId===userInstitutionId ? true : false
  },
  isAdmin: (authUserRole) => {
    return authUserRole==roleToIdMap.admin ? true : false
  },
  canHideTopicOfDiscussion: (authUserRole) => {
    return authUserRole==roleToIdMap.admin ? true : false
  },
  canAdminEditTopic: (authUserRole, superAdmin, topicOfDiscussion, phase) => {
    return (authUserRole==roleToIdMap.admin && superAdmin==true && topicOfDiscussion.actType.code=='Ostalo' && phase.consultativeProcess==null && phase.publicDebate==null) ? true : false
  },
  isCitizen: (authUserRole) => {
    return authUserRole==roleToIdMap.citizen
  }
};

export default authorizationService;
