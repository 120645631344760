import {
  GET_TOPIC_OF_DISCUSSION_INSTITUTIONS,
  GET_TOPIC_OF_DISCUSSION__INSTITUTIONS_LIST,
} from "../actions/types";

const initialState = {
  topicOfDiscussionInstitutionsList: [],
  topicOfDiscussionInstitutions: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_TOPIC_OF_DISCUSSION_INSTITUTIONS:
      return {
        ...state,
        topicOfDiscussionInstitutions: action.payload,
      };
    case GET_TOPIC_OF_DISCUSSION__INSTITUTIONS_LIST:
      return {
        ...state,
        topicOfDiscussionInstitutionsList: action.payload,
      };

    default:
      return state;
  }
}
