import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteOgpReportFile } from '../../actions/ogpReportActions';
import DeleteButton from '../Reusable/DeleteButton';
import ViewDocumentButton from '../Reusable/ViewDocumentButton';
import authorizationService from '../../securityUtils/authorizationService';
import { Tr, Td } from 'react-super-responsive-table';
import { confirmDialog } from '../Reusable/ConfirmDialog';

class OgpReportFileRow extends Component {
  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteOgpReportFile(id);
    };
    confirmDialog(`${this.props.dictionary["OgpReportFileRow.Obrisi.Poruka"]}`, action, `${this.props.dictionary["OgpReportFileRow.Obrisi"]}`);
  };

  render() {
    return (
      <Tr>
        <Td>{this.props.ogpReportFile.documentName}</Td>
        <Td className="text-center">
          <ViewDocumentButton
            documentName={this.props.ogpReportFile.uuidDocName}
            controllerReference={'ogpReportFile'}
            ogpPhase={4}
          />
        </Td>
        {authorizationService.canAccessOGPPageSpecificAreas(
          this.props.authRole
        ) ? (
          <Td className="text-center">
            <DeleteButton
              click={() => this.onDeleteClick(this.props.ogpReportFile.id)}
            />
          </Td>
        ) : (
          ''
        )}
      </Tr>
    );
  }
}

OgpReportFileRow.propTypes = {
  deleteOgpReportFile: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps, {
  deleteOgpReportFile,
})(OgpReportFileRow);
