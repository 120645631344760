import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateOgp, getOgp, finalUpdateOgp } from '../../actions/ogpActions';
import Input from '../Reusable/Input';
import TextArea from '../Reusable/TextArea';
import DatePicker from 'react-datepicker';
import authorizationService from '../../securityUtils/authorizationService';
import moment from 'moment';
import { getAllOgpFiledsOfInterest } from '../../actions/ogpFieldOfInterestActions';
import Select from 'react-select';
import OgpStepList from '../OgpStep/OgpStepList';
import GoBackButton from '../Reusable/GoBackButton';
import { getOGPStepList } from '../../actions/ogpStepActions';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { createOGPArchive } from '../../actions/ogpArchiveActions';
import Alert from 'react-bootstrap/Alert';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Tooltip } from 'react-tooltip';
import { setMessage } from '../../utils';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import ModalForAddOGPModerator from '../OgpModerator/ModalForAddOGPModerator';
import { SignalCellularNullRounded } from '@mui/icons-material';

class UpdateOgp extends Component {
  constructor() {
    super();
    this.state = {
      nameAndLastname: '',
      organisationName: '',
      phoneNum: '',
      email: '',
      currentState: '',
      shortDescriptionAndMainGoal: '',
      strategy: '',
      additionalInformation: '',
      relevancy: '',
      startDate: '',
      endDate: '',
      ogpFieldOfInterests: '',
      selectedOGPFieldsOfInterest: [],
      obligationName: '',
      obligationNumber: '',
      otherParticipants: '',
      status: null,
      statusComment: '',
      errors: {},
      showRestOfTheText: '',
      showMessage: false,
      showRestOfTheButtonText: '',
      showModalForAddOgpModerator: false,
    };

    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleTextAreaExpand = this.handleTextAreaExpand.bind(this);
    this.handleTextAreaShrink = this.handleTextAreaShrink.bind(this);
    this.handleOGPFieldsOfInterestMultiselectChange =
      this.handleOGPFieldsOfInterestMultiselectChange.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getOgp(id);
    this.props.getAllOgpFiledsOfInterest();
    this.props.getOGPStepList(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const {
      id,
      nameAndLastname,
      organisationName,
      phoneNum,
      email,
      currentState,
      shortDescriptionAndMainGoal,
      strategy,
      additionalInformation,
      relevancy,
      startDate,
      endDate,
      ogpFieldOfInterests,
      obligationName,
      obligationNumber,
      otherParticipants,
      status,
      statusComment,
      user,
      visibilityStatus,
      statusCommentCreationDate,
      statusCommentUserId,
      creationDate,
      ogpModerators,
    } = nextProps.ogp;

    this.setState({
      id,
      nameAndLastname,
      organisationName,
      phoneNum,
      email,
      currentState,
      shortDescriptionAndMainGoal,
      strategy,
      additionalInformation,
      relevancy,
      startDate: startDate ? moment(startDate).toDate() : '',
      endDate: endDate ? moment(endDate).toDate() : '',
      ogpFieldOfInterests,
      obligationName,
      obligationNumber,
      otherParticipants,
      status,
      statusComment,
      user,
      visibilityStatus,
      statusCommentCreationDate,
      statusCommentUserId,
      creationDate,
      ogpModerators,
      selectedOGPFieldsOfInterest: ogpFieldOfInterests
        ? ogpFieldOfInterests.map((field) => ({
            ...field,
            value: field.id,
            label: field.name,
          }))
        : [],
    });
  }

  handleStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTextAreaExpand = (e) => {
    e.target.rows = 17;
  };

  handleTextAreaShrink = (e) => {
    e.target.rows = 4;
  };

  handleOGPFieldsOfInterestMultiselectChange(selectedOptions) {
    this.setState({ selectedOGPFieldsOfInterest: selectedOptions || [] });
  }

  handleValidation = () => {
    let error = false;

    if (this.state.status !== null && !this.state.statusComment) {
      this.props.setMessage(
        this.props.dictionary["UpdateOgp.validation.statusComment"],
        '#statusComment',
        '#msgStatus'
      );
      error = true;
    }

    if (this.state.status === null && this.state.statusComment) {
      this.props.setMessage(this.props.dictionary["UpdateOgp.validation.status"], '#status', '#msgStatus');
      error = true;
    }

    if (
      this.state.status &&
      this.state.statusComment &&
      this.state.statusComment.length > 1000
    ) {
      this.props.setMessage(
        this.props.dictionary["UpdateOgp.validation.statusComment.length"],
        '#statusComment',
        '#msgStatus'
      );
      error = true;
    }

    return error;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation()) {
      return;
    }

    const { id } = this.props.match.params;

    const updatedOgp = {
      id: id,
      nameAndLastname: this.state.nameAndLastname,
      organisationName: this.state.organisationName,
      phoneNum: this.state.phoneNum,
      email: this.state.email,
      currentState: this.state.currentState,
      shortDescriptionAndMainGoal: this.state.shortDescriptionAndMainGoal,
      strategy: this.state.strategy,
      relevancy: this.state.relevancy,
      additionalInformation: this.state.additionalInformation,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      ogpFieldOfInterests: this.state.selectedOGPFieldsOfInterest,
      obligationName: this.state.obligationName,
      obligationNumber: this.state.obligationNumber,
      otherParticipants: this.state.otherParticipants,
      status: this.state.status,
      statusComment: this.state.statusComment,
      user: this.props.ogp.user,
      visibilityStatus: this.props.ogp.visibilityStatus,
      statusCommentCreationDate: this.props.ogp.statusCommentCreationDate,
      statusCommentUserId: this.props.ogp.statusCommentUserId,
      creationDate: this.props.ogp.creationDate,
    };

    if (this.state.status !== null) {
      this.props.finalUpdateOgp(updatedOgp, this.props.history);
    } else {
      this.props.updateOgp(updatedOgp, this.props.history);
    }
  };

  handleOGPArchive = () => {
    const { id } = this.props.match.params;

    let ogpFieldsOfInterestArchiveList = [];
    let ogpFieldsOfIneterestList = this.props.ogp.ogpFieldOfInterests;

    let fieldIndex = 0;
    ogpFieldsOfIneterestList.forEach((element) => {
      const newOgpFieldOfInterest = {
        ogpFieldOfInterestName: element.name,
      };
      ogpFieldsOfInterestArchiveList[fieldIndex++] = newOgpFieldOfInterest;
    });

    let ogpStepArchiveList = [];
    const { ogpStepList } = this.props.ogpStep;

    let stepIndex = 0;
    ogpStepList.forEach((element) => {
      const newOgpStep = {
        ogpId: id,
        step: element.step,
        stepStartDate: element.stepStartDate,
        stepEndDate: element.stepEndDate,
      };
      ogpStepArchiveList[stepIndex++] = newOgpStep;
    });

    const archiveItem = {
      ogpId: id,
      nameAndLastname: this.props.ogp.nameAndLastname,
      organisationName: this.props.ogp.organisationName,
      phoneNum: this.props.ogp.phoneNum,
      email: this.props.ogp.email,
      currentState: this.props.ogp.currentState,
      shortDescriptionAndMainGoal: this.props.ogp.shortDescriptionAndMainGoal,
      strategy: this.props.ogp.strategy,
      relevancy: this.props.ogp.relevancy,
      additionalInformation: this.props.ogp.additionalInformation,
      startDate: this.props.ogp.startDate,
      endDate: this.props.ogp.endDate,
      obligationName: this.props.ogp.obligationName,
      obligationNumber: this.props.ogp.obligationNumber,
      otherParticipants: this.props.ogp.otherParticipants,
      status: this.props.ogp.status,
      statusComment: this.props.ogp.statusComment,
      user: this.props.ogp.user,
      ogpFieldsOfInterestArchives: ogpFieldsOfInterestArchiveList,
      ogpStepArchives: ogpStepArchiveList,
    };
    const action = () => {
      this.props
        .createOGPArchive(archiveItem)
        .then(() => this.handleShowMesasage());
    };
    confirmDialog(this.props.dictionary["UpdateOgp.createOGPArchive.confirmDialog.part1"], action, this.props.dictionary["UpdateOgp.createOGPArchive.confirmDialog.part2"]);
  };

  handleShowRestOfTheText = () => {
    this.setState({ showRestOfTheText: 1 });
  };
  handleHideRestOfTheText = () => {
    this.setState({ showRestOfTheText: -1 });
  };

  handleShowMesasage = () => {
    this.setState({
      showMessage: true,
    });

    setTimeout(() => {
      this.setState({
        showMessage: false,
      });
    }, 3000);
  };

  handleShowRestOfTheButtonText = () => {
    this.setState({ showRestOfTheButtonText: 1 });
  };
  handleHideRestOfTheButtonText = () => {
    this.setState({ showRestOfTheButtonText: -1 });
  };

  showModalForAddOgpModerator = (e) => {
    this.setState({
      showModalForAddOgpModerator: true,
    });
  };

  handlePreviewClose = () => {
    this.setState({
      showModalForAddOgpModerator: false,
    });
  };

  render() {
    const { errors } = this.state;

    this.state.email =
      this.state.email == '' &&
      this.props.authRole == 2 &&
      authorizationService.canAccessOGPUpdateSpecificFields(
        this.props.authRole,this.props.ogp.ogpModerators
      )
        ? this.props.authUser.sub
        : this.state.email;
    this.state.nameAndLastname =
      this.state.nameAndLastname == '' &&
      this.props.authRole == 2 &&
      authorizationService.canAccessOGPUpdateSpecificFields(
        this.props.authRole,
        this.props.ogp.ogpModerators
      )
        ? this.props.authUser.firstName +
          ' ' +
          this.props.authUser.lastName
        : this.state.nameAndLastname;

    let isDisabled = authorizationService.canAccessOGPUpdateSpecificFields(
      this.props.authRole,
      this.props.ogp.ogpModerators
    )
      ? false
      : true;

    const { ogpFieldOfInterestList } = this.props.ogpFieldOfInterest;
    const fieldOfInterestList = ogpFieldOfInterestList.map((field) => ({
      ...field,
      value: field.id,
      label: field.name,
    }));

    let options = [];
    for (let i = 0; i < fieldOfInterestList.length; i++) {
      options.push(fieldOfInterestList[i]);
    }

    const customStyleForMultiselect = {
      control: (styles) => ({ ...styles, minHeight: '45px' }),
      option: (styles) => ({
        ...styles,
        color: '#495057',
        fontSize: '17px',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
        marginLeft: '12px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
      }),
      input: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '1.25rem',
        marginLeft: '12px',
      }),
    };

    const { ogpStepList } = this.props.ogpStep;

    let titleText = isDisabled ? this.props.dictionary["UpdateOgp.izmena"] : this.props.dictionary["UpdateOgp.izmena"];

    let restOfTheText =
      this.state.showRestOfTheText == 1 ? <span>{this.props.dictionary["UpdateOgp.saveVersion"]}</span> : '';

    let infoAboutCurrentSatate = (
      <p className="regular-font" style={{ maxWidth: '26vw' }}>
        {this.props.dictionary["tooltip.ogp.infoAboutCurrentState"]}
      </p>
    );
    let infoAboutShortDescriptionAndMainGoal = (
      <p className="regular-font" style={{ maxWidth: '26vw' }}>
        {this.props.dictionary["tooltip.ogp.infoAboutShortDescriptionAndMainGoal"]}
      </p>
    );
    let infoAboutStrategy = (
      <div className="regular-font" style={{ maxWidth: '26vw' }}>
      {this.props.dictionary["tooltip.ogp.infoAboutStrategy"]}
      </div>
    );
    let infoAboutRelevancy = (
      <div className="regular-font" style={{ maxWidth: '26vw' }}>
      {this.props.dictionary["tooltip.ogp.infoAboutRelevancy"]}
      </div>
    );
    let infoAboutAdditionalInformation = (
      <div className="regular-font" style={{ maxWidth: '26vw' }}>
       {this.props.dictionary["tooltip.ogp.infoAboutAdditionalInformation"]}
      </div>
    );

    let comboDisabledStyle =
      isDisabled == true
        ? 'disabled-select-field form-control form-control-mb'
        : 'select-field form-control form-control-mb';

    let statusFieldsVisibility =
      this.state.visibilityStatus == true ? true : false;

    let ogpStatus = 'Поднесен';
    if (this.props.ogp.visibilityStatus == true) {
      ogpStatus = 'У разматрању';
    } else if (this.props.ogp.visibilityStatus == false) {
      ogpStatus = 'Одбачен';
    }

    let restOfTheModalText =
      this.state.showRestOfTheButtonText == 1 ? (
        <span className="pl-2">{this.props.dictionary["UpdateOgp.moderators"]}</span>
      ) : (
        ''
      );

    let ogpInformation = (
      <div>
        {/* <GoBackButton route={'/ogpPage/1'} /> */}
        {this.state.showMessage ? (
          <Alert
            className={
              this.state.showMessage
                ? 'alert-style mt-2 col-md-3 alert-shown'
                : 'alert-style mt-2 col-md-3 alert-hidden'
            }
          >
            <p className="mt-1 regular-font">{this.props.dictionary["UpdateOgp.saveVersionSucces"]}</p>
          </Alert>
        ) : (
          ''
        )}
        {!isDisabled ? (
          <div
            onMouseOver={this.handleShowRestOfTheText}
            onMouseLeave={this.handleHideRestOfTheText}
            className="save-version-button-style"
            style={{
              right: '0',
              marginRight: '1vw',
              marginTop: '2vh',
              position: 'absolute',
            }}
            onClick={this.handleOGPArchive}
          >
            <ArchiveOutlinedIcon /> {restOfTheText}
          </div>
        ) : (
          ''
        )}
        {!isDisabled ? (
          <div
            onMouseOver={this.handleShowRestOfTheButtonText}
            onMouseLeave={this.handleHideRestOfTheButtonText}
            className="save-version-button-style"
            style={{
              right: '0',
              marginRight: '1vw',
              marginTop: '30vh',
              position: 'absolute',
            }}
            onClick={this.showModalForAddOgpModerator}
          >
            <SupervisorAccountRoundedIcon />
            {restOfTheModalText}
          </div>
        ) : (
          ''
        )}
        <div className="container">
          <br></br>
          <div className="row">
            <div className="col-md-12 m-auto">
              <h1 className="title text-center col-md-12">
                {titleText + this.props.dictionary["UpdateOgp.titleTextSuffix"]}
              </h1>
              <hr />
              {!this.state.status || this.state.status == '' ? (
                <div className="col-md-2 ml-auto">
                  <label className="boldText default-label-text">
                    {ogpStatus}
                  </label>
                </div>
              ) : (
                ''
              )}
              <br />
            </div>
            <form onSubmit={this.onSubmit} className="col-md-12 mb-4 row">
              <div className="col-md-9 ml-4 mb-4">
                {statusFieldsVisibility ? (
                  <div className="form-row border-bottom mb-4">
                    <div className="form-group col-md-5">
                      <label className="labelText textShadow">{this.props.dictionary["UpdateOgp.label.status "]}</label>
                      <select
                        className={comboDisabledStyle}
                        onChange={this.onChange}
                        id="status"
                        name="status"
                        value={this.state.status}
                        disabled={isDisabled}
                      >
                        <option key="0" value="" selected disabled hidden>
                          {this.props.dictionary["UpdateOgp.placeholder.status"]}
                        </option>
                        <option key="1" value="Поднесен">
                          {this.props.dictionary["UpdateOgp.status.podnesen"]}
                        </option>
                        <option key="2" value="У разматрању">
                          {this.props.dictionary["UpdateOgp.status.uRazmatranju"]}
                        </option>
                        <option key="3" value="Делимично прихваћен">
                          {this.props.dictionary["UpdateOgp.status.delimicnoPrihvacen"]}
                        </option>
                        <option key="4" value="Прихваћен">
                          {this.props.dictionary["UpdateOgp.status.prihvacen"]}
                        </option>
                        <option key="5" value="Није прихваћен">
                          {this.props.dictionary["UpdateOgp.status.nijePrihvacen"]}
                        </option>
                      </select>
                      <p className="small-font ml-auto mt-3">
                        <span style={{ color: 'red' }}> *</span> {this.props.dictionary["UpdateOgp.forAdministrator"]}
                      </p>
                    </div>
                    <div className="form-group col-md-7">
                      <label className="labelText textShadow ">{this.props.dictionary["UpdateOgp.label.statusComment"]}</label>
                      <TextArea
                        type="text"
                        id="statusComment"
                        placeholder={this.props.dictionary["UpdateOgp.placeholder.statusComment"]}
                        name="statusComment"
                        value={this.state.statusComment}
                        onChange={this.onChange}
                        errors={errors.statusComment}
                        disabled={isDisabled}
                        maxlength="1000"
                        rows="2"
                        onFocus={this.handleTextAreaExpand}
                        onBlur={this.handleTextAreaShrink}
                      />
                      <p className="small-font ml-auto mt-3">
                        <span style={{ color: 'red' }}> *</span> {this.props.dictionary["UpdateOgp.forAdministrator"]}
                      </p>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <div className="form-row border-bottom mb-4">
                  <div className="form-group col-md-9">
                    <label className="labelText textShadow">
                      {this.props.dictionary["UpdateOgp.label.obligationName"]} <span className="redAsterisk"> *</span>
                    </label>
                    <Input
                      type="text"
                      id="obligationName"
                      placeholder={this.props.dictionary["UpdateOgp.placeholder.obligationName"]}
                      name="obligationName"
                      value={this.state.obligationName}
                      onChange={this.onChange}
                      errors={errors.obligationName}
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label className="labelText textShadow ">
                      {this.props.dictionary["UpdateOgp.label.obligationNumber"]}
                    </label>
                    <Input
                      type="text"
                      id="obligationNumber"
                      placeholder={this.props.dictionary["UpdateOgp.placeholder.obligationNumber"]}
                      name="obligationNumber"
                      value={this.state.obligationNumber}
                      onChange={this.onChange}
                      errors={errors.obligationNumber}
                      disabled={isDisabled}
                    />
                  </div>
                  <p className="small-font ml-auto">
                    <span style={{ color: 'gray' }}> *</span> {this.props.dictionary["UpdateOgp.forAdministrator"]}
                  </p>
                </div>
                <div className="from-group row">
                  <div className="col-md-12">
                    <div className="row col-md-12">
                      <label className="labelText textShadow mt-1">
                        {this.props.dictionary["UpdateOgp.label.startEndDate"]}
                      </label>
                      <div className="col-md-3">
                        <DatePicker
                          id="startDate"
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange.bind(
                            'startcreationDate'
                          )}
                          className="dateInput form-control"
                          dateFormat="dd.MM.yyyy"
                          placeholderText={this.props.dictionary["UpdateOgp.placeholder.startDate"]}
                          disabled={isDisabled}
                          maxDate={this.state.endDate}
                        />
                      </div>
                      <label className="labelText textShadow mt-1"> - </label>
                      <div className="col-md-3">
                        <DatePicker
                          id="endDate"
                          selected={this.state.endDate}
                          onChange={this.handleEndDateChange.bind('endDate')}
                          className="dateInput form-control"
                          dateFormat="dd.MM.yyyy"
                          placeholderText={this.props.dictionary["UpdateOgp.placeholder.endDate"]}
                          disabled={isDisabled}
                          minDate={this.state.startDate}
                        />
                      </div>
                    </div>
                    <p className="small-font">
                      <span className="redAsterisk"> *</span> {this.props.dictionary["UpdateOgp.forAdministrator"]}
                    </p>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">
                    {this.props.dictionary["UpdateOgp.label.organisationName"]}
                  </label>
                  <Input
                    type="text"
                    id="organisationName"
                    placeholder={this.props.dictionary["UpdateOgp.placeholder.organisationName"]}
                    name="organisationName"
                    value={this.state.organisationName}
                    onChange={this.onChange}
                    errors={errors.organisationName}
                    disabled={isDisabled}
                  />
                  <p className="small-font">
                    <span className="redAsterisk"> *</span> {this.props.dictionary["UpdateOgp.forAdministrator"]}
                  </p>
                </div>
                <hr></hr>
                <h5 className="textShadow mb-4 default-label-text text-center">
                  {this.props.dictionary["UpdateOgp.label.obligationDescription"]}
                </h5>
                <hr></hr>
                <div className="form-group">
                  <label className="labelText textShadow">
                    {this.props.dictionary["UpdateOgp.label.fieldOfInterestList"]} <span className="redAsterisk"> *</span>
                  </label>
                  <Select
                    isMulti
                    name="fieldOfInterestList"
                    options={options}
                    value={this.state.selectedOGPFieldsOfInterest}
                    onChange={this.handleOGPFieldsOfInterestMultiselectChange}
                    placeholder={this.props.dictionary["UpdateOgp.placeholder.fieldOfInterestList"]}
                    id="multi"
                    styles={customStyleForMultiselect}
                  />
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">
                    {this.props.dictionary["UpdateOgp.label.currentState"]}
                  </label>
                  <span className="redAsterisk"> *</span>
                  <div data-tooltip-id="currentStateTip">
                    <TextArea
                      type="text"
                      id="currentState"
                      placeholder={this.props.dictionary["UpdateOgp.placeholder.currentState"]}
                      name="currentState"
                      value={this.state.currentState}
                      onChange={this.onChange}
                      errors={errors.currentState}
                      maxlength="1000"
                      rows="4"
                      onFocus={this.handleTextAreaExpand}
                      onBlur={this.handleTextAreaShrink}
                    />
                  </div>
                  <Tooltip
                    id="currentStateTip"
                    type="info"
                    effect="solid"
                    place="right"
                  >
                    {infoAboutCurrentSatate}
                  </Tooltip>
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">{this.props.dictionary["UpdateOgp.label.shortDescriptionAndMainGoal"]}</label>
                  <span className="redAsterisk"> *</span>
                  <div data-tooltip-id="shortDescriptionAndMainGoalTip">
                    <TextArea
                      type="text"
                      id="shortDescriptionAndMainGoal"
                      placeholder={this.props.dictionary["UpdateOgp.placeholder.shortDescriptionAndMainGoal"]}
                      name="shortDescriptionAndMainGoal"
                      value={this.state.shortDescriptionAndMainGoal}
                      onChange={this.onChange}
                      errors={errors.shortDescriptionAndMainGoal}
                      maxlength="1000"
                      rows="4"
                      onFocus={this.handleTextAreaExpand}
                      onBlur={this.handleTextAreaShrink}
                    />
                  </div>
                  <Tooltip
                    id="shortDescriptionAndMainGoalTip"
                    type="info"
                    place="right"
                    effect="solid"
                  >
                    {infoAboutShortDescriptionAndMainGoal}
                  </Tooltip>
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">
                    {this.props.dictionary["UpdateOgp.label.strategy"]}
                  </label>
                  <span className="redAsterisk"> *</span>
                  <div data-tooltip-id="strategyTip">
                    <TextArea
                      type="text"
                      id="strategy"
                      placeholder={this.props.dictionary["UpdateOgp.placeholder.strategy"]}
                      name="strategy"
                      value={this.state.strategy}
                      onChange={this.onChange}
                      errors={errors.strategy}
                      maxlength="1000"
                      rows="4"
                      onFocus={this.handleTextAreaExpand}
                      onBlur={this.handleTextAreaShrink}
                    />
                  </div>
                  <Tooltip
                    id="strategyTip"
                    type="info"
                    effect="solid"
                    place="right"
                  >
                    {infoAboutStrategy}
                  </Tooltip>
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">
                    {this.props.dictionary["UpdateOgp.label.relevancy"]}
                  </label>
                  <span className="redAsterisk"> *</span>
                  <div data-tooltip-id="relevancyTip">
                    <TextArea
                      type="text"
                      id="relevancy"
                      placeholder={this.props.dictionary["UpdateOgp.placeholder.relevancy"]}
                      name="relevancy"
                      value={this.state.relevancy}
                      onChange={this.onChange}
                      errors={errors.relevancy}
                      maxlength="1000"
                      rows="4"
                      onFocus={this.handleTextAreaExpand}
                      onBlur={this.handleTextAreaShrink}
                    />
                  </div>
                  <Tooltip
                    id="relevancyTip"
                    type="info"
                    effect="solid"
                    place="right"
                  >
                    {infoAboutRelevancy}
                  </Tooltip>
                </div>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">
                    {this.props.dictionary["UpdateOgp.label.additionalInformation"]}
                  </label>
                  <div data-tooltip-id="additionalInformationTip">
                    <TextArea
                      type="text"
                      id="additionalInformation"
                      placeholder={this.props.dictionary["UpdateOgp.placeholder.additionalInformation"]}
                      name="additionalInformation"
                      value={this.state.additionalInformation}
                      onChange={this.onChange}
                      errors={errors.additionalInformation}
                      maxlength="1000"
                      rows="4"
                      onFocus={this.handleTextAreaExpand}
                      onBlur={this.handleTextAreaShrink}
                    />
                  </div>
                  <Tooltip
                    id="additionalInformationTip"
                    type="info"
                    effect="solid"
                    place="right"
                  >
                    {infoAboutAdditionalInformation}
                  </Tooltip>
                </div>
                <hr></hr>
                <p className="small-font">
                  <span className="redAsterisk"> *</span> {this.props.dictionary["UpdateOgp.forAdministrator"]}
                </p>
                <div
                  style={{
                    border: '.5px solid  #697c9d44',
                    borderRadius: '10px',
                    padding: '2%',
                  }}
                >
                  <OgpStepList
                    ogpId={this.props.ogp.id}
                    ogpStepList={ogpStepList}
                    ogpUpdate={true}
                    ogpModerators={this.props.ogp.ogpModerators}
                  />
                </div>
                <hr></hr>
                <h5 className="textShadow mb-4 default-label-text text-center">
                  {this.props.dictionary["UpdateOgp.title.contactInfo"]}
                </h5>
                <hr></hr>
                <div className="form-group mb-4">
                  <label className="labelText textShadow">{this.props.dictionary["UpdateOgp.label.nameAndLastname"]}</label>
                  <span className="redAsterisk"> *</span>
                  <Input
                    type="text"
                    id="nameAndLastname"
                    placeholder={this.props.dictionary["UpdateOgp.placeholder.nameAndLastname"]}
                    name="nameAndLastname"
                    value={this.state.nameAndLastname}
                    onChange={this.onChange}
                    errors={this.errors}
                    disabled={isDisabled}
                  />
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6 mb-4">
                    <label className="labelText textShadow">
                      {this.props.dictionary["UpdateOgp.label.email"]}
                    </label>
                    <span className="redAsterisk"> *</span>
                    <Input
                      type="text"
                      id="email"
                      placeholder={this.props.dictionary["UpdateOgp.placeholder.email"]}
                      name="email"
                      value={this.state.email}
                      onChange={this.onChange}
                      errors={errors.email}
                      disabled={isDisabled}
                    />
                  </div>
                  <div className="form-group col-md-6 mb-4">
                    <label className="labelText textShadow">
                      {this.props.dictionary["UpdateOgp.label.phoneNum"]}
                    </label>
                    <span className="redAsterisk"> *</span>
                    <Input
                      type="text"
                      id="phoneNum"
                      placeholder={this.props.dictionary["UpdateOgp.placeholder.phoneNum"]}
                      name="phoneNum"
                      value={this.state.phoneNum}
                      onChange={this.onChange}
                      errors={errors.phoneNum}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label className="labelText textShadow">
                      {this.props.dictionary["UpdateOgp.label.otherParticipants"]}
                    </label>
                    <TextArea
                      type="text"
                      id="otherParticipants"
                      placeholder={this.props.dictionary["UpdateOgp.placeholder.otherParticipants"]}
                      name="otherParticipants"
                      value={this.state.otherParticipants}
                      onChange={this.onChange}
                      errors={errors.otherParticipants}
                      maxlength="1000"
                      rows="4"
                      disabled={isDisabled}
                    />
                  </div>
                </div>
                <hr></hr>
                <div id="msgStatus" />
                <div className="float-center">
                  <button
                    id="subscribeButton"
                    type="submit"
                    className="col-md-3 float-right"
                  >
                    {this.props.dictionary["UpdateOgp.save"]}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
    return (
      <Fragment>
        {ogpInformation}
        {this.state.showModalForAddOgpModerator == true ? (
          <ModalForAddOGPModerator
            show={this.state.showModalForAddOgpModerator}
            handleClose={this.handlePreviewClose}
            ogp={this.props.ogp}
          />
        ) : (
          ''
        )}
      </Fragment>
    );
  }
}

UpdateOgp.propTypes = {
  finalUpdateOgp: PropTypes.func.isRequired,
  getOgp: PropTypes.func.isRequired,
  ogpFieldOfInterest: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  ogpStep: PropTypes.object.isRequired,
  getOGPStepList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  ogp: state.ogp.ogp,
  authRole: state.security.authRole,
  authUser: state.security.authUser,
  dictionary:state.startUp.dictionary,
  ogpFieldOfInterest: state.ogpFieldOfInterest,
  errors: state.errors,
  ogpStep: state.ogpStep,
});

export default connect(mapStateToProps, {
  finalUpdateOgp,
  getOgp,
  getAllOgpFiledsOfInterest,
  getOGPStepList,
  createOGPArchive,
  setMessage,
  updateOgp,
})(UpdateOgp);
