import React, { Component } from 'react';
import {convertToHtml} from 'mammoth/mammoth.browser';
import { connect } from "react-redux";


class DocxViewer extends Component {
  componentDidMount() {
    const { base64File } = this.props;
    const binaryData = Uint8Array.from(atob(base64File), c => c.charCodeAt(0)).buffer;
    const iframe = document.getElementById('iframeDocumentViewer');
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    convertToHtml({ arrayBuffer: binaryData }, { includeDefaultStyleMap: true })
    .then((result) => {      
      iframeDocument.body.innerHTML = result.value;
    })
    .catch((error) => {
      let errorHtml = `<h2>${this.props.dictionary["documentViewer.Error"]}</h2>`;
      iframeDocument.body.innerHTML=errorHtml;
    });
    
  }

  render() {
    return (
      <div className='docx-viewer-div'><iframe id="iframeDocumentViewer"/></div>
    );
  }
}
const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});
export default connect(mapStateToProps)(DocxViewer);