
import ModalForSurveyResultsView from "../Survey/ModalForSurveyResultsView";
import ModalForSurveyFillUp from "../SurveyAnswers/ModalForSurveyFillUp";
import { useDispatch, useSelector } from "react-redux";
import ModalForSurveyUpdate from "../Survey/ModalForSurveyUpdate";
import { Link } from 'react-router-dom';
import { useState } from "react";
import { getAllQuestionResults, getSurveyQuestionList } from "../../actions/surveyQuestionsActions";

import { findMethodById, getMethodSurveyDTO } from "../../actions/methodActions";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import { getTopicOfDiscussionDocuments } from '../../actions/topicOfDiscussionActions';



const SurveyActionBar = (props) => {

    const [showModalForSurveyResultsView, setShowModalForSurveyResultsView] = useState(false);
    const [showModalForSurveyFillUp, setShowModalForSurveyFillUp] = useState(false);
    const [showModalForSurveyUpdate, setShowModalForSurveyUpdate] = useState(false);

    const dispatch = useDispatch();
    
    const dictionary = useSelector(state => state.startUp.dictionary);

    const method = props.method;

    const handleCloseModalForSurveyResultsView = () => {
        setShowModalForSurveyResultsView(false);
    }
    const handleShowModalForSurveyResultsView = (id) => {
        dispatch(getAllQuestionResults(id));
        setShowModalForSurveyResultsView(true);
    }

    const handleShowModalForSurveyUpdate = (id) => {  
       setShowModalForSurveyUpdate(true);
   }

   const handleCloseModalForSurveyUpdate = () => {
       setShowModalForSurveyUpdate(false);
   }
   const handleShowModalForSurveyFillUp = (id) => {
       dispatch(getMethodSurveyDTO(id));
       setShowModalForSurveyFillUp(true);
   }
   const handleCloseModalForSurveyFillUp = () => {
       setShowModalForSurveyFillUp(false);
   }
    return(
        <>  {method.active && !method.participated && props.authRole == 3 &&
                <Link onClick={(e) => {
                    e.preventDefault();
                    handleShowModalForSurveyFillUp(method.id);
                }} 
                to="#" 
                className="tableFooterLinks method-links">
                    <PendingActionsIcon/>
                    <span>{dictionary["Method.Ucestvuj"]}</span>
            </Link>   
            }
            {
                method.endDate && 
                    <Link onClick={(e) => {
                        e.preventDefault();
                        handleShowModalForSurveyResultsView(method.id);
                    }} 
                    to="#" 
                    className="tableFooterLinks method-links">
                        <AssignmentTurnedInIcon/>
                        <span>{dictionary["Method.Rezultati"]}</span>
                    </Link>
            }
            {props.isModerator && !props.endProcess &&
                <Link onClick={(e) => {
                        e.preventDefault();
                        dispatch(getTopicOfDiscussionDocuments(props.topicOfDiscussionId));
                        handleShowModalForSurveyUpdate(method.id);
                    }} 
                    to="#" 
                    className="tableFooterLinks method-links">
                        <EditRoundedIcon/>
                        <span>{dictionary["Method.Izmeni"]}</span>
                </Link> 
            }
            
            {showModalForSurveyResultsView && 
                <ModalForSurveyResultsView
                show={showModalForSurveyResultsView}
                handleClose={handleCloseModalForSurveyResultsView}
                surveyId={method.id}
                //   surveyStatus={survey.status}
                surveyName={method.title}
                authRole={props.authRole}
                />
            }
            {showModalForSurveyFillUp && 
                <ModalForSurveyFillUp
                    show={showModalForSurveyFillUp}
                    handleClose={handleCloseModalForSurveyFillUp}
                    methodId={method?.id}
                    method={method}
                    authRole={props.authRole}
                    phase={props.phase}
                    topicOfDiscussionId={props.topicOfDiscussionId}
                />
            }
            {showModalForSurveyUpdate &&
                <ModalForSurveyUpdate
                show={showModalForSurveyUpdate}
                handleClose={handleCloseModalForSurveyUpdate}
                method={method}
                phase={props.phase}
                handleMethodRefresh={props.handleMethodRefresh}
                />
            }
        </>
    );
}

export default SurveyActionBar;