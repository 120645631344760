import React from 'react';
import { downloadDocument } from '../../utils';

const DownloadDocumentButton = (props) => {
   return (
      <button className= {typeof props.customClass != 'undefined' ? props.customClass : 'btnDownloadDocument'} onClick={() =>
        downloadDocument(
          props.documentContent,
          props.documentName
        )}>
        <i className="fa fa-download me-2"></i>
        {typeof props.label != 'undefined' ? props.label : ''}
      </button>
    );
  };
  
  export default DownloadDocumentButton;