import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { getMethodSurveyDTO } from '../../actions/methodActions';
import { createSurveyAnswers } from '../../actions/surveyAnswersActions';
import { getMethodList } from '../../actions/methodActions';
import { setMessage } from '../../utils';
import SurveyQuestion from '../Survey/SurveyQuestion';
import { trackPromise } from 'react-promise-tracker';
import { NotificationManager } from 'react-notifications';

const OPENED_QUESTION = 1;
const CLOSED_QUESTION = 2;

class ModalForSurveyFillUp extends React.Component {
  constructor() {
    super();
    this.state = {
      answers: [{ surveyQuestions: { id: '' }, answer: '' }],
      errors: {},
      surveyQuestionsList: [],
    };
  }

  componentDidMount() {
    const id = this.props.methodId;
    this.props.getMethodSurveyDTO(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const { ...surveyDTO } = nextProps.surveyDTO;

    this.setState({
      ...surveyDTO,
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleCreationDateChange = (date) => {
    this.setState({ creationDate: date });
  };

  handleValidation = (surveyQuestionsList) => {
    let error = false;

    surveyQuestionsList.forEach((element, index) => {
      if (element.required && (!element.closedAnswer || element.closedAnswer.length==0) && (!element.openedEndedAnswer || element.openedEndedAnswer.trim() =='')) {
        this.props.setMessage(
          this.props.dictionary["modalForSurveyFillUp.Validation.Odgovor"],
          null,
          `#msg${element.id}`
        );
        error = true;
      }
    });
    if(error){
      this.props.setMessage(
        this.props.dictionary["modalForSurveyFillUp.Validation.ObaveznaPitanjaValidacija"],
        null,
        '#errorMsg'
      );
    }
    return error;
  };

  
  updateQuestion = (question) => {
    const questions = [...this.state.surveyQuestionsList];
    const index = questions.findIndex((item) => item.id == question.id);
    if (index != -1) {
      const updatedQuestion = {...questions[index], ...question}
      questions[index] = updatedQuestion;
    }

    this.setState({surveyQuestionsList: questions})
  }

  handleSubmit = async (e) => {
    e.preventDefault();

    if (this.handleValidation(this.state.surveyQuestionsList) == true) {
      return;
    }

    const finalResult = this.state.surveyQuestionsList.map((item) => {
      const openedEndedAnswer =
        item.questionType.id == OPENED_QUESTION
          ? item.openedEndedAnswer
          : null;
      const closedAnswer =
        item.questionType.id == CLOSED_QUESTION
          ? item.closedAnswer
          : null;

      return {
        surveyQuestions: {
          id: item.id,
        },
        question: {
          id: item.id,
          questionType: item.questionType,
        },
        openedEndedAnswer,
        closedAnswer,
      };
    });

    await trackPromise(this.props.createSurveyAnswers(finalResult, this.props.methodId))
    .then(()=> {
      NotificationManager.success(this.props.dictionary["ModalForSurveyFillUp.UspesnoPopunjavanjeAnkete"] ,this.props.dictionary["universal.Uspesno"],5000)
    })
    .catch(() => {
      NotificationManager.error(this.props.dictionary["ModalForSurveyFillUp.NeuspesnoPopunjavanjeAnkete"], this.props.dictionary["universal.GreskaUObradi"], 5000)
    })

    await trackPromise(this.props.getMethodList(this.props.topicOfDiscussionId, this.props.phase.id))
    this.props.handleClose('submitted');
  };

  handleCheckChange = (questionId) => (e, option) => {
    this.setState({ [e.target.name]: option });
  };

  render() {
    const { errors } = this.state;

    const surveyQuestionsList = this.props.surveyDTO
      ? this.props.surveyDTO.surveyQuestionsList
      : [];

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="xl"
        backdrop={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <div className="form-group mb-4 mt-2">
              <h1 className="text-center">
                {this.props.surveyDTO ? this.props.surveyDTO.title : ''}
              </h1>
              <hr></hr>
            </div>
            {/* {surveyQuestionsAndAnswers} */}
            { surveyQuestionsList ? (
                surveyQuestionsList.map((question, index) => (<SurveyQuestion key={question.id}
                  updateQuestion={this.updateQuestion} 
                  question={question}
                  index={index}
                  questionsLength={surveyQuestionsList.length}
                  fillUp={true}
                  />))
            ) :
            ('')     
          }
            <div className="form-row">
              {(this.props.method.active && !this.props.method.participated && this.props.authRole == 3) &&
              surveyQuestionsList &&
              surveyQuestionsList.length != 0 ? (
                <>
                  <button
                    type="button"
                    id="subscribeButton"
                    className="col-md-3 m-auto"
                    onClick={this.handleSubmit}
                  >
                    {this.props.dictionary["modalForSurveyFillUp.sacuvaj"]}
                  </button>
                  <div className="d-inline-block ms-3" id={'errorMsg'} />
                </>
              ) : (
                ''
              )}
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  surveyDTO: state.method.methodSurveyDTO,
  errors: state.errors,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  getMethodSurveyDTO,
  createSurveyAnswers,
  setMessage,
  getMethodList
})(ModalForSurveyFillUp);
