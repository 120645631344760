import {
  GET_INSTITUTION,
  GET_INSTITUTION_LIST,
  GET_INSTITUTION_LIST_FILTERED,
  DELETE_INSTITUTION,
  SET_INSTITUTION_LIST_TABLE_FILTER
} from "../actions/types";

const initialState = {
  institutions: [],
  institution: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INSTITUTION_LIST:
      return {
        ...state,
        institutions: action.payload,
      };
      case GET_INSTITUTION_LIST_FILTERED:
        return {
          ...state,
          institutionsFiltered: action.payload,
        };
    case GET_INSTITUTION:
      return {
        ...state,
        institution: action.payload,
      };
    case DELETE_INSTITUTION:
      return {
        ...state,
        institutions: state.institutions.filter(
          (institution) => institution.id !== action.payload
        ),
      };
    case  SET_INSTITUTION_LIST_TABLE_FILTER:
      return {
        ...state,
        institutionListTableFilter: action.payload,
      };

    default:
      return state;
  }
}
