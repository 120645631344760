import axios from "axios";
import { NotificationManager } from "react-notifications";
import { errorMessage } from "../constants";

import {
  GET_ERRORS,
  GET_FIELD_OF_INTEREST,
  GET_FIELD_OF_INTEREST_LIST,
  GET_ACTIVE_FIELD_OF_INTEREST_LIST,
  GET_FIELD_OF_INTEREST_LIST_BY_JLS,
  GET_FIELD_OF_INTEREST_LIST_FOR_FILTER,
  DELETE_FIELD_OF_INTEREST,
  GET_FIELD_OF_INTEREST_IN_TOPIC
} from "./types";

export const createFieldOfInterest = (fieldOfInterest) => async (
  dispatch
) => {
  try {
    await axios.post("/api/fieldOfInterest/create", fieldOfInterest);
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
//ne koristi se
export const createAndUpdateFieldOfInterest = (
  fieldOfInterest,
  id,
  history
) => async (dispatch) => {
  try {
    await axios.post(
      `/api/fieldOfInterest/createAndUpdate/${id}`,
      fieldOfInterest
    );
    //history.push("/fieldOfInterestList");
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateFieldOfInterest = (fieldOfInterest) => async (
  dispatch
) => {
  try {
    await axios.post("/api/fieldOfInterest/update", fieldOfInterest);
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getAllFiledsOfInterest = () => async (dispatch) => {
  const res = await axios.get("/api/fieldOfInterest/findAll");
  dispatch({
    type: GET_FIELD_OF_INTEREST_LIST,
    payload: res.data,
  });
};
export const getAllFiledsOfInterestByJLS = () => async (dispatch) => {
  const res = await axios.get("/api/fieldOfInterest/findAllByJLS");
  dispatch({
    type: GET_FIELD_OF_INTEREST_LIST_BY_JLS,
    payload: res.data,
  });
};
export const getAllFiledsOfInterestForFilterList = (jls) => async (dispatch) => {
  const res = await axios.get(`/api/fieldOfInterest/findAllForFilterList${jls}`);
  dispatch({
    type: GET_FIELD_OF_INTEREST_LIST_FOR_FILTER,
    payload: res.data,
  });
};

export const getAllActiveFiledsOfInterest = () => async (dispatch) => {
  const res = await axios.get(`/api/fieldOfInterest/findAllActiveByJLS`);
  dispatch({
    type: GET_ACTIVE_FIELD_OF_INTEREST_LIST,
    payload: res.data,
  });
};

export const getFieldOfInterest = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/fieldOfInterest/findById/${id}`);
    dispatch({
      type: GET_FIELD_OF_INTEREST,
      payload: res.data,
    });
  } catch (e) {
    e.getMessage();
  }
};

export const deleteFieldOfInterest = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/fieldOfInterest/delete/${id}`);
    dispatch({
      type: DELETE_FIELD_OF_INTEREST,
      payload: id,
    });
  } catch (e) {
    throw(e);
  }
};

export const checkIfFieldOfInterestIsInTopic = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/fieldOfInterest/checkIfFieldOfInterestIsInTopic/${id}`);
    dispatch({
      type: GET_FIELD_OF_INTEREST_IN_TOPIC,
      payload: res.data,
    });
  } catch (e) {
    e.getMessage();
  }
};
