import {
    GET_PHASE_LIST,
    CREATE_PHASE,
    RESET_CREATED_PHASE,
    UPDATE_PHASE
} from "../actions/types";

const initialState = {
    phaseList: []
};

export default function (state = initialState, action) {
    switch(action.type) {
        case GET_PHASE_LIST:
            const konsultacijePhase = action.payload?.find(phase => phase.phaseType === 'Konsultacije');
            const javnaRaspravaPhase = action.payload?.find(phase => phase.phaseType === 'JavnaRasprava');
            return {
                ...state,
                phaseList: action.payload,
                consultativeProcess: konsultacijePhase || null,
                publicDebate: javnaRaspravaPhase || null
            };
        case CREATE_PHASE: 
            return{
                ...state,
                createdPhase:action.payload
            }
        case RESET_CREATED_PHASE: 
            return{
                ...state,
                createdPhase:action.payload
            }
        case UPDATE_PHASE: 
            return{
                ...state
            }
            default:
              return state;
    }
}