import axios from 'axios';
import {
  UPLOAD_DICTIONARY,
  GET_ERRORS,
} from './types';

export const downloadDictionary =
  (type, documentName) => async (dispatch) => {
    try{
      axios({
        url: `/api/dictionary/download-dictionary-by-type/${type}`,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', documentName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
      });
    }catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      throw err;
    }
  };

  export const uploadDictionary = (data, type) => async (dispatch) => {
    try {
      const res = await axios.post(
        `/api/dictionary/upload-dictionary-by-type/${type}`,
        data
      );
      dispatch({
        type: UPLOAD_DICTIONARY,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      throw err;
    }
  };