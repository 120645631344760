import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { updateRole, getRole } from '../../actions/roleActions';

class UpdateRole extends Component {
  constructor() {
    super();
    this.state = {
      roleName: '',
      errors: {},
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getRole(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const { id, roleName } = nextProps.role;

    this.setState({
      id,
      roleName,
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const updateRole = {
      id: this.state.id,
      roleName: this.state.roleName,
    };
    this.props.updateRole(updateRole, this.props.history);
  };

  render() {
    const { errors } = this.state;
    return (
      <div className="component-wrapper-fade-in">
        <div className="container">
          <br></br>
          <div className="row">
            <div className="col-md-12 m-auto">
              <h1 className="title text-center">{this.props.dictionary["updateRole.naslov"]}</h1>
              <hr />
              <br />
            </div>
            <form onSubmit={this.onSubmit} className="col-md-12">
              <div className="col-md-6 m-auto mb-4">
                <div className="form-group row mb-4">
                  <label className="labelText">{this.props.dictionary["updateRole.nazivRole"]}</label>
                  <input
                    type="text"
                    className={classnames('inputField form-control', {
                      'is-invalid': errors.roleName,
                    })}
                    placeholder={this.props.dictionary["updateRole.placeholder.nazivRole"]}
                    name="roleName"
                    value={this.state.roleName}
                    onChange={this.onChange}
                  />
                  {errors.roleName && (
                    <div className="invalid-feedback">{errors.roleName}</div>
                  )}
                </div>
              </div>
              <div className="row justify-content-center">
                <button id="subscribeButton" className="col-md-2">
                {this.props.dictionary["updateRole.sacuvaj"]}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

UpdateRole.propTypes = {
  updateRole: PropTypes.func.isRequired,
  getRole: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  role: state.role.role,
  errors: state.errors,
  dictionary: state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  updateRole,
  getRole,
})(UpdateRole);
