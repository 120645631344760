import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { getAllFiledsOfInterest } from '../../actions/fieldOfInterestActions';
import {
  getAllByUserId,
  createFieldOfInterestUserList,
} from '../../actions/fieldOfInterestUserActions';
import { setMessage } from '../../utils';
import { trackPromise } from "react-promise-tracker";
import {
  NotificationManager,
} from 'react-notifications';

class ModalForChoosingFieldsOfInterest extends React.Component {
  constructor(props) {
    super();
    this.state = {
      errors: {},
      selectedFieldsOfInterest: [],
    };
    this.handleCreationDateChange = this.handleCreationDateChange.bind(this);
    this.handleFieldOfInterestMultiselectChange =
      this.handleFieldOfInterestMultiselectChange.bind(this);
  }

  handleCreationDateChange = (date) => {
    this.setState({ creationDate: date });
  };

  handleComboBoxChange = (object, objectId) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
        fieldId: e.target.value,
        comboSignal: object,
      });
    };
  };

  handleClose = () => {
    this.props.handleClose();
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    this.props.getAllByUserId(this.props.userId);
    this.props.getAllFiledsOfInterest();
  }

  handleFieldOfInterestMultiselectChange(selectedOptions) {
    this.setState({ selectedFieldsOfInterest: selectedOptions || [] });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    const { fieldOfInterests } =
      nextProps.fieldOfInterestInstitutionUser
        .fieldOfInterestInstitutionUserList;

    this.setState({
      selectedFieldsOfInterest: fieldOfInterests
        ? fieldOfInterests.map((field) => ({
            ...field,
            value: field.id,
            label: field.name,
          }))
        : [],
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const listSelectedFields = this.state.selectedFieldsOfInterest;
    const newFUO = {
      fieldOfInterests: listSelectedFields,
      type: 'fieldOfInterests',
    };
    trackPromise(this.props
      .createFieldOfInterestUserList(newFUO))
      .then(() => this.props.handleClose())
      .then(() => {
        NotificationManager.success(this.props.dictionary["ModalForChoosingFieldsOfInterest.UspesanUnos"],this.props.dictionary["universal.Uspesno"], 5000);
      })
      .catch( () => {
        NotificationManager.error(this.props.dictionary["ModalForChoosingFieldsOfInterest.NeuspesanUnos"], this.props.dictionary["universal.GreskaUObradi"], 5000);
      });
  };
  render() {
    let fieldOfInterestList = [
      ...this.props.fieldOfInterest.fieldOfInterestList,
    ];
    const fieldOfInterestList1 = fieldOfInterestList.map((field) => ({
      ...field,
      value: field.id,
      label: field.name,
    }));
   
    let options = [];
    let options2 = [];
    for (var i = 0; i < fieldOfInterestList1.length; i++) {
      options.push(fieldOfInterestList1[i]);
    }
    const customStyleForMultiselect = {
      control: (styles) => ({ ...styles, minHeight: '45px' }),
      option: (styles) => ({
        ...styles,
        color: '#495057',
        fontSize: '17px',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
        marginLeft: '12px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
      }),
      input: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '1.25rem',
        marginLeft: '12px',
      }),
    };
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="modalForChoosingFieldsOfInterest"
      >
        <Modal.Header className="modalHeader">
            <Modal.Title>
                {this.props.dictionary["menu.Profil.OblastiOdInteresovanjaIzbor"]}
            </Modal.Title>
            <button onClick={this.props.handleClose} type="button" class="btn-close btn-close-white m-0 opacity-1" aria-label="Close"></button>
        </Modal.Header> 
        <Modal.Body>  
          <div>
            <div className="form-group col-md-12">
              <label className="labelText textShadow">{this.props.dictionary["ModalForChoosingFieldsOfInterest.OblastiOdInteresovanja"]}</label>
              <Select
                isMulti
                name="fieldOfInterestList1"
                options={options}
                onChange={this.handleFieldOfInterestMultiselectChange}
                placeholder={this.props.dictionary["ModalForChoosingFieldsOfInterest.Placeholder"]}
                id="multi1"
                value={this.state.selectedFieldsOfInterest}
                styles={customStyleForMultiselect}
              />
            </div>
            <div className="row mt-5 mb-2 justify-content-center">
              <button
                type="button"
                id="subscribeButton"
                className="col-md-3 me-0"
                onClick={this.handleSubmit}
              >
                {this.props.dictionary["ModalForChoosingFieldsOfInterest.Sacuvaj"]}
              </button>
            </div>
          </div>
          </Modal.Body>
      </Modal>
    );
  }
}

ModalForChoosingFieldsOfInterest.propTypes = {
  getAllFiledsOfInterest: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  fieldOfInterest: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  fieldOfInterest: state.fieldOfInterest,
  fieldOfInterestInstitutionUser: state.fieldOfInterestInstitutionUser,
  userRoles: state.security.userRoles,
  authRole: state.security.authRole,
  userId: state.security.userId,
  authUser: state.security.authUser,
  errors: state.errors,
  dictionary: state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  getAllFiledsOfInterest,
  setMessage,
  createFieldOfInterestUserList,
  getAllByUserId,
})(ModalForChoosingFieldsOfInterest);
