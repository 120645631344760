import React, { Component } from 'react';
// import { FileViewer } from 'react-file-viewer';
import DocViewer,  { DocViewerRenderers }  from '@cyntler/react-doc-viewer';
class ViewAttachedDocumentWithoutZoom extends Component {
  render() {
    let url;
    if (this.props.content) {
      const data = b64ToBlob(this.props.content);
      url = URL.createObjectURL(data);
    } else {
      return <div></div>;
    }

    window.addEventListener(
      'click',
      (e) => {
        URL.revokeObjectURL(url);
      },
      { once: true }
    );

    return (
      <div className="attachment-wrapper m-auto">
        {/* <FileViewer
          fileType={this.props.fileType}
          filePath={url}
          errorComponent={CustomErrorComponent}
          onError={(e) => console.log('error', e)}
          unsupportedComponent={UnsupportedComponent}
          // documents={[{uri:url}]}
        /> */}
        <DocViewer
                     documents={[{uri:url,fileType:this.props.fileType}]}
                     pluginRenderers={DocViewerRenderers}
                  />
      </div>
    );
  }
}

export default ViewAttachedDocumentWithoutZoom;

class CustomErrorComponent extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return <div>Дошло је до грешке!</div>;
  }
}

class UnsupportedComponent extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return <div>Дошло је до грешке!</div>;
  }
}

function b64ToBlob(b64) {
  const byteCharacters = window.atob(b64);

  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8ClampedArray(byteNumbers);

  const blob = new Blob([byteArray]);
  return blob;
}
