import {
  GET_USER_ORGANIZATION,
  GET_USER_ORGANIZATION_LIST,
  DELETE_USER_ORGANIZATION,
  CREATE_USER_ORGANIZATION,
  UPDATE_USER_ORGANIZATION,
  USER_ORGANIZATION_CRUD_IN_PROGRESS,
  USER_ORGANIZATION_CRUD_COMPLEATED,
} from "../actions/types";

const initialState = {
  userOrganizationList: [],
  userOrganization: {},
  userOrganizationCRUDCompleted:false,
  userOrganizationAdding: false,
  userOrganizationUpdateing: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_ORGANIZATION_CRUD_IN_PROGRESS:
      return {
        ...state,
        userOrganizationAdding: action.payload.CRUD=='create' ? true: false,
        userOrganizationUpdateing: action.payload.CRUD=='create' ? false: true,
        userOrganizationCRUDCompleted: false,
        userOrganization: action.payload.userOrganization,
      };
      case USER_ORGANIZATION_CRUD_COMPLEATED:
        return {
          ...state,
          userOrganizationAdding: false,
          userOrganizationUpdateing: false,
          userOrganizationCRUDCompleted: false,
        };
    case CREATE_USER_ORGANIZATION:  
      return {
        ...state,
        userOrganizationCRUDCompleted: true,
      };
      case UPDATE_USER_ORGANIZATION:  
      return {
        ...state,
        userOrganizationCRUDCompleted: true,
      };
    case GET_USER_ORGANIZATION_LIST:
      return {
        ...state,
        userOrganizationList: action.payload,
      };
    case GET_USER_ORGANIZATION:
      return {
        ...state,
        userOrganization: action.payload,
      };
    case DELETE_USER_ORGANIZATION:
      return {
        ...state,
        userOrganizationList: state.userOrganizationList.filter(
          (userOrganization) => userOrganization.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
