import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextArea from '../Reusable/TextArea';
import ComboBoxCommentStatus from './ComboBoxCommentStatus';
import {
  getCommentDTO,
  updateComment,
  getFilteredCommentListPost,
} from '../../actions/commentActions';
import { setMessage } from '../../utils';
import moment from 'moment';
import { getCommentDocuments } from '../../actions/commentDocumentActions';
import { Link } from 'react-router-dom';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
import { NotificationManager } from 'react-notifications';
import { trackPromise } from 'react-promise-tracker';
import { WidthWide } from '@mui/icons-material';
import authorizationService from '../../securityUtils/authorizationService';
import ComboReusable from '../Reusable/ComboReusable';
class ModalForUpdateComment extends React.Component {
  constructor() {
    super();
    this.state = {
      status: '',
      commentTextModerator: '',
      topicOfDiscussion: {
        id: 0,
      },
      visibilityExplanation: '',
      visibility: '',
      showCommentDocumentFilePreview: false,
      errors: {},
      radioDisabled:false,
      disabledByVisibility:false,
      yes:false,
      no:false
    };
    this.handleCheckChange = this.handleCheckChange.bind(this);
  }

  componentDidMount() {
    this.props.getCommentDTO(this.props.commentId);
    this.props.getCommentDocuments(this.props.commentId);
    
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    const {
      id,
      creationDate,
      commentText,
      topicOfDiscussion,
      status,
      creationDateModerator,
      commentTextModerator,
      user,
      category,
      visibility,
      phaseNumber,
      userOrganization,
      uuidDocumentName,
      textSegment,
      visibilityExplanation,
      articleNumber,
    } = nextProps.comment;

    this.setState({
      id,
      creationDate: creationDate ? moment(creationDate).toDate() : '',
      commentText,
      topicOfDiscussion,
      status: nextProps.comment.statusCode,
      creationDateModerator: creationDateModerator
        ? moment(creationDateModerator).toDate()
        : '',
      commentTextModerator,
      user,
      category: nextProps.comment.categoryCode,
      visibility,
      phaseNumber,
      userOrganization,
      uuidDocumentName,
      textSegment,
      visibilityExplanation,
      articleNumber,
    });

    this.setState({disabledByVisibility: nextProps.comment.visibility==='ДА'? true:false});
    this.setState({radioDisabled: nextProps.comment.statusCode==='Odbijen'? true:false});

    if(nextProps.comment.creationDateModerator===null){
      this.setState({yes:false, no:false});
    }
    if(nextProps.comment.creationDateModerator!==null && nextProps.comment.visibility==='ДА') {
      this.setState({yes:true, no:false});
    }
    if(nextProps.comment.creationDateModerator!==null && nextProps.comment.visibility==='НЕ') {
      this.setState({no:true, yes:false});
    }

    
      
  }

  onChangeSelect = (e) => {
    let defaultMessageForNo = this.props.dictionary["modalForUpdateComment.DefaultMessageForNo"].replace(/\\n/g, "\n");
    this.setState({ status: e.target.value });
    if(e.target.value === 'Odbijen') {
      this.setState({radioDisabled : true,
        yes: false,
        no:true,
        visibilityExplanation: defaultMessageForNo})
    }     
    else
      this.setState({radioDisabled : false})

  };

  
  onChange = (e) => {
  
    this.setState({ [e.target.name]: e.target.value });
    

  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleCheckChange = (e) => {
    let defaultMessageForYes = this.props.dictionary["modalForUpdateComment.DefaultMessageForYes"].replace(/\\n/g, "\n");
    let defaultMessageForNo = this.props.dictionary["modalForUpdateComment.DefaultMessageForNo"].replace(/\\n/g, "\n");

    if (e.target.name === 'yes') {
      if (this.state.yes == true) {
        this.setState({
          yes: true,
          no: false,
          visibilityExplanation: defaultMessageForYes,
        });
      } else {
        this.setState({
          yes: e.target.checked,
          no: !e.target.checked,
          visibilityExplanation: defaultMessageForYes,
        });
      }
    } else {
      if (this.state.no == true) {
        this.setState({
          yes: false,
          no: true,
          visibilityExplanation: defaultMessageForNo,
        });
      } else {
        this.setState({
          yes: !e.target.checked,
          no: e.target.checked,
          visibilityExplanation: defaultMessageForNo,
        });
      }
    }
  };

  handleValidation = () => {
    let error = false;

    if (this.state.yes == false && this.state.no == false || this.state.status==='UObradi') {
      this.props.setMessage(
        this.props.dictionary["modalForUpdateComment.StatusObjaveValidation"],
        '#visibility',
        '#msg1'
      );
      error = true;
    }

    if (
      !this.state.visibilityExplanation ||
      this.state.visibilityExplanation == ''
    ) {
      this.props.setMessage(
        this.props.dictionary["modalForUpdateComment.OdgovorValidation"],
        '#visibilityExplanation',
        '#msgVisibilityExplanation'
      );
      error = true;
    }

    if (
      !this.state.commentTextModerator ||
        this.state.commentTextModerator === ''
    ) {
      this.props.setMessage(
        this.props.dictionary["modalForUpdateComment.OdgovorValidation"],
        '#commentTextModerator',
        '#msg2'
      );
      error = true;
    }

    return error;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const commentUpdate = {
      id: this.props.commentId,
      creationDate: this.props.comment.creationDate,
      commentText: JSON.stringify(this.props.comment.commentText),
      topicOfDiscussion: {
        id: this.props.comment.topicOfDiscussionId,
      },
      creationDateModerator: this.props.comment.creationDateModerator,
      
      commentTextModerator: this.state.commentTextModerator,
      user: this.props.comment.user,
      visibility: this.state.yes == true ? 'ДА' : 'НЕ',
      userOrganization: this.props.comment.userOrganization,
      uuidDocumentName: this.props.comment.uuidDocumentName,
      textSegment: this.props.comment.textSegment,
      visibilityExplanation: this.state.visibilityExplanation,
      articleNumber: this.props.comment.articleNumber,

      commentCategory: this.props.commentCategories.find(x => x.code==this.props.comment.categoryCode),
      commentStatus: this.props.commentStatuses.find(x => x.code==this.state.status),
      method: {
        id : this.props.comment.methodId
      }

      
    };
    trackPromise(this.props
      .updateComment(commentUpdate))
      .then(()=> {
        NotificationManager.success(this.props.dictionary["modalForUpdateComment.UspesnaObradaKomentara"])
      }
      )
      .catch(() => {
        NotificationManager.error(this.props.dictionary["modalForUpdateComment.NeuspesnaObradaKomentara"])
      })
      .finally(() => {
        this.props.handleClose();
        this.props.getFilteredCommentListPost(this.props.comment.topicOfDiscussionId, this.props.searchParams);
      }     
      )
  };

  showModal = () => {
    this.setState({ showCommentDocumentFilePreview: true });
  };
  handlePreviewClose = () => {
    this.setState({ showCommentDocumentFilePreview: false });
  };

  render() {
    const { errors } = this.state;
    const commentStatuses = this.props.commentStatuses.filter(x => x.code != "Primljen" && x.code != "UObradi");
    let isEvaluator = this.props.comment.user ?
    authorizationService.canAccessSuggestionButton(
      this.props.comment.user.role.id
    ) : false;
    const commentText = this.props.comment.commentText
      ? this.props.comment.commentText.comment.text
      : '';
    const highlightedText = this.props.comment.commentText
      ? this.props.comment.commentText.content.text
      : '';
    const nameAndSurname = this.props.comment.user
      ? (this.props.comment.user.firstName +
        ' ' +
        this.props.comment.user.lastName) + ' (' + 
        (this.props.comment.user.agreedToBeContacted ? this.props.comment.user.email +(this.props.comment.user.telephone == null ? "" : ", " + this.props.comment.user.telephone) 
        :isEvaluator ? this.props.comment.user.institution.institutionName : this.props.dictionary["modalForUpdateComment.UserNeZeliDaBudeKontaktiran"])+")"
      : '';

    const pageNumber = this.props.comment.commentText
      ? this.props.comment.commentText.position
        ? 'Страна ' + this.props.comment.commentText.position.pageNumber
        : ''
      : '';

    const creationDateModerator = this.props.comment.creationDateModerator
      ? moment(this.props.comment.creationDateModerator).format(
          'DD.MM.YYYY HH:mm'
        )
      : '';

    const { commentDocumentList } = this.props.commentDocument;

    const documentName =
      commentDocumentList && commentDocumentList[0]
        ? commentDocumentList[0].documentName
        : '';

    const userOrganization = this.props.comment.userOrganization
      ? this.props.comment.userOrganization.organizationName
      : '';

    let isYesCheckedStyle =
      this.state.yes == true
        ? 'boldText labelText textShadow text-right me-1'
        : 'labelText textShadow text-right me-1';
    let isNoCheckedStyle =
      this.state.no == true
        ? 'boldText labelText textShadow text-right me-1 ms-4'
        : 'labelText textShadow text-right me-1 ms-4';

    const modalContent = (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <div className="row mt-3">
                <div className="col-md-6 float-left">
                  <h4 className="text-center">{this.props.dictionary["modalForUpdateComment.PregledKomentara"]}</h4>
                  <hr></hr>
                  <div className="form-group mb-4 mt-2">
                    <label className="labelText textShadow">{this.props.dictionary["modalForUpdateComment.Referenca"]}:</label>
                    {highlightedText ? (
                      <p style={{ wordBreak: 'break-all' }}>
                        {this.props.comment.textSegment}
                      </p>
                    ) : (
                      ''
                    )}
                    <p>
                      {!highlightedText
                        ? 'Нема референцу у тексту.'
                        : highlightedText}
                    </p>
                    {pageNumber && pageNumber != '' ? (
                      <label className="small-font labelText textShadow">
                        <em>{pageNumber}</em>
                      </label>
                    ) : (
                      ''
                    )}
                    <hr></hr>
                    <label className="labelText textShadow">{this.props.dictionary["modalForUpdateComment.Komentar"]}:</label>
                   <div className="overflow-auto" style={{ maxHeight:'250px'}}>
                    <p>{commentText}</p>
                   </div>
                   
                    <hr></hr>
                    <label className="labelText textShadow">{this.props.dictionary["modalForUpdateComment.Korisnik"]}:</label>
                    {userOrganization && userOrganization != '' ? (
                      <p>{nameAndSurname + ', ' + userOrganization}</p>
                    ) : (
                      <p>{nameAndSurname}</p>
                    )}
                    <hr></hr>
                    <label className="labelText textShadow">
                    {this.props.dictionary["modalForUpdateComment.DatumObjave"]}:
                    </label>
                    <p>
                      {moment(this.props.comment.creationDate).format(
                        'DD.MM.YYYY HH:mm'
                      )}
                    </p>
                    <div>
                      {this.props.comment.categoryCode == 'OpstiKomentar' ? (
                        <div className="form-row">
                          <hr></hr>
                          <table
                            id="example"
                            className="table table-sm mt-2 border-0"
                          >
                            <thead>
                            <tr className="border-0">
                              <th className="labelText textShadow border-0">
                              {this.props.dictionary["modalForUpdateComment.PrilozeniDokument"]}:
                              </th>
                              {commentDocumentList && commentDocumentList[0] ? (
                                <th className="text-center labelText textShadow border-0">
                                  Преглед:
                                </th>
                              ) : (
                                ''
                              )}
                            </tr>
                            </thead>
                            <tbody>
                              <tr className="border-0">
                                <td className="p-2 textShadow">
                                  {documentName
                                    ? documentName
                                    : this.props.dictionary["modalForUpdateComment.NemaPrilozenogDokumenta"]}
                                </td>
                                <td className="text-center">
                                  {commentDocumentList &&
                                    commentDocumentList[0] && (
                                      <Link onClick={(e) => {e.preventDefault();this.showModal();}} to="#">
                                        {this.props.dictionary["modalForUpdateComment.Pogledaj"]}
                                      </Link>
                                    )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 float-right">
                  <h4 className="text-center">{this.props.dictionary["modalForUpdateComment.ObradaKomentara"]}</h4>
                  <hr></hr>
                  <div className="form-group">
                  </div>
                  <div className="form-group">
                    <label className="labelText textShadow">
                    {this.props.dictionary["modalForUpdateComment.TekstOdgovora"]}: <span className="redAsterisk"> *</span>
                    </label>
                    <TextArea
                      type="text"
                      id="visibilityExplanation"
                      placeholder={this.props.dictionary["modalForUpdateComment.TekstOdgovoraPlaceholder"]}
                      name="visibilityExplanation"
                      value={this.state.visibilityExplanation}
                      onChange={this.onChange}
                      errors={errors.visibilityExplanation}
                      maxLength="1000"
                      rows={3}
                      disabled={this.state.disabledByVisibility}
                    />
                    <div id="msgVisibilityExplanation" />
                  </div>
                  <div className="from-group mt-2">
                    <label className="labelText textShadow">
                    {this.props.dictionary["modalForUpdateComment.StatusObjavljenogKomentara"]}:{' '}
                      <span className="redAsterisk"> *</span>
                    </label>
                    <ComboReusable
                      onChange={(e) => {this.onChangeSelect(e)}}
                      signal
                      updateValue={(this.state.status && this.state.status != '' && this.state.status != 'UObradi') ? this.state.status : '-1' }
                      disabled={this.state.disabledByVisibility}
                      id="commentStatusCB"
                      namePart={this.props.dictionary["ModalForUpdateComment.Placeholder.Status"]}
                      items={commentStatuses.map((commentStatus) => (
                     <option key={commentStatus.id} value={commentStatus.code}>
                      {commentStatus.value}
                      </option>
                ))}
               
                />
                  <div id="methodTypeCBMsg" />
                    
                  </div>
                  <div id="msg1" />
                  <div className="from-group mt-2"> 
                  <label className="lableText textShadow">
                    {this.props.dictionary["modalForUpdateComment.ObjavljivanjeKomentaraPitanje"]}?:{' '}
                      <span className="redAsterisk"> *</span>
                    </label>
                    <div className="form-row">
                      <label className={isYesCheckedStyle}>
                      {this.props.dictionary["modalForUpdateComment.Da"].toUpperCase()}
                      </label>
                      <input
                        type="radio"
                        id="visibility"
                        name="yes"
                        className="mt-2 filled-in"
                        style={{ cursor: 'pointer' }}
                        checked={this.state.yes}
                        onChange={this.handleCheckChange}
                        disabled={this.state.radioDisabled || this.state.disabledByVisibility} 
                      />
                      <label className={isNoCheckedStyle}>
                      {this.props.dictionary["modalForUpdateComment.Ne"].toUpperCase()}
                      </label>
                      <input
                        type="radio"
                        id="visibility"
                        name="no"
                        className="mt-2 filled-in"
                        style={{ cursor: 'pointer' }}
                        checked={this.state.no}
                        onChange={this.handleCheckChange}
                        disabled={this.state.radioDisabled || this.state.disabledByVisibility}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <label className="labelText textShadow">
                    {this.props.dictionary["modalForUpdateComment.Odgovor"]}: <span className="redAsterisk"> *</span>
                    </label>
                    <TextArea
                      type="text"
                      id="commentTextModerator"
                      placeholder={this.props.dictionary["modalForUpdateComment.OdgovorPlaceholder"]}
                      name="commentTextModerator"
                      value={this.state.commentTextModerator}
                      onChange={this.onChange}
                      errors={errors.commentTextModerator}
                      maxLength="2000"
                      rows={6}
                      disabled={this.state.disabledByVisibility}
                    />
                    <div id="msg2" />
                  </div>
                  {creationDateModerator != '' ? (
                    <div className="form-group mt-2">
                      <label className="labelText textShadow">
                      {this.props.dictionary["modalForUpdateComment.DatumObjave"]}:
                      </label>
                      <p>{creationDateModerator}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  <hr></hr>
                </div>
              </div>
            <br />
            <div className="row">
              <button
                type="button"
                id="subscribeButton"
                className="col-md-3 m-auto"
                onClick={this.handleSubmit}
                disabled={this.state.disabledByVisibility}
              >
                {this.props.dictionary["modalForUpdateComment.Sacuvaj"]}
              </button>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
    return (
      <Fragment>
        {modalContent}
        {this.state.showCommentDocumentFilePreview && (
          <ModalForDocumentPreview
            show={this.state.showCommentDocumentFilePreview}
            documentName={commentDocumentList[0].uuid + commentDocumentList[0].mimeTypeShort}
            controllerReference={'document'}
            handleClose={this.handlePreviewClose}
          />
        )}
      </Fragment>
    );
  }
}

ModalForUpdateComment.propTypes = {
  getCommentDTO: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  comment: state.comment.comment,
  commentDocument: state.commentDocument,
  errors: state.errors,
  dictionary: state.startUp.dictionary,
  commentStatuses: state.startUp.taxonomies.CommentStatus,
  commentCategories: state.startUp.taxonomies.CommentCategory
});

export default connect(mapStateToProps, {
  getCommentDTO,
  setMessage,
  updateComment,
  getFilteredCommentListPost,
  getCommentDocuments,
})(ModalForUpdateComment);
