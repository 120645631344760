import {
  GET_MODERATOR_RESOURCES,
  GET_MODERATOR_RESOURCES_LIST,
  DELETE_MODERATOR_RESOURCES,
} from "../actions/types";

const initialState = {
  moderatorResourcesList: [],
  moderatorResources: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MODERATOR_RESOURCES_LIST:
      return {
        ...state,
        moderatorResourcesList: action.payload,
      };
    case GET_MODERATOR_RESOURCES:
      return {
        ...state,
        moderatorResources: action.payload,
      };
    case DELETE_MODERATOR_RESOURCES:
      return {
        ...state,
        moderatorResourcesList: state.moderatorResourcesList.filter(
          (moderatorResources) => moderatorResources.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
