import Modal from 'react-bootstrap/Modal';
import ComboReusable from '../Reusable/ComboReusable';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { createMethod } from '../../actions/methodActions';
import { getTopicOfDiscussion, getTopicOfDiscussionDocuments } from '../../actions/topicOfDiscussionActions';
import Survey from './Survey';
import CommentGathering from './CommentGathering';
import Event from './Event';
import GeneralPost from './GeneralPost';
import { NotificationManager } from 'react-notifications';
import ExternalMembers from '../ExternalMembers/ExternalMembers';
import { checkPdfFormat, checkPdfStructure, setMessage } from '../../utils';
import { getDocument } from '../../actions/viewAttachmentAction';
import store from '../../store';



const ModalForAddNewMethod = (props) => {
    const methodTypes = useSelector(state => state.startUp.taxonomies.MethodType);
    const dictionary = useSelector(state => state.startUp.dictionary);
    const documentTypes = useSelector(state => state.startUp.taxonomies.DocumentType);
    const phaseTypes = useSelector(state => state.startUp.taxonomies.ProcessPhase);

    const phaseType = phaseTypes.find(x => x.code == props.phase.phaseType);

    const filteredMethodTypes = methodTypes.filter(methodType =>
        phaseType.taxonomyValueInformationList.some(
            x =>  (x.informationValue == `${methodType.id}` && x.informationName=="DostupniTipoviMetodaZaFazu")));
   
    const dispatch = useDispatch();


    const [ selectedMethodType, setSelectedMethodType ] = useState(); 
    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ linkPath, setLinkPath ] = useState('');
    const [ uploadedFiles, setUploadedFiles ] = useState([]);
    const [ startDate, setStartDate ] = useState();
    const [ endDate, setEndDate ] = useState();
    const [ location, setLocation ] = useState('');
    const [ documents, setDocuments ] = useState([]);
    const [ hasArticles, setHasArticles] = useState(false);
    const [ isSubmiting, setIsSubmiting] = useState(false);

    const clearState = () => {
        setTitle('');
        setDescription('');
        setLinkPath('');
        setUploadedFiles([]);
        setStartDate('');
        setEndDate('');
        setLocation('');
    };
    
    const handleComboBoxChange = (e) => {
        clearState();
        setSelectedMethodType(e.target.value);
    }

    const handleSubmit = async () => {
        const error = await handleValidation();
        if (!error) {
            if (!isSubmiting) {
                setIsSubmiting(true);
                let documentType = {}
                let existingDocuments = documents.map(document => ({ uuid: document.uuid }));

                if(selectedMethodType =="PrikupljanjeKomentara") {
                    documentType = documentTypes.find(x => x.code == "PrilogZaKomentarisanje")
                }
                else if(selectedMethodType == "JavniPozivOCD") {
                    documentType = documentTypes.find(x => x.code == "FormularPrijaveOCD")
                }
                else {
                    documentType = documentTypes.find(x => x.code == "Prilog")
                }
                const method = {
                    methodType: methodTypes.find(x => x.code == selectedMethodType),
                    title: title,
                    description: description,
                    topicOfDiscussion: {
                        id: props.topicOfDiscussionId
                    },
                    creationDate: Date.now(),
                    startDate: startDate,
                    endDate: endDate,
                    location: location,
                    hasArticles: hasArticles,
                    phase: {
                        id: props.phase.id},
                    linkPath: linkPath,
                    documents: existingDocuments
                };

                if (selectedMethodType == "Obavestenje") {
                    method.startDate = Date.now();
                }
        
                let formData = new FormData();
                for (let i = 0; i < uploadedFiles.length; i++) {
                formData.append('file', uploadedFiles[i]);
                formData.append('name', uploadedFiles[i].name);
                }
                formData.append(
                    'documenttype',
                    new Blob([JSON.stringify(documentType)], {
                    type: 'application/json',
                    })
                );    
            
                formData.append(
                'method',
                new Blob([JSON.stringify(method)], {
                    type: 'application/json',
                })
                );
        
                dispatch(createMethod(formData))
                .then( () => {
                    NotificationManager.success(dictionary["ModalForAddNewMethod.UspesnoDodavanje"],dictionary["universal.Uspesno"], 5000);
                    props.handleMethodRefresh(props.phase.id);
                    dispatch(getTopicOfDiscussion(props.topicOfDiscussionId));
                    dispatch(getTopicOfDiscussionDocuments(props.topicOfDiscussionId))
                })
                .catch( () => { 
                    NotificationManager.error(dictionary["ModalForAddNewMethod.DodavanjeNeuspelo"], dictionary["universal.GreskaUObradi"], 5000);
                })
                .finally(() => {
                    setIsSubmiting(false);
                    handleClose();
                })
                
        }
        }
        
    }

    const handleClose = () => {
        clearState();
        props.handleClose();
    }

    const b64ToBlob = (b64) => {
        const byteCharacters = atob(b64);
      
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
      
        const byteArray = new Uint8Array(byteNumbers);
      
        const blob = new Blob([byteArray]);
        return blob;
       }
    

    const handleValidation = async () => {
        let error = false;
        if (title?.length > 255) {
            dispatch(setMessage(
                dictionary["ModalForAddNewMethod.Validation.TitleLength"],
                '#title',
                '#titleМsg'
            ));
            error = true;
        }
        if (description?.length > 1000) {
            dispatch(setMessage(
                dictionary["ModalForAddNewMethod.Validation.DescriptionLength"],
                '#description',
                '#descriptionMsg'
            ));
            error = true;
        }
        if (selectedMethodType == null || selectedMethodType === "") {
            dispatch(setMessage(
                dictionary["ModalForAddNewMethod.Validation.TipMetode"],
                '#methodTypeCBM',
                '#methodTypeCBMsg'
            ));
            error = true;
        }
    
        if (selectedMethodType === "PrikupljanjeKomentara") {
            if (documents?.length == 1) {
                await dispatch(getDocument(documents[0].uuid + documents[0].mimeTypeShort,  'document'));
                const documentContent = store.getState().viewAttachment.dokument.documentContent;
                
                const pdfStructure = await checkPdfStructure(
                    [b64ToBlob(documentContent)],
                   "#fileChooser",
                   "#uploadMsg"
                 );
                 if (pdfStructure === false) {
                   setDocuments([]);
                   error = true;
                 }
            }

            if (uploadedFiles.length > 0) {const validPdf = !checkPdfFormat(uploadedFiles,
                "#fileChooser",
                "#uploadMsg");
            if (validPdf) {
            const pdfStructure = await checkPdfStructure(
                 uploadedFiles,
                "#fileChooser",
                "#uploadMsg"
              );
              if (pdfStructure === false) {
                setUploadedFiles([]);
                error = true;
              }
            } 
            else {
                setUploadedFiles([]);
                error = true}}
            if (title === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Title"],
                    '#title',
                    '#titleМsg'
                ));
                error = true;
            }
    
            if (startDate === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.StartDate"],
                    '#startDate',
                    '#startDateМsg'
                ));
                error = true;
            }
    
            if (endDate === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.EndDate"],
                    '#endDate',
                    '#endDateMsg'
                ));
                error = true;
            }
    
            if (description === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Description"],
                    '#description',
                    '#descriptionMsg'
                ));
                error = true;
            }
            if ((uploadedFiles?.length + documents?.length) != 1) {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.OneDocumentOnly"],
                    '#fileChooser',
                    '#uploadMsg'
                ));
                error = true;
            }
            if ((startDate && endDate) && (new Date(startDate) > new Date(endDate))) { 

                    dispatch(setMessage(
                        dictionary["ModalForAddNewMethod.Validation.StartDateEndDate"],
                        '#startDate',
                        '#startDateМsg'
                    ));
                    error = true; 
            }
        }

        if (selectedMethodType == "Anketa") {
            if (title === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Title"],
                    '#title',
                    '#titleМsg'
                ));
                error = true;
            }
        }

        if (selectedMethodType == "Obavestenje") {
            if (title === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Title"],
                    '#title',
                    '#titleМsg'
                ));
                error = true;
            }
            if (description === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Description"],
                    '#description',
                    '#descriptionMsg'
                ));
                error = true;
            }
        }

        if ((selectedMethodType == "OkrugliSto" || 
        selectedMethodType == "PanelDiskusija" || 
        selectedMethodType == "FokusGrupa" || 
        selectedMethodType == "OstaliDogadjaji")) {
           
            if (title === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Title"],
                    '#title',
                    '#titleМsg'
                ));
                error = true;
            }
            
            if (startDate === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.StartDate"],
                    '#startDate',
                    '#startDateМsg'
                ));
                error = true;
            }
            if (location === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Location"],
                    '#location',
                    '#locationMsg'
                ));
                error = true;
            }
        }

        if (selectedMethodType == "JavniPozivOCD") {
            if (title === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Title"],
                    '#title',
                    '#titleМsg'
                ));
                error = true;
            }
            if (startDate === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.StartDate"],
                    '#startDate',
                    '#startDateМsg'
                ));
                error = true;
            }
    
            if (endDate === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.EndDate"],
                    '#endDate',
                    '#endDateMsg'
                ));
                error = true;
            }
            if (description === '') {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.Description"],
                    '#description',
                    '#descriptionMsg'
                ));
                error = true;
            }
            if ((uploadedFiles?.length + documents?.length) != 1) {
                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.OneDocumentOnly"],
                    '#fileChooser',
                    '#uploadMsg'
                ));
                error = true;
            }
            if ((startDate && endDate) && (new Date(startDate) > new Date(endDate))) { 

                dispatch(setMessage(
                    dictionary["ModalForAddNewMethod.Validation.StartDateEndDate"],
                    '#startDate',
                    '#startDateМsg'
                ));
                error = true; 
        }
        }

        if (uploadedFiles.length > 0) {
            const filesArray = Array.from(uploadedFiles);
            let totalSize = 0;
            filesArray.forEach(element => {
                totalSize = totalSize + element.size;
            });
            if (totalSize >= 10000000) {
                dispatch(setMessage(
                    `${dictionary["ModalForAddNewMethod.Validation.DokumentiVeciOd10MB"]}`,
                    '#fileChooser',
                    '#uploadMsg' 
                ))
                error=true; 
            }
        }
    
        return error;
    };
    

    return (
        <Modal
        show={props.show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="d-flex flex-column">
        <h3 className="text-center textShadow m-auto order-2">
                  {dictionary["ModalForAddNewMethod.KreiranjeNoveMetode"]}
        </h3>
        </Modal.Header> 
        <Modal.Body className="modal-body-scroll">
            <div className="from-group">
                <label className="labelText textShadow">
                {dictionary["ModalForAddNewMethod.TipMetode"]} <span className="redAsterisk"> *</span>
                </label>
                <ComboReusable
                id="methodTypeCB"
                namePart={dictionary["ModalForAddNewMethod.Placeholder.TipMetode"]}
                items={filteredMethodTypes.map((methodType) => (
                    <option key={methodType.id} value={methodType.code}>
                    {methodType.value}
                    </option>
                ))}
                onChange={(e) => {handleComboBoxChange(e)}}
                />
                <div id="methodTypeCBMsg" />
            </div>
            {selectedMethodType == "Anketa" && 
                <Survey
                    title={title}
                    setTitle={setTitle}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    setDescription={setDescription}
                    documents={documents}
                    setDocuments={setDocuments}
                    phase={props.phase}

                />
            }
            {selectedMethodType == "PrikupljanjeKomentara" && 
                <CommentGathering
                title={title}
                setTitle={setTitle}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                description={description}
                setDescription={setDescription}
                documents={documents}
                setDocuments={setDocuments}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                phase={props.phase}
                selectedMethodType={selectedMethodType}
                hasArticles={hasArticles}
                setHasArticles={setHasArticles}
                edit={false}
                />
            }
            {(selectedMethodType == "OkrugliSto" || 
              selectedMethodType == "PanelDiskusija" || 
              selectedMethodType == "FokusGrupa" || 
              selectedMethodType == "OstaliDogadjaji") &&
                                <Event
                                title={title}
                                setTitle={setTitle}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                location={location}
                                setLocation={setLocation}
                                uploadedFiles={uploadedFiles}
                                setUploadedFiles={setUploadedFiles}
                                documents={documents}
                                setDocuments={setDocuments}
                                selectedMethodType={selectedMethodType}
                                phase={props.phase}
                                />
            }
            { selectedMethodType == "Obavestenje" && 
                <GeneralPost
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    linkPath={linkPath}
                    setLinkPath={setLinkPath}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    documents={documents}
                    setDocuments={setDocuments}
                    phase={props.phase}
                />
            }
            { selectedMethodType == "JavniPozivOCD" && 
                <ExternalMembers
                    title={title}
                    setTitle={setTitle}
                    description={description}
                    setDescription={setDescription}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    documents={documents}
                    setDocuments={setDocuments}
                    phase={props.phase}
                    readOnly={false}
                    selectedMethodType={selectedMethodType}
                />
            }
           
            <div className="mt-1 d-flex justify-content-around">
                <button
                    type="button"
                    id="subscribeButton"
                    className="m-auto"
                    onClick={handleClose}
                >
                    {dictionary["ModalForAddNewMethod.Odustani"]}
                </button>
                <button
                    type="button"
                    id="subscribeButton"
                    className="m-auto"
                    onClick={handleSubmit}
                >
                    {dictionary["ModalForAddNewMethod.Sacuvaj"]}
                </button>
            </div>
        </Modal.Body> 
      </Modal>
    ); 
}

export default ModalForAddNewMethod;