
import React from "react";
import { Oval } from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";
const LoadingIndicatorOverlay = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  const withoutPromise = props.withoutPromise || false;
  return (
     (
      (promiseInProgress || withoutPromise ) && <Oval
          height={100}
          width={100}
          color="#4357a18a"
          wrapperStyle={{ position: "fixed",
          top: "20%",
          left: "50%",
          width:"auto",
          transform: "translate(-50%, -50%)",
          zIndex:"9999"}}
          wrapperClass=""
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#C0C0C0"
          strokeWidth={3} 
          strokeWidthSecondary={3}
      />
      )
  );
};

export default LoadingIndicatorOverlay;
