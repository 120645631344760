import { useEffect, useState } from "react";
import { getPhaseList } from "../../actions/phaseActions";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Col, Row, Nav } from 'react-bootstrap';
import moment from "moment";
import { getMethodList, getMethodListAnon } from "../../actions/methodActions";
import PhaseContent from "./PhaseContent";
import ModalForUpdatePhase from "./ModalForUpdatePhase";
import authorizationService from '../../securityUtils/authorizationService';
import { Link } from 'react-router-dom';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ModalForStartingPhase from "./ModalForStartingPhase";
import LoadingIndicator from "../Reusable/LoadingIndicator";
import { getTopicOfDiscussionDocuments } from '../../actions/topicOfDiscussionActions';
import { trackPromise } from "react-promise-tracker";
import Alert from '@mui/material/Alert';
import { Tooltip } from "react-tooltip";
import { RESET_CREATED_PHASE } from "../../actions/types";

const Phases = (props) => {

    const [showModalForUpdatePhase, setShowModalForUpdatePhase] = useState(false);
    const [showModalForStartingPhase, setShowModalForStartingPhase] = useState(false)
    const [phaseCode, setPhaseCode] = useState('');
    const dispatch = useDispatch();
    const consultativeProcess = useSelector(state => state.phase.consultativeProcess);
    const publicDebate = useSelector(state => state.phase.publicDebate);
    const createdPhase = useSelector(state => state.phase.createdPhase)
    const methodList = useSelector(state => state.method.methodList);
    const user = useSelector(state => state.security);
    const dictionary = useSelector(state => state.startUp.dictionary);
    
    const [selectedTab, setSelectedTab] = useState('');

    const isModerator = authorizationService.canAccessProcessEditMode(
      user.authRole,
      user.userId,
      props.processCreatorId,
      props.topicOfDiscussionModerators
    );

    const isProcesFinished = props.topicOfDiscussion?.endProcess;
    
    // useEffect(() => {
    //     dispatch(getPhaseList(props.topicOfDiscussionId));
    // }, [])

    useEffect(() => {
      const consultativeActive = consultativeProcess && consultativeProcess.topicOfDiscussionId === props.topicOfDiscussionId && !publicDebate;
      const publicDebateActive = publicDebate && publicDebate.topicOfDiscussionId === props.topicOfDiscussionId && !consultativeProcess;
    
      if (consultativeActive) {
        setSelectedTab('consultativeProcess');
      } else if (publicDebateActive) {
        setSelectedTab('publicDebate');
      } else if (consultativeProcess && publicDebate && consultativeProcess.topicOfDiscussionId === props.topicOfDiscussionId && publicDebate.topicOfDiscussionId === props.topicOfDiscussionId) {
        setSelectedTab(consultativeProcess.active ? 'consultativeProcess' : 'publicDebate');
      }
    }, [consultativeProcess, publicDebate, props.topicOfDiscussionId]);

    useEffect(() => {
          if(consultativeProcess && selectedTab == 'consultativeProcess' ) {
              // setSelectedTab('consultativeProcess')
              handleMethodRefresh(consultativeProcess.id)
          }
          if(publicDebate && selectedTab == 'publicDebate') {
              // setSelectedTab('publicDebate')
              handleMethodRefresh(publicDebate.id)
          }
    }, [selectedTab])

    useEffect(() => {
      if (createdPhase) {
        handleMethodRefresh(createdPhase.id);
          dispatch({
            type: RESET_CREATED_PHASE,
            payload: null,
          })
        }
    }, [createdPhase])
    const isTabActive = (tab) => {
      return tab === selectedTab ? 'active-phase-tab' : '';
    };
    
    const isTabDisabled = (tab) => {
      if(tab === 'consultativeProcess')
      return (publicDebate!=null && consultativeProcess==null)
      if(tab === 'publicDebate')
      return (consultativeProcess!=null && (consultativeProcess?.active || (!consultativeProcess?.active && !consultativeProcess?.completed)) && publicDebate==null)
    }
    const handleTabClick = (tab) => {
      if(isTabDisabled(tab))
        return;
      setSelectedTab(tab);
      const phaseCode = tab ==='consultativeProcess' ? 'Konsultacije':'JavnaRasprava';
      setPhaseCode(phaseCode);
     if((phaseCode==='Konsultacije' && consultativeProcess==null && publicDebate==null) || 
          (phaseCode==='JavnaRasprava' && publicDebate==null && (!consultativeProcess || consultativeProcess.completed)))
        if (!isProcesFinished)  setShowModalForStartingPhase(true);
    };

    const handleMethodRefresh = (phaseId) => {

        if (phaseId) {
          if (user.authRole == 0) {
            trackPromise(dispatch(getMethodListAnon(props.topicOfDiscussionId, phaseId)))
            
          }
          else {
            trackPromise(dispatch(getMethodList(props.topicOfDiscussionId, phaseId)));
          }
        }
        
    }

    const handlePhaseRefresh = () => {
        props.handleRefresh();
    }

    const formatDate = (date) => {
        return date ? moment(date).format('DD.MM.YYYY.') : null
    }

    const handlePreviewClose = () => { 
      setShowModalForStartingPhase(false);
    }

    return (
        <>
          <Tab.Container
          defaultActiveKey={selectedTab}
          //onSelect={(k) => setSelectedTab(k)}
          >
          <Nav className="col min-height-90px">
              {((isModerator) || (!isModerator && consultativeProcess)) ? <>
                <Nav.Item 
                    data-tooltip-id="consultativeProcessTooltip"
                    id="consultativeProcess"
                    className={`phase-tab col-12 col-lg-6 ${isTabActive('consultativeProcess')} ${isTabDisabled('consultativeProcess')?'tab-disabled':''}`}
                    onClick={() => { handleTabClick('consultativeProcess')}}
                  >
                    <Nav.Link
                      eventKey={'consultativeProcess'}
                      onClick={() => {handleMethodRefresh(consultativeProcess?.id)}}
                    >
                      {(isModerator && !consultativeProcess && !publicDebate && !isProcesFinished) ? 
                        <>
                        <i className="far fa-play-circle me-2 fs-4 d-inline align-bottom default-label-text-link"></i>
                        <label
                        id="thirdIdLabel"
                        className="boldText default-tab-label-style d-inline default-label-text-link"
                        >
                          {dictionary["Phases.ZapocniKonsultacije"]}
                        </label>
                        </>
                        :
                        <label
                        id="thirdIdLabel"
                        className="boldText default-tab-label-style"
                        >
                          {dictionary["Phases.Konsultacije"]}
                        </label>
                      }
                      {consultativeProcess && 
                      <>
                      <label
                        id="thirdIdDateLabel"
                        className="default-tab-date-label-style"
                      >
                        {`${formatDate(consultativeProcess?.startDate)}`}
                        {consultativeProcess?.endDate ? `- ${formatDate(consultativeProcess?.endDate)}` : ''}
                      </label>
                      {consultativeProcess?.active && <>
                        <i className="fas fa-check-circle ms-2 colorGreen fs-lg"></i>
                        <label className="ms-1 colorGreen fw-bold text-uppercase">{dictionary["Phases.FazaJeAktivna"]}</label>
                      </>}
                      {!consultativeProcess?.active && !consultativeProcess.completed && <>
                        <i className="fas fa-ban ms-2 colorGray fs-lg"></i>
                        <label className="ms-1 colorGray fw-bold text-uppercase">{dictionary["Phases.FazaNijeAktivna"]}</label>
                      </>}
                      {!consultativeProcess?.active && consultativeProcess.completed && <>
                        <i className="fas fa-power-off ms-2 colorRed fs-lg"></i>
                        <label className="ms-1 colorRed fw-bold text-uppercase">{dictionary["Phases.FazaJeZavrsena"]}</label>
                      </>}
                      </>
                      }
                    </Nav.Link>
                  </Nav.Item>
                  {isTabDisabled('consultativeProcess') && <Tooltip
                  id="consultativeProcessTooltip"
                  type="info"
                  effect="solid"
                  place="top"
                  >
                  {dictionary["Phases.Tooltip.NeMozetePokrenutiKonsultacije"]}
              </Tooltip>}</> : <div className="phase-tab-empty col-12 col-lg-6"> 
              </div>}
                {((isModerator) || (!isModerator && publicDebate)) && props.topicOfDiscussion.actType.code!=='Ostalo' ? <>
                <Nav.Item
                data-tooltip-id="publicDebateTooltip"
                id="publicDebate"
                className={`phase-tab col-12 col-lg-6 default-label-text-link ${isTabActive('publicDebate')} ${isTabDisabled('publicDebate')?'tab-disabled':''}`}
                onClick={() => {handleTabClick('publicDebate')}}
              >
                <Nav.Link
                  eventKey={'publicDebate'}
                  onClick={() => {handleMethodRefresh(publicDebate?.id)}}
                >
                  {(isModerator && !publicDebate && !isProcesFinished) ? 
                  <>
                    <i className="far fa-play-circle me-2 fs-4 d-inline align-bottom default-label-text-link"></i>
                    <label
                    id="thirdIdLabel"
                    className="boldText default-tab-label-style d-inline default-label-text-link"
                    >
                      {dictionary["Phases.ZapocniJavnuRaspravu"]}
                    </label>
                  </>
                  :
                    <label
                    id="thirdIdLabel"
                    className="boldText default-tab-label-style"
                    >
                      {dictionary["Phases.JavnaRasprava"]}
                    </label>
                  }
                  
                  {publicDebate && 
                  <>
                  <label
                    id="thirdIdDateLabel"
                    className="default-tab-date-label-style"
                  >
                    {`${formatDate(publicDebate?.startDate)}`}
                    {publicDebate?.endDate ? `- ${formatDate(publicDebate?.endDate)}` : ''}
                  </label>
                  {publicDebate?.active && <>
                        <i className="fas fa-check-circle ms-2 colorGreen fs-lg"></i>
                        <label className="ms-1 colorGreen fw-bold text-uppercase">{dictionary["Phases.FazaJeAktivna"]}</label>
                      </>}
                      {!publicDebate?.active && !publicDebate.completed && <>
                        <i className="fas fa-ban ms-2 colorGray fs-lg"></i>
                        <label className="ms-1 colorGray fw-bold text-uppercase">{dictionary["Phases.FazaNijeAktivna"]}</label>
                      </>}
                      {!publicDebate?.active && publicDebate.completed && <>
                        <i className="fas fa-power-off ms-2 colorRed fs-lg"></i>
                        <label className="ms-1 colorRed fw-bold text-uppercase">{dictionary["Phases.FazaJeZavrsena"]}</label>
                      </>}
                  </>
                  }
                </Nav.Link>
              </Nav.Item> 
                {isTabDisabled('publicDebate') && <Tooltip
                  id="publicDebateTooltip"
                  type="info"
                  effect="solid"
                  place="top"
                  >
                  {dictionary["Phases.Tooltip.NeMozetePokrenutiJavnuRaspravu"]}
              </Tooltip>}
                </>
              : <div className="phase-tab-empty col-12 col-lg-6"> 
              </div>}
          </Nav>
          <Tab.Content className="p-3 border border-top-0 min-height-540px">
            { consultativeProcess == null && publicDebate == null && selectedTab=='' &&
                <Alert severity="warning">{dictionary["Phases.NijeKreiranaNijednaFaza"]}</Alert>
            }
             {selectedTab=='consultativeProcess' && 
             // <Tab.Pane defaultActiveKey={'consultativeProcess'} className="fade-in">
                <>
                  { (consultativeProcess == null) ?
                    <Alert severity="warning">{dictionary["Phases.NijeKreiranaFazaKonsultacija"]}</Alert> :
                    methodList?.length==0 && <Alert severity="warning">{dictionary["Phases.NemaKreiranihObavestenjaZaFazu"]}</Alert> 
                  } 
                  <PhaseContent 
                      topicOfDiscussionId={props.topicOfDiscussionId}
                      isProcesFinished={isProcesFinished} 
                      phaseId={consultativeProcess?.id}
                      phase={consultativeProcess}
                      topicOfDiscussionModerators={props.topicOfDiscussionModerators}
                      processCreatorId={props.processCreatorId}
                      handleMethodRefresh={handleMethodRefresh}
                      />
                </>

             // </Tab.Pane>
              }
              {selectedTab=='publicDebate' && 
                <>
                  { (publicDebate == null) ?
                    <Alert severity="warning">{dictionary["Phases.NijeKreiranaFazaJavneRasprave"]}</Alert> :
                    methodList?.length==0 && <Alert severity="warning">{dictionary["Phases.NemaKreiranihObavestenjaZaFazu"]}</Alert> 
                  }
              {/* <Tab.Pane eventKey={'publicDebate'} className="fade-in"> */}
                    <PhaseContent topicOfDiscussionId={props.topicOfDiscussionId} 
                                                  isProcesFinished={isProcesFinished} 
                                                  phaseId={publicDebate?.id}
                                                  phase={publicDebate}
                                                  topicOfDiscussionModerators={props.topicOfDiscussionModerators}
                                                  processCreatorId={props.processCreatorId}
                                                  handleMethodRefresh={handleMethodRefresh}
                                                  />
              {/* </Tab.Pane> */}
              </>
            }
          </Tab.Content>            
          </Tab.Container>
          <ModalForStartingPhase
            show={showModalForStartingPhase}
            handleClose={handlePreviewClose}
            topicOfDiscussionId={props.topicOfDiscussionId}
            topicOfDiscussion={props.topicOfDiscussion}
            phaseCode={phaseCode}
            consultativeProcess={consultativeProcess}
            handlePhaseRefresh={handlePhaseRefresh}
            setSelectedTab={setSelectedTab}
          />
        </>
    );
}

export default Phases;