

// import { Card } from 'react-bootstrap';
import ReadMoreLess from '../Reusable/ReadMoreReadLess';
import { Card, Image, Text, Badge, Button, Group } from '@mantine/core';
import moment from 'moment';
import { IconLink } from '@tabler/icons-react';
import { IconThumbUpFilled } from '@tabler/icons-react';
import { IconThumbDownFilled } from '@tabler/icons-react';
import { IconThumbUp} from '@tabler/icons-react';
import { IconThumbDown } from '@tabler/icons-react';
import { Avatar } from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateCommentUserVote } from '../../actions/commentUserVoteActions';
import { createCommentUserVote } from '../../actions/commentUserVoteActions';
import { useState } from 'react';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';




const CommentDisplayCard = ({comment, setRefresh}) => {
    const userId = useSelector(state => state.security.userId);
    const authRole = useSelector(state => state.security.authRole);
    const dictionary = useSelector(state => state.startUp.dictionary);

    const dispatch = useDispatch();

    const [showModalForDocumentPreview, setShowModalForDocumentPreview] = useState(false);
    
    const handleCloseModalForDocumentPreview = () => {
        setShowModalForDocumentPreview(false);
    }

    const statusBadgeColor = comment.status === 'Прихваћен' ? 'green' : comment.status === 'Делимично прихваћен' ? 'yellow' : 'red';
    const categoryBadgeColor = comment.category == 'Општи коментар' ? 'blue' : 'grape';

    const icon = <IconLink style={{ width: "0.9rem", height: "0.9rem" }} />;

    const creationDate = comment.creationDate ? moment(comment.creationDate).format('DD.MM.YYYY. HH:mm') : null;
    const moderatorCreationDate = comment.creationDateModerator ? moment(comment.creationDateModerator).format('DD.MM.YYYY. HH:mm') : null;

    const userLikedComment = comment.commentUserVote.filter(cuv => cuv.userId == userId.toString() && cuv.hasLiked == true).length > 0;
    
    const userDislikedComment = comment.commentUserVote.filter(cuv => cuv.userId == userId.toString() && cuv.hasDisliked == true).length > 0;

    const vote = (vote) => {
    var currentVote = comment.commentUserVote.filter(cuv => cuv.userId == userId.toString())?.[0];
    if(currentVote != null) {
        //update
        const updatedCommentUserVote = {
            id: currentVote.id,
            commentId: comment.id,
            userId: userId,
            hasLiked: vote,
            hasDisliked: !vote
        }
        dispatch(updateCommentUserVote(updatedCommentUserVote)).then(()=>{setRefresh(Math.random())});
    } else {
        const newCommentUserVote = {
            commentId: comment.id,
            userId: userId,
            hasLiked: vote,
            hasDisliked: !vote
        }
        dispatch(createCommentUserVote(newCommentUserVote)).then(()=>{setRefresh(Math.random())});
    }
    };
    
    return (
        <>
            <Card shadow="0" padding="" radius="0" withBorder className='mt-2 mb-2'>
                <Group justify="space-between">
                    <Avatar color="cyan" radius="xl">{comment.userInitials}</Avatar>
                    <Text fw={500}>{comment.userFullName}</Text>
                    {comment.userOrganizationOrganizationName && <Text fw={500}>({comment.userOrganizationOrganizationName})</Text>}
                    <Text>{creationDate}</Text>
                    <Badge variant="dot" color={categoryBadgeColor}>{comment.category}</Badge>
                    <Badge variant="dot" color={statusBadgeColor}>{comment.status}</Badge>
                    {comment.commentDocumentName && <Badge leftSection={icon} className='attachment-badge' onClick={() => {setShowModalForDocumentPreview(true)}}>{dictionary["CommentDisplayCard.Prilog"]}</Badge>}
                </Group>
                {comment.category != 'Општи коментар' ? 
                <>
                    <ReadMoreLess className="user-offset pb-2" text={comment.selectedText} maxLength={500}/>
                    <ReadMoreLess className="user-offset" text={comment.commentText.slice(1, -1)} maxLength={500}/>
                </> :
                <ReadMoreLess className="user-offset" text={comment.commentText.slice(1, -1)} maxLength={500}/>
                }
                
                <Group className="user-offset">
                    {authRole == 3 ? 
                        (userLikedComment ? <IconThumbUpFilled className='emotion can-like' 
                                                            onClick={() => {vote(false)}}/> : 
                                            <IconThumbUp className='emotion can-like'
                                                        onClick={() => {vote(true)}}/>) : 
                        (<IconThumbUp className='emotion'/>)}
                    <span>{comment.numberOfLikes ? comment.numberOfLikes : 0}</span>
                    {authRole == 3 ? 
                        (userDislikedComment ?  <IconThumbDownFilled className='emotion can-like'
                                                                    onClick={() => {vote(true)}}/> : 
                                                <IconThumbDown className='emotion can-like'
                                                            onClick={() => {vote(false)}}/>) : 
                        (<IconThumbDown className='emotion'/>)}
                    <span>{comment.numberOfDislikes ? comment.numberOfDislikes : 0}</span>
                </Group>
                {comment.commentTextModerator && comment.creationDateModerator && 
                <div className="user-offset pt-3">
                    <Group>
                        <Avatar color="blue" radius="xl">{dictionary["CommentDisplayCard.M"]}</Avatar>
                        <Text fw={500}>{dictionary["CommentDisplayCard.Moderator"]}</Text>
                        <Text>{moderatorCreationDate}</Text>
                    </Group>
                    <ReadMoreLess className="user-offset" text={comment.commentTextModerator} maxLength={500}/>
                </div> 
                }
            </Card>
            {showModalForDocumentPreview && (
            <ModalForDocumentPreview
                show={showModalForDocumentPreview}
                 documentName={comment.uuidCommentDocumentName}
                controllerReference={'document'}
                handleClose={handleCloseModalForDocumentPreview}
            />
            )}
        </>
        
    );
}

export default CommentDisplayCard;