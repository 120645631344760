import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import authorizationService from '../../securityUtils/authorizationService';
import Alert from 'react-bootstrap/Alert';
import { Link } from "react-router-dom";
import ModalForTopicOfDiscussionDocuments from './ModalForTopicOfDiscussionDocuments';
class TopicOfDiscussionPageDetailsTable extends Component {
  constructor() {
    super();
    this.state = {
      showModalForTopicOfDiscussionDocuments:false
    };
  }

  showModalForTopicOfDiscussionDocuments = (e) => {
    e.preventDefault(e);
    this.setState({
      showModalForTopicOfDiscussionDocuments: true,
    });
  };

  handlePreviewClose = () => {
    this.setState({
      showModalForTopicOfDiscussionDocuments: false,
    });
  };

  showModalForTopicOfDiscussionDocuments = (e) => {
    e.preventDefault();
    this.setState({
      showModalForTopicOfDiscussionDocuments: true,
    });
  }
  // onDownloadClick = (id, nameOfTopic, reportType) => {
  //   const action = null;
  //   switch (reportType) {
  //     case 1:
  //       action = () => {
  //         this.props.downloadTopicOfDiscussion(id, nameOfTopic);
  //       };
  //       break;
  //     case 2:
  //       break;
  //     case 4:
  //       action = () => {
  //         this.props.downloadTopicOfDiscussionStatistics(reportType);
  //       };
  //       break;
  //     case 5:
  //       action = () => {
  //         this.props.downloadTopicOfDiscussionStatistics(reportType);
  //       };
  //       break;
  //   }

  //   confirmDialog('преузмете извештај о датом процесу', action, '');
  // };

  render() {
    let counter = -1;
    let counter2 = -1;
    let listOfFields = this.props.topicOfDiscussion.fieldOfInterests
      ? this.props.topicOfDiscussion.fieldOfInterests.map((field, index) => {
          counter++;
          if (
            counter ==
            this.props.topicOfDiscussion.fieldOfInterests.length - 1
          ) {
            return (
              <span key={index} className="d-inline">
                {field.name}
              </span>
            );
          } else {
            return (
              <span key={index} className="d-inline">
                {field.name + ', '}
              </span>
            );
          }
        })
      : [];
    let currentPhaseName = this.props.topicOfDiscussion.currentPhaseName ? this.props.topicOfDiscussion.currentPhaseName : '/';
    let institutions = this.props.topicOfDiscussion.institutionList;
    let listOfInstitutions = institutions
      ? institutions.map((institution, index) => {
          let name = institution.institutionName +  this.props.dictionary["TopicOfDiscussionPageDetailsTable.Neaktivno"];
          if (institution.currentlyActive === true) {
            name = institution.institutionName;
          }
          counter2++;
          if (counter2 == institutions.length - 1) {
            return (
              <span key={index} className="form-group">
                {name}
              </span>
            );
          } else {
            return (
              <span key={index} className="form-group">
                {name}
                <br />
              </span>
            );
          }
        })
      : [];

      let numberOfDays = this.props.topicOfDiscussion.trajanje;

    let isFinished = this.props.topicOfDiscussion
      ? this.props.topicOfDiscussion.endProcess == true
        ? true
        : false
      : false;


    let details = (
      <div className="table-responsive">
        <table id="detailsTable" className="table table-sm">
          <tbody>
            <tr width="100%">
              <td  colSpan="2" width="100%" className="border-right">
                <div className='tdHeader'>
                  <label className="tableDetailsFooterLabels">{this.props.dictionary["TopicOfDiscussionPageDetailsTable.Predlagac"]}</label>
                </div>
                <p className="tableDetailsFooterData">
                  {listOfInstitutions && listOfInstitutions.length !== 0
                    ? listOfInstitutions
                    : this.props.topicOfDiscussion.institution
                    ? this.props.topicOfDiscussion.institution.institutionName
                    : ''}
                </p>
              </td>
              </tr>
              <tr width="100%">
              <td colSpan="2" width="100%">
              <div className='tdHeader'>
                <label className="tableDetailsFooterLabels">{this.props.dictionary["TopicOfDiscussionPageDetailsTable.Oblast"]}</label>
              </div>
                <p className="tableDetailsFooterData">{listOfFields}</p>
              </td>
              </tr>
              <tr>
              <td className="border-right" width="50%">
              <div className='tdHeader'>
                <label className="tableDetailsFooterLabels">{this.props.dictionary["TopicOfDiscussionPageDetailsTable.VrstaAkta"]}</label>
              </div>
                <p className="tableDetailsFooterData">{this.props.topicOfDiscussion.actType.value}</p>
              </td>
              <td width="50%">
              <div className='tdHeader'>
                <label className="tableDetailsFooterLabels">{this.props.dictionary["TopicOfDiscussionPageDetailsTable.Faza"]}</label>
              </div>
                <p className="tableDetailsFooterData">{this.props.activePhase}</p>
              </td>
              </tr>
            <tr>
              <td width="50%">
              <div className='tdHeader'>
              <label className="tableDetailsFooterLabels">
                {this.props.dictionary["TopicOfDiscussionPageDetailsTable.TrajanjeProcesa"]}{' '}
              </label>
              </div>
                <label className="tableDetailsFooterData">
                  {!numberOfDays.toString().endsWith('1')
                    ? numberOfDays + this.props.dictionary["TopicOfDiscussionPageDetailsTable.Dana"]
                    : numberOfDays + this.props.dictionary["TopicOfDiscussionPageDetailsTable.Dan"]}
                </label>
              </td>
              <td width="50%">
              <div className='tdHeader'>
                <label className="tableDetailsFooterLabels ">{this.props.dictionary["TopicOfDiscussionPageDetailsTable.Status"]}</label>
              </div>
                <label className="tableDetailsFooterData">
                  {isFinished == false ? this.props.dictionary["TopicOfDiscussionPageDetailsTable.Aktivan"] : this.props.dictionary["TopicOfDiscussionPageDetailsTable.Zavrsen"]}
                </label>
              </td>
            </tr>
            <tr>
              <td width="50%" className="border-right">
                <Link to="#" onClick={(e) => {this.showModalForTopicOfDiscussionDocuments(e)}}>
                  <div className='tdHeader'>
                  <label className="tableDetailsFooterLabels">
                    {this.props.dictionary["TopicOfDiscussionPageDetailsTable.Dokumentacija"]}{`\t`}
                  </label>
                  </div>
                    <label className="tableDetailsFooterData cursor-pointer fs-5 default-label-text-link border-bottom-solid pb-0">
                      {this.props.topicOfDiscussion.numberOfDocuments}
                      <i className="fas fa-folder ms-2"></i>
                    </label>
                </Link>
              </td>
              <td width="50%" className="border-bottom-0">
              <div className='tdHeader'>
              <label className="tableDetailsFooterLabels">
                {this.props.dictionary["TopicOfDiscussionPageDetailsTable.Ucesnici"]}
                </label>
              </div>
                <label className="tableDetailsFooterData fs-5">
                  {this.props.topicOfDiscussion.numberOfSubscribers}
                  <i className="fas fa-users ms-2"></i>
                </label>
              </td>
            </tr>
            {this.props.relatedTopicOfDiscussionList?.length>0 && 
              <tr width="100%">
              <td  colSpan="2" width="100%" className="border-right">
                <div className='tdHeader'>
                  <label className="tableDetailsFooterLabels">{this.props.dictionary["TopicOfDiscussionPageDetailsTable.PovezaniProcesi"]}</label>
                </div>
                {this.props.relatedTopicOfDiscussionList.map((relatedTopicOfDiscussion) => 
                  <Link
                  to={`/topicOfDiscussionPage/${relatedTopicOfDiscussion.id}/${1}`}
                  key={relatedTopicOfDiscussion.id}
                  target={'_self'}
                  className="tableDetailsFooterData d-block mt-1 commentGatheringLink"
                  >
                    {relatedTopicOfDiscussion.nameOfTopic}
                  </Link>
                )}  
              </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    );
    return (
      <>
        {details}
        {this.state.showModalForTopicOfDiscussionDocuments == true &&
          <ModalForTopicOfDiscussionDocuments
            show={this.state.showModalForTopicOfDiscussionDocuments}
            handleClose={this.handlePreviewClose}
            topicOfDiscussionId={this.props.topicOfDiscussion.id}
            topicOfDiscussionDocuments={this.props.topicOfDiscussionDocuments}
            phase = {this.props.phase}
            topicOfDiscussionModerators={this.props.topicOfDiscussionModerators}
            topicOfDiscussion={this.props.topicOfDiscussion}
            processCreatorId={this.props.processCreatorId}
            handleRefresh = {this.props.handleRefresh}
          />
        }
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
})(TopicOfDiscussionPageDetailsTable);
