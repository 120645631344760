
import React, {useEffect, useMemo, useState} from "react";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import { getErrorLog } from '../../actions/errorLogActions';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_SR_CYRL_RS } from 'mantine-react-table/locales/sr-Cyrl-RS';
import {
    NotificationManager,
  } from 'react-notifications';
  import 'dayjs/locale/sr-cyrl';

const ErrorTableServerFiltering = (props) => {
    
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    //table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([ {
        id: 'errorDate', 
        desc: true,
      },
      ]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [searchParams, setSearchParams] = useState({});

    const columns = useMemo(
        () => [
          //{
          //  accessorKey: 'errorDate',
          //  header: props.dictionary["errorLog.errorDate"],
          //  size:120,
          //},
          {
            id : 'errorDate',
            Cell : ({ cell }) => `${cell.getValue()?.toLocaleDateString('sr-RS')} ${cell.getValue()?.toLocaleTimeString('sr-RS')}`,
            accessorFn: (row) => new Date(row.errorDate),
            filterVariant: 'date-range',
            mantineFilterDateInputProps: {
              locale: 'sr-cyrl'
            },
            header: props.dictionary["errorLog.errorDate"],
            size: 140,
            mantineTableHeadCellProps: {
              className:'dateColumn'
            },
        },
          {
            accessorKey: 'errorMessage',
            header: props.dictionary["errorLog.errorMessage"],
            size:110
          },
          {
            accessorKey: 'errorType',
            header: props.dictionary["errorLog.errorType"],
            size:140
          },
          {
            accessorKey: 'source',
            header: props.dictionary["errorLog.source"],
            size:140,
          },
          {
            accessorKey: 'userid',
            header: props.dictionary["errorLog.userid"],
            size:160
          }                
        ],
        [],
      );
      useEffect(() => {
        if (!props.errorList?.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        }
        var filters = [];
        columnFilters?.forEach(element => {
          if(element.id != "errorDate") filters.push(element);
          else {
            if(element.value[0] != undefined && element.value[0] != undefined && element.value[0] != "") {
              const offset = element.value[0].getTimezoneOffset();
              const dateFrom = new Date(element.value[0].getTime() - (offset*60*1000));
              filters.push( {id: "errorDateFrom", value: dateFrom});
            }
            if(element.value[1] != undefined && element.value[1] != undefined && element.value[1] != "") {
              const offset = element.value[1].getTimezoneOffset();
              const dateTo = new Date(element.value[1].getTime() - (offset*60*1000));
              filters.push( {id: "errorDateTo", value: dateTo});
            }
          }
        });
        const searchParams = { 
          start: pagination.pageIndex,
          size: pagination.pageSize,
          filters: filters,
          globalFilter: globalFilter ?? '',
          sorting: sorting ?? []
        };
        setSearchParams(searchParams);
        trackPromise(props.getErrorLog(searchParams))
        .catch(() => {
           setIsError(true);
           NotificationManager.error(props.dictionary["universal.NeuspesnoUcitavanjePodataka"]);
          })
        .then( () => {
          setIsError(false);
        })
        .finally(() => {
          setIsLoading(false);
          setIsRefetching(false);
        }
      );
    }, [
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting
    ]);
      const customTable = 'custom-table';
      const customPaper = 'custom-paper'
      return   (
        <>
        <MantineReactTable
          localization={MRT_Localization_SR_CYRL_RS}
          enableGlobalFilter = {false}
          columns={columns}
          data={props.errorList}
          displayColumnDefOptions= {{ 'mrt-row-numbers': { size:10, align:'center' } }}
          mantineTableProps={{
            className: {customTable},
            striped:true,
            tablelayout: 'fixed',
          }}
          mantineProgressProps={{
            striped:false,
            animate:true,
            size:'xs'
          }}
          mantinePaperProps={{
            className:{customPaper}
          }}
          enableRowNumbers
          initialState={{ showColumnFilters: true }}
          manualFiltering
          manualPagination
          manualSorting
          muiToolbarAlertBannerProps={
            isError
              ? {
                  color: 'error',
                  children: 'Error loading data',
                }
              : undefined
          }
          onColumnFiltersChange={setColumnFilters}
          onGlobalFilterChange={setGlobalFilter}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={props.rowCount}
          state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
          }}
        />
        </>
      );
}
const mapStateToProps = (state) => {
    return {
    dictionary:state.startUp.dictionary,
    errorList: state?.errorLog?.errors?.content ?? [],
    rowCount: state?.errorLog?.errors?.totalElements ?? 0
  } };
  
  export default connect(mapStateToProps, {
    getErrorLog
  })(ErrorTableServerFiltering);