import React, { Component } from "react";

class RoleChooser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: 0,
    };
    this.handleRoleChanged = this.handleRoleChanged.bind(this);
  }

  toggleCheckbox(e) {
    this.setState(
      {
        selectedRole: e.target.id,
      }
      ,
      () => {
        this.props.handleRoleChanged(this.state.selectedRole);
      }
    );
    //this.handleRoleChanged(this.state.selectedRole);
  }

  handleRoleChanged(selectedRole) {}

  render() {
    return (
      <RoleList
        userRoles={this.props.userRoles}
        selectedRole={this.state.selectedRole}
        toggleCheckbox={(e) => this.toggleCheckbox(e)}
      />
    );
  }
}

const RoleList = ({ userRoles, selectedRole, toggleCheckbox }) => {
  return (
    <div>
      {userRoles.map((role, index) => (
        <Role
          key={index}
          role={role}
          checked={selectedRole === index + 1 ? true : false}
          toggleCheckbox={toggleCheckbox}
          selectedRole={selectedRole}
        />
      ))}
    </div>
  );
};

const Role = ({ role, toggleCheckbox, selectedRole }) => {
  return (
    <p>
      <input
        className="mt-2 filled-in"
        type="radio"
        style={{ cursor: 'pointer', border: 0, height: '1.1em', verticalAlign: 'sub' }}
        id={role.id}
        name="selectedRole"
        onChange={toggleCheckbox}
      />
      <span className="ml-2"> {role.roleName}</span>
    </p>
  );
};

export default RoleChooser;
