import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { useState } from 'react';

import { setMessage } from '../../utils'; 
import { connect } from 'react-redux';
import { publishMethod } from '../../actions/methodActions'; 
import { getMethodList } from '../../actions/methodActions';
import { NotificationManager } from 'react-notifications';


const ModalForSurveyPublish = (props) => {
    const dictionary = useSelector((store) => store.startUp.dictionary);

    const dispatch = useDispatch();

    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    
    const handleStartDateChange = (e) => {
        if (!props.startDate) {
            const selectedDate = new Date(e);
            const isMidnight = selectedDate.getHours() === 0 && selectedDate.getMinutes() === 0;
    
            if (isMidnight) {
                const now = new Date();
                selectedDate.setHours(now.getHours());
                selectedDate.setMinutes(now.getMinutes());
                setStartDate(selectedDate);
            } else {
                setStartDate(e);
            }
        } else { 
            setStartDate(e);
        }
    }

    const handleEndDateChange = (e) => {
        if (!props.endDate) {
            const selectedDate = new Date(e);
            const isMidnight = selectedDate.getHours() === 0 && selectedDate.getMinutes() === 0;
        
            if (isMidnight) {
            const adjustedDate = new Date(e);
            adjustedDate.setHours(23);
            adjustedDate.setMinutes(59);
            setEndDate(adjustedDate);
            } else {
            setEndDate(e);
            }
        } else {
            setEndDate(e);
        }
    }

    const handlePublishSurvey = async() => {
        const error = await handleValidation();
        if (!error) {
            const survey = props.survey;
            survey.startDate = startDate;
            survey.endDate = endDate;
    
    
            dispatch(publishMethod(survey)).then( () => {
                NotificationManager.success(dictionary["ModalForSurveyPublish.UspesnaObjava"],dictionary["universal.Uspesno"], 5000);
              })
              .catch( () => { 
                NotificationManager.error(dictionary["ModalForSurveyPublish.ObjavaNeuspela"], dictionary["universal.GreskaUObradi"], 5000);
              }).then(() => {dispatch(getMethodList(survey.topicOfDiscussionId, props.phase.id))});
            props.handleClose();
            props.handleModalForUpdateClose();
        }
    }

    const handleValidation = () => {
        let error = false;
        if (!startDate) {
            props.setMessage(
                `${dictionary["ModalForSurveyPublish.Validation.DatumObjavljivanja"]}`,
                '#startDate',
                '#startDateMsg'
              );
        error = true;
        }
        if (!endDate) {
            props.setMessage(
                `${dictionary["ModalForSurveyPublish.Validation.DatumZatvaranja"]}`,
                '#endDate',
                '#endDateMsg'
              );
        error = true;
        }
        if ((startDate && endDate) && (new Date(startDate) > new Date(endDate))) { 

            props.setMessage(
                dictionary["ModalForSurveyPublish.Validation.StartDateEndDate"],
                '#startDate',
                '#startDateEndDateMsg'
            );
            error = true; 
        }
        return error;
    }
    return ( 
        <Modal
        show={props.show}
        onHide={props.handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <h3 className="text-center textShadow">
            {dictionary["ModalForSurveyPublish.ObjavaAnkete"]}
            </h3>
            <hr></hr>
            <div className='d-flex'>
                <div className="group pe-2">
                    <label className="labelText textShadow pe-3">{dictionary["ModalForSurveyUpdate.DatumKreiranja"]}: <span className="redAsterisk">*</span></label>
                    <DatePicker
                        id="startDate"
                        selected={startDate}
                        onChange={(e) => {handleStartDateChange(e)}}
                        className="form-control"
                        dateFormat="dd.MM.yyyy HH:mm"
                        placeholderText={dictionary["ModalForSurveyUpdate.Placeholder.DatumKreiranja"]}
                        minDate={moment().toDate()}
                        maxDate={moment(props.phase.endDate).toDate()}
                        showTimeInput
                        timeInputLabel={dictionary["DatePicker.TimeInputLabel"]}
                    />  
                    <div id="startDateMsg"/>
                </div>
                <div className="group ps-2">
                    <label className="labelText textShadow pe-3">{dictionary["ModalForSurveyUpdate.DatumZatvaranja"]}: <span className="redAsterisk">*</span></label>
                        <DatePicker
                        id="endDate"
                        selected={endDate}
                        onChange={(e) => {handleEndDateChange(e)}}
                        className="form-control"
                        dateFormat="dd.MM.yyyy HH:mm"
                        placeholderText={dictionary["ModalForSurveyUpdate.Placeholder.DatumZatvaranja"]}
                        minDate={
                            startDate ? moment(startDate).toDate() : moment().toDate()
                            }
                        maxDate={moment(props.phase.endDate).toDate()}
                        showTimeInput
                        timeInputLabel={dictionary["DatePicker.TimeInputLabel"]}
                        /> 
                        <div id="endDateMsg"/>
                </div>
                
            </div>
            <div className="pt-2" id="startDateEndDateMsg"/> 
            <div className='row'>
                <button className='create-button col-md-6 m-auto mt-3' onClick={handlePublishSurvey}>{dictionary["ModalForSurveyPublish.ObjaviAnketu"]}</button>
            </div>
          </Modal.Body>
        </Modal.Header>
    </Modal>
    );
}

export default connect(null, {setMessage})(ModalForSurveyPublish);
