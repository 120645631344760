import { useEffect, useState } from "react";
import TextArea from "../Reusable/TextArea";
const SurveyOpenedAnswer = (props) => {
    const [answer, setAnswer] = useState(props.answer);

    useEffect(() => {
        setAnswer(props.answer);
      }, [props.answer]);

    const onTextChangeHandler = (e) => {
        setAnswer(e.target.value);
        props.updateFillUpAnswer(e.target.value);
    }
    return ( 
        <> 
            <TextArea
            type="text"
            placeholder='Одговор'
            name={'answer' + props.questionId}
            value={answer}
            onChange={onTextChangeHandler}
            maxLength="1000"
            rows={3}
            disabled={props.disabled || props.moderatorReadOnly}
           />
        </>
    ); 
}

export default SurveyOpenedAnswer;