import {
  GET_COMMENT_DOCUMENT,
  GET_COMMENT_DOCUMENT_LIST,
  DELETE_COMMENT_DOCUMENT,
} from "../actions/types";

const initialState = {
  commentDocumentList: [],
  commentDocument: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMMENT_DOCUMENT_LIST:
      return {
        ...state,
        commentDocumentList: action.payload,
      };
    case GET_COMMENT_DOCUMENT:
      return {
        ...state,
        commentDocument: action.payload,
      };
    case DELETE_COMMENT_DOCUMENT:
      return {
        ...state,
        commentDocumentList: state.commentDocumentList.filter(
          (commentDocument) => commentDocument.id !== action.payload
        ),
      };

    default:
      return state;
  }
}
