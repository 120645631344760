import {
  GET_OGP_DESCRIPTION,
  GET_OGP_DESCRIPTION_LIST,
  CREATE_OGP_DESCRIPTION,
} from "../actions/types";

const initialState = {
  ogpDescriptionList: [],
  ogpDescription: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OGP_DESCRIPTION_LIST:
      return {
        ...state,
        ogpDescriptionList: action.payload,
      };
    case GET_OGP_DESCRIPTION:
      return {
        ...state,
        ogpDescription: action.payload,
      };
    case CREATE_OGP_DESCRIPTION:
      return {
        ...state,
        ogpDescriptionList: state.ogpDescriptionList.concat(action.payload),
      };
    default:
      return state;
  }
}
