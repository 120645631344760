import React, { Component } from 'react';
import { Tr, Td } from 'react-super-responsive-table';
import { Link } from 'react-router-dom';

export default class NotificationTopicOfDiscussionRow extends Component {
  render() {
    let cssLiStyle =
      this.props.quickPreviewSignal == 1
        ? 'quickPreviewTopicTitleText mb-2 '
        : 'topicTitleText mb-2 ';
    return (
      <Tr>
        <Td id="unorderedList" className="border-0">
          <Link
            to={`/topicOfDiscussionPage/${this.props.notification.topicOfDiscussion.id}/1`}
            className="remove-link-effect"
          >
            <div>
              <li className={cssLiStyle}>
                {this.props.notification.notificationDescription}
              </li>
            </div>
          </Link>
        </Td>
      </Tr>
    );
  }
}
