import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllOgpFiledsOfInterest } from '../../actions/ogpFieldOfInterestActions';
import PropTypes from 'prop-types';
import OgpFieldOfInterestTable from './OgpFieldOfInterestTable';
import CreateButton from '../Reusable/CreateButton';
import authorizationService from '../../securityUtils/authorizationService';

class OgpFieldOfInterestList extends Component {
  componentDidMount() {
    this.props.getAllOgpFiledsOfInterest();
  }

  render() {
    const { ogpFieldOfInterestList } = this.props.ogpFieldOfInterest;

    return (
      <div className="container component-wrapper-fade-in">
        <br></br>
        <div className="row">
          <div className="col-md-11 m-auto">
            <div className="text-left mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="textShadow" dangerouslySetInnerHTML={{__html: this.props.dictionary["OgpFieldOfInterestList.POU"]}}>
                </h2>
                <div className="float-right">
                  {authorizationService.canAccessOGPPageSpecificAreas(
                    this.props.authRole
                  ) && <CreateButton route={`/addOgpFieldOfInterest`} />}
                </div>
              </div>
              <div>
                <OgpFieldOfInterestTable
                  ogpFieldOfInterestList={ogpFieldOfInterestList}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  ogpFieldOfInterest: state.ogpFieldOfInterest,
});

export default connect(mapStateToProps, { getAllOgpFiledsOfInterest })(
  OgpFieldOfInterestList
);
