import React, { Component } from 'react';
import { connect } from "react-redux";
import ActionPlanRow from './ActionPlanRow';
import authorizationService from '../../securityUtils/authorizationService';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';

class ActionPlanTable extends Component {
  render() {
    let actionPlanList = this.props.actionPlanList.map((actionPlan) => (
      <ActionPlanRow
        key={actionPlan.id}
        actionPlan={actionPlan}
      />
    ));

    const authRole = this.props.authRole;

    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-md border-0">
          <Thead>
            <Tr className="border-0">
              <Th className="border-0 boldText emphesized-label-font">
                {this.props.dictionary["ActionPlanTable.Dokument"]}
              </Th>
              <Th
                width="18%"
                className="border-0 boldText emphesized-label-font text-center"
              >
                {this.props.dictionary["ActionPlanTable.OstaviKomentar"]}
              </Th>
              {authorizationService.canAccessOGPPageSpecificAreas(
                authRole
              ) ? (
                <Th
                  width="10%"
                  className="border-0 boldText emphesized-label-font text-center"
                >
                  {this.props.dictionary["ActionPlanTable.Brisanje"]}
                </Th>
              ) : (
                <Th className="border-0"></Th>
              )}
            </Tr>
          </Thead>
          <Tbody>{actionPlanList}</Tbody>
        </Table>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  errors: state.errors,
  user: state.user,
  authUser: state.security.authUser,
  authRole: state.security.authRole,
  userRoles: state.security.userRoles,
  userId: state.security.userId,
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps,{})(ActionPlanTable);
