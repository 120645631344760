import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  deleteOgpActionPlanArchive,
  downloadOgpActionPlanArchive,
} from '../../actions/ogpActionPlanArchiveActions';
import DeleteButton from '../Reusable/DeleteButton';
import ViewDocumentButton from '../Reusable/ViewDocumentButton';
import authorizationService from '../../securityUtils/authorizationService';
import { Tr, Td } from 'react-super-responsive-table';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Link } from 'react-router-dom';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';

class OgpActionPlanArchiveRow extends Component {
  constructor() {
    super();
    this.state = {
      showOgpActionPlanArchivePreview: false,
    };
  }

  showOgpActionPlanArchivePreview = () => {
    this.setState({ showOgpActionPlanArchivePreview: true });
  };

  handlePreviewClose = () => {
    this.setState({ showOgpActionPlanArchivePreview: false });
  };

  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteOgpActionPlanArchive(id);
    };
    confirmDialog(`${this.props.dictionary["OgpActionPlanArchiveRow.Dialog.Obrisi.Poruka"]}`, 
                  action, 
                  `${this.props.dictionary["OgpActionPlanArchiveRow.Dialog.Obrisi"]}`);
  };

  onDownloadClick = (uuidDocName, documentName) => {
    const action = () => {
      this.props.downloadOgpActionPlanArchive(uuidDocName, documentName);
    };
    confirmDialog(`${this.props.dictionary["OgpActionPlanArchiveRow.Dialog.Preuzmi.Poruka"]}`,  
                  action, 
                  `${this.props.dictionary["OgpActionPlanArchiveRow.Dialog.Preuzmi.Poruka"]}`);
  };

  render() {
    const { ogpActionPlanArchive } = this.props || {};
    const row = (
      <Tr>
        {/*<Td>
          {this.props.ogpActionPlanArchive.validFrom}-
          {this.props.ogpActionPlanArchive.validUntil}
        </Td>*/}
        <Td>{ogpActionPlanArchive.documentName}</Td>
        <Td className="text-center">
          <Link onClick={(e) => {e.preventDefault();this.showOgpActionPlanArchivePreview();}} to="#">
          {this.props.dictionary["Document.Pogledaj"]}
          </Link>
        </Td>
        <Td className="text-center">
          <Link
            onClick={(e) =>{
              e.preventDefault(e);
              this.onDownloadClick(
                ogpActionPlanArchive.uuidDocName,
                ogpActionPlanArchive.documentName
              )
            }}
            to="#"
          >
            <GetAppIcon className="previewButton" />
          </Link>
        </Td>
        {authorizationService.canAccessOGPPageSpecificAreas(
          this.props.authRole
        ) && (
          <Td className="text-center">
            <DeleteButton
              click={() => this.onDeleteClick(ogpActionPlanArchive.id)}
            />
          </Td>
        )}
      </Tr>
    );
    return (
      <Fragment>
        {row}
        {this.state.showOgpActionPlanArchivePreview && (
          <ModalForDocumentPreview
            show={this.state.showOgpActionPlanArchivePreview}
            documentName={ogpActionPlanArchive.uuidDocName}
            controllerReference={'ogpActionPlanArchiveFile'}
            handleClose={this.handlePreviewClose}
          />
        )}
      </Fragment>
    );
  }
}

OgpActionPlanArchiveRow.propTypes = {
  deleteOgpActionPlanArchive: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  deleteOgpActionPlanArchive,
  downloadOgpActionPlanArchive,
})(OgpActionPlanArchiveRow);
