import axios from 'axios';
import {
  GET_ERRORS,
  GET_OGP_REPORT_FILE_LIST,
  GET_OGP_REPORT_FILE,
  DELETE_OGP_REPORT_FILE,
} from './types';

export const saveOgpReportFile = (data, history) => async (dispatch) => {
  await axios.post(`/api/ogpReportFile/upload`, data);
  history.push('/ogpPage/4');
};

export const getOgpReportFilesById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpReportFile/findById/${id}`);
    dispatch({
      type: GET_OGP_REPORT_FILE_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOgpReportFiles = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpReportFile/findAll`);
    dispatch({
      type: GET_OGP_REPORT_FILE_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOgpReportFile = (documentName) => async (dispatch) => {
  const res = await axios.get(`/api/ogpReportFile/find/${documentName}`);
  dispatch({
    type: GET_OGP_REPORT_FILE,
    payload: res.data,
  });
};

export const deleteOgpReportFile = (id) => async (dispatch) => {
  axios.delete(`/api/ogpReportFile/delete/${id}`);
  dispatch({
    type: DELETE_OGP_REPORT_FILE,
    payload: id,
  });
};
