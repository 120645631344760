import React, { Component } from "react";
import NotificationRow from "./NotificationRow";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";

class NotificationTable extends Component {

  render() {
    const{handleShowModalForMethodComments}=this.props;
    let notificationList = this.props.notificationList.map((notification) => (
      <NotificationRow
        key={notification.id}
        notification={notification}
        quickPreviewSignal={this.props.quickPreviewSignal}
        setSeenByCitizen={this.props.setSeenByCitizen}
        handleShowModalForMethodComments={handleShowModalForMethodComments} 
      />
    ));

    return (
      <Table id="example" className="table-responsive">
        <Tbody id="topicUnorderedList" className="col-md-12">
          {notificationList}
        </Tbody>
      </Table>
    );
  }
}

export default NotificationTable;
