import axios from "axios";

import {
    CREATE_METHOD_PARTICIPANT,
    GET_METHOD_PARTICIPANTS,
    GET_METHOD_PARTICIPANT_DOCUMENT,
    GET_ERRORS

} from "./types";

  export const createMethodParticipant = (data) => async (
    dispatch
  ) => {
    
    try {
        const res = await axios.post('/api/methodparticipant/create', data);
        dispatch({
            type: CREATE_METHOD_PARTICIPANT,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
        throw(err);
    }
  };   

  export const getAllMethodParticipants = (methodId) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/methodparticipant/findAll/${methodId}`);
      dispatch({
        type: GET_METHOD_PARTICIPANTS,
        payload: res.data
      })
    }
    catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
  } 


  export const getUserFilledMethodDocument = (methodId, userId) => async (dispatch) => {
    try {
      const res = await axios.get(`/api/methodparticipant/findUserFilledMethodDocument/${methodId}/${userId}`);
      dispatch({
        type: GET_METHOD_PARTICIPANT_DOCUMENT,
        payload: res.data
      })
    }
    catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    }
  }

  
