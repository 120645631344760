import axios from 'axios';
import {
  GET_ERRORS,
  GET_OGP,
  GET_OGP_LIST,
  DELETE_OGP,
  UPDATE_OGP,
  GET_OGP_LIST_PAGEABLE,
} from './types';

export const createOgp = (ogp, history) => async (dispatch) => {
  try {
    await axios.post('/api/ogp/create', ogp);
    history.push('/ogpPage/1');
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateOgp = (ogp, history) => async (dispatch) => {
  try {
    const res = await axios.post('/api/ogp/update', ogp);
    if (history) {
      history.push('/ogpPage/1');
    }
    dispatch({
      type: UPDATE_OGP,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const statusUpdateOgp = (ogp, history) => async (dispatch) => {
  try {
    const res = await axios.post('/api/ogp/statusUpdate', ogp);
    if (history) {
      history.push('/ogpPage/1');
    }
    dispatch({
      type: UPDATE_OGP,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const finalUpdateOgp = (ogp, history) => async (dispatch) => {
  try {
    const res = await axios.post('/api/ogp/finalUpdate', ogp);
    if (history) {
      history.push('/ogpPage/1');
    }
    dispatch({
      type: UPDATE_OGP,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOgps = () => async (dispatch) => {
  const res = await axios.get('/api/ogp/findAllDTO');
  dispatch({
    type: GET_OGP_LIST,
    payload: res.data,
  });
};

export const findAllOgpsPageble =
  (pageNumber, pageSize) => async (dispatch) => {
    const res = await axios
      .get(`/api/ogp/findAll/${pageNumber}/${pageSize}`)
      .then((response) => {
        dispatch({
          type: GET_OGP_LIST_PAGEABLE,
          payload: [
            response.data.content,
            response.data.totalPages,
            response.data.totalElements,
          ],
        });
      });
  };

export const getOgp = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogp/findById/${id}`);
    dispatch({
      type: GET_OGP,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const deleteOgp = (id) => async (dispatch) => {
  await axios.delete(`/api/ogp/delete/${id}`);
  dispatch({
    type: DELETE_OGP,
    payload: id,
  });
};
export const downloadOgpList = (id) => async (dispatch) => {
  axios({
    url: `/api/ogp/exportOgpInWord/${id}`,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'OGP ' + '.docx');
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  });
};
export const downloadExcel = () => async (dispatch) => {
  axios({
    url: `/api/ogp/exportOgpInExcel`,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Репозиторијум' + '.xlsx');
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
  });
};
