import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ModalForUpdateTopicOfDiscussion from './ModalForUpdateTopicOfDiscussion';
import authorizationService from '../../securityUtils/authorizationService';
import ModalForAddModerator from '../TopicOfDiscussionModerator/ModalForAddModerator';
import SupervisorAccountRoundedIcon from '@mui/icons-material/SupervisorAccountRounded';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CommentIcon from '@mui/icons-material/Comment';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import { Tooltip } from 'react-tooltip';
import { finishTooEarly, hideProcessAndNotifyModerator } from '../../actions/topicOfDiscussionActions';
import {
  downloadTopicOfDiscussion,
  downloadConsultativeProcessReportForm,
  downloadPublicDebateReportForm,
} from '../../actions/topicOfDiscussionReportActions';
import { downloadCommentList } from '../../actions/commentActions';
import ShareIcon from '@mui/icons-material/Share';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ModalForAddTopicOfDiscussionRelation from './ModalForAddTopicOfDiscussionRelation';
import { trackPromise } from 'react-promise-tracker';
import { NotificationManager } from 'react-notifications';
import TopicOfDiscussionTakeOver from './TopicOfDiscussionTakeOver';
import {
  createTopicOfDiscussionParticipant,
  unsubscribeFromTopicOfDiscussion,
} from '../../actions/topicOfDiscussionParticipantActions';
import BookmarkRemoveRoundedIcon from '@mui/icons-material/BookmarkRemoveRounded';
import BookmarkAddRoundedIcon from '@mui/icons-material/BookmarkAddRounded';
import ModalForHidingProcess from './ModalForHidingProcess';

class TopicOfDiscussionPageModeratorAndEvaluatorTopControls extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTopicOfDiscussion: false,
      showModalForAddModerator: false,
      showModalForAddTopicOfDiscussionRelation:false,
      showModalForHidingProcess: false,
      showMessage: false,
      report: {
        id: 0,
      },
      userIsSubscribed: props.topicOfDiscussion.participants?.filter(x=>x.user.id==this.props.authUserId).length>0
    };
  }
  // componentWillReceiveProps(nextProps) {
  //   console.log('Received props, subscribed: ' + this.state.userIsSubscribed);
  //   if (nextProps.topicOfDiscussion) {
  //     this.setState({ userIsSubscribed: nextProps.topicOfDiscussion.participants?.filter(x=>x.user.id==this.props.authUserId).length>0 });
  //     console.log('Changed state, subscribed: ' + this.state.userIsSubscribed);
  //     console.log('New value: ' + nextProps.topicOfDiscussion.participants?.filter(x=>x.user.id==this.props.authUserId).length>0);
  //   }
  // }
  showModalForUpdateTopicOfDiscussion = (e) => {
    e.preventDefault(e);
    this.setState({
      showTopicOfDiscussion: true,
    });
  };

  showModalForAddModerator = (e) => {
    e.preventDefault(e);
    this.setState({
      showModalForAddModerator: true,
    });
  };

  showModalForAddTopicOfDiscussionRelation = (e) => {
    e.preventDefault(e);
    this.setState({
      showModalForAddTopicOfDiscussionRelation: true,
    });
  };

  showModalForHidingProcess = (e) => {
    e.preventDefault(e);
    this.setState({
      showModalForHidingProcess: true,
    });
  };
  
  
  handlePreviewClose = () => {
    this.setState({
      showTopicOfDiscussion: false,
      showModalForAddModerator: false,
      showModalForAddTopicOfDiscussionRelation:false,
      showModalForHidingProcess: false
    });
  };

  handleShowMesasage = () => {
    this.setState({
      showMessage: true,
    });

    setTimeout(() => {
      this.setState({
        showMessage: false,
      });
    }, 3000);
  };


  handleComboBoxChange = (object, objectId, id, nameOfTopic) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
      });
      const selectedOptionText = e.target.options[e.target.selectedIndex].text;
      const reportType = e.target.value;
      let action = null;
      if (reportType === '1' || reportType === '2') {
        action = () => {
          this.props.downloadTopicOfDiscussion(id, nameOfTopic, reportType);
        };
      } else if (reportType === '3') {
        action = () => {
          this.props.downloadCommentList(id, nameOfTopic);
        };
      } else if (reportType === '6') {
        action = () => {
          this.props.downloadPublicDebateReportForm(id, nameOfTopic);
        };
      } else if (reportType === '7') {
        action = () => {
          this.props.downloadConsultativeProcessReportForm(id, nameOfTopic);
        };
      }
      confirmDialog(this.props.dictionary["modalConfirm.Preuzimanje"]+selectedOptionText.toLowerCase(), action, '');
    };
  };


  endProcess = () => {
    const action = () => {
    trackPromise(this.props.finishTooEarly(this.props.topicOfDiscussion.id)).then(()=> {
          NotificationManager.success(this.props.dictionary["TopicOfDiscussionPageModeratorAndEvaluatorTopControls.UspesanZavrsetakProcesa"])
        }
        )
        .catch(() => {
          NotificationManager.error(this.props.dictionary["TopicOfDiscussionPageModeratorAndEvaluatorTopControls.NeuspešanZavršetakProcesa"])
        })
      };
      confirmDialog(
        this.props.dictionary["ProcessPage.AskForApproval1"],
        action,
        `${this.props.dictionary["ProcessPage.FinishTheProcess"]}`
      );
    };
  hideProcess = () => {
    const action = () => {
      trackPromise(this.props.hideProcessAndNotifyModerator(this.props.topicOfDiscussion.id)).then(()=> {
        NotificationManager.success(this.props.dictionary["TopicOfDiscussionPageModeratorAndEvaluatorTopControls.UspesnoSakrivanjeProcesa"])
      }
      )
      .catch(() => {
        NotificationManager.error(this.props.dictionary["TopicOfDiscussionPageModeratorAndEvaluatorTopControls.NeuspešnoSakrivanjeProcesa"])
      })
    };
    confirmDialog(
      this.props.dictionary["ProcessPage.AskForApprovalHideProcess"],
      action,
      `${this.props.dictionary["ProcessPage.HideTheProcess"]}`
    );
    };
    onSubscribeClick = (topicOfDiscussionId) => {
      const newTopicOfDiscussionParticipant = {
        topicOfDiscussion: {
          id: topicOfDiscussionId,
        },
      };
      this.props
        .createTopicOfDiscussionParticipant(newTopicOfDiscussionParticipant)
        .then(()=> {
          this.setState({
            userIsSubscribed:true
          });
        });
    };

    onUnsubscribeClick = (topicOfDiscussionId) => {
      const action = () => {
        this.props
          .unsubscribeFromTopicOfDiscussion(topicOfDiscussionId)
          .then(()=>{
            this.setState({
              userIsSubscribed:false
            });
          });
      };
      confirmDialog(this.props.dictionary["modalConfirm.OdjavaSaPredloga"], action, '');
    };




  render() {

    const topicOfDiscussionModerators = this.props.topicOfDiscussion
      .topicOfDiscussionModerators;
    const processCreatorId = this.props.topicOfDiscussion.user
      ? this.props.topicOfDiscussion.user.id
      : null;
    const isAdmin = authorizationService.isAdmin(this.props.authRole);
    const isModerator = authorizationService.canAccessProcessEditMode(
      this.props.authRole,
      this.props.authUserId,
      processCreatorId,
      topicOfDiscussionModerators
    );
    const isModeratorFromInstitution = authorizationService.canTakeOverTopicOfDiscussion(this.props.authRole, this.props.topicOfDiscussion.institution.id, this.props.institutionId);
    const isCitizen = authorizationService.isCitizen(this.props.authRole);
    const isModeratorForTakingOverTopicOfDiscussion = authorizationService.canTakeOverTopicOfDiscussion(this.props.authRole);
    const canHideTopic = authorizationService.canHideTopicOfDiscussion(this.props.authRole);
    const canAdminEditTopic = authorizationService.canAdminEditTopic(this.props.authRole,(this.props.rsSuperAdmin||this.props.jlsSuperAdmin), this.props.topicOfDiscussion, this.props.phase);
    const isEvaluator = authorizationService.canAccessSuggestionButton(
      this.props.authRole
    );
    let isFinished = this.props.topicOfDiscussion
    ? this.props.topicOfDiscussion.endProcess == true
      ? true
      : false
    : false;
    let infoIzvestaji = (
      <p className="regular-font" style={{ maxWidth: '26vw' }}>
        {this.props.dictionary["tooltip.universal.infoIzvestaji"]}
      </p>
    );
    let topicInformation = (
      <>
              {isModerator && (
                <div className="d-flex justify-content-between mb-2 ">
                  <div className="">
                    {!isFinished && (
                      <Link
                        onClick={(e) => {
                          
                          this.showModalForUpdateTopicOfDiscussion(e);
                        }}
                        className="default-label-text-link d-flex align-items-center"
                        to="#"
                      >
                        <EditRoundedIcon fontSize={'small'} />
                        <strong className="ps-1 top-control-text">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzmenaAkta"]}</strong>
                      </Link>
                    )}
                  </div>
                  {(<div className="">
                    <Link
                      onClick={(e) => {
                        this.showModalForAddModerator(e);
                      }}
                      className="default-label-text-link d-flex align-items-center"
                      to="#"
                    >
                      <SupervisorAccountRoundedIcon />
                      <strong className="ps-1 top-control-text">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.Moderatori"]}</strong>
                    </Link>
                  </div>)}
                  {isModerator && !isFinished &&
                    <>
                    <div className="">
                    <Link
                      onClick={(e) => {
                        this.showModalForAddTopicOfDiscussionRelation(e);
                        
                      }}
                      className="default-label-text-link d-flex align-items-center"
                      to="#"
                    >
                      <ShareIcon />
                      <strong className="ps-1 top-control-text">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.PovezaniProcesi"]}</strong>
                    </Link>
                  </div>
                  <div className="">
                  <Link
                    onClick={(e) => {
                      e.preventDefault(e);
                      this.endProcess();
                    }}
                    className="default-label-text-link d-flex align-items-center"
                    to="#"
                  >
                    <CheckCircleIcon />
                    <strong className="ps-1 top-control-text">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.ZavrsiProces"]}</strong>
                  </Link>
                </div>
                </>
                  }
                  <div>

                    
                  {(isModerator || isEvaluator) ? (
                            <div>
                              <div className="">
                              <Link
                                className="default-label-text-link d-flex align-items-center"
                                to={`/commentListModerator/${this.props.topicOfDiscussion.id}`}
                              >
                                <CommentIcon />
                                <strong className='ps-1 top-control-text'>{this.props.dictionary["TopicOfDiscussionPageInformationsTable.Komentari"]}</strong>
                              </Link>
                              </div>
                            </div>
                          ) : (
                            <span
                              /* to={`/commentPage/${topicOfDiscussionId}`} */
                              to="#"
                              className="tableFooterLinks"
                              style={{ display: 'none' }}
                            >
                              <strong className='top-control-text'>{this.props.dictionary["TopicOfDiscussionPageInformationsTable.Komentari"]}</strong>
                            </span>
                          )}
                  </div>
                  <div>
                  {(isModerator || isEvaluator) &&  (this.props.consultativeProcess || this.props.publicDebate) &&(
                        <div>
                          <a data-tooltip-id="eventCreationTip2">
                            <label className="tableFooterLabels">
                            <i className="fas fa-file pe-1"></i>
                              <select
                                id="selectReport"
                                className="select-field-for-reports"
                                onChange={this.handleComboBoxChange(
                                  'report',
                                  'id',
                                  this.props.topicOfDiscussion.id,
                                  this.props.topicOfDiscussion.nameOfTopic
                                )}
                                value="0"
                              >
                                <option key="0" value="0" disabled hidden>
                                {this.props.dictionary["TopicOfDiscussionPageInformationsTable.Izvestaji"]}
                                </option>
                                {(this.props.consultativeProcess || this.props.publicDebate) &&<optgroup label={this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajiZaProces"]}>
                                 {this.props.consultativeProcess && <option key="1" value="1">
                                  {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajKP"]}
                                  </option>}

                                  {this.props.publicDebate && <option key="2" value="2">
                                  {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajJR"]}
                                  </option>}
                                  {(this.props.consultativeProcess || this.props.publicDebate) && <option key="3" value="3">
                                  {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajK"]}
                                  </option>}
                                </optgroup>}
                                {(this.props.consultativeProcess || this.props.publicDebate) && <optgroup label={this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajObrazac"]}>
                                  {this.props.consultativeProcess && <option key="6" value="7">
                                    {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajObrazacKP"]}
                                  </option>}
                                  {this.props.publicDebate && <option key="7" value="6">
                                  {this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzvestajObrazacJR"]}
                                  </option>}
                                </optgroup>}
                              </select>
                            </label>
                          </a>
                          <Tooltip
                            id="eventCreationTip2"
                            type="info"
                            effect="solid"
                            place="right"
                          >
                            {infoIzvestaji}
                          </Tooltip>
                        </div>
                      )}
                  </div>
                </div>
              )}
              {!isModerator && isModeratorFromInstitution && (
                <div className="d-flex justify-content-start mb-2 ">
                  <Link
                    onClick={(e) => {
                      this.showModalForAddModerator(e);
                    }}
                    className="default-label-text-link d-flex align-items-center"
                    to="#"
                  >
                    <SupervisorAccountRoundedIcon />
                    <strong className="ps-1 top-control-text">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.Moderatori"]}</strong>
                  </Link>
                </div>
              )}
              {isAdmin && (
                <div className="d-flex justify-content-start mb-2 ">
                  {canAdminEditTopic && (
                <div className="me-3">
                  <Link
                   onClick={(e) => {       
                          this.showModalForUpdateTopicOfDiscussion(e);
                        }}
                        className="default-label-text-link d-flex align-items-center"
                        to="#"
                  >
                  <EditRoundedIcon fontSize={'small'} />
                  <strong className="ps-1 top-control-text">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.IzmenaAkta"]}</strong>
                  </Link>
              </div>
              )}
              {/* {canHideTopic && (<div className="me-3">
                    {this.props.topicOfDiscussion.hidden == true ?
                    (  <div className="default-label-text d-flex align-items-center">
                        <VisibilityOffIcon /><strong className="ps-1 top-control-text">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.SkrivenProces"]}</strong>
                      </div>) 
                    : 
                    (<Link
                      onClick={(e) => {
                        e.preventDefault(e);
                        this.showModalForHidingProcess(e);
                        //this.hideProcess();
                      }}
                      className="default-label-text-link d-flex align-items-center"
                      to="#"
                    >
                      <VisibilityOffIcon />
                      <strong className="ps-1 top-control-text">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.SakrijProces"]}</strong>
                    </Link>)}
                  </div>)} */}
                </div>
              )}
              {isCitizen && (
                <div className="d-flex justify-content-start mb-2 ">
                <div className="me-3">
                    {this.state.userIsSubscribed==true ?
                   (<Link
                    onClick={(e) => {
                      e.preventDefault(e);
                      this.onUnsubscribeClick(this.props.topicOfDiscussion.id);
                    }}
                    className="default-label-text-link d-flex align-items-center"
                    to="#"
                  >
                    <BookmarkRemoveRoundedIcon />
                    <strong className="ps-1 top-control-text">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.OdjaviSe"]}</strong>
                  </Link>)
                    :
                    (<Link
                      onClick={(e) => {
                        e.preventDefault(e);
                        this.onSubscribeClick(this.props.topicOfDiscussion.id);
                      }}
                      className="default-label-text-link d-flex align-items-center"
                      to="#"
                    >
                      <BookmarkAddRoundedIcon />
                      <strong className="ps-1 top-control-text">{this.props.dictionary["TopicOfDiscussionPageInformationsTable.PrijaviSe"]}</strong>
                    </Link>)}
                  </div>
                </div>
              )}

              </>

    );

    return (
      <Fragment>
        {this.props.topicOfDiscussion.user==null && (isModeratorFromInstitution || isModerator) && <TopicOfDiscussionTakeOver topicOfDiscussionId={this.props.topicOfDiscussion.id} authUserId={this.props.authUserId}/>}
        {topicInformation}
        {this.state.showTopicOfDiscussion == true ? (
          <ModalForUpdateTopicOfDiscussion
            show={this.state.showTopicOfDiscussion}
            handleClose={this.handlePreviewClose}
            topicOfDiscussionId={this.props.topicOfDiscussion.id}
            topicOfDiscussion={this.props.topicOfDiscussion}
            getToD={this.props.getTopicOfDiscussion}
            phase={this.props.phase}
          />
        ) : (
          ''
        )}
        {this.state.showModalForAddModerator == true ? (
          <ModalForAddModerator
            show={this.state.showModalForAddModerator}
            handleClose={this.handlePreviewClose}
            topicOfDiscussion={this.props.topicOfDiscussion}
            isProcessFinished={this.props.isProcessFinished}
            isModerator={isModerator}
            isModeratorFromInstitution={isModeratorFromInstitution}
          />
        ) : (
          ''
        )}
        {this.state.showModalForAddTopicOfDiscussionRelation == true && 
          <ModalForAddTopicOfDiscussionRelation
            show={this.state.showModalForAddTopicOfDiscussionRelation}
            handleClose={this.handlePreviewClose}
            topicOfDiscussion={this.props.topicOfDiscussion}
            relatedTopicOfDiscussionList={this.props.relatedTopicOfDiscussionList}
          />
        }
        {this.state.showModalForHidingProcess == true && 
          <ModalForHidingProcess
            show={this.state.showModalForHidingProcess}
            handleClose={this.handlePreviewClose}
            topicOfDiscussionId={this.props.topicOfDiscussion.id}
          />
        }
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  jlsSuperAdmin: state.security.jlsSuperAdmin,
  rsSuperAdmin: state.security.rsSuperAdmin,
  authUserId: state.security.userId,
  dictionary:state.startUp.dictionary,
  consultativeProcess: state.phase.consultativeProcess,
  publicDebate: state.phase.publicDebate,
  phase:state.phase,
  institutionId:state.security.institutionId,
});

export default connect(mapStateToProps,{  downloadTopicOfDiscussion,
  downloadCommentList,
  downloadPublicDebateReportForm,
  downloadConsultativeProcessReportForm,
  finishTooEarly,
  hideProcessAndNotifyModerator,
  createTopicOfDiscussionParticipant,
  unsubscribeFromTopicOfDiscussion
  }) (TopicOfDiscussionPageModeratorAndEvaluatorTopControls);
