import React from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import TextArea from "../Reusable/TextArea";
import Input from "../Reusable/Input";
import { setMessage } from "../../utils";
import { createEvaluatorMessage } from "../../actions/evaluatorMessageActions";

class ModalForAddEvaluatorMessage extends React.Component {
  constructor() {
    super();
    this.state = {
      messageTitle: "",
      messageText: "",
      errors: {},
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleValidation = () => {
    let error = false;

    if (this.state.messageTitle == "") {
      this.props.setMessage(
        `${this.props.dictionary["ModalForAddEvaluatorMessage.Validation.Naslov"]}`,
        "#messageTitle",
        "#messageTitleМsg"
      );
      error = true;
    }

    if (this.state.messageText == "") {
      this.props.setMessage(
        `${this.props.dictionary["ModalForAddEvaluatorMessage.Validation.Poruka"]}`,
        "#messageText",
        "#messageTextМsg"
      );
      error = true;
    }

    if (this.state.messageText != "" && this.state.messageText.length > 1000) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForAddEvaluatorMessage.Validation.PorukaDuzina"]}`,
        "#messageText",
        "#messageTextМsg"
      );
      error = true;
    }
    return error;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newEvaluatorMessage = {
      messageText: this.state.messageText,
      messageTitle: this.state.messageTitle,
      topicOfDiscussion: {
        id: this.props.topicOfDiscussionId,
      },
    };

    this.props
      .createEvaluatorMessage(newEvaluatorMessage)
      .then(this.handleClose)
      .then(this.props.handleShowMesasage);
  };

  render() {
    const { errors } = this.state;

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <div className="form-group">
              <label className="labelText textShadow">
                {this.props.dictionary["ModalForAddEvaluatorMessage.NaslovPoruke"]}: <span className="redAsterisk"> *</span>
              </label>
              <Input
                type="text"
                id="messageTitle"
                placeholder={this.props.dictionary["ModalForAddEvaluatorMessage.Placeholder.NaslovPoruke"]}
                name="messageTitle"
                value={this.state.messageTitle}
                onChange={this.onChange}
                errors={errors.messageTitle}
              />
              <div id="messageTitleМsg" />
            </div>
            <div className="form-group">
              <label className="labelText textShadow">
              {this.props.dictionary["ModalForAddEvaluatorMessage.Poruka"]}: <span className="redAsterisk"> *</span>
              </label>
              <TextArea
                type="text"
                id="messageText"
                placeholder={this.props.dictionary["ModalForAddEvaluatorMessage.Placeholder.Poruka"]}
                name="messageText"
                value={this.state.messageText}
                onChange={this.onChange}
                errors={errors.messageText}
                maxLength="1000"
                rows={6}
              />
              <div id="messageTextМsg" />
            </div>
            <div className="form-row justify-content-end">
              <button
                type="button"
                id="subscribeButton"
                className="col-md-3 m-auto"
                onClick={this.handleSubmit}
              >
                {this.props.dictionary["ModalForAddEvaluatorMessage.PosaljiPoruku"]}
              </button>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  setMessage,
  createEvaluatorMessage,
})(ModalForAddEvaluatorMessage);
