import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteOgpFieldOfInterest } from '../../actions/ogpFieldOfInterestActions';
import DeleteButton from '../Reusable/DeleteButton';
import UpdateButton from '../Reusable/UpdateButton';
import authorizationService from '../../securityUtils/authorizationService';
import { Tr, Td } from 'react-super-responsive-table';
import { confirmDialog } from '../Reusable/ConfirmDialog';

class OgpFieldOfInterestRow extends Component {
  onDeleteClick = (id) => {
    const action = () => {
      this.props.deleteOgpFieldOfInterest(id);
    };
    confirmDialog(`${this.props.dictionary["OgpFieldOfInterestRow.Obrisi.Poruka"]}`, action, `${this.props.dictionary["OgpFieldOfInterestRow.Obrisi"]}`);
  };

  render() {

    return (
      <Tr>
        <Td className="textShadow">{this.props.ogpFieldOfInterest.name}</Td>

        {authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) ? (
          <Td width="10%" className="text-center">
            <UpdateButton
              route={`/updateogpFieldOfInterest/${this.props.ogpFieldOfInterest.id}`}
            />
          </Td>
        ) : (
          ''
        )}
        {authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) ? (
          <Td width="10%" className="text-center">
            <DeleteButton
              click={() => this.onDeleteClick(this.props.ogpFieldOfInterest.id)}
            />
          </Td>
        ) : (
          ''
        )}
      </Tr>
    );
  }
}

OgpFieldOfInterestRow.propTypes = {
  deleteOgpFieldOfInterest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps, {
  deleteOgpFieldOfInterest,
})(OgpFieldOfInterestRow);
