import React, { Component } from "react";
import { connect } from "react-redux";
import { getAllAd } from "../../actions/adActions";
import AdTable from "./AdTable";

class AdList extends Component {
  componentDidMount() {
    this.props.getAllAd();
  }

  render() {
    const { adList } = this.props.ad;

    return (
      <div className="container-fluid">
        {/* <GoBackButton route={`/ogpPage/${1}`} /> */}
        <br></br>
        <div className="row col-md-11 m-auto">
          <div className="col-md-12 text-left mb-2">
            <h2 className="textShadow text-center">{this.props.dictionary["AdList.ArhivaSvihObjava"]}</h2>
            <AdTable adList={adList}  />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ad: state.ad,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, { getAllAd })(AdList);
