import React from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import TextArea from "../Reusable/TextArea";
import { setMessage } from "../../utils";
import UploadCommentDocument from "../CommentDocument/UploadCommentDocument";
import ComboReusable from "../Reusable/ComboReusable";

class ModalForAddComment extends React.Component {
  constructor() {
    super();
    this.state = {
      commentText: "",
      commentLength: 3000,
      disabled: false,
      errors: {},
      fileUploaded: false,
      userOrganization: {
        id: "",
      },
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  onDocumentUpload = () => {
    this.setState({ fileUploaded: true });
  };

  handleComboBoxChange = (object, objectId) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
      });
    };
  };
  handleDocumentUploaded = () => {
    this.handleClose();
    this.props.handleShowMesasage();
  }
  handleValidation = () => {
    let error = false;

    if (this.state.commentText == "") {
      let msg = this.props.dictionary["modalForAddComment.ValidationMessage"]
      this.props.setMessage(
        msg,
        "#commentText",
        "#commentTextМsg"
      );
      error = true;
    }
    return error;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newCommentText = {
      position: "",
      content: "",
      comment: {
        text: this.state.commentText,
      },
    };

    if (this.props.ogpPhase) {
      let newOgpComment = {
        commentText: JSON.stringify(newCommentText),
        visibility: 'НЕ',
        commentStatus: this.props.commentStatus.find((status) => status.code == "Primljen"),
        commentCategory: this.props.commentCategory.find((category) => category.code == "OpstiKomentar"),
        phaseNumber: this.props.ogpPhase,
        uuidDocumentName: this.props.documentName,
      };

      if (
        this.state.userOrganization &&
        this.state.userOrganization.id &&
        this.state.userOrganization.id != 0
      ) {
        newOgpComment = {
          commentText: JSON.stringify(newCommentText),
          visibility: 'НЕ',
          phaseNumber: this.props.ogpPhase,
          commentStatus: this.props.commentStatus.find((status) => status.code == "Primljen"),
          commentCategory: this.props.commentCategory.find((category) => category.code == "OpstiKomentar"),
          userOrganization: {
            id: this.state.userOrganization.id,
          },
          uuidDocumentName: this.props.documentName,
        };
      }

      this.props
        .createOgpComment(newOgpComment)
        .then(this.handleClose)
        .then(this.props.handleShowMesasage);
    } else {
      let newComment = {
        commentText: JSON.stringify(newCommentText),
        visibility: 'НЕ',
        topicOfDiscussion: {
          id: this.props.topicOfDiscussionId,
        },
        method: { id: this.props.methodId},
        commentStatus: this.props.commentStatus.find((status) => status.code == "Primljen"),
        commentCategory: this.props.commentCategory.find((category) => category.code == "OpstiKomentar"),
        // userOrganization: {
        //   id: this.state.userOrganization.id,
        // },
        uuidDocumentName: this.props.documentName,
      };

      if (
        this.state.userOrganization &&
        this.state.userOrganization.id &&
        this.state.userOrganization.id != 0
      ) {
        newComment = {
          commentText: JSON.stringify(newCommentText),
          visibility: 'НЕ',
          topicOfDiscussion: {
            id: this.props.topicOfDiscussionId,
          },
          method: { id: this.props.methodId},
          commentStatus: this.props.commentStatus.find((status) => status.code == "Primljen"),
          commentCategory: this.props.commentCategory.find((category) => category.code == "OpstiKomentar"),
          userOrganization: {
            id: this.state.userOrganization.id,
          },
          uuidDocumentName: this.props.documentName,
        };
      }

      this.props
        .createComment(newComment)
        .then(this.onDocumentUpload)
    }
  };

  render() {
    const { errors } = this.state;

    const userOrganizationList = this.props.userOrganizationList;

    return (
              <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="addCommentModal"
      >
        <Modal.Header closeButton>
          <Modal.Body>
            {userOrganizationList && userOrganizationList.length != 0 ? (
              <div className="from-group">
                <label className="labelText textShadow">
                {this.props.dictionary["modalForAddComment.Organizacija"]}:
                </label>
                <ComboReusable
                  id="userOrganizationCb"
                  namePart={this.props.dictionary["modalForAddComment.OrganizacijaKomentar"]}
                  items={userOrganizationList.map((userOrganization) => (
                    <option
                      key={userOrganization.id}
                      value={userOrganization.id}
                    >
                      {userOrganization.organizationName}
                    </option>
                  ))}
                  onChange={this.handleComboBoxChange("userOrganization", "id")}
                  allItemsNaming={"Ништа од наведеног"}
                />
              </div>
            ) : (
              ""
            )}
            <div className="form-group">
              <label className="alert alert-danger">{this.props.dictionary["modalForAddComment.Upozorenje"]}</label>
              <label className="labelText textShadow">
                {this.props.dictionary["modalForAddComment.Komentar"]}: <span className="redAsterisk"> *</span>
              </label>
              <TextArea
                type="text"
                id="commentText"
                placeholder={this.props.dictionary["modalForAddComment.KomentarPlaceholder"]}
                name="commentText"
                value={this.state.commentText}
                onChange={this.onChange}
                errors={errors.commentText}
                maxLength={this.state.commentLength}
                rows={8}
              />
              <div id="commentTextМsg" />
              <span>{this.state.commentText.length}/{this.state.commentLength}</span>
            </div>
            {this.props.ogpPhase ? (
              ""
            ) : (
              <div className="form-group">
                <label className="labelText textShadow">
                {this.props.dictionary["modalForAddComment.PrilozeniDokument"]}:
                </label>
                <UploadCommentDocument
                  fileUploaded={this.state.fileUploaded}
                  onDocumentUploaded={this.handleDocumentUploaded} />
              </div>
            )}
            <div className="form-row">
              <button
                type="button"
                id="subscribeButton"
                className="col-md-3 m-auto"
                onClick={this.handleSubmit}
              >
                {this.props.dictionary["modalForAddComment.DodajKomentar"]}
              </button>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.errors,
  dictionary: state.startUp.dictionary,
  method: state.method.method,
  commentStatus: state.startUp.taxonomies.CommentStatus,
  commentCategory: state.startUp.taxonomies.CommentCategory
});

export default connect(mapStateToProps, {
  setMessage,
})(ModalForAddComment);
