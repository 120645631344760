import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/securityActions';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';

class Footer extends Component {
  constructor() {
    super();
    this.state = {
      termsOfUse: false,
    };
  }
  logout() {
    this.props.logout();
    window.location.href = '/';
  }

  showTermsOfUseModal = () => {
    this.setState({ termsOfUse: true });
  };

  handlePreviewClose = () => {
    this.setState({
      termsOfUse: false,
    });
  };

  render() {
    const footerContent = (
      <div className="mt-5">
        <div className="foot-top">
          <div className="container">
            <a href="#">{this.props.dictionary["footer.VrhStrane"]}</a>
          </div>
        </div>
        <footer>
          <div className="container">
            <div className="foot-content">
              <div className="site-title">
                <h3>{this.props.dictionary["footer.eKonsultacije.gov.rs"]}</h3>
                <p>{this.props.dictionary["footer.PortaleKonsultacije"]}</p>
              </div>
              <div className="copy">
              {this.props.dictionary["footer.Licenca"]}
                <a href="https://www.ite.gov.rs/" target="_blank">
                  ite.gov.rs
                </a>
              </div>
              <ul className="nav">
                <li className="emph">
                  <Link onClick={this.showTermsOfUseModal} to="#">
                  {this.props.dictionary["footer.UsloviKoriscenja"]}
                  </Link>
                </li>
              </ul>
            </div>
            <ul className="foot-social">
              <li className="fb">
                <Link
                  href="https://www.facebook.com/KancelarijaITE/"
                  onClick={(event) => {
                    event.preventDefault();
                    window.open('https://www.facebook.com/KancelarijaITE/');
                  }}
                  target="_blank"
                  to="#"
                >
                  Facebook
                </Link>
              </li>
              <li className="tw">
                <Link
                  href="https://twitter.com/kancelarijaITE"
                  onClick={(event) => {
                    event.preventDefault();
                    window.open('https://twitter.com/kancelarijaITE');
                  }}
                  target="_blank"
                  to="#"
                >
                  Twitter
                </Link>
              </li>
              <li className="py">
                <Link
                  href="https://www.youtube.com/user/euprava"
                  onClick={(event) => {
                    event.preventDefault();
                    window.open('https://www.youtube.com/user/euprava');
                  }}
                  target="_blank"
                  to="#"
                >
                  Play
                </Link>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    );
    return (
      <Fragment>
        {footerContent}
        {this.state.termsOfUse && (
          <ModalForDocumentPreview
            show={this.state.termsOfUse}
            documentName={'PolitikaPrivatnosti.docx'}
            controllerReference={'document'}
            handleClose={this.handlePreviewClose}
          />
        )}
      </Fragment>
    );
  }
}

Footer.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  error: state.errors,
});

export default connect(mapStateToProps, { logout })(Footer);
