import React, { Component } from 'react';
import { connect } from 'react-redux';
import { downloadUsers, getUser } from '../../actions/userActions';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../Reusable/LoadingIndicator';
import { trackPromise } from 'react-promise-tracker';
import UserTableServerFiltering from './UserTableServerFiltering';
import { NotificationManager } from 'react-notifications';
import ModalForAddUser from './ModalForAddUser';
import ModalForUpdateUser from './ModalForUpdateUser';

class UserList extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      showModalForAddUser: false,
      showModalForUpdateUser: false,
      refreshData: 0
    };
  }

  handleCloseModalForAddUser = (refresh) => {
    if(refresh != null && refresh == true) {
      this.setState({
        refreshData: Math.random()
      })
    }
    this.setState({
        showModalForAddUser: false,
      });
  };
  handleShowModalForAddUser = () => {
    this.setState({
      showModalForAddUser: true,
    });
  };
  handleCloseModalForUpdateUser = (refresh) => {
    if(refresh != null && refresh == true) {
      this.setState({
        refreshData: Math.random()
      })
    }
    this.setState({
        showModalForUpdateUser: false,
      });
  };
  handleShowModalForUpdateUser = (id) => {
    this.props.getUser(id);
    this.setState({
      showModalForUpdateUser: true,
    });
  };

  downloadFile = (e, fileName) => {  
    trackPromise(this.props.downloadUsers(fileName)).then(() => {
        NotificationManager.success(this.props.dictionary["userList.PreuzmiKorisnikeUspesno"], this.props.dictionary["universal.Uspesno"], 5000);
    }).catch(() => {
        NotificationManager.error(this.props.dictionary["userList.PreuzmiKorisnikeNeuspesno"], this.props.dictionary["universal.GreskaUObradi"], 5000);
    });
  };



  render() {
    const { isLoading } = this.state;

    return isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="container-fluid component-wrapper-fade-in">
        <br></br>
        <div className="row">
          <div className="col-md-11 m-auto">
            <div className="text-left mb-2">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="textShadow">{this.props.dictionary["userList.Korisnici"]}</h2>
                <div>
                <button className="create-button create-button-group" 
                        onClick={(e)=>this.downloadFile(e, this.props.dictionary["userList.NazivFajlaSaEksportovanimUserima"])}
                >
                  {this.props.dictionary["userList.PreuzmiKorisnike"]}
                </button>
                <button 
                      className="create-button"
                      onClick={(e) => {e.preventDefault(); this.handleShowModalForAddUser();}}
                    >
                      {this.props.dictionary["userList.DodajKorisnika"]}
                </button>
                {/* <Link to={`/addUser`} className="col-md-3">
                  <button className="create-button create-button-group">
                  {this.props.dictionary["userList.DodajKorisnika"]}
                  </button>
                </Link> */}
                </div>
              </div>
              <br></br>
              <UserTableServerFiltering
                refresh={this.state.refreshData}
                showModalForUpdateFunction = {this.handleShowModalForUpdateUser.bind(this)}
              />
              <div id="msg" />
              { this.state.showModalForAddUser &&
                <ModalForAddUser
                  show={this.state.showModalForAddUser}
                  handleClose={this.handleCloseModalForAddUser}
                />
              }
              { this.state.showModalForUpdateUser &&
                <ModalForUpdateUser
                  show={this.state.showModalForUpdateUser}
                  handleClose={this.handleCloseModalForUpdateUser}
                  user={this.props.user}
                />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserList.propTypes = {
  user: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary,
  user: state.user.user,
});

export default connect(mapStateToProps, { downloadUsers, getUser })(UserList);
