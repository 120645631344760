import CommentDisplayPagination from "./ComentDisplayPagination";
import CommentDisplayCardList from "./CommentDisplayCardList";
import CommentDisplayHeader from "./CommentDisplayHeader";
import { getCommentListForDocument } from "../../actions/commentActions";
import { useState } from "react";
import { useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { useDispatch } from "react-redux";

const CommentDisplay = ({uuidDocumentName, methodId}) => {

    const dispatch = useDispatch();

    const [refresh, setRefresh] = useState(0);
    
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
    });

    useEffect(() => {
        const searchParams = { 
          start: pagination.pageIndex,
          size: pagination.pageSize,
          filters: columnFilters,
          globalFilter: globalFilter ?? '',
          sorting: sorting ?? []
        };
        trackPromise(dispatch(getCommentListForDocument(uuidDocumentName, methodId, searchParams)))
        
    }, [
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      refresh
    ]);
    return (
        <div>
            <CommentDisplayHeader columnFilters={columnFilters} setColumnFilters={setColumnFilters} sorting={sorting} setSorting={setSorting}/>
            <CommentDisplayCardList setRefresh={setRefresh}/>
            <CommentDisplayPagination pagination={pagination} setPagination={setPagination}/>
        </div>
    );
}

export default CommentDisplay;