import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createTopicOfDiscussion } from '../../actions/topicOfDiscussionActions';
import TextArea from '../Reusable/TextArea';
import Select from 'react-select';
import { getAllActiveFiledsOfInterest } from '../../actions/fieldOfInterestActions';
import { getAllArchivedTopicsOfDiscussion } from '../../actions/topicOfDiscussionActions';
import { setMessage } from '../../utils';
import { Tooltip } from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../style/ConfirmDialog.css';
import { trackPromise } from 'react-promise-tracker';
import {
  NotificationManager,
} from 'react-notifications';

class ModalForTopicOfDiscussion extends React.Component {
  constructor(props) {
    super();
    this.state = {
      nameOfTopic: '',
      description: '',
      creationDate: '',
      selectedfieldsOfInterest: [],
      institution: {
        id: 0,
      },
      law: false,
      regulation: false,
      other: false,
      newProcess: true,
      topic: {
        topicOfDiscussionGroupId: null,
      },
      visibilityOfPrevieousProcess: '',
      newArchive: true,
      oldArchive: false,
      oldArchiveTopicLink: '',
      oldArchiveTopicTitle: '',
      oldArchiveTopicYear: '',
      errors: {},
      selectedTypeOfAct:null,
    };
    this.handleTypeOfActCheckChange = this.handleTypeOfActCheckChange.bind(
      this
    );
    this.handleFieldOfInterestMultiselectChange = this.handleFieldOfInterestMultiselectChange.bind(
      this
    );
    this.handleTextAreaExpand = this.handleTextAreaExpand.bind(this);
    this.handleTextAreaShrink = this.handleTextAreaShrink.bind(this);
  }

  componentDidMount() {
    if(this.props.institutionCurrentlyActive===false) {
      inactiveInstitutionMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Modal.NeaktivnaInstitucijaPoruka"]}`,
        `${this.props.dictionary["ModalForTopicOfDiscussion.Modal.NeaktivnaInstitucijаOdustani"]}`,
          this.props.handleClose
      );
    }
    else {
      this.props.getAllActiveFiledsOfInterest();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }


  handleComboBoxChange = (object, objectId) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
        fieldId: e.target.value,
        comboSignal: object,
      });
    };
  };

  handleClose = () => {
    this.props.handleClose();
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTypeOfActCheckChange = (item) => {
    this.setState({selectedTypeOfAct:item});
    if(item.taxonomyValueInformationList.some(element => 
      element.informationName=='actTypeTiedToFieldOfInterest'))
      this.setState({ selectedfieldsOfInterest:[]});

  };

  handleFieldOfInterestMultiselectChange(selectedOptions) {
    this.setState({ selectedfieldsOfInterest: selectedOptions || [] });
  }

  handleReroute = () => {
    const { topicOfDiscussionMaxId } = this.props.topicOfDiscussion;
    this.props.history.push(`/topicOfDiscussionPage/${topicOfDiscussionMaxId}/${1}`);
    //this.props.history.go();
  };

  handleTextAreaExpand = (e) => {
    e.target.rows = 8;
  };

  handleTextAreaShrink = (e) => {
    e.target.rows = 4;
  };

  handleValidation = () => {
    let error = false;

    if(this.state.selectedTypeOfAct==null) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.Cekiranje"]}`,
        '#typeOfAct',
        '#msg5'
      );
      error = true;
    }
    if (this.state.nameOfTopic == '') {
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.NazivTeme"]}`,
        '#nameOfTopic',
        '#msg1'
      );
      error = true;
    } else if (this.state.nameOfTopic.length>255){
        this.props.setMessage(
          `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.NazivTemeDuzina"]}`,
          '#nameOfTopic',
          '#msg1'
        );
        error = true;
    }

    if (this.state.description == '') {
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.Opis"]}`,
        '#description',
        '#msg2'
      );
      error = true;
    }

    if (this.state.description.length > 1000) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.OpisDuzina"]}`,
        '#description',
        '#msg2'
      );
      error = true;
    }

    if (this.state.selectedfieldsOfInterest.length == 0) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.Oblast"]}`,
        '#multi',
        '#msg3'
      );
      error = true;
    }
    if(this.props.institutionCurrentlyActive===false || this.props.institutionCurrentlyActive==null){
      this.props.setMessage(
        `${this.props.dictionary["ModalForTopicOfDiscussion.Validation.NeaktivnaInstitucija"]}`,
        '#institutionLabel',
        '#msg4'
      );
      error = true;
    }
    return error;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newTopic = {
      nameOfTopic: this.state.nameOfTopic,
      description: this.state.description,
      creationDate: this.state.creationDate,
      fieldOfInterests: this.state.selectedfieldsOfInterest,
      institution: {
        id: this.props.institutionId,
      },
      actType:this.state.selectedTypeOfAct,
      law: 0,
      regulation: 0,
      other: 0,
      newProcess: this.state.newProcess,
      topicOfDiscussionGroupId: this.state.topic
        ? this.state.topic.topicOfDiscussionGroupId
        : null,
      oldArchiveTopicLink: this.state.oldArchiveTopicLink,
      oldArchiveTopicTitle: this.state.oldArchiveTopicTitle,
      oldArchiveTopicYear: this.state.oldArchiveTopicYear,
      jls:this.props.jls
    };
    trackPromise(this.props
      .createTopicOfDiscussion(newTopic)
      .then( () => {
        NotificationManager.success(this.props.dictionary["ModalForTopicOfDiscussion.UspesnoKreiranje"],this.props.dictionary["universal.Uspesno"], 5000);
      })
      .then(() => this.props.handleClose())
      .then(() => this.handleReroute()))
      .catch( () => {
        NotificationManager.error(this.props.dictionary["ModalForTopicOfDiscussion.KreiranjeNeuspelo"], this.props.dictionary["universal.GreskaUObradi"], 5000);
      })
  };

  render() {
    const { errors } = this.state;
    const  activeFieldOfInterestList  = this.props.fieldOfInterest;
    const {selectedTypeOfAct} = this.state;

    const actTypes = this.props.taxonomies['ActType'].filter(item => 
      item.taxonomyValueInformationList.some(element => 
        element.informationName==(this.props.jls ? 'JLS':'RepublickiNivo')));

    let fieldOfInterestOptions=activeFieldOfInterestList.map((field) => ({
      ...field,
      value: field.id,
      label: field.name,
    }));
    if(this.state.selectedTypeOfAct!=null && this.state.selectedTypeOfAct.code != 'Ostalo') {
      fieldOfInterestOptions = fieldOfInterestOptions
      .filter((field) => field.actType.code==this.state.selectedTypeOfAct.code)
      .map((field) => ({
          ...field,
          value: field.id,
          label: field.name,
      }));
    } 
 

    const customStyleForMultiselect = {
      control: (styles) => ({ ...styles, minHeight: '45px' }),
      option: (styles) => ({
        ...styles,
        color: '#495057',
        fontSize: '17px',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
        marginLeft: '12px',
      }),
      multiValueLabel: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
      }),
      input: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '1.25rem',
        marginLeft: '12px',
      }),
    };

    const actTypeTooltipInfo = {
        PlanskiDokument:<p className="regular-font">{this.props.dictionary["tooltip.universal.infoAboutRegulation"]}</p>,
        Propis:<p className="regular-font">{this.props.dictionary["tooltip.universal.infoAboutLaw"]}</p>,
        Ostalo:<p className="regular-font">{this.props.dictionary["tooltip.universal.infoAboutOther"]}</p>
    };
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <h4 className="text-center">{this.props.dictionary["ModalForTopicOfDiscussion.UnosNovogAkta"]}</h4>
            <hr></hr>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="labelText textShadow">
                  {this.props.dictionary["ModalForTopicOfDiscussion.TipDokumenta"]}:{' '}
                    <span className="redAsterisk"> *</span>
                  </label>
                  <div className="form-group mb-4 mt-2">
                    {actTypes.map((item) => (
                      <>
                        <a data-tooltip-id={`currentStateTip_${item.code}`}>
                          <label className="col-md-7 labelText textShadow">
                            <span className="bullet-char">&bull;</span>
                              {item.value}:
                          </label>
                          <input
                            type="radio"
                            id={item.code}
                            name="law"
                            className="mt-2 filled-in"
                            checked={selectedTypeOfAct?.code===item.code}
                            onChange={()=> this.handleTypeOfActCheckChange(item)}
                          />
                        </a>
                          <Tooltip
                          id={`currentStateTip_${item.code}`}
                          type="info"
                          effect="solid"
                          place="right"
                          >
                            {actTypeTooltipInfo[item.code]}
                          </Tooltip>
                      </>
                    ))}
                  <div id="msg5" />
                  </div>    
                  <label className="labelText textShadow">
                  {this.props.dictionary["ModalForTopicOfDiscussion.Naziv"]}: <span className="redAsterisk"> *</span>
                  </label>
                  <TextArea
                    type="text"
                    id="nameOfTopic"
                    placeholder={this.props.dictionary["ModalForTopicOfDiscussion.Placeholder.NazivAkta"]}
                    name="nameOfTopic"
                    value={this.state.nameOfTopic}
                    onChange={this.onChange}
                    errors={errors.nameOfTopic}
                    maxLength="255"
                  />
                   <p className="small-font">
                    <span className="redAsterisk"> *</span>
                    <em>
                    {this.props.dictionary["ModalForTopicOfDiscussion.Info.PrviDeo"]} <strong>{this.props.dictionary["ModalForTopicOfDiscussion.Info.BrojKarakterNazivAkta"]}</strong> {this.props.dictionary["ModalForTopicOfDiscussion.Info.ZadnjiDeo"]}{' '}
                    </em>
                  </p>
                  <div id="msg1" />
                </div>
                <div className="form-group mb-4 mt-2">
                  <label className="labelText textShadow">
                  {this.props.dictionary["ModalForTopicOfDiscussion.Opis"]}:
                    <span className="redAsterisk"> *</span>
                  </label>
                  <TextArea
                    type="text"
                    id="description"
                    placeholder={this.props.dictionary["ModalForTopicOfDiscussion.Placeholder.Opis"]}
                    name="description"
                    value={this.state.description}
                    onChange={this.onChange}
                    errors={errors.description}
                    maxLength="1000"
                    rows={4}
                    onFocus={this.handleTextAreaExpand}
                    onBlur={this.handleTextAreaShrink}
                  />
                  <p className="small-font">
                    <span className="redAsterisk"> *</span>
                    <em>
                    {this.props.dictionary["ModalForTopicOfDiscussion.Info.PrviDeo"]} <strong>{this.props.dictionary["ModalForTopicOfDiscussion.Info.Broj"]}</strong> {this.props.dictionary["ModalForTopicOfDiscussion.Info.ZadnjiDeo"]}{' '}
                    </em>
                  </p>
                  <div id="msg2" />
                </div>
                <div className="form-group mb-4 mt-2">
                  <label className="labelText textShadow">
                  {this.props.dictionary["ModalForTopicOfDiscussion.Oblast"]}: <span className="redAsterisk"> *</span>
                  </label>
                  <Select
                    isMulti
                    name="fieldOfInterestList1"
                    options={fieldOfInterestOptions}
                    value={this.state.selectedfieldsOfInterest}
                    onChange={this.handleFieldOfInterestMultiselectChange}
                    placeholder= {this.props.dictionary["ModalForTopicOfDiscussion.Placeholder.Oblast"]}
                    id="multi"
                    styles={customStyleForMultiselect}
                  />
                  <div id="msg3" />
                </div>
                <div className="from-group mb-4 mt-2">
                  <label className="labelText textShadow">
                  {this.props.dictionary["ModalForTopicOfDiscussion.Institucija"]}: 
                  </label>
                  <label className="d-block" id="institutionLabel">
                  {this.props.institutionName}
                  </label>
                  <div id="msg4" />
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <button
                type="button"
                id="subscribeButton"
                className="col-md-6 m-auto"
                onClick={this.handleSubmit}
              >
                {this.props.dictionary["ModalForTopicOfDiscussion.Sacuvaj"]}
              </button>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

ModalForTopicOfDiscussion.propTypes = {
  createTopicOfDiscussion: PropTypes.func.isRequired,
  fieldOfInterest: PropTypes.object.isRequired,
  getAllActiveFiledsOfInterest: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  getAllArchivedTopicsOfDiscussion: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  fieldOfInterest: state.fieldOfInterest.activeFieldOfInterestList,
  topicOfDiscussion: state.topicOfDiscussion,
  errors: state.errors,
  topicOfDiscussionGroup: state.topicOfDiscussionGroup,
  dictionary: state.startUp.dictionary,
  institutionName:state.security.institutionName,
  institutionId:state.security.institutionId,
  institutionCurrentlyActive:state.security.institutionCurrentlyActive,
  jls:state.security.jls,
  taxonomies:state.startUp.taxonomies,
});

export default connect(mapStateToProps, {
  createTopicOfDiscussion,
  getAllActiveFiledsOfInterest,
  setMessage,
  getAllArchivedTopicsOfDiscussion,
})(ModalForTopicOfDiscussion);

const inactiveInstitutionMessage = (message, buttonText, action) => {
  confirmAlert({
    customUI: ({onClose}) => {
      return (
        <div id="react-confirm-alert">
          <div className="react-confirm-alert">
            <div className="custom-ui button">
              <p>{message}</p>
              <button onClick={() => {onClose(); action();}}>{buttonText}</button>
            </div>
          </div>
        </div>
      );
    },
    onClickOutside: () => {
      return action();
    },
  });
};
