import axios from 'axios';
import {
  GET_ERRORS,
  GET_OGP_ACTION_PLAN_ARCHIVE_LIST,
  GET_OGP_ACTION_PLAN_ARCHIVE,
  DELETE_OGP_ACTION_PLAN_ARCHIVE,
} from './types';

export const saveOgpActionPlanArchive = (data, history) => async (dispatch) => {
  await axios.post(`/api/ogpActionPlanArchiveFile/upload`, data);
  history.push('/ogpPage/3');
};

export const getOgpActionPlansArchivesById = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpActionPlanArchiveFile/findById/${id}`);
    dispatch({
      type: GET_OGP_ACTION_PLAN_ARCHIVE_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOgpActionPlansArchive = () => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpActionPlanArchiveFile/findAll`);
    dispatch({
      type: GET_OGP_ACTION_PLAN_ARCHIVE_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOgpActionPlanArchive =
  (actionPlanArchivetName) => async (dispatch) => {
    const res = await axios.get(
      `/api/ogpActionPlanArchiveFile/find/${actionPlanArchivetName}`
    );
    dispatch({
      type: GET_OGP_ACTION_PLAN_ARCHIVE,
      payload: res.data,
    });
  };

export const deleteOgpActionPlanArchive = (id) => async (dispatch) => {
  axios.delete(`/api/ogpActionPlanArchiveFile/delete/${id}`);
  dispatch({
    type: DELETE_OGP_ACTION_PLAN_ARCHIVE,
    payload: id,
  });
};

export const downloadOgpActionPlanArchive =
  (uuidDocName, documentName) => async (dispatch) => {
    axios({
      url: `/api/ogpActionPlanArchiveFile/download/${uuidDocName}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    });
  };
