import React from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextArea from '../Reusable/TextArea';
import {
  getOgpComment,
  updateOgpComment,
  getOgpCommentList,
} from '../../actions/ogpCommentActions';
import { setMessage } from '../../utils';
import moment from 'moment';

class ModalForUpdateOGPComment extends React.Component {
  constructor() {
    super();
    this.state = {
      visibilityExplanation: '',
      visibility: '',
      errors: {},
    };
    this.handleCheckChange = this.handleCheckChange.bind(this);
  }

  componentDidMount() {
    this.props.getOgpComment(this.props.ogpCommentId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    const {
      id,
      creationDate,
      commentText,
      user,
      category,
      visibility,
      phaseNumber,
      userOrganization,
      uuidDocumentName,
      visibilityExplanation,
    } = nextProps.ogpComment;

    this.setState({
      id,
      creationDate: creationDate ? moment(creationDate).toDate() : '',
      commentText,
      user,
      category,
      visibility,
      phaseNumber,
      userOrganization,
      uuidDocumentName,
      visibilityExplanation,
    });

    this.setState({
      yes: nextProps.ogpComment.visibility
        ? nextProps.ogpComment.visibility == 'ОБЈАВЉЕН'
          ? true
          : false
        : '',
      no: nextProps.ogpComment.visibility
        ? nextProps.ogpComment.visibility == 'ОДБИЈЕН'
          ? true
          : false
        : '',
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClose = () => {
    this.props.handleClose();
  };

  handleCheckChange = (e) => {
    let defaultMessageForYes = `${this.props.dictionary["ModalForUpdateOGPComment.ObjavaDa"]}`;
    let defaultMessageForNo =
    `${this.props.dictionary["ModalForUpdateOGPComment.ObjavaNe"]}`;

    if (e.target.name === 'yes') {
      if (this.state.yes == true) {
        this.setState({
          yes: true,
          no: false,
          visibilityExplanation: defaultMessageForYes,
        });
      } else {
        this.setState({
          yes: e.target.checked,
          no: !e.target.checked,
          visibilityExplanation: defaultMessageForYes,
        });
      }
    } else {
      if (this.state.no == true) {
        this.setState({
          yes: false,
          no: true,
          visibilityExplanation: defaultMessageForNo,
        });
      } else {
        this.setState({
          yes: !e.target.checked,
          no: e.target.checked,
          visibilityExplanation: defaultMessageForNo,
        });
      }
    }
  };

  handleValidation = () => {
    let error = false;

    if (this.state.yes == false && this.state.no == false) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForUpdateOGPComment.Validation.Status"]}`,
        '#visibility',
        '#msg1'
      );
      error = true;
    }

    if (
      !this.state.visibilityExplanation ||
      this.state.visibilityExplanation == ''
    ) {
      this.props.setMessage(
        `${this.props.dictionary["ModalForUpdateOGPComment.Validation.Odgovor"]}`,
        '#visibilityExplanation',
        '#msgVisibilityExplanation'
      );
      error = true;
    }

    return error;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const ogpCommentUpdate = {
      id: this.props.ogpCommentId,
      creationDate: this.props.ogpComment.creationDate,
      commentText: JSON.stringify(this.props.ogpComment.commentText),
      user: this.props.ogpComment.user,
      category: this.props.ogpComment.category,
      visibility: this.state.yes == true ? 'ОБЈАВЉЕН' : 'ОДБИЈЕН',
      phaseNumber: this.props.ogpComment.phaseNumber,
      userOrganization: this.props.ogpComment.userOrganization,
      uuidDocumentName: this.props.ogpComment.uuidDocumentName,
      visibilityExplanation: this.state.visibilityExplanation,
    };

    this.props
      .updateOgpComment(ogpCommentUpdate)
      .then(() => this.props.handleClose());
  };

  render() {
    const { errors } = this.state;

    const commentText = this.props.ogpComment.commentText
      ? this.props.ogpComment.commentText.comment.text
      : '';
    const highlightedText = this.props.ogpComment.commentText
      ? this.props.ogpComment.commentText.content.text
      : '';
    const nameAndSurname = this.props.ogpComment.user
      ? this.props.ogpComment.user.firstName +
        ' ' +
        this.props.ogpComment.user.lastName
      : '';

    const pageNumber = this.props.ogpComment.commentText
      ? this.props.ogpComment.commentText.position
        ? 'Страна ' + this.props.ogpComment.commentText.position.pageNumber
        : ''
      : '';

    const userOrganization = this.props.ogpComment.userOrganization
      ? this.props.ogpComment.userOrganization.organizationName
      : '';

    let isYesCheckedStyle =
      this.state.yes == true
        ? 'boldText labelText textShadow text-right'
        : 'labelText textShadow text-right';
    let isNoCheckedStyle =
      this.state.no == true
        ? 'boldText labelText textShadow text-right'
        : 'labelText textShadow text-right';

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <div className="row mt-3">
              <div className="col-md-12">
                <div className="col-md-6 float-left">
                  <h4 className="text-center">{this.props.dictionary["ModalForUpdateOGPComment.PregledKomentara"]}</h4>
                  <hr></hr>
                  <div className="form-group mb-4 mt-2">
                    {pageNumber && pageNumber != '' ? (
                      <label className="small-font labelText textShadow">
                        <em>{pageNumber}</em>
                      </label>
                    ) : (
                      ''
                    )}
                    {pageNumber && pageNumber != '' && <hr></hr>}
                    <label className="labelText textShadow">{this.props.dictionary["ModalForUpdateOGPComment.Komentar"]}:</label>
                    <p>{commentText}</p>
                    <hr></hr>
                    <label className="labelText textShadow">{this.props.dictionary["ModalForUpdateOGPComment.Korisnik"]}:</label>
                    {userOrganization && userOrganization != '' ? (
                      <p>{nameAndSurname + ', ' + userOrganization}</p>
                    ) : (
                      <p>{nameAndSurname}</p>
                    )}
                    <hr></hr>
                    <label className="labelText textShadow">
                    {this.props.dictionary["ModalForUpdateOGPComment.DatumObjave"]}:
                    </label>
                    <p>
                      {moment(this.props.ogpComment.creationDate).format(
                        'DD.MM.YYYY HH:mm'
                      )}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 float-right">
                  <h4 className="text-center">{this.props.dictionary["ModalForUpdateOGPComment.Obrada"]}</h4>
                  <hr></hr>
                  <div className="form-group">
                    <label className="lableText textShadow">
                    {this.props.dictionary["ModalForUpdateOGPComment.StatusObjave"]}:{' '}
                      <span className="redAsterisk"> *</span>
                    </label>
                    <div className="form-row">
                      <label className={isYesCheckedStyle}>
                        <span className="bullet-char">&bull;</span>
                        {this.props.dictionary["ModalForUpdateOGPComment.Objavljen"]}
                      </label>
                      <input
                        type="checkbox"
                        id="visibility"
                        name="yes"
                        className="mt-2 col-md-1 filled-in"
                        style={{ cursor: 'pointer' }}
                        checked={this.state.yes}
                        onChange={this.handleCheckChange}
                      />
                      <label className={isNoCheckedStyle}>
                        <span className="bullet-char">&bull;</span>
                        {this.props.dictionary["ModalForUpdateOGPComment.Odbijen"]}
                      </label>
                      <input
                        type="checkbox"
                        id="visibility"
                        name="no"
                        className="mt-2 col-md-1 filled-in"
                        style={{ cursor: 'pointer' }}
                        checked={this.state.no}
                        onChange={this.handleCheckChange}
                      />
                    </div>
                    <div id="msg1" />
                  </div>
                  <div className="form-group mt-2">
                    <label className="labelText textShadow">
                    {this.props.dictionary["ModalForUpdateOGPComment.ObrazlozenjeStatusa"]}:{' '}
                      <span className="redAsterisk"> *</span>
                    </label>
                    <TextArea
                      type="text"
                      id="visibilityExplanation"
                      placeholder={this.props.dictionary["ModalForUpdateOGPComment.Placeholder.ObrazlozenjeStatusa"]}
                      name="visibilityExplanation"
                      value={this.state.visibilityExplanation}
                      onChange={this.onChange}
                      errors={errors.visibilityExplanation}
                      maxLength="1000"
                      rows={3}
                    />
                    <div id="msgVisibilityExplanation" />
                  </div>
                  <hr></hr>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <button
                type="button"
                id="subscribeButton"
                className="col-md-3 m-auto"
                onClick={this.handleSubmit}
              >
                {this.props.dictionary["ModalForUpdateOGPComment.Sacuvaj"]}
              </button>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

ModalForUpdateOGPComment.propTypes = {
  getOgpComment: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  updateOgpComment: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ogpComment: state.ogpComment.ogpComment,
  errors: state.errors,
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {
  getOgpComment,
  setMessage,
  updateOgpComment,
  getOgpCommentList,
})(ModalForUpdateOGPComment);
