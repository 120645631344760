export const changeTabCssStyle = (active, arrayOfItems) => {
  if (document.getElementById(active).classList.contains('active-tab')) {
    return;
  }
  let arrayOfElements = arrayOfItems;
  arrayOfElements.forEach((item) => {
    console.log(item);
    if (
      item != active &&
      document.getElementById(item) &&
      document.getElementById(item).classList.contains('active-tab')
    ) {
      let element = document.getElementById(item);
      element.classList.remove('active-tab');
      element.classList.toggle('default-tab-style');
      let elementLabelId = item + 'Label';
      let elementLabel = document.getElementById(elementLabelId);
      elementLabel.classList.remove('active-tab-label');
      elementLabel.classList.toggle('default-tab-label-style');
      let elementDateLabelId = item + 'DateLabel';
      let elementDateLabel = document.getElementById(elementDateLabelId);
      elementDateLabel.classList.remove('active-tab-date-label');
      elementDateLabel.classList.toggle('default-tab-date-label-style');
    }
  });

  let newElement = document.getElementById(active);
  newElement.classList.remove('default-tab-style');
  newElement.classList.toggle('active-tab');
  let newElementLabelId = active + 'Label';
  let newElementLabel = document.getElementById(newElementLabelId);
  newElementLabel.classList.remove('default-tab-label-style');
  newElementLabel.classList.toggle('active-tab-label');
  let newElementDateLabelId = active + 'DateLabel';
  let newElementDateLabel = document.getElementById(newElementDateLabelId);
  newElementDateLabel.classList.remove('default-tab-date-label-style');
  newElementDateLabel.classList.toggle('active-tab-date-label');
};
