import React from "react";
import Modal from "react-bootstrap/Modal";
import { connect } from 'react-redux';
class ModalForSpecificCommentInfo extends React.Component {
  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id='modalForSpecificCommentInfo'
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <h2 className="title-text text-center">
              {this.props.dictionary["modalForSpecificCommentInfo.Uputstvo"]}
            </h2>
            <hr></hr>
            <p className="large-font">
              <strong className="default-label-text">
                <em>{this.props.dictionary["modalForSpecificCommentInfo.Opis"]}</em>
                <br />
              </strong>
              <span>
               <div dangerouslySetInnerHTML={{__html: this.props.dictionary["modalForSpecificCommentInfo.OpisTekst"]}}/>
              </span>
            </p>
            <label>
              <strong className="default-label-text">{this.props.dictionary["modalForSpecificCommentInfo.Napomena"]} </strong>
              <em>
                <div dangerouslySetInnerHTML={{__html: this.props.dictionary["modalForSpecificCommentInfo.NapomenaTekst"]}} />
              </em>
            </label>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps,{
}) (ModalForSpecificCommentInfo);
