import DatePicker from 'react-datepicker';
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setMessage } from '../../utils';
import { getPhaseList, updatePhaseDates } from '../../actions/phaseActions';
import { trackPromise } from 'react-promise-tracker';
import { RESET_ERRORS } from '../../actions/types';
import { NotificationManager } from 'react-notifications';

const ModalForUpdatePhase = (props) => {

    const [startDate, setStartDate] = useState(props.phase?.startDate ? new Date(props.phase?.startDate) : '');
    const [endDate, setEndDate] = useState(props.phase?.endDate ? new Date(props.phase?.endDate) : '');

    const dictionary = useSelector(state => state.startUp.dictionary);
    const phaseTax = useSelector(state => state.startUp.taxonomies.ProcessPhase).find(x => x.code == props.phase.phaseType)


    const taxonomies = useSelector(state => state.startUp.taxonomies);
    
    const informationName = props.phase.article77 ? 'MinimalniPeriodTrajanjaFazeZaClan77' : 'MinimalniPeriodTrajanjaFaze'

    const minPhaseLength = phaseTax.taxonomyValueInformationList.find(x => x.informationName == informationName).informationValue;

    const dispatch = useDispatch();

    const handleStartDateChange = (date) => {
        setStartDate(date);
      };
    
    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const showUpdatePhaseValidationMessage = (objectName, defaultMessage) => {
     
        document.getElementById(`${objectName}Message`).style.color = 'red';
        document.getElementById(`${objectName}Message`).innerHTML = '<p>' + defaultMessage + '</p>';
        
        dispatch({
          type: RESET_ERRORS,
          payload: { objectName: null, defaultMessage: null},
        })
  
        setTimeout(function () {
          if (document.getElementById(`${objectName}Message`) != null) {
            document.getElementById(`${objectName}Message`).innerHTML = '';
            document.getElementById(`${objectName}Message`).classList.remove('alert-danger');
          }
        }, 3000);
    }
  

    const handleSubmit = () => {
      const error = handleValidation();
      if (!error) { 
        const phase = {
          id: props.phase.id,
          startDate: startDate,
          endDate: endDate,
          topicOfDiscussion: {
            id: props.topicOfDiscussionId
          }
        }
        trackPromise(dispatch(updatePhaseDates(phase))).then(() => {
          dispatch(getPhaseList(props.topicOfDiscussionId))
          NotificationManager.success(dictionary["ModalForUpdatePhase.UspesnaIzmena"],dictionary["universal.Uspesno"], 5000);
          props.handleClose();
        }).catch((err) => {
          showUpdatePhaseValidationMessage(err.response.data.objectName, err.response.data.defaultMessage);
        })
      }
    }

    const handleValidation = () => {
      let error = false;
      if (!startDate || startDate == '') {
        dispatch(setMessage(
          dictionary["ModalForAddNewMethod.Validation.StartDate"],
          '#startDate',
          '#startDateМsg'
         ));
        error = true;
      } 
      if (!endDate || endDate == '') {
        dispatch(setMessage(
          dictionary["ModalForAddNewMethod.Validation.EndDate"],
          '#endDate',
          '#endDateМsg'
         ));
        error = true;
      } 

      const diff = moment(endDate).diff(moment(startDate), 'days');
      if (diff < parseInt(minPhaseLength)) {
        dispatch(setMessage(
          dictionary["ModalForUpdatePhase.Validation.MinDayLength"] + ` ${minPhaseLength}`,
          '#startDate',
          '#phaseDatesMessage'
         ));
        error = true;
      }
      return error;
    }
    
    return (
        <Modal
        show={props.show}
        onHide={props.handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <h3 className="text-center textShadow">{props.phase.phaseTypeDisplay}</h3>
            <hr></hr>
            <div className="row">
              <div className="col-md-6 float-left">
                <label className="labelText textShadow">
                  {dictionary["modalForUpdatePublicDebate.datumZapocinjanja"]}<span className="redAsterisk"> *</span>
                </label>
                <DatePicker
                  id="startDate"
                  selected={startDate}
                  onChange={handleStartDateChange}
                  className="dateInput form-control"
                  dateFormat="dd.MM.yyyy"
                  placeholderText={dictionary["modalForUpdatePublicDebate.placeholderText.datumZapocinjanja"]}
                  minDate={moment().toDate()}
                  maxDate={endDate}
                />
                <div id="startDateМsg" />
              </div>
              <div className="col-md-6 float-right">
                <label className="labelText textShadow">
                {dictionary["modalForUpdatePublicDebate.datumZavrsetka"]}<span className="redAsterisk"> *</span>
                </label>
                <DatePicker
                  id="endDate"
                  selected={endDate}
                  onChange={handleEndDateChange}
                  className="dateInput form-control"
                  dateFormat="dd.MM.yyyy"
                  placeholderText={dictionary["modalForUpdatePublicDebate.placeholderText.datumZavrsetka"]}
                  minDate={startDate? new Date(startDate.getTime() + minPhaseLength * 24 * 60 * 60 * 1000) : null}
                />
                <div id="endDateМsg" />
              </div>
            </div>
            <div id="phaseDatesMessage"></div>
            <hr></hr>
            <div className="row mt-1">
              <button
                type="button"
                id="subscribeButton"
                className="col-md-6 m-auto"
                onClick={handleSubmit}
              >
                {dictionary["modalForUpdatePublicDebate.sacuvaj"]}
              </button>
            </div>
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
}

export default ModalForUpdatePhase;