import {
    CREATE_METHOD_PARTICIPANT,
    GET_METHOD_PARTICIPANTS,
    GET_METHOD_PARTICIPANT_DOCUMENT,
    RESET_METHOD_PARTICIPANT_DOCUMENT
} from "../actions/types";

const initialState = {
    methodParticipant: {},
    methodParticipantList: [],
    methodParticipantDocument: null
};

export default function (state = initialState, action) {
    switch (action.type) {
      case CREATE_METHOD_PARTICIPANT:
        return {
            ...state
            };
        case GET_METHOD_PARTICIPANTS:
            return {
                ...state,
                methodParticipantList: action.payload
            }
        case GET_METHOD_PARTICIPANT_DOCUMENT:
            return {
                ...state,
                methodParticipantDocument: action.payload
            }
        case RESET_METHOD_PARTICIPANT_DOCUMENT:
            return {
                ...state,
                methodParticipantDocument: action.payload
            }
      default:
        return state;
    }
}