import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Input from '../Reusable/Input';
import ComboReusable from '../Reusable/ComboReusable';
import { getRoleList } from '../../actions/roleActions';
import { getInstitutionsByStatus } from '../../actions/institutionActions';
import { createUser } from '../../actions/userActions';
import { setMessage } from '../../utils';
import { deleteErrors } from '../../utils';
import { NotificationManager } from 'react-notifications';
import { CREATE_USER_SUCCESS } from '../../actions/types'

const ModalForAddUser = (props) => {
    const EXCLUDE_CITIZEN_ROLE = x=>x.id!=3;
    const dispatch = useDispatch();
    const dictionary = useSelector(state => state.startUp.dictionary);
    const roleList = useSelector(state => state.role.roleList.filter(EXCLUDE_CITIZEN_ROLE));
    const institutions = useSelector(state => state.institution.institutions);
    const isLoggedUserJLS = useSelector(state => state.security.jls);
    const isLoggedUserRSSuperAdmin = useSelector(state=>state.security.rsSuperAdmin);
    const isLoggedUserJLSSuperAdmin = useSelector(state=>state.security.jlsSuperAdmin);
    const isLoggedUserJLSAdmin = useSelector(state=>state.security.jlsSmallAdmin);
    const loggedUserRole = useSelector(state=>state.security.authRole);
    const loggedUserInstitutionId = useSelector(state=>state.security.institutionId);
    const errors = useSelector(state=>state.errors);
    const userCreated = useSelector(state=>state.user.userCreated);

    const emptyUser = {
        jmbg: '',
        firstName: '',
        lastName: '',
        email: '',
        role: {
          id: -1,
        },
        institution: {
          id: -1,
        },
        jls: false,
    };
    const [user, setUser] = useState(emptyUser);
    const [institutionDisabled, setInstitutionDisabled] = useState(false);
    const [roleDisabled, setRoleDisabled] = useState(false);

    useEffect(()=>{
        dispatch(getRoleList());
        dispatch(getInstitutionsByStatus());
        dispatch(deleteErrors());
        if((isLoggedUserRSSuperAdmin == false && isLoggedUserJLS == false) || isLoggedUserJLSAdmin==true) {
          setUser((prevUSer)=>{return {...emptyUser,institution: {id:loggedUserInstitutionId}}});
          setInstitutionDisabled(true);
        }
        if(isLoggedUserRSSuperAdmin==false && isLoggedUserJLSSuperAdmin==false) {
          setUser((prevUser)=>{return {...prevUser,role: {id:2}}});
          setRoleDisabled(true);
        }
    },[])

    useEffect(()=>{
        if(userCreated != null) {
            if(userCreated==true)
                if(errors.duplicateRoleErrorMessage) NotificationManager.error(errors.duplicateRoleErrorMessage,dictionary["universal.GreskaUObradi"], 5000);
                else NotificationManager.success(dictionary["addUser.UspesnoDodavanje"],dictionary["universal.Uspesno"], 5000);
            else NotificationManager.error(dictionary["addUser.NeuspesnoDodavanje"],dictionary["universal.GreskaUObradi"], 5000);  
            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: null,
              });
        }
    },[userCreated, props.error])

    const onChange = (e) => {
        const {name,value} = e.target;
        setUser(prevUser => ({...prevUser, [name]:value}));
    };
    const handleComboBoxChange = (object, objectId) => {
        return (e) => {
            const settingNonAdminRole = object == 'role' && objectId == 'id' && e.target.value != 1; 
            setUser(prevUser=> ({
                ...prevUser,
                [object]: { [objectId]: e.target.value },
                ['jls'] : settingNonAdminRole ? false : prevUser.jls }));
        };
    };
    const handleCheckboxChange = (e) => {
        const {name,checked} = e.target;
        setUser(prevUser => ({...prevUser,
                               [name]: checked,
                               ['institution'] : (prevUser.role?.id==1 || prevUser.role?.id==4) && checked ? null : prevUser.institution 

        }));
    };

    const handleClose = (refresh) => {
        setUser(emptyUser);
        props.handleClose(refresh);
    };
    const handleValidation = () => {
        let error = false;
    
        if (user.jmbg == '') {
          dispatch(setMessage(dictionary["addUser.Validation.jmbg"], '#jmbg', '#errorValidationJMBG'));
          error = true;
        }
        if (user.jmbg.length !== 13) {
          dispatch(setMessage(dictionary["addUser.Validation.jmbgDuzina"],'#jmbg','#errorValidationJMBG'));
          error = true;
        }
        if (user.firstName == '') {
          dispatch(setMessage(dictionary["addUser.Validation.ime"],'#firstName','#errorValidationFirstName'));
          error = true;
        }
        if (user.lastName == '') {
          dispatch(setMessage(dictionary["addUser.Validation.prezime"],'#lastName','#errorValidationLastName'));
          error = true;
        }
        if (user.email == '') {
          dispatch(setMessage(dictionary["addUser.Validation.email"], '#email', '#errorValidationEmail'));
          error = true;
        }
        if (user.role?.id == -1) {
          dispatch(setMessage(dictionary["addUser.Validation.uloga"],'#roleCb','#errorValidationRole'));
          error = true;
        }
        //nije obavezna kada RS super admin dodaje JLS super admina ili JLS super evaluatora
        if ((user.institution == null ||user.institution?.id == -1) && (isLoggedUserJLS == true || (user.role?.id != 1 && user.role?.id != 4) || user.jls == false)) {
          dispatch(setMessage(dictionary["addUser.Validation.institucija"],'#institutionCb','#errorValidationInstitution'));
          error = true;
        }
        return error;
    };
    const handleSubmit = (e) => {
        
        e.preventDefault();
        
        if (handleValidation() == true) {
          return;
        }

        dispatch(createUser(user))
        .catch( (err) => {
            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: false,
              });
          })
        .then( () => {
            dispatch({
                type: CREATE_USER_SUCCESS,
                payload: true,
              });
        })
        .finally ( () => { 
            handleClose(true); 
        });
    };

    return(
        <Modal
            show={props.show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered    
        >
        <Modal.Header closeButton>
        <Modal.Body>
            <h4 className="text-center">{dictionary["addUser.naslov"]}</h4>
            <hr />
            <div className="form-group">
                <label className="textShadow">
                    {dictionary["addUser.jmbg"]}<span className="redAsterisk">*</span>
                </label>
                <Input
                    type="text"
                    id="jmbg"
                    placeholder={dictionary["addUser.placeholder.jmbg"]}
                    name="jmbg"
                    value={user.jmbg}
                    onChange={onChange}
                    //errors={errors.jmbg}
                />
                <div id="errorValidationJMBG" />
            </div>
            <div className="form-group">
                <label className="textShadow">
                    {dictionary["addUser.ime"]}<span className="redAsterisk">*</span>
                </label>
                <Input
                    type="text"
                    id="firstName"
                    placeholder={dictionary["addUser.placeholder.ime"]}
                    name="firstName"
                    value={user.firstName}
                    onChange={onChange}
                />
                <div id="errorValidationFirstName" />
            </div>
            <div className="form-group">
                <label className="textShadow">
                    {dictionary["addUser.prezime"]}<span className="redAsterisk">*</span>
                </label>
                <Input
                    type="text"
                    id="lastName"
                    placeholder={dictionary["addUser.placeholder.prezime"]}
                    name="lastName"
                    value={user.lastName}
                    onChange={onChange}
                />
                <div id="errorValidationLastName" />
            </div>
            <div className="form-group">
                <label className="textShadow">
                    {dictionary["addUser.email"]}<span className="redAsterisk">*</span>
                </label>
                <Input
                    type="text"
                    id="email"
                    placeholder={dictionary["addUser.placeholder.email"]}
                    name="email"
                    value={user.email}
                    onChange={onChange}
                />
                <div id="errorValidationEmail" />
            </div>
            <div className="form-group">
                <label className="textShadow">
                    {dictionary["addUser.uloga"]}<span className="redAsterisk">*</span>
                </label>
                <ComboReusable
                    id="roleCb"
                    disabled={roleDisabled}
                    namePart={dictionary["addUser.namePart.uloga"]}
                    items={roleList.map((role) => (
                          <option key={role.id} value={role.id}>
                            {role.roleName}
                          </option>
                        ))}
                    updateValue = {user.role.id}
                    onChange={handleComboBoxChange('role', 'id')}
                />
                <div id="errorValidationRole" />
            </div>
            { isLoggedUserJLS == false && (user.role.id == 1 || user.role.id == 4) ?
                    (<div className='form-group'>
                      <label className='textShadow'>
                        {dictionary["addUser.pripadaJLS"]}
                      </label>
                      <input
                        type="checkbox"
                        name="jls"
                        className="mx-2 filled-in form-check-input"
                        checked={user.jls}
                        onChange={handleCheckboxChange}
                      />
                    </div>):""}
            {!(user.jls && !isLoggedUserJLS && loggedUserRole==1)  ? 
                    (<div className="form-group">
                        <label className="textShadow">
                         {dictionary["addUser.institucija"]} <span className="redAsterisk">*</span>
                        </label>
                        <ComboReusable
                          id="institutionCb"
                          disabled={institutionDisabled}
                          namePart={dictionary["addUser.namePart.institucija"]}
                          items={institutions.map((institution) => (
                            <option key={institution.id} value={institution.id}>
                              {institution.institutionName}
                            </option>
                          ))}
                          updateValue = {user.institution?.id}
                          onChange={handleComboBoxChange('institution','id')}
                        />
                        <div id="errorValidationInstitution" />
                      </div>
                    ) : (
                      ''
                    )}
            <div className="col-md-12 mt-4">
                <button
                    id="subscribeButton"
                    className="col-md-2 float-right"
                    onClick={handleSubmit}
                >
                {dictionary["addUser.sacuvaj"]}
                </button>
                <button
                    id="subscribeButton"
                    className="col-md-2 float-right"
                    onClick={handleClose}
                >
                {dictionary["addUser.odustani"]}
                </button>
            </div>
        </Modal.Body>
        </Modal.Header>
        </Modal>
    );
}
export default ModalForAddUser;