import axios from "axios";

import {
  GET_ERRORS,
  CREATE_OGP_ARCHIVE,
  GET_OGP_ARCHIVE_LIST,
  GET_OGP_ARCHIVE,
} from "./types";

export const createOGPArchive = (ogpArchive) => async (dispatch) => {
  try {
    const res = await axios.post("/api/ogpArchive/create", ogpArchive);
    dispatch({
      type: CREATE_OGP_ARCHIVE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getOGPArchiveList = (ogpId) => async (dispatch) => {
  const res = await axios.get(`/api/ogpArchive/findAll/${ogpId}`);
  dispatch({
    type: GET_OGP_ARCHIVE_LIST,
    payload: res.data,
  });
};

export const getOGPArchive = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/ogpArchive/findById/${id}`);
    dispatch({
      type: GET_OGP_ARCHIVE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};
export const downloadOgpList = (id) => async (dispatch) => {
  axios({
    url: `/api/ogpArchive/exportOgpInWord/${id}`,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "OGP " + ".docx");
    document.body.appendChild(link);
    link.click();
  });
};
