import {
  GET_USER_ORGANIZATION_CATEGORY,
  GET_USER_ORGANIZATION_CATEGORY_LIST,
  DELETE_USER_ORGANIZATION_CATEGORY,
  CREATE_USER_ORGANIZATION_CATEGORY_SUCCESS,
  UPDATE_USER_ORGANIZATION_CATEGORY_SUCCESS
} from "../actions/types";

const initialState = {
  userOrganizationCategoryList: [],
  userOrganizationCategory: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_ORGANIZATION_CATEGORY_LIST:
      return {
        ...state,
        userOrganizationCategoryList: action.payload,
      };
    case GET_USER_ORGANIZATION_CATEGORY:
      return {
        ...state,
        userOrganizationCategory: action.payload,
      };
    case DELETE_USER_ORGANIZATION_CATEGORY:
      return {
        ...state,
        userOrganizationCategoryList: state.userOrganizationCategoryList.filter(
          (userOrganizationCategory) =>
            userOrganizationCategory.id !== action.payload
        ),
      };
    case CREATE_USER_ORGANIZATION_CATEGORY_SUCCESS:
      return {
        ...state,
        userOrganizationCategoryCreated: action.payload
      };
    case UPDATE_USER_ORGANIZATION_CATEGORY_SUCCESS:
      return {
        ...state,
        userOrganizationCategoryUpdated: action.payload
      };
    default:
      return state;
  }
}
