import { useSelector } from "react-redux";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Link } from "react-router-dom";



const SurveyQuestionResults = (props) => {

    const dictionary = useSelector((store) => store.startUp.dictionary)

    const answers = props.question?.surveyAnswerResult?.map((answer, index) => {
    let percentage = 0;
    if (answer.replies > 0) {
        percentage = ((answer.replies/props.question.totalClosedAnswers)*100).toFixed(0);
    }
    const repliesLabel = answer.replies == 1 ? dictionary["SurveyQuestionResults.Odgovor"] : dictionary["SurveyQuestionResults.Odgovora"]
    return  <div className="" key={index}>
    <span className="col-md-12 ps-2" style={{ fontWeight: 'bold' }}>{answer.answer}</span>
    <div>
      <div className="d-flex flex-column flex-md-row">
        <ProgressBar
          className="w-100"
          style={{ height: '23px' }}
          now={percentage}
        />
        <div className="text-center col-md-2">
            <span className="pe-4">{percentage} %</span>
            <span className="">{answer.replies} {repliesLabel}</span>
        </div>
        
      </div>
    </div>
  </div>
    })

   return ( 
   <div className="card mb-4 fade-in" style={{width: "100%", margin: "auto"}}>
        <div className="card-header d-flex question-card-header">
            <h6 className="align-self-top pe-1">{props.index+1}.</h6>
            <h6 className="card-title">{props.question.question}</h6>
        </div>  
        <div className="card-body pt-0">
            {props.question.questionType.id == 2 ? 
                (
                    props.question.surveyAnswerResult?.length == 0 ?
                    <div><span>{dictionary["SurveyQuestionResults.NisuZabelezeniOdgovori"]}</span></div> :
                    <>
                        {answers}
                        <div className="col-md-12 pt-2">
                            <label className="pe-2">{dictionary["SurveyQuestionResults.BrojOdgovora"]}: </label>
                            <span>{props.question.totalClosedAnswers}</span>
                        </div>
                    </>
                ) : 
                (
                    <div>
                        <Link
                            onClick={(e) => {
                            e.preventDefault();
                            props.openModalForOpenAnswersView(props.question);
                        }}  
                            className='pe-2 tableFooterLinks'
                            to="#"
                        >
                            {dictionary["SurveyQuestionResults.BrojOdgovora"]}:
                        </Link>
                        <span>{props.question.numberOfOpenEndedAnswers}</span>
                    </div>
                )
            }
        </div>
    </div>
   ); 
}


export default SurveyQuestionResults;

    