import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { findMethodById } from '../../actions/methodActions';
import MethodDocumentTable from './MetodDocumentTable';

const ModalForMethodDocuments = (props) => {

    const dispatch = useDispatch();
    
    const methodDocuments = useSelector(state => state.method.method.documents);
    const additionalDocuments = useSelector(state => state.method.method.additionalDocuments);
    const dictionary = useSelector(state => state.startUp.dictionary);

    useEffect(() => {
      dispatch(findMethodById(props.method.id));
    }, [])
    return(
        <Modal
        show={props.show}
        onHide={props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="d-flex flex-column">
        <h3 className="text-center textShadow m-auto order-2">
            {dictionary["ModalForMethodDocuments.Header"]}
        </h3>
        </Modal.Header> 
        <Modal.Body>
          <MethodDocumentTable
            methodDocuments={(props.method.methodType == "PrikupljanjeKomentara" || props.method.methodType == "JavniPozivOCD") ? additionalDocuments : methodDocuments}
            modalForPreviewSignal={true}
          />
        </Modal.Body> 
      </Modal>
    );
}
export default ModalForMethodDocuments;