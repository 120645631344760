import {
  GET_COMMENT,
  GET_COMMENT_LIST,
  GET_COMMENT_LIST_FILTERED,
  DELETE_COMMENT,
  GET_COMMENT_LIST_DTO,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  UPDATE_COMMENT_STATUS,
  GET_COMMENT_MAX_ID,
  GET_NUMBER_OF_UNHANDLED_COMMENTS,
  GET_COMMENT_LIST_ON_DOCUMENT
} from "../actions/types";

const initialState = {
  commentList: [],
  comment: {},
  commentListDTO: [],
  commentMaxId: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMMENT_LIST:
      return {
        ...state,
        commentList: action.payload,
      };
      case GET_COMMENT_LIST_FILTERED:
        return {
          ...state,
          commentListFiltered :  action.payload,
        };
    case GET_COMMENT:
      return {
        ...state,
        comment: action.payload,
      };
    case DELETE_COMMENT:
      return {
        ...state,
        commentList: state.commentList.filter(
          (comment) => comment.id !== action.payload
        ),
      };
    case GET_COMMENT_LIST_DTO:
      return {
        ...state,
        commentListDTO: action.payload,
      };
    case CREATE_COMMENT:
      return {
        ...state,
        commentList: state.commentList.concat(action.payload),
      };
    case UPDATE_COMMENT:
      return {
        ...state
      };
      case UPDATE_COMMENT_STATUS:
      return {
        ...state
      };
    case GET_COMMENT_MAX_ID:
      return {
        ...state,
        commentMaxId: action.payload,
      };
      case GET_NUMBER_OF_UNHANDLED_COMMENTS:
        return {
          ...state,
          commentsUnhandled: action.payload,
        };
    case GET_COMMENT_LIST_ON_DOCUMENT:
      return {
        ...state,
        commentListOnDocument: {
          ...action.payload,
          content: action.payload.content.map((comment) => ({
            ...comment,
            commentUserVote: comment.commentUserVote == null ? [] : JSON.parse(comment.commentUserVote)
          }))
        }
      };
    default:
      return state;
  }
}