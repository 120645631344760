import React, { Component } from 'react';
import { connect } from 'react-redux';

class ComboBoxSearchCriteria extends Component {
  render() {
    return (
      <select
        id="searchCriteria"
        className="select-field form-control form-control-mb"
        onChange={this.props.onChange}
        value={this.props.value ? this.props.value : '-1'}
        disabled={this.props.disabled}
      >
        <option key="-1" value="-1" disabled hidden>
          {this.props.dictionary["ComboBoxSearchCriteria.option0"]}
        </option>
        <option key="1" value={1}>
          {this.props.dictionary["ComboBoxSearchCriteria.option1"]}
        </option>
        <option key="2" value={2}>
          {this.props.dictionary["ComboBoxSearchCriteria.option2"]}
        </option>
      </select>
    );
  }
}
const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary
});

export default connect(mapStateToProps, {
})(ComboBoxSearchCriteria);