import {
  GET_OGP,
  GET_OGP_LIST,
  DELETE_OGP,
  UPDATE_OGP,
  GET_OGP_LIST_PAGEABLE,
} from '../actions/types';

const initialState = {
  ogps: [],
  ogp: {},
  totalElements: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OGP_LIST_PAGEABLE:
      return {
        ...state,
        ogps: action.payload[0],
        numberOfPages: action.payload[1],
        totalElements: action.payload[2],
      };
    case GET_OGP_LIST:
      return {
        ...state,
        ogps: action.payload,
      };
    case GET_OGP:
      return {
        ...state,
        ogp: action.payload,
      };
    case DELETE_OGP:
      return {
        ...state,
        ogps: state.ogps.filter((ogp) => ogp.id !== action.payload),
      };
    case UPDATE_OGP:
      return {
        ...state,
        ogps: state.ogps.map((ogp) =>
          ogp.id === action.payload.id ? action.payload : ogp
        ),
      };
    default:
      return state;
  }
}
