import {
  GET_OGP_COMMENT,
  GET_OGP_COMMENT_LIST,
  DELETE_OGP_COMMENT,
  GET_OGP_COMMENT_LIST_DTO,
  CREATE_OGP_COMMENT,
  UPDATE_OGP_COMMENT,
  GET_OGP_COMMENT_MAX_ID,
} from "../actions/types";

const initialState = {
  ogpCommentList: [],
  ogpComment: {},
  ogpCommentListDTO: [],
  ogpCommentMaxId: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_OGP_COMMENT_LIST:
      return {
        ...state,
        ogpCommentList: action.payload,
      };
    case GET_OGP_COMMENT:
      return {
        ...state,
        ogpComment: action.payload,
      };
    case DELETE_OGP_COMMENT:
      return {
        ...state,
        ogpCommentList: state.ogpCommentList.filter(
          (comment) => comment.id !== action.payload
        ),
      };
    case GET_OGP_COMMENT_LIST_DTO:
      return {
        ...state,
        ogpCommentListDTO: action.payload,
      };
    case CREATE_OGP_COMMENT:
      return {
        ...state,
        ogpCommentList: state.ogpCommentList.concat(action.payload),
      };
    case UPDATE_OGP_COMMENT:
      return {
        ...state,
        ogpCommentList: state.ogpCommentList.map((comment) =>
          comment.id === action.payload.id ? action.payload : comment
        ),
        ogpCommentListDTO: state.ogpCommentListDTO.map((comment) =>
          comment.id === action.payload.id ? action.payload : comment
        ),
      };
    case GET_OGP_COMMENT_MAX_ID:
      return {
        ...state,
        ogpCommentMaxId: action.payload,
      };
    default:
      return state;
  }
}
