import axios from 'axios';
import ApiService from '../securityUtils/ApiService';

import {
  GET_NOTIFICATIONS_COUNTERS,
  GET_NOTIFICATION_BY_TOPIC_OF_DISCUSSION_ID,
  GET_NOTIFICATIONS_BY_FIELD_OF_INTEREST_LIST,
  GET_NOTIFICATIONS_BY_INSTITUTION_LIST,
  GET_ERRORS,
} from './types';

export const getNotificationsCounters = () => async (dispatch) => {
  try{
    const req = {
      method: 'GET',
      url: `/api/notificationTopicOfDiscussion/getNotificationsCounters`,
    };
    ApiService.callApi(req).then((res) => {
    dispatch({
      type: GET_NOTIFICATIONS_COUNTERS,
      payload: res.data,
    });
  });
  }
  catch (error) {
    dispatch({
      type: GET_ERRORS,
      payload: error.response.data,
    });
  }
};
export const getNotificationByTopicOfDiscussionId = (id) => async (
  dispatch
) => {
  const res = await axios.get(
    `/api/notificationTopicOfDiscussion/findByTopicOfDiscussionId/${id}`
  );
  dispatch({
    type: GET_NOTIFICATION_BY_TOPIC_OF_DISCUSSION_ID,
    payload: res.data,
  });
};

export const getNotificationsByFieldOfInterest = (id) => async (dispatch) => {
  const res = await axios.get(
    `/api/notificationTopicOfDiscussion/findByFiledOfInterest/${id}`
  );
  dispatch({
    type: GET_NOTIFICATIONS_BY_FIELD_OF_INTEREST_LIST,
    payload: res.data,
  });
};

export const getNotificationsByInstitution = (id) => async (dispatch) => {
  const res = await axios.get(
    `/api/notificationTopicOfDiscussion/findByInstitution/${id}`
  );
  dispatch({
    type: GET_NOTIFICATIONS_BY_INSTITUTION_LIST,
    payload: res.data,
  });
};
