import {
  GET_NOTIFICATIONS_COUNTERS,
  GET_NOTIFICATION_BY_TOPIC_OF_DISCUSSION_ID,
  GET_NOTIFICATIONS_BY_FIELD_OF_INTEREST_LIST,
  GET_NOTIFICATIONS_BY_INSTITUTION_LIST,
} from "../actions/types";

const initialState = {
  notificationsCounters: {},
  notificationByTopicOfDiscussionIdList: [],
  notificationByFiledOfInterestList: [],
  notificationByInstitutionList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_COUNTERS:
      return {
        ...state,
        notificationsCounters: action.payload,
      };
    case GET_NOTIFICATION_BY_TOPIC_OF_DISCUSSION_ID:
      return {
        ...state,
        notificationByTopicOfDiscussionIdList: action.payload,
      };
    case GET_NOTIFICATIONS_BY_FIELD_OF_INTEREST_LIST:
      return {
        ...state,
        notificationByFiledOfInterestList: action.payload,
      };
    case GET_NOTIFICATIONS_BY_INSTITUTION_LIST:
      return {
        ...state,
        notificationByInstitutionList: action.payload,
      };
    default:
      return state;
  }
}
