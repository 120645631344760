import React, { useState }  from 'react';

import { Tr, Td } from 'react-super-responsive-table';
import { Link } from 'react-router-dom';
import ModalForDocumentPreview from '../Reusable/ModalForDocumentPreview';
import { useSelector } from 'react-redux';


const ExternalMembersRow = (props) =>  {

    const [showExternalMemberApplication, setShowExternalMemberApplication] = useState(false);

    const dictionary = useSelector(state => state.startUp.dictionary);

    const showModal = () => {
        setShowExternalMemberApplication(true);
    };
    const handlePreviewClose = () => {
        setShowExternalMemberApplication(false);
    };

    return (
      <>
        <Tr>
            <Td>{props.methodParticipant.user.firstName} {props.methodParticipant.user.lastName}</Td>
            <Td>{props.methodParticipant.organisation ? dictionary["ExternalMembersRow.OrganizacijaDa"] : dictionary["ExternalMembersRow.OrganizacijaNe"]}</Td>
            <Td className="text-center">
            <Link onClick={(e) => {e.preventDefault(); showModal();}} to="#">
                {dictionary["Document.Pogledaj"]}
            </Link>
            </Td>
      </Tr>
        {showExternalMemberApplication && (
          <ModalForDocumentPreview
            show={showExternalMemberApplication}
            documentName={props.methodParticipant.document.uuid + props.methodParticipant.document.mimeTypeShort}
            controllerReference={'document'}
            handleClose={handlePreviewClose}
          />
        )}
      </>
    );
}


export default ExternalMembersRow;
