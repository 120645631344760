import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../../actions/securityActions";
import AuthService from "../../securityUtils/AuthService";
import { trackPromise } from "react-promise-tracker";
import {
  authorizationService,
  roleToIdMap,
} from "../../securityUtils/authorizationService";
import ModalForDocumentPreview from "../Reusable/ModalForDocumentPreview";
import { headerDocumentTitles, headerModalName } from "../../constants";
import {
  NotificationManager,
} from "react-notifications";
import { forgetMe } from "../../actions/securityActions";
import { confirmDialog } from "../Reusable/ConfirmDialog";
import UserOrganizationList from "../UserOrganization/UserOrganizationList";
import ModalForChoosingInstitutionsOfInterest from "../Dashboard/ModalForChoosingInstitutionsOfInterest";
import ModalForChoosingFieldsOfInterest from "../Dashboard/ModalForChoosingFieldsOfInterest";
import { exportRawData } from "../../actions/topicOfDiscussionActions";
import LoadingIndicatorOverlay from "../Reusable/LoadingIndicatorOverlay";
import { getAllFiledsOfInterest } from "../../actions/fieldOfInterestActions";
import { getInstitutions } from "../../actions/institutionActions";
import {
  getNotificationsCounters,
  getNotificationByTopicOfDiscussionId,
  getNotificationsByFieldOfInterest,
  getNotificationsByInstitution,
} from "../../actions/notificationTopicOfDiscussionActions";
import { getNotifications } from "../../actions/notificationActions";
import {
  getTopicsOfDiscussionByUserId,
  getAllTopicsWithUserActivity,
  getAllTopicsWithUserActivityAndNotification,
  getOtherTopicOfDiscussionsPost,
} from "../../actions/topicOfDiscussionActions";
import UpdateContactData from "../User/UpdateContactData";
import CreateButtonModal from "../Reusable/CreateButtonModal";
import ModalForAddTopicOfDiscussion from '../TopicOfDiscussion/ModalForAddTopicOfDiscussion';
import ModalForTopicOfDiscussionList from "../TopicOfDiscussion/ModalForTopicOfDiscussionList";
import ModalForNotificationList from "../Notification/ModalForNotificationList";

class Header extends Component {
  constructor() {
    super();
    this.state = {
      showModalFieldsAndInstitution: false,
      showModalFieldsOfInterest: false,
      showModalInstitutionsOfInterest: false,
      showModalForDocumentPreview: false,
      showTopicOfDiscussion: false,
      documentForPreviewName: "",
      isModalLGSize: false,
      showMyProfileModal: false,
      modalChild: null,
      isLoading: false,
      signalCombo: false,
      activeTab: "topics",
    };
  }

  componentDidMount() {
    trackPromise(
      this.props
        .getAllFiledsOfInterest()
        .then(this.props.getInstitutions())
    ).finally(() => this.setState({ isLoading: false }));
  }


  setShowMyProfileModal = (value, instruction, showLarge, isUserAdmin) => {
    if(value){
      if(instruction === "TopicsOfDiscussion" || instruction=="Notifications" ){
        if(instruction === "TopicsOfDiscussion" && isUserAdmin) {
          const searchParams = {
            start: 0,
            size:1000,
            filters: [{ id: "endProcess", value: false }, {id:"isJLS", value:this.props.jls},{id:"documentType",value:"Остало"}],
            globalFilter: "",
            sorting: [{ id: "creationDate", desc: true }],
          };
          this.props.getOtherTopicOfDiscussionsPost(searchParams);
        }
        this.props.getAllTopicsWithUserActivity();
        this.props.getAllTopicsWithUserActivityAndNotification();
        this.props.getTopicsOfDiscussionByUserId(this.props.userId);
        
      }
      if (instruction === "Notifications")
        this.props.getNotificationsCounters();
    }
    else{
      if(instruction == ""){
        this.props.getNotificationByTopicOfDiscussionId(0);
        this.props.getNotificationsByFieldOfInterest(0);
        this.props.getNotificationsByInstitution(0);
      }
    }
    this.setState({
      showMyProfileModal: value,
      modalChild: instruction,
      isModalLGSize: showLarge,
    });
  };

  showModalForAddTopicOfDiscussion = (e) => {
    this.setState({
      showTopicOfDiscussion: true,
    });
  };

  handleLoginClick() {
    AuthService.login();
  }

  handleLogoutClick() {
    AuthService.logout();
  }

  handleZaboraviMeClick() {
    const action = () => {
      try {
        this.props.forgetMe(this.props.userId).then(() => {
          NotificationManager.success(
            this.props.dictionary["menu.Profil.UspesnoZaboravljen"],
            this.props.dictionary["menu.Profil.Anonimizacija"],
            3000
          );
          this.handleLogoutClick();
        });
      } catch (error) {
        NotificationManager.error(
          this.props.dictionary["menu.Profil.GreskaZaboraviMe"],
          this.props.dictionary["menu.Profil.Anonimizacija"],
          3000
        );
      }
    };
    confirmDialog(
      this.props.dictionary["menu.Profil.ZaboraviMeUpozorenje"],
      action,
      this.props.dictionary["menu.Profil.ZelimDaBudemAnonimiziran"]
    );
  }

  showModalFieldsAndInstitution = (e) => {
    this.setState({
      showModalFieldsAndInstitution: true,
    });
  };

  showModalFieldsOfInterest = (e) => {
    this.setState({
      showModalFieldsOfInterest: true,
    });
  };

  showModalInstitutionsOfInterest = (e) => {
    this.setState({
      showModalInstitutionsOfInterest: true,
    });
  };

  showmodalTopicOfDiscussion = (e) => {
    this.props.getAllTopicsWithUserActivity();
    this.props.getAllTopicsWithUserActivityAndNotification();
    this.props.getTopicsOfDiscussionByUserId(this.props.userId);
    this.setState({
      showmodalTopicOfDiscussion: true,
    });
  };


  exportRawData = (e) => {
    this.setState({ isLoading: true });
    trackPromise(this.props.exportRawData(this.props.dictionary["menu.IzvozPodatakaFileName"]))
    .then(()=> {
      NotificationManager.success(this.props.dictionary["menu.UspesanIzvozPodataka"],this.props.dictionary["universal.Uspesno"])
    })
    .catch(() => {
      NotificationManager.error(this.props.dictionary["menu.NeuspesanIzvozPodataka"],this.props.dictionary["universal.GreskaUObradi"])
    })
    .finally(() => {
      this.setState({ isLoading: false });
    });
  };

  showModalForDocumentPreviewHandler = (documentForPreview) => {
    switch (documentForPreview) {
      case headerModalName.MANUAL:
        this.getManualNameHandler(this.props.authRole);
        this.setState({
          showModalForDocumentPreview: true,
          isModalLGSize: true,
        });
        break;
      case headerModalName.ROAD_OF_LAW:
        this.setState({
          documentForPreviewName: headerDocumentTitles.ROAD_OF_LAW,
          showModalForDocumentPreview: true,
          isModalLGSize: false,
        });
        break;
    }
  };

  handlePreviewClose = () => {
    this.setState({
      showModalFieldsAndInstitution: false,
      showModalFieldsOfInterest: false,
      showModalInstitutionsOfInterest: false,
      showModalForDocumentPreview: false,
      documentForPreviewName: "",
      isModalLGSize: false,
      showTopicOfDiscussion: false,
      showMyProfileModal:false,
    });
    this.props.getNotificationByTopicOfDiscussionId(0);
  };

  getManualNameHandler = (UserRole) => {
    const userRole = parseInt(UserRole);
    let documentName = "";
    switch (userRole) {
      case roleToIdMap.admin:
        documentName = headerDocumentTitles.ROLE_ADMIN;
        break;
      case roleToIdMap.ogpAdmin:
        documentName = headerDocumentTitles.ROLE_ADMIN_OGP;
        break;
      case roleToIdMap.moderator:
        documentName = headerDocumentTitles.ROLE_MODERATOR;
        break;
      case roleToIdMap.evaluator:
        documentName = headerDocumentTitles.ROLE_EVALUATOR;
        break;
      case roleToIdMap.citizen:
        documentName = headerDocumentTitles.ROLE_CITIZEN;
        break;
      default:
        documentName = headerDocumentTitles.ROLE_CITIZEN;
    }
    this.setState({ documentForPreviewName: documentName });
  };
  handleUlClick = () => {
    const mojProfil = document.getElementById('mojProfil');
    if(mojProfil.classList.contains('open')){
      mojProfil.classList.remove('open');
    }
  }

  handleBlur = (e) => {
    const clickedElement = e.relatedTarget;
    const mojProfil = document.getElementById('mojProfil');
    if (mojProfil.classList.contains('open') && (!clickedElement || !clickedElement.classList.contains('my-egov-link'))) {
      mojProfil.classList.remove('open');
    }
  }

  render() {
    const { isLoading } = this.state;
    let isUserLoggedIn = Object.keys(this.props.authUser).length != 0;
    let isUserCitizen = this.props.authRole == roleToIdMap.citizen;
    let isUserModerator = this.props.authRole == roleToIdMap.moderator;
    let isUserEvaluator = this.props.authRole == roleToIdMap.evaluator;
    let isUserAdmin = this.props.authRole == roleToIdMap.admin;
    let userhasSeverallRoles = this.props.userRoles.length > 1;

    const secondHeaderLinks = (
      <ul className="textShadow">
        {
          isUserLoggedIn && <li>
          <Link to="/dashboard">
            {this.props.dictionary["menu.Procesi"]}
          </Link>
        </li>
        }
        {authorizationService.canAccessStartOfANewProcess(
          this.props.authRole
        ) ? (
          <li>
            <Link to="#" onClick={(e) => {e.preventDefault(); this.showModalForAddTopicOfDiscussion();}}>
              {this.props.dictionary["menu.ZapocniNoviProces"]}
            </Link>
          </li>
        ) : (
          ""
        )}
        {/* Ima rolu admina, super admin je, za RS nije jls ni small ni superAdmin i institucija mu ima flag superAdmin ili za JLS ima flag jlsSuperAdmin */}
        {(authorizationService.canAccessAdminPanel(this.props.authRole) && ((this.props.jlsSuperAdmin ==false && this.props.jlsSmallAdmin==false && this.props.rsSuperAdmin==true) || this.props.jlsSuperAdmin==true)) ||
        authorizationService.canAccessOGPPageSpecificAreas(
          this.props.authRole
        ) ? (
          <li className="has-child">
            <Link to="#">{this.props.dictionary["menu.Sifarnici"]}</Link>
            {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
              <ul>
                <li>
                  <Link to="/fieldOfInterestList">
                    {this.props.dictionary["menu.Sifarnici.Oblasti"]}
                  </Link>
                </li>
                <li>
                  <Link to="/institutionList">
                    {this.props.dictionary["menu.Sifarnici.Institucije"]}
                  </Link>
                </li>
                {this.props.jls == false ? (
                <ul>
                <li>
                  <Link to="/userOrganizationCategoryList">
                    {
                      this.props.dictionary[
                        "menu.Sifarnici.KategorijeOrganizacije"
                      ]
                    }
                  </Link>
                </li>
                <li>
                  <Link to="/dictionaryUtils">
                    {this.props.dictionary["menu.Sifarnici.RecnikPojmova"]}
                  </Link>
                </li>
                </ul>
                ) : ""}
              </ul>
            ) : (
              <ul>
                <li>
                  <Link to="/ogpFieldOfInterestList">
                    {
                      this.props.dictionary[
                        "menu.Sifarnici.POUOblasti"
                      ]
                    }
                  </Link>
                </li>
              </ul>
            )}
          </li>
        ) : (
          ""
        )}
        {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
          <li className="has-child">
              <Link to="#">
                {this.props.dictionary["menu.AdminPanel"]}
              </Link>
            <ul>
              <li>
                <Link to="/userList">
                  {this.props.dictionary["menu.Korisnici"]}
                </Link>
              </li>
              <li>
                <Link to="/errorList">
                  {this.props.dictionary["menu.AdminPanel.Greske"]}
                </Link>
              </li>
            </ul>
          </li>
        ) : (
          ""
        )}
        {authorizationService.canAccessSuggestionButton(
          this.props.authRole
        ) && (
          <li>
            <Link to={"/moderatorResourcesList"}>
              {this.props.dictionary["menu.ResursiModeratora"]}
            </Link>
          </li>
        )}
        {authorizationService.canAccessSuggestionButton(
          this.props.authRole
        ) && (
          <li>
            <Link to={"/indicatorsPage"}>
              {this.props.dictionary["menu.Indikatori"]}
            </Link>
          </li>
        )}
        {authorizationService.canAccessExportRawDataButton(
          this.props.authRole
        ) && (
          <li>
            <Link onClick={() => this.exportRawData()} to="#">
              {this.props.dictionary["menu.Izvoz"]}
            </Link>
          </li>
        )}
      </ul>
    );
    let welcomePage = document.getElementById("welcomePage");

    const signInOffset = isUserLoggedIn ? "" : "signIn";
    const myEgovShow = isUserLoggedIn ? "my-egov" : "my-egov d-none";
    const signInBarShow =
      !isUserLoggedIn && welcomePage != null
        ? "signin-bar d-none"
        : "signin-bar";
    let headerMenu = (
      <header className={`${signInOffset} `}>
        <div className="head-sections">
          <div className="top-bar">
            <ul>
              <li>
                <Link to="/putusvajanjaakta">
                  {this.props.dictionary["menu.PutUsvajanjaAkta"]}
                </Link>
              </li>
              <li>
                <Link
                  onClick={() =>
                    this.showModalForDocumentPreviewHandler(
                      headerModalName.MANUAL
                    )
                  }
                  to="#"
                >
                  {this.props.dictionary["menu.Uputstvo"]}
                </Link>
              </li>
              <li>
                <Link
                  to="https://rsjp.gov.rs/cir/inicijative-privrede-i-gradjana/"
                  target={"_blank"}
                  onClick={(event) => {
                    event.preventDefault();
                    window.open(
                      "https://rsjp.gov.rs/cir/inicijative-privrede-i-gradjana/"
                    );
                  }}
                >
                  {this.props.dictionary["menu.InicijativePrivredeIGradjana"]}
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to={`/ogpPage/1`}>
                  {this.props.dictionary["menu.POU"]}
                </Link>
              </li>
            </ul>
          </div>
          <div className="mid-bar"> 
            <div>
              <h1>
                <Link to={isUserLoggedIn ? "/dashboard" : "/"}>
                  {this.props.dictionary["menu.Logo"]}
                </Link>
              </h1>
              <div className="nav">
                <nav>{secondHeaderLinks}</nav>
              </div>
            </div>
            <div>
              <a href="" className="mobile-handle"></a>
              {!isUserLoggedIn && (
                <div className={`${signInBarShow} `}>
                  <h6 className="signin-header m-2">
                    {
                      this.props.dictionary[
                        "menu.LandingPage.PrijaviSeUpozorenje"
                      ]
                    }
                  </h6>
                  <CreateButtonModal
                    onClickHandler={this.handleLoginClick}
                    label={this.props.dictionary["menu.LandingPage.PrijaviSe"]}
                    className="signin-notification"
                    buttonClass="signin-button create-button"
                  />
                </div>
              )}
              <div id='mojProfil' className={`${myEgovShow} `}  onBlur={(e) => this.handleBlur(e)}>
                <a  href="">{this.props.dictionary["menu.Profil.MojProfil"]}</a>
                {isUserLoggedIn && (
                  <ul id='mojProfilUl' onClick={() => this.handleUlClick()}>
                    {this.props.userRoles.length>0 && this.props.authRole>0 ? (
                      <li
                      style={{
                        paddingLeft: "30px",
                      }}
                      >
                      {this.props.dictionary["userList.column.uloga"]}{": "}{this.props.userRoles.filter(x=>x["role"]["id"]==this.props.authRole)[0]["role"]["roleName"]}             
                      </li>
                    ) : (
                      ""
                    )}
                    {isUserLoggedIn && userhasSeverallRoles ? (
                      <li>
                        <Link to="/signin-oidc" className="my-egov-link">
                          {this.props.dictionary["menu.Profil.PromeniUlogu"]}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {isUserCitizen ? (
                      <li>
                        <Link
                          to="#"
                          className="my-egov-link"
                          onClick={(e) => {
                            this.setShowMyProfileModal(
                              true,
                              "Organizations",
                              true
                            );
                          }}
                        >
                          {this.props.dictionary["menu.Profil.Organizacije"]}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {isUserLoggedIn && isUserCitizen ? (
                      <li>
                        <Link
                          onClick={(e) => {
                            this.showModalFieldsOfInterest();
                          }}
                          className="my-egov-link"
                          to="#"
                        >
                          <label className="long-string mt-2">
                            {
                              this.props.dictionary[
                                "menu.Profil.OblastiOdInteresovanja"
                              ]
                            }
                          </label>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {isUserLoggedIn  && isUserCitizen ? (
                      <li>
                        <Link
                          onClick={(e) => {
                            this.showModalInstitutionsOfInterest();
                          }}
                          className="my-egov-link"
                          to="#"
                        >
                          <label className="long-string mt-2">
                            {this.props.dictionary["menu.Profil.Institucije"]}
                          </label>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {isUserLoggedIn && (isUserCitizen 
                    //ZAKOMENTARISANO ZBOG PRIVREMENOG UKLANJANJA PROCESI-OSTALO IZ MOG PROFILA
                    // || isUserAdmin
                    ) ? (
                      <li>
                        <Link
                          onClick={(e) => {
                            this.setShowMyProfileModal(
                              true,
                              "TopicsOfDiscussion",
                              false,
                              isUserAdmin
                            );
                          }}
                          className="my-egov-link"
                          to="#"
                        >
                          <label className="long-string mt-2">
                            {isUserCitizen ? this.props.dictionary["menu.Profil.Teme"]
                            : this.props.dictionary["menu.Profil.ProcesiOstalo"]
                            }
                          </label>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {isUserLoggedIn && (isUserCitizen || isUserModerator) ? (
                      <li>
                        <Link
                          onClick={(e) => {
                            this.setShowMyProfileModal(true, "Notifications");
                          }}
                          className="my-egov-link"
                          to="#"
                        >
                          <label className="long-string mt-2">
                            {this.props.dictionary["menu.Profil.Obavestenja"]}
                          </label>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {isUserLoggedIn && isUserCitizen ? (
                      <li>
                        <Link
                          onClick={(e) => {
                            this.setShowMyProfileModal(true, "Settings");
                          }}
                          className="my-egov-link"
                          to="#"
                        >
                          <label className="long-string mt-2">
                            {this.props.dictionary["menu.Profil.Podesavanja"]}
                          </label>
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {isUserLoggedIn && isUserCitizen ? (
                      <li>
                        <Link
                          to="#"
                          className="my-egov-link"
                          onClick={(e) => {
                            this.handleZaboraviMeClick();
                          }}
                        >
                          {this.props.dictionary["menu.Profil.ZaboraviMe"]}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {!isUserLoggedIn ? (
                      <li>
                        <Link
                          className="my-egov-link"
                          onClick={this.handleLoginClick}
                          to="#"
                        >
                          {this.props.dictionary["menu.PrijaviSe"]}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                    {isUserLoggedIn ? (
                      <li>
                        <Link
                          className="my-egov-link"
                          onClick={this.handleLogoutClick}
                          to="#"
                        >
                          {this.props.dictionary["menu.Profil.OdjaviSe"]}
                        </Link>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="bot-bar"></div>
        </div>
        {/* <div className={`container col-md-11 m-auto`}>
          <div className="head-tabs"></div>
        </div> */}
        {/* {!isUserLoggedIn && (
          <div className="signin-bar">
            <h6 className="signin-header m-2">
              {this.props.dictionary["menu.LandingPage.PrijaviSeUpozorenje"]}
            </h6>
            <CreateButtonModal
              onClickHandler={this.handleLoginClick}
              label={this.props.dictionary["menu.LandingPage.PrijaviSe"]}
              className="signin-notification"
              buttonClass="signin-button create-button"
            />
          </div>
        )} */}
      </header>
    );
    return (
      <Fragment>
        {isLoading && <LoadingIndicatorOverlay />}
        {headerMenu}
        {this.state.showModalFieldsOfInterest && (
          <ModalForChoosingFieldsOfInterest
            handleClose={this.handlePreviewClose}
            show={this.state.showModalFieldsOfInterest}
          />
        )}
        {this.state.showModalInstitutionsOfInterest && (
            <ModalForChoosingInstitutionsOfInterest
              handleClose={this.handlePreviewClose}
              show={this.state.showModalInstitutionsOfInterest}
            />
        )}
        {this.state.showModalForDocumentPreview && (
          <ModalForDocumentPreview
            show={this.state.showModalForDocumentPreview}
            documentName={this.state.documentForPreviewName}
            controllerReference={"document"}
            handleClose={this.handlePreviewClose}
            largeModal={this.state.isModalLGSize}
            notWideModal={true}
          />
        )}
        {this.state.showMyProfileModal && this.state.isModalLGSize ? (
          <>
            {this.state.modalChild === "Organizations" && (
              <UserOrganizationList 
                show={this.state.showMyProfileModal}
                handleClose={() => this.setShowMyProfileModal(false, "")}
              />
            )}
          </>
        ) : (  
            <>
              {this.state.modalChild === "Institutions" && (
                <UserOrganizationList 
                  show={this.state.showMyProfileModal}
                  handleClose={() => this.setShowMyProfileModal(false, "")}
                />
              )}
              {this.state.modalChild === "TopicsOfDiscussion" && (
                <ModalForTopicOfDiscussionList
                  show={this.state.showMyProfileModal}
                  handleClose={() => this.setShowMyProfileModal(false, "")}
                  topicOfDiscussionList={this.props.topicOfDiscussionList}
                  topicOfDiscussion={this.props.topicOfDiscussion}
                  isUserAdmin={isUserAdmin}
                />
              )}
              {this.state.modalChild === "Notifications" && (
                 <ModalForNotificationList
                  show={this.state.showMyProfileModal}
                  handleClose={() => this.setShowMyProfileModal(false, "")}
                  userId={this.props.userId}
                  authRole={this.props.authRole}
                 />        
              )}
              {this.state.modalChild === "Settings" && <UpdateContactData 
                show={this.state.showMyProfileModal}
                handleClose={() => this.setShowMyProfileModal(false, "")}
              />}
            </>
        )}
        {(authorizationService.canAccessStartOfANewProcess(
          this.props.authRole
        ) && this.state.showTopicOfDiscussion) && (
          <ModalForAddTopicOfDiscussion
          show={this.state.showTopicOfDiscussion}
          handleClose={this.handlePreviewClose}
          history={this.props.history}
        />
        )}
      </Fragment>
    );
  }
}

Header.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userRoles: state.security.userRoles,
  authRole: state.security.authRole,
  userId: state.security.userId,
  authUser: state.security.authUser,
  jls: state.security.jls,
  jlsSuperAdmin: state.security.jlsSuperAdmin,
  jlsSmallAdmin: state.security.jlsSmallAdmin,
  rsSuperAdmin: state.security.rsSuperAdmin,
  error: state.errors,
  dictionary: state.startUp.dictionary,
  topicOfDiscussion: state.topicOfDiscussion,
  notification: state.notification,
  topicOfDiscussionList: state?.topicOfDiscussion?.otherTopicOfDiscussionListFilterable?.content ?? [],
});

export default connect(mapStateToProps, {
  logout,
  forgetMe,
  exportRawData,
  getAllFiledsOfInterest,
  getInstitutions,
  getAllTopicsWithUserActivity,
  getAllTopicsWithUserActivityAndNotification,
  getTopicsOfDiscussionByUserId,
  getNotificationsCounters,
  getNotifications,
  getNotificationByTopicOfDiscussionId,
  getNotificationsByFieldOfInterest,
  getNotificationsByInstitution,
  getOtherTopicOfDiscussionsPost
})(Header);
