import React, { Component } from "react";
import { connect } from 'react-redux';
import UserOrganizationRow from "./UserOrganizationRow";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";

class UserOrganizationTable extends Component {
  render() {
    var userOrganizationList = this.props.userOrganizationList.map(
      (userOrganization) => (
        <UserOrganizationRow
          key={userOrganization.id}
          userOrganization={userOrganization}
          isAccessedFromAdminPanel={this.props.isAccessedFromAdminPanel}
        />
      )
    );


    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-sm table-striped mt-3">
          <Thead>
            <Tr className="">
              <Th className="boldText emphesized-label-font text-center pb-2">
               {this.props.dictionary["userOrganizationTable.nazivOrganizacije"]}
              </Th>
              <Th className="text-center pb-2">{this.props.dictionary["userOrganizationTable.kategorijaOrganizacije"]}</Th>
              <Th className="text-center pb-2">{this.props.dictionary["userOrganizationTable.izvrsilacPromene"]}</Th>
              <Th width="10%" className="text-center pb-2">
               {this.props.dictionary["userOrganizationTable.izmena"]}
              </Th>

              <Th width="10%" className="text-center pb-2">
               {this.props.dictionary["userOrganizationTable.brisanje"]}
              </Th>
            </Tr>
          </Thead>
          <Tbody>{userOrganizationList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps,{}) (UserOrganizationTable);
