import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class RoadOfActAdoption extends Component {
  render() {
    const link1 = this.props.dictionary["roadOfActAdoption.url.zakonOPlanskomSistemu"];
    const link2 =this.props.dictionary["roadOfActAdoption.url.uredba"];
    const link3 =this.props.dictionary["roadOfActAdoption.url.zakonODrzavnojUpravi"];
    const link4 =this.props.dictionary["roadOfActAdoption.url.pravilnik"];
    const link5 =this.props.dictionary["roadOfActAdoption.url.poslovnikVlade"];

    return (
      <Container
        fluid
        className="col-md-12 component-wrapper-fade-in-large-list justify-content-center mb-5"
      >
        <div id="topic-header-row" className="row container-flow mb-5">
          <div className="col-md-12 m-auto">
            <div className="col-md-11 m-auto">
              <h1>{this.props.dictionary["roadOfActAdoption.naslov"]}</h1>
            </div>
          </div>
        </div>
        <div className="col-md-11 m-auto">
          <p className="text-justify mt-4">
          {this.props.dictionary["roadOfActAdoption.sadrzaj"]}
          </p>
          <p className="text-justify">
            <strong className="title-text-color">{this.props.dictionary["roadOfActAdoption.konsultacije"]}</strong>
            {this.props.dictionary["roadOfActAdoption.sadrzajKonsultacije"]}
          </p>
          <p className="text-justify">
            <strong className="title-text-color">{this.props.dictionary["roadOfActAdoption.javnaRasprava"]}</strong>{' '}
            {this.props.dictionary["roadOfActAdoption.sadrzajJavnaRasprava"]}
           </p>
          <p>
            <strong>
            {this.props.dictionary["roadOfActAdoption.istaknutoI"]}{' '}
              <u>
                <em>
                {this.props.dictionary["roadOfActAdoption.podvucenoI"]}
                </em>
              </u>
              {this.props.dictionary["roadOfActAdoption.istaknutoII"]}{' '}
              <u>
                <em>
                {this.props.dictionary["roadOfActAdoption.podvucenoII"]}
                </em>
              </u>
            </strong>
          </p>
          <p className="text-justify">
          {this.props.dictionary["roadOfActAdoption.portal"]}
          </p>
          <hr className="mt-3" />
          <p className="text-justify">**</p>
          <p className="text-justify">
            <u className="row col-md-12 mb-2">
              <Link
                href={link1}
                onClick={(event) => {
                  event.preventDefault();
                  window.open(link1);
                }}
                target="_blank"
                to="#"
              >
                {this.props.dictionary["roadOfActAdoption.zakonOPlanskomSistemu"]}
              </Link>
            </u>

            <u className="row col-md-12 mb-2">
              <Link
                href={link2}
                onClick={(event) => {
                  event.preventDefault();
                  window.open(link2);
                }}
                target="_blank"
                to="#"
              >
                {this.props.dictionary["roadOfActAdoption.uredba"]}
              </Link>
            </u>

            <u className="row col-md-12 mb-2">
              <Link
                href={link3}
                onClick={(event) => {
                  event.preventDefault();
                  window.open(link3);
                }}
                target="_blank"
                to="#"
              >
                {this.props.dictionary["roadOfActAdoption.zakonODrzavnojUpravi"]}
              </Link>
            </u>

            <u className="row col-md-12 mb-2">
              <Link
                href={link4}
                onClick={(event) => {
                  event.preventDefault();
                  window.open(link4);
                }}
                target="_blank"
                to="#"
              >
                {this.props.dictionary["roadOfActAdoption.pravilnik"]}
              </Link>
            </u>
            <u className="row col-md-12 mb-2">
              <Link
                href={link5}
                onClick={(event) => {
                  event.preventDefault();
                  window.open(link5);
                }}
                target="_blank"
                to="#"
              >
                {this.props.dictionary["roadOfActAdoption.poslovnikVlade"]}
              </Link>
            </u>
          </p>
        </div>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {})(RoadOfActAdoption);
