import axios from "axios";

import {
    GET_RELATED_TOPIC_OF_DISCUSSION_LIST,
    GET_ERRORS,
} from "./types";

export const getRelatedTopicOfDiscussionList = (topicOfDiscussionId) => async (
    dispatch
  ) => {
    try {
        const res = await axios.get(
            `/api/topicOfDiscussionRelation/findAllByTopicOfDiscussionId/${topicOfDiscussionId}`
          );
        dispatch({
            type: GET_RELATED_TOPIC_OF_DISCUSSION_LIST,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
    }
  };

  export const addRelatedTopicsOfDiscussion = (data) => async (
    dispatch
  ) => {
    try {
        const res = await axios.post(
            `/api/topicOfDiscussionRelation/addRelatedTopicsOfDiscussion`, data
          );
        dispatch({
            type: GET_RELATED_TOPIC_OF_DISCUSSION_LIST,
            payload: res.data,
          });
    }
    catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          });
    }
  };









