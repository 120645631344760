import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createUserOrganization } from "../../actions/userOrganizationActions";
import Input from "../Reusable/Input";
import { setMessage } from "../../utils";
import { getUserOrganizationCategoryList } from "../../actions/userOrganizationCategoryActions";
import ComboReusable from "../Reusable/ComboReusable";
import Modal from 'react-bootstrap/Modal';
import {
  NotificationManager,
} from 'react-notifications';
import { trackPromise } from 'react-promise-tracker';

class AddUserOrganization extends Component {
  constructor() {
    super();
    this.state = {
      organizationName: "",
      userOrganizationCategory: {
        id: 0,
      },
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleComboBoxChange = this.handleComboBoxChange.bind(this);
  }
  componentDidMount() {
    this.props.getUserOrganizationCategoryList();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleComboBoxChange = (object, objectId) => {
    return (e) => {
      this.setState({
        [object]: { [objectId]: e.target.value },
      });
    };
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleValidation = () => {
    let error = false;

    if (this.state.organizationName == "") {
      this.props.setMessage(
         this.props.dictionary["addUserOrganization.Validation.Naziv"],
        "#organizationName",
        "#msg1"
      );
      error = true;
    }

    if (this.state.userOrganizationCategory.id == 0) {
      this.props.setMessage(
        this.props.dictionary["addUserOrganization.Validation.Kategorija"],
        "#userOrganizationCategoryCb",
        "#msg2"
      );
      error = true;
    }

    return error;
  };

  onSubmit(e) {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newUserOrganization = {
      organizationName: this.state.organizationName,
      userOrganizationCategory: {
        id: this.state.userOrganizationCategory.id,
      },
    };
    
    
    trackPromise(this.props.createUserOrganization(newUserOrganization, this.props.history))
    .then( () => {
      NotificationManager.success(this.props.dictionary["AddUserOrganization.UspesanUnos"],this.props.dictionary["universal.Uspesno"], 5000);
    })
    .catch( () => {
      NotificationManager.error(this.props.dictionary["AddUserOrganization.NeuspesanUnos"], this.props.dictionary["universal.GreskaUObradi"], 5000);
    });
  }

  render() {
    const { errors } = this.state;
    let { userOrganizationCategoryList } = this.props.userOrganizationCategory;

    if (this.props.authRole == 3) {
      userOrganizationCategoryList = userOrganizationCategoryList.filter(
        (uo) => uo.userOrganizationCategoryName !== "Орган државне управе"
      );
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="modalForAddUserOrganization"
      >
        <Modal.Header closeButton>
            <Modal.Title>
                {this.props.dictionary["addUserOrganization.naslov"]}
            </Modal.Title>
        </Modal.Header> 
        <Modal.Body>  
        <div>
          <div className="register">
            <div className="container">
              <div className="row">
                <div className="m-auto">
                  <form onSubmit={this.onSubmit} className="col-md-12">
                    <div className="form-group  mb-3">
                      <label className="labelText textShadow">
                      {this.props.dictionary["addUserOrganization.nazivOrganizacije"]}
                      </label>
                      <span className="redAsterisk"> *</span>
                      <Input
                        type="text"
                        id="organizationName"
                        name="organizationName"
                        placeholder={this.props.dictionary["addUserOrganization.placeholder.nazivOrganizacije"]}
                        value={this.state.organizationName}
                        onChange={this.onChange}
                        errors={errors.organizationName}
                      />
                      <div id="msg1" />
                    </div>
                    <div className="from-group">
                      <label className="labelText textShadow">
                      {this.props.dictionary["addUserOrganization.kategorija"]}<span className="redAsterisk"> *</span>
                      </label>
                      <ComboReusable
                        id="userOrganizationCategoryCb"
                        namePart={this.props.dictionary["addUserOrganization.namePart.kategorija"]}
                        items={userOrganizationCategoryList.map((objekat) => (
                          <option key={objekat.id} value={objekat.id}>
                            {objekat.userOrganizationCategoryName}
                          </option>
                        ))}
                        onChange={this.handleComboBoxChange(
                          "userOrganizationCategory",
                          "id"
                        )}
                      />
                      <div id="msg2" />
                    </div>
                    <div className="row mt-5 mb-2 justify-content-center">
                      <button
                        id="subscribeButton"
                        className="col-md-6 me-0"
                      >
                        {this.props.dictionary["addUserOrganization.sacuvaj"]}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      </Modal>
    );
  }
}

AddUserOrganization.propTypes = {
  createUserOrganization: PropTypes.func.isRequired,
  userOrganizationCategory: PropTypes.object.isRequired,
  getUserOrganizationCategoryList: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userOrganization: state.userOrganization.userOrganization,
  userOrganizationCategory: state.userOrganizationCategory,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  createUserOrganization,
  getUserOrganizationCategoryList,
  setMessage,
})(AddUserOrganization);
