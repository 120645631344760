import axios from 'axios';

import {
  GET_ERRORS,
  GET_USER,
  GET_USERS,
  DEACTIVATE_USER,
  SET_USER_ROLES,
  GET_USER_AFTER_LOGIN,
  GET_ALL_MODERATORS,
  ACTIVATE_USER,
  SET_USER_LIST_TABLE_FILTER
} from './types';

export const createUser = (user) => async (dispatch) => {
  try {
    await axios.post('/api/user/create', user);
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateUser = (user, history) => async (dispatch) => {
  try {
    await axios.put('/api/user/update', user);
    //history.push('/userList');
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateContactData = (user) => async (dispatch) => {
  try {
    const res = await axios.put('/api/user/updateContactData', user);
    dispatch({
      type: GET_USER,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getUsers = () => async (dispatch) => {
  const res = await axios.get('/api/user/findAll');
  dispatch({
    type: GET_USERS,
    payload: res.data,
  });
};

export const getUsersPost = (searchParams) => async (dispatch) => {
  try {
    const res = await axios.post('/api/user/findAll', searchParams);
    dispatch({
      type: GET_USERS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response?.data,
    });
  }
};

export const getUser = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/user/findById/${id}`);
    dispatch({
      type: GET_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const deactivateUser = (id) => async (dispatch) => {
  const res = await axios.patch(`/api/user/deactivate/${id}`);
  // dispatch({
  //   type: DEACTIVATE_USER,
  //   payload: res.data,
  // });
};

export const getUserRolesAndOptionallyCreateCitizen = (citizen) => async (
  dispatch
) => {
  const res = await axios.put(
    `/api/user/findByJmbgAndOptionallyCreateCitizen1`,
    citizen
  );
  dispatch({
    type: SET_USER_ROLES,
    payload: res.data,
  });
};

export const getUserAfterLogin = (jmbg, roleId) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/api/user/findByJmbgAndRoleId/${jmbg}/${roleId}`
    );
    dispatch({
      type: GET_USER_AFTER_LOGIN,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getAllModerators = () => async (dispatch) => {
  const res = await axios.get('/api/user/findAllModerators');
  dispatch({
    type: GET_ALL_MODERATORS,
    payload: res.data,
  });
};

export const activateUser = (id) => async (dispatch) => {
  const res = await axios.patch(`/api/user/activate/${id}`);
  // dispatch({
  //   type: ACTIVATE_USER,
  //   payload: res.data,
  // });
};

export const downloadUsers =
  (documentName) => async (dispatch) => {
    axios({
      url: `/api/user/download-users`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', documentName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
    });
  };

  export const setUserListTableFilter =
  (searchParams) => async (dispatch) => {
    try {
      dispatch({
        type: SET_USER_LIST_TABLE_FILTER,
        payload: searchParams,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response?.data,
      });
    }
  };

