import axios from "axios";
import {
  GET_ERRORS,
  GET_MAIL_SETTINGS,
  GET_MAIL_SETTINGS_LIST,
  DELETE_MAIL_SETTINGS,
} from "./types";

export const createMailSettings = (mailSettings, history) => async (
  dispatch
) => {
  try {
    await axios.post("/api/mailSettings/create", mailSettings);
    history.push("/mailSettingsList");
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const updateMailSettings = (mailSettings, history) => async (
  dispatch
) => {
  try {
    await axios.put("/api/mailSettings/update", mailSettings);
    history.push("/mailSettingsList");
    dispatch({
      type: GET_ERRORS,
      payload: {},
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getMailSettingsList = () => async (dispatch) => {
  const res = await axios.get("/api/mailSettings/findAll");
  dispatch({
    type: GET_MAIL_SETTINGS_LIST,
    payload: res.data,
  });
};

export const getMailSettings = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/mailSettings/findById/${id}`);
    dispatch({
      type: GET_MAIL_SETTINGS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const deleteMailSettings = (id) => async (dispatch) => {
  await axios.delete(`/api/mailSettings/delete/${id}`);
  dispatch({
    type: DELETE_MAIL_SETTINGS,
    payload: id,
  });
};
