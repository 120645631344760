import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createAd, getAd, updateAd } from '../../actions/adActions';
import TextArea from '../Reusable/TextArea';
import { setMessage } from '../../utils';
import { confirmDialog } from '../Reusable/ConfirmDialog';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Tooltip } from 'react-tooltip';
import { Link } from 'react-router-dom';

class AddAd extends Component {
  constructor() {
    super();
    this.state = {
      ad: null,
      errors: {},
      active: true,
      startDate: null,
      endDate: null,
      requestIssues: false,
    };
    this.handleTextAreaExpand = this.handleTextAreaExpand.bind(this);
    this.handleTextAreaShrink = this.handleTextAreaShrink.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
  }

  componentDidMount() {
    this.props.getAd(this.props.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    const { ad, active, startDate, endDate, requestIssues } = nextProps.ad;
    if (!active) {
      this.setState({
        ad: null,
        startDate: null,
        endDate: null,
        requestIssues: false,
      });
    } else {
      this.setState({
        ad,
        active,
        startDate: startDate ? moment(startDate).toDate() : '',
        endDate: endDate ? moment(endDate).toDate() : '',
        requestIssues,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  handleTextAreaExpand = (e) => {
    e.target.rows = 6;
  };

  handleTextAreaShrink = (e) => {
    e.target.rows = 3;
  };

  handleValidation = () => {
    let error = false;

    if (!this.state.ad) {
      this.props.setMessage(`${this.props.dictionary["AddAd.Validation.Tekst"]}`, '#ad', '#msgAd');
      error = true;
    }
    if (this.state.ad?.length > 1000) {
      this.props.setMessage(
        `${this.props.dictionary["AddAd.Validation.TekstDuzina"]}`,
        '#ad',
        '#msgAd'
      );
      error = true;
    }
    if (this.state.requestIssues) {
      if (!this.state.startDate) {
        this.props.setMessage(
          `${this.props.dictionary["AddAd.Validation.Datum"]}`,
          '#startDate',
          '#msgAd2'
        );
        error = true;
      }
      if (!this.state.endDate) {
        this.props.setMessage(`${this.props.dictionary["AddAd.Validation.Datum"]}`, '#endDate', '#msgAd2');
        error = true;
      }
    }
    return error;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newAd = {
      ad: this.state.ad,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      requestIssues: this.state.requestIssues,
      active: this.state.active,
    };

    const action = () => {
      this.props
        .createAd(newAd)
        .then(this.props.handleButtonClickedChangeAd('closeAdEditForm'));
    };
    confirmDialog(`${this.props.dictionary["AddAd.SacuvajObjavu.Poruka"]}`, action, `${this.props.dictionary["AddAd.SacuvajObjavu"]}`);
  };

  handleClearForm = () => {
    const action = () => {
      const addForUpdate = {
        ...this.props.ad,
        active: false,
      };
      this.setState({
        ad: null,
        startDate: null,
        endDate: null,
        requestIssues: false,
      });

      this.props
        .updateAd(addForUpdate)
        .then(this.props.handleButtonClickedChangeAd('closeAdEditForm'));
    };
    confirmDialog(`${this.props.dictionary["AddAd.ObrisiObjavu.Poruka"]}`, action, `${this.props.dictionary["AddAd.ObrisiObjavu"]}`);
  };

  render() {
    const { errors } = this.state;

    let infoAboutCheckBox = (
      <p className="regular-font" style={{ maxWidth: '26vw' }}>
        {this.props.dictionary["tooltip.universal.infoAboutCheckBox"]}
      </p>
    );

    return (
      <form onSubmit={this.onSubmit} className="component-wrapper-fade-in">
        <div className="form-group mb-4">
          <TextArea
            type="text"
            id="ad"
            placeholder={this.props.dictionary["AddAd.Placeholder.Objava"]}
            name="ad"
            value={this.state.ad}
            onChange={this.onChange}
            errors={errors.ad}
            rows="4"
            maxlength="1000"
            onFocus={this.handleTextAreaExpand}
            onBlur={this.handleTextAreaShrink}
          />
          <div id="msgAd" />
        </div>
        <div className="row col-md-12">
          <label className="labelText textShadow mt-1">{this.props.dictionary["AddAd.Period"]}:</label>
          <div className="col-md-4">
            <DatePicker
              id="startDate"
              selected={this.state.startDate}
              onChange={this.handleStartDateChange.bind('startcreationDate')}
              className="dateInput form-control"
              dateFormat="dd.MM.yyyy"
              placeholderText={this.props.dictionary["AddAd.Placeholder.DatumOd"]}
              minDate={moment().toDate()}
            />
          </div>
          <label className="labelText textShadow mt-1"> - </label>
          <div className="col-md-4">
            <DatePicker
              id="endDate"
              selected={this.state.endDate}
              onChange={this.handleEndDateChange.bind('endDate')}
              className="dateInput form-control"
              dateFormat="dd.MM.yyyy"
              placeholderText={this.props.dictionary["AddAd.Placeholder.DatumDo"]}
              minDate={moment().toDate()}
            />
          </div>
          <Link onClick={(e) => {e.preventDefault(); this.handleClearForm();}} className="ml-auto" to="#">
            <i className="fas fa-trash-alt deleteButton"></i>
          </Link>
          <a
            data-tooltip-id="requestIssuesTip"
            className="row col-md-12 mt-3"
          >
            <label className="labelText textShadow">{this.props.dictionary["AddAd.PrikupljanjePredloga"]}:</label>
            <input
              type="checkbox"
              name="requestIssues"
              className="mt-2 filled-in col-md-1"
              checked={this.state.requestIssues}
              onChange={this.handleCheckboxChange}
            />
          </a>
          <Tooltip
            id="requestIssuesTip"
            type="info"
            effect="solid"
            place="right"
          >
            {infoAboutCheckBox}
          </Tooltip>
        </div>
        <div id="msgAd2" />
        <div className="form-group">
          <button
            type="submit"
            id="subscribeButton"
            className="col-md-2 float-right"
          >
            {this.props.dictionary["AddAd.Sacuvaj"]}
          </button>
        </div>
      </form>
    );
  }
}

AddAd.propTypes = {
  createAd: PropTypes.func.isRequired,
  getAd: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary,
  ad: state.ad.ad,
  errors: state.errors
});

export default connect(mapStateToProps, {
  setMessage,
  createAd,
  getAd,
  updateAd,
})(AddAd);
