import React, { Component } from 'react';
import SurveyAvailableAnswerRow from '../SurveyAvailableAnswers/SurveyAvailableAnswerRow';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';
import { connect } from 'react-redux';

class SurveyAvailableAnswersTable extends Component {
  render() {
    const {
      handleEdit,
      handleDelete,
      handleUpdateAnswer,
      selectedAnswer,
    } = this.props;

    let counter = 1;
    let surveyAvailableAnswersList = this.props.surveyAvailableAnswersList.map(
      (answer) => (
        <SurveyAvailableAnswerRow
          key={answer.ordinalNumber}
          answer={answer}
          counter={counter++}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleUpdateAnswer={handleUpdateAnswer}
          selectedAnswer={selectedAnswer}
        />
      )
    );

    return (
      <div className="table-responsive min-table-height component-wrapper-fade-in">
        <hr></hr>
        <Table id="example" className="table table-sm table-striped mt-2">
          <Thead>
            <Tr className="border-bottom">
              <Th
                width="5%"
                className="border-0 boldText emphesized-label-font"
              >
                {this.props.dictionary["surveyAvailableAnswersTable.rb"]}
              </Th>
              <Th className="border-0 boldText emphesized-label-font">
               {this.props.dictionary["surveyAvailableAnswersTable.odgovor"]}
              </Th>
              <Th width="9%" className="text-center border-0">
               {this.props.dictionary["surveyAvailableAnswersTable.izmena"]}
              </Th>
              <Th width="9%" className="text-center border-0">
               {this.props.dictionary["surveyAvailableAnswersTable.brisanje"]}
              </Th>
            </Tr>
          </Thead>
          <Tbody>{surveyAvailableAnswersList}</Tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps,{}) (SurveyAvailableAnswersTable);
