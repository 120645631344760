import React, { Component } from 'react';
import { saveOGPDocument } from '../../actions/ogpDocumentActions';
import { connect } from 'react-redux';
import UploadDocument
 from '../Reusable/UploadDocument';
class UploadOgpDocument extends Component {
  render() {
    return (
      <UploadDocument 
        handleUpload={this.props.saveOGPDocument}
        history={this.props.history}
      />
    );
  }
}

export default connect(null, {
  saveOGPDocument
})(UploadOgpDocument);
