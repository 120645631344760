import React from "react";
import { useSelector } from "react-redux";

import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";
import ExternalMembersRow from "./ExternalMembersRow";



const  ExternalMembersTable = (props) => {
    
    const dictionary = useSelector(state => state.startUp.dictionary);

    const tableRows = props.methodParticipants?.map((methodParticipant) => (
        <ExternalMembersRow
            key={methodParticipant.id}
            methodParticipant={methodParticipant}
            methodId={props.methodId}
        />
    ))

    return (
        <>
        { tableRows?.length > 0 ?
            <div className="table-responsive">
            <Table id="example" className="table table-md border-0">
              <Thead>
                <Tr className="border-0">
                  <Th className="border-0 boldText emphesized-label-font">
                  {dictionary["ExternalMembersRow.ImePrezime"]}
                  </Th>
                  <Th className="border-0 boldText emphesized-label-font">
                  {dictionary["ExternalMembersRow.PrijavaOrganizacije"]}
                  </Th>
                <Th className="border-0 boldText emphesized-label-font text-center">
                    {dictionary["ExternalMembersRow.Pregled"]}
                </Th>
                </Tr>
              </Thead>
              <Tbody>{tableRows}</Tbody>
            </Table>
          </div> : <p className="text-center">{dictionary["ExternalMembersTable.NemaPrijavljenih"]}</p>
        }
        </>
      
    );
  }




export default ExternalMembersTable;
