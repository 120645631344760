import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdDocumentTable from "./AdDocumentTable";
import authorizationService from "../../securityUtils/authorizationService";
import UploadAdDocument from "./UploadAdDocument";
import { getAdDocumentList } from "../../actions/adDocumentActions";

class ModalForAdDocumentPreview extends React.Component {
  componentDidMount() {
    const id = this.props.adId ? this.props.adId : 0;
    this.props.getAdDocumentList(id);
  }

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    const { adDocumentList } = this.props.adDocument;
    let isListEmpty = adDocumentList.length == 0 ? true : false;
    let isArchive = this.props.archiveSignal ? true : false;

    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
        size="xl"
        backdrop={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Body>
            <h4 className="text-center">{this.props.dictionary["ModalForAdDocumentPreview.Pregled"]}</h4>
            <hr></hr>
            {authorizationService.canAccessOGPPageSpecificAreas(this.props.authRole) &&
            isArchive == false ? (
              <UploadAdDocument
                adId={this.props.adId}
                getAdDocumentList={this.props.getAdDocumentList}
              />
            ) : (
              ""
            )}
            {isListEmpty ? (
              <p className="faded-text text-center large-font">{this.props.dictionary["ModalForAdDocumentPreview.NemaPriloga"]}</p>
            ) : (
              <AdDocumentTable
                adDocumentList={adDocumentList}
                isArchive={isArchive}
              />
            )}
          </Modal.Body>
        </Modal.Header>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  adDocument: state.adDocument,
  user: state.user,
});

export default connect(mapStateToProps, {
  getAdDocumentList,
})(ModalForAdDocumentPreview);
