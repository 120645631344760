import {
  GET_FIELD_OF_INTEREST_INSTITUTION_USER,
  GET_FIELD_OF_INTEREST_BY_USER_ID_LIST,
  GET_INSTITUTION_BY_USER_ID_LIST,
} from "../actions/types";

const initialState = {
  fieldOfInterestInstitutionUserList: [],
  fieldOfInterestByUserIdList: [],
  institutionByUserIdList: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FIELD_OF_INTEREST_INSTITUTION_USER:
      return {
        ...state,
        fieldOfInterestInstitutionUserList: action.payload,
      };
    case GET_FIELD_OF_INTEREST_BY_USER_ID_LIST:
      return {
        ...state,
        fieldOfInterestByUserIdList: action.payload,
      };
    case GET_INSTITUTION_BY_USER_ID_LIST:
      return {
        ...state,
        institutionByUserIdList: action.payload,
      };
    default:
      return state;
  }
}
