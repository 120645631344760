import React, { Component } from "react";
import FieldOfInterestRow from "./FieldOfInterestRow";
import authorizationService from "../../securityUtils/authorizationService";
import { Table, Thead, Tbody, Th, Tr } from "react-super-responsive-table";
import { connect } from "react-redux";

class FieldOfInterestTable extends Component {
  render() {
    var fieldOfInterestList = this.props.fieldOfInterestList.map(
      (fieldOfInterest) => (
        <FieldOfInterestRow
          key={fieldOfInterest.id}
          fieldOfInterest={fieldOfInterest}
          getFiledsOfInterestFunction = {this.props.getFiledsOfInterestFunction}
          showModalForUpdateFunction = {this.props.showModalForUpdateFunction}
      />
      )
    );


    return (
      <div className="table-responsive min-table-height">
        <Table id="example" className="table table-sm table-striped mt-2">
          <Thead>
            <Tr className="table-header-height">
              <Th className="boldText emphesized-label-font">{this.props.dictionary["FieldOfInterestTable.NazivOblasti"]}</Th>
              <Th className="text-center">{this.props.dictionary["FieldOfInterestTable.Status"]}</Th>
              <Th className="text-center">{this.props.dictionary["FieldOfInterestTable.Tip"]}</Th>
              {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
                <Th width="10%" className="text-center">
                  {this.props.dictionary["FieldOfInterestTable.Izmena"]}
                </Th>
              ) : (
                <Th></Th>
              )}
              {authorizationService.canAccessAdminPanel(this.props.authRole) ? (
                <Th width="10%" className="text-center">
                  {this.props.dictionary["FieldOfInterestTable.Brisanje"]}
                </Th>
              ) : (
                <Th></Th>
              )}
            </Tr>
          </Thead>
          <Tbody>{fieldOfInterestList}</Tbody>
        </Table>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps,{}) (FieldOfInterestTable);
