import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createUserOrganizationCategory } from '../../actions/userOrganizationCategoryActions';
import Input from '../../components/Reusable/Input';
import { setMessage } from '../../utils';
import { NotificationManager } from 'react-notifications';
import { trackPromise } from 'react-promise-tracker';

class AddUserOrganizationCategory extends Component {
  constructor() {
    super();
    this.state = {
      userOrganizationCategoryName: '',
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleValidation = () => {
    let error = false;

    if (this.state.userOrganizationCategoryName == '') {
      this.props.setMessage(
        this.props.dictionary["addUserOrganizationCategory.Validation.Kategorija"],
        '#userOrganizationCategoryName',
        '#msg1'
      );
      error = true;
    }

    return error;
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const newUserOrganizationCategory = {
      userOrganizationCategoryName: this.state.userOrganizationCategoryName,
    };
    trackPromise(this.props.createUserOrganizationCategory(newUserOrganizationCategory, this.props.history))
    .catch(() => {
      NotificationManager.error(this.props.dictionary["addUserOrganizationCategory.NeuspesnoDodavanje"]);
     })
   .then( () => {
    if (this.state.errors.duplicateUserOrganizationCategoryNameErrorMessage) {
      NotificationManager.error(this.state.errors.duplicateUserOrganizationCategoryNameErrorMessage)
    }
    else {
      NotificationManager.success(this.props.dictionary["addUserOrganizationCategory.UspesnoDodavanje"]);
    }
   })
  };
  goBack = () => {
    this.props.history.push('/userOrganizationCategoryList');
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="container component-wrapper-fade-in">
        <div className="row">
          <div className="col-md-6 m-auto">
            <h1 className="text-center textShadow mt-5">
             {this.props.dictionary["addUserOrganizationCategory.naslov"]}{' '}
            </h1>
            <hr />
            <form onSubmit={this.onSubmit} className="col-md-12">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="textShadow">
                  {this.props.dictionary["addUserOrganizationCategory.nazivKategorije"]}<span className="redAsterisk"> *</span>
                  </label>
                  <Input
                    type="text"
                    id="userOrganizationCategoryName"
                    placeholder={this.props.dictionary["addUserOrganizationCategory.placeholder.nazivKategorije"]}
                    name="userOrganizationCategoryName"
                    value={this.state.userOrganizationCategoryName}
                    onChange={this.onChange}
                    errors={errors.userOrganizationCategoryName}
                  />
                  <div id="msg1" />
                </div>
              </div>
              <div className="col-md-12 mt-4">
                <button id="subscribeButton" className="col-md-2 float-right">
                  {this.props.dictionary["addUserOrganizationCategory.sacuvaj"]}
                </button>
                <button
                      id="subscribeButton"
                      className="col-md-2 float-right"
                       onClick={()=>this.goBack()}
                    >
                       {this.props.dictionary["addUserOrganizationCategory.odustani"]}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

AddUserOrganizationCategory.propTypes = {
  createUserOrganizationCategory: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userOrganizationCategory:
    state.userOrganizationCategory.userOrganizationCategory,
  errors: state.errors,
  dictionary:state.startUp.dictionary,
});

export default connect(mapStateToProps, {
  createUserOrganizationCategory,
  setMessage,
})(AddUserOrganizationCategory);
