import { CLIENT_ID } from './securityUtils/oidcConfig';

export const BACKEND_MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const FRONTEND_MOMENT_DATE_FORMAT = 'DD.MM.YYYY';
export const BACKEND_MOMENT_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const FRONTEND_MOMENT_DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';

export const errorMessage = 'Ентитет је у употреби и не може бити обрисан!';

export const gaKey = 'UA-183640023-1';

export const allowedFileFormats =
  '.pdf, .png, .jpg, .gif, .xlsx, .csv, .docx, .doc, .txt';

export const rowsPerPageOptions = [10, 20, 50, 100];
export const numberOfItemsPerPage = 10;

export const authenticationAssuranceLevels = {
  min: 'AAL1',
  max: 'AAL3',
};

export const headerDocumentTitles = {
  ANONYMOUS_USER: 'AnonimniKorisnik.pdf',
  ROLE_ADMIN: 'Admin.pdf',
  ROLE_ADMIN_OGP: 'AdminOGP.pdf',
  ROLE_MODERATOR: 'Moderator.pdf',
  ROLE_EVALUATOR: 'Evaluator.pdf',
  ROLE_CITIZEN: 'Gradjanin.pdf',
  ROAD_OF_LAW: 'Put usvajanja zakona.docx',
  PRIVACY_POLICY:'PolitikaPrivatnosti.docx'
};

export const manualOptions = [
  {value:0, label:'АНОНИМНИ КОРИСНИК'},
  {value:1, label:'АДМИН'},
  {value:2, label:'МОДЕРАТОР'},
  {value:3, label:'ГРАЂАНИН'},
  {value:4, label:'ЕВАЛУАТОР'},
  {value:5, label:'ОГП АДМИН'},
  {value:6, label:'ПОЛИТИКА ПРИВАТНОСТИ'}
]

export const headerModalName = {
  MANUAL: 'MANUAL',
  ROAD_OF_LAW: 'ROAD_OF_LAW',
};

export const sessionStorageTokenKey = `oidc.user:https://prijava.test.eid.gov.rs/oauth2/oidcdiscovery/:${CLIENT_ID}`;
