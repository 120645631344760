import { useState, useMemo, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { MRT_Localization_SR_CYRL_RS } from 'material-react-table/locales/sr-Cyrl-RS';
import { trackPromise } from 'react-promise-tracker';
import { connect } from "react-redux";
import { getFilteredInstitutions, setInstitutionListTableFilter, deleteInstitution } from "../../actions/institutionActions"; 
import { confirmDialog } from '../Reusable/ConfirmDialog';
import authorizationService from "../../securityUtils/authorizationService";
import { MantineReactTable } from "mantine-react-table";
import { Link } from 'react-router-dom';
import {
  NotificationManager,
} from 'react-notifications';

const InstitutionTableServerFiltering = (props) => {
    const dispatch = useDispatch();
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [refresh, setRefresh] = useState(0);
    //const [rowCount, setRowCount] = useState(0);
  
    //table state
    const [columnFilters, setColumnFilters] = useState(props.filter?.filters || []);
    const [globalFilter, setGlobalFilter] = useState(props.filter?.globalFilter || '');
    const [sorting, setSorting] = useState(props.filter?.sorting || []);
    const [pagination, setPagination] = useState( {
      pageIndex: props.filter?.start || 0,
      pageSize: props.filter?.size || 15,
    });

    const handleDelete = (id) => {
      const action = () => {
        props.deleteInstitution(id)
        .then( () => {
          NotificationManager.success(props.dictionary["UpdateInstitution.UspesnoBrisanje"],props.dictionary["universal.Uspesno"], 5000);
        })
        .catch( () => { 
          NotificationManager.error(props.dictionary["UpdateInstitution.BrisanjeNeuspelo"], props.dictionary["universal.GreskaUObradi"], 5000);
        })
        .finally(()=>{
          setRefresh(Math.random());
        });
      };
      confirmDialog(`${props.dictionary["UpdateInstitution.ConfirmDialog.DeoPoruke"]}`, 
                     action, 
                     `${props.dictionary["UpdateInstitution.ConfirmDialog.Da"]}`,
                     );
  };
    
    const columns = useMemo(
        () => [
          {
            accessorKey: 'institutionName',
            header: `${props.dictionary["InstitutionTableServerFiltering.Columns.NazivInstitucije"]}`,
          },
          {
            accessorKey: 'currentlyActiveText',
            header: `${props.dictionary["InstitutionTableServerFiltering.Columns.Status"]}`,

          },
          {
            accessorKey: 'comment',
            header: `${props.dictionary["InstitutionTableServerFiltering.Columns.Komentar"]}`,
          },
          {
            header: `${props.dictionary["InstitutionTableServerFiltering.Columns.Izmena"]}`,
            columnDefType: 'display',
            enableColumnOrdering: true,
            show : authorizationService.canAccessAdminPanel(props.authRole),
            accessorFn: (row) => row.id,
            Cell: ({ row }) => (
                <Link to='#' onClick={()=>{props.showModalForUpdateFunction(row.original.id)}}>
                <i className="fas fa-edit deleteButton"></i>
                </Link>
            ),
            size: 50
          },
          {
            header: `${props.dictionary["InstitutionTableServerFiltering.Columns.Brisanje"]}`,
            columnDefType: 'display',
            enableColumnOrdering: true,
            show : ({row}) => authorizationService.canAccessAdminPanel(props.authRole) && row.original.caDelete==true,
            accessorFn: (row) => row.id,
            Cell: ({ row }) => (
                row.original.canDelete==true ? 
                (<Link to='#' onClick={()=>{handleDelete(row.original.id)}}>
                <i className="far fa-trash-alt deleteButton"></i>
                </Link>) : ""
            ),
            size: 50
          }
        ],
        [],
      );

      useEffect(() => {
        if (!props.institutions?.length) {
          setIsLoading(true);
        } else {
          setIsRefetching(true);
        } 
        const searchParams = { 
          start: pagination.pageIndex,
          size: pagination.pageSize,
          filters: columnFilters ?? [],
          globalFilter: globalFilter ?? '',
          sorting: sorting ?? []
        };
        props.setInstitutionListTableFilter(searchParams);
        trackPromise(props.getFilteredInstitutions(searchParams))
        .catch(() => {
           setIsError(true);
           NotificationManager.error(props.dictionary["universal.NeuspesnoUcitavanjePodataka"]);
          })
        .then( () => {
          setIsError(false);
        })
        .finally(() => {
          setIsLoading(false);
          setIsRefetching(false);
        }
      );
    }, [
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      refresh,
      props.refresh
    ]);
    const customTable = "custom-table";
    const customPaper = "custom-paper";
    return (
        <>
        <MantineReactTable
        localization={MRT_Localization_SR_CYRL_RS}
        columns={columns}
        data={props.institutions}
        enableRowNumbers
        initialState={{ showColumnFilters: true }}
        manualFiltering
        manualPagination
        manualSorting
        muiToolbarAlertBannerProps={
            isError
            ? {
                color: 'error',
                children: 'Error loading data',
                }
            : undefined
        }
        mantineTableProps={{
          className: {customTable},
          striped:true,
          tablelayout: 'fixed',
        }}
        mantinePaperProps={{
          className:{customPaper}
        }}
        mantineProgressProps={{
          striped:false,
          animate:true,
          size:'xs'
        }}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={props.rowCount}
        state={{
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        }}
        />
    </>
    );
}

const mapStateToProps = (state) => {
    return {
    authRole: state?.security?.authRole,
    dictionary: state?.startUp?.dictionary,
    filter: state?.institution.institutionListTableFilter,
    institutions: state?.institution?.institutionsFiltered?.content ?? [],
    rowCount: state?.institution?.institutionsFiltered?.totalElements ?? 0
  } };

export default connect(mapStateToProps, {
    getFilteredInstitutions,
    setInstitutionListTableFilter,
    deleteInstitution
})(InstitutionTableServerFiltering);