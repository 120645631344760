import React, { Component } from 'react';
import Select from 'react-select';
import ComboReusable from '../Reusable/ComboReusable';
import NotificationTopicOfDiscussionTable from '../NotificationTopicOfDiscussion/NotificationTopicOfDiscussionTable';
import { connect } from 'react-redux';


class NotificationsByInstitutionTab extends Component {
  render() {
    const institutionByUserIdList = this.props.institutionByUserIdList.map((item) => ({
      ...item,
      value: item.institution.id,
      label: item.institution.institutionName+' ('+item.notificationNumber+')',
    }));
    const customStyleForMultiselect = {
      control: (styles) => ({ ...styles, minHeight: '45px' }),
      option: (styles) => ({
        ...styles,
        color: '#495057',
        fontSize: '17px',
      }),
      placeholder: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '17px',
        marginLeft: '12px',
      }),
      input: (styles) => ({
        ...styles,
        color: '#6c757d',
        fontSize: '1.25rem',
        marginLeft: '12px',
      }),
    };
    const listScroller = {
      overflow:'auto',
      height:'70vh'
    }
    return (
      <div style={listScroller} className="p-3">
        <Select
          id="institutionsOfInterest"
          options={institutionByUserIdList}
          placeholder={this.props.dictionary["menu.Profil.NotificationsSelectInstitution"]}
          onChange={this.props.handleComboNotificationsByInstitution()}
          styles={customStyleForMultiselect}
        />
        <p className="ml-3 mt-2 medium-font">
          <em>
          {this.props.dictionary["NotificationsByInstitutionTab.Pregled"]}
          </em>
        </p>
        <NotificationTopicOfDiscussionTable
          quickPreviewSignal={1}
          filteredByCriteriaSignal={'institution'}
          notificationTopicOfDiscussionList={
            this.props.notificationByInstitutionList
          }
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dictionary: state.startUp.dictionary
});

export default connect(mapStateToProps, {})(NotificationsByInstitutionTab);

