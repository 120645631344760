import React, { Component } from 'react';
import { connect } from "react-redux";
import ActionPlanReportRow from './ActionPlanReportRow';
import { Table, Thead, Tbody, Th, Tr } from 'react-super-responsive-table';
import authorizationService from '../../securityUtils/authorizationService';

class ActionPlanReportTable extends Component {
  render() {
    let actionPlanReportList = this.props.actionPlanReportList.map(
      (actionPlanReport) => (
        <ActionPlanReportRow
          key={actionPlanReport.id}
          actionPlanReport={actionPlanReport}
        />
      )
    );

    return (
      <div className="table-responsive col-md-12 component-wrapper-fade-in">
        <Table id="example" className="table table-md mt-2 border-0">
          <Thead>
            <Tr className="border-0">
              <Th className="border-0 boldText emphesized-label-font">
                {this.props.dictionary["ActionPlanReportTable.Dokument"]}
              </Th>
              <Th width="9%" className="text-center border-0">
              {this.props.dictionary["ActionPlanReportTable.Pregled"]}
              </Th>
              <Th width="9%" className="text-center border-0">
              {this.props.dictionary["ActionPlanReportTable.Preuzimanje"]}
              </Th>
              {authorizationService.canAccessOGPPageSpecificAreas(
                this.props.authRole
              ) && (
                <Th width="9%" className="text-center border-0">
                  {this.props.dictionary["ActionPlanReportTable.Brisanje"]}
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>{actionPlanReportList}</Tbody>
        </Table>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
});
export default connect(mapStateToProps,{})(ActionPlanReportTable);
