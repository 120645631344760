import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUser, updateContactData } from '../../actions/userActions';
import classnames from 'classnames';
import { getInstitutionsByStatus } from '../../actions/institutionActions';
import { getRoleList } from '../../actions/roleActions';
import { deleteErrors } from '../../utils';
import { setMessage } from '../../utils';
import {
  NotificationManager,
} from 'react-notifications';
import { trackPromise } from 'react-promise-tracker';
import {FormControlLabel, FormGroup, Switch} from '@mui/material';
import Modal from 'react-bootstrap/Modal';

class UpdateContactData extends Component {
  constructor() {
    super();
    this.state = {
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      telephone: '',
      agreedToBeContacted: false,
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if(nextProps.user==null) return;
    const {
      id,
      firstName,
      lastName,
      email,
      telephone,
      agreedToBeContacted,
    } = nextProps.user;

    this.setState({
      id,
      firstName,
      lastName,
      email,
      telephone,
      agreedToBeContacted,
    });
  }
  componentDidMount() {
    const idUsr = this.props.userId;
    this.props.getUser(idUsr);
    this.props.deleteErrors();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.name=="agreedToBeContacted" ? e.target.checked : e.target.value });
  }

  handleValidation = () => {
    let error = false
    if (this.state.firstName == '') {
      this.props.setMessage(
        this.props.dictionary["updateContactData.Validation.ime"],
        '#firstNameUpdate',
        '#msg2'
      );
      error = true;
    }
    if (this.state.lastName == '') {
      this.props.setMessage(
        this.props.dictionary["updateContactData.Validation.prezime"],
        '#lastNameUpdate',
        '#msg3'
      );
      error = true;
    }
    if (this.state.email == '') {
      this.props.setMessage(this.props.dictionary["updateContactData.Validation.email"], '#emailUpdate', '#msg4');
      error = true;
    }

    return error;
  };

  onSubmit(e) {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const updateUser = {
      id: this.state.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      telephone: this.state.telephone,
      agreedToBeContacted: this.state.agreedToBeContacted,
    };
    trackPromise(this.props.updateContactData(updateUser))
    .catch(() => {
      NotificationManager.error(this.props.dictionary["updateUser.NeuspesnaPromena"]);
     })
   .then( () => {
    NotificationManager.success(this.props.dictionary["updateUser.UspesnaPromena"]);
   })
  }

  render() {
    const { errors } = this.state;
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="modalForUpdateContactData"
      >
        <Modal.Header className='modalHeader'>
            <Modal.Title>
            {this.props.dictionary["updateContactData.naslov"]}
            </Modal.Title>
            <button onClick={this.props.handleClose} type="button" class="btn-close btn-close-white m-0 opacity-1" aria-label="Close"></button>
        </Modal.Header> 
        <Modal.Body>  
        <div className="component-wrapper-fade-in">
          <div className="register">
            <div className="container">
              <div className="row">
                <div className="col-md-12 m-auto">
                  <form onSubmit={this.onSubmit} className="col-md-12">
                    <div className="col-md-12">
                      <div className="form-group mt-2">
                        <label className="textShadow">{this.props.dictionary["updateContactData.ime"]}</label>
                        <input
                          id="firstNameUpdate"
                          type="text"
                          className={classnames('form-control')}
                          placeholder={this.props.dictionary["updateContactData.placeholder.ime"]}
                          name="firstName"
                          value={this.state.firstName}
                          onChange={this.onChange}
                        />
                        <div id="msg2" />
                      </div>
                      <div className="form-group mt-2">
                        <label className="textShadow">{this.props.dictionary["updateContactData.prezime"]}</label>
                        <input
                          type="text"
                          id="lastNameUpdate"
                          className={classnames('form-control')}
                          placeholder={this.props.dictionary["updateContactData.placeholder.prezime"]}
                          name="lastName"
                          value={this.state.lastName}
                          onChange={this.onChange}
                        />
                        <div id="msg3" />
                      </div>
                      <div className="form-group mt-2">
                        <label className="textShadow">
                        {this.props.dictionary["updateContactData.email"]}
                        </label>
                        <input
                          id="emailUpdate"
                          type="text"
                          className={classnames('form-control')}
                          placeholder={this.props.dictionary["updateContactData.placeholder.email"]}
                          name="email"
                          value={this.state.email}
                          onChange={this.onChange}
                        />
                        <div id="msg4" />
                      </div>
                      <div className="form-group mt-2">
                      <label className="textShadow">
                      {this.props.dictionary["updateContactData.telefon"]}
                      </label>
                      <input
                        id="telephoneUpdate"
                        type="text"
                        className={classnames('form-control')}
                        placeholder={this.props.dictionary["updateContactData.placeholder.telefon"]}
                        name="telephone"
                        value={this.state.telephone}
                        onChange={this.onChange}
                      />
                      <div id="msg5" />
                    </div>
                    <div className="form-group mt-2">
                      <FormControlLabel 
                      control={
                          <Switch
                            id="agreedToBeContactedUpdate"
                            name="agreedToBeContacted"
                            checked={this.state.agreedToBeContacted}
                            onChange={this.onChange}
                            role="checkbox"
                          />
                        }
                        label={this.props.dictionary["updateContactData.pristanakNaKontakt"]} />
                      <div id="msg5" />
                      </div>
                    </div>
                    <div className="row mt-5 mb-2 justify-content-center">
                      <button
                        type="submit"
                        id="subscribeButton"
                        className="col-md-4 me-0"
                      >
                        {this.props.dictionary["updateContactData.sacuvaj"]}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      </Modal>
    );
  }
}


const mapStateToProps = (state) => ({
  dictionary:state.startUp.dictionary,
  userId: state.security.userId,
  user: state.user.user,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getUser,
  updateContactData,
  getRoleList,
  getInstitutionsByStatus,
  deleteErrors,
  setMessage,
})(UpdateContactData);
