import { useSelector } from "react-redux"
import Input from '../Reusable/Input';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import UploadDocumentInitialisedFiles from '../Reusable/UploadDocumentInitialisedFiles';
import TextArea from '../Reusable/TextArea';
import MethodDocumentTable from "../MethodDocument/MetodDocumentTable";
import MethodDocuments from "../MethodDocument/MethodDocuments";

const Event = (props) => {
    const dictionary = useSelector(state => state.startUp.dictionary);

    const handleStartDateChange = (e) => {
        if (!props.startDate) {
            const selectedDate = new Date(e);
            const isMidnight = selectedDate.getHours() === 0 && selectedDate.getMinutes() === 0;
    
            if (isMidnight) {
                const now = new Date();
                selectedDate.setHours(now.getHours());
                selectedDate.setMinutes(now.getMinutes());
                props.setStartDate(selectedDate);
            } else {
                props.setStartDate(e);
            }
        } else { 
            props.setStartDate(e);
        }
    }

    return (
        <>  
            <div className="form-group">
                <label className="labelText textShadow">
                    {dictionary["ModalForAddNewMethod.Naslov"]}: <span className="redAsterisk"> *</span>
                </label>
                <Input
                    type="text"
                    id="title"
                    placeholder={dictionary["ModalForAddNewMethod.Placeholder.Naslov"]}
                    name="title"
                    value={props.title}
                    onChange={(e) => props.setTitle(e.target.value)}
                    //errors={errors.title}
                />
                <div id="titleМsg" />
            </div>
            <div className="form-group">
                <label className="labelText textShadow">
                    {dictionary["ModalForAddNewMethod.DatumOdrzavanja"]}: <span className="redAsterisk"> *</span>
                </label>
                <DatePicker
                    id="startDate"
                    selected={props.startDate ? new Date(props.startDate) : null}
                    onChange={(date) => {handleStartDateChange(date)}}
                    className="dateInput form-control"
                    dateFormat="dd.MM.yyyy HH:mm"
                    placeholderText={dictionary["ModalForAddNewMethod.Placeholder.DatumOdrzavanja"]}
                    minDate={moment().toDate()}
                    maxDate={props.phase?.endDate ? new Date(props.phase?.endDate) : null}
                    showTimeInput
                    timeInputLabel={dictionary["DatePicker.TimeInputLabel"]}
                    />
                <div id="startDateМsg" />
            </div>
            <div className="form-group">
                <label className="labelText textShadow">
                {dictionary["ModalForAddNewMethod.Location"]}: <span className="redAsterisk"> *</span>
                </label>
                <TextArea
                    type="text"
                    id="location"
                    placeholder={dictionary["ModalForAddNewMethod.Placeholder.Location"]}
                    name="location"
                    value={props.location}
                    onChange={(e) => props.setLocation(e.target.value)}
                    //errors={errors.location}
                    maxLength="1000"
                    rows={6}
                />
                <div id="locationMsg" />
            </div>
            <MethodDocuments
                methodDocuments = {props.methodDocuments}
                uploadedFiles={props.uploadedFiles}
                setUploadedFiles={props.setUploadedFiles}
                selectedFieldsOfInterest={[]}
                phaseId={props.phase.id}
                documents={props.documents}
                setDocuments={props.setDocuments}
                methodId={props.methodId}
            />
        </>
    )
}

export default Event;