import React, { Component } from 'react';
import { connect } from 'react-redux';
import ComboReusable from '../Reusable/ComboReusable';
import { getInstitutions } from '../../actions/institutionActions';
import LoadingIndicator from '../Reusable/LoadingIndicator';
import { trackPromise } from 'react-promise-tracker';
import { gaKey } from '../../constants';
import ReactGA from 'react-ga';
import DatePicker from 'react-datepicker';
import ComboBoxSearchCriteria from './ComboBoxSearchCriteria';
import { setMessage } from '../../utils';
import { generateReport } from '../../actions/topicOfDiscussionReportActions';
import moment from 'moment';

class IndicatorsPage extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      searchCriteria: null, // Ukupno ili po ODU
      institutionId: null, // if ODU
      startDate: null,
      endDate: null,
      phaseTypeId: null,
      actTypeId: null,
      actTypeCode: null
    };
  }

  componentDidMount() {
   /*  if(this.props.alowedAnalytics){
      ReactGA.initialize(gaKey);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }; */
    trackPromise(this.props.getInstitutions()).finally(() =>
      this.setState({ isLoading: false })
    );
  }

  handleComboBoxChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });

  };

  handleStartDateChange = (date) => {
    this.setState({ startDate: date });
  };
  handleEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  handleValidation = () => {
    let error = false;

    if (!this.state.phaseTypeId) {
      this.props.setMessage(
        `${this.props.dictionary["IndicatorsPage.Validation.MorateIzabratiFazu"]}`,
        '#phaseTypeId',
        '#phaseTypeIdМsg'
      );
      error = true;
    }

    if (!this.state.searchCriteria) {
      this.props.setMessage(
        `${this.props.dictionary["IndicatorsPage.Validation.MorateIzabratiNacinPretrage"]}`,
        '#searchCriteria',
        '#searchCriteriaMsg'
      );
      error = true;
    }

    if (!(this.state.startDate && this.state.endDate)) {
      this.props.setMessage(`${this.props.dictionary["IndicatorsPage.Validation.MorateUnetiPeriod"]}`, '#period', '#periodМsg');
      error = true;
    }

    if (
      this.state.searchCriteria === '2' &&
      !this.state.institutionId
    ) {
      this.props.setMessage(
        `${this.props.dictionary["IndicatorsPage.Validation.MorateIzabratiInstituciju"]}`,
        '#institutionId',
        '#institutionIdМsg'
      );
      error = true;
    }

    if (!this.state.actTypeId) {
      this.props.setMessage(
        `${this.props.dictionary["IndicatorsPage.Validation.MorateOdabratiTipAkta"]}`,
        '#actTypeId',
        '#actTypeIdМsg'
      );
      error = true;
    }

    return error;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if (this.handleValidation() == true) {
      return;
    }

    const {
      institutionId,
      startDate,
      endDate,
      searchCriteria,
      phaseTypeId,
      actTypeId
    } = this.state;

    const { institutions } = this.props || [];
    const institutionNames =
      institutionId && institutions.filter((i) => i.id == institutionId);

    const generateRepotrtCriteria = {
      institutionId: searchCriteria === '2' ? institutionId : null,
      institutionName:
        searchCriteria === '2' && institutionNames
          ? institutionNames[0]?.institutionName
          : null,
      startDate,
      endDate,
      jls: this.props.jls,
      phaseTypeId,
      actTypeId,
      actTypeCode: this.props.taxonomies['ActType'].filter(item => item.id==actTypeId)[0].code,
      phaseTypeCode: this.props.taxonomies['ProcessPhase'].filter(item => item.id==phaseTypeId)[0].code
    };

    const period =
      moment(startDate).format('DD.MM.YYYY') +
      ' - ' +
      moment(endDate).format('DD.MM.YYYY');
    this.props.generateReport(generateRepotrtCriteria, period);
  };

  render() {
    const { isLoading } = this.state;
    let { institutions } = this.props || [];
    institutions=institutions.filter(item => item.jls==this.props.jls);
    institutions.sort(function (a, b) {
      return a.institutionName.localeCompare(b.institutionName);
    });

    const actTypes = this.props.taxonomies['ActType'].filter(item => 
      item.taxonomyValueInformationList.some(element => 
        element.informationName==(this.props.jls ? 'JLS':'RepublickiNivo')))
       .filter(item => item.taxonomyValueInformationList.some(element => 
        element.informationName=='ZaIzvestaj'));

    const phaseTypes = this.props.taxonomies['ProcessPhase'];
    return isLoading ? (
      <LoadingIndicator />
    ) : (
      <div className="container-fluid component-wrapper-fade-in-large-list">
        <div id="topic-header-row" className="row container-flow mb-4">
          <div className="col-md-12 m-auto">
            <div className="col-md-11 m-auto">
              <h1>{this.props.dictionary["IndicatorsPage.Indikatori"]}</h1>
            </div>
          </div>
        </div>
        <div className="row container-flow mb-5">
          <div className="col-md-12 m-auto">
            <div className="col-md-11 m-auto">
              <h3 className="mb-5">{this.props.dictionary["IndicatorsPage.KriterijumiZaGenerisanje"]}</h3>
              <div className="row col-md-12 mt-3 mb-4">
                <div className="col-md-6 mt-3 d-flex">
                  <label className="labelText textShadow mt-2">
                  {this.props.dictionary["IndicatorsPage.Period"]}:
                    <span className="redAsterisk"> *</span>
                  </label>
                  <div className="col-md-4 ps-3 pe-3">
                    <DatePicker
                      id="period"
                      selected={this.state.startDate}
                      onChange={this.handleStartDateChange.bind(
                        'startcreationDate'
                      )}
                      className="dateInput form-control"
                      dateFormat="dd.MM.yyyy"
                      placeholderText={this.props.dictionary["IndicatorsPage.Datepicker.Placeholder.Pocetak"]}
                      maxDate={this.state.endDate}
                      showYearDropdown
                    />
                  </div>
                  <label className="labelText textShadow mt-2"> - </label>
                  <div className="col-md-4 ps-3 pe-3">
                    <DatePicker
                      id="period"
                      selected={this.state.endDate}
                      onChange={this.handleEndDateChange.bind('endDate')}
                      className="dateInput form-control"
                      dateFormat="dd.MM.yyyy"
                      placeholderText={this.props.dictionary["IndicatorsPage.Datepicker.Placeholder.Kraj"]}
                      minDate={this.state.startDate}
                      showYearDropdown
                    />
                  </div>
                  <div id="periodМsg" />
                </div>
                </div>
                <div className="d-flex">
                  <div className="pe-2">
                    <label className="labelText textShadow">
                    {this.props.dictionary["IndicatorsPage.IzaberiteTipAkta"]}:
                      <span className="redAsterisk"> *</span>
                    </label>
                    <ComboReusable
                      id="actTypeId"
                      namePart={this.props.dictionary["IndicatorsPage.TipAkta.NamePart"]}
                      items={actTypes.map((key) => (
                        <option key={key.id} value={key.id}>
                          {key.value}
                        </option>
                      ))}
                      onChange={this.handleComboBoxChange}
                    />
                  <div id="actTypeIdМsg" />
                </div>
             
              
                <div className="pe-2">
                  <label className="labelText textShadow">
                  {this.props.dictionary["IndicatorsPage.IzaberiteFazuProcesa"]}:
                    <span className="redAsterisk"> *</span>
                  </label>
                  <ComboReusable
                    id="phaseTypeId"
                    namePart={this.props.dictionary["IndicatorsPage.FazaProcesa.NamePart"]}
                    items={phaseTypes.map((phase) => (
                      <option key={phase.id} value={phase.id}>
                        {phase.value}
                      </option>
                    ))}
                    onChange={this.handleComboBoxChange}
                  />
                  <div id="phaseTypeIdМsg" />
                </div>
                </div>
                <div className="d-flex">
                <div className="pe-2">
                  <label className="labelText textShadow">
                  {this.props.dictionary["IndicatorsPage.IzaberiteNacinPretrage"]}:
                    <span className="redAsterisk"> *</span>
                  </label>
                  <ComboBoxSearchCriteria
                    value={this.state.searchCriteria}
                    onChange={this.handleComboBoxChange}
                  />
                  <div id="searchCriteriaMsg" />
                </div>
                {this.state.searchCriteria === '2' && (
                  <div className="form-group">
                    <label className="labelText textShadow">
                    {this.props.dictionary["IndicatorsPage.IzaberiteODU"]}:
                      <span className="redAsterisk"> *</span>
                    </label>
                    <ComboReusable
                      id="institutionId"
                      namePart="институцију"
                      items={institutions.map((institution) => (
                        <option key={institution.id} value={institution.id}>
                          {institution.currentlyActive == true
                            ? institution.institutionName
                            : institution.institutionName + ' (неактивно)'}
                        </option>
                      ))}
                      onChange={this.handleComboBoxChange}
                    />
                    <div id="institutionIdМsg" />
                  </div>
                )}
              </div>
              <div className="row">
                  <div className="pe-2">
        
                <button
                  type="button"
                  id="subscribeButton"
                  className="col-md-2 auto"
                  onClick={this.handleSubmit}
                >
                  {this.props.dictionary["IndicatorsPage.GenerisiIzvestaj"]}
                </button>
                </div></div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  institutions: state.institution.institutions,
  authRole: state.security.authRole,
  dictionary:state.startUp.dictionary,
  errors: state.errors,
  alowedAnalytics: state.security.alowedAnalytics,
  jls:state.security.jls,
  taxonomies:state.startUp.taxonomies,
});

export default connect(mapStateToProps, {
  getInstitutions,
  setMessage,
  generateReport,
})(IndicatorsPage);
